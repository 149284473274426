import React, {FC} from 'react';
import {Table, flexRender} from '@tanstack/react-table';
import styled from 'styled-components';
import {ColumnDef} from '@tanstack/table-core';
import clsx from 'clsx';

interface TableHeaderProps {
  table: Table<unknown>;
  makeRowClassName?: (row: any) => string;
}

// custom column type definition
// added alignMiddle property for conditional style
export type ColumnDefCustom<T> = ColumnDef<T> & {alignMiddle?: boolean};

const TableBody: FC<TableHeaderProps> = props => {
  const {table, makeRowClassName = () => {}} = props;

  return (
    <tbody>
      {table.getRowModel().rows.map(row => (
        <RowStyle key={row.id} className={` ${makeRowClassName(row.original)}`}>
          {row?.getVisibleCells()?.map(cell => {
            const columnDef = cell.column.columnDef as ColumnDefCustom<null>;

            return (
              <td
                key={cell.id}
                className={clsx(columnDef.meta?.cellCostumClass, {
                  'align-middle':
                    columnDef?.alignMiddle === undefined ||
                    columnDef?.alignMiddle === true,
                })}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </RowStyle>
      ))}
    </tbody>
  );
};

const RowStyle = styled.tr<{sorted?: string; sortable?: boolean}>`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  max-height: 100px;

  padding: 0;
  &:hover {
    background-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
  }
`;

export default TableBody;
