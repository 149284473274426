import React, {FC, useContext, useMemo} from 'react';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import RelatedDocument from 'types/related-document';
import AsyncItemPicker from 'components/async-item-picker';
import {useDispatch} from 'react-redux';
import updateItem from '../../store/actions/update-item';
import {CommercialProduct} from '../../types/commercial-product';
import documentContext from '../document-context/document-context';
import {useLinkOrderDocumentMutation} from '../../store/endpoints/link-order-document';
import {useParams} from 'react-router';
import {useUnlinkOrderDocumentMutation} from '../../store/endpoints/unlink-order-document';
import {components} from 'react-select';
import {Link} from 'react-router-dom';

interface Props {
  docs?: RelatedDocument[];
  index: number;
  item: CommercialProduct;
}

const RelatedDocumentsSection: FC<Props> = props => {
  const {docs, item, index} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(item, key, v));
  };

  const {validationErrors, doc} = useContext(documentContext);

  const [link, {isLoading: isLinking}] = useLinkOrderDocumentMutation();

  const [unlink, {isLoading: isUnLinking}] = useUnlinkOrderDocumentMutation();

  const handleLink = async (doc: RelatedDocument) => {
    const result = await link({
      id,
      commercial_product_id: item?.id,
      target: doc,
    });
    if ('data' in result) {
      handleUpdate('related_documents', result.data.data);
    }
  };

  const {id} = useParams();

  const handleUnlink = async (doc: RelatedDocument) => {
    const result = await unlink({
      id,
      commercial_product_id: item?.id,
      target: doc,
    });
    if ('data' in result) {
      handleUpdate('related_documents', result.data.data);
    }
  };

  const handleChange = (v: any, payload: any) => {
    if (payload.action === 'remove-value') {
      handleUnlink(payload.removedValue);
    } else if (payload.action === 'select-option') {
      handleLink(payload.option);
    }
  };

  const isDisabled = useMemo(
    () => isLinking || doc?.document_type === 'STOCK',
    [doc?.document_type, isLinking]
  );

  const length = item.related_documents?.length ?? 0;

  const canAddMore = useMemo(() => {
    if (isLinking) {
      return false;
    }
    if (doc?.document_type === 'STOCK') {
      return false;
    }
    if (doc?.document_type === 'CLIENT') {
      return length < 1;
    }
    if (doc?.document_type === 'SUPPLIER') {
      return true; //length < 2;
    }
  }, [doc?.document_type, isLinking, length]);

  const MultiValueLabel = (_props: any) => {
    const d = _props.data;

    const linkMap: LinkTypeMap = {
      invoice: `/dashboard/invoices/${d.model_id}/details`,
      order: `/dashboard/orders/${d.model_id}/details`,
      quotation: `/dashboard/quotations/${d.model_id}/details`,
    };

    return (
      <div className="text btn-link">
        <Link to={linkMap[d.model_type]} target="_blank">
          <components.MultiValueLabel {..._props} />
        </Link>
      </div>
    );
  };

  return (
    <div>
      <AsyncItemPicker
        label={t(...messages.linkedDocuments())}
        onChange={(x: any, action: any) => handleChange(x, action)}
        menuPlacement="top"
        value={item.related_documents}
        isMulti
        isClearable={false}
        className="bg-gray-light"
        isSearchable={canAddMore ? undefined : false}
        placeholder={isDisabled ? '' : undefined}
        menuIsOpen={canAddMore ? undefined : false}
        components={{
          MultiValueLabel,
          ...(canAddMore
            ? {}
            : {DropdownIndicator: () => '', IndicatorSeparator: () => ''}),
        }}
        styles={{
          // @ts-ignore
          multiValueRemove: base => {
            return isDisabled ? {...base, display: 'none'} : base;
          },
          // @ts-ignore
          control: base => {
            return isDisabled
              ? {
                  ...base,
                  backgroundColor:
                    'rgba(var(--bs-gray-light-rgb), var(--bs-bg-opacity))',
                }
              : base;
          },
        }}
        //isDisabled={isDisabled}
        endPoint={`orders/${doc?.id}/commercial-products/${item.id}/orders-to-link`}
        errors={validationErrors[`products.${index}.related_documents`]}
      />
    </div>
  );
};

export default RelatedDocumentsSection;

export const generateLink = (d: RelatedDocument) => {
  const linkMap: LinkTypeMap = {
    Invoice: `/dashboard/invoices/${d.model_id}/details`,
    Order: `/dashboard/orders/${d.model_id}/details`,
    Quotation: `/dashboard/quotations/${d.model_id}/details`,
  };

  return linkMap[d.model_type];
};
type LinkTypeMap = Record<RelatedDocument['model_type'], string>;
