import React, {FC} from 'react';
import Filters from './filters';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Button, CardBody, Col, Row} from 'reactstrap';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const CreditsControls: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <Row>
      <Col>
        <CardBody>
          <Filters
            filters={filters}
            onChangeFilters={onChangeFilters}
            onClearFilters={onClearFilters}
          />
        </CardBody>
      </Col>
    </Row>
  );
};

export default CreditsControls;
