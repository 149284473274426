import {useState} from 'react';
import {addMonths} from 'date-fns';
import {
  useStoreOrderMutation,
  StoreOrderRequest,
} from 'features/commercial/orders/store/endpoints/store-order';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {now} from 'utils/now';
import realDate from 'utils/real-date';
import {useSeeder} from 'utils/use-seeder';
import toast from 'store/ui/actions/toast';
import {useDispatch} from 'react-redux';
import {guessCategory} from 'pages/commercial/invoices/create/invoice/use-invoice-form';
import {Directory} from 'types/directory';
import {useShowDocumentCategoriesQuery} from 'features/commercial/quotations/store/endpoints/show-document-category';
import {DocumentCategory} from 'types/document-category';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useCustomerOrdersForm = () => {
  const [storeCustomerOrder, {isLoading, error}] = useStoreOrderMutation();

  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeCustomerOrder(params);

    if ('data' in result) {
      const customer_order = result?.data.data;
      navigate(`/dashboard/orders/${customer_order.id}/details`);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  const {data} = useShowDocumentCategoriesQuery();

  const categories = (data?.data || []) as DocumentCategory[];
  const syncParams = (client: Directory) => {
    const updatedParams = {...params};

    updatedParams.client = client;

    updatedParams.category = guessCategory(client, categories);

    setParams(updatedParams);
  };

  const [params, setParams] = useState<Params>({
    client: undefined,
    document_type: {
      id: 1,
      value: 'CLIENT',
    },
    category: undefined,
    type: undefined,
    origin: undefined,
    language: undefined,
    order_date: now(),
    due_date: realDate(addMonths(new Date(), 1)),
  });

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    syncParams,
  };
};
type Params = StoreOrderRequest;
