import {ApiEndpointBuilder} from 'services/api/types';
import {ResponseData} from 'types/response-data';

const verifyPasswordResetTokenEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<ResponseData<any>, VerifyTokenRequest>({
    query: info => ({
      url: 'verify-reset-token',
      method: 'POST',
      body: {
        token: info.token,
        email: info.email,
      },
    }),
  });
};

type VerifyTokenRequest = {
  token?: string;
  email?: string;
};

export default verifyPasswordResetTokenEndpoint;
