import React, {useMemo} from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {
  Alert,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import {useStockMove} from './use-stock-move';
import {MOVEMENT_TYPE} from './constant';
import ItemPicker from 'components/item-picker';

const StockMovementForm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    errorMessage,
    setParams,
    movementInfo,
    addMovementInfo,
    removeMovementInfo,
    updateMovementInfo,
  } = useStockMove();

  const movementType = useMemo(
    () => MOVEMENT_TYPE.map(x => ({name: t(...x.label()), id: x.id})),
    []
  );

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Row>
            <Col md="10">
              <ItemPicker
                className="mb-3"
                options={movementType}
                onKeyDown={handleKeyboardEventDown}
                endPoint="data/inventory-movements"
                onChange={(type: any) => setParams({...params, type})}
                value={params.type}
                label={t(...messages.movement_type())}
                errors={validationErrors.type}
              />
            </Col>
            <Col md="2" className="">
              <div className="d-flex align-items-center h-100">
                <Button color="light" onClick={addMovementInfo}>
                  <Icon name="plus" size={15} className="me-1" />
                  {t(messages.addMovement())}
                </Button>
              </div>
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th style={{width: '25%'}}> {t(...messages.from())} </th>
                <th style={{width: '25%'}}> {t(...messages.to())} </th>
                <th style={{width: '100px'}}> {t(...messages.quantity())} </th>
                <th> {t(...messages.comments())} </th>
                <th> {t('productsPage.action')} </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(movementInfo).map((p, i) => (
                <tr key={p.id}>
                  <>
                    <td>
                      <AsyncItemPicker
                        onKeyDown={handleKeyboardEventDown}
                        disabled={params.type?.id === 'IN'}
                        endPoint="data/stock-locations"
                        containerClassName="d-flex align-items-center flex-column w-100"
                        className="w-100"
                        onChange={stock_location_start =>
                          updateMovementInfo(
                            p,
                            'stock_location_start',
                            stock_location_start
                          )
                        }
                        value={p.stock_location_start}
                        errors={
                          validationErrors[
                            `movement_infos.${i}.stock_location_start_id`
                          ]
                        }
                      />
                    </td>
                    <td>
                      <AsyncItemPicker
                        onKeyDown={handleKeyboardEventDown}
                        disabled={params.type?.id === 'OUT'}
                        containerClassName="d-flex align-items-center flex-column w-100"
                        endPoint="data/stock-locations"
                        className="w-100"
                        onChange={stock_location_arrival =>
                          updateMovementInfo(
                            p,
                            'stock_location_arrival',
                            stock_location_arrival
                          )
                        }
                        value={p.stock_location_arrival}
                        errors={
                          validationErrors[
                            `movement_infos.${i}.stock_location_arrival_id`
                          ]
                        }
                      />
                    </td>
                    <td>
                      <TextInput
                        onKeyDown={handleKeyboardEventDown}
                        onChange={quantity =>
                          updateMovementInfo(p, 'quantity', quantity)
                        }
                        value={p.quantity}
                        errors={
                          validationErrors[`movement_infos.${i}.quantity`]
                        }
                      />
                    </td>
                    <td>
                      <TextInput
                        onChange={comments =>
                          updateMovementInfo(p, 'comment', comments)
                        }
                        rows={1}
                        value={p.comment}
                        type="textarea"
                        errors={validationErrors[`movement_infos.${i}.comment`]}
                      />
                    </td>

                    <td>
                      <div className="d-flex align-items-center flex-column">
                        <Button
                          color="transparent"
                          onClick={() => removeMovementInfo(p.id)}
                        >
                          <Icon
                            className="text-danger"
                            name="trash-2"
                            size={15}
                          />
                        </Button>
                      </div>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default StockMovementForm;
