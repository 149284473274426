import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedOrderFromResource} from './show-order';
import {Order} from '../../types/order';

const sendLabelOrder = api.injectEndpoints({
  endpoints: builder => ({
    sendLabelOrder: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `orders/${params.id}/send-labels`,
          body: {
            body: params.body,
            product_variants: params.products,
            emails: params.emails,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
  emails: any[];
  body?: string;
  products?: any[];
};

type Response = ResponseData<Order> & {message: string};

export const {useSendLabelOrderMutation} = sendLabelOrder;
