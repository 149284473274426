import React from 'react';
import EmailRow from './email-row';
import messages from './messages';
import PhoneRow from './phone-row';
import {useCoordinateForm} from './use-coordinate-tab';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import {Trans, useTranslation} from 'react-i18next';
import {Row, Col, Alert, Dropdown} from 'reactstrap';
import {DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {useToggle} from 'usehooks-ts';
import clsx from 'clsx';
import Can from 'features/hylian-shield/can';

const CoordinatesTab = () => {
  const {
    addPhone,
    phone,
    email,
    addEmail,
    errorMessage,
    isLoading,
    isSuccess,
    directory,
    removePhone,
    removeEmail,
    mainEmailId,
    mainPhoneId,
    setMainEmailId,
    setMainPhoneId,
    submit,
    updatePhone,
    updateEmail,
    validationErrors,
    canEdit,
  } = useCoordinateForm();
  const [v, toggle] = useToggle();

  const {t} = useTranslation();

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <SectionSeparator
            title={t(...messages.title(), {name: directory?.display_name})}
          />
        </Col>
        <Can action="edit" subject="directory">
          <Col md="2">
            <Dropdown isOpen={v} toggle={toggle} direction="down">
              <DropdownToggle color="important" className="w-100">
                {t(...messages.add())}
                <Icon name="chevron-down" className="ms-1 " size={15} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    addPhone();
                  }}
                >
                  {t(...messages.addPhone())}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    addEmail();
                  }}
                >
                  {t(...messages.addEmail())}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Can>
      </Row>
      <Row className="mb-3">
        <SectionSeparator title={t(...messages.phone())} />

        <PhoneRow
          updatePhone={updatePhone}
          phone={phone}
          mainPhoneId={mainPhoneId}
          setMainPhoneId={(id: ObjectId) => setMainPhoneId(id)}
          removePhone={removePhone}
          validationErrors={validationErrors}
        />

        <SectionSeparator
          title={t(...messages.emails(), {name: directory?.display_name})}
        />
        <EmailRow
          mainEmailId={mainEmailId}
          setMainEmailId={(id: ObjectId) => setMainEmailId(id)}
          updateEmail={updateEmail}
          email={email}
          removeEmail={removeEmail}
          validationErrors={validationErrors}
        />
      </Row>

      <Row>
        <Col md={12} className=" mb-4">
          {validationErrors.directory_emails && (
            <Row>
              <Col>
                <Alert color="warning">
                  {validationErrors.directory_emails}
                </Alert>
              </Col>
            </Row>
          )}

          {!directory?.is_archived && (
            <AppBarActions>
              <Can action="edit" subject="directory">
                <Button
                  isLoading={isLoading}
                  color="important"
                  onClick={submit}
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </Can>
            </AppBarActions>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CoordinatesTab;
