import {createSelector} from '@reduxjs/toolkit';
import {selectDomain} from '.';

export default createSelector([selectDomain], domain => {
  const allCollections = domain.expandedCollections;

  const expandCollection = Object.values(allCollections).at(0);

  return expandCollection;
});
