import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {Plus} from 'react-feather';

import messages from '../messages';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

import {FilterMap} from 'features/listing/list/slice/types';

import {ProjectsFilters} from './types';
import Can from 'features/hylian-shield/can';

interface Props {
  filters: FilterMap<ProjectsFilters>;
}

const Actions: FC<Props> = props => {
  const {t} = useTranslation();

  return (
    <div className="justify-content-end d-flex">
      <AppBarActions>
        <Can
          action="administration"
          subject={['studio-projects', 'studio-petits-projects']}
        >
          <Fragment>
            <Link
              to="/dashboard/projects/create"
              className="btn btn-important "
            >
              <Plus size={15} className="me-1" />
              {t(...messages.addProject())}
            </Link>
          </Fragment>
        </Can>
      </AppBarActions>
    </div>
  );
};

export default Actions;
