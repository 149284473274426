import {Invoice} from 'features/commercial/invoices/types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getProjectSourcingFeesInvoices = api.injectEndpoints({
  endpoints: builder => ({
    getProjectSourcingFeesInvoices: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `projects/${params.id}/invoices?for_sourcing_fees`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Invoice[]>;

export const {useLazyGetProjectSourcingFeesInvoicesQuery} =
  getProjectSourcingFeesInvoices;
