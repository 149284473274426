import React, {FC, useContext} from 'react';
import {paymentMethods} from '../../constants/payment-methods';
import documentContext from '../../containers/document-context/document-context';
import updatePayment from '../../store/actions/update-payment';
import {PaymentMethod} from '../../types/payment-method';
import EmptyLabel from '../spacing/empty-label';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import DateInput from 'components/date-input';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Col, Row} from 'reactstrap';
import styled from 'styled-components';
import {Option} from 'types/option';
import {Payment} from 'types/payment';
import PaymentRemovalButton from '../payment-removal-button/payment-removal-button';
import clsx from 'clsx';

interface Props {
  payment: Payment;
  index: number;
}

const PaymentItem: FC<Props> = props => {
  const {payment, index} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const {validationErrors, doc, doc_type, is_locked} =
    useContext(documentContext);

  const method = payment.payment_method?.value as PaymentMethod | undefined;

  const isMethodLocked = method && new Set(paymentMethods).has(method);

  const isRemotePayment = typeof payment.id === 'number';

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <DateInput
            disabled={is_locked}
            label={t(...messages.date())}
            value={payment.date}
            className={clsx({
              'bg-gray-light': isRemotePayment,
            })}
            onChange={d => dispatch(updatePayment(payment, 'date', d))}
            errors={validationErrors[`payments.${index}.date`]}
          />
        </Col>
        <Col>
          <AsyncItemPicker
            label={t(...messages.paymentType())}
            endPoint={`data/payment-types`}
            value={payment.type}
            disabled={is_locked}
            getOptionValue={(x: Option) => x.value}
            otherParams={{
              document_id: doc?.id,
              document_type: doc_type,
            }}
            inputClassName={clsx({
              'bg-gray-light': isRemotePayment,
            })}
            getOptionLabel={(t: Option) => t.label}
            onChange={r => dispatch(updatePayment(payment, 'type', r))}
            errors={validationErrors[`payments.${index}.type`]}
          />
        </Col>
        <Col>
          <AsyncItemPicker
            label={t(...messages.paymentMethod())}
            endPoint={`data/payment-methods`}
            value={payment.payment_method}
            disabled={isMethodLocked || is_locked}
            otherParams={{
              document_id: doc?.id,
              document_type: doc_type,
            }}
            inputClassName={clsx({
              'bg-gray-light': isRemotePayment,
            })}
            getOptionValue={(x: Option) => x.value}
            getOptionLabel={(x: Option) => x.label}
            onChange={r =>
              dispatch(updatePayment(payment, 'payment_method', r))
            }
            errors={validationErrors[`payments.${index}.payment_method`]}
          />
        </Col>
        <Col>
          <TextInput
            label={t(...messages.reference())}
            value={payment.reference}
            disabled={is_locked}
            className={clsx({
              'bg-gray-light': isRemotePayment,
            })}
            onChange={r => dispatch(updatePayment(payment, 'reference', r))}
            errors={validationErrors[`payments.${index}.reference`]}
          />
        </Col>
        <Col>
          <TextInput
            type="number"
            label={t(...messages.amount())}
            disabled={isMethodLocked || is_locked}
            value={payment.amount}
            className={clsx({
              'bg-gray-light': isRemotePayment,
            })}
            onChange={r => dispatch(updatePayment(payment, 'amount', r))}
            errors={validationErrors[`payments.${index}.amount`]}
          />
        </Col>
        <Col md="auto">
          <EmptyLabel />
          <PaymentRemovalButton id={payment.id} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentItem;

const Container = styled.div`
  .form-label {
    margin-bottom: 0;
  }
`;
