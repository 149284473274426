import {ObjectId} from 'types/object-id';

export default <T extends {id: ObjectId}>(arr: T[]): Record<ObjectId, T> => {
  const record: Record<ObjectId, T> = {};
  if (!arr?.length) {
    return record;
  }
  for (const item of arr) {
    record[item.id] = item;
  }
  return record;
};
