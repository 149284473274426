import React from 'react';
import CategoryActions from './location-actions';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import {DirectoryCategory} from 'types/directory-category';
import Stocklocation from 'features/stock/types/stock-location';
import StockLocationActions from './location-actions';

const columns: ColumnDef<Stocklocation>[] = [
  {
    header: r => <Trans i18nKey="stockLocationPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockLocationPage.category" />,
    accessorFn: row => row.stock_location_category?.name ?? '',
    id: 'stock_location_category',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockLocationPage.action" />,
    enableSorting: false,
    accessorFn: row => <StockLocationActions stockLocation={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
