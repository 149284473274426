import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import {IContactForm} from '../use-contacts-form';

const ContactsFilters: FC<Props> = ({regroupedContacts}) => {
  const {t} = useTranslation();

  return (
    <Row className="align-items-center mb-3">
      <Col md={12}>
        <div className="mb-3">
          <div className="d-flex flex-wrap gap-2">
            {regroupedContacts.map((element, index) => (
              <Button
                key={index}
                color={'transparent'}
                active={false}
                size="sm"
                className=" border-1 border-bottom-0 font-size-14 text-center px-3 py-2 border-gray-light"
                // onClick={() => handleClick(c)}
              >
                {t(
                  `editProjectContactsSection.${element.type.toLocaleLowerCase()}`
                )}
                <span
                  className="badge ms-1 font-size-16 text-white bg-important "
                  style={{backgroundColor: '#c9f2d6'}}
                >
                  {element.data.length}
                </span>
              </Button>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

type Props = Pick<IContactForm, 'regroupedContacts'>;

export default ContactsFilters;
