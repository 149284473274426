import React, {FC} from 'react';
import {IAttachmentsForm} from '../use-attachments-form';
import {CardHeader, CardTitle} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import messages from '../messages';

export const HeaderSection: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <CardHeader>
      <CardTitle tag="h1">{t(...messages.attachments())}</CardTitle>
    </CardHeader>
  );
};

type Props = IAttachmentsForm;
