import {ModalParams} from '../types';
import {uiActions} from '../ui-slice';
import {MODAL} from 'features/modal-notch/constants';

const showModal = <T extends MODAL, P extends ModalParams[T]>(
  type: T,
  params?: P
) => {
  return uiActions.showModal({
    modalType: type,
    params,
  });
};

export default showModal;
