import React from 'react';
import ProductMaterActions from './product-master-actions';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Product} from 'types/product';
import {ColumnDef} from '@tanstack/react-table';

const columns: ColumnDef<Product>[] = [
  {
    header: r => <Trans i18nKey="productPage.name" />,
    accessorFn: row => (
      <Link to={`/dashboard/products/${row.id}`} target="_blank">
        <EmptyCell cell={row.name?.toLocaleUpperCase()} />
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    id: 'name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="productPage.collectionName" />,
    accessorFn: row => <EmptyCell cell={row.product_collection.name} />,
    id: 'product_collection_name',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="productPage.familyName" />,
    accessorFn: row => <EmptyCell cell={row.product_families[0].name} />,
    id: 'product_families_name',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="productPage.variantCount" />,
    accessorFn: row =>
      row.product_variant_count ? row.product_variant_count : '0',
    id: 'product_variant_count',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: false,
  },

  {
    header: r => <Trans i18nKey="productPage.actions" />,
    accessorFn: row => <ProductMaterActions product={row} />,
    id: 'actions',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: false,
  },
];

export default columns;
