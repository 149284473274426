import React from 'react';
import LoadRunner from 'components/load-runner';

const FormSectionSkeletonoader = () => (
  <div className=" mt-4 px-2 pt-2 ">
    <LoadRunner speed={2} width="100%" height={400} viewBox="0 0 100% 180">
      <rect x="0" y="10" rx="5" ry="5" width="100%" height="40" />
      <rect x="0" y="70" rx="5" ry="5" width="100%" height="40" />
      <rect x="0" y="130" rx="5" ry="5" width="100%" height="40" />
      <rect x="0" y="185" rx="5" ry="5" width="100%" height="40" />
      <rect x="0" y="240" rx="5" ry="5" width="100%" height="40" />
      <rect x="0" y="320" rx="5" ry="5" width="50%" height="50" />
      <rect x="600" y="320" rx="5" ry="5" width="50%" height="50" />
    </LoadRunner>
  </div>
);

export default FormSectionSkeletonoader;
