import React, {FC} from 'react';
import TransferTableRow from './transfer-table-row';
import {useTranslation} from 'react-i18next';
import TransferTableHeader from './transfer-table-header';
import {Table} from 'reactstrap';
import {useSelector} from 'react-redux';
import {selectQuotation} from '../../store/endpoints/show-quotation';
import denormalizeObject from 'utils/denormalize-object';
import {ObjectId} from 'types/object-id';

interface Props {
  id?: ObjectId;
  items: Record<ObjectId, boolean>;
  onUpdateItem: (id: ObjectId) => void;
  onToggleAll?: any;
  isAllSelected: boolean;
}

const TransferTable: FC<Props> = props => {
  const {id, onUpdateItem, items, isAllSelected, onToggleAll} = props;

  const {t} = useTranslation();

  const quote = useSelector(selectQuotation({id}));

  const obj = quote.data?.data.commercial_products_objectified;

  const allItems = denormalizeObject(obj);

  return (
    <div className="table-responsive">
      <Table>
        <TransferTableHeader
          isAllSelected={isAllSelected}
          onToggleAll={onToggleAll}
        />
        <tbody>
          {allItems.map((item, index) => (
            <TransferTableRow
              onToggle={() => onUpdateItem(item.id)}
              isSelected={items[item.id]}
              key={item.id}
              item={item}
              index={index}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TransferTable;
