import React, {FC, useContext} from 'react';
import deleteOptionsByProduct from '../../store/actions/delete-options-by-product';
import updateItemVariant from '../../store/actions/update-item-variant';
import {CommercialProduct} from '../../types/commercial-product';
import documentContext from '../document-context/document-context';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import getVariantName from 'utils/row-getters/get-variant-name';

interface Props {
  index: number;
  item: CommercialProduct;
  dragHandleProps?: any;
}

const VariantPicker: FC<Props> = props => {
  const {item, index} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdateVariant = (v: CommercialProduct['variant']) => {
    dispatch(deleteOptionsByProduct(item.id));
    dispatch(updateItemVariant(item, v));
  };

  const {validationErrors, doc, doc_type} = useContext(documentContext);

  return (
    <AsyncItemPicker
      label={t(...messages.product())}
      endPoint="data/commercial/product-variants"
      errors={validationErrors[`products.${index}.product_variant_id`]}
      value={item.variant}
      otherParams={{
        document_category_id: doc?.document_category?.id,
        document_type: doc_type,
        document_id: doc?.id,
      }}
      cacheOptions={false}
      getOptionLabel={getVariantName}
      isClearable={false}
      onChange={p => handleUpdateVariant(p)}
    />
  );
};

export default VariantPicker;
