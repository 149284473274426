import React, {useEffect, useState, useCallback} from 'react';
import {FC, Fragment} from 'react';
import EmptyState from './empty-state';
import messages from './messages';
import ResultItems from './result-items';
import SearchSkeleton from './search-skeleton';
import './style.scss';
import debounce from 'lodash/debounce';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalHeader, Row} from 'reactstrap';
import {useLazyGetGlobalSearchQuery} from 'store/global/endpoints/global-search';
import {Search} from 'store/global/types';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import TextInput from 'components/text-input';
import Button from 'components/button';

const GlobalSearchModal: FC<Props> = props => {
  const {onDismiss} = props;
  const {value} = useSelector(selectActiveModalParams);
  const [search, setSearch] = useState(value || '');
  const [result, setResult] = useState<Search[]>([]);
  const {t} = useTranslation();
  const [submit, {isFetching}] = useLazyGetGlobalSearchQuery();
  const handleChanchangeSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleSearch = async () => {
    const result = await submit({search});
    setResult(result.data?.data || []);
  };

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      handleSearch();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <div className="mb-3 d-flex">
            <input
              ref={inputRef}
              onKeyDown={(e: any) => handleKeyboardEventDown(e)}
              type="text"
              className="form-control rounded-end-0 "
              value={search}
              onChange={handleChanchangeSearch}
              placeholder={t(...messages.placeholder())}
            />
            <Button
              className="align-self-end rounded-start-0 "
              isLoading={isFetching}
              color="secondary"
              onClick={handleSearch}
            >
              {!isFetching && <i className="bx bx-search-alt align-middle"></i>}
            </Button>
          </div>
          {isFetching ? (
            <SearchSkeleton />
          ) : result.length > 0 ? (
            <div className="scroll-modal">
              {result.map(
                (item: Search) =>
                  item.result.length > 0 && (
                    <Row key={item.label}>
                      <ResultItems item={item} onDismiss={onDismiss} />
                    </Row>
                  )
              )}
            </div>
          ) : (
            <EmptyState></EmptyState>
          )}
        </div>
      </ModalBody>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
  value?: string;
}

export default GlobalSearchModal;
