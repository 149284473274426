import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  comment: () => __(translations.productsGeleralPage.comment, 'Comment'),
  create: () => __(translations.productsGeleralPage.create, 'New product'),
  pageTitle: () => __(translations.productsGeleralPage.pageTitle, 'New product'),
  productionTime: () => __(translations.productsGeleralPage.productionTime, 'Production time'),
  products: () => __(translations.productsGeleralPage.products, 'Products'),
  saveAs: () => __(translations.productsGeleralPage.saveAs, 'Save as'),
  stockMax: () => __(translations.productsGeleralPage.stockMax, 'Stock max'),
  stockMin: () => __(translations.productsGeleralPage.stockMin, 'Stock min'),
  collection: () => __(translations.productsGeleralPage.collection, 'Collection'),
  name: () => __(translations.productsGeleralPage.name, 'Name'),
  description: () => __(translations.productsGeleralPage.description, 'Description'),
  vat: () => __(translations.productsGeleralPage.vat, 'V.A.T'),
  inCatalog: () => __(translations.productsGeleralPage.inCatalog, 'in Catalog'),
  family: () => __(translations.productsGeleralPage.family, 'Family'),
};
