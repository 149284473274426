import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductCollection} from 'types/product-collection';
import {ResponseData} from 'types/response-data';
import queryParams from 'utils/query-params';

const getProductTotals = api.injectEndpoints({
  endpoints: builder => ({
    getProductTotals: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `product-variants/totals`,
          params: queryParams(params, '|'),
        };
      },
    }),
  }),
});

type Request = {};

type Response = ResponseData<{
  product_count: number;
  variant_count: number;
}>;

export const {useGetProductTotalsQuery} = getProductTotals;
