import {Order} from 'features/commercial/orders/types/order';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const moveOrderTo = api.injectEndpoints({
  endpoints: builder => ({
    moveOrderTo: builder.mutation<Response, MoveOrderRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `calendar/${params.id}/move`,
          body: {
            to: params.to,
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'calendarOrder', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type MoveOrderRequest = {
  id?: ObjectId;
  to?: string;
};

type Response = ResponseData<Order>;

export const {useMoveOrderToMutation} = moveOrderTo;
