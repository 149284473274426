import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  boxed: () => __(translations.righSideBar.boxed, 'Boxed'),
  compact: () => __(translations.righSideBar.compact, 'Compact'),
  dark: () => __(translations.righSideBar.dark, 'Dark'),
  default: () => __(translations.righSideBar.default, 'Default'),
  fixed: () => __(translations.righSideBar.fixed, 'Fixed'),
  fluid: () => __(translations.righSideBar.fluid, 'Fluid'),
  language: () => __(translations.righSideBar.language, 'Language'),
  layoutMode: () => __(translations.righSideBar.layoutMode, 'Layout Mode'),
  layoutPosition: () => __(translations.righSideBar.layoutPosition, 'Layout Position'),
  layoutWidth: () => __(translations.righSideBar.layoutWidth, 'Layout Width'),
  light: () => __(translations.righSideBar.light, 'Light'),
  scrollable: () => __(translations.righSideBar.scrollable, 'Scrollable'),
  sidebarSize: () => __(translations.righSideBar.sidebarSize, 'Sidebar Size'),
  small: () => __(translations.righSideBar.small, 'Small (Icon View)'),
};
