import React, {FC} from 'react';
import clsx from 'clsx';
import Currency from 'components/currency/currency';
import {Trans} from 'react-i18next';
import CostumeCurrency from 'components/costume-currency/costume-currency';

interface Props {
  doc: {
    with_vat?: boolean;
    is_duty_free?: boolean;
    total_ttc?: number | string;
    currency_symbole?: string;
  };
}

const TtcColumn: FC<Props> = props => {
  const {doc} = props;

  return (
    <div>
      {doc.with_vat ? (
        <span className={clsx({'text-info': doc?.is_duty_free})}>
          <CostumeCurrency value={doc?.total_ttc} symbol={doc?.currency_symbole}  />
        </span>
      ) : (
        <span>
          <Trans i18nKey="quotationsPage.detaxed" />
        </span>
      )}
    </div>
  );
};

export default TtcColumn;
