import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  arrival: () => __(translations.transferTable.arrival, 'Arrival'),
  departure: () => __(translations.transferTable.departure, 'Departure'),
  generate: () => __(translations.transferTable.generate, 'Generate'),
  label: () => __(translations.transferTable.label, 'Label'),
  quantity: () => __(translations.transferTable.quantity, 'Quantity'),
  sku: () => __(translations.transferTable.sku, 'SKU'),
  supplier: () => __(translations.transferTable.supplier, 'Supplier'),
  transfer: () => __(translations.transferTable.transfer, 'Transfer'),
};
