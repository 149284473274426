import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  actions: () => __(translations.actionVariantPage.actions, 'Actions on variant'),
  duplicateVariantTitle: () => __(translations.actionVariantPage.duplicate, 'Deplucat variant'),
  duplicateVariantDescription: () =>
    __(
      translations.actionVariantPage.duplicateDetails,
      'This is useful if you want to create another variant that has the same properties as the current one, and make some minor changes to it (e.g color, size, dimensions…)'
    ),
  duplicateAlertMessage: () => __(translations.actionVariantPage.duplicateAlertMessage, 'are you sure you want to duplicate this variant?'),
  deleteVariantTitle: () => __(translations.actionVariantPage.delete, 'Delete variant'),
  deleteVariant: () => __(translations.common.delete, 'Delete'),
  deleteVariantDescription: () =>
    __(translations.actionVariantPage.deleteDetails, 'This deletes the product and all its variants permanently. Once this is done, there is no going back. Please be certain.'),
  deleteAlertMessage: () => __(translations.actionVariantPage.deleteAlertMessage, 'are you sure you want to delete this variant?'),
  archiveTitle: () => __(translations.actionVariantPage.archiveTitle, 'Archive variant'),
  archiveVariant: () => __(translations.common.archive, 'Archive'),
  archiveDetails: () => __(translations.actionVariantPage.archiveDetails, 'Archive variant will move this variant to old collection/ patrimoine IMH  list'),
  archiveAlertMessage: () => __(translations.actionVariantPage.archiveAlertMessage, 'are you sure you want to archive this variant?'),
  blockedTitle: () => __(translations.actionVariantPage.blocked, 'Block variant'),
  blockedVariant: () => __(translations.common.blocked, 'Block'),
  unblockedVariant: () => __(translations.common.unblocked, 'Unblock'),
  blockedDetails: () => __(translations.actionVariantPage.blockedDetails, 'This will block this variant'),
  blockAlertMessage: () => __(translations.actionVariantPage.blockAlertMessage, 'are you sure you want to block this variant?'),
  toOldCollectionTitle: () => __(translations.actionVariantPage.toOldCollectionTitle, 'Move to old collection'),
  toOldCollection: () => __(translations.actionVariantPage.toOldCollection, 'Add'),
  toOldCollectionDetails: () => __(translations.actionVariantPage.toOldCollectionDetails, 'This will move this variant to old collection/ patrimoine IMH  list'),
  toOldCollectionAlertMessage: () => __(translations.actionVariantPage.toOldCollectionAlertMessage, 'are you sure you want to move this variant to old collection?'),
  outOfOldCollectionTitle: () => __(translations.actionVariantPage.outOfOldCollectionTitle, 'Remove from old collection'),
  outOfOldCollection: () => __(translations.actionVariantPage.outOfOldCollection, 'Remove'),
  outOfOldCollectionDetails: () => __(translations.actionVariantPage.outOfOldCollectionDetails, 'This will remove this variant from old collection/ patrimoine IMH  list'),
  outOfOldCollectionAlertMessage: () => __(translations.actionVariantPage.outOfOldCollectionAlertMessage, 'are you sure you want to remove this variant from old collection?'),
};
