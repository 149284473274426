import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Email, Phone} from 'types/phone';
import {ResponseData} from 'types/response-data';

const updateCoordinate = api.injectEndpoints({
  endpoints: builder => ({
    updateCoordinate: builder.mutation<Response, UpdatePhoneRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `directories/${params.id}/phone-emails`,
          body: {
            id: params.id,
            deleted_email_records: params.deletedEmailRecords,
            deleted_phone_numbers_records: params.deletedPhoneRecords,
            directory_emails: params.emails.map(x => ({
              id: x.id,
              comment: x.comment,
              email: x.email,
              is_main: x.id === params.mainEmailId,
              type: x.type?.value,
            })),
            phone_numbers: params.phones.map(x => ({
              id: x.id,
              comment: x.comment,
              number: x.number,
              is_main: x.id === params.mainPhoneId,
              type: x.type?.value,
              code: x.code?.value,
            })),
          },
        };
      },
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;
          dispatch(updateCachedDirectoryFromResource(directory));
        } catch (e) {}
      },
    }),
  }),
});

export type UpdatePhoneRequest = {
  id?: ObjectId;
  deletedEmailRecords: ObjectId[];
  deletedPhoneRecords: ObjectId[];
  phones: Phone[];
  emails: Email[];
  mainEmailId?: ObjectId;
  mainPhoneId?: ObjectId;
};

type Response = ResponseData<Directory>;

export const {useUpdateCoordinateMutation} = updateCoordinate;
