import {MonitoringListingTypes} from '../types/monitoring-listing-types';

export const NAV_LINKS: {
  title: string;
  type: MonitoringListingTypes;
}[] = [
  {
    title: 'mobilierCM',
    type: 'v_client',
  },
  {
    title: 'mobilierStock',
    type: 'v_stock',
  },
  {
    title: 'petitsObjetsCM',
    type: 'z_client',
  },
  {
    title: 'petitsObjetsStock',
    type: 'z_stock',
  },
  {
    title: 'transport',
    type: 'transport',
  },
];
