import React from 'react';
import OrderGeneralInformation from './general-informations';
import {useParams} from 'react-router';
import {Col, Row} from 'reactstrap';

const GeneralInfoTab = () => {
  const {id} = useParams();

  return (
    <Row>
      <Col>
        <OrderGeneralInformation />
      </Col>
    </Row>
  );
};

export default GeneralInfoTab;
