import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  toOrder: () => __(translations.transferQuoteModal.toOrder, 'To order'),
  areYouSure: () => __(translations.transferQuoteModal.areYouSure, 'Are you sure?'),
  toInvoice: () => __(translations.transferQuoteModal.toInvoice, 'To invoice'),
  cannotEditAnymore: () => __(translations.transferQuoteModal.cannotEditAnymore, 'You will not be able to edit this anymore'),
  transfer: () => __(translations.transferQuoteModal.transfer, 'Transfer'),
};
