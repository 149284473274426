import React from 'react';
import messages from '../messages';
import CreateContactForm from './create-contact-form';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

const CreateContactPage = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {
              title: t('directoryPage.pageTitle'),
              path: '/dashboard/directories',
            },
            {title: t(...messages.pageTitle()), path: '#'},
          ]}
        />
        <CreateContactForm />
      </Container>
    </div>
  );
};

export default CreateContactPage;
