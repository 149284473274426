import React from 'react';
import messages from '../messages';
import './variants-list-styles.scss';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import AsyncTagSelect from 'components/async-tag-select/async-tag-select';
import LoadRunner from 'components/load-runner';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {NavLink, useSearchParams} from 'react-router-dom';
import {Button, Card, CardBody, CardTitle} from 'reactstrap';
import {Collapse, FormGroup, Label, Row} from 'reactstrap';
import {
  VariantsListRequest,
  useGetProductVariantsQuery,
} from 'store/production/endpoints/get-product-variants';
import {useLocalStorage} from 'usehooks-ts';

type Filter = keyof VariantsListRequest;

const VariantsList = () => {
  const {id} = useParams();

  const [v, tt] = useLocalStorage('is-filters-variants-open', false);

  const [searchParams, setSearchParams] = useSearchParams();

  const getFilterValue = (k: Filter) => {
    return searchParams.get(k);
  };
  const {data, isFetching} = useGetProductVariantsQuery(
    {
      id,
      'filter[product_variant_category_name]': getFilterValue(
        'filter[product_variant_category_name]'
      ),
      'filter[is_old_collection]': !!Number(
        getFilterValue('filter[is_old_collection]')
      ),
      'filter[is_blocked]': !!Number(getFilterValue('filter[is_blocked]')),
    },
    {refetchOnMountOrArgChange: true}
  );

  const prefix = `/dashboard/products/${id}/variants`;

  const {t} = useTranslation();

  const variants = data?.data ?? [];

  const bones = Array(3).fill(1);

  const onChangeFilter = (k: Filter, v: any) => {
    if (!v) {
      searchParams.delete(k);
    } else {
      searchParams.set(k, v);
    }
    setSearchParams(searchParams);
  };

  const hasSelectedCategory = getFilterValue(
    'filter[product_variant_category_name]'
  );

  const selectedCategory = hasSelectedCategory
    ? getFilterValue('filter[product_variant_category_name]')
    : t(...messages.collectionFilterDefault());

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="font-size-16">{t(...messages.variants())}</h5>
            <Button
              onClick={() => tt(!v)}
              color="link"
              className={clsx({'me-2': 1, 'text-muted': !v})}
            >
              <Icon name="sliders" size={12} />
            </Button>
          </div>
          <Collapse isOpen={v}>
            {/* <Row>
              <FormGroup>
                <AsyncTagSelect
                  endPoint="data/product-variants-categories"
                  label={t(...messages.category())}
                  optionLabel=""
                  isClearable={false}
                  onChange={o => {
                    onChangeFilter('filter[product_variant_category_name]', o);
                  }}
                  value={
                    getFilterValue('filter[product_variant_category_name]') ??
                    selectedCategory
                  }
                />
              </FormGroup>
            </Row> 
            <Row>
              <FormGroup>
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_old_collection"
                    checked={
                      getFilterValue('filter[is_old_collection]') === '1'
                    }
                    onChange={(o: any) =>
                      onChangeFilter(
                        'filter[is_old_collection]',
                        o.target?.checked ? '1' : '0'
                      )
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="is_old_collection"
                  >
                    {t(...messages.isoldCollection())}
                  </Label>
                </div>
              </FormGroup>
            </Row> */}
            <Row>
              <FormGroup>
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_blocked"
                    checked={getFilterValue('filter[is_blocked]') === '1'}
                    onChange={(o: any) =>
                      onChangeFilter(
                        'filter[is_blocked]',
                        o.target?.checked ? '1' : '0'
                      )
                    }
                  />
                  <Label className="form-check-label" htmlFor="is_blocked">
                    {t(...messages.isBlocked())}
                  </Label>
                </div>
              </FormGroup>
            </Row>
          </Collapse>
        </CardTitle>

        <div className="list-group list-group-flush variant-list ">
          {isFetching && (
            <div className="">
              {bones.map((x, i) => (
                <div key={i}>
                  <LoadRunner
                    speed={1}
                    width={340}
                    height={84}
                    viewBox="0 0 340 84"
                  >
                    <rect x="9" y="4" rx="0" ry="0" width="320" height="22" />
                    <rect x="18" y="14" rx="0" ry="0" width="303" height="6" />
                    <rect x="11" y="33" rx="0" ry="0" width="108" height="13" />
                    <rect x="129" y="33" rx="0" ry="0" width="60" height="13" />
                    <rect x="196" y="33" rx="0" ry="0" width="60" height="13" />
                  </LoadRunner>
                </div>
              ))}
            </div>
          )}

          {!isFetching &&
            variants.map(v => (
              <NavLink
                key={v.id}
                to={`${prefix}/${v.id}/general?${searchParams.toString()}`}
                className={({isActive}) =>
                  `list-group-item ${
                    isActive ? 'bg-important text-white' : ''
                  } list-group-item-action`
                }
              >
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0 me-3">
                    <div className="avatar-title bg-soft-light text-light rounded-circle font-size-22">
                      <img src={v.cover?.square} width={32} height={32} />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5
                        style={{color: 'inherit'}}
                        className="font-size-14  mb-1 text-nowrap text-uppercase"
                      >
                        {v.name}
                      </h5>
                      {/*   <p className="font-size-13 text-muted mb-0">
                        {v.finition}
                      </p> */}
                    </div>
                  </div>
                </div>
              </NavLink>
            ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default VariantsList;
