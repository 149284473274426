import React, {FC, useState} from 'react';
import messages from '../messages';
import {OverpaymentFilters} from './types';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {FilterMap} from 'features/table/slice/types';
import {useLazyGetOverpaymentsExportQuery} from 'pages/accounting/store/endpoints/export-overpayments';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';

interface Props {
  filters: FilterMap<OverpaymentFilters>;
}

const Actions: FC<Props> = props => {
  const {filters} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [submitOverpayments, {isFetching: isLoadingOverpayements}] =
    useLazyGetOverpaymentsExportQuery();

  const handleSubmitOverpayments = async (filters: any) => {
    const result = await submitOverpayments(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  return (
    <div>
      <AppBarActions>
        {/* Action */}
        <Button
          color="light"
          isLoading={isLoadingOverpayements}
          onClick={() => handleSubmitOverpayments(filters)}
        >
          {t(...messages.export())}
        </Button>
      </AppBarActions>
    </div>
  );
};

export default Actions;
