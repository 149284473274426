import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import Button from 'components/button';
import StockSublocation from 'features/stock/types/stock-sub-location';
import Can from 'features/hylian-shield/can';

interface Props {
  stockSubLocation: StockSublocation;
}

const StockSubLocationActions: FC<Props> = props => {
  const {stockSubLocation} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();
  const handleDestroy = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: stockSubLocation.id,
        name: 'stock-sub-locations',
      })
    );
  };

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.UPDATE_STOCK_SUB_LOCATION, {
        stockSubLocation,
      })
    );
  };

  const hasActions = true;
  return (
    <div className="d-flex align-items-center h-100">
      <Can action="edit" subject="stock">
        <Button
          tag="button"
          className="me-2"
          color="transparent"
          onClick={handleEdit}
        >
          <Edit size={20} />
        </Button>
        <Button onClick={handleDestroy} color="transparent">
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default StockSubLocationActions;
