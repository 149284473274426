import React, {FC} from 'react';
import {
  Badge,
  Button,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import TextInput from 'components/text-input';
import ICommissionsForm from '../use-commissions-form';
import {Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import AsyncItemPicker from 'components/async-item-picker';
import {Invoice} from 'features/commercial/invoices/types/invoice';
import messages from '../messages';
import {Link} from 'react-router-dom';
import Icon from '@ailibs/feather-react-ts';
import {useParams} from 'react-router';
import Can from 'features/hylian-shield/can';
import useAbility from 'features/hylian-shield/use-ability';

const BodySection: FC<Props> = ({
  params,
  validationErrors,
  subject,
  setParams,
  handleChangeRow,
  handleRemoveRow,
  handleSelectInvoice,
}) => {
  const {t} = useTranslation();
  const {commissions} = params;

  const {id: project_id} = useParams();

  const {checkAbility} = useAbility();

  return (
    <CardBody>
      <div className="mb-4">
        <TextInput
          type="textarea"
          value={params.commissions_global_comment}
          label={t(...messages.comment())}
          onChange={commissions_global_comment =>
            setParams({...params, commissions_global_comment})
          }
        />
      </div>
      <hr />
      {commissions.length > 0 ? (
        <>
          {commissions.map((item, index) => (
            <div key={index}>
              <Row>
                <Row>
                  <div className="d-flex justify-content-end">
                    <Badge
                      color={
                        !item?.billing_date && !item?.payment_date
                          ? 'black'
                          : item?.billing_date && !item?.payment_date
                          ? 'danger'
                          : 'success'
                      }
                      className="py-1 px-2"
                    >
                      {!item?.billing_date && !item?.payment_date
                        ? t(...messages.toInvoice())
                        : item?.billing_date && !item?.payment_date
                        ? t(...messages.pending())
                        : t(...messages.paid())}
                    </Badge>
                  </div>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex gap-1">
                      <div className="flex-grow-1">
                        <div>
                          <AsyncItemPicker
                            endPoint={`data/invoices?from_commissions&project_id=${project_id}`}
                            getOptionLabel={(val: Invoice) => (
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{zIndex: -1}}
                              >
                                <div>{val.document_ref}</div>
                                <div style={{zIndex: 1}}>
                                  <Link
                                    target="_blank"
                                    to={`/dashboard/invoices/${val.id}/details`}
                                  >
                                    <Icon name="external-link" size={15} />
                                  </Link>
                                </div>
                              </div>
                            )}
                            value={item?.invoice}
                            errors={
                              validationErrors[`commissions.${index}.invoice`]
                            }
                            label={t(...messages.invoiceNumber())}
                            onChange={val => handleSelectInvoice(val, index)}
                          />
                        </div>
                      </div>
                      <Can action="edit" subject={subject}>
                        <Button
                          color="danger"
                          className="d-inline-block align-self-end"
                          id={`tooltip-${index.toString()}`}
                          onDoubleClick={() => handleRemoveRow(index)}
                        >
                          <Trash2 size={14} />
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`tooltip-${index.toString()}`}
                        >
                          {t('common.doubleClickToRemove')}
                        </UncontrolledTooltip>
                      </Can>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.natureOfTheGoods())}
                      value={item?.products}
                      errors={validationErrors[`commissions.${index}.products`]}
                      onChange={val => {
                        handleChangeRow(index, 'products', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.commission())}
                      type="text"
                      value={item?.commission}
                      errors={
                        validationErrors[`commissions.${index}.commission`]
                      }
                      onChange={val => {
                        handleChangeRow(index, 'commission', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.amountHT())}
                      type="number"
                      value={item?.total_ht}
                      errors={validationErrors[`commissions.${index}.total_ht`]}
                      onChange={val => {
                        handleChangeRow(index, 'total_ht', val);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <TextInput
                      disabled
                      label={t(...messages.invoiceDate())}
                      errors={
                        validationErrors[`commissions.${index}.billing_date`]
                      }
                      value={
                        item?.billing_date
                          ? new Date(item?.billing_date).toLocaleDateString()
                          : ''
                      }
                      onChange={() => {}}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.supplier())}
                      type="text"
                      value={item?.supplier}
                      errors={validationErrors[`commissions.${index}.supplier`]}
                      onChange={val => {
                        handleChangeRow(index, 'supplier', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      disabled
                      label={t(...messages.completionDate())}
                      errors={
                        validationErrors[`commissions.${index}.payment_date`]
                      }
                      value={item?.payment_date}
                      onChange={() => {}}
                    />
                  </Col>
                </Row>
              </Row>
              <hr
                className="mt-4"
                style={{
                  display: index < commissions.length - 1 ? 'block' : 'none',
                }}
              ></hr>
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-center py-4">
          <p>{t('common.noData')}</p>
        </div>
      )}
    </CardBody>
  );
};

type Props = ICommissionsForm;

export default BodySection;
