import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useAddressForm} from './use-address-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {ADDRESS_TYPES} from 'constants/address-types';
import guessVatModeByCountry from 'features/directory/utils/guess-vat-mode-by-country';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Option} from 'types/option';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';

const AddressForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useAddressForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  const {hideDefault} = useSelector(selectActiveModalParams);
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <ItemPicker
            options={Object.values(ADDRESS_TYPES)}
            onChange={address_type => setParams({...params, address_type})}
            value={params.address_type}
            // @ts-ignore
            getOptionLabel={x => t(x.name)}
            label={t(...messages.addressType())}
            errors={validationErrors.address_type}
          />
          {!hideDefault && (
            <div className="form-check my-3">
              <label className="form-check-label" htmlFor="form-check-left">
                {t(...messages.defaultAddress())}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="form-check-left"
                checked={params.is_default}
                onChange={ev =>
                  setParams({...params, is_default: ev.target.checked})
                }
              />
            </div>
          )}
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={label => setParams({...params, label})}
            value={params.label}
            label={t(...messages.name())}
            errors={validationErrors.name}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={first_line => setParams({...params, first_line})}
            value={params.first_line}
            label={t(...messages.firstLine())}
            errors={validationErrors.first_line}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={second_line => setParams({...params, second_line})}
            value={params.second_line}
            label={t(...messages.secondLine())}
            errors={validationErrors.second_line}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={third_line => setParams({...params, third_line})}
            value={params.third_line}
            label={t(...messages.thirdLine())}
            errors={validationErrors.third_line}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={zip_code => setParams({...params, zip_code})}
            value={params.zip_code}
            label={t(...messages.zipCode())}
            errors={validationErrors.zip_code}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={city => setParams({...params, city})}
            value={params.city}
            label={t(...messages.city())}
            errors={validationErrors.city}
          />
          <AsyncItemPicker
            endPoint="data/countries"
            onChange={country => {
              setParams({
                ...params,
                country,
                vat_mode: guessVatModeByCountry(country?.id),
              });
            }}
            value={params.country}
            label={t(...messages.country())}
            errors={validationErrors.country}
          />
          <AsyncItemPicker
            endPoint="data/vat-modes"
            onChange={vat_mode => setParams({...params, vat_mode})}
            getOptionLabel={(x: Option) => x.label}
            getOptionValue={(x: Option) => x.value}
            value={params.vat_mode}
            label={t(...messages.vatMode())}
            errors={validationErrors.vat_mode}
          />
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={comment => setParams({...params, comment})}
            type="textarea"
            value={params.comment}
            label={t(...messages.comment())}
            errors={validationErrors.comment}
          />

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default AddressForm;
