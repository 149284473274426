import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

export const getQuotationDefaultMailContent = api.injectEndpoints({
  endpoints: builder => ({
    getQuotationDefaultMailContent: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `/data/default-mail-content/for-quotation`,
        params,
      }),
    }),
  }),
});

type Request = {
  local: string;
};

type Response = ResponseData<{content: string}>;

export const {useGetQuotationDefaultMailContentQuery} =
  getQuotationDefaultMailContent;
