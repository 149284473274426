import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoiceGeneralInformation.title, 'General information'),
  client: () => __(translations.invoiceGeneralInformation.client, 'Client'),
  addNewCompanyClient: () => __(translations.invoiceGeneralInformation.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.invoiceGeneralInformation.addNewPrivateClient, 'Add Private'),
  clientReference: () => __(translations.invoiceGeneralInformation.clientReference, 'Client Reference'),
  changesCommited: () => __(translations.invoiceGeneralInformation.changesCommited, 'Your changes has been saved successfully!'),
  afterSalesServiceInvoice: () => __(translations.invoiceGeneralInformation.afterSalesServiceInvoice, 'After-sales service invoice'),
  invoiceBillingdate: () => __(translations.invoiceGeneralInformation.invoiceBillingDate, 'Invoice billing date'),
  invoiceDuedate: () => __(translations.invoiceGeneralInformation.invoiceDueDate, 'Invoice due date'),
  invoiceDeliveryDate: () => __(translations.invoiceGeneralInformation.invoiceDeliveryDate, 'Invoice delivery date'),
  invoiceType: () => __(translations.invoiceGeneralInformation.invoiceType, 'Invoice Type'),
  invoiceCategory: () => __(translations.invoiceGeneralInformation.invoiceCategory, 'Invoice Category'),
  invoiceOrigin: () => __(translations.invoiceGeneralInformation.invoiceOrigin, 'Origin'),
  invoiceLanguage: () => __(translations.invoiceGeneralInformation.invoiceLanguage, 'Language'),
  invoiceCurrency: () => __(translations.invoiceGeneralInformation.invoiceCurrency, 'Currency'),
  invoiceProject: () => __(translations.invoiceGeneralInformation.invoiceProject, 'Project'),
  invoiceCurrencyConversion: () => __(translations.invoiceGeneralInformation.invoiceCurrencyConversion, 'Currency conversion'),
  privateComment: () => __(translations.invoiceGeneralInformation.privateComment, 'Private comment'),
  projectFeesType: () => __(translations.invoiceGeneralInformation.projectFeesType, 'Project fees type'),
  designation: () => __(translations.invoiceGeneralInformation.designation, 'Designation'),
};
