import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.createDirectoryPage.pageTitle, 'Create Contact'),
  id: () => __(translations.createDirectoryPage.id, 'ID'),
  gender: () => __(translations.createDirectoryPage.gender, 'Gender'),
  first_name: () => __(translations.createDirectoryPage.first_name, 'First Name'),
  last_name: () => __(translations.createDirectoryPage.last_name, 'Last Name'),
  country: () => __(translations.createDirectoryPage.country, 'Country'),
  job: () => __(translations.createDirectoryPage.job, 'Job'),
  type: () => __(translations.createDirectoryPage.type, 'Type'),
  description: () => __(translations.createDirectoryPage.description, 'Description'),
  email: () => __(translations.createDirectoryPage.email, 'Email'),
  phone: () => __(translations.createDirectoryPage.phone, 'Phone'),
  name: () => __(translations.createDirectoryPage.name, 'Name'),
  address: () => __(translations.createDirectoryPage.address, 'Address'),
  category: () => __(translations.createDirectoryPage.category, 'Category'),
  website: () => __(translations.createDirectoryPage.website, 'Website'),
  company: () => __(translations.createDirectoryPage.company, 'Company'),
  family: () => __(translations.createDirectoryPage.family, 'Family'),
  addCompany: () => __(translations.createDirectoryPage.addCompany, 'Add Company'),
  comment: () => __(translations.createDirectoryPage.comment, 'Comment'),
  companyList: () => __(translations.createDirectoryPage.companyList, 'Company List'),
  exports: () => __(translations.createDirectoryPage.exports, 'Exports'),
  newsletter: () => __(translations.createDirectoryPage.newsletter, 'subscribed to newsletter'),
  project: () => __(translations.createDirectoryPage.project, 'Project'),
  referentContact: () => __(translations.createDirectoryPage.referentContact, 'Referent contact'),
  requirementRate: () => __(translations.createDirectoryPage.requirementRate, 'Requirement rate'),
  segment: () => __(translations.createDirectoryPage.segment, 'Segment'),
  turnover: () => __(translations.createDirectoryPage.turnover, 'Turnover'),
  official_studio_supplier: () => __(translations.createDirectoryPage.officialSupplierStudio, 'Official supplier studio'),
  official_furniture_supplier: () => __(translations.createDirectoryPage.officialSupplierFurniture, 'Official supplier furniture'),
  official_suppliers_small_items: () => __(translations.createDirectoryPage.officialSupplierSmallItems, 'Official supplier small items'),
};
