import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

export const getProductVariants = api.injectEndpoints({
  endpoints: builder => ({
    getProductVariants: builder.query<Response, VariantsListRequest>({
      query: params => ({
        method: 'GET',
        url: `products/${params.id}/variants`,
        params: {
          ...params,
          'filter[product_variant_category_name]':
            params['filter[product_variant_category_name]'] ?? undefined,
        },
      }),
    }),
  }),
});

export type VariantsListRequest = {
  id?: ObjectId;
  'filter[product_variant_category_name]'?: ObjectId | null;
  'filter[is_old_collection]'?: boolean;
  'filter[is_blocked]'?: boolean;
};

type Response = ResponseData<ProductVariant[]>;

export const updateCachedVariantFromResource = (variant: ProductVariant) => {
  return getProductVariants.util.updateQueryData(
    'getProductVariants',
    {id: String(variant.product_id)},
    x => {
      const r = x.data.findIndex(x => x.id === variant.id);
      if (r === -1) {
        x.data = x.data.concat(variant);
      } else {
        x.data[r] = variant;
      }
    }
  );
};
export const removeCachedVariantFromResource = (variant: ProductVariant) => {
  return getProductVariants.util.updateQueryData(
    'getProductVariants',
    {id: String(variant.product_id)},
    x => {
      x.data = x.data.filter(x => x.id !== variant.id);
    }
  );
};

export const {useGetProductVariantsQuery} = getProductVariants;
