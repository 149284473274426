import React, {Fragment, useState} from 'react';
import messages from './messages';
import {useAttachmentsForm} from './use-attachments-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import DragDropFile from 'components/dragndrop';
import ImageCropPicker from 'components/image-crop-picker';
import ImageInput from 'components/image-input';
import ItemPicker from 'components/item-picker';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Col, Dropdown, DropdownItem} from 'reactstrap';
import {DropdownMenu, DropdownToggle} from 'reactstrap';
import {Row} from 'reactstrap';
import {useUploadTemporaryFileMutation} from 'store/global/endpoints/upload-temporary-file';
import {DirectoryAttachment} from 'types/attachements';
import {Option} from 'types/option';
import {TemporaryFile} from 'types/temporary-file';
import {useToggle} from 'usehooks-ts';
import {now} from 'utils/now';
import Can from 'features/hylian-shield/can';

const AttachmentsTab = () => {
  const {
    addAttachments,
    attachments,
    isLoading,
    removeAttachments,
    submit,
    updateAttachment,
    validationErrors,
    directory,
    canEdit,
  } = useAttachmentsForm();

  const {t} = useTranslation();

  const [v, toggle] = useToggle();

  const [cropImage, showCropperFor] = useState<
    DirectoryAttachment | undefined
  >();

  const [sendTempFile, {isLoading: isUploadingTemp}] =
    useUploadTemporaryFileMutation({});

  const handleUploadImage = async (file: File) => {
    if (cropImage) {
      const tempFileResult = await sendTempFile({data: {file}});
      if ('data' in tempFileResult) {
        updateAttachment(cropImage, 'file', tempFileResult.data.data);
      }
    }
    showCropperFor(undefined);
  };

  return (
    <div>
      <Row className="mb-3">
        <Col md={9}>
          <SectionSeparator
            title={t(...messages.title(), {name: directory?.display_name})}
          />
        </Col>
        <Col md={3}>
          <Dropdown
            disabled={directory?.is_archived || !canEdit}
            isOpen={v}
            toggle={toggle}
            direction="down"
          >
            <DropdownToggle color="important" className="w-100">
              {t(...messages.add())}
              <Icon name="chevron-down" className="ms-1" size={15} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  addAttachments({
                    label: t(...messages.image()),
                    value: 'IMAGE',
                  });
                }}
              >
                {t(...messages.image())}
              </DropdownItem>
              <DropdownItem onClick={() => addAttachments()}>
                {t(...messages.file())}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>

      {Object.values(attachments).map((p, i) => (
        <Row className="mb-5 mt-4 d-flex justify-content-between  " key={p.id}>
          <Col>
            {p.type?.value === 'IMAGE' ? (
              <ItemPicker
                value={1}
                options={[{id: 1, name: t(...messages.image())}]}
                label={t(...messages.type())}
                isDisabled
                onChange={() => {}}
              />
            ) : (
              <AsyncItemPicker
                label={t(...messages.type())}
                className={clsx({'d-none': p.type?.value === 'IMAGE'})}
                filterOption={(x: any) => x?.value !== 'IMAGE'}
                endPoint="data/attachment-types"
                menuPlacement="top"
                value={p.type}
                errors={validationErrors[`attachements.${i}.type`]}
                getOptionLabel={(p: Option) => p.label}
                getOptionValue={(p: Option) => p.value}
                onChange={x => updateAttachment(p, 'type', x)}
              />
            )}
          </Col>
          <Col md={2}>
            <TextInput
              label={t(...messages.fileName())}
              type="text"
              errors={validationErrors[`attachements.${i}.name`]}
              value={p.name}
              onChange={x => updateAttachment(p, 'name', x)}
            />
          </Col>
          <Col className="align-items-center">
            {p.type?.value !== 'IMAGE' ? (
              <Fragment>
                <label htmlFor="" className="mb-0">
                  {t(...messages.file())}{' '}
                </label>
                <DragDropFile
                  file={p?.file}
                  errors={validationErrors[`attachements.${i}.file_id`]}
                  onChangeFile={(x: TemporaryFile) =>
                    updateAttachment(p, 'file', x)
                  }
                />
              </Fragment>
            ) : (
              <ImageInput
                file={p.file}
                isLoading={isUploadingTemp}
                errors={validationErrors[`attachements.${i}.file_id`]}
                onUploadClick={() => showCropperFor(p)}
                onChange={x => updateAttachment(p, 'file', x)}
              />
            )}
          </Col>

          <Col md={3}>
            <DateInput
              errors={validationErrors[`attachements.${i}.date`]}
              value={p.date ? p.date : now()}
              label={t(messages.date())}
              onChange={x => updateAttachment(p, 'date', x)}
            />
          </Col>

          <Col className="align-items-end d-flex" md={1}>
            {/* @ts-ignore*/}
            {/*    <AttachmentActions
              handleDelete={removeAttachments}
              attachment={p}
            /> */}
            <Button
              color="link"
              className="text-danger"
              onClick={() => removeAttachments(p.id)}
            >
              <Icon name="trash-2" size={18} />
            </Button>
          </Col>
        </Row>
      ))}

      <Row>
        <Col md={12} className="mb-4">
          {!directory?.is_archived && (
            <AppBarActions>
              <Can action="edit" subject="directory">
                <Button
                  isLoading={isLoading}
                  disabled={!canEdit}
                  color="important"
                  onClick={submit}
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </Can>
            </AppBarActions>
          )}
        </Col>
      </Row>
      <ImageCropPicker
        onCropFinished={handleUploadImage}
        onClose={() => showCropperFor(undefined)}
        isVisible={!!cropImage}
      />
    </div>
  );
};

export default AttachmentsTab;
