import React, {FC} from 'react';
import ItemMapper from '../../components/item-mapper/item-mapper';
import reorderItems from '../../store/actions/reorder-items';
import selectItems from '../../store/selectors/select-items';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import {DropResult, DragDropContext} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import arrayReorder from 'utils/array-reorder';

interface Props {}

const ItemsSection: FC<Props> = () => {
  const lines = useSelector(selectItems);

  const dispatch = useDispatch();

  const onDragEnd = (result: DropResult) => {
    const {destination, source} = result;

    if (!destination) {
      return;
    }

    const newList = arrayReorder(lines, source.index, destination.index);

    dispatch(reorderItems(newList));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {lines.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
              >
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <ItemMapper
                      item={item}
                      index={index}
                      isDragging={snapshot.isDragging}
                      dragHandleProps={provided.dragHandleProps}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ItemsSection;
