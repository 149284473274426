import makeSelectEntityRequest from 'features/table/slice/selectors/make-select-entity-request';
import {ProductPaginationMeta, ProductsFilters} from './types';
import {FilterMap} from 'features/table/slice/types';
import {useSelector} from 'react-redux';
import {useGetProductStockCountsQuery} from 'store/production/endpoints/get-product-stock-counts';
import {useGetProductTotalsQuery} from 'store/production/endpoints/get-product-totals';
import {ProductCollection} from 'types/product-collection';
import selectEntity from 'features/table/slice/selectors/select-entity';

export const useFilter = (filters: FilterMap<ProductsFilters>) => {
  const collection = (filters['filter[product_collection_id]'] ??
    []) as ProductCollection[];

  const collectionName = collection?.length === 1 ? collection[0].name : '';
  const {data} = useSelector(selectEntity('variants'));
  const {isLoading} = useSelector(makeSelectEntityRequest('variants'));

  const {data: totalsData, isFetching: isLoadingTotals} =
    useGetProductTotalsQuery(filters);
  const {data: stockCountsData, isFetching: isLoadingStockCounts} =
    useGetProductStockCountsQuery(filters, {
      skip: !data?.length || isLoading,
    });

  const totals = totalsData?.data;
  const stockCounts = stockCountsData?.data;

  return {
    collection,
    collectionName,
    stockCounts,
    totals,
    isLoadingStockCounts,
    isLoadingTotals,
  };
};
