import React from 'react';
import {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from 'reactstrap';
import {uiActions} from 'store/ui/ui-slice';
import {Directory} from 'types/directory';

interface Props {
  contact?: Directory;
  directory?: Directory;
}

export const ContactItem: FC<Props> = props => {
  const {contact, directory} = props;
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdateContact = () => {
    if (contact) {
      dispatch(
        uiActions.showModal({
          modalType: MODAL.UPDATE_LIST_OF_CONTACT,
          params: {
            contact: contact,
            directory: directory,
          },
        })
      );
    }
  };
  const handleDelete = () => {
    if (contact) {
      dispatch(
        uiActions.showModal({
          modalType: MODAL.DETTACH_CONTACT,
          params: {
            contact: contact,
            directory: directory,
          },
        })
      );
    }
  };

  const mail = contact?.main_directory_email?.email;

  return (
    <div className="col-sm-4 col-xl-3" style={{width: '400px'}}>
      <Card>
        <CardBody>
          <UncontrolledDropdown direction="start" className="text-end">
            <DropdownToggle className="" tag="i">
              <i className="bx bx-dots-horizontal-rounded" role="button" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="w-100" onClick={handleUpdateContact}>
                <i className="bx bx-edit-alt me-2" />
                <span className="align-middle ms-2">
                  {t(...messages.edit())}{' '}
                </span>
              </DropdownItem>
              <DropdownItem className="w-100" onClick={handleDelete}>
                <i className="bx bx-link text-danger  me-2" />
                <span className="align-middle ms-2">
                  {t(...messages.detach())}
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="d-flex align-items-start ">
            <div className="flex-grow-1">
              <div className="mb-3">
                <Link
                  to={`/dashboard/directories/${contact?.id}/general`}
                  className="fs-5"
                >
                  {contact?.display_name}
                </Link>
              </div>
              <Row>
                <Col md={10}>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.job())} </span>
                    <span className="ms-1">{contact?.job}</span>
                  </p>
                </Col>
                <Col md={10}>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.phone())}</span>
                    <span className="ms-1">{contact?.main_phone?.number}</span>
                  </p>
                </Col>
                <Col md={10}>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.email())}</span>
                    <span className="ms-1">
                      {mail ? <a href={`mailto:${mail}`}>{mail}</a> : null}
                    </span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
