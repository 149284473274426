import {AppTheme, LayoutState} from './types';
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  layoutTheme,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from 'constants/layout';

export const initialState: LayoutState = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  layoutMode: layoutTheme.LIGHTMODE as AppTheme,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: true,
  showRightSidebar: false,
  layoutPosition: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
};
