import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

export const updateAvatarEndpoint = api.injectEndpoints({
  endpoints: builder => ({
    updateAvatar: builder.mutation<ResponseData<User>, Request>({
      query: ({image, meta}) => ({
        url: 'me/profile/avatar',
        method: 'POST',
        body: {
          media_id: image,
          meta,
        },
      }),
    }),
  }),
});

type Request = {
  image: ObjectId;
  meta?: any;
};

export const {useUpdateAvatarMutation} = updateAvatarEndpoint;
