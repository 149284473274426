import Stocklocation from 'features/stock/types/stock-location';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';

import {ResponseData} from 'types/response-data';

const updateLocationCategory = api.injectEndpoints({
  endpoints: builder => ({
    updateLocationCategory: builder.mutation<
      Response,
      updateLocationCategoryRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `stock-location-categories/${params.id}`,
          body: {
            name: params.name,
          },
        };
      },
    }),
  }),
});

export type updateLocationCategoryRequest = {
  id: ObjectId;
  name: string;
};

type Response = ResponseData<Stocklocation>;

export const {useUpdateLocationCategoryMutation} = updateLocationCategory;
