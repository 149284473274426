import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  general: () => __(translations.invoicesConstants.general, 'General'),
  details: () => __(translations.invoicesConstants.details, 'Details'),
  relatedResources: () => __(translations.invoicesConstants.relatedResources, 'Related resources'),
  actions: () => __(translations.invoicesConstants.actions, 'Actions'),
  logs: () => __(translations.invoicesConstants.logs, 'Logs'),
};
