import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useAttachDirectoryMutation} from 'store/directory/endpoints/attach-directory';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAttachDirectoryForm = () => {
  const [submit, {isLoading, error}] = useAttachDirectoryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const fake = useSeeder();

  const [params, setParams] = useState({
    id: modalParams.id,
    company: undefined,
  });
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      const message = result.data?.data.message;
      dispatch(toast('success', message));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
