import ItemMemos from '../../components/item-memos/item-memos';
import ItemRemovalButton from '../../components/item-removal-button/item-removal-button';
import OtherOptionsButton from '../../components/other-options-button/other-options-button';
import DragHandleSpacer from '../../components/spacing/drag-handle-spacer';
import EmptyLabel from '../../components/spacing/empty-label';
import RemovalContainer from '../../components/spacing/removal-cross-container';
import RemoveContainer from '../../components/spacing/remove-container';
import ItemOptionsSection from '../../containers/item-options-section/item-options-section';
import updateItem from '../../store/actions/update-item';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import selectLineTotal from '../../store/selectors/select-line-total';
import {CommercialProduct} from '../../types/commercial-product';
import documentContext from '../document-context/document-context';
import DocumentIs from '../document-is/document-is';
import RelatedDocumentsSection from '../related-documents-section/related-documents-section';
import VariantPicker from '../variant-picker/variant-picker';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {useMediaQuery} from 'usehooks-ts';
import ItemLinkingSection from './item-linking-section';
import {currency} from '../../utils/currency-parser';
import StockMovementButton from '../stock-movement-button/stock-movement-button';
import {ProjectFeeTypeEnum} from '../../types/commercial-document';

interface Props {
  index: number;
  item: CommercialProduct;
  dragHandleProps?: any;
}

const ItemProduct: FC<Props> = props => {
  const {item, index, dragHandleProps} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(item, key, v));
  };

  const {validationErrors, is_locked, doc} = useContext(documentContext);

  const row = useSelector(selectLineTotal(item));

  const isMd = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)'
  );

  const isRemoteObject = useSelector(selectIsRemoteObject(item.id));

  const isSelected = !!item.variant?.id;

  const feeType = doc?.project_fee_type?.value;

  return (
    <div>
      <Row>
        <Col md="auto">
          <div>
            <Button
              disabled={is_locked}
              color="link"
              size="sm"
              className="dragHandle p-0"
              {...dragHandleProps}
            >
              <Icon name="menu" color="gray" size={20} />
            </Button>
          </div>
          <div>
            <OtherOptionsButton item={item} />
          </div>
        </Col>
        <Col>
          <Row>
            <Col md={5}>
              {isSelected ? (
                <>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <TextInput
                        label={t('commercialDocumentComponents.product')}
                        value={item.custom_name}
                        disabled={is_locked}
                        onChange={p => handleUpdate('custom_name', p)}
                      />
                      {!isRemoteObject && (
                        <RemoveContainer>
                          <RemovalContainer className="me-4">
                            <Icon
                              name="x"
                              className={is_locked ? 'd-none' : 'text-danger'}
                              onClick={() => handleUpdate('variant', null)}
                              size={15}
                            />
                          </RemovalContainer>
                        </RemoveContainer>
                      )}
                    </div>
                    <div className="align-items-center d-flex ms-2">
                      <Link
                        target="_blank"
                        to={`/dashboard/products/${item?.variant?.product?.id}/variants/${item?.variant?.id}/general`}
                      >
                        <Icon className="mt-3" name="external-link" size={15} />
                      </Link>
                    </div>
                  </div>

                  <ItemMemos item={item} />
                </>
              ) : (
                <VariantPicker item={item} index={index} />
              )}
            </Col>

            <Col md={1}>
              <EmptyLabel />
              <StockMovementButton item={item} />
            </Col>

            <Col md={isMd && 4}>
              <TextInput
                label={t(...messages.unitPrice())}
                value={item.unit_price_ht}
                type="number"
                disabled={is_locked}
                onChange={p => handleUpdate('unit_price_ht', p)}
                errors={validationErrors[`products.${index}.unit_price_ht`]}
              />
            </Col>
            <Col>
              <TextInput
                label={
                  feeType === ProjectFeeTypeEnum.SOURCING_FEE
                    ? t(...messages.commission())
                    : t(...messages.discount())
                }
                value={item.discount}
                type="number"
                disabled={is_locked}
                errors={validationErrors[`products.${index}.discount`]}
                onChange={p => handleUpdate('discount', p)}
              />
            </Col>
            {feeType !== ProjectFeeTypeEnum.SOURCING_FEE && (
              <Col>
                <TextInput
                  label={t(...messages.quantity())}
                  value={item.quantity}
                  disabled={is_locked}
                  errors={validationErrors[`products.${index}.quantity`]}
                  type="number"
                  onChange={q => handleUpdate('quantity', q)}
                />
              </Col>
            )}

            <Col md={isMd && 2}>
              <TextInput
                label={t(...messages.subTotal())}
                value={currency(
                  row.priceExclTaxes,
                  doc?.currency_symbole || ''
                )}
                readOnly
                disabled
                onChange={() => {}}
              />
            </Col>
            <Col className="" md="auto">
              <EmptyLabel />
              <ItemRemovalButton item={item} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="auto">
          <DragHandleSpacer />
        </Col>
        <Col>
          <Row>
            <Col md={4}>
              <Row></Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <ItemOptionsSection item={item} lineIndex={index} type="OPTION" />
              <DocumentIs oneOf={['order']}>
                {
                  doc?.document_type !== 'SUPPLIER' && (
                    <ItemOptionsSection
                      item={item}
                      lineIndex={index}
                      type="COMPONENT"
                    />
                  )}
              </DocumentIs>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md="auto">
          <DragHandleSpacer />
        </Col>
        <Col>
          <ItemLinkingSection item={item} index={index} />
        </Col>
        <Col>
          <DocumentIs oneOf={['order']}>
            {isRemoteObject && (
              <RelatedDocumentsSection
                item={item}
                index={index}
                docs={item.related_documents}
              />
            )}
          </DocumentIs>
        </Col>
      </Row>
    </div>
  );
};

export default ItemProduct;
