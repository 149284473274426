import {useEffect, useState} from 'react';
import {faker} from '@faker-js/faker';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateContactListMutation} from 'store/directory/endpoints/update-contact-list';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {CompactDirectory} from 'types/compact-directory';
import {Directory} from 'types/directory';
import {Option} from 'types/option';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useContactListForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateContactListMutation();

  const serverError = parseValidationErrors(error);
  const modalParams = useSelector(selectActiveModalParams);

  const {directory, contact} = modalParams;

  const [contactList, setContactList] = useState<CompactDirectory>({
    job: faker.name.jobTitle(),
  } as CompactDirectory);
  const [isdisabled, setIsdisabled] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      contact: contactList,
      id: directory.id,
    });
    if ('data' in result) {
      dispatch(uiActions.dismissModal());
    }
  };

  const updateContactList = (
    p: CompactDirectory,
    k: keyof CompactDirectory,
    v: any
  ) => {
    setContactList({...p, [k]: v});
  };

  useEffect(() => {
    if (contact) {
      setContactList({directory: contact, job: contact.job, id: contact.id});
      setIsdisabled(true);
    }
  }, [contact]);

  const filterOption = (option: Option<Directory>, inputValue: string) => {
    const isSelected = directory.contacts.some(
      (x: Directory) => x.id === option.value
    );

    if (isSelected) {
      return false;
    }
    const n = option.data?.display_name.toString() ?? '';
    return n.toLowerCase().includes(inputValue.toLowerCase());
  };

  return {
    submit: handleSubmit,
    updateContactList,
    contactList,
    errorMessage: serverError.message,
    isdisabled,
    isLoading,
    isSuccess,
    filterOption,
    validationErrors: serverError.errors,
  };
};
