import React from 'react';
import EmptyStateIllustration from 'assets/images/empty-state.svg';
import {Row, Col} from 'reactstrap';

const EmptyState = ({
  size = 'medium',
}: {
  size?: 'large' | 'medium' | 'small';
}) => {
  return (
    <div>
      <div className="justify-content-center mb-3">
        <div className="justify-content-center d-flex mb-2">
          {/* <img className="img-fluid" src={EmptyStateIllustration} width={size === 'medium' ? 120 : size === 'large' ? 180 : 60} /> */}
          <div style={{width: 120}} />
        </div>
        <div className="text-center text-muted">
          {size !== 'small' && `Aucune image n'est disponible`}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
