import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import RelatedDocument from 'types/related-document';

const linkOrderDocument = api.injectEndpoints({
  endpoints: builder => ({
    linkOrderDocument: builder.mutation<Response, LinkOrderDocumentRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `orders/${params.id}/commercial-products/${params.commercial_product_id}/link-order`,
          body: {
            model_id: params.target?.id,
            commercial_product_id: params.target?.commercial_product_id,
            model_type: params.target?.model_type,
            document_type: params.target?.document_type,
            document_class: params.target?.document_class,
          },
        };
      },
    }),
  }),
});

export type LinkOrderDocumentRequest = {
  commercial_product_id?: ObjectId;
  target?: RelatedDocument;
  id?: ObjectId;
};

type Response = ResponseData<RelatedDocument[]>;

export const {useLinkOrderDocumentMutation} = linkOrderDocument;

export const linkOrderDocumentEndpoint =
  linkOrderDocument.endpoints.linkOrderDocument;
