import showUserEndpoint from './show-user';
import storeUserEndpoint from './store';
import updateUserEndpoint from './update-user';
import updateUserPasswordEndpoint from './update-user-password';
import updateUserStatusEndpoint from './update-user-status';
import {api} from 'services/api';

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    updateUser: updateUserEndpoint(builder),
    storeUser: storeUserEndpoint(builder),
    showUser: showUserEndpoint(builder),
    updateUserStatus: updateUserStatusEndpoint(builder),
    updateUserPassword: updateUserPasswordEndpoint(builder),
  }),
  overrideExisting: false,
});

export const {
  useUpdateUserMutation,
  useStoreUserMutation,
  useShowUserQuery,
  useUpdateUserStatusMutation,
  useUpdateUserPasswordMutation,
} = userApi;
