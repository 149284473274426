import React, {FC} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import ProductsStockGrid from 'features/stock/products-stock-grid/containers/products-stock-grid/products-stock-grid';

interface Props {}

const ProductsStockPage: FC<Props> = props => {
  const {} = props;

  const {t} = useTranslation();

  return (
    <div>
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <div>
        <ProductsStockGrid />
      </div>
    </div>
  );
};

export default ProductsStockPage;
