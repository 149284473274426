import React, {FC, Fragment, useContext} from 'react';
import CostsContext from '../costs-context';
import messages from '../messages';
import {actions} from '../slice';
import updateCost from '../slice/actions/update-cost';
import selectCostItems from '../slice/selectors/select-cost-items';
import {CostField} from '../slice/types';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Label, Row, Col, Button} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {DirectoryProductVariant} from 'types/directory-product-variant';
import getDirectoryNameWithOverlap from 'utils/row-getters/get-directory-name-with-overlap';
import {Link} from 'react-router-dom';
import Can from 'features/hylian-shield/can';

interface Props {
  categoryId: 'development';
  index: number;
}

const Development: FC<Props> = props => {
  const {categoryId} = props;

  const {validationErrors} = useContext(CostsContext);

  const costs = useSelector(selectCostItems(categoryId));

  const vals = Object.values(costs);
  const dispatch = useDispatch();

  const handleUpdate = (id: ObjectId, field: CostField, value: string) => {
    dispatch(updateCost(id, categoryId, field, value));
  };

  const validate = (k: string, index: number) => {
    return validationErrors[`costs.${categoryId}.${index}.${k}`];
  };

  const {t} = useTranslation();
  return (
    <Fragment>
      <Row>
        <Col>
          {/* ROW MULTIPLIER */}
          <Label className="text-primary">
            <Trans i18nKey={`costsTab.${categoryId}`} />
          </Label>

          {vals.map((cost, i) => (
            <Row className="mb-3" key={cost.id}>
              <Col md={12}>
                <p>
                  <strong className="text-uppercase">
                    {cost.variant?.name}
                  </strong>
                </p>
              </Col>
              <Col md="2">
                <AsyncItemPicker
                  endPoint="data/cost-types"
                  getOptionValue={(x: Option) => x.id}
                  getOptionLabel={(x: Option) => x.name}
                  label={t(...messages.type())}
                  isClearable={false}
                  errors={validate('product_cost_type_id', i)}
                  value={cost.product_cost_type}
                  onChange={x => handleUpdate(cost.id, 'product_cost_type', x)}
                />
              </Col>
              <Col md="3">
                <AsyncItemPicker
                  endPoint="data/suppliers-type-company"
                  getOptionLabel={(
                    directoryVariant: DirectoryProductVariant
                  ) => {
                    return getDirectoryNameWithOverlap(
                      directoryVariant?.directory ?? directoryVariant
                    );
                  }}
                  label={t(...messages.supplier())}
                  value={cost.directory}
                  errors={validate('directory_id', i)}
                  onChange={(x: any) => {
                    handleUpdate(cost.id, 'directory', x);
                  }}
                />
              </Col>

              <Col className="d-flex" md="2">
                <TextInput
                  label={t(...messages.totalCost())}
                  type="number"
                  errors={validate('total_cost', i)}
                  value={cost.total_cost}
                  onChange={x => handleUpdate(cost.id, 'total_cost', x)}
                />
              </Col>
              <Col md="2">
                <TextInput
                  label={t(...messages.deprecatedOn())}
                  type="text"
                  errors={validate('damped_on', i)}
                  value={cost.damped_on}
                  onChange={x => handleUpdate(cost.id, 'damped_on', x)}
                />
              </Col>
              <Col>
                <TextInput
                  label={t(...messages.comment())}
                  type="textarea"
                  rows={1}
                  value={cost.comment}
                  errors={validate('comment', i)}
                  onChange={x => handleUpdate(cost.id, 'comment', x)}
                />
              </Col>
              <Col className="" md={1}>
                <div>
                  <Label className="mb-0">&nbsp;</Label>
                </div>
                <Can action="edit" subject="product-prices">
                  <Button
                    color="link"
                    className="text-danger"
                    onClick={() => {
                      dispatch(actions.removeCost({id: cost.id, categoryId}));
                    }}
                  >
                    <Icon name="trash-2" size={18} />
                  </Button>
                </Can>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Development;
