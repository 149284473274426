import React from 'react';
import {directoryTabs} from '../constants';
import './tab-pane.styles.scss';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';
import {selectDirectory} from 'store/directory/endpoints/show-directory';

const TabPane = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const prefix = `/dashboard/directories/${id}`;

  const {data, isSuccess} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  const tabz = directoryTabs.filter(x => {
    if (!x.hidden) {
      return true;
    }
    return x.hidden && x.hidden(directory);
  });
  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-2 tab-pane">
      {tabz.map(x => (
        <NavItem key={x.to}>
          <NavLink
            className={active =>
              clsx({active: active.isActive}, 'px-3 nav-link')
            }
            to={`${prefix}/${x.to}`}
          >
            {t(...x.title())}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default TabPane;
