import {useEffect, useMemo, useState} from 'react';
import {produce} from 'immer';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ObjectId} from 'types/object-id';
import {
  TransferOrderRequest,
  TransferResponse,
} from '../../store/endpoints/transfer-order';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useDispatch} from 'react-redux';
import {uiActions} from 'store/ui/ui-slice';
import {selectOrder} from '../../store/endpoints/show-order';
import denormalizeObject from 'utils/denormalize-object';
import {
  RedirectTo,
  TransferSupplierOrderRow,
  useTransferOrderToSupplierOrderMutation,
} from '../../store/endpoints/transfer-to-supplier-order';
import clearSelection from 'features/table/slice/actions/clear-selection';
import selectSelectedResources from 'features/table/slice/selectors/select-selected-resources';
import {CommercialProduct} from 'features/commercial/documents/types/commercial-product';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [transfer, {isLoading, error, data: response}] =
    useTransferOrderToSupplierOrderMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useSelector(selectActiveModalParams);

  const [items, setItems] = useState<
    Record<ObjectId, TransferSupplierOrderRow>
  >({});

  const dispatch = useDispatch();
  const selectedIds = useSelector(
    selectSelectedResources('transfer-to-supplier-order')
  );
  const order = useSelector(selectOrder({id}));

  const originalItems = order.data?.data.commercial_products_objectified;

  const handleSubmit = async () => {
    const selectedItems = Object.keys(items ?? {}).filter(
      item =>
        selectedIds.includes(item) &&
        isTransferred(items[item].commercial_product)
    );
    const result = await transfer({
      items,
      id,
      selectedIds: selectedItems,
    });

    if ('data' in result) {
      redirectByType(result?.data);
      dispatch(clearSelection('transfer-to-supplier-order'));

      dispatch(clearSelection('transfer-to-supplier-order'));

      dispatch(uiActions.dismissModal());
    }
  };

  const handleDismiss = () => {
    dispatch(clearSelection('transfer-to-supplier-order'));

    dispatch(uiActions.dismissModal());
  };

  const redirectByType = (res: TransferResponse) => {
    const {redirect_to} = res;

    const linkByDoctype = {
      Order: `dashboard/orders`,
      Invoice: `dashboard/invoices`,
    };

    const to = redirect_to[redirect_to.length - 1] as RedirectTo;
    window.open(
      `/app/${linkByDoctype[to.model_type]}/${to.id}/details`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const initItems = (rows: any[]) => {
    rows.forEach(row => {
      setItems(s =>
        produce(s, draft => {
          draft[row?.uuid] = {
            ...(draft[row?.uuid] ?? {}),
            commercial_product: {
              ...row,
              sku: row.commercial_product_type === 'PRODUCT'
                ? row.variant?.sku
                : row.sku,
            },
          };
        })
      );
    });
  };

  const filteredItems = useMemo(() => {
    return denormalizeObject(originalItems).filter(x => {
      return x?.commercial_product_type === 'PRODUCT';
    });
  }, []);

  useEffect(() => {
    initItems(
      filteredItems
        .map((el: CommercialProduct) => [el, el.components])
        .flat(Infinity)
    );
  }, [filteredItems]);

  const updateItem = (
    uuid?: ObjectId,
    key?: keyof TransferSupplierOrderRow,
    value?: any
  ) => {
    if (!uuid) {
      throw new Error(`Unknown SKU: ${uuid}`);
    }
    if (!uuid || !key) {
      throw new Error(`Unknown key: ${uuid}`);
    }
    setItems(s =>
      produce(s, draft => {
        draft[uuid] = {
          ...(draft[uuid] ?? {}),
          [key]: value,
        };
      })
    );
  };
  const isTransferred = (row: any) => {
    return (
      row?.is_abele_to_be_transfer_to_supplier_order &&
      !row?.is_transferred_to_supplier_order
    );
  };

  const canSubmit = useMemo(() => {
    return !(
      Object.keys(items ?? {}).filter(
        item =>
          selectedIds.includes(item) &&
          isTransferred(items[item].commercial_product)
      ).length > 0
    );
  }, [selectedIds]);

  return {
    setItems,
    isTransferred,
    submit: handleSubmit,
    allItems: filteredItems,
    updateItem,
    handleDismiss,
    items,
    errorMessage: serverError.message,
    isLoading,
    validationErrors: serverError.errors,
    initItems,
    canSubmit,
  };
};

type Params = Partial<TransferOrderRequest>;
