import {useMemo, useState} from 'react';
import {produce} from 'immer';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ObjectId} from 'types/object-id';
import {
  Email,
  PreparerOrderRequest,
  usePreparerOrderMutation,
} from '../../store/endpoints/preparer-order';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useDispatch} from 'react-redux';
import {selectOrder} from '../../store/endpoints/show-order';
import denormalizeObject from 'utils/denormalize-object';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import makeId from 'utils/make-id';
import messages from './messages';

const newEntry = {
  id: makeId(),
  value: '',
};

export default (onDismiss: () => void) => {
  const [prepare, {isLoading, error, data: response}] =
    usePreparerOrderMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useSelector(selectActiveModalParams);

  const {t} = useTranslation();
  const order = useSelector(selectOrder({id}));

  const originalItems = order.data?.data.commercial_products_objectified;

  const [items, setItems] = useState<Record<ObjectId, boolean>>({});

  const makeEmail = () => {
    const header = t(...messages.emailHeader());
    const footer = t(...messages.emailFooter());

    const p = denormalizeObject(originalItems).filter(p => p.has_label);
    const body =
      p.length > 0
        ? `\n\n${p
            .map(el => `-${el.custom_name} x ${el.quantity}`)
            .join('\n')}\n\n`
        : '\n\n...\n\n';

    return header + body + footer;
  };

  const [params, setParams] = useState<Params>({
    body: makeEmail(),
  });

  const [emails, onChangeEmails] = useState<Email[]>([newEntry]);

  const handleAddEmail = () => {
    onChangeEmails(prev => [...prev, newEntry]);
  };

  const handleChangeEmail = (index: number, value: string) => {
    const updatedEmails = [...emails];

    updatedEmails[index] = {
      ...emails[index],
      value,
    };

    onChangeEmails(updatedEmails);
  };

  const handleDeleteEmail = (index: number) => {
    const updatedEmails = [...emails];

    updatedEmails.splice(index, 1);
    return onChangeEmails(updatedEmails);
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await prepare({
      ...params,
      items,
      emails: emails.map(el => el.value),
      id,
    });

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      onDismiss();
    }
  };

  const updateItem = (id: ObjectId) => {
    setItems(
      produce(items, draft => {
        draft[id] = !draft[id];
      })
    );
  };

  const itemsLength = useMemo(() => {
    const commercial_products = Object.keys(items ?? {})
      .filter(x => items[x])
      .map(id => ({id}));
    return commercial_products.length;
  }, [items]);

  const onToggleAll = () => {
    if (isAllSelected) {
      setItems({});
      return;
    }
    const itemsTmp = {} as Record<ObjectId, boolean>;
    denormalizeObject(originalItems).forEach(x => {
      if (!isPrepared(x.id)) {
        itemsTmp[x.id] = true;
      }
    });
    setItems({
      ...itemsTmp,
    });
  };

  const isAllSelected = useMemo(() => {
    const allItems = denormalizeObject(originalItems).filter(
      x => !x.is_prepared
    );

    return allItems.length === itemsLength;
  }, [itemsLength, originalItems]);

  const isPrepared = (id: ObjectId) => {
    if (!originalItems) {
      return true;
    }
    return !!originalItems[id].is_prepared;
  };

  return {
    setItems,
    itemsLength,
    emails,
    onChangeEmails,
    handleDeleteEmail,
    handleChangeEmail,
    setParams,
    onToggleAll,
    isPrepared,
    handleAddEmail,
    isAllSelected,
    submit: handleSubmit,
    updateItem,
    items,
    errorMessage: serverError.message,
    isLoading,
    params,
    validationErrors: serverError.errors,
  };
};

type Params = Partial<PreparerOrderRequest>;
