import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoicesRelatedPartsTab.title, 'Related documents'),
  code: () => __(translations.invoicesRelatedPartsTab.code, 'Code'),
  client: () => __(translations.invoicesRelatedPartsTab.client, 'Client'),
  date: () => __(translations.invoicesRelatedPartsTab.date, 'Date'),
  totalExcl: () => __(translations.invoicesRelatedPartsTab.totalExcl, 'Total excl taxes'),
  status: () => __(translations.invoicesRelatedPartsTab.status, 'Status'),
};
