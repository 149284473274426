import React, {FC} from 'react';
import CatLoader from './product-filter-skeleton';
import {ProductsFilters, ProductPaginationMeta} from './types';
import makeSelectEntityRequest from 'features/table/slice/selectors/make-select-entity-request';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {FilterMap} from 'features/table/slice/types';
import {useSelector} from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {ProductCollection} from 'types/product-collection';
import {useGetProductCollectionQuery} from 'store/production/endpoints/get-product-collections';

/**
 * Props for the Collections component.
 */
interface Props {
  /**
   * The current filters.
   */
  filters: FilterMap<ProductsFilters>;
  /**
   * Callback function to handle filter changes.
   */
  onChangeFilters: (key: ProductsFilters, value: any) => void;
}

/**
 * Component for displaying and managing product collections.
 *
 * @param props - The component props.
 * @returns The JSX elements for product collections.
 */
const Collections: FC<Props> = ({filters, onChangeFilters}) => {
  // Extract collection from filters and convert to ObjectId array
  const collectionQuery = filters['filter[product_collection_id]'] as
    | string
    | '';
  const collection: ObjectId[] = (collectionQuery?.split('|') || []).map(
    (id: ObjectId) => Number(id)
  );

  // Retrieve product collection data
  const {data, isLoading} = useGetProductCollectionQuery(filters);

  const productCollections: ProductCollection[] =
    data?.data?.product_collections ?? [];

  /**
   * Handles collection button click.
   *
   * @param c - The selected product collection.
   */
  const handleClick = (c: ProductCollection): void => {
    const found = collection.includes(c.id);

    if (found) {
      const updatedCollection = collection.filter(id => id !== c.id);
      onChangeFilters(
        'filter[product_collection_id]',
        updatedCollection.length === 0 ? null : updatedCollection.join('|')
      );
    } else {
      onChangeFilters(
        'filter[product_collection_id]',
        collection.concat(c.id).join('|')
      );
    }
  };

  return (
    <div>
      <Row className="align-items-center mb-3">
        <Col md={12}>
          <div className="mb-3">
            <div className="d-flex flex-wrap gap-2">
              {isLoading ? (
                <CatLoader />
              ) : (
                productCollections.map(c => (
                  <Button
                    key={c.id}
                    color={'white'}
                    active={collection.includes(c.id)}
                    size="sm"
                    className={` font-size-14 text-center px-3 py-2 ${
                      collection.includes(c.id)
                        ? ' border-important border-bottom-0 text-important '
                        : ' border-gray-light border-bottom-0'
                    } `}
                    onClick={() => handleClick(c)}
                  >
                    {c.name}{' '}
                    <span
                      className={`badge  ms-1 font-size-16 ${
                        collection.includes(c.id)
                          ? '  text-important border-important border-1 border '
                          : ' text-white bg-important '
                      } `}
                    >
                      {c.variant_count}
                    </span>
                  </Button>
                ))
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Collections;
