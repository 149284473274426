import {createAction} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';
import {ResourceName} from 'types/resource-name';

export default createAction(
  'table/toggleSelection',
  function prepare(
    resourceName: ResourceName,
    selected?: Record<ObjectId, boolean>
  ) {
    return {
      payload: {
        resourceName,
        selected,
      },
    };
  }
);
