import {UiState} from './types';

const initialState: UiState = {
  modal: {
    modalType: null,
    params: {},
  },
  requests: [],
  showTutorial: true,
};

export default initialState;
