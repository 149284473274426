import {createAsyncThunk} from '@reduxjs/toolkit';
import {saveAuthState, StoredAuthState} from 'services/auth-service';
import {RootState} from 'store';

/**
 * Merge user action
 *
 */
export const mergeUser = createAsyncThunk(
  'oauth/mergeUser',
  async (_, {getState}) => {
    const {existingUser, accessToken} = (getState() as RootState).auth
      .socialAuth;
    if (existingUser && accessToken) {
      await saveAuthState({
        user: existingUser,
        accessToken,
      });
    }

    return {
      user: existingUser,
      accessToken: accessToken,
    } as StoredAuthState;
  }
);
