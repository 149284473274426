import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

export const showQuotation = api.injectEndpoints({
  endpoints: builder => ({
    showQuotation: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `quotations/${params.id}/commercial-products`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'quotations', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Quotation>;

export const {useShowQuotationQuery, useLazyShowQuotationQuery} = showQuotation;

export const updateCachedQuotationFromResource = (quotation: Quotation) => {
  return showQuotation.util.updateQueryData(
    'showQuotation',
    {id: String(quotation.id)},
    x => {
      x.data = quotation;
    }
  );
};

export const selectQuotation = showQuotation.endpoints.showQuotation.select;
