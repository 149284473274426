import React, {FC} from 'react';
import messages from './messages';
import {useOtherInfoForm} from './use-other-info-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {Row, Col, Alert, Label} from 'reactstrap';
import Can from 'features/hylian-shield/can';

const OtherInfoTab: FC<{}> = () => {
  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, setParams} =
    useOtherInfoForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  return (
    <div className="px-auto py-2">
      <Row>
        <Col md={5}>
          <TextInput
            label={t(...messages.hsCode())}
            className="mb-1"
            value={params.hs_code}
            errors={validationErrors.hs_code}
            onChange={hs_code => setParams({...params, hs_code})}
          />
        </Col>

        <Col md={10}>
          <TextInput
            label={t(...messages.carbonFootprint())}
            className="mb-1"
            type="textarea"
            value={params.carbon_footprint}
            errors={validationErrors.carbon_footprint}
            onChange={carbon_footprint =>
              setParams({...params, carbon_footprint})
            }
          />
        </Col>
        <Col md={10}>
          <TextInput
            label={t(...messages.inspiration())}
            className="mb-1"
            type="textarea"
            value={params.inspiration}
            errors={validationErrors.inspiration}
            onChange={inspiration => setParams({...params, inspiration})}
          />
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <TextInput
            type="textarea"
            rows={5}
            label={t(...messages.setup())}
            className="mb-1"
            value={params.setup}
            errors={validationErrors.setup}
            onChange={setup => setParams({...params, setup})}
          />
        </Col>
        <Col md={10}>
          <TextInput
            type="textarea"
            rows={5}
            label={t(...messages.maintenanceInstruction())}
            className="mb-1"
            value={params.maintenance_instruction}
            errors={validationErrors.maintenance_instruction}
            onChange={maintenance_instruction =>
              setParams({
                ...params,
                maintenance_instruction: maintenance_instruction,
              })
            }
          />
        </Col>
      </Row>

      <Can action="edit" subject="products">
        <AppBarActions>
          <Button isLoading={isLoading} onClick={submit} color="important">
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </AppBarActions>
      </Can>
    </div>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export default OtherInfoTab;
