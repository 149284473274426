import { ColumnDef } from '@tanstack/table-core';
import React from 'react';
import {Trans} from 'react-i18next';
import {Exports} from 'types/exports';

const columns: ColumnDef<Exports>[] = [
  {
    header: r => <Trans i18nKey="exportsPage.id" />,
    accessorFn: r => r?.id,
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'id',
  },
  {
    header: r => <Trans i18nKey="exportsPage.name" />,
    accessorFn: row => <a href={row.url}>{row.name}</a>,
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="exportsPage.type" />,
    accessorFn: row => row.type,
    cell: r => r.getValue(),

    id: 'type',
    enableSorting: true,
  },
  /*   {
    header: r => <Trans i18nKey="exportsPage.date" />,
    enableSorting: false,
    id: 'date',
    maxWidth: '120px',
    accessorFn: row => row.username.,
  }, */
  {
    header: r => <Trans i18nKey="exportsPage.size" />,
    enableSorting: false,
    cell: r => r.getValue(),

    id: 'size',
    accessorFn: row => row.human_readable_size,
  },
  {
    header: r => <Trans i18nKey="exportsPage.created_at" />,
    enableSorting: false,
    id: 'created_at',
    cell: r => r.getValue(),

    accessorFn: row => new Date(row.created_at).toLocaleString(),
  },
];

export default columns;
