import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const sendEmailQuotation = api.injectEndpoints({
  endpoints: builder => ({
    sendEmailQuotation: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `quotations/${params.id}/send-mail`,
          body: {
            body: params.body,
            emails: params.emails,
          },
        };
      },
    }),
  }),
});

type Request = {id: ObjectId; body?: string; emails?: string[]};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useSendEmailQuotationMutation} = sendEmailQuotation;
