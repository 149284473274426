import React from 'react';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {CompactSupplierOrder} from 'features/commercial/orders/types/order';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import CostumeCurrency from 'components/costume-currency/costume-currency';
import RelatedDocument from 'types/related-document';
import TooltipCell from 'components/tooltip-cell';
import {TYPE} from 'components/async-preview-tooltip/constant';

const columns: ColumnDef<CompactSupplierOrder>[] = [
  {
    header: r => <Trans i18nKey="supplierOrderPage.number" />,
    accessorFn: row => (
      <div id={`supplier-order-code-${row.id}`}>
        <AsyncPreviewTooltip column="ref" type="ORDER" id={row.id}>
          <Link to={`/dashboard/orders/${row.id}/details`} target="_blank">
            <b>{row.document_ref}</b>
          </Link>
        </AsyncPreviewTooltip>
      </div>
    ),
    cell: r => r.getValue(),
    meta: {size: '85px'},
    id: 'document_ref',
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="supplierOrderPage.supplier" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.supplier_id}/general`}
        target="_blank"
      >
        <b>{row.supplier_name}</b>
      </Link>
    ),
    id: 'supplier_name',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.related_documents" />,
    accessorFn: row =>
      row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <AsyncPreviewTooltip
              id={related_document.model_id}
              key={`${related_document.model_type}-${related_document.model_id}`}
              type={related_document.model_type?.toLocaleUpperCase() as TYPE}
              column="ref"
            >
              <Link
                className="mb-1"
                key={`${related_document.model_type}-${related_document.model_id}`}
                to={guessUrl(related_document)}
                target="_blank"
              >
                <b>{related_document.model_ref}</b>
              </Link>
            </AsyncPreviewTooltip>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.projet" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'project_name',
    accessorFn: row => <EmptyCell cell={row.project?.name} />,
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.date" />,
    enableSorting: true,
    id: 'order_date',
    accessorFn: row => <FormattedDate value={row.order_date} format="date" />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.contact" />,
    enableSorting: true,
    id: 'contact',
    accessorFn: row => <EmptyCell cell={row.user_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.countermark" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.client_id}/general`}
        target="_blank"
      >
        <b> {row.client_name}</b>
      </Link>
    ),
    id: 'client_name',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.totalHt" />,
    enableSorting: true,
    id: 'total_ht',
    accessorFn: row => (
      <CostumeCurrency
        value={row.total_ht}
        symbol={row?.currency_symbole ?? ''}
      />
    ),

    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.estimatedDelivery" />,
    enableSorting: true,
    id: 'estimated_supplier_time',
    accessorFn: row => (
      <FormattedDate value={row.estimated_supplier_time} format="date" />
    ),
    cell: r => r.getValue(),
    meta: {cellCostumClass: 'text-center'},
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.controlDate" />,
    enableSorting: true,
    id: 'delivery_date',
    accessorFn: row => (
      <FormattedDate value={row.delivery_date} format="date" />
    ),
    meta: {cellCostumClass: 'text-center'},

    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="supplierOrderPage.status" />,
    enableSorting: true,
    id: 'status',
    accessorFn: row => <StatusBadge status={row.status} />,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="quotationsPage.transporter_name" />,
    enableSorting: true,
    id: 'transporter_name',
    accessorFn: row => <EmptyCell cell={row.transporter?.display_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.comment" />,
    enableSorting: false,
    id: 'comment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <TooltipCell cell={row?.private_comment ?? ''} id={row.id}></TooltipCell>
    ),
  },
];

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};

export default columns;
