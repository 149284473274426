import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  UpsertInteractionRequest,
  useUpsertInteractionMutation,
} from 'store/directory/endpoints/upsert-interaction';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {Directory} from 'types/directory';
import {Interaction} from 'types/interaction';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [submit, {isLoading, error}] = useUpsertInteractionMutation();

  const serverError = parseValidationErrors(error);

  const {directory, interaction} = useSelector(selectActiveModalParams) as {
    interaction: Interaction;
    directory: Directory;
  };

  const fake = useSeeder();

  const [params, setParams] = useState<Params>({
    comment: __DEV__ ? fake.paragraph : '',
    id: interaction?.id,
    interaction_type: undefined,
    directory_id: directory.id,
  });

  useEffect(() => {
    if (interaction) {
      setParams(
        produce(params, p => {
          p.comment = interaction.comment;
          p.interaction_type = interaction.interaction_type;
          p.directory_id = interaction.directory_id;
        })
      );
    }
  }, [interaction]);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = UpsertInteractionRequest;
