import {useCallback, useEffect} from 'react';
import {actions} from '../slice';
import hydrateFilters from '../slice/actions/hydrate-filters';
import loadData from '../slice/actions/load-data';
import decodeUrl from '../slice/utils/decode-url';
import {ListParams} from '../types';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {ResourceName} from 'types/resource-name';

const useFilter = (
  name: ResourceName,
  url: string,

  routerParams?: boolean,
  otherParams?: ListParams
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.size > 0) {
      dispatch(hydrateFilters(name, decodeUrl(searchParams)));
    }
  }, [dispatch, name]);
  const handleChangeFilters = (key: string, value: string) => {
    if (routerParams) {
      if (!value || value == '') {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      setSearchParams(searchParams);
    }
    dispatch(actions.changeFilter({name, key, value}));
    dispatch(actions.loadData({url, name, otherParams}));
  };

  const handleClearFilters = () => {
    if (routerParams) {
      setSearchParams();
    }
    dispatch(actions.clearFilters({name}));
    dispatch(actions.clearSelection(name));
    dispatch(actions.loadData({url, name, otherParams}));
  };
  const refetch = () => {
    dispatch(loadData({name, url, otherParams, page: 1}));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearFilters({name}));
    };
  }, []);
  return {
    handleChangeFilters,
    handleClearFilters,
  };
};

export default useFilter;
