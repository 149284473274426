import {useState} from 'react';
import {ADDRESS_TYPES} from 'constants/address-types';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {UpdateAddressRequest} from 'store/directory/endpoints/update-address';
import {useUpdateAddressMutation} from 'store/directory/endpoints/update-address';
import dismissModalWithResult from 'store/ui/actions/dismiss-modal-with-result';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Address} from 'types/address';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAddressForm = () => {
  const [submit, {isLoading, error}] = useUpdateAddressMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const address = modalParams.address as Address;

  const [params, setParams] = useState<Params>({
    directory_id: address?.directory_id,
    id: address?.id,
    zip_code: address?.zip_code,
    first_line: address?.first_line,
    label: address?.label,
    second_line: address?.second_line,
    city: address?.city,
    vat_mode: address?.vat_mode,
    country: address?.country,
    third_line: address?.third_line,
    comment: address?.comment,
    address_type: ADDRESS_TYPES[address?.address_type?.id],
    is_default: address?.is_default,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(dismissModalWithResult(result.data.data));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = UpdateAddressRequest;
