import React, {FC} from 'react';
import messages from '../messages';
import {InvoicePaginationMeta} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Card, Col, Row} from 'reactstrap';
import TagsItem from 'components/tags-card';
import Currency from 'components/currency/currency';

interface TagsProps {}

const TagsRow: FC<TagsProps> = props => {
  const {t} = useTranslation();

  const meta = useSelector(selectEntityMeta<InvoicePaginationMeta>('invoices'));

  return (
    <Row className=" justify-content-around mb-3">
      <Col sm={2}>
        <Card className="py-2 px-2 m-0  text-center">
          <h5 className="text-info mt-2 font-size-14">
            {t(...messages.totalBenefitPerYear())}
          </h5>
          <TagsItemWithlabel
            label={t(...messages.year())}
            tag={meta?.total_benefits_year}
          />
          <TagsItemWithlabel
            label={t(...messages.previousYear())}
            tag={meta?.total_benefits_previous_year}
          />
        </Card>
      </Col>
      <Col sm={2}>
        <Card className="py-2 px-2  text-center">
          <h5 className="text-info mt-2 font-size-14">
            {t(...messages.totalBenefitPerMonth())}
          </h5>
          <TagsItemWithlabel
            label={t(...messages.month())}
            tag={meta?.total_benefits_month}
          />
          <TagsItemWithlabel
            label={t(...messages.previousMonth())}
            tag={meta?.total_benefits_previous_month}
          />
        </Card>
      </Col>

      <Col sm={2} className="mb-2">
        <TagsItem title={t(...messages.totalBenefit())}>
          <Currency value={meta?.total_benefit} />
        </TagsItem>
      </Col>
      <Col sm={2} className="mb-2">
        <TagsItem
          title={`${t(...messages.totalInvoicesHT())}   |  ${t(
            ...messages.totalInvoicesTTC()
          )}`}
        >
          <Currency value={meta?.total_ht} /> |{' '}
          <Currency value={meta?.total_ttc} />
        </TagsItem>
      </Col>

      <Col sm={2} className="mb-2">
        <TagsItem title={t(...messages.totalDeposit())}>
          <Currency value={meta?.total_deposit} />
        </TagsItem>
      </Col>

      <Col sm={2}>
        <TagsItem title={t(...messages.totalPay())}>
          <Currency value={meta?.total_sold} />
        </TagsItem>
      </Col>
    </Row>
  );
};

interface TagsItemsProps {
  tag: number;
  label?: string;
}

const TagsItemWithlabel: FC<TagsItemsProps> = props => {
  const {tag, label} = props;
  return (
    <p className="text-muted m-0 fs-6  d-flex justify-content-between align-items-center">
      {label && <span className="font-size-12 me-2 fw-bold">{label}</span>}
      {tag ? <Trans i18nKey="numbers.currency" values={{val: tag}} /> : 0}
    </p>
  );
};

export default TagsRow;
