import {createAction} from '@reduxjs/toolkit';
import {Situation} from 'types/situation';

export default createAction(
  'costs/situationChanged',
  function prepare(situation?: Situation | null | unknown) {
    return {
      payload: situation,
    };
  }
);
