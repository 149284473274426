import React, {useContext} from 'react';
import {FC} from 'react';
import messages from './messages';
import useComplementaryInfoFrom from './use-complementary-info-From';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Label, Row} from 'reactstrap';
import {Option} from 'types/option';

const ComplementaryInfoTab: FC<{}> = () => {
  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    setParams,
    directory,
    canEdit,
  } = useComplementaryInfoFrom();

  return (
    <>
      {/*  <SectionSeparator
        title={t(...messages.title(), {name: directory?.display_name})}
      /> */}
      {/*  <Row className="mb-2">
        <Col md={10}>
          <AsyncItemPicker
            endPoint="data/legal-forms"
            className="mb-1"
            label={t(...messages.legalStatus())}
            optionLabel=""
            errors={validationErrors.legal_form_id}
            value={params.legal_form}
            onChange={legal_form => setParams({...params, legal_form})}
          />
        </Col>
      </Row> */}
      <Row className="mb-2">
        <Col md={5}>
          <TextInput
            type="text"
            label={t(...messages.siret())}
            rows={4}
            value={params.siret}
            errors={validationErrors.siret}
            onChange={siret => setParams({...params, siret})}
          />
        </Col>
        <Col md={5}>
          <TextInput
            // Charles darwin would hate you for this
            label={t(...messages.ape())}
            className="mb-1"
            value={params.ape}
            errors={validationErrors.ape}
            onChange={ape => setParams({...params, ape})}
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={5}>
          <TextInput
            className="mb-1"
            label={t(...messages.vat())}
            errors={validationErrors.vat_id}
            value={params.vat_number}
            onChange={vat_number => setParams({...params, vat_number})}
          />
        </Col>
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/vat-modes"
            className="mb-1"
            getOptionLabel={(x: Option) => x.label}
            getOptionValue={(x: Option) => x.value}
            label={t(...messages.vatType())}
            errors={validationErrors.vat_type}
            value={params.vat_type}
            onChange={vat_type => setParams({...params, vat_type})}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <TextInput
            label={t(...messages.iban())}
            className="mb-1"
            value={params.iban}
            errors={validationErrors.iban}
            onChange={iban => setParams({...params, iban})}
          />
        </Col>
        <Col md={5}>
          <TextInput
            label={t(...messages.bic())}
            className="mb-1"
            value={params.bic_swift}
            errors={validationErrors.bic_swift}
            onChange={bic_swift => setParams({...params, bic_swift})}
          />
        </Col>
      </Row>

      {!directory?.is_archived && (
        <AppBarActions>
          <Button
            isLoading={isLoading}
            disabled={!canEdit}
            onClick={submit}
            color="important"
          >
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </AppBarActions>
      )}
    </>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export default ComplementaryInfoTab;
