import {CommercialProduct} from '../../types/commercial-product';
import selectItems from './select-items';
import {createSelector} from '@reduxjs/toolkit';

/**
 * Relatable items are other items in the document
 *
 * One item is only able to adopt other items as children
 * if, and only if:
 *  - they are neither titles nor subtitles
 *  - they are strictly items
 *  - they MUST have variant field selected
 *  - they do not have their own id, i.e. an item cannot beget itself
 *
 */
export default (line: CommercialProduct) =>
  createSelector([selectItems], (items: CommercialProduct[]) => {
    // strip out items according to constraints
    const onlyRelatable = items
      // prevent circular dependencies
      // check if the item is already linked to the line
      .filter(
        item =>
          !line.linked_products?.find(
            linked_product =>
              linked_product.commercial_product_id ===
              item.commercial_product_id
          )
      )
      // check if the line is already linked to the item
      .filter(
        item =>
          !item.linked_products?.find(
            linked_product =>
              linked_product.commercial_product_id ===
              line.commercial_product_id
          )
      )
      // only take products
      .filter(x => x?.commercial_product_type === 'PRODUCT')
      // only take items with variants
      .map(x => ({
        ...x.variant,
        order: x.order,
        commercial_product_id: x.id,
      }))
      // exclude empty values
      .filter(x => !!x?.commercial_product_id)
      // exclude self
      .filter(x => x?.commercial_product_id !== line?.commercial_product_id);
    return onlyRelatable;
  });
