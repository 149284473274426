import {setupGoogleAuth as setupGoogleAuthAction} from '../actions/setup-google-auth';
import {AuthState} from '../types';
import {WritableDraft} from 'types/writable-draft';

const setupGoogleAuthMatcher = setupGoogleAuthAction.fulfilled;

/**
 * Google Auth configuration reducer
 *
 */
const setupGoogleAuthReducer = (state: WritableDraft<AuthState>) => {
  state.google.isGoogleAuthConfigured = true;
};

export const setupGoogleAuth = {
  matcher: setupGoogleAuthMatcher,
  reducer: setupGoogleAuthReducer,
};
