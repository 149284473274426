import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import RelatedDocument from 'types/related-document';

const unlinkOrderDocument = api.injectEndpoints({
  endpoints: builder => ({
    unlinkOrderDocument: builder.mutation<Response, UnLinkOrderDocumentRequest>(
      {
        query: params => {
          return {
            method: 'POST',
            url: `orders/${params.id}/commercial-products/${params.commercial_product_id}/unlink-order`,
            body: {
              model_id: params.target?.id,
              model_type: params.target?.model_type,
              commercial_product_id: params.target?.commercial_product_id,
              document_type: params.target?.document_type,
              document_class: params.target?.document_class,
              is_component: params.target?.is_component,
              commercial_product_option_id:
                params.target?.commercial_product_option_id,
            },
          };
        },
        //@ts-expect-error
        invalidatesTags: (result, error, params) => {
          if (result) {
            return [{type: 'orders', id: params.id}];
          }
          return [];
        },
      }
    ),
  }),
});

export type UnLinkOrderDocumentRequest = {
  commercial_product_id?: ObjectId;
  target?: RelatedDocument;
  id?: ObjectId;
};

type Response = ResponseData<RelatedDocument[]>;

export const {useUnlinkOrderDocumentMutation} = unlinkOrderDocument;

export const unlinkOrderDocumentEndpoint =
  unlinkOrderDocument.endpoints.unlinkOrderDocument;
