import {globalSaga} from './global/saga';
import LayoutSaga from './layout/saga';
import {uiSaga} from './ui/saga';
import {commercialDocumentSaga} from 'features/commercial/documents/store/saga';
import {listSaga} from 'features/listing/list/slice/saga';
import {tableSaga} from 'features/table/slice/saga';
import {all, fork} from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    //public
    fork(globalSaga),
    fork(commercialDocumentSaga),
    fork(LayoutSaga),
    fork(listSaga),
    fork(tableSaga),
    fork(uiSaga),
  ]);
}
