import React, {FC, Fragment} from 'react';
import messages from './messages';
import usePaymentForm from './use-payment-form';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {PAYMENT_METHOD} from 'features/commercial/documents/constants/payment-methods';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Pricing} from 'types/pricing';
import {CommercialDocumentType} from 'features/commercial/documents/types/commercial-document-type';

interface Props {
  row?: Pricing;
  onDismiss: () => void;
}

const PaymentPickerModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {type, directory_id, model_id, model_type} = useSelector(
    selectActiveModalParams
  ) as {
    type: Option;
    directory_id: ObjectId;
    model_type: CommercialDocumentType;
    model_id: ObjectId;
  };

  const {params, setParams, submit, validationErrors, handleSelect} =
    usePaymentForm();

  const isCredit = PAYMENT_METHOD.CREDIT === type.value;

  const isOverpayment = PAYMENT_METHOD.OVERPAYMENT === type.value;
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          {isCredit && t(...messages.credit())}
          {isOverpayment && t(...messages.overPayment())}
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          {isCredit && (
            <>
              <AsyncItemPicker
                endPoint={`directories/${directory_id}/wallets`}
                otherParams={{
                  type: type.value,
                  model_id,
                  model_type,
                }}
                onChange={documentable => handleSelect(documentable)}
                value={params.wallet}
                errors={validationErrors.documentable}
                label={t(...messages.selectACredit())}
              />
            </>
          )}
          {isOverpayment && (
            <>
              <AsyncItemPicker
                endPoint={`directories/${directory_id}/wallets`}
                otherParams={{type: type.value, model_id, model_type}}
                label={t(...messages.selectAnInvoice())}
                value={params.wallet}
                errors={validationErrors.documentable}
                onChange={documentable => handleSelect(documentable)}
              />
            </>
          )}
          <TextInput
            label={t(...messages.amountToUse())}
            value={params.amount}
            errors={validationErrors.amount}
            onChange={amount => setParams({...params, amount})}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit}>
          {t('common.saveChanges')}
        </Button>{' '}
        <Button color="light" onClick={onDismiss}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default PaymentPickerModal;
