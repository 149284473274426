import React from 'react';
import {FC} from 'react';
import Icon from '@ailibs/feather-react-ts';
import {Link} from 'react-router-dom';
import {Directory} from 'types/directory';
import styled from 'styled-components';

interface GetOptionLabelProps {
  directory: Directory;
}

const GetOptionLabel: FC<GetOptionLabelProps> = props => {
  const {directory} = props;

  return (
    <div className="d-flex justify-content-between align-items-center">
      {directory.display_name}

      <IconContainer>
        <Link
          target="_blank"
          to={`/dashboard/directories/${directory.id}/general`}
        >
          <Icon name="external-link" size={15} />
        </Link>
      </IconContainer>
    </div>
  );
};

export default GetOptionLabel;

const IconContainer = styled.div`
  z-index: 99999;
  background: none;
  text-align: center;
  position: absolute;
  right: 0;
`;
