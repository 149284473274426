import React, {FC, Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Row, Badge} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {Product} from 'types/product';
import {ProductVariant} from 'types/product-variant';

interface Props {
  product: Product;
}

const SummarySection: FC<Props> = props => {
  const {product} = props;
  const {t} = useTranslation();

  const {id, variant} = useParams();
  const data = useSelector(selectVariant({id: variant, productId: id}));
  const variantData = data?.data?.data as ProductVariant;

  return (
    <div>
      <Fragment>
        <Helmet>
          <title>
            {t('editProductPage.pageTitle') +
              ' - ' +
              (!!variantData ? variantData?.name : product.name)}
          </title>
        </Helmet>
        <Row>
          <div>
            <div className="d-flex align-items-start mt-3">
              <div className="flex-grow-1">
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <h1 className="w-50 font-size-24 text-uppercase text-primary">
                      {!!variant ? variantData?.name : product.name}
                    </h1>
                  </div>

                  {/*!!variant ? null : <p> {product.description}</p>*/}
                </div>
                <div className="d-flex flex-wrap align-items-start  gap-2 gap-lg-3 text-muted font-size-13">
                  {variant ? (
                    <div>
                      <div className="d-flex flex-wrap gap-2 text-dark font-size-16 fw-bold">
                        {product.name}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <div className="d-flex flex-wrap text-dark gap-2 font-size-16 text-uppercase fw-bold">
                      {product.product_collection.name}
                    </div>
                  </div>

                  <div>
                    <div className="d-flex flex-wrap gap-2 font-size-16">
                      {product.product_families.map(x => (
                        <div
                          key={x.id}
                          className="text-uppercase text-dark fw-bold"
                        >
                          {x.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>
        </Row>
      </Fragment>
    </div>
  );
};

export default SummarySection;
