import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.stockOrderPage.pageTitle, 'Stock Orders'),
  addStockOrder: () => __(translations.stockOrderPage.addStockOrder, 'Add stock order'),
  orders: () => __(translations.stockOrderPage.stock, 'Stock Orders list'),
  contremark: () => __(translations.stockOrderPage.contremark, 'Countermark'),
  type: () => __(translations.stockOrderPage.type, 'Type'),
  ref: () => __(translations.stockOrderPage.ref, 'Reference'),
  orderDate: () => __(translations.stockOrderPage.orderDate, 'Order Date'),
  statedDate: () => __(translations.stockOrderPage.statedDate, 'Stated Date'),
  category: () => __(translations.stockOrderPage.category, 'Category'),
  refClient: () => __(translations.stockOrderPage.refClient, 'Reference Client'),
  totalHt: () => __(translations.stockOrderPage.totalHt, 'Total HT'),
  totalTtc: () => __(translations.stockOrderPage.totalTtc, 'Total TTC'),
  acompte: () => __(translations.stockOrderPage.acompte, 'Acompte'),
  payement: () => __(translations.stockOrderPage.payement, 'Payment'),
  balance: () => __(translations.stockOrderPage.balance, 'Balance'),
  status: () => __(translations.stockOrderPage.status, 'Status'),
  totalStockOrders: () => __(translations.stockOrderPage.totalStockOrders, 'Total Stock Orders'),
  totalPendingStockOrders: () => __(translations.stockOrderPage.totalPendingStockOrders, 'Total Pending Stock Orders'),
  totalValidStockOrders: () => __(translations.stockOrderPage.totalValidStockOrders, 'Total Valid Stock Orders'),
  totalExpiredStockOrders: () => __(translations.stockOrderPage.totalExpiredStockOrders, 'Total Expired Stock Orders'),
  month: () => __(translations.stockOrderPage.month, 'Month'),
  year: () => __(translations.stockOrderPage.year, 'Year'),
  stockOrdertotalHt: () => __(translations.stockOrderPage.stockOrdertotalHt, 'Total orders HT'),
  stockOrdertotalTtc: () => __(translations.stockOrderPage.stockOrdertotalTtc, 'Total orders TTC'),
  stockOrderTotalCost: () => __(translations.stockOrderPage.stockOrderTotalCost, 'Total cost'),
  totalOrders: () => __(translations.stockOrderPage.totalOrders, 'Total orders'),
  contact: () => __(translations.stockOrderPage.contact, 'Contact'),
};
