import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

const updateFiscalSettings = api.injectEndpoints({
  endpoints: builder => ({
    updateFiscalSettings: builder.mutation<
      Response,
      UpdateFiscalSettingsRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `settings/fiscal`,
          body: {
            ...params,
          },
        };
      },
    }),
  }),
});

export type UpdateFiscalSettingsRequest = Settings['fiscal'];

type Response = ResponseData<Settings['fiscal']>;

export const {useUpdateFiscalSettingsMutation} = updateFiscalSettings;

export const updateFiscalSettingsEndpoint =
  updateFiscalSettings.endpoints.updateFiscalSettings;
