import React, {FC} from 'react';
import {StockProductsInPreparationStatusEnum} from 'features/stock/constants/stock-products-in-preparation-status-enum';
import {useEnumAsOption} from 'features/studio/projects/utils/use-enum-as-option';
import {Button, ButtonGroup, Card, CardBody, Col, Row} from 'reactstrap';

const StockproductsInPreparationTabPane: FC<Props> = ({setParam, param}) => {
  const NAV_LINKS = useEnumAsOption(
    StockProductsInPreparationStatusEnum,
    'statusBadge'
  );

  const handleTabPane = (status: string) => {
    setParam(status);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <ButtonGroup>
                {NAV_LINKS.map(({id, name}) => (
                  <Button
                    key={id}
                    color="important"
                    outline
                    onClick={() => handleTabPane(id)}
                    active={param === id}
                  >
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

type Props = {
  setParam: (arg: string) => void;
  param: string;
};

export default StockproductsInPreparationTabPane;
