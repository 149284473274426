import {selectQuotation} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Quotation} from 'types/quotation';

export const useCurrentQuote = () => {
  const {id} = useParams();

  const {data} = useSelector(selectQuotation({id}));

  return data?.data as Quotation;
};
