import React from 'react';
import SubLocationActions from './sub-location-actions';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import StockSublocation from 'features/stock/types/stock-sub-location';

const columns: ColumnDef<StockSublocation>[] = [
  {
    header: r => <Trans i18nKey="stockSubLocationPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="stockSubLocationPage.location" />,
    accessorFn: row => <EmptyCell cell={row.stock_location?.name} />,
    id: 'stock_location',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockLocationPage.action" />,
    enableSorting: false,
    accessorFn: row => <SubLocationActions stockSubLocation={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
