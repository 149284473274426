import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  invoices: () => __(translations.createInvoicePage.invoices, 'Invoices'),
  title: () => __(translations.createInvoicePage.title, 'Create Invoice'),
  client: () => __(translations.createInvoicePage.client, 'Client'),
  addNewCompanyClient: () => __(translations.createInvoicePage.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.createInvoicePage.addNewPrivateClient, 'Add Private'),
  invoiceType: () => __(translations.createInvoicePage.invoiceType, 'Type'),
  invoiceCategory: () => __(translations.createInvoicePage.invoiceCategory, 'Category'),
  invoiceOrigin: () => __(translations.createInvoicePage.invoiceOrigin, 'Origin'),
  invoiceLanguage: () => __(translations.createInvoicePage.invoiceLanguage, 'Language'),
  invoiceDueDate: () => __(translations.createInvoicePage.invoiceDueDate, 'Due date'),
  invoicedeliveryDate: () => __(translations.createInvoicePage.invoicedeliveryDate, 'Delivery date'),
  invoiceBillingDate: () => __(translations.createInvoicePage.invoiceBillingDate, 'Billing date'),
};
