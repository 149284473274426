import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';
import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {} from 'react-router';
import {selectIsAuthenticated} from 'store/auth/selectors';
import toast from 'store/ui/actions/toast';
import {useNavigate} from 'react-router';

const Authmiddleware: FC<any> = props => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const isAllowed = checkAbility(
    props.action ?? ['show', 'edit', 'administration'],
    props.subject
  );

  if (!isAuthenticated) {
    return <Navigate to={{pathname: '/login'}} />;
  }

  if (!isAllowed && props.subject) {
    dispatch(toast('error', t('common.notAllowed')));
    navigate(-1);

    return <></>;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
