import React, {FC, Fragment} from 'react';
import messages from './messages';
import useStoreEcotax from './use-create-eco-tax';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Col, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

const EcotaxCreateForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();
  const {submit, errorMessage, isLoading, params, setParams, validationErrors} =
    useStoreEcotax();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <Row className="mb-2">
          <TextInput
            label={t('ecoTaxPage.productCode')}
            onChange={value => {
              setParams({...params, product_code: value});
            }}
            errors={validationErrors.product_code}
            value={params.product_code}
            onKeyPress={handleKeyboardEventDown}
          ></TextInput>
        </Row>
        <Row className="mb-2">
          <TextInput
            label={t('ecoTaxPage.customsCode')}
            onChange={value => {
              setParams({...params, customs_code: value});
            }}
            errors={validationErrors.customs_code}
            value={params.customs_code}
            onKeyPress={handleKeyboardEventDown}
          ></TextInput>
        </Row>
        <Row className="mb-2">
          <TextInput
            label={t('ecoTaxPage.ecopartCode')}
            onChange={value => {
              setParams({...params, ecopart_code: value});
            }}
            errors={validationErrors.ecopart_code}
            value={params.ecopart_code}
            onKeyPress={handleKeyboardEventDown}
          ></TextInput>
        </Row>
        <Row className="mb-2">
          <TextInput
            label={t('ecoTaxPage.ecopartRate')}
            onChange={ecopart_rate => setParams({...params, ecopart_rate})}
            value={params.ecopart_rate}
            errors={validationErrors.ecopart_rate}
            onKeyPress={handleKeyboardEventDown}
          ></TextInput>
        </Row>
        <Row>
          <div>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default EcotaxCreateForm;
