import {userApi} from '../endpoints';
import {UserState} from '../types';
import {isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';
import {WritableDraft} from 'types/writable-draft';

const updateUserStatusMatcher = isAnyOf(
  userApi.endpoints.updateUserStatus.matchFulfilled
);

/**
 * Update user status
 *
 */
const updateUserStatusReducer = (
  state: WritableDraft<UserState>,
  {payload}: PayloadAction<ResponseData<User>>
) => {
  const {data: user} = payload;
  state.statuses[user.id] = user.status;
};

export const updateUserStatus = {
  matcher: updateUserStatusMatcher,
  reducer: updateUserStatusReducer,
};
