import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {ProductVariants} from '../../container/actions-tab/send-label/use-action-order-send-label';
import {DocumentType} from 'features/commercial/documents/types/commercial-document';

const sendEmailOrder = api.injectEndpoints({
  endpoints: builder => ({
    sendEmailOrder: builder.mutation<Response, Request>({
      query: params => {
        const url =
          params.document_type === 'SUPPLIER'
            ? `orders/${params.id}/send-mail?for_supplier`
            : `orders/${params.id}/send-mail`;

        if (params.document_type !== 'SUPPLIER') delete params.product_variants;

        return {
          method: 'POST',
          url,
          body: {
            ...params,
            packing_list: params.has_packing_list_attachment,
            stock_summary: params.has_stock_summary_attachment,
            options_sheet: params.has_options_sheet_attachment,
          },
        };
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
  body?: string;
  emails?: string[];
  document_type: DocumentType;
  product_variants?: ProductVariants[];
  has_order_attachment?: boolean;
  has_packing_list_attachment?: boolean;
  has_options_sheet_attachment?: boolean;
  has_stock_summary_attachment?: boolean;
};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useSendEmailOrderMutation} = sendEmailOrder;
