import {GlobalState} from './types';
import {application} from 'constants/application';
import {PerPageOptions} from 'features/listing/constants';

export const initialState: GlobalState = {
  isBusy: true,
  locale: application.defaultLocale,
  wsClient: {
    status: 'disconnected',
  },
  settings: {
    alert_count: {},
    commercial: {},
    fiscal: {},
    general: {},
    mailing: {},
  },
  perPage: PerPageOptions.at(0)?.id,
};

export type WSClientStatus = 'connected' | 'disconnected';
