import React, {FC, useContext, useMemo} from 'react';
import Button from 'components/button';
import {CommercialProduct} from '../../types/commercial-product';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import {useSelector} from 'react-redux';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import clsx from 'clsx';
import documentContext from '../document-context/document-context';
import DocumentIs from '../document-is/document-is';

interface Props {
  item: CommercialProduct;
}

const StockMovementButton: FC<Props> = props => {
  const {item} = props;

  const dispatch = useDispatch();

  const {doc} = useContext(documentContext);

  const handleShowStockMovementModal = () => {
    // until this is returned with resource, we use this if statement
    const doc_type = doc?.document_type;
    const dtIn =
      doc_type === 'CREDIT' ||
      doc_type === 'CREDIT_ACCOUNTING' ||
      doc_type === 'SUPPLIER';

    dispatch(
      showModal(MODAL.COMMERCIAL_PRODUCT_STOCK_MOVEMENT, {
        item,
        type: dtIn ? 'IN' : 'OUT',
        doc,
      })
    );
  };

  const isRemoteId = useSelector(selectIsRemoteObject(item.id));

  const isInDocs =
    doc?.document_type === 'CREDIT_ACCOUNTING' ||
    doc?.document_type === 'SUPPLIER' ||
    doc?.document_type === 'CREDIT' ||
    doc?.document_type === 'DEFAULT';

  const showStockButton = item.handel_stock && isRemoteId && isInDocs;

  const staleQuantity = useMemo(() => {
    return item.quantity;
  }, [item.updated_at]);

  return (
    <div>
      {showStockButton && (
        <Button
          block
          color={clsx({
            success: item?.stock_is_full,
            danger: !item?.stock_is_full,
          })}
          className="p-0 mt-2"
          size="sm"
          disabled={item.stock_is_full}
          onClick={handleShowStockMovementModal}
        >
          {`${item.calculated_quantity} / ${staleQuantity}`}
        </Button>
      )}
    </div>
  );
};

export default StockMovementButton;
