export default (url: string, params: Record<string, any>) => {
  const queryParams = new URLSearchParams(params);
  const separator = url.includes('?') ? '&' : '?';
  queryParams.forEach((value, key) => {
    if (value === null || value === undefined || value.length === 0) {
      queryParams.delete(key);
    }
  });
  if (queryParams.toString().length !== 0) {
    return `${url}${separator}${queryParams.toString()}`;
  }
  return url;
};
