import React from 'react';
import messages from '../messages';
import ProductIllustration from './illustration.svg';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Row, Col} from 'reactstrap';

const StemProduct = () => {
  const {id} = useParams();

  const {t} = useTranslation();

  return (
    <div>
      <Row className="justify-content-center mb-3">
        <Col md={4}>
          <img className="img-fluid mb-4" src={ProductIllustration} />
          <div className="text-center text-muted">
            Nostrud amet laboris duis exercitation cillum. Tempor cillum anim
            tempor ipsum in aliqua culpa.
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="justify-content-center d-flex">
          <NavLink
            className="btn btn-primary"
            to={`/dashboard/products/${id}/add-variant`}
          >
            <span>{t(...messages.createVariant())}</span>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
};

export default StemProduct;
