import {Invoice} from '../../types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';
import {updateCachedInvoiceFromResource} from './show-invoice';

const updateInvoice = api.injectEndpoints({
  endpoints: builder => ({
    updateInvoice: builder.mutation<Response, UpdateInvoiceRequest>({
      query: params => {
        const body: Partial<PartialUpdateInvoiceTaxesRequest> = {};

        let taxMode: Invoice['tax_mode'] = 'NO-VAT-NO-DETAX';

        if (!params.with_vat && params.is_detaxed) {
          taxMode = 'DETAXED';
        }
        if (params.with_vat && !params.is_detaxed) {
          taxMode = 'NO-VAT';
        }

        if (taxMode === 'DETAXED' || taxMode === 'NO-VAT-NO-DETAX') {
          body.vat_number = params.vat_number;
          body.vat_mode = params.vat_mode?.value;
        }
        return {
          method: 'PUT',
          url: `invoices/${params.id}`,
          body: {
            directory_id: params.client?.id,
            commercial_type_id: params.commercial_type?.id,
            document_category_id: params.document_category?.id,
            origin_id: params.origin?.id,
            language: params.language?.value,
            ref_client: params?.client_reference,
            project_id: params?.project?.id,
            currency: params.currency?.value,
            billing_date: params.billing_date,
            due_date: params.due_date,
            document_type: params.document_type,
            project_fee_type: params.projectFeesType?.value,
            delivery_date: params.delivery_date,
            is_after_sale_service: params.is_after_sale_service,
            currency_conversion: params.currency_conversion,
            private_comment: params.private_comment,
            designation: params.designation,
            ...body,
            tax_mode: taxMode,
            transporter_id: params.transporter?.id,
            transporter_name: params.transporter?.display_name,
            transporter_price: params.transporter_price,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const i = (await queryFulfilled).data?.data;
          dispatch(updateCachedInvoiceFromResource(i));
        } catch {}
      },
    }),
  }),
});

export type UpdateInvoiceRequest = {
  id?: ObjectId;
  client?: Option;
  document_category?: Option;
  client_display_name?: string;
  client_reference?: string;
  commercial_type?: Option;
  origin?: Option;
  language?: any;

  currency?: Option;
  project?: Option;
  category?: Option;
  due_date?: string;
  billing_date?: string;
  document_type?: string;
  delivery_date?: string;
  is_after_sale_service?: boolean;
  currency_conversion?: string;
  private_comment?: string;
  projectFeesType?: Option;
  designation?: string;

  // vat and taxes
  vat_number?: string;
  vat_mode?: Option;
  is_detaxed?: boolean;
  vatIncluded?: boolean;
  with_vat?: boolean;
  // transporter
  transporter?: Option;
  transporter_price?: string;
};

export type PartialUpdateInvoiceTaxesRequest = {
  vat_number?: string;
  vat_mode?: ObjectId | undefined;
  with_vat?: boolean;
  is_detaxed?: boolean;
};

type Response = ResponseData<Invoice>;

export const {useUpdateInvoiceMutation} = updateInvoice;
