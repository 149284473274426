import {selectDomain} from '.';
import {createSelector} from '@reduxjs/toolkit';
import {CommercialProductOptionType} from 'types/commercial-product-option';
import {ObjectId} from 'types/object-id';
import denormalizeObject from 'utils/denormalize-object';

export default (lineId: ObjectId, type?: CommercialProductOptionType) =>
  createSelector([selectDomain], domain => {
    const options = domain.itemOptions;
    return denormalizeObject(options).filter(x => {
      if (type) {
        return x.commercial_product_id === lineId && x.type === type;
      }
      return x.commercial_product_id === lineId;
    });
  });
