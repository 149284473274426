import selectEatenSouls from 'features/listing/list/slice/selectors/select-eaten-souls';
import {useSelector} from 'react-redux';
import {Model} from 'types/model';
import {ResourceName} from 'types/resource-name';

export default <T extends Model>(name: ResourceName, arrayLike?: T[]) => {
  const souls = arrayLike ?? [];

  const eatenSouls = useSelector(selectEatenSouls(name));

  // this can be optimized further to make it loop only once, but for now, this will do
  const saints = souls.filter(i => !eatenSouls.includes(String(i.id)));

  const lost = souls.filter(i => eatenSouls.includes(String(i.id)));

  return {
    saints,
    lost,
  };
};
