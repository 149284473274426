import messages from './messages';
import {STATUS} from 'constants/status';
import {__} from 'utils/messages';

export const STATUES = [
  {
    id: STATUS.ENABLED,
    name: messages.enabled,
  },
  {
    id: STATUS.DISABLED,
    name: messages.disabled,
  },
];
export const ARCHIVES = [
  {
    id: false,
    name: messages.all,
  },
  {
    id: true,
    name: messages.archivedUser,
  },
];
