import React, {FC, useContext} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Button} from 'reactstrap';
import {useDispatch} from 'react-redux';
import {MODAL} from 'features/modal-notch/constants';
import showModal from 'store/ui/actions/show-modal';
import {CommercialProduct} from '../../types/commercial-product';
import documentContext from '../../containers/document-context/document-context';

interface Props {
  item: CommercialProduct;
}

const OtherOptionsButton: FC<Props> = props => {
  const {item} = props;

  const dispatch = useDispatch();

  const {doc, doc_type, is_locked} = useContext(documentContext);

  const handleOpenOptionsModal = () => {
    dispatch(
      showModal(MODAL.ITEM_OPTIONS_MODAL, {
        item,
        doc,
        doc_type,
      })
    );
  };

  return (
    <Button
      color="link"
      size="sm"
      disabled={is_locked}
      className="dragHandle p-0"
      onClick={handleOpenOptionsModal}
    >
      <Icon name="edit" color="gray" size={20} />
    </Button>
  );
};

export default OtherOptionsButton;
