import React from 'react';
import TagsItems from 'components/tags-card';
import {Col, Row} from 'reactstrap';
import Currency from 'components/currency/currency';
import {useSelector} from 'react-redux';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {OverpaymentPaginationMeta} from './types';
import {useTranslation} from 'react-i18next';
import messages from '../messages';

export const TagsRow = () => {
  const {t} = useTranslation();

  const meta = useSelector(
    selectEntityMeta<OverpaymentPaginationMeta>('overpayments')
  );

  return (
    <Row className="justify-content-around mb-3">
      <Col sm={6}>
        <TagsItems title={t(...messages.totalSoldRemaining())}>
          <Currency value={meta?.solde ?? 0} />
        </TagsItems>
      </Col>
      <Col sm={6}>
        <TagsItems title={t(...messages.totalOverpaymentsGenerated())}>
          {meta?.total ?? 0}
        </TagsItems>
      </Col>
    </Row>
  );
};
