import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {CommercialProductOptionType} from 'types/commercial-product-option';
import {ObjectId} from 'types/object-id';

export default createAction(
  actions.addItemOption.type,
  function prepare(line: ObjectId, type: CommercialProductOptionType) {
    return {
      payload: {
        line,
        type,
      },
    };
  }
);
