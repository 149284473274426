import {useGetStockLocationsQuery} from 'features/production/store/endpoints/get-stock-locations';
import React, {FC, useMemo} from 'react';
import LocationItem from './location-item/location-item';
import {Col, Row, Spinner} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import {ObjectId} from 'types/object-id';

interface Props {
  id: ObjectId;
}
const LocationAvailability: FC<Props> = props => {
  const {id} = props;

  const {data, isFetching} = useGetStockLocationsQuery({
    id,
  });
  const currentLocations = data?.data ?? [];

  const total = useMemo(() => {
    let q = 0;
    currentLocations?.forEach(x => {
      q += x.quantity ?? 0;
    });
    return q;
  }, [data]);

  const {t} = useTranslation();
  return (
    <div>
      <Row>
        <Col>
          <strong>{t(...messages.stockLocation())}</strong>
        </Col>
        <Col md={2}>
          <strong>{t(...messages.quantity())}</strong>
        </Col>
        <Col md={2}>
          <strong>{t(...messages.status())}</strong>
        </Col>

        <Col>
          <strong className="ps-2">{t(...messages.comment())}</strong>
        </Col>
      </Row>
      {currentLocations.map(l => (
        <LocationItem key={l.id} location={l} hasStatus />
      ))}
      <div className="d-flex justify-content-center">
        {isFetching && <Spinner size="sm" />}
      </div>
      <hr />
      <Row>
        <Col>
          <div className="text-end fw-bold">
            {t(...messages.totalInStock())}
          </div>
        </Col>
        <Col>
          <div className="text-start fw-bold">{total}</div>
        </Col>
      </Row>
      <br />
    </div>
  );
};

export default LocationAvailability;
