import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectProduct} from 'store/production/endpoints/show-product';
import {
  UpdateProductRequest,
  useUpdateProductMutation,
} from 'store/production/endpoints/update-product';
import {selectLatestCreatedFamily} from 'store/production/selectors/select-latest-stored-family';
import toast from 'store/ui/actions/toast';
import {Product} from 'types/product';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useProductForm = () => {
  const [updateProduct, {isLoading, error, data: response}] =
    useUpdateProductMutation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const [params, setParams] = useState<Params>({
    name: '',
    description: '',
    comment: '',
    in_catalog: false,
    product_family: [],
    label: '',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updateProduct(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const latestFamily = useSelector(selectLatestCreatedFamily);

  useEffect(() => {
    if (latestFamily) {
      const familyOption = {
        id: latestFamily.id,
        name: latestFamily.name,
      };
      setParams({
        ...params,
        product_family: [...params.product_family, familyOption],
      });
    }
  }, [latestFamily]);

  const {id} = useParams();

  const {data} = useSelector(selectProduct({id}));

  const product = data?.data as Product;
  useEffect(() => {
    if (!params.product_collection) {
      setParams({
        ...params,
        product_family: [],
      });
    }
  }, [params.product_collection]);
  useEffect(() => {
    if (product) {
      setParams({
        id: product.id,
        name: product.name,
        label: '',
        product_collection: product.product_collection,
        product_family: product.product_families,
        vat: product.vat,
        comment: product.comment,
        description: product.description,
        in_catalog: product.in_catalog,
      });
    }
  }, [product]);

  return {
    isLoading,
    params,
    setParams,

    submit: handleSubmit,
    validationErrors: serverError.errors,
  };
};

type Params = UpdateProductRequest;
