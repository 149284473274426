import {application} from 'constants/application';

/**
 * Get a specific route from the API base url's origin
 *
 * @param {string} route Route name, eg: www.example.com/{route}
 * @returns {string} composed url, eg: www.example.com/route
 */
const composeRoute = (route: string): string => {
  const reactAppApiBaseUrl = String(process.env.REACT_APP_API_BASE_URL);
  let splitUrl = reactAppApiBaseUrl.split('/');
  let scheme = splitUrl[0];
  let host = splitUrl[2];
  let url = scheme + '//' + host;
  return `${url}/${route}`;
};

export default composeRoute;
