import {createAsyncThunk} from '@reduxjs/toolkit';
import facebookSignInService from 'services/facebook-signin';
import {FbR} from 'services/facebook-signin/types';

/**
 * Facebook login action
 *
 */
export const loginWithFacebook = createAsyncThunk(
  'oauth/loginWithFacebook',
  async (response: FbR, thunkApi) => {
    const result = await facebookSignInService.signIn(response);
    if (!result.isSuccessful) {
      return thunkApi.rejectWithValue('Login canceled');
    }
    return result;
  }
);
