import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.paymentsPage.title, 'Payments list'),
  export: () => __(translations.paymentsPage.export, 'Export'),

  // columns
  invoiceOrderNumber: () => __(translations.paymentsPage.invoiceNumber, 'Invoice / Order number'),
  relatedDocument: () => __(translations.paymentsPage.relatedDocument, 'Related document'),
  amount: () => __(translations.paymentsPage.amount, 'Amount'),
  paymentType: () => __(translations.paymentsPage.paymentType, 'Payment type'),
  paymentMethod: () => __(translations.paymentsPage.paymentMethod, 'Payment method'),
  paymentDate: () => __(translations.paymentsPage.paymentDate, 'Payment date'),
  actions: () => __(translations.paymentsPage.actions, 'Actions'),

  // payment types
  settlement: () => __(translations.paymentsPage.SETTLEMENT, 'Settlement'),
  deposit: () => __(translations.paymentsPage.DEPOSIT, 'Deposit'),
  refund: () => __(translations.paymentsPage.REFUND, 'Refund'),

  // also payment method
  bank_fees: () => __(translations.paymentsPage.BANK_FEES, 'Bank fees'),

  // payments methods
  cb: () => __(translations.paymentsPage.CB, 'CB'),
  cheque: () => __(translations.paymentsPage.CHEQUE, 'Cheque'),
  bank_transfer: () => __(translations.paymentsPage.BANK_TRANSFER, 'Bank transfer'),
  amex: () => __(translations.paymentsPage.AMEX, 'AMEX'),
  cash: () => __(translations.paymentsPage.CASH, 'Cash'),
  credit: () => __(translations.paymentsPage.CREDIT, 'Credit'),
  overpayment: () => __(translations.paymentsPage.OVERPAYMENT, 'Overpayment'),

  // actions
  reconcilingPayment: () => __(translations.paymentsPage.reconcilingPayment, 'Reconciling payment'),
  reconciledPayment: () => __(translations.paymentsPage.reconciledPayment, 'Payment reconciled'),

  // filters
  invoiceType: () => __(translations.paymentsPage.invoiceType, 'Invoice type'),
};
