import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ICommission, Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectCommissions = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectCommissions: builder.mutation<
      Response,
      StoreProjectCommissionsRequest
    >({
      query: body => {
        return {
          method: 'POST',
          url: `projects/${body.id}/commissions`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export interface ICommissionRequest extends ICommission {
  directory_id: ObjectId;
  invoice_id: ObjectId;
}

export type StoreProjectCommissionsRequest = {
  id: ObjectId;
  commissions: ICommissionRequest[];
  commissions_global_comment?: string;
  deleted_records: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectCommissionsMutation} = storeProjectCommissions;
