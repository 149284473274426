import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.stockProductsPage.action, 'Action'),
  category: () => __(translations.stockProductsPage.location, 'Location'),
  title: () => __(translations.stockProductsPage.title, 'Stock Products'),
  edit: () => __(translations.stockProductsPage.edit, 'Edit'),
  enter: () => __(translations.stockProductsPage.enter, 'Enter'),
  location: () => __(translations.stockProductsPage.location, 'Location'),
  product: () => __(translations.stockProductsPage.product, 'Product'),
  stockLocation: () => __(translations.stockProductsPage.stockLocation, 'Stock Location'),
  status: () => __(translations.stockProductsPage.status, 'Status'),
  serialNumber: () => __(translations.stockProductsPage.serialNumber, 'Serial Number'),
};
