import {
  updateStockProductRequest,
  useUpdateStockProductMutation,
} from 'features/stock/store/endpoint/update-stock-product';
import {StockProduct} from 'features/stock/types/stock-products';
import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ResourceName} from 'types/resource-name';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useStockProductForm = () => {
  const [submit, {isLoading, error}] = useUpdateStockProductMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);
  const {stockProduct, path} = modalParams as {
    stockProduct: StockProduct;
    path: {name: ResourceName; url: string};
  };
  const [params, setParams] = useState<Params>({
    id: stockProduct.id,
    serial_number: stockProduct.code,
    validated: stockProduct.status,
    comment: stockProduct.comment,
    invalid_reason: stockProduct.invalid_reason,
    stock_sub_location: stockProduct.stock_sub_location,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(loadData({name: path.name, url: path.url, page: 1}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    stockProduct,
    submit: handleSubmit,
  };
};

type Params = updateStockProductRequest;
