import React from 'react';
import logo from '../../assets/images/logo-sm.svg';
import CarouselPage from '../auth-inner/CarouselPage';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Alert, Container, Input, Label, Form} from 'reactstrap';

const Register = () => {
  const {user, registrationError} = useSelector((state: any) => ({
    user: state.register.user,
    registrationError: state.register.registrationError,
    loading: state.register.loading,
  }));

  document.title = 'Register | Minia - React Admin & Dashboard Template';

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" />{' '}
                        <span className="logo-txt">Minia</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">
                          Get your free Minia account now.
                        </p>
                      </div>
                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={e => {
                          e.preventDefault();
                          return false;
                        }}
                      >
                        {user && user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="mb-0">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="mb-0">Username</Label>
                          <Input
                            name="username"
                            type="text"
                            placeholder="Enter username"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="mb-0">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the Minia{' '}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{' '}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            Login{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Minia . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
