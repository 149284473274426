import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useActionProductMutation} from 'store/production/endpoints/action-product';
import {useGetProductVariantsQuery} from 'store/production/endpoints/get-product-variants';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useActionProductForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error, isSuccess}] = useActionProductMutation();
  const [selectAll, setSelectAll] = useState(false);

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const modalParams = useSelector(selectActiveModalParams);

  const {productId: id, messageAlert, detailsMessage, path} = modalParams;

  const [selectedVariants, setSelectedVariants] = useState<
    Record<ObjectId, boolean>
  >({});
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const result = await submit({
      selectedVariants,
      all_are_selected: selectAll,
      url: modalParams.url,
      id,
    });
    if ('data' in result) {
      if (path === 'duplicate') {
        navigate(`/dashboard/products/${result?.data?.data?.id}/general`);
      } else {
        navigate(path);
      }

      toast.success(result?.data?.message);
      onDismiss();
    }
  };

  const {data} = useGetProductVariantsQuery({id}, {skip: !id});

  const variants = data?.data ?? [];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedVariants(
      variants.reduce(
        (acc, v) => ({
          ...acc,
          [v.id]: !selectAll,
        }),
        {}
      )
    );
  };

  const toggleVariant = (v: ProductVariant) => {
    const updatedSelectedVariants = {
      ...selectedVariants,
      [v.id]: !selectedVariants[v.id],
    };
    setSelectedVariants(updatedSelectedVariants);

    const allSelected = variants.every(
      variant => updatedSelectedVariants[variant.id]
    );
    setSelectAll(allSelected);
  };
  return {
    variants,
    selectedVariants,
    toggleVariant,
    toggleSelectAll,
    selectAll,
    messageAlert,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    detailsMessage,
    submit: handleSubmit,
  };
};

type CopyParams = {};
