import {useEffect, useState} from 'react';
import {addMonths} from 'date-fns';
import {
  useStoreOrderMutation,
  StoreOrderRequest,
} from 'features/commercial/orders/store/endpoints/store-order';
import {useLocation, useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {now} from 'utils/now';
import realDate from 'utils/real-date';
import toast from 'store/ui/actions/toast';
import {useDispatch} from 'react-redux';
import {useLocalStorage, useReadLocalStorage} from 'usehooks-ts';
import {VariantToOrder} from 'features/stock/products-stock-grid/containers/product-alert-details-table/types';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useStockOrdersForm = () => {
  const [storeOrder, {isLoading, error}] = useStoreOrderMutation();
  const [variantsToOrder, setvariantsToOrderOnLocalStorage] = useLocalStorage<
    VariantToOrder[] | undefined
  >('variantsToOrder', []);

  // get query params from route
  const fromAlertPage = useLocation().search.includes('fromAlertPage');

  const serverError = parseValidationErrors(error);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeOrder({...params, products: variantsToOrder});
    if ('data' in result) {
      const order = result?.data.data;
      setvariantsToOrderOnLocalStorage(undefined);
      navigate(`/dashboard/orders/${order.id}/details`);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  useEffect(() => {
    if (!fromAlertPage) {
      setvariantsToOrderOnLocalStorage(undefined);
    }
  }, []);

  const [params, setParams] = useState<Params>({
    client: undefined,
    document_type: {
      id: 3,
      value: 'STOCK',
    },
    category: {
      id: 1,
      value: 'STOCK',
    },
    type: undefined,
    supplier_type: undefined,
    origin: {
      id: 1,
      value: 'STOCK',
    },
    language: undefined,
    order_date: now(),
    due_date: realDate(addMonths(new Date(), 1)),
  });

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreOrderRequest;
