import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

export const getOrderLabelDefaultMailContent = api.injectEndpoints({
  endpoints: builder => ({
    getOrderLabelDefaultMailContent: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `/data/default-mail-content/for-send-labels`,
        params: {
          local: params.local,
        },
      }),
    }),
  }),
});

type Request = {
  local?: string;
};

type Response = ResponseData<{content: string}>;

export const {useGetOrderLabelDefaultMailContentQuery} =
  getOrderLabelDefaultMailContent;
