import {updateCachedVariantFromResource} from './get-product-variants';
import {updateCachedVariantDetailsFromResource} from './show-variant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const variantToNewProduct = api.injectEndpoints({
  endpoints: builder => ({
    variantToNewProduct: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/${params.id}/transfer`,
          body: {
            product_id: params.product_id,
          },
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        const result = (await queryFulfilled).data.data;
        dispatch(updateCachedVariantFromResource(result));
        dispatch(updateCachedVariantDetailsFromResource(result));
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
  product_id: ObjectId;
};

type Response = ResponseData<ProductVariant>;

export const {useVariantToNewProductMutation} = variantToNewProduct;
