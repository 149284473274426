import {Invoice} from 'features/commercial/invoices/types/invoice';
import {useLazyGetProjectSellsFFEInvoicesQuery} from 'features/studio/projects/store/get-project-sells-ffe-invoices';
import {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {Option} from 'types/option';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {
  StoreProjectGlobalCommentsRequest,
  useStoreProjectGlobalCommentsMutation,
} from 'features/studio/projects/store/store-project-global-comments';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {CommercialProduct} from 'features/commercial/documents/types/commercial-product';
import {InvoiceFfeVintage} from 'features/studio/projects/types/invoice-ffe-vintage';

export const useSellsFFEForm = (): ISellsFFEForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState<Params>({
    invoices: [],
    sells_ffe_global_comment: '',
    product_type: {} as Option,
  });

  const {id} = useParams();

  const [getInvoices] = useLazyGetProjectSellsFFEInvoicesQuery();

  const [updateGlobalComment, {isLoading: isSubmitting}] =
    useStoreProjectGlobalCommentsMutation();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const getCalculatedPrice = ({
    quantity,
    unit_price_ht,
    discount,
  }: CommercialProduct) => {
    return Math.round(
      Number(unit_price_ht) -
        Number(unit_price_ht) * Number(quantity) * (Number(discount) / 100)
    );
  };

  useEffect(() => {
    setParams({
      ...params,
      sells_ffe_global_comment: project?.sells_ffe_global_comment,
    });

    (async () => {
      setIsLoading(true);
      const result = await getInvoices({
        id,
        product_type: params.product_type?.value as string,
      }).refetch();

      if (!result.data?.data) return;

      const invoices = result.data?.data;
      setParams(prev => {
        return {...prev, invoices} as Params;
      });
      setIsLoading(false);
    })();
  }, [params.product_type]);

  const handleSubmit = async () => {
    const result = await updateGlobalComment({
      id,
      sells_ffe_global_comment: params.sells_ffe_global_comment,
    } as StoreProjectGlobalCommentsRequest);

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const grand_total_ht = useMemo(() => {
    return params.invoices.reduce(
      (acc, el) => acc + Number(el.marge_infos?.selling_price_ht_calculated),
      0
    );
  }, [params.invoices]);

  const grand_margin_excl = useMemo(() => {
    return params.invoices.reduce(
      (acc, el) => acc + Number(el.marge_infos?.marge_ht),
      0
    );
  }, [params.invoices]);

  const options = {
    product_types: [
      {
        label: t(...messages.mobilier()),
        value: 'mobilier_only',
      },
      {
        label: t(...messages.petitsObjets()),
        value: 'petits_objets_only',
      },
    ],
  };

  return {
    isLoading,
    isSubmitting,
    params,
    options,
    grand_total_ht,
    grand_margin_excl,
    getCalculatedPrice,
    setParams,
    submit: handleSubmit,
  };
};

export default interface ISellsFFEForm {
  isLoading: boolean;
  isSubmitting: boolean;
  params: Params;
  options: {
    product_types: {label: string; value: string}[];
  };
  grand_total_ht: number;
  grand_margin_excl: number;
  getCalculatedPrice: (args: CommercialProduct) => number;
  setParams: (args: Params) => void;
  submit: () => void;
}

type Params = {
  invoices: InvoiceFfeVintage[];
  sells_ffe_global_comment?: string;
  product_type?: Option;
};
