import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  manDays: () => __(translations.editProjectManDaysSection.manDays, 'Man Days'),
  addManday: () => __(translations.editProjectManDaysSection.addManday, 'Add a Man Day'),
  manDaysIncludedInContract: () => __(translations.editProjectManDaysSection.manDaysIncludedInContract, 'Man Days included in contract'),
  manDaysRemaining: () => __(translations.editProjectManDaysSection.manDaysRemaining, 'Man Days remaining'),
  manDaysConsumed: () => __(translations.editProjectManDaysSection.manDaysConsumed, 'Man Days consumed'),
  others: () => __(translations.editProjectManDaysSection.others, 'Others'),
  scale: () => __(translations.editProjectManDaysSection.scale, 'Scale'),

  // inputs
  comment: () => __(translations.editProjectManDaysSection.comment, 'Comment'),
  location: () => __(translations.editProjectManDaysSection.location, 'Location'),
  reason: () => __(translations.editProjectManDaysSection.reason, 'Reason'),
  date: () => __(translations.editProjectManDaysSection.date, 'Date'),
  invoiceNubmer: () => __(translations.editProjectManDaysSection.invoiceNubmer, 'Invoice Number'),
  includedInContract: () => __(translations.editProjectManDaysSection.includedInContract, 'Included in contract'),
  team: () => __(translations.editProjectManDaysSection.team, 'Team'),
  manDayIndia: () => __(translations.editProjectManDaysSection.manDayIndia, 'Man Day (India)'),
  manDayProjectManager: () => __(translations.editProjectManDaysSection.manDayProjectManager, 'Man Day (Project Manager)'),
  manDayAssistant: () => __(translations.editProjectManDaysSection.manDayAssistant, 'Man Day (Assistant)'),

  assistant: () => __(translations.editProjectManDaysSection.assistant, 'Assistant'),
  projectManager: () => __(translations.editProjectManDaysSection.projectManager, 'Project manager'),
};
