import {authActions} from '../auth-slice';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {clearAuthState} from 'services/auth-service';
import googleSignInService from 'services/google-signin';

const purgeAuthState = createAsyncThunk('auth/purge', async (_, thunkApi) => {
  const result = clearAuthState();
  thunkApi.dispatch(authActions.logout());
  await googleSignInService.logout();
  return result;
});

export default purgeAuthState;
