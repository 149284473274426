import {Search} from '../types';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getGlobalSearch = api.injectEndpoints({
  endpoints: builder => ({
    getGlobalSearch: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `search`,
          params: {keyword: params.search},
        };
      },
    }),
  }),
});

type Request = {
  search: string;
};

type Response = ResponseData<Search[]>;

export const {useLazyGetGlobalSearchQuery} = getGlobalSearch;
