import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import './style.scss';
import {useUpdateCostsForm} from './use-update-costs-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {
  Alert,
  Card,
  CardBody,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const UpdateCostsForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    setParams,
    isLoading,
    validationErrors,
    errorMessage,
    variants,
  } = useUpdateCostsForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <div className="mb-3">
            <TextInput
              label={t(...messages.publicPriceExclTax())}
              onChange={public_price_ht =>
                setParams({...params, public_price_ht})
              }
              value={params.public_price_ht}
              errors={validationErrors.public_price_ht}
            />
          </div>
          <div className="mb-3">
            <TextInput
              label={t(...messages.decoratorPriceExclTax())}
              onChange={decorator_price_ht =>
                setParams({...params, decorator_price_ht})
              }
              value={params.decorator_price_ht}
              errors={validationErrors.decorator_price_ht}
            />

            <TextInput
              label={t(...messages.resellerPriceExclTax())}
              onChange={resseler_price_ht =>
                setParams({...params, resseler_price_ht})
              }
              value={params.resseler_price_ht}
              errors={validationErrors.resseler_price_ht}
            />
          </div>

          <div className="row ">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
        <div className="scroll-modal">
          {variants.map(v => (
            <Card key={v.id} outline>
              <CardBody className="p-2">
                <div className="text-uppercase">{v.name}</div>
              </CardBody>
            </Card>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default UpdateCostsForm;
