import {Invoice} from 'features/commercial/invoices/types/invoice';
import {useLazyGetProjectSourcingFeesInvoicesQuery} from 'features/studio/projects/store/get-project-sourcing-fees-invoices';
import {selectProject} from 'features/studio/projects/store/show-project';
import {
  StoreProjectGlobalCommentsRequest,
  useStoreProjectGlobalCommentsMutation,
} from 'features/studio/projects/store/store-project-global-comments';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import toast from 'store/ui/actions/toast';

export const useSourcingFeesForm = (): ISourcingFeesForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [params, setParams] = useState<Params>({
    invoices: [],
    sourcing_fees_global_comment: '',
  });

  const {id} = useParams();

  const [getInvoices] = useLazyGetProjectSourcingFeesInvoicesQuery();

  const [updateGlobalComment, {isLoading: isSubmitting}] =
    useStoreProjectGlobalCommentsMutation();

  const handleChangeGlobalComment = (sourcing_fees_global_comment: string) => {
    setParams({...params, sourcing_fees_global_comment});
  };

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  useEffect(() => {
    setParams({
      ...params,
      sourcing_fees_global_comment: project?.sourcing_fees_global_comment,
    });

    (async () => {
      setIsLoading(true);
      const result = await getInvoices({id});

      if (!result.data?.data) return;

      const _invoices = result.data?.data;
      const invoices = _invoices.map(item => {
        return {
          ...item,
          total_ht: item.commercial_products.reduce(
            (acc, {quantity, unit_price_ht}) =>
              acc + Number(unit_price_ht) * Number(quantity),
            0
          ),
          total_commission: item.commercial_products.reduce(
            (acc, {quantity, unit_price_ht, discount}) =>
              acc +
              Number(unit_price_ht) *
                Number(quantity) *
                (Number(discount) / 100),
            0
          ),
        };
      });

      setParams(prev => {
        return {
          ...prev,
          invoices,
        } as Params;
      });

      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    const result = await updateGlobalComment({
      id,
      sourcing_fees_global_comment: params.sourcing_fees_global_comment,
    } as StoreProjectGlobalCommentsRequest);

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const {grand_total_ht, grand_total_commission} = useMemo(() => {
    return {
      grand_total_ht: params.invoices.reduce(
        (acc, el) => acc + Number(el.total_ht),
        0
      ),
      grand_total_commission: params.invoices.reduce(
        (acc, el) => acc + Number(el.total_commission),
        0
      ),
    };
  }, [params.invoices]);

  return {
    isLoading,
    params,
    grand_total_ht,
    grand_total_commission,
    isSubmitting,
    submit: handleSubmit,
    handleChangeGlobalComment,
  };
};

export default interface ISourcingFeesForm {
  isLoading: boolean;
  params: Params;
  grand_total_ht: number;
  grand_total_commission: number;
  isSubmitting: boolean;
  submit: () => void;
  handleChangeGlobalComment: (val: string) => void;
}

type Params = {invoices: Invoice[]; sourcing_fees_global_comment?: string};
