import React, {FC, useMemo} from 'react';
import {Label} from './use-action-order-send-label';
import AsyncItemPicker from 'components/async-item-picker';

const LabelLine: FC<Props> = props => {
  const {label, index, childIndex, validationErrors, updateLabel} = props;

  return (
    <tr
      style={
        childIndex
          ? {borderTopStyle: 'hidden'}
          : label.children
          ? {borderBottomStyle: 'hidden'}
          : {}
      }
    >
      <td style={{width: '100px'}}>
        <code>{label?.variant?.sku}</code>
      </td>
      <td>{label.custom_name}</td>
      <td style={{width: '100px'}}>{label?.quantity}</td>
      <td style={{width: '240px'}}>
        <AsyncItemPicker
          endPoint="data/stock-locations"
          errors={
            validationErrors[`product_variants.${index}.stock_location_id`]
          }
          value={label.stock_location}
          disabled={label.label_is_sent}
          onChange={v => {
            updateLabel(index, childIndex, 'stock_location', v);
          }}
        />
      </td>

      <td style={{maxWidth: '200px'}}>
        <div className="d-flex justify-content-center">
          {label.children === undefined && (
            <input
              className="form-check-input my-1"
              type="checkbox"
              disabled={label.label_is_sent || label.label_is_sent}
              onChange={() => {
                updateLabel(index, childIndex, 'generate', 'boolean');
              }}
              checked={!!label.generate || label.label_is_sent}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

interface Props {
  label: Label;
  index: number;
  syncLabelSupplierEmail?: any;
  childIndex?: number;
  updateLabel?: any;
  validationErrors?: any;
}
export default LabelLine;
