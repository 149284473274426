import messages from './messages';
import {SERIAL_NUMBER_STATUS} from 'types/product-variant';

export const CUSTOM_SERIAL_NUMBER_STATUSES: Record<
  SERIAL_NUMBER_STATUS,
  {color: string; text: any}
> = {
  [SERIAL_NUMBER_STATUS.OPENED]: {
    color: 'light',
    text: 'serialNumberTab.opened',
  },
  [SERIAL_NUMBER_STATUS.PENDING]: {
    color: 'warning',
    text: 'serialNumberTab.pending',
  },
  [SERIAL_NUMBER_STATUS.VALIDATED]: {
    color: 'success',
    text: 'serialNumberTab.validated',
  },
  [SERIAL_NUMBER_STATUS.WAITING_FOR_DELIVERY]: {
    color: 'warning',
    text: 'serialNumberTab.waiting_for_delivery',
  },
  [SERIAL_NUMBER_STATUS.DEDUCTED]: {
    color: 'success',
    text: 'serialNumberTab.deducted',
  },
  [SERIAL_NUMBER_STATUS.CANCELED]: {
    color: 'danger',
    text: 'serialNumberTab.canceled',
  },
};
