import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.loginPage.title, 'Welcome'),
  subTitle: () => __(translations.loginPage.subTitle, 'Please, sign in to continue'),
  rememberMe: () => __(translations.loginPage.rememberMe, 'Remember me'),
  signIn: () => __(translations.loginPage.signIn, 'Sign in'),
  forgotYourPassword: () => __(translations.loginPage.forgotYourPassword, 'Forgot your password?'),
  recoverAccount: () => __(translations.loginPage.recoverAccount, 'Recover account'),
  signUpNow: () => __(translations.loginPage.signUpNow, 'Sign-up now!'),
  password: () => __(translations.loginPage.password, 'Password'),
  email: () => __(translations.loginPage.email, 'E-mail'),
  pageTitle: () => __(translations.loginPage.pageTitle, 'Sign-in'),
  dontHaveAnAccount: () => __(translations.loginPage.dontHaveAnAccount, "Don't have an account?"),
};
