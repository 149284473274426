import {selectDomain} from '.';
import _ from 'lodash';
import {createSelector} from 'reselect';
import {ResourceName} from 'types/resource-name';

export default (name: ResourceName) => {
  return createSelector([selectDomain], listing => {
    const rows = _.get(listing, `selectedResources.${name}`);

    if (!!rows) {
      const ids = Object?.keys(rows);
      return ids;
    }

    return [];
  });
};
