import Currency from 'components/currency/currency';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  CardBody,
  Col,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import messages from '../messages';
import TextInput from 'components/text-input';
import IVintageForm from '../use-vintage-form';
import ListSkeleton from './skeleton';

const BodySection: FC<Props> = ({
  params,
  isLoading,
  getCalculatedPrice,
  setParams,
}) => {
  const {t} = useTranslation();

  const {invoices, sells_vintage_global_comment} = params;

  return (
    <CardBody>
      <div className="mb-4">
        <TextInput
          type="textarea"
          value={sells_vintage_global_comment}
          label={t(...messages.comment())}
          onChange={sells_vintage_global_comment =>
            setParams({...params, sells_vintage_global_comment})
          }
        />
      </div>
      <hr />
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <>
          {' '}
          {invoices.length > 0 ? (
            <UncontrolledAccordion stayOpen>
              {invoices.map((item, x) => (
                <AccordionItem key={x}>
                  <AccordionHeader targetId={x.toString()}>
                    <Row className="w-100">
                      <Col lg={3}>
                        <h6>{t(...messages.invoiceNumber())}</h6>
                        <p>
                          <Link
                            target="_BLANK"
                            to={`/dashboard/invoices/${item.id}/details`}
                          >
                            {item.document_ref}
                          </Link>
                        </p>
                      </Col>
                      <Col lg={3}>
                        <h6>{t(...messages.billingDate())}</h6>
                        <p>
                          {new Date(item.billing_date).toLocaleDateString()}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <h6>{t(...messages.totalHT())}</h6>
                          <p>
                            <strong>
                              <Currency
                                value={
                                  item.marge_infos?.selling_price_ht_calculated
                                }
                              />
                            </strong>
                          </p>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <h6>{t(...messages.dueDate())}</h6>
                        <p>{item.payment_summary?.last_payment_date}</p>
                      </Col>
                    </Row>
                    <Badge
                      color={
                        !item.billing_date &&
                        !item.payment_summary?.last_payment_date
                          ? 'black'
                          : item.billing_date &&
                            !item.payment_summary?.last_payment_date
                          ? 'danger'
                          : 'success'
                      }
                      className="position-absolute top-0 end-0 mt-3 me-2"
                    >
                      {!item.billing_date &&
                      !item.payment_summary?.last_payment_date
                        ? t(...messages.toInvoice())
                        : item.billing_date &&
                          !item.payment_summary?.last_payment_date
                        ? t(...messages.pending())
                        : t(...messages.paid())}
                    </Badge>
                  </AccordionHeader>
                  <AccordionBody accordionId={x.toString()}>
                    <Row>
                      <Col lg={3}>
                        <h6>{t(...messages.products())}</h6>
                      </Col>
                      <Col lg={3}>
                        <h6>{t(...messages.quantity())}</h6>
                      </Col>
                      <Col lg={6} />
                    </Row>
                    {item.commercial_products.length > 0 ? (
                      <>
                        {item.commercial_products.map((product, y) => (
                          <div key={x + y}>
                            <Row>
                              <Col lg={3}>
                                <Link
                                  target="_BLANK"
                                  to={`/dashboard/products/${product.variant?.product?.id}/variants/${product.product_variant_id}/general`}
                                >
                                  {product.custom_name}
                                </Link>
                              </Col>
                              <Col lg={3}>
                                <p>{product.quantity}</p>
                              </Col>
                              <Col lg={6}>
                                <Currency value={getCalculatedPrice(product)} />
                              </Col>
                            </Row>
                            <hr
                              style={{
                                display:
                                  y < item.commercial_products.length - 1
                                    ? 'block'
                                    : 'none',
                              }}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <p className=" text-center">{t('common.noData')}</p>
                    )}
                  </AccordionBody>
                </AccordionItem>
              ))}
            </UncontrolledAccordion>
          ) : (
            <div className="d-flex justify-content-center py-4">
              <p>{t('common.noData')}</p>
            </div>
          )}
        </>
      )}
    </CardBody>
  );
};

type Props = IVintageForm;

export default BodySection;
