import React, {FC} from 'react';
import {IAttachmentsForm, IRegroupedAttachments} from '../use-attachments-form';
import {CardBody, CardTitle} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import {AttachmentAccordion} from './attachment-accordion';
import {ProjectAttachmentTypesEnum} from 'features/studio/projects/constants/project-attachments';

export const BodySection: FC<Props> = props => {
  const {t} = useTranslation();

  // attachments must be separated in 2 categories
  const categorizedAttachments = [
    {
      name: t(...messages.clientProjectFlow()),
      regroupedAttachments:
        props.regroupedAttachments.filter(isClientProjectFlow),
    },
    {
      name: t(...messages.communication()),
      regroupedAttachments: props.regroupedAttachments.filter(isCommunication),
    },
  ];

  return (
    <CardBody>
      {categorizedAttachments.map(({name, regroupedAttachments}) => (
        <>
          <CardTitle tag="h4" className="mt-4">
            {name}
          </CardTitle>
          <AttachmentAccordion
            {...props}
            regroupedAttachments={regroupedAttachments}
          />
        </>
      ))}
    </CardBody>
  );
};

const isClientProjectFlow = (element: IRegroupedAttachments) =>
  element.type === ProjectAttachmentTypesEnum.HONORARIA_PROPOSAL ||
  element.type === ProjectAttachmentTypesEnum.CONTRACT ||
  element.type === ProjectAttachmentTypesEnum.CONTRACTUAL_PLAN ||
  element.type === ProjectAttachmentTypesEnum.THREE_D ||
  element.type === ProjectAttachmentTypesEnum.IMAGES_PHOTOS;

const isCommunication = (element: IRegroupedAttachments) =>
  element.type === ProjectAttachmentTypesEnum.HUB ||
  element.type === ProjectAttachmentTypesEnum.VIDEO ||
  element.type === ProjectAttachmentTypesEnum.PRESS;

type Props = IAttachmentsForm;
