import React from 'react';
import messages from './messages';
import {useProfileForm} from './use-profile-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import DateInput from 'components/date-input';
import TextInput from 'components/text-input';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {CardTitle, Col, FormFeedback, Row} from 'reactstrap';

const ProfilePage = () => {
  const {t} = useTranslation();
  const {
    submit,
    isSuccess,
    params,
    setParams,
    isLoading,
    errorMessage,
    validationErrors,
  } = useProfileForm();

  const handleUpdateProfile = () => {
    submit();
  };

  return (
    <div className="">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="font-size-15">{t(...messages.title())}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <div className="mt-2 pt-50">
              <Row>
                <Col sm="6" className="mb-1">
                  <TextInput
                    id="first_name"
                    label={t(...messages.firstName())}
                    name="first_name"
                    value={params.first_name}
                    onChange={first_name => setParams({...params, first_name})}
                    errors={validationErrors?.last_name}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <TextInput
                    id="last_name"
                    label={t(...messages.lastName())}
                    name="last_name"
                    value={params.last_name}
                    onChange={last_name => setParams({...params, last_name})}
                    errors={validationErrors?.last_name}
                  />
                </Col>

                <Col sm="6" className="mb-1">
                  <TextInput
                    id="phone_number"
                    label={t(...messages.phoneNumber())}
                    name="phone_number"
                    value={params.phone_number}
                    onChange={phone_number =>
                      setParams({...params, phone_number})
                    }
                    errors={validationErrors?.phone_number}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <TextInput
                    id="address"
                    label={t(...messages.address())}
                    name="address"
                    value={params.address}
                    onChange={address => setParams({...params, address})}
                    errors={validationErrors?.address}
                  />
                </Col>

                <Col sm="6" className="mb-1">
                  <DateInput
                    label={t(...messages.dateOfBirth())}
                    value={params.born_at}
                    onChange={born_at => setParams({...params, born_at})}
                    errors={validationErrors.born_at}
                  />
                  <FormFeedback>{validationErrors?.born_at}</FormFeedback>
                </Col>
                <Col className="mt-2" sm="12">
                  <AppBarActions>
                    <Button
                      type="submit"
                      className="me-1"
                      onClick={handleUpdateProfile}
                      isLoading={isLoading}
                      color="important"
                    >
                      {t('common.saveChanges')}
                    </Button>
                  </AppBarActions>
                </Col>
              </Row>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProfilePage;
