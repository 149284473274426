import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const storeFamily = api.injectEndpoints({
  endpoints: builder => ({
    storeFamily: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `product-families`,
          body: {
            name: params.name,
            code: params.code,
            inspiration: params.inspiration,
            product_collections: params.collections?.map(x => x.id),
          },
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  name?: string;
  code?: string;
  inspiration?: string;
  collections?: Option[];
};

type Response = ResponseData<ProductFamily>;

export const {useStoreFamilyMutation} = storeFamily;

export const storeFamilyEndpoint = storeFamily.endpoints.storeFamily;
