import {Quotation} from 'types/quotation';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedQuotationFromResource} from './show-quotation';

const updateStatusQuotation = api.injectEndpoints({
  endpoints: builder => ({
    updateStatusQuotation: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'PUT',
          url: `/quotations/${params.id}/toggle-lock`,
          body: {},
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const q = (await queryFulfilled).data?.data;
          dispatch(updateCachedQuotationFromResource(q));
        } catch {
          // do nothing
        }
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Quotation>;

export const {useUpdateStatusQuotationMutation} = updateStatusQuotation;
