import React, {FC} from 'react';
import BatchActions from './batch-actions';
import Collections from './collections';
import ExportAction from './exports-section';
import Filters from './filters';
import TagsRow from './tags-row';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Button, CardBody, Col, Row} from 'reactstrap';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const GridControls: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <CardBody className="pb-0 mb-0">
      <TagsRow filters={filters} />
      <Filters
        filters={filters}
        onChangeFilters={onChangeFilters}
        onClearFilters={onClearFilters}
        additionnalFilters={BatchActions}
      />
      <Can action="export" subject="product-prices">
        <ExportAction filters={filters} />
      </Can>
      <Collections filters={filters} onChangeFilters={onChangeFilters} />
    </CardBody>
  );
};

export default GridControls;
