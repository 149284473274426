import {useContext} from 'react';
import {ProjectTypeEnum} from '../constants/project-type';
import {EnumMapping} from './use-enum-as-option';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

export const useFilterTypesByPermission = (
  arr: EnumMapping<typeof ProjectTypeEnum>[]
) => {
  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;
  const isPetitsProjects = checkAbility(
    'administration',
    'studio-petits-projects'
  );

  const isStudioProjects = checkAbility('administration', 'studio-projects');

  if (isPetitsProjects && isStudioProjects) return arr;

  if (isPetitsProjects) return arr.filter(el => el.id === 'PETITS_PROJETS');

  return arr.filter(el => el.id !== 'PETITS_PROJETS');
};
