import {loadTableDataEndpoint} from './endpoints/load-table-data';
import {actions} from './index';
import makeSelectEntityFilters from './selectors/make-select-entity-filters';
import {FilterMap, ListConfig} from './types';
import serializeFilters from './utils/serialize-filters';
import {PayloadAction} from '@reduxjs/toolkit';
import {SharedPrefs} from 'constants/shared-prefs';
import {put, takeLatest, select, delay} from 'redux-saga/effects';
import {saveString} from 'services/storage-service';

/**
 * Load data request
 */
export function* loadData(action: PayloadAction<ListConfig>) {
  yield delay(500);
  // Select credentials
  const {name} = action.payload;

  const filters: FilterMap = yield select(makeSelectEntityFilters(name));
  const url = serializeFilters(action, filters).toString();
  // @ts-ignore
  const promise = yield put(loadTableDataEndpoint.initiate({url}));

  try {
    // @ts-expect-error
    const response = yield promise.unwrap();

    yield put(
      actions.loadDataSuccess({
        name,
        data: response.data,
        meta: response.meta,
      })
    );
  } catch (e: any) {
    yield put(actions.loadDataError({name, meta: e?.data}));
  }
  promise.unsubscribe();
}

export function* persistLimit(action: PayloadAction<any>) {
  const key = action.payload.key;
  const value = action.payload.value;
  if (key === 'limit') {
    saveString(SharedPrefs.PER_PAGE, value);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* tableSaga() {
  // Watches for list actions and calls loadData when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadData.type, loadData);
  yield takeLatest(actions.changeFilter.type, persistLimit);
}
