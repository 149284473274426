import React, {useEffect} from 'react';
import companiesColumns from './companies-columns';
import contactsColumns from './contacts-columns';
import DirectoryFilters from './filters-section';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Table from 'features/table';
import {actions} from 'features/table/slice';
import makeSelectEntityFilters from 'features/table/slice/selectors/make-select-entity-filters';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'reactstrap';

const CompaniesDirectoryPage = () => {
  const {t} = useTranslation();

  const filters = useSelector(makeSelectEntityFilters('directories'));

  const dispatch = useDispatch();

  useEffect(() => {
    const dd = filters['filter[directory_type]'];
    if (!dd) {
      dispatch(
        actions.changeFilter({
          name: 'directories',
          key: 'filter[directory_type]',
          value: 'INDIVIDUAL',
        })
      );
    }
  }, [filters]);

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.pageTitle()), path: '/dashboard/directories'},
          ]}
        />
        <Table
          columns={
            filters['filter[directory_type]'] === 'COMPANY'
              ? companiesColumns
              : contactsColumns
          }
          hideSearch
          otherParams={{
            delimiter: '|',
          }}
          url="directories"
          name="directories"
          filtersComponent={DirectoryFilters}
        />
      </Container>
    </div>
  );
};

export default CompaniesDirectoryPage;
