import {
  StoreLocationRequest,
  useStoreLocationMutation,
} from 'features/stock/store/endpoint/store-location';
import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {Quotation} from 'types/quotation';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useLocationForm = () => {
  const [submit, {isLoading, error}] = useStoreLocationMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const [params, setParams] = useState<Params>({
    name: '',
    stock_location_category: undefined,
    is_locked: false,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(loadData({name: 'stock-locations', page: 1}));
      dispatch(uiActions.dismissModalWithResult(result.data.data));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = StoreLocationRequest;
