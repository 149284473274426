import React from 'react';
import {useTranslation} from 'react-i18next';
import {CardHeader, CardTitle} from 'reactstrap';
import messages from '../messages';

const HeaderSection = () => {
  const {t} = useTranslation();

  return (
    <CardHeader>
      <CardTitle tag="h1">{t(...messages.expenses())}</CardTitle>
    </CardHeader>
  );
};

export default HeaderSection;
