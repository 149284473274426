import {StockProduct} from 'features/stock/types/stock-products';
import StockSublocation from 'features/stock/types/stock-sub-location';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const transferProduct = api.injectEndpoints({
  endpoints: builder => ({
    transferProduct: builder.mutation<Response, transferProductRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `/stock/products-in-stock/transfer`,
          body: {
            serial_number: params.serial_number,
            stock_sub_location_id: params.stock_sub_location?.id,
          },
        };
      },
    }),
  }),
});

export type transferProductRequest = {
  serial_number?: string;
  stock_sub_location?: Option;
  stockProduct?: StockProduct;
};

type Response = ResponseData<StockSublocation>;

export const {useTransferProductMutation} = transferProduct;
