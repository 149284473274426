import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  add: () => __(translations.variantAttachments.add, 'Add an attachment'),
  date: () => __(translations.variantAttachments.date, 'Date'),
  delete: () => __(translations.variantAttachments.delete, 'Delete'),
  editImage: () => __(translations.variantAttachments.editImage, 'Edit image'),
  file: () => __(translations.variantAttachments.file, 'File'),
  fileName: () => __(translations.variantAttachments.fileName, 'File name'),
  image: () => __(translations.variantAttachments.image, 'Image'),
  inCatalog: () => __(translations.variantAttachments.inCatalog, 'In catalog'),
  name: () => __(translations.variantAttachments.name, 'Name'),
  show: () => __(translations.variantAttachments.show, 'Show in browser'),
  title: () => __(translations.variantAttachments.title, 'variantAttachments of {{name}}'),
  type: () => __(translations.variantAttachments.type, 'Type of file'),
  upload: () => __(translations.variantAttachments.upload, 'Upload a file'),
};
