import {CostsFormState} from 'pages/dashboard/products/edit/variant/costs/slice/types';
import {TotalFields} from 'pages/dashboard/products/edit/variant/costs/slice/types';
import {api} from 'services/api';
import {CostCategory} from 'types/cost-category';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductCostItem} from 'types/product-cost-item';
import {ResponseData} from 'types/response-data';
import {Situation} from 'types/situation';

type CostMap = Record<CostCategory, ProductCostItem[]>;

const updateCosts = api.injectEndpoints({
  endpoints: builder => ({
    updateCosts: builder.mutation<Response, CostsRequest>({
      query: params => {
        const situationParams = params.situation;
        const costs = {} as CostMap;
        const categories = Object.keys(params.costs) as CostCategory[];
        categories.forEach((c, index) => {
          const lines = Object.values(params.costs[c]);
          costs[c] = lines.map(mapCostField(c));
        });

        const totals = params.totals;

        // const vat = Number(params.vat?.value ?? 0);

        // const vatCoe = vat / 100 + 1;

        return {
          method: 'POST',
          url: `/products/${params.id}/variants/${params.variant}/prices`,
          body: {
            situation_id: situationParams?.id,
            situation_name: situationParams?.name,
            is_main: !!situationParams?.is_main,
            product_id: params.id,
            variant_id: params.variant,
            vat_id: params.vat?.value,
            costs,
            prices: {
              public_price: {
                product_price_category_id: 1, // public price
                price_ht: fixNumber(totals.public_price),
                // price_ttc: fixNumber(Number(totals.public_price) * vatCoe),
              },
              decorator_price: {
                product_price_category_id: 2, // decorator
                price_ht: fixNumber(totals.decorator_price),
                // price_ttc: fixNumber(Number(totals?.decorator_price) * vatCoe),
                discount: fixNumber(totals.decorator_price_discount),
              },
              reseller_price: {
                product_price_category_id: 3, // reseller
                price_ht: fixNumber(totals.reseller_price),
                // price_ttc: fixNumber(Number(totals?.reseller_price) * vatCoe),

                discount: fixNumber(totals.reseller_price_discount),
              },
            },
            deleted_records: Object.keys(totals.deleted_records).filter(
              x => !!totals.deleted_records[x]
            ),
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'variants', id: result?.data.product_variant_id}];
        }
        return [];
      },
    }),
  }),
});

export type CostsRequest = {
  id?: ObjectId;
  situation?: Partial<Situation>;
  vat?: Option;
  variant?: ObjectId;
  costs: CostsFormState['fields'];
  totals: Record<keyof TotalFields, any>;
};

type Response = ResponseData<Situation>;

/**
 * Maps the cost item field to match that of the server
 * @param field
 * @returns
 */
const mapCostField = (c: CostCategory) => (field: ProductCostItem) => {
  return {
    comment: field.comment,
    component_id: field?.component?.id ?? null,
    cost_per_unit: fixNumber(field.cost_per_unit),
    damped_on: field.damped_on,
    directory_id: field.directory?.id ?? null,
    id: field.id,
    product_cost_category_id: costByType[c],
    cusomization_option_id: field.cusomization_option?.id,
    product_cost_type_id: field.product_cost_type?.id,
    quantity: fixNumber(field.quantity),
    total_cost: fixNumber(field.total_cost),
  };
};

const fixNumber = (numberLike?: string | number) => {
  return Number(Number(numberLike).toFixed(2));
};

export const {useUpdateCostsMutation} = updateCosts;

/**
 * Note that those are real IDs that exist in the DB
 *
 */
const costByType: Record<CostCategory, ObjectId> = {
  components: 1,
  development: 2,
  logistic: 3,
  other: 4,
};
