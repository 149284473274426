import {useState} from 'react';
import messages from '../messages';
import {
  StoreMaterialRequest,
  useStoreMaterialMutation,
} from '../store/endpoints/store-material';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useMaterialForm = (onDismiss: () => void) => {
  const [storeMaterial, {isLoading, error}] = useStoreMaterialMutation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);
  const {actions} = useListSlice();
  const name = 'materials';
  const url = 'materials';
  const handleSubmit = async () => {
    const result = await storeMaterial(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.materialCreatedSuccess())));
      dispatch(actions.clearSelection(name));
      dispatch(actions.loadData({url, name}));
      onDismiss();
    }
  };

  const [params, setParams] = useState<Params>({
    name: {
      en: '',
      fr: '',
    },
  });

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreMaterialRequest;
