import React from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Directory} from 'types/directory';

const getDirectoryNameWithOverlap = (d: Directory) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>{d?.display_name}</div>
      <IconContainer>
        <Link target="_blank" to={`/dashboard/directories/${d?.id}/general `}>
          <Icon name="external-link" size={15} />
        </Link>
      </IconContainer>
    </div>
  );
};
export default getDirectoryNameWithOverlap;

const IconContainer = styled.div`
  z-index: 1;
  background: none;
  text-align: center;
  position: absolute;
  right: 0;
`;
