import React, {useMemo} from 'react';
import {useShowInvoiceQuery} from '../../store/endpoints/show-invoice';
import {Invoice} from '../../types/invoice';
import InvoiceDetailsRouter from '../invoice-details-router/invoice-details-router';
import InvoiceSummarySection from '../invoice-summary-section/invoice-summary-section';
import InvoiceTabPane from '../invoice-tab-pane/invoice-tab-pane';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Button from 'components/button';
import DocumentSkeleton from 'features/commercial/documents/components/document-skeleton';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import ActionsTab from '../actions-tab/actions-tab';

const InvoiceMasterDetail = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const {data, isFetching, error, isError, isSuccess, refetch} =
    useShowInvoiceQuery({id});

  const invoice = data?.data as Invoice;
  const title = useMemo(() => {
    let title = '';
    if (invoice) {
      title =
        invoice?.document_type === 'DEFAULT'
          ? t(...messages.title())
          : t(...messages.creditTitle());
    }
    return title;
  }, [invoice]);
  const tabTitle = useMemo(() => {
    let title = '';
    if (invoice) {
      title =
        invoice?.document_type !== 'DEFAULT'
          ? t(...messages.creditHelmetTitle(), {
              ref: invoice?.document_ref,
            })
          : t(...messages.invoiceHelmetTitle(), {
              ref: invoice?.document_ref,
            });
    }
    return title;
  }, [invoice]);

  const serverError = parseValidationErrors(error);

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>{tabTitle}</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            breadcrumbItems={[
              {
                title: t('common.dashboard'),
                path: '/dashboard',
              },
              {
                title: title,
                path:
                  invoice?.document_type !== 'DEFAULT'
                    ? '/dashboard/credits '
                    : '/dashboard/invoices',
              },
              {
                title: title,
                path: '#',
              },
            ]}
          />
          {/* Content */}

          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  {isSuccess && (
                    <InvoiceSummarySection
                      invoice={invoice}
                      onReload={refetch}
                    />
                  )}
                  <InvoiceTabPane />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {isFetching && <DocumentSkeleton />}

                  {isError && (
                    <div>
                      <Alert color="danger">{serverError.message}</Alert>
                      <div className="d-flex justify-content-center py-3">
                        <Button onClick={refetch}>
                          <Icon name="refresh-cw" size={15} className="me-2" />
                          Reload
                        </Button>
                      </div>
                    </div>
                  )}

                  {isSuccess && <InvoiceDetailsRouter />}
                  {isSuccess && <ActionsTab invoice={invoice} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceMasterDetail;
