import {createSelector} from 'reselect';
import {RootState} from 'store';

export const selectDomain = (state: RootState) => state.ui;

/**
 * Returns whether or not to show the tutorial
 *
 */
export const selectShowTutorial = createSelector([selectDomain], uiState => {
  return uiState.showTutorial;
});

/**
 * Returns the current modal
 *
 */
export const selectActiveModal = createSelector(
  [selectDomain],
  uiState => uiState.modal
);

/**
 * Returns the current modal
 *
 */
export const selectActiveModalParams = createSelector(
  [selectDomain],
  uiState => uiState.modal?.params
);
