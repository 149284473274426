import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.customerOrderPage.title, 'Customer Orders'),
  countermark: () => __(translations.customerOrderPage.countermark, 'Countermark'),
  number: () => __(translations.customerOrderPage.number, 'Number'),
  invoice: () => __(translations.customerOrderPage.invoice, 'Invoice'),
  proformaInvoice: () => __(translations.customerOrderPage.proformaInvoice, 'Proforma Invoice'),
  date: () => __(translations.customerOrderPage.date, 'Date'),
  contact: () => __(translations.customerOrderPage.contact, 'Contact'),
  total_ht: () => __(translations.customerOrderPage.total_ht, 'Total excl taxes'),
  total_ttc: () => __(translations.customerOrderPage.total_ttc, 'Total incl taxes'),
  advances: () => __(translations.customerOrderPage.advances, 'Instalments'),
  payments: () => __(translations.customerOrderPage.payments, 'Payments'),
  balance: () => __(translations.customerOrderPage.balance, 'Balance'),
  status: () => __(translations.customerOrderPage.status, 'Status'),
  nullOrderTooltip: () => __(translations.customerOrderPage.nullOrderTooltip, 'No records were found'),
  type: () => __(translations.customerOrderPage.type, 'Type'),
  category: () => __(translations.customerOrderPage.category, 'Category'),
  ref_client: () => __(translations.customerOrderPage.ref_client, 'Customer ref'),
  sorting: () => __(translations.customerOrderPage.sorting, ' Sorting of results'),
  paymentDate: () => __(translations.customerOrderPage.paymentDate, 'Payment date'),
  statedDate: () => __(translations.customerOrderPage.statedDate, 'Announced deadline'),
  startDate: () => __(translations.customerOrderPage.startDate, 'Start date'),
  endDate: () => __(translations.customerOrderPage.endDate, 'End date'),
  family: () => __(translations.customerOrderPage.family, 'Family'),
  product: () => __(translations.customerOrderPage.product, 'Product'),
  vintage: () => __(translations.customerOrderPage.vintage, 'Vintage product'),
  productionStatedDate: () => __(translations.customerOrderPage.productionStatedDate, 'Announced deadline'),
  orderDate: () => __(translations.customerOrderPage.orderDate, 'Order date'),
  addCustomerOrder: () => __(translations.customerOrderPage.addCustomerOrder, 'New customer order'),
  totalCustomerOrders: () => __(translations.customerOrderPage.totalCustomerOrders, 'Total  customer orders'),
  totalPendingCustomerOrders: () => __(translations.customerOrderPage.totalPendingCustomerOrders, 'Total pending customer orders'),
  totalValidCustomerOrders: () => __(translations.customerOrderPage.totalValidCustomerOrders, 'Total valid customer orders'),
  totalExpiredCustomerOrders: () => __(translations.customerOrderPage.totalExpiredCustomerOrders, 'Total expired customer orders'),
  deposit: () => __(translations.customerOrderPage.deposit, 'Deposit'),
  totalCustomertotalHt: () => __(translations.customerOrderPage.totalCustomertotalHt, 'Total orders HT'),
  totalCustomertotalTtc: () => __(translations.customerOrderPage.totalCustomertotalTtc, 'Total orders TTC'),
  totalCustomertotalPayment: () => __(translations.customerOrderPage.totalCustomertotalPayment, 'Total orders payment'),
  totalCustomertotalAcompte: () => __(translations.customerOrderPage.totalCustomertotalAcompte, 'Total orders acompte'),
  totalCustomertotalSolde: () => __(translations.customerOrderPage.totalCustomertotalSolde, 'Total orders solde'),
  model: () => __(translations.customerOrderPage.model, 'Model'),
  loan: () => __(translations.customerOrderPage.loan, 'Loan'),
  proforma: () => __(translations.customerOrderPage.proforma, 'Proforma'),
  withoutVat: () => __(translations.customerOrderPage.withoutVat, 'Without VAT'),
  withVat: () => __(translations.customerOrderPage.withVat, 'With VAT'),
  withDutyFree: () => __(translations.customerOrderPage.withDutyFree, 'With duty free'),
  vatMode: () => __(translations.customerOrderPage.vatMode, 'VAT mode'),
  export: () => __(translations.customerOrderPage.export, 'Export'),
};
