import {createSelector} from '@reduxjs/toolkit';
import {selectProduct} from 'store/production/endpoints/show-product';
import {ObjectId} from 'types/object-id';
import {Product} from 'types/product';

export default (id?: ObjectId) =>
  createSelector(
    [
      selectProduct({
        id,
      }),
    ],
    x => {
      if (!id) {
        return {coe: 1};
      }

      const spinOff = x?.data?.data as Product;
      const unpack = spinOff?.vat?.value;
      const y = Number(unpack ?? 0);
      const mesh = y / 100 + 1;
      return {coe: mesh, vat: spinOff?.vat};
    }
  );
