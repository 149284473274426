import {lastDecade} from './utils';
import {TFunction} from 'react-i18next';

export const getOptions = (t: TFunction<'translation', undefined>,categories?:string[],numberOfPoint?:number) =>
  Object({
    dataLabels: {
      enabled: true,
      formatter:  (val: any) => {
        return val? val : '';
      },
      offsetY: -30,
      offsetX: 0,
      style: {
        fontSize: '14px',
        colors: ['#ADB5BD']
        
        
      },
      
    },
    noData: {
      text: t('masterProductStats.noDataAvailable'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
    stroke: {
      colors: '#000',
      width: 1,
    },
    fill: {
      colors: '#849c51',
      opacity: 0.5,
    },

    colors: ['#556ee6'],
    xaxis: {
      type: 'category',
      categories: categories? categories : lastDecade(),
    },
    yaxis: {
      forceNiceScale: true,
      decimalsInFloat: 0,
      max: numberOfPoint? numberOfPoint + 2 : undefined,  
     
    },
    
    grid: {
      borderColor: '#fff',
    },
    tooltip: {
      x: {
        format: 'yyyy',
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'top', // position of dataLabels
          
        },
      },
    },

    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          pan: false,
          zoomout: false,
          reset: false,
          customIcons: [],
        },
      },
    },
  });
