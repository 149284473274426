import React, {FC, useEffect} from 'react';
import messages from '../messages';
import SectLoader from './product-filter-skeleton';
import {DirectoryFilters} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Row, Col, Button} from 'reactstrap';
import {DirectorySection} from 'types/directory-section';

interface Props {
  filters: FilterMap<DirectoryFilters>;
  onChangeFilters: (key: DirectoryFilters, value: any) => void;
}

const Sections: FC<Props> = props => {
  const {filters, onChangeFilters} = props;

  const sections = (filters['filter[directory_type]'] ??
    '') as DirectorySection;

  const data = useSelector(selectEntityMeta('directories'));
  const {t} = useTranslation();

  //@ts-ignore

  const directoryMeta = (data ?? []) as DirectorySection;
  const sect = [
    {
      id: 'INDIVIDUAL',
      name: t(...messages.contacts()),
      directory_count: directoryMeta.individuals_count,
    },
    {
      id: 'COMPANY',
      name: t(...messages.companyList()),
      directory_count: directoryMeta?.companies_count,
    },
  ] as DirectorySection[];

  useEffect(() => {
    onChangeFilters('filter[directory_type]', 'INDIVIDUAL');
  }, []);

  const handleClick = (c: DirectorySection) => {
    onChangeFilters('filter[directory_type]', c.id);
  };
  return (
    <div>
      {/* Second row */}
      <Row className="align-items-center ">
        <Col md={12}>
          <div className="mb-3">
            {/* Filters go here */}
            <div className="d-flex flex-wrap gap-2 ">
              {sect.length === 0 ? (
                <SectLoader></SectLoader>
              ) : (
                sect?.map((c: any) => (
                  <Button
                    key={c.id}
                    color={'white'}
                    active={sections === c.id}
                    size="sm"
                    className={` border-1 border-bottom-0 font-size-14 text-center px-3 py-2 ${
                      sections === c.id
                        ? ' border-important text-important '
                        : ' border-gray-light'
                    } `}
                    onClick={() => handleClick(c)}
                  >
                    {c.name}{' '}
                    <span
                      className={`badge  ms-1 font-size-16 ${
                        sections === c.id
                          ? '  text-important border-important border-1 border '
                          : ' text-white bg-important '
                      } `}
                    >
                      {c.directory_count}
                    </span>
                  </Button>
                ))
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Sections;
