import {COLUMN, TYPE} from 'components/async-preview-tooltip/constant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const showAsyncPreviewTooltip = api.injectEndpoints({
  endpoints: builder => ({
    showAsyncPreviewTooltip: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `/tooltips/commercial-documents/${params.id}/info`,
        params: {
          type: params.type,
          column: params.column,
        },
      }),
    }),
  }),
});

type Request = {
  id?: ObjectId;
  type: TYPE;
  column?: COLUMN;
};

type Response = ResponseData<any>;

export const {
  useShowAsyncPreviewTooltipQuery,
  useLazyShowAsyncPreviewTooltipQuery,
} = showAsyncPreviewTooltip;
