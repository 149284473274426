import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.DetailsStockInProductsModal.title, 'Details stock in product'),
  serialNumber: () => __(translations.DetailsStockInProductsModal.serialNumber, 'Serial number'),
  client: () => __(translations.DetailsStockInProductsModal.client, 'Client'),
  quantity: () => __(translations.DetailsStockInProductsModal.quantity, 'Quantity'),
  subLocalisation: () => __(translations.DetailsStockInProductsModal.subLocalisation, 'Sub-localisation'),
};
