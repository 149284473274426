import React from 'react';
import CategoryActions from './location-category-actions';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import StocklocationCatergory from 'features/stock/types/stock-location-category';

const columns: ColumnDef<StocklocationCatergory>[] = [
  {
    header: r => <Trans i18nKey="stockLocationPage.category" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockLocationPage.action" />,
    enableSorting: false,
    accessorFn: row => <CategoryActions stockLocationCategory={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
