import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CardHeader, CardSubtitle, CardTitle, Col, Row} from 'reactstrap';
import TagsItem from 'components/tags-card';
import messages from '../messages';
import Currency from 'components/currency/currency';
import IConclusionForm from '../use-conclusion-form';

const HeaderSection: FC<Props> = ({params, isLoading, round}) => {
  const {t} = useTranslation();

  const {
    total_honoraria,
    total_commissions,
    total_sells_ffe,
    total_sells_vintage,
    total_fees,
    total_sourcing_fees,
  } = params.conclusion;

  return (
    <CardHeader>
      <CardTitle tag="h5">{t(...messages.conclusion())}</CardTitle>
      <Row className="row-gap-2">
        <Col lg={2}>
          <TagsItem title={t(...messages.totalHonoraria())}>
            <Currency value={round(total_honoraria)} />
          </TagsItem>
        </Col>
        <Col lg={2}>
          <TagsItem title={t(...messages.totalFees())}>
            <Currency value={round(total_fees)} />
          </TagsItem>
        </Col>
        <Col lg={2}>
          <TagsItem title={t(...messages.totalSourcingFees())}>
            <Currency value={round(total_sourcing_fees)} />
          </TagsItem>
        </Col>
        <Col lg={2}>
          <TagsItem title={t(...messages.totalCommissions())}>
            <Currency value={round(total_commissions)} />
          </TagsItem>
        </Col>
        <Col lg={2}>
          <TagsItem title={t(...messages.totalSellsFFE())}>
            <Currency value={round(total_sells_ffe)} />
          </TagsItem>
        </Col>
        <Col lg={2}>
          <TagsItem title={t(...messages.totalSellsVintage())}>
            <Currency value={round(total_sells_vintage)} />
          </TagsItem>
        </Col>
      </Row>
    </CardHeader>
  );
};

type Props = IConclusionForm;

export default HeaderSection;
