import React from 'react';
import CategoryActions from './category-actions';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import {DirectoryCategory} from 'types/directory-category';

const columns: ColumnDef<DirectoryCategory>[] = [
  {
    header: r => <Trans i18nKey="directoryCategoriesPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="directoryCategoriesPage.family" />,
    accessorFn: row => (
      <EmptyCell
        cell={
          <div>
            {row.directory_families?.split(',').map(x => (
              <p className="mb-0" key={x}>
                {x.toLowerCase()}
              </p>
            ))}
          </div>
        }
      />
    ),
    id: 'directory_families',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="directoryCategoriesPage.action" />,
    enableSorting: false,
    accessorFn: row => <CategoryActions category={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
