import React, {FC} from 'react';
import DirectoryCharts from './directory-charts';
import InvoiceTable from './invoice-stat/invoice-table';
import OrderTable from './order-stat/order-table';
import QuoteTable from './quote-stat/quote-table';
import SupplierOrderTable from './supplier-order-stat/supplier-order-table';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Row} from 'reactstrap';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {Directory} from 'types/directory';

interface Props {}

const LinkedResourcesTab: FC<Props> = props => {
  const {id} = useParams();
  const {data} = useSelector(selectDirectory({id}));
  const directory = data?.data as Directory;
  return (
    <>
      <Row className="align-items-center justify-content-center mb-3">
        <DirectoryCharts
          id={directory?.id}
          is_supplier={directory?.is_supplier}
        />
      </Row>
      {directory?.is_supplier ? null : (
        <>
          <OrderTable />
          <InvoiceTable />
          <QuoteTable />
        </>
      )}
      <SupplierOrderTable
        id={directory.id}
        is_supplier={directory.is_supplier}
      />
    </>
  );
};

export default LinkedResourcesTab;
