import saveAuthState from '../actions/persist-auth-state';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {application} from 'constants/application';
import {AuthResponse} from 'services/api/response-types';
import {ApiEndpointBuilder} from 'services/api/types';
import {RootState} from 'store';

const registerEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation({
    async queryFn(
      credentials: {},
      {getState, dispatch},
      extra,
      fetchWithBaseQuery
    ) {
      const {fcmToken} = (getState() as RootState).auth;
      const result = await fetchWithBaseQuery({
        url: 'signup',
        method: 'POST',
        body: {
          ...credentials,
          fcm_token: fcmToken,
          platform: application.platform,
        },
      });
      if (result.error) {
        return {error: result.error as FetchBaseQueryError};
      }
      dispatch(saveAuthState(result.data as AuthResponse));
      return result;
    },
  });
};

export default registerEndpoint;
