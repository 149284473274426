import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDettachCompanyMutation} from 'store/directory/endpoints/dettach-company';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDestroyDirectoryForm = (onDismiss: () => void) => {
  const [update, {isLoading, error, isSuccess}] = useDettachCompanyMutation();

  const serverError = parseValidationErrors(error);

  const directory = useSelector(selectActiveModalParams);

  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const result = await update({
      company: directory.company,
      id: directory.id,
    });
    if ('data' in result) {
      dispatch(uiActions.dismissModal());
      toast.success(result.data.data.message);
    }
  };

  return {
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
