import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  team: () => __(translations.editProjectTeamSection.team, 'Team'),
  configureTeam: () => __(translations.editProjectTeamSection.configureTeam, 'Project team configuration'),
  artisticDirector: () => __(translations.editProjectTeamSection.artisticDirector, 'Artistic Director'),
  project_manager: () => __(translations.editProjectTeamSection.project_manager, 'Project Manager'),
  artistic_director: () => __(translations.editProjectTeamSection.artistic_director, 'Artistic Director'),
  assistant: () => __(translations.editProjectTeamSection.assistant, 'Assistant'),
  active: () => __(translations.editProjectTeamSection.active, 'Active'),
  inactive: () => __(translations.editProjectTeamSection.inactive, 'Inactive'),
  all: () => __(translations.editProjectTeamSection.all, 'All'),
  activeOnly: () => __(translations.editProjectTeamSection.activeOnly, 'Active only'),
  inactiveOnly: () => __(translations.editProjectTeamSection.inactiveOnly, 'Inactive only'),
  scaleManDays: () => __(translations.editProjectTeamSection.scaleManDays, '{{scale}} / Man Days'),
  addUser: () => __(translations.editProjectTeamSection.addUser, 'Ajouter un utilisateur'),
  manDays: () => __(translations.editProjectTeamSection.manDays, 'Man Days'),
  configureManDays: () => __(translations.editProjectTeamSection.configureManDays, 'Configure Man Days'),
};
