import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  attachments: () => __(translations.editProjectAttachmentSection.attachments, 'Attachments'),
  attachmentsList: () => __(translations.editProjectAttachmentSection.attachmentsList, 'Attachments List'),
  addAttachment: () => __(translations.editProjectAttachmentSection.addAttachment, 'Ajouter une piéce jointe'),
  image: () => __(translations.editProjectAttachmentSection.image, 'Image'),
  file: () => __(translations.editProjectAttachmentSection.file, 'File'),
  fileName: () => __(translations.editProjectAttachmentSection.fileName, 'File name'),
  clientProjectFlow: () => __(translations.editProjectAttachmentSection.clientProjectFlow, 'Client Project Flow'),
  communication: () => __(translations.editProjectAttachmentSection.communication, 'Communication'),
  date: () => __(translations.editProjectAttachmentSection.date, 'Date'),
  publish: () => __(translations.editProjectAttachmentSection.publish, 'Publish'),
  legendAndCredits: () => __(translations.editProjectAttachmentSection.legendAndCredits, 'Lengend and credits'),
  principalImage: () => __(translations.editProjectAttachmentSection.principalImage, 'Principal Image'),

  // attachments types
  contract: () => __(translations.editProjectAttachmentSection.contract, 'Contract'),
  contractual_plan: () => __(translations.editProjectAttachmentSection.contractual_plan, 'Contractual Plan'),
  images_photos: () => __(translations.editProjectAttachmentSection.images_photos, 'Images / Photos'),
  press: () => __(translations.editProjectAttachmentSection.press, 'Press'),
  honoraria_proposal: () => __(translations.editProjectAttachmentSection.honoraria_proposal, 'Honoraria Proposal'),
  hub: () => __(translations.editProjectAttachmentSection.hub, 'HUB'),
  three_d: () => __(translations.editProjectAttachmentSection.three_d, '3D'),
  video: () => __(translations.editProjectAttachmentSection.video, 'Video'),
};
