import styled from 'styled-components';

const StickyContainer = styled.div`
  position: sticky;
  bottom: 10px;
  
  margin-left: 70px;
  @media (max-width: 992px) {
    margin-left: 0 !important;
  }
`;

export default StickyContainer;
