import React from 'react';
import {useState} from 'react';
import messages from '../messages';
import clsx from 'clsx';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {Alert, Row, Col, Button as RSButton} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {authActions} from 'store/auth/auth-slice';
import {useResetPasswordMutation} from 'store/auth/endpoints';
import {
  selectIsPasswordResetFinished,
  selectResetInfo,
} from 'store/auth/selectors';

const UpdatePasswordSection = () => {
  const {t} = useTranslation();

  const info = useSelector(selectResetInfo);

  const [params, setParams] = useState({
    token: info.resetToken,
    password: '',
    password_confirmation: '',
  });

  const [resetPassword, {isLoading, error}] = useResetPasswordMutation();

  const {errors: validationErrors, message: errorMessage} =
    parseValidationErrors(error);

  const handleSubmit = () => {
    resetPassword(params);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const finish = () => {
    dispatch(authActions.resetPasswordForgot());
    navigate('/login');
  };

  const finished = useSelector(selectIsPasswordResetFinished);

  return (
    <div>
      <div className="text-center">
        <h5 className="mb-0">{t(...messages.pageTitle())}</h5>
        <p className="text-muted mt-2">{t(...messages.resetPasswordHint())}.</p>
      </div>
      <div
        className={clsx({
          'custom-form mt-4': true,
          'd-none': finished,
        })}
      >
        <div className="mb-3">
          <TextInput
            value={params.password}
            label={t(...messages.password())}
            onChange={password => setParams({...params, password})}
            type="password"
            invalid={validationErrors.password}
          />
        </div>
        <div className="mb-3">
          <TextInput
            label={t(...messages.passwordConfirmation())}
            value={params.password_confirmation}
            onChange={password_confirmation =>
              setParams({...params, password_confirmation})
            }
            type="password"
            invalid={validationErrors.password_confirmation}
          />
        </div>
        {errorMessage ? (
          <Alert color="danger" style={{marginTop: '13px'}}>
            {errorMessage}
          </Alert>
        ) : null}
        <Row className="mb-3">
          <Col className="text-end">
            <Button
              color="important"
              className="w-100 waves-effect waves-light"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {t(...messages.submit())}
            </Button>
          </Col>
        </Row>
      </div>
      {/* Success */}
      <div
        className={clsx({
          'd-none': !finished,
        })}
      >
        <Alert color="success">{t(...messages.passwordResetSuccess())}</Alert>
        <div className="mt-5 text-center">
          <p className="  mb-0">
            <RSButton onClick={finish} color="important">
              {' '}
              {t(...messages.backToLogin())}{' '}
            </RSButton>{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordSection;
