import React from 'react';
import {format, parseISO} from 'date-fns';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {Directory} from 'types/directory';

const Tail = () => {
  const {id} = useParams();

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data as Directory;

  const at = format(parseISO(directory.created_at), 'd MMM yyyy');

  return (
    <div className="timeline-launch">
      <div className="timeline-box">
        <div className="timeline-text">
          <h3 className="font-size-18">Créé le {at}</h3>
          <p className="text-muted mb-0">{directory.display_name}</p>
        </div>
      </div>
    </div>
  );
};

export default Tail;
