import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {
  UpdateEcotaxRequest,
  useUpdateEcotaxMutation,
} from 'pages/accounting/store/endpoints/update-ecotax';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

export default () => {
  const [send, {isLoading, error}] = useUpdateEcotaxMutation();

  const serverError = parseValidationErrors(error);
  const modalParams = useSelector(selectActiveModalParams);
  const onSuccess = modalParams?.onSuccess as () => void;
  const dispatch = useDispatch();
  const ecoTax = modalParams?.ecotax;

  const [params, setParams] = useState<Params>({
    id: ecoTax?.id ?? '',
    product_code: ecoTax?.product_code ?? '',
    customs_code: ecoTax?.customs_code ?? '',
    ecopart_code: ecoTax?.ecopart_code ?? '',
    ecopart_rate: ecoTax?.ecopart_rate,
  });

  const handleSubmit = async () => {
    const result = await send(params);
    if ('data' in result) {
      dispatch(loadData({name: 'ecotaxes', page: 1}));
      onSuccess();

      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = UpdateEcotaxRequest;
