import React, {FC} from 'react';
import {EmailMap} from './use-action-invoice-send-email';
import Icon from '@ailibs/feather-react-ts';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';

interface Props {
  email: EmailMap;
  removeEmail: (id: ObjectId) => void;
  updateEmail: any;
  validationErrors: any;
}

const EmailRow: FC<Props> = props => {
  const {email, removeEmail, updateEmail, validationErrors} = props;
  const {t} = useTranslation();

  return (
    <>
      {Object.values(email).map((p, i) => (
        <Row className="mb-3 " key={p.id}>
          <Col>
            <TextInput
              type="text"
              label={t('invoiceActionModal.invoiceEmail')}
              errors={validationErrors[`emails.${i}`]}
              value={p.email}
              onChange={x => updateEmail(p, 'email', x)}
            />
          </Col>
          <Col md="auto">
            <EmptyLabel />
            <Button color="" className="p-1" onClick={() => removeEmail(p.id)}>
              <Icon name="trash-2" size={16} className="text-danger me-1" />
            </Button>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default EmailRow;
