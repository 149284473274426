import {useState} from 'react';
import {faker} from '@faker-js/faker';
import loadData from 'features/table/slice/actions/load-data';
import {
  StoreEcotaxRequest,
  useStoreEcotaxMutation,
} from 'pages/accounting/store/endpoints/store-ecotax';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [send, {isLoading, error}] = useStoreEcotaxMutation();

  const serverError = parseValidationErrors(error);
  const modalParams = useSelector(selectActiveModalParams);
  const onSuccess = modalParams?.onSuccess as () => void;
  const dispatch = useDispatch();

  const fake = useSeeder();

  const [params, setParams] = useState<Params>({
    product_code: __DEV__ ? faker.address.zipCode() : '',
    customs_code: __DEV__ ? faker.address.zipCode() : '',
    ecopart_code: __DEV__ ? faker.address.zipCode() : '',
    ecopart_rate: __DEV__ ? faker.address.zipCode() : '',
  });

  const handleSubmit = async () => {
    const result = await send(params);
    if ('data' in result) {
      dispatch(loadData({name: 'ecotaxes', page: 1}));
      onSuccess();

      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = StoreEcotaxRequest;
