// App.jsx / App.tsx

import React, { Component, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import EventInfo from '@ckeditor/ckeditor5-utils/src/eventinfo'

interface ITextEditor {
    value: string | undefined;
    label: string;
    onChange: {
        (value: string): void
    },
    errors: string[] | string;
}

export const TextEditor = ({ value, label, errors, onChange }: ITextEditor) => {

    const errorMessage = useMemo(() => {
        return Array.isArray(errors) ? errors[0] : errors;
      }, [errors]);

    return (
        <div>
            <label>{label}</label>
            <CKEditor
                editor={ClassicEditor}
                data={value ? value : "<p></p>"}
                onChange={(event, editor) => onChange(editor.getData())}
            />
            {errorMessage ? (
                <div className="invalid-feedback d-block">{errorMessage} </div>
            ) : null}
        </div>
    );
}

