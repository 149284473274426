import React, {FC} from 'react';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {Badge, Card, CardBody, DropdownItem} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import {Address} from 'types/address';

interface Props {
  address: Address;
}
const AddressItem: FC<Props> = props => {
  const {address} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleDeleteAddress = () => {
    dispatch(
      uiActions.confirmDelete({
        name: 'address',
        resourceId: address.id,
        otherParams: {directoryId: address.directory_id},
      })
    );
  };

  const handleEdit = () => {
    dispatch(showModal(MODAL.UPDATE_ADDRESS_FORM, {address}));
  };

  return (
    <div className="col-sm-6 col-xl-3">
      <Card>
        <CardBody>
          

          <h5 className="font-size-15 mb-1 d-flex justify-content-between align-items-center mb-2 ">

           <span>{address.address_type.name}</span> 
           <UncontrolledDropdown direction="start" className="text-end">
            <DropdownToggle className="" tag="i">
              <i className="bx bx-dots-horizontal-rounded" role="button" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} className="w-100" onClick={handleEdit}>
                <span className="align-middle ms-2">{t('common.edit')}</span>
              </DropdownItem>

              <DropdownItem
                tag="button"
                className="text-danger"
                onClick={handleDeleteAddress}
              >
                <span className="align-middle ms-2">{t('common.delete')} </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

            </h5>
          <p className="font-size-15 mb-1 ">{address.label}</p>
          <p className="mb-1">
            {address.first_line}
            {address.second_line?.length > 0 &&
              ', ' + address.second_line + ', '}
            {address.third_line}
          </p>
          <p className="text-muted mb-1">
            {address.display_name_parts.join(',')}
          </p>
          <p className="mb-2">{address.country?.name}</p>

          <h5 className="mb-2 font-size-14">
            {t('updateAddressModal.vatMode')}
            <Badge className="ms-1 font-size-14">{address.vat_mode?.label}</Badge>
          </h5>

          <p>{address.comment}</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddressItem;
