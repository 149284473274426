import React, {FC} from 'react';
import TABS from './constants';
import './credits-tab-pane.styles.scss';
import {useTranslation} from 'react-i18next';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {CreditStatusType} from 'features/acounting/credits/types/credit-status-type';

export const CreditsTabPane: FC<Props> = ({tab, setTab}) => {
  const {t} = useTranslation();

  const handleTabPane = (tab: CreditStatusType) => {
    setTab(tab);
  };

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4 tab-pane">
      {TABS.map(x => (
        <NavItem key={x.tab}>
          <NavLink
            href="#"
            active={tab === x.tab}
            onClick={() => handleTabPane(x.tab)}
          >
            {t(...x.title())}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

type Props = {
  tab: CreditStatusType;
  setTab: (arg: CreditStatusType) => void;
};
