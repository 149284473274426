import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useStockEntryForm} from './use-stock-entry-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Col, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

const UpdateStockStockEntryForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    errorMessage,
    setParams,
    stockProduct,
  } = useStockEntryForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <LineItem
            label={t('DetailsStockProductsModal.product')}
            value={stockProduct.product_variant.name}
          />
          <LineItem label={t(...messages.name())} value={stockProduct.code} />

          <LineItem
            label={t('DetailsStockProductsModal.stockLocation')}
            value={stockProduct.stock_location_name}
          />

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

interface LineItemProps {
  label: string;
  value: string | number;
}

const LineItem: FC<LineItemProps> = props => {
  const {label, value} = props;

  const {t} = useTranslation();

  return (
    <Row className="mb-2">
      <Col md={5} className="fw-bold w-20 font-size-14">
        <b>{label}</b>
      </Col>
      <Col className="text-end ">{value}</Col>
    </Row>
  );
};

export default UpdateStockStockEntryForm;
