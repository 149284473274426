import React, {FC, Fragment} from 'react';
import GalleryView from 'components/gallery-view';
import {Project} from 'types/project';
import {useTranslation} from 'react-i18next';

const ProjectCover: FC<{project: Project}> = props => {
  const {project} = props;

  return (
    <Fragment>
      {project.project_image ? (
        <GalleryView
          images={[project.project_image]}
          previewSize={36}
          size="small"
        />
      ) : null}
    </Fragment>
  );
};

export default ProjectCover;
