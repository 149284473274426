import React from 'react';
import FormSection from './form-section';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
// import images
import {useTranslation} from 'react-i18next';
//redux
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Row, Col, Container, Card} from 'reactstrap';
import {ObjectId} from 'types/object-id';

const EditUser = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.editPageTitle())}</title>
      </Helmet>
      <Container>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t(...messages.dashboard()), path: '/dashboard'},
            {title: t(...messages.users()), path: '/dashboard/users'},
            {title: t(...messages.editTitle()), path: '#'},
          ]}
        />
        <Card className="p-3">
          <Col md="auto">
            <FormSection id={id as ObjectId} />
          </Col>
        </Card>
      </Container>
    </div>
  );
};

export default EditUser;
