import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  oldPassword: () => __(translations.securityTab.oldPassword, 'Old password'),
  password: () => __(translations.securityTab.password, 'Password'),
  passwordConfirmation: () => __(translations.securityTab.passwordConfirmation, 'Password confirmation'),
  passwordLength: () => __(translations.securityTab.passwordLength, 'Minimum 8 characters long - the more, the better'),
  passwordLengthAdvice1: () => __(translations.securityTab.passwordLengthAdvice1, 'At least one lowercase character'),
  passwordLengthAdvice2: () => __(translations.securityTab.passwordLengthAdvice2, 'At least one number, symbol, or whitespace character'),
  passwordRequirements: () => __(translations.securityTab.passwordRequirements, 'Password requirements'),
  saveChanges: () => __(translations.securityTab.saveChanges, 'Save changes'),
  title: () => __(translations.securityTab.title, 'Security'),
};
