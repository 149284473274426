import React, {FC} from 'react';
import messages from '../messages';
import Filters from './filters';
import TagsRow from './tags-row';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CardBody, Col, Row} from 'reactstrap';
import Button from 'components/button';
import {useLocalStorage} from 'usehooks-ts';
import {useLazyGetOrdersExportQuery} from 'features/commercial/orders/store/endpoints/get-export-orders';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const CustomerOrderControls: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const [submitOrders, {isFetching: isLoadingTable}] =
    useLazyGetOrdersExportQuery();
  const dispatch = useDispatch();

  const handleSubmitTables = async (filters: any) => {
    const result = await submitOrders({document_type: 'CLIENT', ...filters});
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <Row>
      <Col>
        <CardBody>
          <TagsRow />

          <Row className="mb-3">
            <AppBarActions>
              <>
                <Can
                  subject={['ccc', 'cch', 'ccr', 'ccv', 'ccz']}
                  action="create-and-edit"
                >
                  <Link
                    to="/dashboard/customer-orders/create"
                    className="btn btn-important"
                  >
                    <Icon name="plus" className="me-1" size={15} />
                    {t(...messages.addCustomerOrder())}
                  </Link>
                </Can>
                <Can
                  subject={['ccc', 'cch', 'ccr', 'ccv', 'ccz']}
                  action="export"
                >
                  <Button
                    color="light"
                    className="ms-2"
                    onClick={() => handleSubmitTables(filters)}
                    disabled={isLoadingTable}
                  >
                    {t(...messages.export())}
                  </Button>
                </Can>
              </>
            </AppBarActions>
          </Row>
          <Filters
            onClearFilters={onClearFilters}
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        </CardBody>
      </Col>
    </Row>
  );
};

export default CustomerOrderControls;
