import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

export const showVariant = api.injectEndpoints({
  endpoints: builder => ({
    showVariant: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `products/${params.productId}/variants/${params.id}`,
      }),
      // @ts-expect-error
      providesTags: (_result, _error, arg) => [
        {type: 'attachments', id: arg.id}, //provides product attachments
        {type: 'variants', id: arg.id}, //provides variant itself
      ],
    }),
  }),
});

type Request = {
  id?: ObjectId;
  productId?: ObjectId;
};

type Response = ResponseData<ProductVariant>;

export const updateCachedVariantDetailsFromResource = (
  variant: ProductVariant
) => {
  return showVariant.util.updateQueryData(
    'showVariant',
    {id: String(variant.id), productId: String(variant.product_id)},
    x => {
      x.data = variant;
    }
  );
};

export const {useShowVariantQuery, util} = showVariant;

export const selectVariant = showVariant.endpoints.showVariant.select;
