import {SituationParamPayload} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'costs/updateSituation',
  function prepare(
    key: SituationParamPayload['key'],
    value: SituationParamPayload['value']
  ) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);
