import {useState} from 'react';
import clearSelection from 'features/table/slice/actions/clear-selection';
import loadData from 'features/table/slice/actions/load-data';
import selectEntity from 'features/table/slice/selectors/select-entity';
import selectSelectedResources from 'features/table/slice/selectors/select-selected-resources';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  MassUpdateCostsRequest,
  useMassUpdateCostsMutation,
} from 'store/production/endpoints/mass-update-costs';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ProductVariant} from 'types/product-variant';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUpdateCostsForm = () => {
  const dispatch = useDispatch();

  const [commit, {isLoading, error, isSuccess, reset}] =
    useMassUpdateCostsMutation();

  const {resourceName, url} = useSelector(selectActiveModalParams);
  const data = useSelector(selectEntity(resourceName));
  const ids = useSelector(selectSelectedResources(resourceName));

  const variants = data.data.filter((d: any) =>
    ids.includes(d.id.toString())
  ) as ProductVariant[];

  const handleSubmit = async () => {
    const result = await commit({
      ids,
      ...params,
    });
    if ('data' in result) {
      dispatch(clearSelection(resourceName));
      dispatch(loadData({name: resourceName, url, page: 1}));
      dispatch(uiActions.dismissModal());
      dispatch(toast('success', result.data.message));
    }
  };

  const [params, setParams] = useState<MassUpdateCostsRequest>({
    decorator_price_ht: 0,
    public_price_ht: 0,
    resseler_price_ht: 0,
  });

  const serverError = parseValidationErrors(error);

  return {
    errorMessage: serverError.message,
    isLoading,
    params,
    setParams,
    isSuccess,
    submit: handleSubmit,
    variants,
    validationErrors: serverError.errors,
    reset,
  };
};
