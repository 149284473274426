import {useState, useEffect} from 'react';
import messages from '../messages';
import {useSendEmailQuotationMutation} from 'features/commercial/quotations/store/endpoints/action-quotation-send-email';
import {useGetQuotationDefaultMailContentQuery} from 'features/commercial/quotations/store/endpoints/get-quotation-default-mail-content';
import {selectQuotation} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {produce} from 'immer';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Email} from 'types/phone';
import {Quotation} from 'types/quotation';
import makeId from 'utils/make-id';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSendQuotationAsEmail = (onDismiss: () => void) => {
  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, id} = modalParams;

  const {data} = useSelector(selectQuotation({id}));

  const quotation = data?.data as Quotation;

  const locale = quotation.language;

  const [submit, {isLoading, error}] = useSendEmailQuotationMutation();
  const response = useGetQuotationDefaultMailContentQuery({
    local: locale.value as string,
  });

  const [email, setEmail] = useState<EmailMap>({
    '1': {
      id: '1',
      email: quotation?.directory?.main_directory_email?.email,
    },
  });

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const updateEmail = (p: Email, k: keyof Email, v: any) => {
    setEmail(
      produce(email, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };

  const removeEmail = (id: ObjectId) => {
    const _email: EmailMap = Object.assign({}, email);
    delete _email[id];
    setEmail({
      ..._email,
    });
  };

  const addEmail = () => {
    const p: Email = {
      id: makeId(),
      email: __DEV__ ? fake.email : '',
    };
    setEmail(
      produce(email, draft => {
        draft[p.id] = p;
      })
    );
  };

  const {t} = useTranslation();

  const [params, setParams] = useState<Params>({
    body: undefined,
    emails: undefined,
  });

  useEffect(() => {
    if (response.data?.data && response.data?.data.content) {
      setParams(prevParams => ({
        ...prevParams,
        body: response.data?.data.content,
      }));
    }
  }, [response.data?.data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const emails = Object.values(email).map(emailObj => emailObj.email) as (
      | string
    )[];
    const result = await submit({
      id,
      body: params?.body,
      emails: emails,
    });
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    updateEmail,
    email,
    removeEmail,
    addEmail,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = {
  body?: string;
  emails?: string[];
};

export type EmailMap = Record<ObjectId, Email>;
