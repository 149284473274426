import React, {FC, useContext, useEffect} from 'react';
import {AppLogo, MobileLogo} from './components/app-logo';
import {menuItems} from './constants';
import messages from './messages';
import appLogo from 'assets/images/logo.png';
import {CircleMenu, CircleMenuItem} from 'react-circular-menu';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {NavLink, useNavigate} from 'react-router-dom';
import {AbilityContextType} from 'features/hylian-shield/types';
import {AbilityContext} from 'features/hylian-shield';
import clsx from 'clsx';
import {isMobile} from 'utils/is-mobile';

interface Props {}

const WelcomePage: FC<Props> = () => {
  const {t} = useTranslation();
  const [openMenu, setOpenMenu] = React.useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const openTimeout = setTimeout(() => {
      setOpenMenu(true);
    }, 300);
    return () => {
      clearTimeout(openTimeout);
    };
  }, []);

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  return (
    <div
      style={{height: '100vh'}}
      className="d-flex justify-content-center  align-items-center"
    >
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <CircleMenu
        startAngle={90}
        rotationAngle={360}
        itemSize={isMobile() ? 4.5 : 7}
        radius={isMobile() ? 8.7 : 15}
        open={openMenu}
        onMenuToggle={() => {}}
        menuToggleElement={
          <div>
            {isMobile() ? (
              <MobileLogo src={appLogo} />
            ) : (
              <AppLogo src={appLogo} />
            )}
          </div>
        }
        rotationAngleInclusive={false}
      >
        {menuItems.map(x => {
          const canSee = checkAbility('show', x.subject);
          return (
            <CircleMenuItem
              key={x.id}
              style={{
                cursor: !canSee ? 'not-allowed' : 'pointer',
              }}
              className={clsx(
                {
                  'opacity-25': !canSee,
                },
                'border-2 text-black border-gray-light bg-gray-light'
              )}
              onClick={() => {
                if (!canSee) return;
                return navigate(x.to);
              }}
            >
              <div
                style={{fontSize: isMobile() ? '11px' : '14px'}}
                className="text-uppercase"
                key={x.id}
              >
                {t(`welcomePage.${x.name}`)}
              </div>
            </CircleMenuItem>
          );
        })}
      </CircleMenu>
    </div>
  );
};

export default WelcomePage;
