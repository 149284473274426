import React, {FC} from 'react';
import messages from './messages';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';
import OrderSectionByType from 'features/commercial/orders/components/order-section-by-type';
import {EditOrderRequest} from 'features/commercial/orders/store/endpoints/edit-order';

interface Props {
  params: EditOrderRequest;
  onChangeParams: (p: Record<string, any>) => void;
  validationErrors: ValidationErrors;
  is_locked?: boolean;
}

const OrderProduction: FC<Props> = props => {
  const {onChangeParams, params, validationErrors, is_locked} = props;

  const {t} = useTranslation();

  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <Row>
        <Col md={12} className="mb-2 mt-2">
          <TextInput
            label={t(...messages.despositRequest())}
            data-testid="quote-number-input"
            disabled={is_locked}
            type="number"
            value={params.production_requested_deposit}
            errors={validationErrors.production_requested_deposit}
            onChange={production_requested_deposit => {
              onChangeParams({
                ...params,
                production_requested_deposit,
              });
            }}
          />
        </Col>
        <Col md={12} className="mb-3 mt-1">
          <DateInput
            label={t(...messages.statedAnnoucment())}
            disabled={is_locked}
            value={params.production_declared_completion_date}
            onChange={production_declared_completion_date =>
              onChangeParams({...params, production_declared_completion_date})
            }
            errors={validationErrors.production_declared_completion_date}
          />
        </Col>

        <OrderSectionByType orderTypeToDisplay={['CLIENT']}>
          <Col md={12} className="mb-3">
            <TextInput
              label={t(...messages.deadlineAnnouncedFr())}
              disabled={is_locked}
              data-testid="product-name-input"
              value={params.deadline_announced_fr}
              errors={validationErrors.deadline_announced_fr}
              onChange={deadline_announced_fr => {
                onChangeParams({
                  ...params,
                  deadline_announced_fr,
                });
              }}
            />
          </Col>
          <Col md={12} className="mb-3 mt-1">
            <TextInput
              label={t(...messages.deadlineAnnouncedEn())}
              data-testid="product-name-input"
              disabled={is_locked}
              value={params.deadline_announced_en}
              errors={validationErrors.deadline_announced_en}
              onChange={deadline_announced_en => {
                onChangeParams({
                  ...params,
                  deadline_announced_en,
                });
              }}
            />
          </Col>
        </OrderSectionByType>
        <Col md={12} className="align-items-center mb-3 mt-1">
          <input
            className="form-check-input "
            type="checkbox"
            id="hide-delay"
            disabled={is_locked}
            onChange={() =>
              onChangeParams({
                ...params,
                hide_delay: !params.hide_delay,
              })
            }
            checked={params.hide_delay}
          />
          <label className="form-check-label  mx-2" htmlFor="hide-delay">
            {t(...messages.delayIsHidden())}
          </label>
        </Col>
      </Row>
    </div>
  );
};

export default OrderProduction;
