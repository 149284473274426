import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectProduct} from 'store/production/endpoints/show-product';
import {
  useUpdateOtherProductInfoMutation,
  UpdateOtherInfoRequest,
} from 'store/production/endpoints/update-other-product-info';
import toast from 'store/ui/actions/toast';
import {Product} from 'types/product';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useOtherInfoForm = () => {
  const [updateProduct, {isLoading, error, isSuccess}] =
    useUpdateOtherProductInfoMutation();

  const serverError = parseValidationErrors(error);
  const {id} = useParams();

  const {data} = useSelector(selectProduct({id}));
  const product = data?.data as Product;

  const [params, setParams] = useState<Params>({
    id: id,
    customization_options: [],
    setup: '',
    inspiration: '',
    maintenance_instruction: '',
    carbon_footprint: '',

    hs_code: '',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updateProduct(params);

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  useEffect(() => {
    setParams({
      id: product?.id ?? 0,
      customization_options: product?.customization_options ?? [],
      setup: product?.setup ?? '',
      maintenance_instruction: product?.maintenance_instruction ?? '',
      carbon_footprint: product?.carbon_footprint ?? '',
      inspiration: product?.inspiration ?? '',

      hs_code: product?.hs_code ?? '',
    });
  }, [product]);

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    isSuccess,
  };
};

type Params = UpdateOtherInfoRequest;
