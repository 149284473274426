import React, {FC} from 'react';
import messages from './messages';
import BarCharts from 'components/bar-charts';
import Currency from 'components/currency/currency';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, CardHeader, Col} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {useGetProductStatsQuery} from 'store/production/endpoints/get-product-stats';

interface Props {
  id?: ObjectId;
}

const VariantCharts: FC<Props> = props => {
  const {t} = useTranslation();
  const {id} = props;

  const {data} = useGetProductStatsQuery({id});
  const stats = data?.data ?? [];
  const totalTurnOver =
    stats.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.ca),
      0
    ) ?? 0;
  const totalSales =
    stats.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.selling_count),
      0
    ) ?? 0;
  const turnoverSeries = [
    {
      name: t(...messages.turnover()),
      data: !stats || stats[0]?.year === 0 ? [] : stats?.map(x => x?.ca),
    },
  ];

  const invoiceSeries = [
    {
      name: t(...messages.sales()),
      data:
        !stats || stats[0]?.year === 0 ? [] : stats?.map(x => x?.selling_count),
    },
  ];
  const years = stats.map(x => x.year.toString()) as string[];

  const invoiceNumberOfPoint = Math.max(...invoiceSeries[0].data);
  const turnoverNumberOfPoint = Math.max(...turnoverSeries[0].data);
  return (
    <>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0"> {t(...messages.turnover())} </h4>
              <div>
                <span className="fw-bold">{t(...messages.total())}</span>
                <span className="float-right font-weight-bold">
                  {' '}
                  <Currency value={totalTurnOver} />{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={turnoverSeries}
              categories={years}
              numberOfPoint={turnoverNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0"> {t(...messages.sales())} </h4>

              <div>
                <span className="fw-bold">{t(...messages.total())}</span>
                <span className="float-right font-weight-bold">
                  {' '}
                  {totalSales}{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={invoiceSeries}
              categories={years}
              numberOfPoint={invoiceNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default VariantCharts;
