import {extraReducers} from './extra-reducers';
import {initialState} from './initial-state';
import {createSlice} from '@reduxjs/toolkit';

const directorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {},
  extraReducers,
});

export const directoryActions = directorySlice.actions;

export default directorySlice;
