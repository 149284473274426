import React from 'react';
import messages from './messages';
import {useManifactureTimeForm} from './use-manifacture-time-tab';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Row, Col, Label, Alert} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {Directory} from 'types/directory';
import getDirectoryName from 'utils/row-getters/get-directory-name';
import Can from 'features/hylian-shield/can';

const ManifactureTimeTab = () => {
  const {
    addManifactureTime,
    manifactureTime,
    errorMessage,
    isLoading,
    isSuccess,
    removeManifactureTime,
    submit,
    updateManifactureTime,
    validationErrors,
    filterOption,
  } = useManifactureTimeForm();
  const {id, variant: variantId} = useParams();

  const {t} = useTranslation();
  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const navigate = useNavigate();
  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: data?.data.name?.toLocaleUpperCase(),
        })}
      />

      {Object.values(manifactureTime).map((p, i) => (
        <Row className="mb-3" key={p.id}>
          <Col md={8}>
            <AsyncItemPicker
              label={t(...messages.Manifacturer())}
              filterOption={filterOption}
              getOptionLabel={getDirectoryName}
              endPoint="data/suppliers-type-company"
              errors={validationErrors[`production_times.${i}.directory_id`]}
              value={p.directory}
              onChange={x => updateManifactureTime(p, 'directory', x)}
            />
          </Col>
          <Col md={3}>
            <TextInput
              label={t(...messages.time())}
              type="text"
              errors={validationErrors[`production_times.${i}`]}
              value={p.days}
              onChange={x => updateManifactureTime(p, 'days', x)}
            />
          </Col>

          <Col className="d-flex align-items-center py-4" md={1}>
            <Button
              color="link"
              className="text-danger"
              onClick={() => removeManifactureTime(p.id)}
            >
              <Icon name="trash-2" size={18} />
            </Button>
          </Col>
        </Row>
      ))}

      <Can action="edit" subject="products">
        <Row className="mb-3">
          <Col md={4}>
            <Button color="light" onClick={addManifactureTime}>
              <Icon name="plus" className="me-1" size={18} />
              {t(...messages.addTime())}
            </Button>
          </Col>
        </Row>
      </Can>

      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className=" mb-4">
            <AppBarActions>
              <Button isLoading={isLoading} color="important" onClick={submit}>
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default ManifactureTimeTab;
