import React, {useCallback, useEffect, useMemo} from 'react';
import {FC} from 'react';
import {PaginationMeta} from '../slice/types';
import {useTranslation} from 'react-i18next';
import ReactPaginate from 'react-paginate';
import {Props} from 'react-select';
import {CardBody, Col, FormGroup, Row} from 'reactstrap';
import TagSelect from 'components/tag-select/tag-select';
import {useSelector} from 'react-redux';
import selectEntityPaginationLimit from '../slice/selectors/select-entity-pagination-limit';
import {ResourceName} from 'types/resource-name';
import messages from '../../listing/list/messages';
import {PerPageOptions} from '../../listing/constants';

interface Props {
  currentPage: number;
  pageCount: number;
  filters: any;
  onChangeFilters: (key: string, value: any) => void;
  meta: PaginationMeta;
  onPageChange: (p: number) => void;
  name: ResourceName;
}

const Paginator: FC<Props> = props => {
  const {
    currentPage,
    pageCount,
    meta,
    onPageChange,
    filters,
    name,
    onChangeFilters,
  } = props;

  const {t} = useTranslation();

  const handleChangeFilters = (key: string, value: any) => {
    onChangeFilters(key, value);
  };

  const limit = useSelector(selectEntityPaginationLimit(name));

  return (
    <CardBody>
      <Row>
        <Col md={8} className="d-flex">
          <div className="d-flex w-100  align-items-center">
            <FormGroup className="w-50 me-4">
              <TagSelect
                menuplacement="top"
                label={t(...messages.perPage())}
                options={PerPageOptions}
                placeholder={t(...messages.show())}
                value={limit}
                onChange={o => handleChangeFilters('limit', o)}
              />
            </FormGroup>
            <div className="pt-2">
              {meta
                ? t(...messages.showingXOfY(), {
                    x: meta?.from ? meta?.from : 0,
                    y: meta?.to ? meta?.to : 0,
                    z: meta?.total,
                  })
                : ''}
            </div>
          </div>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          <ReactPaginate
            forcePage={currentPage - 1}
            onPageChange={page => onPageChange(page.selected + 1)}
            pageCount={pageCount}
            breakLabel="…"
            nextLabel=">"
            previousLabel="<"
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            activeClassName="active"
            pageClassName="page-item"
            nextLinkClassName="page-link"
            nextClassName="page-item next"
            previousClassName="page-item prev"
            previousLinkClassName="page-link"
            pageLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1"
          ></ReactPaginate>
        </Col>
      </Row>
    </CardBody>
  );
};

export default Paginator;
