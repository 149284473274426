import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import messages from './messages';
import styled from 'styled-components';
import {Label} from 'reactstrap';

const TransferTableHeader: FC<any> = props => {
  const {t} = useTranslation();

  const {onToggleAll, isAllSelected} = props;

  return (
    <thead>
      <tr>
        <CheckboxColumn>
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={onToggleAll}
            id={`select-all-products`}
          />

          <Label className="px-2 mb-0 fw-bold" htmlFor={`select-all-products`}>
            {t(...messages.transfer())}
          </Label>
        </CheckboxColumn>
        <th>{t(...messages.sku())}</th>
        <th>{t(...messages.label())}</th>
        <th>{t(...messages.quantity())}</th>
      </tr>
    </thead>
  );
};

export default TransferTableHeader;

const CheckboxColumn = styled.th`
  white-space: nowrap;
  width: 1%;
`;
