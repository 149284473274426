import React, {FC, useMemo} from 'react';
import messages from '../messages';
import {CustomOrderFilters} from './types';
import AsyncItemPicker from 'components/async-item-picker';
import DateRangeInput from 'components/date-range-input';
import TextInput from 'components/text-input';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Label, Row} from 'reactstrap';
import {Option} from 'types/option';
import getVariantName from 'utils/row-getters/get-variant-name';
import {CompactVariant} from 'types/compact-variant';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<CustomOrderFilters>;
  onChangeFilters: (key: CustomOrderFilters, value: any) => void;
  onClearFilters?: () => void;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <SearchSection
      onChangeFilters={onChangeFilters}
      filters={filters}
      onClearFilters={onClearFilters}
    >
      <Row className="mb-3">
        {/* <Col lg="3" md="3">
          <FormGroup>
            <TextInput
              label={t(...messages.deposit()) + ' ( ± 20 % ) '}
              type={'number'}
              value={(filters['filter[deposit]'] as string) || ''}
              onChange={text => onChangeFilters('filter[deposit]', text)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <TextInput
              label={t(...messages.total_ht()) + ' ( ± 3 % ) '}
              type={'number'}
              value={(filters['filter[total_ht_for_3]'] as string) || ''}
              onChange={text => onChangeFilters('filter[total_ht_for_3]', text)}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <TextInput
              label={t(...messages.payments()) + ' ( ± 3 % ) '}
              type={'number'}
              value={(filters['filter[payment_for_3]'] as string) || ''}
              onChange={text => onChangeFilters('filter[payment_for_3]', text)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.paymentDate())}
              value={filters['filter[payment_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[payment_date]', newDate);
              }}
            />
          </FormGroup>
        </Col> */}
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.productionStatedDate())}
              value={
                filters['filter[production_declared_completion_date]'] as string
              }
              onChange={newDate => {
                onChangeFilters(
                  'filter[production_declared_completion_date]',
                  newDate
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.orderDate())}
              value={filters['filter[order_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[order_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/product-families"
              className="mb-1"
              label={t(...messages.family())}
              value={filters['filter[product_family_id]']}
              onChange={o => onChangeFilters('filter[product_family_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/variants"
              className="mb-1"
              getOptionLabel={(v: CompactVariant) => {
                return v.name + ' | ' + v.collection_name;
              }}
              label={t(...messages.product())}
              value={filters['filter[product_variant]']}
              onChange={o => onChangeFilters('filter[product_variant]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/users"
              className="mb-1"
              label={t('createDirectoryPage.referentContact')}
              value={filters['filter[user_id]']}
              onChange={o => onChangeFilters('filter[user_id]', o)}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/commercial-types?client_order"
              defaultValue
              getDefaultOptions={(meta: any) => meta?.default_index}
              className="mb-1"
              label={t(...messages.type())}
              value={filters['filter[commercial_type_id]']}
              onChange={o => onChangeFilters('filter[commercial_type_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/projects"
              className="mb-1"
              label={t('productsPage.project')}
              value={filters['filter[project_id]']}
              onChange={o => onChangeFilters('filter[project_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-categories"
              className="mb-1"
              label={t(...messages.category())}
              value={filters['filter[document_category_id]']}
              onChange={o => onChangeFilters('filter[document_category_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-origins"
              className="mb-1"
              label={t('createCustomerOrderPage.customerOrderOrigin')}
              value={filters['filter[origin_id]']}
              onChange={o => onChangeFilters('filter[origin_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-sub-type"
              className="mb-1"
              label={t(...messages.model())}
              getOptionLabel={(o: any) => o.label}
              getOptionValue={(o: any) => o.value}
              value={filters['filter[document_sub_type]']}
              onChange={o => onChangeFilters('filter[document_sub_type]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/order-statuses?document_type=client"
              className="mb-1"
              label={t(...messages.status())}
              getOptionLabel={(o: any) => o.label}
              getOptionValue={(o: any) => o.value}
              isClearable
              value={filters['filter[status]']}
              onChange={o => onChangeFilters('filter[status]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/vat-modes"
              className="mb-1"
              label={t(...messages.vatMode())}
              getOptionLabel={(o: any) => o.label}
              getOptionValue={(o: any) => o.value}
              isClearable
              value={filters['filter[vat_mode]'] as Option}
              onChange={o => onChangeFilters('filter[vat_mode]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_vintage"
                checked={!!filters['filter[is_vintage]']}
                onChange={(o: any) =>
                  onChangeFilters('filter[is_vintage]', o.target.checked)
                }
              />
              <Label className="form-check-label" htmlFor="is_vintage">
                {t(...messages.vintage())}
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_after_sale_service"
                checked={!!filters['filter[is_after_sale_service]']}
                onChange={(o: any) =>
                  onChangeFilters(
                    'filter[is_after_sale_service]',
                    o.target.checked
                  )
                }
              />
              <Label
                className="form-check-label"
                htmlFor="is_after_sale_service"
              >
                {t('invoicesPage.isAfterSaleService')}
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
