export enum ProjectTypeEnum {
  ARCHI_RESIDENCE = 'ARCHI',
  ARCHI_HOTEL_RESTAURANT = 'ARCHI_HOTEL_RESTAURANT',
  ARCHI_RETAIL = 'ARCHI_RETAIL',
  SCENOGRAPHY = 'SCENOGRAPHY',
  DESIGN_COLLABORATIONS = 'DESIGN_COLLABORATIONS',
  PETITS_PROJETS = 'PETITS_PROJETS',
  PROJECT_ROOM = "PROJECT_ROOM",
  INSTITUTION = "INSTITUTION",
  TINY_ROOM = "TINY_ROOM",
}
