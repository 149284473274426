import {layoutActions} from '../layout-slice';
import {AppTheme} from '../types';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {application} from 'constants/application';
import {SharedPrefs} from 'constants/shared-prefs';
import {loadString} from 'services/storage-service';
import {changeAppLocale} from 'store/global/change-app-locale';

const loadLayoutPreferences = createAsyncThunk(
  'layout/loadLayoutPreferences',
  async (_, thunkApi) => {
    const appTheme = await loadString<AppTheme>(SharedPrefs.APP_THEME);
    const appLocale = await loadString<AppTheme>(SharedPrefs.APP_LOCALE);
    const perPage = await loadString<string>(SharedPrefs.PER_PAGE);
    thunkApi.dispatch(layoutActions.changeLayoutTheme(appTheme ?? 'light'));
    thunkApi.dispatch(changeAppLocale(appLocale ?? application.defaultLocale));

    return {
      appTheme,
      perPage,
    };
  }
);

export default loadLayoutPreferences;
