import React, {FC, Fragment} from 'react';
import messages from './messages';
import Currency from 'components/currency/currency';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Alert, Badge, Col, Row} from 'reactstrap';
import {Directory} from 'types/directory';
import FormattedDate from 'components/formatted-date';

interface Props {
  directory: Directory;
  onReload?: () => void;
}

const SummarySection: FC<Props> = props => {
  const {directory, onReload, ...all} = props;

  const {t} = useTranslation();

  const mail = directory?.main_directory_email?.email;
  return (
    <div>
      <Fragment>
        <Row>
          <div className="col-sm order-2 order-sm-1">
            <div className="d-flex align-items-start mt-3">
              <div className="flex-grow-1">
                <div>
                  <h1 className="text-primary">
                    {directory.display_name.toUpperCase()}
                  </h1>
                  {directory.company && (
                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.company())} :{' '}
                      </span>
                      <span className="ms-1">
                        <Link
                          to={`/dashboard/directories/${directory.company?.id}/general`}
                          target="_blank"
                        >
                          {directory.company?.display_name.toUpperCase()}
                        </Link>
                      </span>
                    </p>
                  )}
                </div>
                <div
                  style={{width: '60%'}}
                  className="d-flex flex-wrap mt-2 mb-2"
                >
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.status())} :</span>
                    <span className="ms-1">
                      {directory.directory_type.label}
                    </span>
                  </p>

                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.phone())} :</span>
                    <span className="ms-1">
                      {directory?.main_phone?.number}
                    </span>
                  </p>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.email())} :</span>
                    <span className="ms-1">
                      {mail ? (
                        <a href={`mailto:${mail}`}>
                          {directory?.main_directory_email?.email}
                        </a>
                      ) : (
                        '-'
                      )}
                    </span>
                  </p>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">
                      {t(...messages.avargePurchase())}:
                    </span>
                    <span className="ms-1">
                      {directory?.turnover ? (
                        <Currency value={directory?.turnover} />
                      ) : null}
                    </span>
                  </p>
                  <p className="me-3 mb-0">
                    <span className="fw-bold">
                      {t(...messages.lastPurchase())} :
                    </span>
                    <span className="ms-1">
                      <FormattedDate
                        value={directory?.last_purchase}
                        format="date"
                      />
                    </span>
                  </p>
                </div>
                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                  <div>
                    <div className="d-flex flex-wrap gap-2 font-size-16">
                      {directory.directory_families.map(x => (
                        <div
                          key={x.id}
                          className="text-uppercase text-dark fw-bold"
                        >
                          {x.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div className="d-flex flex-wrap gap-2 font-size-16">
                      {directory.directory_categories.map(x => (
                        <div
                          key={x.id}
                          className="text-uppercase text-dark fw-bold"
                        >
                          {x.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <Row className="mt-3">
          <Col>
            {directory?.duplicated && (
              <Alert color="warning">
                <h4 className="alert-heading">{t(...messages.warning())}</h4>
                <p className="mb-0">{t(...messages.duplicatesWarning())}</p>
              </Alert>
            )}
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

export default SummarySection;
