import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const storeTransferStockLocation = api.injectEndpoints({
  endpoints: builder => ({
    storeTransferStockLocation: builder.mutation<
      Response,
      TransferStockLocationRequest
    >({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/${params.productVariantId}/serial-numbers/${params.serialNumberId}/stock-movements`,
          body: params,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{location: 1}];
        }
        return [];
      },
    }),
  }),
});

export type TransferStockLocationRequest = {
  productVariantId: ObjectId;
  serialNumberId: ObjectId;
};

type Response = ResponseData<ProductVariant>;

export const {useStoreTransferStockLocationMutation} =
  storeTransferStockLocation;
