import messages from './messages';

export const quotationTabs = [
  {
    to: 'general',
    title: messages.general,
  },
  {
    to: 'details',
    title: messages.details,
  },
  {
    to: 'related-parts',
    title: messages.realtedParts,
  },
  {
    to: 'logs',
    title: messages.logs,
  },
];
