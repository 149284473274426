import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  comment: () => __(translations.commercialDocumentComponents.comment, 'Comment'),
  discount: () => __(translations.commercialDocumentComponents.discount, 'Discount (%)'),
  commission: () => __(translations.commercialDocumentComponents.commission, 'Commission (%)'),
  quantity: () => __(translations.commercialDocumentComponents.quantity, 'Quantity'),
  subTotal: () => __(translations.commercialDocumentComponents.subTotal, 'Sub-total'),
  unitPrice: () => __(translations.commercialDocumentComponents.unitPrice, 'Unit price'),
  vat: () => __(translations.commercialDocumentComponents.vat, 'VAT'),
};
