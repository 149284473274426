import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  helmetTitle: () => __(translations.quotationDetailsPage.helmetTitle, 'Quotation {{ref}}'),
  details: () => __(translations.quotationDetailsPage.details, 'Details'),
  general: () => __(translations.quotationDetailsPage.general, 'General'),
  summary: () => __(translations.quotationDetailsPage.summary, 'Summary'),
  production: () => __(translations.quotationDetailsPage.production, 'Production'),
  title: () => __(translations.quotationDetailsPage.title, 'Quotation details'),
  actions: () => __(translations.quotationDetailsPage.actions, 'Actions'),
  realtedParts: () => __(translations.quotationDetailsPage.realtedParts, 'Related documents'),
  logs: () => __(translations.quotationDetailsPage.logs, 'Logs'),
};
