import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  invoices: () => __(translations.createCreditPage.invoices, 'Quotations'),
  title: () => __(translations.createCreditPage.title, 'Create Credit'),
  client: () => __(translations.createCreditPage.client, 'Client'),
  addNewCompanyClient: () => __(translations.createCreditPage.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.createCreditPage.addNewPrivateClient, 'Add Private'),
  creditType: () => __(translations.createCreditPage.creditType, 'Type'),
  creditCategory: () => __(translations.createCreditPage.creditCategory, 'Category'),
  creditOrigin: () => __(translations.createCreditPage.creditOrigin, 'Origin'),
  creditLanguage: () => __(translations.createCreditPage.creditLanguage, 'Language'),
  creditDueDate: () => __(translations.createCreditPage.creditDueDate, 'Due date'),
  creditDelivereyDate: () => __(translations.createCreditPage.creditDelivereyDate, 'Delivery date'),
  creditBillingDate: () => __(translations.createCreditPage.creditBillingDate, 'Billing date'),
};
