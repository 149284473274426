import React from 'react';

interface Props {
  starsNumber: number;
}
const GenerateStars = ({starsNumber}: Props) => {
  const stars = [];
  for (let i = 0; i < starsNumber; i++) {
    stars.push(<i className="fas fa-star" key={i} />);
  }
  return <span>{stars}</span>;
};

export default GenerateStars;
