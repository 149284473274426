import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Box, Edit, Eye} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import Button from 'components/button';
import {StockInProduct} from 'features/stock/types/stock-in-product';
import Can from 'features/hylian-shield/can';

interface Props {
  stockInProduct: StockInProduct;
}

const StockInProductsActions: FC<Props> = props => {
  const {stockInProduct} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.DETAILS_STOCK_IN_PRODUCT, {
        stockInProduct: stockInProduct,
        path: {
          url: 'stock/products-in-stock',
          name: 'stock-products-in-stock',
        },
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="edit" subject="stock">
        <Button
          tag="button"
          className="me-2"
          color="transparent"
          onClick={handleEdit}
        >
          <Edit size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default StockInProductsActions;
