import React from 'react';
import {Routes, Route} from 'react-router';
import {routes} from './routes';
import {Guardian} from './Guardian';

const ProjectRoutes = () => {
  return (
    <Routes>
      {routes.map((r, x) => (
        <Route
          key={x}
          path={r.path}
          element={<Guardian {...r}>{r.element}</Guardian>}
        />
      ))}
    </Routes>
  );
};

export default ProjectRoutes;
