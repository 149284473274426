import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import {DirectoryCategory} from 'types/directory-category';
import Button from 'components/button';

interface Props {
  category: DirectoryCategory;
}

const CategoryActions: FC<Props> = props => {
  const {category} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleDestroy = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: category.id,
        name: 'directory-categories',
      })
    );
  };

  const handleEdit = () => {
    dispatch(showModal(MODAL.UPDATE_DIRECTORY_CATEROGY_FORM, category));
  };

  const hasActions = true;
  return (
    <div className="d-flex">
      <Button color="transparent" className="btn-sm" onClick={handleEdit}>
        <Edit size={18} />
      </Button>

      {/* <Can action="delete" subject="directory-category"> */}
      <Button onClick={handleDestroy} className="btn-sm" color="transparent">
        <Trash2 size={18} className="text-danger" />
      </Button>
      {/* </Can> */}
    </div>
  );
};

export default CategoryActions;
