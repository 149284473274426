import React, {FC} from 'react';
import CardRows from './compenents/card-rows';
import Header from './compenents/header';
import Paginator from './compenents/paginator';
import DataTable from './container/table';
import useFilters from './hooks/use-filters';
import useInitiateTable from './hooks/use-initiate-table';
import {useListSlice} from './slice';
import makeSelectEntityRequest from './slice/selectors/make-select-entity-request';
import {ListParams} from './types';
import {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Alert, Card, CardHeader} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import {ResourceName} from 'types/resource-name';
import {useMediaQuery} from 'usehooks-ts';

interface TableProps {
  url: string;
  routerParams?: boolean;
  name: ResourceName;
  subHeaderComponent?: React.ReactNode;
  otherParams?: ListParams;
  hideFilters?: boolean;
  columns: ColumnDef<any>[];
  hideSearch?: boolean;
  filtersComponent?: any;
  makeRowClassName?: (row: any) => string;
}

const Table: FC<TableProps> = props => {
  const {
    url,
    name,
    columns,
    routerParams,
    hideFilters,
    makeRowClassName,
    hideSearch,
    otherParams,
    filtersComponent: FiltersComponent,
  } = props;

  const {handleChangeFilters, handleClearFilters} = useFilters(
    name,
    url,
    routerParams,
    otherParams
  );

  const {actions} = useListSlice();

  const {isLoading, errorMessage} = useSelector(makeSelectEntityRequest(name));

  const dispatch = useDispatch();

  const onPageChange = (page: number) => {
    dispatch(actions.loadData({url, name, page, otherParams}));
  };

  const {t} = useTranslation();

  const theme = useSelector(selectAppTheme);

  const media = useMediaQuery('(max-width: 768px)');
  const {meta, filters, table} = useInitiateTable(
    name,
    url,
    columns,
    otherParams
  );

  return (
    <>
      <Card>
        {hideFilters ?? (
          <CardHeader style={{borderBottom: 'none'}}>
            {!!FiltersComponent && (
              <FiltersComponent
                filters={filters}
                onClearFilters={handleClearFilters}
                onChangeFilters={handleChangeFilters}
              />
            )}
            <Header
              filters={filters}
              name={name}
              hideSearch={hideSearch}
              onChangeFilters={handleChangeFilters}
              onClearFilters={handleClearFilters}
              meta={meta}
            />{' '}
          </CardHeader>
        )}

        {/* error message (in case of failure) */}
        {errorMessage && (
          <Alert color="danger" className="m-3">
            <div className="alert-body">{errorMessage}</div>
          </Alert>
        )}

        <div className="table-responsive">
          {media ? (
            <CardRows isLoading={isLoading} table={table}></CardRows>
          ) : (
            <DataTable
              makeRowClassName={makeRowClassName}
              table={table}
              isLoading={isLoading}
            ></DataTable>
          )}
          {!isLoading && !!meta && table.getRowModel().rows.length !== 0 ? (
            <Paginator
              currentPage={meta?.current_page}
              pageCount={meta?.last_page}
              onPageChange={onPageChange}
              meta={meta}
              name={name}
              filters={filters}
              onChangeFilters={handleChangeFilters}
            />
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default Table;
