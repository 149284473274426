import selectPurchasePrice from './select-purchase-price';
import selectResellerPrice from './select-reseller-price';
import {createSelector} from '@reduxjs/toolkit';

export default createSelector(
  [selectResellerPrice, selectPurchasePrice],
  (resellerPrice, purchasePrice) => {
    return (resellerPrice / Math.max(1, purchasePrice)).toFixed(2);
  }
);
