import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const showDirectory = api.injectEndpoints({
  endpoints: builder => ({
    showDirectory: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `directories/${params.id}`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'directories', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Directory>;

export const {useShowDirectoryQuery} = showDirectory;

export const updateCachedDirectoryFromResource = (directory: Directory) => {
  return showDirectory.util.updateQueryData(
    'showDirectory',
    {id: String(directory.id)},
    x => {
      x.data = directory;
    }
  );
};

export const selectDirectory = showDirectory.endpoints.showDirectory.select;
