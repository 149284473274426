import {CommercialProductOption} from 'types/commercial-product-option';
import {CommercialProductOptionType} from 'types/commercial-product-option';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';

export default (
  parentId: ObjectId,
  type: CommercialProductOptionType
): CommercialProductOption => {
  return {
    id: makeId(),
    price_ht: 0,
    type: type,
    commercial_product_id: parentId,
  };
};
