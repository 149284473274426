import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockMovements.transferModal.title, 'Stock movement'),
  movementType: () => __(translations.stockMovements.transferModal.movementType, 'Movement type'),
  actualLocation: () => __(translations.stockMovements.transferModal.actualLocation, 'Actual location'),
  arrivalLocation: () => __(translations.stockMovements.transferModal.arrivalLocation, 'Arrival location'),
  moveOut: () => __(translations.stockMovementForm.transferModal.moveOut, 'Out'),
  moveTransfer: () => __(translations.stockMovementForm.transferModal.moveTransfer, 'Transfer'),
};
