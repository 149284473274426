import {selectDomain} from '.';
import {createSelector} from '@reduxjs/toolkit';
import {ResourceName} from 'types/resource-name';

export default (name: ResourceName) =>
  createSelector(
    selectDomain,
    // entity request status
    domain => ({
      isLoading: domain.loading[name] || false,
      errorMessage: domain.errorMessage[name] || null,
    })
  );
