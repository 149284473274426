import React, {FC} from 'react';
import clsx from 'clsx';
import {getDate, format, parseISO} from 'date-fns';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Trash2} from 'react-feather';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row} from 'reactstrap';
import {UncontrolledDropdown} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import {Directory} from 'types/directory';
import {Interaction} from 'types/interaction';

interface Props {
  direction: 'left' | 'right';
  interaction: Interaction;
  directory: Directory;
  index: number;
}

const TimelineItem: FC<Props> = props => {
  const {direction, interaction, directory} = props;

  const dObj = parseISO(interaction.created_at);

  const day = getDate(dObj);

  const month = format(dObj, 'LLL');
  const year = format(dObj, 'yyyy');

  const dispatch = useDispatch();

  return (
    <Row
      className={clsx({
        'timeline-left': direction === 'left',
        'timeline-right': direction === 'right',
      })}
    >
      <Col md={6} className={clsx({'d-md-none d-block': direction === 'left'})}>
        <div className="timeline-icon">
          <i className="bx bx-user-pin text-primary h2 mb-0"></i>
        </div>
      </Col>
      <Col md={6}>
        <div className="timeline-box">
          <div className="timeline-date  bg-primary text-center rounded">
            <h3 className="text-white mb-0">{day}</h3>
            <p className="mb-0 text-white lh-1">
              <span>{month}</span> <span>{year}</span>{' '}
            </p>
          </div>
          <div className="direction-column-reverse">
            <div className="timeline-text">
              <h3 className="font-size-18">
                {interaction.interaction_type?.label}
              </h3>
              <p className="mb-0 mt-2 pt-2 px-3 text-start text-muted">
                {interaction.comment}
              </p>

              <div className="d-flex flex-wrap align-items-start event-img mt-3 gap-2">
                <div
                  className={clsx({
                    'd-flex': 1,
                    'flex-row-reverse': direction === 'right',
                  })}
                >
                  <Link
                    to={`/dashboard/users/${interaction.user?.id}/profile`}
                    target="_blank"
                    className="mx-2"
                  >
                    {interaction.user?.profile?.full_name}
                  </Link>
                  <img
                    src={interaction.user?.profile?.avatar?.square}
                    alt=""
                    className="img-fluid rounded"
                    width="60"
                  />
                </div>
              </div>
              <div>
                <UncontrolledDropdown direction="start">
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-dots-horizontal-rounded" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag="button"
                      onClick={e =>
                        dispatch(
                          showModal(MODAL.INTERACTION_FORM, {
                            directory,
                            interaction,
                          })
                        )
                      }
                    >
                      <Edit />
                      <span className="align-middle ms-2">
                        <Trans i18nKey="productsPage.edit" />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      tag="button"
                      className="text-danger"
                      onClick={e =>
                        dispatch(
                          uiActions.confirmDelete({
                            resourceId: interaction.id,
                            name: 'interactions',
                            otherParams: {
                              directoryId: interaction.directory_id,
                            },
                          })
                        )
                      }
                    >
                      <Trash2 />
                      <span className="align-middle ms-2">
                        <Trans i18nKey="productsPage.delete" />
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>
      </Col>
      {/* TODO Fix me so that only one icon is shown no matter what */}
      <Col
        md={6}
        className={clsx({
          'd-sm-block': direction === 'left', // only on large screen
          'd-none': direction === 'right', //right
        })}
      >
        <div className="timeline-icon">
          <i className="bx bx-user-pin text-primary h2 mb-0"></i>
        </div>
      </Col>
    </Row>
  );
};

export default TimelineItem;
