import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  documentNumber: () => __(translations.commercialDocumentComponents.documentNumber, '# {{id}}'),
  documentDate: () => __(translations.commercialDocumentComponents.documentDate, 'Date:'),
  invoice: () => __(translations.commercialDocumentComponents.invoice, 'Invoice'),
  order: () => __(translations.commercialDocumentComponents.order, 'Order'),
  quotation: () => __(translations.commercialDocumentComponents.quotation, 'Quotation'),
};
