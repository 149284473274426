import {
  AppTheme,
  LayoutState,
  LayoutWidth,
  LeftSideBarTheme,
  LeftSideBarType,
} from 'store/layout/types';

const layoutTypes: Record<string, LayoutState['layoutType']> = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const layoutTheme: Record<string, LayoutState['layoutMode']> = {
  LIGHTMODE: 'light',
  DARKMODE: 'dark',
};

const layoutWidthTypes: Record<string, LayoutWidth> = {
  FLUID: 'fluid',
  BOXED: 'boxed',
};

const topBarThemeTypes: Record<string, AppTheme> = {
  LIGHT: 'light',
  DARK: 'dark',
};

const leftSidebarTypes: Record<string, LeftSideBarType> = {
  DEFAULT: 'lg',
  COMPACT: 'md',
  ICON: 'sm',
};

const leftSideBarThemeTypes: Record<string, LeftSideBarTheme> = {
  LIGHT: 'light',
  COLORED: 'brand',
  DARK: 'dark',
};

export {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
};
