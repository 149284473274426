import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {CommercialProductOption} from 'types/commercial-product-option';

export default createAction(actions.updateItemOption.type, function prepare<
  T extends KeyType
>(item: CommercialProductOption, key: T, value: CommercialProductOption[T]) {
  return {
    payload: {
      item,
      key,
      value,
    },
  };
});

type KeyType = keyof CommercialProductOption;

type ValueByKeyType<T> = {
  discount: number;
  notes: string;
  //   vat: Option;
  //   variant: ProductVariant;
};

type ValueByKeyTypeExtended<T> = ValueByKeyType<T> & {};
