import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const projectCommissionsExport = api.injectEndpoints({
  endpoints: builder => ({
    projectCommissionsExport: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `projects/${params.id}/export-commissions`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{
  download_link: string;
  document_name: string;
}>;

export const {useLazyProjectCommissionsExportQuery} = projectCommissionsExport;
