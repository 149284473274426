import React from 'react';
import {FC} from 'react';
import Icon from '@ailibs/feather-react-ts';
import {Link} from 'react-router-dom';
import {Directory} from 'types/directory';

interface GetOptionLabelProps {
  directory: Directory;
}

const GetOptionLabel: FC<GetOptionLabelProps> = props => {
  const {directory} = props;

  return (
    <div className="d-flex justify-content-between align-items-center">
      {directory.display_name}

      <Link
        target="_blank"
        to={`/dashboard/directories/${directory.id}/general`}
      >
        <Icon name="external-link" size={15} />
      </Link>
    </div>
  );
};

export default GetOptionLabel;
