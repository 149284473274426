import {api} from 'services/api';
import {ListResponse} from 'types/list-response';
import {Model} from 'types/model';

/**
 * To disable caching listing data uncomment the force refetch function or adjust it so that it uses app settings
 * to determine whether or not it should force refetch
 *
 */
const loadListingData = api.injectEndpoints({
  endpoints: builder => ({
    loadListingData: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: params.url,
      }),
      forceRefetch: ({state}) => {
        //   // @ts-expect-error
        //   const settings = selectGeneralSettings(state);
        return true;
      },
    }),
  }),
});

type Request = {
  url: string;
};

type Response = ListResponse<Model>;

export const {useLoadListingDataQuery} = loadListingData;
export const loadListingDataEndpoint =
  loadListingData.endpoints.loadListingData;
