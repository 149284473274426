import React from 'react';
import columns from './columns';
import FiltersSection from './filters-section';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import TableCompact from 'features/table/table-compact';
import SectionSeparator from 'components/separator/section-separator';

const QuotationLogsTab = () => {
  const {id} = useParams();

  const {t} = useTranslation();

  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <TableCompact
        columns={columns}
        url={`quotations/${id}/logs`}
        name="quotation-logs"
        filtersComponent={FiltersSection}
      />
    </div>
  );
};
export default QuotationLogsTab;
