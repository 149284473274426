import {createSelector} from 'reselect';
import {RootState} from 'store';

/** Change this to where ever the modal notch is put */
const selectDomain = (state: RootState) => state.ui;

export const selectModalProperties = createSelector(
  [selectDomain],
  ui => ui.modal
);
