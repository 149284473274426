import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, BreadcrumbItem} from 'reactstrap';
import styled from 'styled-components';

interface BreadcrumbProps {
  breadcrumbItems: {title: string; path: string}[];
  showHeading?: boolean;
}

const Breadcrumb: FC<BreadcrumbProps> = props => {
  const {breadcrumbItems, showHeading = true} = props;
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <H1 className="mb-0">
            {showHeading
              ? breadcrumbItems[breadcrumbItems.length - 1].title
              : null}
          </H1>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems.map(breadcrumbItem => (
                <BreadcrumbItem key={breadcrumbItem.path}>
                  <Link to={breadcrumbItem.path}>{breadcrumbItem.title}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;

const H1 = styled.h1`
  font-size: 36px;
  font-weight: 500;
`;
