import React, {FC} from 'react';
import addPayment from '../../store/actions/add-payment';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from 'reactstrap';

interface Props {
  primary?: boolean;
}

const AddPayment: FC<Props> = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleAddPayment = () => {
    dispatch(addPayment());
  };

  return (
    <Button color="light" onClick={handleAddPayment}>
      {`${t(...messages.newPayment())} ${t(...messages.payment())}`}
    </Button>
  );
};

export default AddPayment;
