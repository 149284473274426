import {api} from 'services/api';
import {Directory} from 'types/directory';
import {Option} from 'types/option';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const storeQuotation = api.injectEndpoints({
  endpoints: builder => ({
    storeQuotation: builder.mutation<Response, StoreQuotationRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `quotations`,
          body: {
            directory_id: params.client?.id,
            document_category_id: params.category?.id,
            commercial_type_id: params.type?.id,
            project_id: params.project?.id,
            origin_id: params.origin?.id,
            language: params.language?.value,
            created_at: params.created_at,
            due_date: params.deadline,
          },
        };
      },
    }),
  }),
});

export type StoreQuotationRequest = {
  client?: Directory;
  category?: Option;
  type?: Option;
  origin?: Option;
  project?: Option;
  language?: any;
  created_at?: string;
  deadline?: string;
};

type Response = ResponseData<Quotation>;

export const {useStoreQuotationMutation} = storeQuotation;
