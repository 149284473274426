import {createAsyncThunk} from '@reduxjs/toolkit';
import googleSignInService from 'services/google-signin';

/**
 * Google login action
 *
 */
export const loginWithGoogle = createAsyncThunk(
  'oauth/loginWithGoogle',
  async () => {
    const result = await googleSignInService.signIn();
    return result;
  }
);
