import {FilterMap, ListConfig} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {isArray} from 'lodash';
import {Option} from 'types/option';
import composeRoute from 'utils/compose-route';

export default (action: PayloadAction<ListConfig>, filters: FilterMap) => {
  const {url, page, otherParams} = action.payload;

  const {
    delimiter = ',',
    optionValue = 'id',
    ...restOfParams
  } = otherParams || {};

  const queryParams = new URL(composeRoute(`api/${url}`));
  const params: FilterMap = {
    page: page ?? '1',
    ...filters,
    ...restOfParams,
  };
  Object.keys(params).forEach(key => {
    if (!!key) {
      const val = params[key];
      let param;
      if (val) {
        if (typeof val === 'string') {
          param = val;
        }

        if (typeof val === 'number') {
          param = val.toString();
        }
        if (typeof val === 'boolean') {
          param = val ? '1' : '0';
        }
        if (typeof val === 'object' && !isArray(val)) {
          param = val[optionValue] as string;
        }

        if (typeof val === 'object' && Array.isArray(val)) {
          const arr = val as Option[];
          param = arr
            .map(x => (typeof x === 'string' ? x : x[optionValue]))
            .join(delimiter);
        }
      }

      if (param) {
        queryParams.searchParams.append(key, param);
      }
    }
  });
  return queryParams;
};
