import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const deleteQuotation = api.injectEndpoints({
  endpoints: builder => ({
    deleteQuotation: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'DELETE',
          url: `/quotations/${params.id}/force-delete`,
        };
      },
    }),
  }),
});

type Request = {id: ObjectId};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useDeleteQuotationMutation} = deleteQuotation;
