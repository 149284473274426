import React, {useEffect} from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useTransferStockLocationForm} from './use-transfer-stock-location-form';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Col, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import ItemPicker from 'components/item-picker';
import {MOVEMENT_TYPE} from './constant';
import AsyncItemPicker from 'components/async-item-picker';
import {Option} from 'types/option';
import TextInput from 'components/text-input';

const TransferStockLocationModal: FC<Props> = props => {
  const {
    serialNumberId,
    productVariantId,
    stockLocationStart,
    serialNumber,
    onDismiss,
  } = props;

  const {t} = useTranslation();

  const movementType = MOVEMENT_TYPE.map(x => ({
    name: t(...x.label()),
    id: x.id,
  }));

  const {
    submit,
    isSubmitting,
    validationErrors,
    params,
    errorMessage,
    setParams,
  } = useTransferStockLocationForm({
    serialNumberId,
    productVariantId,
    stockLocationStart,
    onDismiss,
  });

  const {type, stock_location_arrival_id} = params;

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} /> {serialNumber}
        </span>
      </ModalHeader>

      <ModalBody className="">
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <ItemPicker
          options={movementType}
          value={type}
          valueOnly
          errors={validationErrors && validationErrors['type']}
          label={t(...messages.movementType())}
          onChange={(type: any) =>
            setParams({
              ...params,
              type,
            })
          }
          isDisabled
        />
        <Row className="mt-2">
          <Col>
            <TextInput
              value={stockLocationStart.name}
              label={t(...messages.actualLocation())}
              onChange={() => {}}
              disabled
            />
          </Col>
          <Col>
            <AsyncItemPicker
              endPoint="data/stock-locations"
              containerClassName="d-flex flex-column w-100"
              label={t(...messages.arrivalLocation())}
              className="w-100"
              valueOnly
              value={stock_location_arrival_id}
              errors={
                validationErrors &&
                validationErrors['stock_location_arrival_id']
              }
              onChange={stock_location_arrival_id =>
                setParams({
                  ...params,
                  stock_location_arrival_id,
                })
              }
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isSubmitting}>
          {t('common.saveChanges')}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t('actionVariantModal.cancel')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  productVariantId: ObjectId;
  serialNumberId: ObjectId;
  stockLocationStart: Option;
  serialNumber: string;
  onDismiss: () => void;
}

export default TransferStockLocationModal;
