import React, {FC, useState} from 'react';
import {Spinner, Table} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useGetVariantAlertsInStockQuery} from '../../store/endpoints/get-variant-alerts-in-stock';
import {Link} from 'react-router-dom';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import NaturalNumber from 'components/natural-numbers/natural-numbers';

import useOrderNow from './useOrderNow';
import Button from 'components/button';
import Can from 'features/hylian-shield/can';

interface Props {
  id?: ObjectId;
}

const ProductAlertDetailsTable: FC<Props> = props => {
  const {id} = props;
  const {handleSelect, handleClick, variantsToOrder} = useOrderNow();

  const {t} = useTranslation();

  const {data, isFetching} = useGetVariantAlertsInStockQuery(
    {id},
    {skip: !id, refetchOnMountOrArgChange: true}
  );

  const variants = data?.data ?? [];

  return (
    <div>
      <AppBarActions>
        <Can subject="ccs" action="create-and-edit">
          <Button
            onClick={handleClick}
            disabled={variantsToOrder?.length === 0}
            className="btn-important"
          >
            {t(...messages.orderNow())}
          </Button>
        </Can>
      </AppBarActions>

      <Table responsive>
        {!isFetching && (
          <thead>
            <tr>
              {/* <th /> */}
              <th></th>
              <th>{t(...messages.subFamily())}</th>
              <th>{t(...messages.product())}</th>

              <th className="text-center">{t(...messages.stockAvailable())}</th>
              <th className="text-center">{t(...messages.stockCurrent())}</th>
              <th className="text-center">{t(...messages.stockMin())}</th>
              <th className="text-center">
                {t(...messages.quantityToOrder())}
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {isFetching && (
            <tr>
              <td colSpan={6} className="py-5 text-center">
                <Spinner />
              </td>
            </tr>
          )}
          {!isFetching &&
            variants?.map(v => (
              <tr key={v.id}>
                {/* until we implement creating orders from a modal, this should stay commented out */}
                <td>
                  <input
                    type="checkbox"
                    onClick={(o: any) => handleSelect(v, o.target.checked)}
                    name="alert_order[]"
                  />
                </td>

                <td>
                  <Link
                    to={`/dashboard/products/${v.product_id}/general`}
                    className="alert_choice"
                    data-url="MOBILIER imh//CHAISES ET FAUTEUILS//CAP MARTIN"
                  >
                    {v.product_name}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/dashboard/products/${v.product_id}/variants/${v.id}/general`}
                    className="alert_choice"
                    data-url="MOBILIER imh//CHAISES ET FAUTEUILS//CAP MARTIN"
                  >
                    {v.name}
                  </Link>
                </td>
                <td className="text-center">
                  <NaturalNumber value={v.stock_available} />
                </td>
                <td className="text-center">
                  <NaturalNumber value={v.stock_prod} />
                </td>
                <td className="text-center">
                  <NaturalNumber value={v.stock_min} />
                </td>
                <td className="text-center">
                  <NaturalNumber value={v.quantity_to_order} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProductAlertDetailsTable;
