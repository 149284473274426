import {GoogleResponse, GoogleUser} from './types';
import {application} from 'constants/application';

const googleSignInService = {
  configure: async () => {
    const params = application.oauth.google;
    // GoogleSignin.configure(params);
  },
  signIn: async () => {
    // await GoogleSignin.hasPlayServices();
    // const result = await GoogleSignin.signIn();
    return {} as GoogleUser;
  },
  logout: () => {},
  responseRetrieved: (response: GoogleResponse) => {
    return {
      type: 'googleUserRetrieved',
      payload: {
        user: response.profileObj,
        idToken: response.tokenId,
      },
    };
  },
};

export default googleSignInService;
