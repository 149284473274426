import {selectDomain} from '.';
import selectPublicPrice from './select-public-price';
import selectPurchasePrice from './select-purchase-price';
import {createSelector} from '@reduxjs/toolkit';

export default createSelector(
  [selectDomain, selectPurchasePrice, selectPublicPrice],
  (state, purPrice, pubPrice) => {
    const mCoeffecient = state.coefficients.public;
    if (mCoeffecient) {
      return mCoeffecient;
    }
    if (mCoeffecient === '') {
      return '';
    }
    const result = Number(pubPrice) / Math.max(1, purPrice);
    return result.toFixed(2);
  }
);
