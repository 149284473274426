import React, {FC, Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, ModalFooter, Label, Alert} from 'reactstrap';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import Button from 'components/button';
import messages from './messages';
import {
  UpdateOrderInfoRequest,
  useUpdateOrderInfoMutation,
} from '../../store/endpoints/update-order-info';
import DateInput from 'components/date-input';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import TextInput from 'components/text-input';
import Can from 'features/hylian-shield/can';

interface Props {
  onDismiss: () => void;
}

const EditOrderInfoModal: FC<Props> = props => {
  const {t} = useTranslation();

  const row = useSelector(selectActiveModalParams);

  const {id} = row;

  const {onDismiss} = props;

  const [update, {isLoading, error}] = useUpdateOrderInfoMutation();

  const [params, setParams] = useState<Params>(row);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      ...params,
      id,
    });

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      onDismiss();
    }
  };

  const {errors, message: errorMessage} = parseValidationErrors(error);
  return (
    <Fragment>
      <Fragment>
        <ModalHeader className="bg-primary">
          <span className="text-light">{t(...messages.title())}</span>
        </ModalHeader>

        <ModalBody className="">
          <div>
            <div className="mb-3">
              <DateInput
                label={t(...messages.supplierDelay())}
                value={params.estimated_supplier_time}
                errors={errors['supplier_delay']}
                onChange={estimated_supplier_time =>
                  setParams({...params, estimated_supplier_time})
                }
              />
              <DateInput
                value={params.due_date}
                label={t(...messages.announcedDelay())}
                errors={errors['due_date']}
                onChange={due_date => setParams({...params, due_date})}
              />
              {/* TODO: use components/text-editor instead */}
              <TextInput
                label={t(...messages.productionComments())}
                errors={errors['production_comments']}
                type="textarea"
                rows={4}
                value={params.production_comments}
                onChange={production_comments =>
                  setParams({...params, production_comments})
                }
              />
              {errors['production_comments'] ? (
                <div className="invalid-feedback d-block">
                  {errors['production_comments']}
                </div>
              ) : null}
            </div>
            <div>
              {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <a
            className="btn btn-link"
            color="link"
            href={`/app/dashboard/orders/${id}/general`}
            rel="noreferrer"
            target="_blank"
          >
            {t(...messages.goToDocument())}
          </a>
          <Button color="light" onClick={onDismiss}>
            {t('common.cancel')}
          </Button>
          <Can action="administration" subject="production-monitoring">
            <Button
              color="important"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {t('common.saveChanges')}
            </Button>
          </Can>
        </ModalFooter>
      </Fragment>
    </Fragment>
  );
};

export default EditOrderInfoModal;

type Params = UpdateOrderInfoRequest;
