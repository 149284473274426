import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  cancel: () => __(translations.usersCreatePage.cancel, 'Cancel'),
  dashboard: () => __(translations.usersCreatePage.dashboard, 'Dashboard'),
  email: () => __(translations.usersCreatePage.email, 'E-mail'),
  password: () => __(translations.usersCreatePage.password, 'Password'),
  passwordConfirmation: () => __(translations.usersCreatePage.passwordConfirmation, 'Confirm password'),
  role: () => __(translations.usersCreatePage.role, 'Role'),
  submit: () => __(translations.usersCreatePage.submit, 'Submit'),
  title: () => __(translations.usersCreatePage.title, 'Create User'),
  firstName: () => __(translations.usersCreatePage.firstName, 'First name'),
  lastName: () => __(translations.usersCreatePage.lastName, 'Last name'),
  users: () => __(translations.usersCreatePage.users, 'Users'),
  pageTitle: () => __(translations.usersCreatePage.pageTitle, 'Create user'),
  successMessage: () => __(translations.usersCreatePage.successMessage, 'User created successfully'),
};
