import {updateCachedVariantDetailsFromResource} from './show-variant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const blockVariantAction = api.injectEndpoints({
  endpoints: builder => ({
    blockVariantAction: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/${params.id}/block`,
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const result = (await queryFulfilled).data.data;
          dispatch(updateCachedVariantDetailsFromResource(result));
        } catch {}
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
};

type Response = ResponseData<ProductVariant>;

export const {useBlockVariantActionMutation} = blockVariantAction;
