import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const UpdateDirectory = api.injectEndpoints({
  endpoints: builder => ({
    UpdateDirectory: builder.mutation<Response, UpdateDirectoryRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `directories/${params.id}`,
          body: {
            comment: params.comment,
            color_code: params.color_code,
            company_name: params.company_name,
            description: params.description,
            directory_categories: params.directory_categories.map(x => x.id),
            directory_families: params.families.map(x => x.id),
            directory_type: params.directory_type,
            email: params.email,
            first_name: params.first_name,
            gender: params.gender?.value,
            newsletters: params?.newsletters?.map(x => x.id),

            website: params.website,
            job: params.job,
            last_name: params.last_name,
            is_official_furniture_supplier:
              params.is_official_furniture_supplier,
            is_official_studio_supplier: params.is_official_studio_supplier,
            is_official_suppliers_small_items:
              params.is_official_suppliers_small_items,
            reference_contact_id: params.reference_contact?.id,
            requirement_rate: params.requirement_rate?.value,
            residency_country: params.residency_country?.id,
          },
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;
          dispatch(updateCachedDirectoryFromResource(directory));
        } catch {}
      },
    }),
  }),
});

export type UpdateDirectoryRequest = {
  catalog?: boolean;
  id?: ObjectId;
  directory_categories: Option[];
  comment?: string;
  company_name?: string;
  description?: string;
  email?: string;
  families: Option[];
  color_code?: string;
  first_name?: string;
  gender?: Option;
  has_newsletter?: boolean;
  newsletters?: Option[];

  job?: string;
  last_name?: string;
  is_official_furniture_supplier?: boolean;
  is_official_studio_supplier?: boolean;
  is_official_suppliers_small_items?: boolean;
  reference_contact?: Option;
  requirement_rate?: Option;
  residency_country?: Option;
  website?: string;
  directory_type?: string;
};

type Response = ResponseData<Directory>;

export const {useUpdateDirectoryMutation} = UpdateDirectory;
