import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getPriceHistory = api.injectEndpoints({
  endpoints: builder => ({
    getPriceHistory: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `product-variants/${params.variant_id}/cost-history`,
      }),
    }),
  }),
});

type Request = {
  variant_id?: ObjectId;
};

type Response = ResponseData<
  {
    public_price: string;
    created_at: string;
    total_cost: string;
  }[]
>;

export const {useGetPriceHistoryQuery} = getPriceHistory;
