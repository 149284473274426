import React, {FC, useContext} from 'react';
import ItemMemos from '../../components/item-memos/item-memos';
import ItemRemovalButton from '../../components/item-removal-button/item-removal-button';
import DragHandleSpacer from '../../components/spacing/drag-handle-spacer';
import EmptyLabel from '../../components/spacing/empty-label';
import ItemOptionsSection from '../item-options-section/item-options-section';
import updateItem from '../../store/actions/update-item';
import selectLineTotal from '../../store/selectors/select-line-total';
import {CommercialProduct} from '../../types/commercial-product';
import documentContext from '../document-context/document-context';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import {useMediaQuery} from 'usehooks-ts';
import OtherOptionsButton from '../../components/other-options-button/other-options-button';
import {currency} from '../../utils/currency-parser';
import {ProjectFeeTypeEnum} from '../../types/commercial-document';

interface Props {
  index: number;
  item: CommercialProduct;
  dragHandleProps?: any;
}

const ItemExternalProduct: FC<Props> = props => {
  const {item, index, dragHandleProps} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(item, key, v));
  };

  const {validationErrors, is_locked, doc} = useContext(documentContext);

  const row = useSelector(selectLineTotal(item));

  const isMd = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)'
  );

  const feeType = doc?.project_fee_type?.value;

  return (
    <div className="mb-3">
      <Row className="mb-3">
        <Col md="auto">
          <div>
            <Button
              disabled={is_locked}
              color="link"
              size="sm"
              className="dragHandle p-0"
              {...dragHandleProps}
            >
              <Icon name="menu" color="gray" size={20} />
            </Button>
          </div>
          <div>
            <OtherOptionsButton item={item} />
          </div>
        </Col>
        <Col>
          <Row>
            <Col md={6}>
              <div className="d-flex">
                <TextInput
                  containerStyle="flex-grow-1"
                  label={t('commercialDocumentComponents.externalProduct')}
                  value={item.custom_name}
                  disabled={is_locked}
                  errors={validationErrors[`products.${index}.custom_name`]}
                  onChange={p => handleUpdate('custom_name', p)}
                />
              </div>
              <ItemMemos item={item} />
            </Col>

            <Col md={isMd && 4}>
              <TextInput
                label={t(...messages.unitPrice())}
                value={item.unit_price_ht}
                disabled={is_locked}
                type="number"
                onChange={p => handleUpdate('unit_price_ht', p)}
                errors={validationErrors[`products.${index}.unit_price_ht`]}
              />
            </Col>
            <Col>
              <TextInput
                label={
                  feeType === ProjectFeeTypeEnum.SOURCING_FEE
                    ? t(...messages.commission())
                    : t(...messages.discount())
                }
                value={item.discount}
                type="number"
                disabled={is_locked}
                errors={validationErrors[`products.${index}.discount`]}
                onChange={p => handleUpdate('discount', p)}
              />
            </Col>
            {feeType !== ProjectFeeTypeEnum.SOURCING_FEE && (
              <Col>
                <TextInput
                  label={t(...messages.quantity())}
                  value={item.quantity}
                  disabled={is_locked}
                  errors={validationErrors[`products.${index}.quantity`]}
                  type="number"
                  onChange={q => handleUpdate('quantity', q)}
                />
              </Col>
            )}

            <Col md={isMd && 2}>
              <TextInput
                label={t(...messages.subTotal())}
                value={currency(
                  row.priceExclTaxes,
                  doc?.currency_symbole || ''
                )}
                readOnly
                disabled
                onChange={() => {}}
              />
            </Col>
            <Col className="" md="auto">
              <EmptyLabel />
              <ItemRemovalButton item={item} />
            </Col>
          </Row>
        </Col>
      </Row>

      <ItemOptionsSection item={item} type="OPTION" lineIndex={index} />

      <Row>
        <Col md="auto">
          <DragHandleSpacer />
        </Col>
        <Col>{/* <ItemLinkingSection item={item} index={index} /> */}</Col>
        <Col>
          {/* <RelatedDocumentsSection
            item={item}
            index={index}
            docs={item.related_documents}
          /> */}
        </Col>
      </Row>
    </div>
  );
};

export default ItemExternalProduct;
