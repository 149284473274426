import React, {FC, MouseEvent, useContext, useState} from 'react';
import messages from './messages';
import './style.scss';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {MODAL} from 'features/modal-notch/constants';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import showModal from 'store/ui/actions/show-modal';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {useValidateInvoiceMutation} from '../../store/endpoints/action-invoice-validate';
import {Invoice} from '../../types/invoice';
import toast from 'store/ui/actions/toast';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {PaymentMethod} from 'features/commercial/documents/types/payment-method';
import invoiceContext from 'features/commercial/documents/containers/document-context/document-context';
import Can from 'features/hylian-shield/can';
import {Subject} from 'features/hylian-shield/types';

interface Props {
  invoice: Invoice;
}

const ActionsTab: FC<Props> = props => {
  const {invoice} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [validateInvoice, {isLoading, error}] = useValidateInvoiceMutation();
  const serverError = parseValidationErrors(error);

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState<{[key: string]: boolean}>({
    print: false,
    credit: false,
  });

  const toggle = (event: MouseEvent<HTMLElement>) => {
    const {id: key} = event.currentTarget;

    setDropdownOpen(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const {id} = useParams();

  const handleDuplicat = () => {
    dispatch(
      showModal(MODAL.INVOICE_DUPLICAT, {
        id: id,
        message: t(...messages.duplicateAlertMessage()),
      })
    );
  };

  const handleImportPayment = (type: PaymentMethod) => {
    const typeOption = {
      value: type,
      label: t(`paymentPickerModal.${type?.toLocaleLowerCase()}`),
    };
    dispatch(
      showModal(MODAL.PAYMENT_PICKER_MODAL, {
        type: typeOption,
        directory_id: invoice?.directory_id,
        model_type: invoice.document_type,
        model_id: invoice?.id,
      })
    );
  };

  const handleSendEmail = () => {
    dispatch(
      showModal(MODAL.INVOICE_SEND_EMAIL, {
        id: id,
        onSuccess: () => {},
      })
    );
  };

  const handleGenerateCredit = () => {
    dispatch(
      showModal(MODAL.INVOICE_GENERATE_CREDIT, {
        id: id,
        onSuccess: () => {},
      })
    );
  };
  const handleGenerateAccountingCredit = () => {
    dispatch(
      showModal(MODAL.INVOICE_GENERATE_ACCOUNTING_CREDIT, {
        id: id,
        onSuccess: () => {},
      })
    );
  };

  const handleValidation = async () => {
    const result = await validateInvoice({id: id});
    if ('error' in result) {
      dispatch(toast('error', serverError.message));
    } else if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
    setDropdownOpen(prev => ({
      ...prev,
      credit: false,
    }));
  };

  return (
    <AppBarActions>
      <div className="order-1 d-flex align-items-center">
        <Button
          onClick={handleValidation}
          isLoading={isLoading}
          color="light mx-2"
        >
          {invoice?.status === 'VALIDATED' ? (
            <>
              <Icon name="unlock" size={15} className="me-2" />
              {t('common.unlock')}
            </>
          ) : (
            <>
              <Icon name="lock" size={15} className="me-2" />
              {t('common.validate')}
            </>
          )}
        </Button>
        <Can
          subject={
            invoice.commercial_type?.code?.toLocaleLowerCase() as Subject
          }
          action="export"
        >
          <Dropdown isOpen={dropdownOpen.print} toggle={toggle}>
            <DropdownToggle id="print" className="btn-light me-2" caret>
              {t(...messages.print())}
              <Icon name="chevron-down" className="ms-1" size={15} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem target={'_blank'} href={invoice?.pdf_links?.fr}>
                {t(...messages.printFrenchVersion())}
              </DropdownItem>
              <DropdownItem target={'_blank'} href={invoice?.pdf_links?.en}>
                {t(...messages.printEnglishVersion())}
              </DropdownItem>
              {invoice?.document_type === 'DEFAULT' ? (
                <>
                  {/* <DropdownItem
                  target={'_blank'}
                  href={invoice?.pdf_links?.stock_resume}
                >
                  {t(...messages.exportStockResume())}
                </DropdownItem> */}
                  <DropdownItem
                    target={'_blank'}
                    href={invoice?.pdf_links?.packing_list_fr}
                  >
                    {t(...messages.exportPackageListFr())}
                  </DropdownItem>
                  <DropdownItem
                    target={'_blank'}
                    href={invoice?.pdf_links?.packing_list_en}
                  >
                    {t(...messages.exportPackageListEn())}
                  </DropdownItem>
                </>
              ) : null}
            </DropdownMenu>
          </Dropdown>
        </Can>
        <Button
          color="light"
          className="text-nowrap me-2"
          onClick={handleDuplicat}
        >
          <Icon name="copy" size={15} className="me-2" />
          {t('common.duplicat')}
        </Button>
        {invoice?.document_type === 'DEFAULT' ? (
          <>
            <Button className="me-2" color="light" onClick={handleSendEmail}>
              <Icon name="send" size={15} className="me-2" />
              {t(...messages.sendEmailInvoice())}
            </Button>

            <Dropdown isOpen={dropdownOpen.credit} toggle={toggle}>
              <DropdownToggle id="credit" className="btn-light me-2" caret>
                {t(...messages.generateCredit())}
                <Icon name="chevron-down" className="ms-1" size={15} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleGenerateCredit}>
                  {t(...messages.generateCredit())}
                </DropdownItem>
                <DropdownItem
                  // disabled={invoice?.status !== 'VALIDATED'}
                  onClick={handleGenerateAccountingCredit}
                >
                  {t(...messages.generateAccountingCredit())}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        ) : null}

        <Can
          subject={
            invoice.commercial_type?.code?.toLocaleLowerCase() as Subject
          }
          action="credits-overpayments-use"
        >
          <Button
            onClick={() => handleImportPayment('OVERPAYMENT')}
            disabled={(invoice?.directory?.overpayment_count ?? 0) < 1}
            color="light"
            className="me-2"
          >
            <Trans i18nKey={`addPaymentDropdown.overpayment`} />{' '}
            {invoice?.directory && (
              <Badge
                color={`${
                  invoice.directory.overpayment_count > 0 ? 'success' : 'dark'
                }`}
              >
                {invoice.directory.overpayment_count}
              </Badge>
            )}
          </Button>
          <Button
            onClick={() => handleImportPayment('CREDIT')}
            disabled={(invoice?.directory?.credit_count ?? 0) < 1}
            color="light"
          >
            <Trans i18nKey={`addPaymentDropdown.credit`} />{' '}
            {invoice?.directory && (
              <Badge
                color={`${
                  invoice.directory.credit_count > 0 ? 'success' : 'dark'
                }`}
              >
                {invoice.directory.credit_count}
              </Badge>
            )}
          </Button>
        </Can>
      </div>
    </AppBarActions>
  );
};

export default ActionsTab;
