import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {IExpense, Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectExpenses = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectExpenses: builder.mutation<
      Response,
      StoreProjectExpensesRequest
    >({
      query: (body: StoreProjectExpensesRequest) => {
        return {
          method: 'POST',
          url: `projects/${body.id}/expenses`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export interface IExpenseRequest extends IExpense {
  user_id: ObjectId;
}

export type StoreProjectExpensesRequest = {
  id: ObjectId;
  expenses: IExpenseRequest[];
  deleted_records: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectExpensesMutation} = storeProjectExpenses;
