import {ApiEndpointBuilder} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {Role} from 'types/role';
import {User} from 'types/user';

const storeUserEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Response, Request>({
    query: params => {
      return {
        method: 'POST',
        url: 'users',
        body: {
          first_name: params.first_name,
          last_name: params.last_name,
          username: params.username,
          email: params.email,
          phone_number: params.phone_number,
          password: params.password,
          password_confirmation: params.password_confirmation,
          role_id: params.role?.id,
        },
      };
    },
  });
};

type Request = {
  first_name?: string;
  last_name?: string;
  username?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
  role?: Role;
};

type Response = ResponseData<User>;

export default storeUserEndpoint;
