import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';
import {updateCachedQuotationFromResource} from './show-quotation';

const archiveQuotation = api.injectEndpoints({
  endpoints: builder => ({
    archiveQuotation: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'PUT',
          url: `quotations/${params.id}/archive`,
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const q = (await queryFulfilled).data?.data;
          dispatch(updateCachedQuotationFromResource(q));
        } catch {
          // do nothing
        }
      },
    }),
  }),
});

type Request = {id: ObjectId};

type Response = ResponseData<Quotation>;

export const {useArchiveQuotationMutation} = archiveQuotation;
