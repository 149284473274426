import { useTranslation } from "react-i18next";

export type EnumMapping<T extends Record<string, string>> = {
  name: string;
  id: keyof T;
};


export const useEnumAsOption = <T extends Record<string, string>>(
  enumObject: T,
  translationPrefix: string,
) => {
  const { t } = useTranslation()

  /**
   * @summary transform enum to array option for pickers components
   * @param enumObject 
   * @param translationPrefix 
   * @returns EnumMapping<T>[]
   */
    return (Object.keys(enumObject) as Array<keyof T>).map((key) => {
      return {
        name: t(`${translationPrefix}.${key.toString().toLocaleLowerCase() as string}`),
        id: key,
      };
  })


}