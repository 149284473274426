import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDeleteDirectoryMutation} from 'store/directory/endpoints/delete-directory';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useArchiveDirectoryForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDeleteDirectoryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, directory_id: id} = modalParams;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit({
      url: modalParams.url,
      id,
    });
    if ('data' in result) {
      dispatch(toast('success', result?.data?.data.message));
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
