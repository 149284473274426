import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'costs/updateCoefficient',
  function prepare(purchasePrice: string | number, value: string | number) {
    return {
      payload: {
        value,
        purchasePrice,
      },
    };
  }
);
