import {api} from 'services/api';
import {Material} from 'types/materials';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateMaterial = api.injectEndpoints({
  endpoints: builder => ({
    updateMaterial: builder.mutation<Response, UpdateMaterialRequest>({
      query: params => {

        return {
          method: 'PUT',
          url: `materials/${params.id}`,
          body: {
            name: params.name,
          },
        };
      },
    }),
  }),
});

export type UpdateMaterialRequest = {
  id?: ObjectId;
  name: {
    fr?: string;
    en?: string;
  };
};

type Response = ResponseData<Material>;

export const {useUpdateMaterialMutation} = updateMaterial;
