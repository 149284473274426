import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ITeamForm} from '../use-team-form';
import messages from '../messages';

import {CardBody, CardTitle} from 'reactstrap';
import {TeamTypesEnum} from 'constants/team';
import {TeamAccordion} from './team-accordion';

const BodySection: FC<Props> = props => {
  const {t} = useTranslation();

  // India and the rest of the team must be separated
  const indiaOnly = props.regroupedTeam.filter(
    el => el.type === TeamTypesEnum.ARTISTIC_DIRECTOR
  );
  const team = props.regroupedTeam.filter(
    el => el.type !== TeamTypesEnum.ARTISTIC_DIRECTOR
  );

  return (
    <CardBody>
      {props.defaultOpenedAccordion.length > 0 ? (
        <div>
          <div className="d-flex justify-content-between">
            <CardTitle tag="h4">India</CardTitle>
            <h6>{`${props.project?.artistic_director_mandays_rate ?? 0} ${t(
              ...messages.manDays()
            )}`}</h6>
          </div>
          <TeamAccordion {...props} regroupedTeam={indiaOnly} />
          <br />
          <div className="d-flex justify-content-between">
            <CardTitle tag="h4">{t(...messages.team())}</CardTitle>
            <h6>{`${props.project?.others_mandays_rate ?? 0} ${t(
              ...messages.manDays()
            )}`}</h6>
          </div>
          <TeamAccordion {...props} regroupedTeam={team} />
        </div>
      ) : (
        <div className="d-flex justify-content-center py-4">
          <p>{t('common.noData')}</p>
        </div>
      )}
    </CardBody>
  );
};

type Props = ITeamForm;

export default BodySection;
