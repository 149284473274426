import React from 'react';
import {FC} from 'react';
import messages from './messages';
import Can from 'features/hylian-shield/can';
import {MODAL} from 'features/modal-notch/constants';
import {Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {Directory} from 'types/directory';
import Button from 'components/button';

interface Props {
  directory: Directory;
}

const DirectoryActions: FC<Props> = ({directory}) => {
  const dispatch = useDispatch();

  const {t} = useTranslation();
  const handleDelete = () => {
    dispatch(
      showModal(MODAL.DESTROY_DIRECTORY, {
        directory_id: directory.id,
        message: t(...messages.deleteAlertMessage()),
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="delete-and-merge" subject="directory">
        <Button color="transparent" onClick={handleDelete}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default DirectoryActions;
