import React, {ReactNode} from 'react';

export const addHighlight = (className: string) => {
  const elements = document.querySelectorAll(`.${className}`);

  elements?.forEach(element => {
    element.classList.add('bg-light');
  });
};

export const removeHighlight = (className: string) => {
  const elements = document.querySelectorAll(`.${className}`);

  elements?.forEach(element => {
    element.classList.remove('bg-light');
  });
};

export const HighLighter = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => {
  return (
    <span
      onMouseEnter={() => addHighlight(className)}
      onMouseLeave={() => removeHighlight(className)}
    >
      {children}
    </span>
  );
};
