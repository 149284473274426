import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const DeleteDirectory = api.injectEndpoints({
  endpoints: builder => ({
    DeleteDirectory: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'DELETE',
          url: `directories/${params.id}`,
        };
      },
      // @ts-expect-error
      invalidatesTags: (_a, _b, arg) =>
        _a ? [{type: 'directories', id: arg.id}] : [],
    }),
  }),
});

type Request = {
  id?: ObjectId;
  url: string;
  action?: string;
};

type Response = ResponseData<{directory_id: ObjectId; message: string}>;

export const {useDeleteDirectoryMutation} = DeleteDirectory;
