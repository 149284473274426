import React, {FC} from 'react';
import CarouselPage from '../../auth-inner/CarouselPage';
import UpdatePasswordSection from './update-password-section';
import VerifyTokenSection from './verify-token-section';
import logo from 'assets/images/logo-sm.svg';
import {application} from 'constants/application';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Col, Container} from 'reactstrap';
import {selectResetInfo} from 'store/auth/selectors';

interface Props {}

const ResetPasswordPage: FC<Props> = () => {
  const {resetToken, passwordResetTokenRequested} =
    useSelector(selectResetInfo);

  const isTokenValid = resetToken && passwordResetTokenRequested;
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" />{' '}
                        <span className="logo-txt">{application.name}</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      {isTokenValid && <UpdatePasswordSection />}
                      {!resetToken && passwordResetTokenRequested && (
                        <VerifyTokenSection />
                      )}
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {application.name}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
