import React from 'react';
import CalendarPage from 'pages/dashboard/production-monitoring/calendar/calendar-page';
import ListingPage from 'pages/dashboard/production-monitoring/listing/listing-page';
import {Route, Routes} from 'react-router';

const MonitoringRoutes = () => {
  return (
    <Routes>
      <Route path="/listing/*" element={<ListingPage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route
        path="/supplier"
        element={
          <div>
            <h1>Fournisseur</h1>
            Fournisseur...
          </div>
        }
      />

      <Route path={`/`} element={<div>Nothing…</div>} />
    </Routes>
  );
};

export default MonitoringRoutes;
