import {authApi} from '../endpoints';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {MutationThunkArg} from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {WritableDraft} from 'types/writable-draft';

const forgotPasswordMatcher = authApi.endpoints.forgotPassword.matchFulfilled;

const forgotPasswordReducer = (
  state: WritableDraft<AuthState>,
  action: ForgotPasswordAction
) => {
  state.resetPassword.passwordResetTokenRequested = true;
  state.resetPassword.email = action.meta.arg.originalArgs.email;
};

/**
 * User requested a password reset link
 *
 */
export const forgotPassword = {
  matcher: forgotPasswordMatcher,
  reducer: forgotPasswordReducer,
};

type ForgotPasswordAction = PayloadAction<
  unknown,
  string,
  {
    arg: MutationThunkArg & {
      originalArgs: {
        email: string;
      };
    };
    requestId: string;
    requestStatus: 'fulfilled';
  } & {
    fulfilledTimeStamp: number;
    baseQueryMeta: unknown;
  },
  never
>;
