import React from 'react';
import {AppRoute} from './types';
import RegisterPage from 'pages/auth/Register';
import ForgetPasswordPage from 'pages/auth/forgot-password';
import LoginPage from 'pages/auth/login';
import ResetPasswordPage from 'pages/auth/reset-password';

const guestRoutes: Array<AppRoute> = [
  {path: '/login', component: <LoginPage />},
  {path: '/register', component: <RegisterPage />},
  {path: '/forgot-password', component: <ForgetPasswordPage />},
  {path: '/reset-password', component: <ResetPasswordPage />},
];

export default guestRoutes;
