import {
  useUpdateStockEntryMutation,
  updateStockEntryRequest,
} from 'features/stock/store/endpoint/update-stock-entry';
import {StockProduct} from 'features/stock/types/stock-products';
import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useStockEntryForm = () => {
  const [submit, {isLoading, error}] = useUpdateStockEntryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);
  const stockProduct = modalParams?.stockProduct as StockProduct;

  const [params, setParams] = useState<Params>({
    serial_number: stockProduct?.code,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(
        loadData({name: 'stock-products', url: 'stock/products', page: 1})
      );
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    stockProduct,
    submit: handleSubmit,
  };
};

type Params = updateStockEntryRequest;
