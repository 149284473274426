import {useState} from 'react';
import {useGenerateCreditFromInvoiceMutation} from '../../../store/endpoints/action-generate-credit';
import messages from '../messages';
import {selectInvoice} from 'features/commercial/invoices/store/endpoints/show-invoice';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import denormalizeObject from 'utils/denormalize-object';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useGenerateCreditFromInvoiceMutation();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCommercialProducts, setSelectedCommercialProducts] = useState<
    Record<ObjectId, boolean>
  >({});

  const serverError = parseValidationErrors(error);

  const {t} = useTranslation();

  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const result = await submit({
      id,
      document_type: 'CREDIT',
      commercial_product_ids: Object.keys(selectedCommercialProducts)?.filter(
        id => selectedCommercialProducts[id]
      ),
    });
    if ('data' in result) {
      navigate(`/dashboard/invoices/${result?.data?.data.id}/details`);
      dispatch(toast('success', t(...messages.invoiceCreditSuccess())));
      onDismiss();
    }
  };

  const {data} = useSelector(selectInvoice({id}));

  const commercialProducts =
    denormalizeObject(data?.data.commercial_products_objectified) ?? [];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedCommercialProducts(
      commercialProducts.reduce(
        (acc, v) => ({
          ...acc,
          [v.id]: !selectAll,
        }),
        {}
      )
    );
  };
  const toggleCommercialProduct = (v: any) => {
    setSelectAll(false);

    setSelectedCommercialProducts({
      ...selectedCommercialProducts,
      [v.id]: !selectedCommercialProducts[v.id],
    });
  };

  return {
    message,
    isLoading,
    submit: handleSubmit,
    errorMessage: serverError.message,
    toggleCommercialProduct,
    toggleSelectAll,
    selectAll,
    selectedCommercialProducts,
    commercialProducts,
  };
};
