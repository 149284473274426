import React, {FC} from 'react';
import LoadRunner from 'components/load-runner';
import ContentLoader from 'react-content-loader';

interface Props {
  numberOfItems?: number;
}

const CatLoader: FC<Props> = props => {
  const {numberOfItems} = props;
  const width = 141;
  const fake = Array.from(Array(numberOfItems || 9).keys());
  const itemsRowone = fake.map(x => (
    <rect
      key={x}
      x={1 + 155 * x}
      y="5"
      rx="5"
      ry="5"
      width={width}
      height="26"
    />
  ));
  const itemsRowtwo = fake.map(x => (
    <rect
      key={x}
      x={1 + 155 * x}
      y="39"
      rx="5"
      ry="5"
      width={width}
      height="26"
    />
  ));
  return (
    <div style={{width: '100%', height: '90px'}}>
      <LoadRunner
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 100% 10%"
        {...props}
      >
        {itemsRowone}
        {itemsRowtwo}
      </LoadRunner>
    </div>
  );
};

export default CatLoader;
