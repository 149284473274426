import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {InvoiceFfeVintage} from '../types/invoice-ffe-vintage';

const getProjectSellsFFEInvoices = api.injectEndpoints({
  endpoints: builder => ({
    getProjectSellsFFEInvoices: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: params.product_type
            ? `projects/${params.id}/invoices?for_vente_ffe&${params.product_type}`
            : `projects/${params.id}/invoices?for_vente_ffe`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  product_type?: string;
};

type Response = ResponseData<InvoiceFfeVintage[]>;

export const {useLazyGetProjectSellsFFEInvoicesQuery} =
  getProjectSellsFFEInvoices;
