import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.creditGeneralInformation.title, 'General information'),
  client: () => __(translations.creditGeneralInformation.client, 'Client'),
  creditBillingDate: () => __(translations.creditGeneralInformation.creditBillingDate, 'Credit billing date'),
  creditDueDate: () => __(translations.creditGeneralInformation.creditDueDate, 'Credit due date'),
  creditDeliveryDate: () => __(translations.creditGeneralInformation.creditDeliveryDate, 'Credit delivery date'),
  addNewCompanyClient: () => __(translations.creditGeneralInformation.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.creditGeneralInformation.addNewPrivateClient, 'Add Private'),
  creditType: () => __(translations.creditGeneralInformation.creditType, 'Credit Type'),
  creditCategory: () => __(translations.creditGeneralInformation.creditCategory, 'Credit Category'),
  creditOrigin: () => __(translations.creditGeneralInformation.creditOrigin, 'Origin'),
  creditLanguage: () => __(translations.creditGeneralInformation.creditLanguage, 'Language'),
  creditCurrency: () => __(translations.creditGeneralInformation.creditCurrency, 'Currency'),
  clientReference: () => __(translations.creditGeneralInformation.clientReference, 'Client Reference'),
  creditProject: () => __(translations.creditGeneralInformation.creditProject, 'Project'),
  changesCommited: () => __(translations.creditGeneralInformation.changesCommited, 'Your changes has been saved successfully!'),
  afterSalesServiceInvoice: () => __(translations.creditGeneralInformation.afterSalesService, 'After-sales service invoice'),
  creditCurrencyConversion: () => __(translations.creditGeneralInformation.creditCurrencyConversion, 'Currency conversion'),
  privateComment: () => __(translations.creditGeneralInformation.privateComment, 'Private comment'),
};
