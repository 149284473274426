import React, {FC, Fragment} from 'react';
import {FilterMap} from 'features/table/slice/types';
import {CardBody, Row, Col, FormGroup} from 'reactstrap';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
}

const FiltersSection: FC<FilterProps> = () => {
  return (
    <Fragment>
      <CardBody className="py-0">
        <Row className="mt-1">
          <Col lg="3" md="3"></Col>
          <Col lg="3" md="3">
            <FormGroup></FormGroup>
          </Col>
          <Col lg="3" md="3">
            <FormGroup></FormGroup>
          </Col>
          <Col lg="3" md="3" className="pt-4"></Col>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default FiltersSection;
