import {Invoice} from '../../types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const showInvoice = api.injectEndpoints({
  endpoints: builder => ({
    showInvoice: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `invoices/${params.id}/commercial-products`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'invoices', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Invoice>;

export const {useLazyShowInvoiceQuery, useShowInvoiceQuery} = showInvoice;

export const updateCachedInvoiceFromResource = (invoice: Invoice) => {
  return showInvoice.util.updateQueryData(
    'showInvoice',
    {id: String(invoice.id)},
    x => {
      x.data = invoice;
    }
  );
};

export const selectInvoice = showInvoice.endpoints.showInvoice.select;
