import {createSelector} from '@reduxjs/toolkit';
import {selectDomain} from '.';

export default createSelector([selectDomain], domain => {
  const allProducts = domain.expandedProducts;

  const expandProduct = Object.values(allProducts).at(0);

  return expandProduct;
});
