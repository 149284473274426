import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  StoreAddressRequest,
  useStoreAddressMutation,
} from 'store/directory/endpoints/store-address';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {Quotation} from 'types/quotation';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAddressForm = () => {
  const [submit, {isLoading, error}] = useStoreAddressMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const quotation = modalParams?.quotation as Quotation | undefined;

  const fake = useSeeder();

  const {directory} = modalParams;

  const [params, setParams] = useState<Params>({
    id: directory,
    zip_code: __DEV__ ? fake.zipCode : '',
    comment: __DEV__ ? fake.paragraph : '',
    label: __DEV__ ? fake.fileName() : '',
    first_line: __DEV__ ? fake.address() : '',
    second_line: __DEV__ ? fake.address() : '',
    vat_mode: undefined,
    city: __DEV__ ? fake.city : '',
    third_line: __DEV__ ? fake.address() : '',
    address_type: undefined,
    is_default: false,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(uiActions.dismissModalWithResult(result.data.data));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = StoreAddressRequest;
