import React, {FC} from 'react';
import {ButtonProps, Button as RSButton} from 'reactstrap';

interface Props extends ButtonProps {
  isLoading?: boolean;
  color?: string;
}

const Button: FC<Props> = props => {
  const {isLoading, disabled, children, ...rest} = props;
  return (
    <RSButton disabled={isLoading || disabled} {...rest}>
      {isLoading && (
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
      )}
      {children}
    </RSButton>
  );
};

export default Button;
