import React from 'react'
import AsyncItemPicker from "components/async-item-picker";
import { Directory } from "types/directory";
import GetOptionLabel from "./get-option-label";
import NoOptionMessage from "./no-option-message";
import { User } from 'types/user';


const TeamPicker = ({label, value, otherParams, onChange} : TeamPickerType) => (
    <AsyncItemPicker
        endPoint="data/users?from_project_contact"
        otherParams={otherParams}
        getOptionLabel={(user: User) => (
            <GetOptionLabel user={user} />
        )}
        label={label}
        optionLabel=""
        value={value}
        onChange={onChange}
        noOptionsMessage={(e: any) => (
            <NoOptionMessage newClient={e.inputValue} />
        )}
    />
)

type TeamPickerType = {
    label: string;
    value: any;
    otherParams?: {
        excluded_families: string;
        excluded_categories: string
    };
    onChange: {
        (value: Directory): void
    }
}

export default TeamPicker;