import React, {FC} from 'react';
import messages from './messages';
import {STATUS} from 'constants/status';
import {useTranslation} from 'react-i18next';
import {Badge} from 'reactstrap';

interface Props {
  status?: STATUS | string;
  text?: string;
  color?: string;
}

/**
 *
 * @param status
 * @param color
 * @param text // translation index
 * @returns
 */

const StatusBadge: FC<Props> = ({status, color, text}) => {
  const {t} = useTranslation();
  switch (status) {
    case STATUS.IN_PROGRESS:
      return (
        <Badge className="py-1 px-2 " color={color ?? 'secondary'}>
          {text ? t(text) : t(...messages.inProgress())}{' '}
        </Badge>
      );
    case STATUS.IN_PRODUCTION:
      return (
        <Badge className="py-1 px-2 " color={color ?? 'secondary'}>
          {text ? t(text) : t(...messages.inProduction())}{' '}
        </Badge>
      );
    case STATUS.PARTIALLY_IN_STOCK:
      return (
        <Badge className="py-1 px-2 " color={color ?? 'secondary'}>
          {text ? t(text) : t(...messages.partiallyInStock())}{' '}
        </Badge>
      );

    case STATUS.PARTIALLY_IN_PRODUCTION:
      return (
        <Badge className="py-1 px-2 " color={color ?? 'secondary'}>
          {text ? t(text) : t(...messages.partiallyInProduction())}{' '}
        </Badge>
      );

    case STATUS.PENDING:
      return (
        <Badge className="py-1 px-2" color={color ?? 'important'}>
          {text ? t(text) : t(...messages.pending())}{' '}
        </Badge>
      );

    case STATUS.APPROVED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.approved())}
        </Badge>
      );

    case STATUS.REJECTED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.rejected())}{' '}
        </Badge>
      );
    case STATUS.CANCELED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.canceled())}{' '}
        </Badge>
      );
    case STATUS.ENABLED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.enabled())}{' '}
        </Badge>
      );
    case STATUS.DISABLED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.disabled())}{' '}
        </Badge>
      );
    case STATUS.DELETED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.deleted())}{' '}
        </Badge>
      );
    case STATUS.EXPIRED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.expired())}{' '}
        </Badge>
      );
    case STATUS.IN_REVIEW:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.inReview())}{' '}
        </Badge>
      );
    case STATUS.VALIDATED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.validated())}{' '}
        </Badge>
      );

    case STATUS.CLOSED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.closed())}{' '}
        </Badge>
      );
    case STATUS.COMPLETED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.completed())}{' '}
        </Badge>
      );
    case STATUS.FAILED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.failed())}{' '}
        </Badge>
      );
    case STATUS.OPENED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.opened())}{' '}
        </Badge>
      );

    case STATUS.PARTIALLY_RECEIVED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.partiallyReceived())}{' '}
        </Badge>
      );
    case STATUS.AWAITING_SHIPMENT:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.awaitingShipment())}{' '}
        </Badge>
      );
    case STATUS.INVOICED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.invoiced())}{' '}
        </Badge>
      );
    case STATUS.PARTIALLY_DEDUCTED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.partiallyDeducted())}{' '}
        </Badge>
      );
    case STATUS.DEDUCTED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.deducted())}{' '}
        </Badge>
      );
    case STATUS.PARTIALLY_DEFAULTED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.partiallyDefaulted())}{' '}
        </Badge>
      );
    case STATUS.DEFAULTED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.defaulted())}{' '}
        </Badge>
      );
    case STATUS.ONGOING:
      return (
        <Badge className="py-1 px-2" color={color ?? 'secondary'}>
          {text ? t(text) : t(...messages.ongoing())}{' '}
        </Badge>
      );
    case STATUS.CHARGED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.charged())}{' '}
        </Badge>
      );
    case STATUS.AWAITING_TRASNPORT:
      return (
        <Badge className="py-1 px-2" color={color ?? 'info'}>
          {text ? t(text) : t(...messages.awaitingTransport())}{' '}
        </Badge>
      );
    case STATUS.AVAILABLE:
      return (
        <Badge className="py-1 px-2" color={color ?? 'info'}>
          {text ? t(text) : t(...messages.available())}{' '}
        </Badge>
      );
    case STATUS.USED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.used())}{' '}
        </Badge>
      );
    case STATUS.BLOCKED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.blocked())}{' '}
        </Badge>
      );
    case STATUS.NEW:
      return (
        <Badge className="py-1 px-2" color={color ?? 'primary'}>
          {text ? t(text) : t(...messages.new())}{' '}
        </Badge>
      );
    case STATUS.IN_STOCK:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.inStock())}{' '}
        </Badge>
      );
    case STATUS.ARCHIVED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.archived())}{' '}
        </Badge>
      );
    case STATUS.WITHOUT_FOLLOW_UP:
      return (
        <Badge className="py-1 px-2" color={color ?? 'danger'}>
          {text ? t(text) : t(...messages.without_follow_up())}{' '}
        </Badge>
      );
    case STATUS.POTENTIAL:
      return (
        <Badge className="py-1 px-2" color={color ?? 'warning'}>
          {text ? t(text) : t(...messages.potential())}{' '}
        </Badge>
      );
    case STATUS.FINALIZED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.finalized())}{' '}
        </Badge>
      );
    case STATUS.WAITING_FOR_DELIVERY:
      return (
        <Badge className="py-1 px-2" color={color ?? 'light'}>
          {text ? t(text) : t(...messages.waiting_for_delivery())}{' '}
        </Badge>
      );
    case STATUS.DELIVERED:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.delivered())}{' '}
        </Badge>
      );
    case STATUS.REFUND:
      return (
        <Badge className="py-1 px-2" color={color ?? 'success'}>
          {text ? t(text) : t(...messages.refund())}{' '}
        </Badge>
      );
    default:
      return null;
  }
};

export default StatusBadge;
