import {extraReducers} from './extra-reducers';
import {initialState} from './initial-state';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ROLE} from 'constants/roles';
import {StoredAuthState} from 'services/auth-service';
import {ValueOf} from 'types/value-of';

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout(state) {
      state.user = null;
      state.accessToken = null;
      state.google = initialState.google;
      state.facebook = initialState.facebook;
      state.socialAuth = initialState.socialAuth;
    },
    hydrate(state, action: PayloadAction<StoredAuthState>) {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    resetSocialAuth(state) {
      state.google = initialState.google;
      state.facebook = initialState.facebook;
      state.socialAuth = initialState.socialAuth;
    },
    resetPasswordForgot(state) {
      state.resetPassword = initialState.resetPassword;
    },
    changeSignupRole(state, action: PayloadAction<ValueOf<typeof ROLE>>) {
      state.signupRole = action.payload;
    },
  },
  extraReducers,
});

export const authActions = authSlice.actions;

export default authSlice;
