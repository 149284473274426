import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoiceMasterDetail.title, 'Invoice'),
  invoiceListPageTitle: () => __(translations.invoiceMasterDetail.invoiceListPageTitle, 'Invoices'),
  creditTitle: () => __(translations.invoiceMasterDetail.creditTitle, 'Credit'),
  creditListPageTitle: () => __(translations.invoiceMasterDetail.creditListPageTitle, 'Credits'),

  invoiceHelmetTitle: () => __(translations.invoiceMasterDetail.invoiceHelmetTitle, 'Invoice {{ref}}'),
  creditHelmetTitle: () => __(translations.invoiceMasterDetail.creditHelmetTitle, 'Credit {{ref}}'),
};
