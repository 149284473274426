import React from 'react';
import messages from './messages';
import useMailingForm from './use-mailing-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {CardTitle, Col, Row} from 'reactstrap';

const MailingTab = () => {
  const {t} = useTranslation();

  const {
    isLoading,
    errorMessage,
    isSuccess,
    submit,
    validationErrors,
    params,
    setParams,
  } = useMailingForm();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="mb-0">
          <h5 className="font-size-15">{t(...messages.title())}</h5>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div>
          <div className="mt-2 pt-50">
            {/* Small objects */}
            <Row>
              <Col>
                <h6> {t(...messages.smallObject())} </h6>
                <hr className="m-0 mb-2" />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine1())}
                  value={params.small_objects_address_l1}
                  onChange={small_objects_address_l1 =>
                    setParams({...params, small_objects_address_l1})
                  }
                  errors={validationErrors?.small_objects_address_l1}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine2())}
                  value={params.small_objects_address_l2}
                  onChange={small_objects_address_l2 =>
                    setParams({...params, small_objects_address_l2})
                  }
                  errors={validationErrors?.small_objects_address_l2}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label="E-mail"
                  value={params.small_objects_email}
                  onChange={small_objects_email =>
                    setParams({...params, small_objects_email})
                  }
                  errors={validationErrors?.small_objects_email}
                />
              </Col>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.phone())}
                  value={params.small_objects_phone}
                  onChange={small_objects_phone =>
                    setParams({...params, small_objects_phone})
                  }
                  errors={validationErrors?.small_objects_phone}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <h6>{t(...messages.studio())} </h6>
                <hr className="m-0 mb-2" />
              </Col>
            </Row>

            <Row className="mb-2">
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine1())}
                  value={params.studio_address_l1}
                  onChange={studio_address_l1 =>
                    setParams({...params, studio_address_l1})
                  }
                  errors={validationErrors?.studio_address_l1}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine2())}
                  value={params.studio_address_l2}
                  onChange={studio_address_l2 =>
                    setParams({...params, studio_address_l2})
                  }
                  errors={validationErrors?.studio_address_l2}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.email())}
                  value={params.studio_email}
                  onChange={studio_email =>
                    setParams({...params, studio_email})
                  }
                  errors={validationErrors?.studio_email}
                />
              </Col>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.phone())}
                  value={params.studio_phone}
                  onChange={studio_phone =>
                    setParams({...params, studio_phone})
                  }
                  errors={validationErrors?.studio_phone}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>{t(...messages.showroom())} </h6>
                <hr className="m-0 mb-2" />
              </Col>
            </Row>

            <Row>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine1())}
                  value={params.showroom_address_l1}
                  onChange={showroom_address_l1 =>
                    setParams({...params, showroom_address_l1})
                  }
                  errors={validationErrors?.showroom_address_l1}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.addressLine2())}
                  value={params.showroom_address_l2}
                  onChange={showroom_address_l2 =>
                    setParams({...params, showroom_address_l2})
                  }
                  errors={validationErrors?.showroom_address_l2}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.email())}
                  value={params.showroom_email}
                  onChange={showroom_email =>
                    setParams({...params, showroom_email})
                  }
                  errors={validationErrors?.showroom_email}
                />
              </Col>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.phone())}
                  value={params.showroom_phone}
                  onChange={showroom_phone =>
                    setParams({...params, showroom_phone})
                  }
                  errors={validationErrors?.showroom_phone}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" className="mb-1">
                <TextInput
                  type="textarea"
                  label={t(...messages.labelsMailContent())}
                  value={params.quotation_mail_content}
                  onChange={quotation_mail_content =>
                    setParams({...params, quotation_mail_content})
                  }
                  errors={validationErrors?.quotation_mail_content}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mb-1">
                <TextInput
                  type="textarea"
                  label={t(...messages.orderMailContent())}
                  value={params.order_mail_content}
                  onChange={order_mail_content =>
                    setParams({...params, order_mail_content})
                  }
                  errors={validationErrors?.order_mail_content}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="mb-1">
                <TextInput
                  type="textarea"
                  label={t(...messages.orderLabelsMail())}
                  value={params.order_mail_for_labels}
                  onChange={order_mail_for_labels =>
                    setParams({...params, order_mail_for_labels})
                  }
                  errors={validationErrors?.order_mail_for_labels}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-2" sm="12">
                <AppBarActions>
                  <Button
                    className="me-1"
                    onClick={submit}
                    isLoading={isLoading}
                    color="important"
                  >
                    <Icon name="check" className="me-2" size={15} />
                    <Trans i18nKey="common.saveChanges" />
                  </Button>
                </AppBarActions>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MailingTab;
