import React, {FC, useMemo} from 'react';
import messages from '../../../messages';
import {STATUSES} from './constant';
import {InvoiceFilters} from './types';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Row} from 'reactstrap';
import {Option} from 'types/option';
import DateRangeInput from 'components/date-range-input';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<InvoiceFilters>;
  onClearFilters?: () => void;
  onChangeFilters: (key: InvoiceFilters, value: any) => void;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {filters, onChangeFilters, onClearFilters} = props;

  const statuses = useMemo(() => {
    return STATUSES.map(x => {
      return {
        ...x,
        name: t(...x.name()),
      };
    });
  }, []);
  return (
    <SearchSection
      onChangeFilters={onChangeFilters}
      onClearFilters={onClearFilters}
      filters={filters}
    >
      <Row className="mb-3">
        <Col lg="6" md="6">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.date())}
              value={filters['filter[credit_date]'] as string}
              onChange={o => onChangeFilters('filter[credit_date]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/commercial-types?invoice"
              className="mb-1"
              label={t('invoicesPage.type')}
              value={filters['filter[commercial_type_id]']}
              onChange={o => onChangeFilters('filter[commercial_type_id]', o)}
            />
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
