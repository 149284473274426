import React, {FC} from 'react';
import messages from '../messages';
import ProfilWithText from './skeleton';
import formatters from 'locales/messages';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
} from 'reactstrap';
import {User} from 'types/user';

interface Props {
  user?: User;
}

const ProfileSection: FC<Props> = props => {
  const {t} = useTranslation();

  const {user} = props;

  return (
    <div className="">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="font-size-16">{t(...messages.title())}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {user ? (
            <div>
              <div className="mt-2 pt-50">
                <Row>
                  <Col sm="6" className="mb-1">
                    <Label className="form-label me-2 fw-bold" for="firstName">
                      {t(...messages.firstName())}
                      {' : '}
                    </Label>
                    {user.profile?.first_name}
                  </Col>
                  <Col sm="6" className="mb-1">
                    <Label className="form-label me-2 fw-bold" for="lastName">
                      {t(...messages.lastName())}
                      {' : '}
                    </Label>
                    {user.profile?.last_name}
                  </Col>

                  <Col sm="6" className="mb-1">
                    <Label className="form-label me-2 fw-bold" for="email">
                      {t(...messages.email())}
                      {' : '}
                    </Label>
                    {user.email}
                  </Col>

                  <Col sm="6" className="mb-1">
                    <Label
                      className="form-label me-2 fw-bold"
                      for="phoneNumber"
                    >
                      {t(...messages.phoneNumber())}
                      {' : '}
                    </Label>
                    {user.profile?.phone_number}
                  </Col>

                  <Col sm="6" className="mb-1">
                    <Label className="form-label me-2 fw-bold" for="address">
                      {t(...messages.address())} {' : '}
                    </Label>
                    {user.profile?.address}
                  </Col>

                  <Col sm="6" className="mb-1">
                    <Label
                      className="form-label me-2 fw-bold"
                      for="dateOfBirth"
                    >
                      {t(...messages.dateOfBirth())}
                      {' : '}
                    </Label>
                    {user.profile?.born_at
                      ? t(...formatters.intlDateTime(), {
                          val: new Date(user.profile?.born_at),
                          formatParams: {
                            val: {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            },
                          },
                        })
                      : null}
                  </Col>

                  <Col className="mt-2" sm="12"></Col>
                </Row>
              </div>
            </div>
          ) : (
            <ProfilWithText></ProfilWithText>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProfileSection;
