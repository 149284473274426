import {loginWithGoogle as loginWithGoogleAction} from '../actions/login-with-google';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {isAnyOf} from '@reduxjs/toolkit';
import {AUTH_PROVIDER} from 'constants/auth-provider';
import {GoogleUser} from 'services/google-signin/types';
import {WritableDraft} from 'types/writable-draft';

/**
 * Match successful google login actions
 *
 */
const loginWithGoogleMatcher = isAnyOf(
  loginWithGoogleAction.fulfilled,
  isGoogleSuccessfulAuth
);

/**
 * Google login reducer
 *
 */
const loginWithGoogleReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<GoogleUser>
) => {
  // state.socialAuth.id = payload.user.id;
  // state.socialAuth.provider_token = payload.idToken;
  // state.socialAuth.first_name = payload.user.givenName ?? '';
  // state.socialAuth.last_name = payload.user.familyName ?? '';
  // state.socialAuth.email = payload.user.email ?? '';
  state.socialAuth.provider_name = AUTH_PROVIDER.GOOGLE;
};

export const loginWithGoogle = {
  matcher: loginWithGoogleMatcher,
  reducer: loginWithGoogleReducer,
};

function isGoogleSuccessfulAuth(
  action: PayloadAction<any>
): action is PayloadAction<GoogleUser> {
  return action.type === 'googleUserRetrieved';
}
