import InvoiceDuplicatActionConfirm from './action-modal/duplicate';
import InvoiceGenerateAccountingCreditActionConfirm from './action-modal/generate-accounting-credit';
import InvoiceGenerateCreditActionConfirm from './action-modal/generate-credit';
import InvoiceSendEmailActionConfirm from './action-modal/send-email';
import {MODAL} from 'features/modal-notch/constants';

export default {
  [MODAL.INVOICE_DUPLICAT]: InvoiceDuplicatActionConfirm,
  [MODAL.INVOICE_SEND_EMAIL]: InvoiceSendEmailActionConfirm,
  [MODAL.INVOICE_GENERATE_CREDIT]: InvoiceGenerateCreditActionConfirm,
  [MODAL.INVOICE_GENERATE_ACCOUNTING_CREDIT]:
    InvoiceGenerateAccountingCreditActionConfirm,
};
