import React, {FC, useState} from 'react';
import {PricingFilters} from '../pricing-grid/types';
import Icon from '@ailibs/feather-react-ts';
import {useLazyGetCatalogExportsQuery} from 'features/commercial/quotations/store/endpoints/get-catalog-export';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import toast from 'store/ui/actions/toast';

interface Props {
  filters: FilterMap<PricingFilters>;

  item: {
    title: () => [string, ...any[]];
    subMenu: {
      title: () => [string, ...any[]];
      query: {
        locale: string;
        for_client: string;
        for_reseller: string;
      };
    }[];
  };
}

const ExportItems: FC<Props> = props => {
  const {item, filters} = props;
  const [submitCatalog, {isLoading}] = useLazyGetCatalogExportsQuery();
  const dispatch = useDispatch();

  const handleExport = async (filters: any) => {
    const result = await submitCatalog(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const {t} = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div style={{width: '100%', padding: '5px 0 0 5px'}}>
      {/* Action */}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        //@ts-ignore
        direction="right"
      >
        <DropdownToggle
          color="none"
          className="p-0 text-start"
          style={{width: '100%'}}
        >
          <div className="d-flex justify-content-between align-items-center w-100  px-1">
            <span>{t(...item.title())}</span>
            <Icon name="chevron-right" size={15} className="ms-1" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {item.subMenu.map((subItem, index) => {
            return (
              <DropdownItem
                key={index}
                onClick={() => {
                  handleExport({...subItem.query, ...filters});
                }}
              >
                {t(...subItem.title())}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
        <DropdownItem divider />
      </Dropdown>
    </div>
  );
};

export default ExportItems;
