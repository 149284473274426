import React, {FC} from 'react';

import Icon from '@ailibs/feather-react-ts';
import styled from 'styled-components';

interface ExpandRowProps {
  onClick: any;
  isExpanded: boolean;
}

const ExpandRow: FC<ExpandRowProps> = props => {
  const {onClick, isExpanded} = props;

  return (
    <StyledDiv onClick={onClick}>
      {isExpanded ? (
        <Icon name="chevron-down" size={15} className="ms-1" />
      ) : (
        <Icon name="chevron-right" size={15} className="ms-1" />
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  cursor: pointer;
`;

export default ExpandRow;
