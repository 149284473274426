import React from 'react';
import {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

interface NoOptionMessageProps {
  newClient: string;
}

const NoOptionMessage: FC<NoOptionMessageProps> = () => {
  const {t} = useTranslation();
  return (
    <div className="d-flex flex-column">
      <Link className="my-2" target="_blank" to={`/dashboard/users/create`}>
        <Icon name="plus" size={15} />
        <span>{t(...messages.addUser())}</span>
      </Link>
    </div>
  );
};

export default NoOptionMessage;
