import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import DateInput from 'components/date-input';
import {Alert, Card, CardBody, Col, Label, Row} from 'reactstrap';

import messages from '../messages';
import {useProjectForm} from './use-project-form';
import ItemPicker from 'components/item-picker';
import {Option} from 'types/option';
import DirectoryPicker from 'features/studio/projects/components/contact-picker';
import {TextEditor} from 'components/text-editor';

const CreateProductForm: FC<{}> = () => {
  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    errorMessage,
    options,
    setParams,
    handleDating,
    handleChange,
  } = useProjectForm();

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
            <TextInput
              label={t(...messages.name())}
              className="mb-1"
              value={params.name}
              errors={validationErrors.name}
              onChange={name => {
                handleChange('name', name);
              }}
            />
            <TextInput
              label={t(...messages.projectCodeName())}
              rows={1}
              className="mb-1"
              value={params.internal_name}
              errors={validationErrors.internal_name}
              onChange={internal_name => {
                handleChange('internal_name', internal_name);
              }}
            />
          </Col>
          <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
            <DirectoryPicker
              label={t(...messages.customer())}
              value={params.directory}
              className="mb-1"
              errors={validationErrors.directiory}
              onChange={directory => {
                handleChange('directory', directory);
              }}
            />
            <ItemPicker
              label={t(...messages.projectType())}
              value={params.project_type}
              className="z-3"
              errors={validationErrors.project_type}
              options={options.types}
              getOptionLabel={(val: any) => val.name}
              onChange={project_type => {
                handleChange('project_type', project_type);
              }}
            />
          </Col>
        </Row>

        <TextInput
          label={t(...messages.clientNotice())}
          className="mb-3"
          type="textarea"
          rows={3}
          data-testid="product-name-input"
          value={params.client_notice}
          errors={validationErrors.client_notice}
          onChange={client_notice =>
            handleChange('client_notice', client_notice)
          }
        />

        <Row className="mb-3">
          <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
            <ItemPicker
              label={t(...messages.projectStatus())}
              value={params.status}
              options={options.status}
              className="mb-1"
              getOptionLabel={(val: any) => val.name}
              onChange={status => {
                handleChange('status', status);
              }}
            />
          </Col>
          <Col md={6} lg={6} xs={12} className="d-flex flex-column gap-3">
            <Row>
              <Col lg={5}>
                <div>
                  <DateInput
                    label={t(...messages.startDate())}
                    errors={validationErrors.starts_at}
                    value={params.starts_at?.toString()}
                    onChange={starts_at => {
                      handleDating('starts_at', starts_at as string);
                    }}
                  />
                </div>
              </Col>
              <Col>
                <TextInput
                  label="Durée"
                  type="number"
                  value={params.duration_in_months}
                  disabled={!params.starts_at}
                  onChange={duration_in_months =>
                    handleDating('duration_in_months', duration_in_months)
                  }
                />
              </Col>
              <Col lg={5}>
                <div>
                  <DateInput
                    label={t(...messages.endDate())}
                    disabled={!params.starts_at}
                    errors={validationErrors.ends_at}
                    value={params.ends_at}
                    onChange={ends_at => {
                      handleDating('ends_at', ends_at as string);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <TextInput
          label={t(...messages.designation())}
          type="textarea"
          rows={3}
          className="mb-3"
          value={params.designation}
          errors={validationErrors.designation}
          onChange={designation => {
            handleChange('designation', designation);
          }}
        />

        <TextEditor
          label={t(...messages.description())}
          errors={validationErrors.description}
          value={params.description}
          onChange={description => {
            handleChange('description', description);
          }}
        />
        <Row>
          <Col md={8}>
            <div className="my-3">
              {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <AppBarActions>
              <Button isLoading={isLoading} onClick={submit} color="important">
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export default CreateProductForm;
