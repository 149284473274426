import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.addressModal.title, 'Address'),
  addressType: () => __(translations.addressModal.addressType, 'Address type'),
  city: () => __(translations.addressModal.city, 'City'),
  country: () => __(translations.addressModal.country, 'Country'),
  firstLine: () => __(translations.addressModal.firstLine, 'Line 1'),
  secondLine: () => __(translations.addressModal.secondLine, 'Line 2'),
  thirdLine: () => __(translations.addressModal.thirdLine, 'Line 3'),
  vatMode: () => __(translations.addressModal.vatMode, 'Vat mode'),
  zipCode: () => __(translations.addressModal.zipCode, 'Zip code'),
  name: () => __(translations.addressModal.name, 'Name'),
  comment: () => __(translations.addressModal.comment, 'Comment'),
  defaultAddress: () => __(translations.addressModal.defaultAddress, 'Default address'),
};
