import React, {FC, Fragment} from 'react';
import messages from '../messages';
import {useColorForm} from './use-color-form';
import {useUpdateColorForm} from './use-color-update-form';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {ChromePicker} from 'react-color';
import {useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Color} from 'types/colors';

interface Props {
  message?: string;
  onDismiss: () => void;
  store: boolean;
  color?: Color;
}

const ColorModal: FC<Props> = props => {
  const {onDismiss, store, color} = props;
  const {params, validationErrors, isLoading, errorMessage, submit, setParams} =
    useColorForm(onDismiss);
  const {
    updateParams,
    updateValidationErrors,
    updateIsLoading,
    updateErrorMessage,
    updateSubmit,
    setUpdateParams,
  } = useUpdateColorForm(onDismiss, color);
  const {t} = useTranslation();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.title())}</span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Row>
            <p>{t(...messages.colorCode())}</p>
            <div className="d-flex justify-content-center p-3">
              <ChromePicker
                className="w-100 shadow-sm p-3"
                color={store ? params.code : updateParams.code}
                onChange={(c: any) =>
                  store
                    ? setParams({...params, code: c.hex})
                    : setUpdateParams({...updateParams, code: c.hex})
                }
              />
            </div>
            <p className="text-small text-danger">{validationErrors.code}</p>
            {color && (
              <div>
                <div className="mb-3">
                  <TextInput
                    label={t(...messages.nameFr())}
                    value={updateParams.name?.fr}
                    errors={validationErrors['name.fr']}
                    onChange={fr => {
                      setUpdateParams({
                        ...updateParams,
                        name: {
                          ...updateParams.name,
                          fr: fr,
                        },
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <TextInput
                    label={t(...messages.nameEn())}
                    value={updateParams.name?.en}
                    errors={validationErrors['name.en']}
                    onChange={en => {
                      setUpdateParams({
                        ...updateParams,
                        name: {
                          ...updateParams.name,
                          en: en,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {!color && (
              <div>
                <div className="mb-3">
                  <TextInput
                    label={t(...messages.nameFr())}
                    value={params.name.fr}
                    errors={validationErrors['name.fr']}
                    onChange={fr => {
                      setParams({
                        ...params,
                        name: {
                          ...params.name,
                          fr: fr,
                        },
                      });
                    }}
                  />
                </div>
                <div className="mb-3">
                  <TextInput
                    label={t(...messages.nameEn())}
                    value={params.name.en}
                    errors={validationErrors['name.en']}
                    onChange={en => {
                      setParams({
                        ...params,
                        name: {
                          ...params.name,
                          en: en,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="my-3">
              {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {!!updateErrorMessage && (
                <Alert color="danger">{updateErrorMessage}</Alert>
              )}
            </div>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="important"
          onClick={store ? submit : updateSubmit}
          isLoading={store ? isLoading : updateIsLoading}
        >
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default ColorModal;
