import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.updateStockProductsModal.title, 'Update stock location'),
  sublocation: () => __(translations.updateStockProductsModal.sublocation, 'Sublocation'),
  comment: () => __(translations.updateStockProductsModal.comment, 'Comment'),
  reason: () => __(translations.updateStockProductsModal.reason, 'Reason'),
  valid: () => __(translations.updateStockProductsModal.valid, 'Valide'),
  invalid: () => __(translations.updateStockProductsModal.invalid, 'Invalid'),
  toValidate: () => __(translations.updateStockProductsModal.toValidate, 'To validate'),
  verficationStatus: () => __(translations.updateStockProductsModal.verficationStatus, 'Verification status'),
};
