import React from 'react';
import DirectoryActions from './directory-actions';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {ColumnDef} from '@tanstack/table-core';
import EmptyaccessorFn from 'components/empty-cell';
import GenerateStars from 'components/generate-stars/generate-stars';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Directory} from 'types/directory';

const contactsColumns: ColumnDef<Directory>[] = [
  {
    header: r => <Trans i18nKey="directoryPage.id" />,
    accessorFn: r => r?.id,
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'id',
    meta: {size: '50px', costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="directoryPage.name" />,
    accessorFn: row =>
      row.is_archived ? (
        <EmptyaccessorFn cell={row.display_name.trim()} />
      ) : (
        <Link target="_blank" to={`/dashboard/directories/${row.id}/general`}>
          <Icon name="eye" size={15} className="me-1 " />

          <GenerateStars starsNumber={row.stars_number} />

          <b> {row.display_name.toLocaleUpperCase()}</b>
        </Link>
      ),
    id: 'full-name',
    meta: {size: '200px', costumClass: 'font-size-14'},
    cell: r => r.getValue(),
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.occupation" />,
    accessorFn: row => <EmptyaccessorFn cell={row.job} />,
    id: 'occupation',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14', size: '180px'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.company" />,
    accessorFn: row => (
      <EmptyaccessorFn cell={row.company?.display_name.toLocaleUpperCase()} />
    ),
    id: 'company-name',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14', size: '250px'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.category" />,
    accessorFn: row => (
      <EmptyaccessorFn
        cell={row.directory_categories?.map(x => x.name).join(',')}
      />
    ),
    id: 'category-names',
    meta: {costumClass: 'font-size-14', size: '200px'},
    cell: r => r.getValue(),
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.family" />,
    accessorFn: row => (
      <EmptyaccessorFn
        cell={row.directory_families?.map(x => x.name).join(',')}
      />
    ),
    id: 'families',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14', size: '150px'},
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="directoryPage.email" />,
    accessorFn: row => (
      <EmptyaccessorFn
        cell={
          row?.main_directory_email?.email ? (
            <a href={`mailto:${row.main_directory_email?.email}`}>
              <b> {row.main_directory_email?.email}</b>
            </a>
          ) : (
            '-'
          )
        }
      />
    ),
    meta: {size: '200px', costumClass: 'font-size-14'},
    cell: r => r.getValue(),
    id: 'email',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.phone" />,
    accessorFn: row => <EmptyaccessorFn cell={row.main_phone?.number} />,
    id: 'phone',
    cell: r => r.getValue(),
    meta: {size: '200px', costumClass: 'font-size-14'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.action" />,
    enableSorting: false,
    accessorFn: row => <DirectoryActions directory={row} />,
    id: 'actions',
    meta: {size: '100px', costumClass: 'font-size-14'},
    cell: r => r.getValue(),
  },
];

export default contactsColumns;
