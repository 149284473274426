import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  estimate: () => __(translations.variantLinkedRecource.estimate, 'Estimate'),
  order: () => __(translations.variantLinkedRecource.order, 'Orders'),
  supplierOrder: () => __(translations.variantLinkedRecource.supplierOrder, 'Supplier orders'),
  invoice: () => __(translations.variantLinkedRecource.invoice, 'Invoice'),
  seeAll: () => __(translations.variantLinkedRecource.seeAll, 'See all'),
  year: () => __(translations.variantLinkedRecource.year, 'Year'),
  code: () => __(translations.variantLinkedRecource.code, 'Code'),
  price: () => __(translations.variantLinkedRecource.price, 'Price'),
  quantity: () => __(translations.variantLinkedRecource.quantity, 'Quantity'),
  status: () => __(translations.variantLinkedRecource.status, 'Status'),
  sales: () => __(translations.variantLinkedRecource.sales, 'Nb of sales'),
  turnover: () => __(translations.variantLinkedRecource.turnover, 'Turnover'),
  supplierTurnover: () => __(translations.variantLinkedRecource.supplierTurnover, 'Supplier turnover'),
  supplierProductCount: () => __(translations.variantLinkedRecource.supplierProductCount, 'Nb of products'),
  total: () => __(translations.variantLinkedRecource.total, 'Total :'),
};
