import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

const updateGeneralSettings = api.injectEndpoints({
  endpoints: builder => ({
    updateGeneralSettings: builder.mutation<
      Response,
      UpdateGeneralSettingsRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `settings/general`,
          body: {
            ...params,
          },
        };
      },
    }),
  }),
});

export type UpdateGeneralSettingsRequest = Settings['general'];

type Response = ResponseData<Settings['general']>;

export const {useUpdateGeneralSettingsMutation} = updateGeneralSettings;

export const updateGeneralSettingsEndpoint =
  updateGeneralSettings.endpoints.updateGeneralSettings;
