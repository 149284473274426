import {extraReducers} from './extra-reducers';
import initialState from './initial-state';
import {Toast} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MODAL} from 'features/modal-notch/constants';
import {RestoreResourceAction} from 'features/modal-notch/reincarnation-angel/types';
import {DeleteResourceAction} from 'features/modal-notch/soul-eater/types';

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<any>) => {
      state.modal.modalType = action.payload.modalType;
      state.modal.params = action.payload.params;
    },
    dismissModal: state => {
      state.modal = initialState.modal;
    },
    dismissModalWithResult: (state, action: PayloadAction<unknown>) => {
      state.modal = initialState.modal;
    },
    dismissTutorial: state => {
      state.showTutorial = false;
    },
    confirmDelete(state, action: PayloadAction<DeleteResourceAction>) {
      state.modal.modalType = MODAL.SOUL_EATER;
      state.modal.params = action.payload;
    },
    confirmRestore(state, action: PayloadAction<RestoreResourceAction>) {
      state.modal.modalType = MODAL.REINCARNATION_ANGEL;
      state.modal.params = action.payload;
    },
    toast(state, action: PayloadAction<Toast>) {
      state.toast = action.payload;
    },
    clearToast(state) {
      state.toast = initialState.toast;
    },
  },
  extraReducers,
});

export const uiActions = uiSlice.actions;

export default uiSlice;
