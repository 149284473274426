import React, {FC} from 'react';
import messages from '../messages';
import {QuotationPaginationMeta} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Col, Row} from 'reactstrap';
import TagsItem from 'components/tags-card';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import Currency from 'components/currency/currency';

interface TagsProps {}

const TagsRow: FC<TagsProps> = props => {
  const {t} = useTranslation();

  const meta = useSelector(
    selectEntityMeta<QuotationPaginationMeta>('quotations')
  );

  return (
    <Row className="align-items-center justify-content-center mb-3">
      <Col sm={3}>
        <TagsItem title={t(...messages.totalQuotations())}>
       <NaturalNumber value={meta?.total_quotations } />
        </TagsItem>
      </Col>

   

      <Col sm={3}>
      <TagsItem title={t(...messages.totalValidQuotations())}>
         <NaturalNumber value={meta?.total_valid_quotations } />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem title={t(...messages.total_ht())} >
     
           <Currency value= {meta?.total_ht } />
         </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem title={t(...messages.total_ttc())}>
         
           <Currency value={meta?.total_ttc } />
            </TagsItem>
          
      </Col>
    </Row>
  );
};

export default TagsRow;
