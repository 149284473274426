import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const showSituationPrices = api.injectEndpoints({
  endpoints: builder => ({
    showSituationPrices: builder.query<SituationPricesResponse, Request>({
      query: params => ({
        method: 'GET',
        url: `data/product-variants/${params.variantId}/situations/${params.id}/discount-price`,
        params: {
          document_id: params.document_id,
          document_type: params.doc_type,
          document_category_id: params.document_category_id,
          situation_id: params.id,
        },
      }),
    }),
  }),
});

type Request = {
  variantId?: ObjectId;
  id?: ObjectId;
  document_id?: ObjectId;
  doc_type?: string;
  document_category_id?: ObjectId;
};

export type SituationPricesResponse = ResponseData<SituationPrices>;

export type SituationPrices = {
  discount: string;
  selling_price_ht: string;
};

export default showSituationPrices;

export const {useLazyShowSituationPricesQuery, useShowSituationPricesQuery} =
  showSituationPrices;
