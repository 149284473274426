import React, {FC} from 'react';
import ContentLoader, {IContentLoaderProps} from 'react-content-loader';
import {useSelector} from 'react-redux';
import {selectAppTheme} from 'store/layout/selectors';

interface Props extends IContentLoaderProps {}

const LoadRunner: FC<Props> = props => {
  const {children, ...otherProps} = props;

  const theme = useSelector(selectAppTheme);

  return (
    <ContentLoader
      backgroundColor={theme === 'dark' ? '#3B403D' : '#f6f6ef'}
      foregroundColor={theme === 'dark' ? '#3B403D' : '#e8e8e3'}
      style={{backgroundColor: 'var(--bs-card-bg)'}}
      {...otherProps}
    >
      {children}
    </ContentLoader>
  );
};

export default LoadRunner;
