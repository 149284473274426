import {Invoice} from '../../types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const generateCreditFromInvoice = api.injectEndpoints({
  endpoints: builder => ({
    generateCreditFromInvoice: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `/invoices/${params.id}/generate-credit`,
          body: {
            document_type: params.document_type,
            commercial_product_ids: params.commercial_product_ids,
          },
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  document_type?: 'CREDIT' | 'CREDIT_ACCOUNTING';
  commercial_product_ids?: ObjectId[];
};

type Response = ResponseData<Invoice>;

export const {useGenerateCreditFromInvoiceMutation} = generateCreditFromInvoice;
