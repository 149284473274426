import React from 'react';
import FormSection from './form-section';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
// import images
import {useTranslation} from 'react-i18next';
//redux
import {useDispatch} from 'react-redux';
import {Col, Container, Card} from 'reactstrap';

const CreateUser = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t(...messages.dashboard()), path: '/dashboard'},
            {title: t(...messages.users()), path: '/dashboard/users'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Card className="p-3">
          <Col md="auto">
            <FormSection />
          </Col>
        </Card>
      </Container>
    </div>
  );
};

export default CreateUser;
