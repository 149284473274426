import React, {ReactNode} from 'react';
import {FC, useMemo} from 'react';
import {FormFeedback, Input, InputProps, Label} from 'reactstrap';
import useInputId from 'utils/use-input-id';

const TextInput: FC<Props> = props => {
  const {
    value,
    errors,
    onChange,
    label,
    required,
    type,
    inputStyle,
    wrapperStyle,
    placeholder,
    labelStyle,
    disabled,
    id: inputId,
    containerStyle,

    errorMessageStyle,
    ...rest
  } = props;

  const errorMessage = useMemo(() => {
    return Array.isArray(errors) ? errors[0] : errors;
  }, [errors]);

  const id = useInputId(inputId);

  return (
    <div className={containerStyle}>
      <span className="text-danger">{required && '* '}</span>
      {label && (
        <Label className="mb-0" htmlFor={id}>
          {label}
        </Label>
      )}
      <Input
        id={id}
        className={`form-control  ${disabled ? 'bg-gray-light' : ''}`}
        style={inputStyle}
        disabled={disabled}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value ?? ''}
        type={type}
        invalid={errorMessage ? true : false}
        {...rest}
      />
      {errorMessage ? (
        <FormFeedback type="invalid">{errorMessage}</FormFeedback>
      ) : null}
    </div>
  );
};

interface Props extends Omit<InputProps, 'onChange'> {
  value?: string | number;
  placeholder?: string;
  id?: string;
  onChange: (text: string) => void;
  errors?: string | string[];
  type?: 'email' | 'text' | 'password' | 'number' | 'textarea';
  inputStyle?: any;
  wrapperStyle?: any;
  errorMessageStyle?: any;
  labelStyle?: any;
  label?: string | ReactNode;
  required?: boolean;
  secureTextEntry?: boolean;
}

export default TextInput;
