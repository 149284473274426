// Importing necessary modules and types
import {selectProject} from 'features/studio/projects/store/show-project';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {ITeam, Project} from 'types/project';
import {
  HandleAddInput,
  HandleFilter,
  HandleInputChange,
  HandleRemoveInput,
  HandleShowModal,
  HandleSwitchStatus,
  IRegroupedTeam,
  Input,
} from './types';
import {ObjectId} from 'types/object-id';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import {
  StoreProjectTeamRequest,
  useStoreProjectTeamMutation,
} from 'features/studio/projects/store/store-project-team';
import {useGroupByType} from 'features/studio/projects/utils/use-regroup-by-type';
import {useEnumAsOption} from 'features/studio/projects/utils/use-enum-as-option';
import {TeamStatusFilterEnum} from 'features/studio/projects/constants/project-team-status';
import {EnumMapping} from 'features/studio/projects/utils/use-enum-as-option';
import messages from './messages';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

// Custom hook for managing team form state and behavior
export const useTeamForm = (): ITeamForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  // State to manage input data for the team form
  const [input, setInput] = useState<Input>({
    project_manager: {},
    assistant: {},
  } as Input);

  // State to manage parameters for the team form
  const [params, setParams] = useState<Params>({
    team: [],
    deleted_records: [],
  });

  // State to manage status filter
  const [status, setStatus] = useState<
    EnumMapping<typeof TeamStatusFilterEnum>
  >({
    name: t(...messages.all()),
    id: TeamStatusFilterEnum.ALL,
  });

  // Get the 'id' parameter from the URL using react-router
  const {id} = useParams();

  // Select project data from Redux store using the 'selectProject' selector
  const {data} = useSelector(selectProject({id}));

  const [project, setProject] = useState<Project>(data?.data as Project);

  const subject = useCurrentSubject(project.project_type);

  // Use the updateProject mutation hook to update project data
  const [storeProjectTeam, {isLoading, error, data: response}] =
    useStoreProjectTeamMutation();

  // adapt data to awaited payload shape
  const shapePayload = (params: Params): StoreProjectTeamRequest => {
    const team = params.team.map(({user, type, is_active}) => {
      return {
        user_id: user.id,
        is_active,
        type,
      };
    });

    return {
      id,
      team,
      deleted_records: params.deleted_records,
    };
  };

  const onUpdateProject = (data: Project) => {
    setProject(data);
  };
  // handle open modal
  const handleShowModal = () => {
    // do not spread project variable
    dispatch(
      showModal(MODAL.CONFIG_MAN_DAYS_MODAL, {project, onUpdateProject})
    );
  };

  // Handle form submission
  const handleSubmit = async () => {
    const result = await storeProjectTeam(shapePayload(params));

    // Update form parameters if data is received from the server
    if ('data' in result) {
      const project = result?.data.data;
      dispatch(toast('success', t('common.changesCommited')));

      setParams({team: project.assistants});
    }
  };

  // Effect to update the form parameters when project data changes
  useEffect(() => {
    // Check if project data is available
    if (!data?.data) return;

    setParams({team: data.data?.assistants});
  }, [data]);

  // Handle filter
  const handleFilter = (value: EnumMapping<typeof TeamStatusFilterEnum>) => {
    setStatus(value);
  };

  // Function to handle adding input data to the team form
  const handleAddInput = (type: keyof Input) => {
    // Check if the user property is available in the input type
    if (!input[type].user) return;

    // Remove object from deleted_records if it exists
    if (params.deleted_records?.indexOf(input[type].user.id) !== -1) {
      const index = params.deleted_records?.indexOf(input[type].user.id);
      const deleted_records = [...(params.deleted_records || [])];

      deleted_records.slice(index, 1);
      setParams({
        ...params,
        deleted_records,
      });
    }

    // Check if the input data already exists in the team parameters
    const isAlreadyExist = !!params.team.find(
      e => e.user.id === input[type].user.id
    );
    if (isAlreadyExist) {
      const updateEntry = [...params.team];

      const index = params.team.findIndex(
        el => el.user.id === input[type].user.id
      );
      updateEntry[index] = {...input[type], is_active: true};

      setParams({
        team: updateEntry,
      });
    } else {
      // Add input data to the team parameters
      setParams({team: [...params.team, {...input[type], is_active: true}]});
    }

    // Reset the input for the specified type
    return setInput({...input, [type]: {}} as Input);
  };

  // Switch member status by user_id
  const handleSwitchStatus = (user_id: ObjectId) => {
    const team = [...params.team];
    const index = params.team.findIndex(el => el.user.id === user_id);

    team[index] = {
      ...team[index],
      is_active: !team[index].is_active,
    };

    setParams({team});
  };

  // Function to handle removing input data from the team form
  const handleRemoveInput = (user_id: ObjectId) => {
    // Check if there is any data in the team parameters
    if (params.team.length === 0) return;

    // Copy the team and deleted_records arrays to modify them
    const team = [...params.team];
    const index = params.team.findIndex(el => el.user.id === user_id);

    const deleted_records = [
      ...(params.deleted_records || []),
      team[index].user.id,
    ];

    // Remove the specified entry from the team
    team.splice(index, 1);

    // Update the team and deleted_records parameters
    setParams({team, deleted_records});
  };

  // Function to handle input changes in the team form
  const handleInputChange: HandleInputChange = (key, value) => {
    setInput({
      ...input,
      [key]: {
        user: value,
        type: key.toLocaleUpperCase(),
      } as ITeam,
    });
  };

  // Memoized computation to regroup the team data by type
  const regroupedTeam = useMemo(() => {
    const data = [...params.team];
    switch (status.id) {
      case TeamStatusFilterEnum.ACTIVE:
        return useGroupByType(
          data.filter(el => el.is_active),
          'team'
        ) as IRegroupedTeam[];
      case TeamStatusFilterEnum.INACTIVE:
        return useGroupByType(
          data.filter(el => !el.is_active),
          'team'
        ) as IRegroupedTeam[];
      default:
        return useGroupByType(data, 'team') as IRegroupedTeam[];
    }
  }, [params.team, status]);

  // open by default none empty accordions
  const defaultOpenedAccordion = useMemo(() => {
    return regroupedTeam
      .map((el, index) => ({...el, identifier: index}))
      .filter(el => el.data.length > 0)
      .map(el => el.identifier.toString());
  }, [regroupedTeam]);

  // item picker options
  const options = {
    statuses: useEnumAsOption(TeamStatusFilterEnum, 'editProjectTeamSection'),
  };

  // Return the team form state and functions
  return {
    project,
    params,
    regroupedTeam,
    input,
    isLoading,
    defaultOpenedAccordion,
    status,
    options,
    subject,
    setParams,
    handleAddInput,
    handleInputChange,
    handleSwitchStatus,
    handleRemoveInput,
    handleFilter,
    handleShowModal,
    submit: handleSubmit,
  };
};

// Interface defining the structure of the team form state and functions
export interface ITeamForm {
  project?: Project;
  params: Params;
  regroupedTeam: IRegroupedTeam[];
  input: Input;
  isLoading: boolean;
  defaultOpenedAccordion: string[];
  status: EnumMapping<typeof TeamStatusFilterEnum>;
  options: {
    statuses: EnumMapping<typeof TeamStatusFilterEnum>[];
  };
  subject: Subject;
  setParams: (arg: Params) => void;
  handleInputChange: HandleInputChange;
  handleAddInput: HandleAddInput;
  handleSwitchStatus: HandleSwitchStatus;
  handleRemoveInput: HandleRemoveInput;
  handleFilter: HandleFilter;
  handleShowModal: HandleShowModal;
  submit: () => void;
}

// Type defining the structure of the team form parameters
type Params = {team: ITeam[]; deleted_records?: ObjectId[]};
