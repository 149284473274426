import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationsPage.title, 'Quotations'),
  addQuotation: () => __(translations.quotationsPage.addQuotation, 'Add quotation'),
  user: () => __(translations.quotationsPage.user, 'Contact'),
  date: () => __(translations.quotationsPage.date, 'Date'),
  status: () => __(translations.quotationsPage.status, 'Status'),
  total_ht: () => __(translations.quotationsPage.total_ht, 'Total HT'),
  total_ttc: () => __(translations.quotationsPage.total_ttc, 'Total TTC'),
  quatationName: () => __(translations.quotationsPage.name, 'Number'),
  type: () => __(translations.quotationsPage.type, 'Type'),
  client: () => __(translations.quotationsPage.client, 'Client'),
  category: () => __(translations.quotationsPage.category, 'Category'),
  quotationIssueDate: () => __(translations.quotationsPage.quotationIssueDate, 'Issue date'),
  deadline: () => __(translations.quotationsPage.deadline, 'Deadline'),
  internalDueDate: () => __(translations.quotationsPage.internalDueDate, 'Internal due date'),
  productionTime: () => __(translations.quotationsPage.productionTime, 'Production time'),
  origin: () => __(translations.quotationsPage.origin, 'Origin'),
  vintage: () => __(translations.quotationsPage.vintage, 'Vintage'),
  totalQuotations: () => __(translations.quotationsPage.totalQuotations, 'Total quotations'),
  totalValidQuotations: () => __(translations.quotationsPage.totalValidQuotations, 'Total valid quotations'),
  totalDraftQuotations: () => __(translations.quotationsPage.totalDraftQuotations, 'Total draft quotations'),
  totalPendingQuotations: () => __(translations.quotationsPage.totalPendingQuotations, 'Total pending quotations'),
  totalExpiredQuotations: () => __(translations.quotationsPage.totalExpiredQuotations, 'Total expired quotations'),
  transporter_name: () => __(translations.quotationsPage.transporter_name, 'Transporter name'),
  comment: () => __(translations.quotationsPage.comment, 'Comment'),
  ref_client: () => __(translations.quotationsPage.ref_client, 'Client ref'),
  projet: () => __(translations.quotationsPage.projet, 'Project'),
  document_category: () => __(translations.quotationsPage.document_category, 'Document category'),
  nullQuoteTooltip: () => __(translations.quotationsPage.nullQuoteTooltip, 'no records'),
  related_documents: () => __(translations.quotationsPage.related_documents, 'Related documents'),
  trashed: () => __(translations.quotationsPage.trashed, 'Archived quotations'),
  detaxed: () => __(translations.quotationsPage.detaxed, 'Sold before taxes'),
};
