import messages from '../messages';
import {PricingFilters} from '../pricing-grid/types';
import {__} from 'utils/messages';

export const OPTIONS = [
  {
    id: '1',
    label: messages.available,
  },
  {
    id: '0',
    label: messages.notAvailable,
  },
];
export const OPTIONSCatalog = [
  {
    id: '1',
    label: messages.inCatalog,
  },
  {
    id: '0',
    label: messages.notInCatalog,
  },
];
export const OPTIONSForSale = [
  {
    id: '1',
    label: messages.forSale,
  },
  {
    id: '0',
    label: messages.notForSale,
  },
];

export const EXPORTMenu = [
  {
    title: messages.catalog,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', for_client: '0', for_reseller: '0'},
      },
      {
        title: messages.en,
        query: {locale: 'en', for_client: '0', for_reseller: '0'},
      },
    ],
  },

  {
    title: messages.clientCatalog,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', for_client: '1', for_reseller: '0'},
      },
      {
        title: messages.en,
        query: {locale: 'en', for_client: '1', for_reseller: '0'},
      },
    ],
  },
  {
    title: messages.resielerCatalog,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', for_client: '0', for_reseller: '1'},
      },
      {
        title: messages.en,
        query: {locale: 'en', for_client: '0', for_reseller: '1'},
      },
    ],
  },
  {
    title: messages.clientResielerCatalog,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', for_client: '1', for_reseller: '1'},
      },
      {
        title: messages.en,
        query: {locale: 'en', for_client: '1', for_reseller: '1'},
      },
    ],
  },
];
export const PatrimoineEXPORTMenu = [
  {
    title: messages.imh,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', 'filter[product_family_name]': 'PATRIMOINE IMH'},
      },
      {
        title: messages.en,
        query: {locale: 'en', 'filter[product_family_name]': 'PATRIMOINE IMH'},
      },
    ],
  },
  {
    title: messages.artistic,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', 'filter[product_family_name]': 'PATRIMOINE ARTISTIQUE'},
      },
      {
        title: messages.en,
        query: {locale: 'en', 'filter[product_family_name]': 'PATRIMOINE ARTISTIQUE'},
      },
    ],
  },

  {
    title: messages.imhConsulting,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', 'filter[product_family_name]': 'PATRIMOINE IMH CONSULTING'},
      },
      {
        title: messages.en,
        query: {locale: 'en', 'filter[product_family_name]': 'PATRIMOINE IMH CONSULTING'},
      },
    ],
  },
  {
    title: messages.indiaPerso,
    subMenu: [
      {
        title: messages.fr,
        query: {locale: 'fr', 'filter[product_family_name]': 'PATRIMOINE INDIA PERSO'},
      },
      {
        title: messages.en,
        query: {locale: 'en', 'filter[product_family_name]': 'PATRIMOINE INDIA PERSO'},
      },
    ],
  },
  
];

