import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  components: () => __(translations.componentsTab.components, 'Components'),
  title: () => __(translations.componentsTab.title, 'Components of {{name}}'),
  label: () => __(translations.componentsTab.label, 'Label'),
  quantity: () => __(translations.componentsTab.quantity, 'Quantity'),
  addComponent: () => __(translations.componentsTab.addComponent, 'Add component'),
};
