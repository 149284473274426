import React, {FC, useState} from 'react';
import messages from '../messages';
import {PricingFilters} from '../pricing-grid/types';
import {EXPORTMenu} from './constants';
import ExportItems from './export-catalog';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {useLazyGetmappingCatalogExportsQuery} from 'features/commercial/quotations/store/endpoints/get-mapping-catalog-export';
import {useLazyGetXLSExportsQuery} from 'features/commercial/quotations/store/endpoints/get-xls-export';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import toast from 'store/ui/actions/toast';

interface Props {
  filters: FilterMap<PricingFilters>;
}

const ExportAction: FC<Props> = props => {
  const {filters} = props;

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [submitXls, {isFetching: isFetchingXls}] = useLazyGetXLSExportsQuery();
  const [submitMAppingCatalog, {isFetching: isFetchingMappingCatalog}] =
    useLazyGetmappingCatalogExportsQuery();
  const handleExportXls = async (filters: any) => {
    const result = await submitXls(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };
  const handleExportMappingCatalog = async (filters: any) => {
    const result = await submitMAppingCatalog(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <AppBarActions>
      {/* Action */}
      <Dropdown
        isOpen={dropdownOpen}
        className="me-2"
        toggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <DropdownToggle color="light">
          {t(...messages.exports())}
          <Icon name="chevron-down" size={15} className="ms-1" />
        </DropdownToggle>
        <DropdownMenu style={{width: '240px'}}>
          {EXPORTMenu.map((item, index) => {
            return <ExportItems key={index} item={item} filters={filters} />;
          })}

          <DropdownItem
            className="ps-2"
            onClick={() => handleExportMappingCatalog(filters)}
            disabled={isFetchingMappingCatalog}
          >
            {t(...messages.catalogMapping())}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            className="ps-2"
            onClick={() => handleExportXls(filters)}
            disabled={isFetchingXls}
          >
            {t(...messages.xls())}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </AppBarActions>
  );
};

export default ExportAction;
