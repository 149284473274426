export enum ContactTypesEnum {
    DELEGATED_OWNER = 'DELEGATED_OWNER', // Maître d'ouvrage délégué
    CLIENT_REFERENT = 'CLIENT_REFERENT', // Référent client
    DELEGATED_PROJECT_MANAGER = 'DELEGATED_PROJECT_MANAGER', // Maître d'œuvre délégué
    OPERATOR = 'OPERATOR', // Exploitant
    BUSINESS = 'BUSINESS', // Entreprises SUPPLIER = 'SUPPLIER', // Fournisseur
    SUPPLIER = 'SUPPLIER', // Fournisseur
    OTHERS = 'OTHERS', // Autres
}

export enum ContactStatusEnum {
    CONSULTED = 'CONSULTED', // Consulté
    RETAINED = 'RETAINED', // Retenu
    CALL_FOR_TENDER = 'CALL_FOR_TENDER' // Appel d'offre
}