import React, {FC} from 'react';
import messages from './messages';
import {EmailMap} from './use-coordinate-tab';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Row, Col, Label, FormGroup, Input} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import Can from 'features/hylian-shield/can';

interface Props {
  email: EmailMap;
  removeEmail: (id: ObjectId) => void;
  updateEmail: any;
  mainEmailId: ObjectId;
  setMainEmailId: (id: ObjectId) => void;
  validationErrors: any;
}

const EmailRow: FC<Props> = props => {
  const {
    email,
    removeEmail,
    updateEmail,
    validationErrors,
    mainEmailId,
    setMainEmailId,
  } = props;
  const {t} = useTranslation();

  return (
    <>
      {Object.values(email).map((p, i) => (
        <Row className="mb-3 " key={p.id}>
          <Col md={3}>
            <AsyncItemPicker
              endPoint="data/directory-email-types"
              getOptionLabel={(x: Option) => x.label}
              getOptionValue={(x: Option) => x.value}
              menuPlacement="top"
              label={t(...messages.type())}
              errors={validationErrors[`directory_emails.${i}.type`]}
              onChange={x => updateEmail(p, 'type', x)}
              value={p.type}
            />
          </Col>
          <Col md={3}>
            <TextInput
              label={
                <>
                  {t(...messages.email())}{' '}
                  <a href={`mailto:${p.email}`}>
                    {' '}
                    <Icon name="mail" size={12}></Icon>
                  </a>
                </>
              }
              type="text"
              errors={validationErrors[`directory_emails.${i}.email`]}
              value={p.email}
              onChange={x => updateEmail(p, 'email', x)}
            />
          </Col>
          <Col md={4}>
            <TextInput
              label={t(...messages.comment())}
              type="textarea"
              rows={1}
              errors={validationErrors[`directory_emails.${i}.comment`]}
              value={p.comment}
              onChange={x => updateEmail(p, 'comment', x)}
            />
          </Col>
          <Col md={1}>
            <Label className="h-100">
              <FormGroup switch className="align-items-center h-100  d-flex   ">
                <Input
                  className="me-1"
                  type="switch"
                  id={`switcher-${p.id}`}
                  role="switch"
                  checked={mainEmailId === p.id}
                  errors={validationErrors[`directory_emails.${i}.is_main`]}
                  onChange={x => setMainEmailId(p.id)}
                />
                <Label htmlFor={`switcher-${p.id}`} className="mb-0">
                  {t(...messages.main())}
                </Label>
              </FormGroup>
            </Label>
          </Col>

          <Col md={1}>
            {mainEmailId !== p.id && (
              <div className="d-flex align-items-center justify-content-end py-4">
                <Can action="delete-and-merge" subject="directory">
                  <Button
                    color="link"
                    className="text-danger p-0 py-1 text-center"
                    onClick={() => removeEmail(p.id)}
                  >
                    <Icon name="trash-2" size={18} />
                  </Button>
                </Can>
              </div>
            )}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default EmailRow;
