import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import selectExpandedFamily from '../../store/selectors/select-expanded-family';
import selectExpandedProduct from '../../store/selectors/select-expanded-product';
import selectExpandedCollection from '../../store/selectors/select-expanded-collection';
import clsx from 'clsx';
import messages from './messages';
import {useTranslation} from 'react-i18next';

const ExpansionBreadCrumbs = () => {
  const family = useSelector(selectExpandedFamily);

  const product = useSelector(selectExpandedProduct);

  const collection = useSelector(selectExpandedCollection);

  const {t} = useTranslation();
  return (
    <Fragment>
      <tr className="text-center">
        <th colSpan={17}>
          <span className={clsx({'d-none': !!collection?.id})}>
            {t(...messages.selectRow())}
          </span>
          <span className={clsx({'d-none': !collection?.id})}>
            {`${collection?.name} `}
          </span>
          <span className={clsx({'d-none': !family?.id})}>
            {`/ ${family?.name} `}
          </span>
          <span className={clsx({'d-none': !product?.id})}>
            {`/ ${product?.name} `}
          </span>
        </th>
      </tr>
    </Fragment>
  );
};

export default ExpansionBreadCrumbs;
