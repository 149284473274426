import React, {FC, useState} from 'react';
import TextInput from 'components/text-input';
import {Badge, Card, CardBody, Col, Row, Table} from 'reactstrap';
import Button from 'components/button';
import Icon from '@ailibs/feather-react-ts';
import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import messages from '../../messages';
import {useUpdateStockLocationCommentMutation} from 'features/production/store/endpoints/update-stock-location-comment';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import Stocklocation from 'features/stock/types/stock-location';
import StatusBadge from 'components/status-badge';
import {Link} from 'react-router-dom';
import {STATUS} from 'constants/status';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import {SerialNumber} from 'types/product-variant';
import {CUSTOM_SERIAL_NUMBER_STATUSES} from '../../../serial-number/constants';
import Can from 'features/hylian-shield/can';

interface Props {
  location: Stocklocation;
  hasStatus?: boolean;
}

const LocationItem: FC<Props> = props => {
  const {location, hasStatus} = props;

  const dispatch = useDispatch();

  const [submitComment, {isLoading, error}] =
    useUpdateStockLocationCommentMutation();

  const {message} = parseValidationErrors(error);

  const [isEditingComment, setisEditingComment] = useState(false);

  const [comment, setComment] = useState(location.comment);

  const [lastCommittedComment, setLastCommittedComment] = useState(
    location.comment
  );

  const cancel = () => {
    setComment(location.comment);
    setisEditingComment(false);
  };

  const handleShowModal = (serial_number: SerialNumber) => {
    dispatch(
      showModal(MODAL.TRANSFER_STOCK_LOCATION_MODAL, {
        productVariantId: id,
        serialNumberId: serial_number.id,
        serialNumber: serial_number.code,
        stockLocationStart: {
          id: location.id,
          name: location.name,
        },
      })
    );
  };

  const {variant: id} = useParams();

  const submit = async () => {
    setisEditingComment(false);
    const result = await submitComment({
      id,
      comment,
      location_id: location.id,
    });
    if ('data' in result) {
      setLastCommittedComment(result.data.data);
    } else {
      setComment(lastCommittedComment);
    }
  };

  const {t} = useTranslation();

  return (
    <FixedHeightRow className="mt-2">
      <Card>
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center">{location.name}</Col>
            <Col className="d-flex align-items-center" md={2}>
              {location.quantity}
            </Col>

            {hasStatus && (
              <Col className="d-flex align-items-center" md={2}>
                <StatusBadge status={STATUS.IN_STOCK} />
              </Col>
            )}
            <Col>
              {isEditingComment ? (
                <div className="d-flex flex-row gap-2">
                  <div className="flex-grow-1">
                    <TextInput
                      type="textarea"
                      value={comment}
                      onChange={setComment}
                    />
                  </div>
                  <Button color="light" onClick={submit}>
                    <Icon name="check" size={15} />
                  </Button>
                  <Button color="light" onClick={cancel}>
                    <Icon name="x" size={15} />
                  </Button>
                </div>
              ) : (
                <CommentParagraph>
                  <CommentField
                    onClick={() => setisEditingComment(!isEditingComment)}
                  >
                    {!isEmpty(comment) ? comment : t(...messages.clickToEdit())}
                  </CommentField>
                </CommentParagraph>
              )}
              <ErrorText className="text-danger font-size-10">
                {message}
                {` `}
              </ErrorText>
            </Col>
            {!hasStatus && (
              <Col className="d-flex align-items-center" md={1}></Col>
            )}
          </Row>
          {location.serial_numbers && location.serial_numbers.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>{t(...messages.serialNumber())}</th>
                  <th>{t(...messages.order())}</th>
                  <th>{t(...messages.status())}</th>
                  <th>{t(...messages.transfer())}</th>
                </tr>
              </thead>
              <tbody>
                {location.serial_numbers.map((sn, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={sn.pdf_links?.label}
                      >
                        {sn.code}
                      </a>
                    </td>
                    <td>
                      <Link
                        target="_blank"
                        to={`/dashboard/orders/${sn.order?.id}/details`}
                      >
                        {sn.order?.client_name
                          ? sn.order?.client_name + ' - '
                          : null}{' '}
                        {sn.order?.order_ref}
                      </Link>
                    </td>
                    <td>
                      <StatusBadge
                        status={sn.status}
                        color={CUSTOM_SERIAL_NUMBER_STATUSES[sn.status].color}
                        text={CUSTOM_SERIAL_NUMBER_STATUSES[sn.status].text}
                      />
                    </td>
                    <td>
                      <Can action="edit" subject="products">
                        <Button
                          color="light"
                          className="btn-sm"
                          onClick={() => handleShowModal(sn)}
                        >
                          <Icon name="corner-up-right" size={14} />
                        </Button>
                      </Can>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </FixedHeightRow>
  );
};

export default LocationItem;

const FixedHeightRow = styled.div`
  min-height: 60px;
`;

const ErrorText = styled.div`
  padding: 0 13px;
  white-space: pre-wrap;
`;

const CommentParagraph = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`;

const CommentField = styled.div`
  min-height: 38px;
  display: flex;
  padding: 0 13px;
  align-items: center;
  white-space: pre-wrap;
  &:hover {
    background-color: var(--ds-surface, #ededed);
    background-size: 20px 20px;
    background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
    border-radius: var(--ds-border-radius, 3px) var(--ds-border-radius, 3px) 0 0;
  }
`;
