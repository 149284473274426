import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectManDaysConfig = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectManDaysConfig: builder.mutation<
      Response,
      StoreProjectManDaysConfigRequest
    >({
      query: (body: StoreProjectManDaysConfigRequest) => {
        return {
          method: 'PUT',
          url: `projects/${body.id}/man-days`,
          params: {
            artistic_director_mandays_cost: body.artistic_director_mandays_cost,
            artistic_director_mandays_rate: body.artistic_director_mandays_rate,
            project_manager_mandays_cost: body.project_manager_mandays_cost,
            assistant_mandays_cost: body.assistant_mandays_cost,
            others_mandays_rate: body.others_mandays_rate,
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export interface StoreProjectManDaysConfigRequest
  extends Pick<
    Project,
    | 'artistic_director_mandays_rate'
    | 'artistic_director_mandays_cost'
    | 'project_manager_mandays_cost'
    | 'assistant_mandays_cost'
    | 'others_mandays_rate'
  > {
  id: ObjectId;
}

type Response = ResponseData<Project>;

export const {useStoreProjectManDaysConfigMutation} = storeProjectManDaysConfig;
