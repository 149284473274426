import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {CommercialProductOption} from 'types/commercial-product-option';
import {ProductCustomization} from 'types/product-customization';

export default createAction(
  actions.updateItemOptionVariant.type,
  function prepare(
    item: CommercialProductOption,
    value?: ProductCustomization
  ) {
    return {
      payload: {
        item,
        value,
      },
    };
  }
);
