import {useGenerateCreditFromInvoiceMutation} from '../../../store/endpoints/action-generate-credit';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useGenerateCredit = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useGenerateCreditFromInvoiceMutation();
  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const result = await submit({
      id,
      document_type: 'CREDIT_ACCOUNTING',
      commercial_product_ids: [],
    });
    if ('data' in result) {
      navigate(`/dashboard/invoices/${result?.data?.data.id}/details`);
      dispatch(toast('success', t(...messages.invoiceDuplicateSucees())));
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    submit: handleSubmit,
    errorMessage: serverError.message,
  };
};
