import {createContext} from 'react';
import {ValidationErrors} from 'services/api/types';

const CostsContext = createContext<CostsResponse>({
  validationErrors: {},
  reset() {},
});

export type CostsResponse = {
  errorMessage?: string;
  message?: string;
  reset: () => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  validationErrors: ValidationErrors;
};

export default CostsContext;
