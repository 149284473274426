import React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import StatusBadge from 'components/status-badge';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {SerialNumber} from 'types/product-variant';
import {CUSTOM_SERIAL_NUMBER_STATUSES} from './constants';

export const columns: ColumnDef<SerialNumber>[] = [
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="serialNumberTab.serialNumber" />
      </span>
    ),
    cell: r => r.getValue(),
    accessorFn: row => (
      <a href={row.pdf_links.label} target="_BLANK" rel="noreferrer">
        {row.code}
      </a>
    ),
    id: 'code',
  },
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="serialNumberTab.order" />
      </span>
    ),
    cell: r => r.getValue(),
    accessorFn: row => (
      <Link to={`/dashboard/orders/${row.order.id}/details`} target="_blank">
        {row.order.client_name} - {row.order.order_ref}
      </Link>
    ),
    id: 'order',
  },
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="serialNumberTab.status" />
      </span>
    ),
    cell: r => r.getValue(),
    accessorFn: row => (
      <StatusBadge
        status={row.status}
        text={CUSTOM_SERIAL_NUMBER_STATUSES[row.status].text}
        color={CUSTOM_SERIAL_NUMBER_STATUSES[row.status].color}
      />
    ),
    id: 'status',
  },
];
