import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const UpdateReconciledPayment = api.injectEndpoints({
  endpoints: builder => ({
    UpdateReconciledPayment: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'PUT',
          url: `payments/${params.id}/reconcile-payment`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useUpdateReconciledPaymentMutation} = UpdateReconciledPayment;
