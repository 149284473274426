import {PAYMENT_METHOD} from '../../constants/payment-methods';
import {Wallet} from '../../types/wallet';
import makePayment from '../../utils/make-payment';
import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {Option} from 'types/option';

export default createAction(
  actions.addPaymentFrom.type,
  (wallet?: Wallet, method?: Option, amount?: string) => {
    const payment = makePayment();

    payment.wallet = wallet;
    payment.payment_method = method;
    payment.amount = amount;

    payment.credit_id =
      PAYMENT_METHOD.CREDIT === method?.value ? wallet?.id : undefined;

    payment.overpayment_id =
      PAYMENT_METHOD.OVERPAYMENT === method?.value ? wallet?.id : undefined;
    return {
      payload: payment,
    };
  }
);
