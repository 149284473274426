import React, {Fragment} from 'react';
import VariantRoutes from '../variant-routes';
import ActionsTab from './actions';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import LoadRunner from 'components/load-runner';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Alert} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useShowVariantQuery} from 'store/production/endpoints/show-variant';

const VariantDetailsSection = () => {
  const {id, variant: variantId} = useParams();
  const {t} = useTranslation();

  const {isLoading, error, isError, isSuccess, refetch} = useShowVariantQuery({
    id: variantId,
    productId: id,
  });

  const serverError = parseValidationErrors(error);

  return (
    <Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <LoadRunner width={'100%'} height={80} viewBox="0 0 100% 80">
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
            <rect x="0" y="40" rx="5" ry="5" width="80%" height="20" />
          </LoadRunner>
        </div>
      )}

      {isError && (
        <div>
          <Alert color="danger">{serverError.message}</Alert>
          <div className="d-flex justify-content-center py-3">
            <Button onClick={refetch}>
              <Icon name="refresh-cw" size={15} className="me-2" />
              {t('common.reload')}
            </Button>
          </div>
        </div>
      )}

      {isSuccess && <VariantRoutes />}
      <AppBarActions>
        <ActionsTab />
      </AppBarActions>
    </Fragment>
  );
};

export default VariantDetailsSection;
