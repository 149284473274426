import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useVariantToNewProductMutation} from 'store/production/endpoints/action-variant-to-new-product';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

import {Product} from 'types/product';
import {ProductVariant} from 'types/product-variant';

export default (onDismiss: () => void) => {
  const navigate = useNavigate();
  const [submit, {isLoading, error}] = useVariantToNewProductMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {variant} = modalParams;

  const [params, setParams] = useState({
    product: {} as Product,
  });

  const redirectByVariant = (v: ProductVariant) => {
    navigate(`/dashboard/products/${v.product_id}/variants/${variant}/general`);
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit({
      id: variant,
      product_id: params.product.id,
    });
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
      redirectByVariant(result.data.data);
      onDismiss();
    }
  };

  return {
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    params,
    setParams,
    submit: handleSubmit,
  };
};
