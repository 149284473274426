import {Order} from 'features/commercial/orders/types/order';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateOrderInfo = api.injectEndpoints({
  endpoints: builder => ({
    updateOrderInfo: builder.mutation<Response, UpdateOrderInfoRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `orders/${params.id}/general-info`,
          body: {
            estimated_supplier_time: params.estimated_supplier_time,
            due_date: params.due_date,
            production_comments: params.production_comments,
            status: params.status?.value,
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'calendarOrder', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type UpdateOrderInfoRequest = {
  id?: ObjectId;
  estimated_supplier_time?: string;
  due_date?: string;
  production_comments?: string;
  status?: Option;
};

type Response = ResponseData<UpdateOrderInfoRequest>;

export const {useUpdateOrderInfoMutation} = updateOrderInfo;
