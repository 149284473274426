import React, {Fragment, useContext} from 'react';
import CostsContext from './costs-context';
import messages from './messages';
import updatePublicPrice from './slice/actions/update-public-price';
import selectPublicPrice from './slice/selectors/select-public-price';
import selectPublicPriceCoeMargin from './slice/selectors/select-public-price-coe-margin';
import selectPublicPriceIncludingTax from './slice/selectors/select-public-price-including-tax';
import selectPublicPriceMargin from './slice/selectors/select-public-price-margin';
import selectPurchasePrice from './slice/selectors/select-purchase-price';
import selectVat from './slice/selectors/select-vat';
import clsx from 'clsx';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Col, Row} from 'reactstrap';
import updateCoefficient from './slice/actions/update-coefficient';

const PublicPrice = () => {
  const {t} = useTranslation();

  const coeMarg = useSelector(selectPublicPriceCoeMargin);

  const dispatch = useDispatch();

  const publicPrice = useSelector(selectPublicPrice);

  const {id} = useParams();

  const {coe} = useSelector(selectVat(id));

  const publicPriceIncludingTax = useSelector(
    selectPublicPriceIncludingTax(coe)
  );

  const purchasePrice = useSelector(selectPurchasePrice);

  const margin = useSelector(selectPublicPriceMargin);

  const {validationErrors} = useContext(CostsContext);

  const handleUpdateCoe = (v: any) => {
    dispatch(updateCoefficient(purchasePrice, v));
  };

  return (
    <Fragment>
      <Col md={3}>
        <TextInput
          label={t(...messages.publicPriceSubtotal())}
          errors={validationErrors['prices.public_price.price_ht']}
          className={clsx({'border-warning': publicPrice < purchasePrice})}
          type="number"
          value={publicPrice}
          onChange={v => dispatch(updatePublicPrice(v))}
        />
        <Row>
          <Col md={6}>
            <TextInput
              label={t(...messages.coeMargin())}
              type="text"
              value={coeMarg}
              onChange={v => handleUpdateCoe(v)}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={t(...messages.margin())}
              disabled
              readOnly
              type="text"
              value={margin}
              onChange={() => {}}
            />
          </Col>
        </Row>
        <TextInput
          label={t(...messages.publicPriceTotal())}
          type="number"
          value={publicPriceIncludingTax.toFixed(2)}
          disabled
          readOnly
          onChange={() => {}}
        />
      </Col>
    </Fragment>
  );
};

export default PublicPrice;
