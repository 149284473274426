import React, {useEffect} from 'react';
import {useCurrentInvoice} from '../invoice-context/invoice-context';
import CreditGeneralInformation from './credit-general-information';
import InvoiceGeneralInformation from './invoice-general-information';
import VatAndTaxes from './vat-and-taxes';
import {Col, Row} from 'reactstrap';
import {useInvoiceGeneralInformationForm} from './use-invoice-general-information-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import Icon from '@ailibs/feather-react-ts';
import {useTranslation} from 'react-i18next';
import InvoiceTransporter from './invoice-transporter';
import Can from 'features/hylian-shield/can';
import {Subject} from 'features/hylian-shield/types';

const GeneralInfoTab = () => {
  const invoice = useCurrentInvoice();
  const {t} = useTranslation();
  const is_locked = invoice?.status === 'VALIDATED';

  const {
    params,
    validationErrors,
    isLoading,
    isCurrencyVisible,
    isSubTypeVisible,
    submit,
    setParams,
  } = useInvoiceGeneralInformationForm();

  return (
    <Row>
      <Col>
        {invoice.document_type === 'CREDIT' ? (
          <CreditGeneralInformation
            params={params}
            isCurrencyVisible={isCurrencyVisible}
            is_locked={is_locked}
            isSubTypeVisible={isSubTypeVisible}
            validationErrors={validationErrors}
            onChangeParams={setParams}
          />
        ) : (
          <InvoiceGeneralInformation
            is_locked={is_locked}
            isSubTypeVisible={isSubTypeVisible}
            isCurrencyVisible={isCurrencyVisible}
            params={params}
            validationErrors={validationErrors}
            onChangeParams={setParams}
          />
        )}
      </Col>
      <Col>
        <VatAndTaxes
          params={params}
          is_locked={is_locked}
          validationErrors={validationErrors}
          onChangeParams={setParams}
        />
        <InvoiceTransporter
          params={params}
          is_locked={is_locked}
          validationErrors={validationErrors}
          onChangeParams={setParams}
        />
      </Col>
      <AppBarActions>
        <Can
          subject={invoice.commercial_type.code?.toLocaleLowerCase() as Subject}
          action="create-and-edit"
        >
          <Button
            isLoading={isLoading}
            disabled={is_locked}
            onClick={submit}
            color="important"
          >
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </Can>
      </AppBarActions>
    </Row>
  );
};

export default GeneralInfoTab;
