import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  commissions: () => __(translations.editProjectCommissionsSection.commissions, 'Commissions'),
  commission: () => __(translations.editProjectCommissionsSection.commission, 'Commission'),
  addCommission: () => __(translations.editProjectCommissionsSection.addCommission, 'Add a commission'),
  addNewSupplier: () => __(translations.editProjectCommissionsSection.addNewSupplier, 'Add new supplier'),
  totalHT: () => __(translations.editProjectCommissionsSection.totalHT, 'Total HT'),

  // statuses
  toInvoice: () => __(translations.editProjectCommissionsSection.toInvoice, 'To invoice'),
  pending: () => __(translations.editProjectCommissionsSection.pending, 'Pending'),
  paid: () => __(translations.editProjectCommissionsSection.paid, 'Paid'),

  // inputs
  comment: () => __(translations.editProjectCommissionsSection.comment, 'Comment'),
  supplier: () => __(translations.editProjectCommissionsSection.supplier, 'Supplier'),
  natureOfTheGoods: () => __(translations.editProjectCommissionsSection.natureOfTheGoods, 'Nature of the goods'),
  pourcentage: () => __(translations.editProjectCommissionsSection.pourcentage, 'Pourcentage'),
  amountHT: () => __(translations.editProjectCommissionsSection.amountHT, 'Amount excl.'),
  invoiceDate: () => __(translations.editProjectCommissionsSection.invoiceDate, 'Invoice Date'),
  invoiceNumber: () => __(translations.editProjectCommissionsSection.invoiceNumber, 'Invoice Number'),
  completionDate: () => __(translations.editProjectCommissionsSection.completionDate, 'Completion Date'),
};
