import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  StoreProductRequest,
  useStoreProductMutation,
} from 'store/production/endpoints/store-product';
import {selectLatestCreatedFamily} from 'store/production/selectors/select-latest-stored-family';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useProductForm = () => {
  const [storeProduct, {isLoading, error}] = useStoreProductMutation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const [params, setParams] = useState<Params>({
    name: __DEV__ ? fake.productName : '',
    description: __DEV__ ? fake.paragraph : '',
    comment: __DEV__ ? fake.paragraph : '',
    in_catalog: false,
    families: [],
    vat: undefined,
    collection: undefined,
  });
  useEffect(() => {
    if (!params.collection) {
      setParams({
        ...params,
        families: [],
      });
    }
  }, [params.collection]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const result = await storeProduct(params);

    if ('data' in result) {
      const product = result?.data.data;
      navigate(`/dashboard/products/${product.id}/general`);
    }
  };

  const latestFamily = useSelector(selectLatestCreatedFamily);

  useEffect(() => {
    if (latestFamily) {
      const familyOption = {
        id: latestFamily.id,
        name: latestFamily.name,
      };
      setParams({...params, families: [...params.families, familyOption]});
    }
  }, [latestFamily]);

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = StoreProductRequest;
