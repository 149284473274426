import clearSelection from 'features/table/slice/actions/clear-selection';
import loadData from 'features/table/slice/actions/load-data';
import selectSelectedResources from 'features/table/slice/selectors/select-selected-resources';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useMassUpdateCatalogMutation} from 'store/production/endpoints/mass-update-catalog';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUpdateCatalogForm = () => {
  const dispatch = useDispatch();

  const [commit, {isLoading, error, isSuccess, reset}] =
    useMassUpdateCatalogMutation();
  const modalParams = useSelector(selectActiveModalParams);
  const {url, path, resourceName} = modalParams;

  const ids = useSelector(selectSelectedResources(resourceName));

  const handleSubmit = async () => {
    const result = await commit({
      ids,
      url,
    });
    if ('data' in result) {
      dispatch(clearSelection(resourceName));
      dispatch(loadData({name: resourceName, url: path, page: 1}));

      dispatch(uiActions.dismissModal());
      dispatch(toast('success', result.data.message));
     
    } 
  };

  const serverError = parseValidationErrors(error);

  return {
    errorMessage: serverError.message,
    isLoading,

    isSuccess,
    submit: handleSubmit,
    validationErrors: serverError.errors,
  };
};
