import {application} from 'constants/application';

/**
 * I18n service
 *
 */
const i18nService = {
  /**
   * Available application locales
   *
   */
  availableLocales: [
    {
      id: 'en',
      label: 'English',
    },
    {
      id: 'fr',
      label: 'Français',
    },
  ],
  /**
   * Applies a locale and make any necessary changes
   *
   * @param locale
   */
  applyLocale: (locale: string) => {},

  /**
   * Get the device language
   *
   * @returns
   */
  getDeviceLanguage: () => {
    let defaultLocale = window.navigator.language;
    return defaultLocale
      ? defaultLocale.substring(0, 2)
      : application.defaultLocale;
  },
};

export default i18nService;
