import React, {FC} from 'react';
import {getOptions} from './constant';
import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {useTranslation} from 'react-i18next';

interface Props {
  series: Series;
  categories?: string[];
  numberOfPoint?: number;
}

const BarCharts: FC<Props> = props => {
  const {series,categories,numberOfPoint} = props;
  const {t} = useTranslation();
  return (
    <ReactApexChart
      options={getOptions(t,categories,numberOfPoint)}
      series={series}
      type="bar"
      height="260"
      className="apex-charts"
    />
  );
};

type Series = ApexOptions['series'];
export default BarCharts;
