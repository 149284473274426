import {createAction} from '@reduxjs/toolkit';
import {ResourceName} from 'types/resource-name';

export default createAction(
  'table/clearSelection',
  function prepare(resourceName: ResourceName) {
    return {
      payload: resourceName,
    };
  }
);
