import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  changesCommited: () => __(translations.generalSettingsTab.changesCommited, 'All changes were saved!'),
  companyAddressCity: () => __(translations.generalSettingsTab.companyAddressCity, 'Company address City'),
  companyAddressLine1: () => __(translations.generalSettingsTab.companyAddressLine1, 'Company address line 1'),
  companyAddressLine2: () => __(translations.generalSettingsTab.companyAddressLine2, 'Company address line 2'),
  companyAddressZip: () => __(translations.generalSettingsTab.companyAddressZip, 'Company address ZIP'),
  email: () => __(translations.generalSettingsTab.email, 'E-mail'),
  fax: () => __(translations.generalSettingsTab.fax, 'Fax'),
  mailChimpKey: () => __(translations.generalSettingsTab.mailChimpKey, 'Mail Chimp Key'),
  phone: () => __(translations.generalSettingsTab.phone, 'Phone'),
  title: () => __(translations.generalSettingsTab.title, 'General'),
  websiteDomain: () => __(translations.generalSettingsTab.websiteDomain, 'Website Domain'),
  websiteName: () => __(translations.generalSettingsTab.websiteName, 'Website name'),
  websiteURL: () => __(translations.generalSettingsTab.websiteURL, 'Website URL'),
};
