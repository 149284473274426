import React from 'react';
import {FC} from 'react';
import {STATUS} from 'constants/status';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Input, Label} from 'reactstrap';
import {useUpdateUserStatusMutation} from 'store/users/endpoints';
import {selectUserStatus} from 'store/users/selectors/select-user-status';
import {User} from 'types/user';

export const UserStatuses = {
  ENABLED: {
    color: 'primary',
    name: <Trans i18nKey="usersPage.enable" />,
  },
  DISABLED: {color: 'success'},
  SUSPENDED: {
    color: 'danger',
    name: <Trans i18nKey="usersPage.disable" />,
  },
  DELETED: {color: 'warning'},
};

const UserStatus: FC<{user: User}> = ({user}) => {
  const id = `square-switch${user.id}`;
  const {role} = user;

  const [updateStatus, {isLoading}] = useUpdateUserStatusMutation();

  const status = useSelector(selectUserStatus(user));

  const handleUpdateStatus = async () => {
    await updateStatus({
      id: user.id,
      status: status === STATUS.ENABLED ? STATUS.DISABLED : STATUS.ENABLED,
    });
  };

  return (
    <div className="d-flex flex-wrap gap-2">
      <Input
        onChange={handleUpdateStatus}
        type="checkbox"
        id={id}
        switch={isLoading ? 'default' : 'success'}
        disabled={isLoading || role?.name === 'ROOT'}
        checked={status === STATUS.ENABLED}
      />
      <Label htmlFor={id} data-on-label="✓" data-off-label="✗"></Label>
    </div>
  );
};

export default UserStatus;
