import {createContext} from 'react';
import {CommercialDocument} from '../../types/commercial-document';
import {CommercialDocumentType} from '../../types/commercial-document-type';
import {ValidationErrors} from 'services/api/types';
import {STATUS} from 'constants/status';

export default createContext<DocumentResponse>({
  validationErrors: {},
  reset() {},
  submit() {},
});

export type DocumentResponse = {
  errorMessage?: string;
  message?: string;
  reset: () => void;
  submit: (payload: any) => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  status?: STATUS;
  validationErrors: ValidationErrors;
  doc_type?: CommercialDocumentType;
  doc?: CommercialDocument; // we don't use document variable name since it refers to the global
  is_locked?: boolean;
};
