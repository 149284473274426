import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useStockProductForm} from './use-product-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {
  Alert,
  Col,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Can from 'features/hylian-shield/can';

const UpdateStockProductForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    errorMessage,
    setParams,
    stockProduct,
  } = useStockProductForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <h4>{stockProduct.product_variant.name}</h4>

          <AsyncItemPicker
            endPoint={`data/stock-sub-locations?stock_location_id=${stockProduct.stock_location_id}`}
            onChange={stock_sub_location =>
              setParams({...params, stock_sub_location})
            }
            value={params.stock_sub_location}
            label={t(messages.sublocation())}
            errors={validationErrors.stock_sub_location_id}
          />
          <div className="mt-3">
            <TextInput
              label={t(messages.comment())}
              value={params.comment}
              onChange={comment => setParams({...params, comment})}
              errors={validationErrors.comment}
              type="textarea"
              rows={3}
            />
          </div>
          <div className="row my-3">
            <p className="mb-0 fs-6">{t(...messages.verficationStatus())}</p>
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div className="form-check  mb-3" dir="ltr">
                  <input
                    name="status"
                    type="radio"
                    className="form-check-input"
                    checked={params.validated === 'VALIDATED'}
                    id="VALIDATED"
                    value={'VALIDATED'}
                    onChange={o => {
                      setParams({...params, validated: o.target.value});
                    }}
                  />
                  <Label className="form-check-label" htmlFor="VALIDATED">
                    {t(...messages.valid())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div className="form-check  mb-3" dir="ltr">
                  <input
                    name="status"
                    type="radio"
                    checked={params.validated === 'CANCELED'}
                    className="form-check-input"
                    id="CANCELED"
                    value={'CANCELED'}
                    onChange={o =>
                      setParams({...params, validated: o.target.value})
                    }
                  />
                  <Label className="form-check-label" htmlFor="CANCELED">
                    {t(...messages.invalid())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </div>
          {params.validated === 'CANCELED' && (
            <div className="row my-2">
              <div className="col">
                <AsyncItemPicker
                  endPoint="data/invalid-stock-reasons"
                  getOptionLabel={(v: any) => v.label}
                  getOptionValue={(v: any) => v.value}
                  className="mb-1"
                  label={t(...messages.reason())}
                  value={params.invalid_reason}
                  onChange={invalid_reason =>
                    setParams({...params, invalid_reason})
                  }
                  errors={validationErrors.invalid_reason}
                />
              </div>
            </div>
          )}
          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Can action="edit" subject="stock">
          <Button isLoading={isLoading} color="important" onClick={submit}>
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </Can>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default UpdateStockProductForm;
