import React from 'react';
import columns from './columns';
import messages from './messages';
import EcoTaxControls from './product-ecotax-controls';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';

const ProductEcoTaxPage = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Table
          url="ecotaxes/products"
          otherParams={{
            delimiter: '|',
          }}
          columns={columns}
          filtersComponent={EcoTaxControls}
          name="products-ecotax"
        />
      </Container>
    </div>
  );
};

export default ProductEcoTaxPage;
