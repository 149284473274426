import {selectDomain} from '.';
import {CommercialProduct} from '../../types/commercial-product';
import selectItems from './select-items';
import {createSelector} from '@reduxjs/toolkit';
import {format} from 'date-fns';
import {produce} from 'immer';
import {Address} from 'types/address';
import {CommercialProductOption} from 'types/commercial-product-option';
import {ObjectId} from 'types/object-id';
import {Payment} from 'types/payment';
import denormalizeObject from 'utils/denormalize-object';

/**
 * formats date
 *
 */
const formatPaymentDate = (strLike?: string) => {
  return strLike ? format(new Date(strLike), 'yyyy-MM-dd') : undefined;
};

/**
 * Returns an array of children item ids
 * each item in a quote can have multiple other items
 * as its children
 *
 * @param p
 * @returns {ObjectId[]}
 */
const getChildIds = (
  p: CommercialProduct,
  products: CommercialProduct[]
): ObjectId[] => {
  const ids: ObjectId[] = [];
  products
    .filter(c => p.linked_products?.find(x => x.commercial_product_id === c.id))
    .forEach(x => {
      const id = x?.order;

      if (typeof id === 'string' || typeof id === 'number') {
        ids.push(id);
      }
    });
  return ids;
};

/**
 * Transforms an address into the shape required
 * by the API
 *
 * @param address
 * @returns {unknown}
 */
const transformAddress = (address: Address): unknown => {
  return produce<any>(address, draft => {
    draft.country = address.country.id;
    draft.vat_mode = address.vat_mode?.value;
    draft.address_type = address.address_type.id;
    draft.recorded_id = address.id;
  });
};

/**
 * Transforms a payment into the shape required
 * by the API
 *
 * @param payment
 * @returns {unknown}
 */
const transformPayment = (payment: Payment): unknown => {
  return produce<any>(payment, draft => {
    draft.date = formatPaymentDate(payment.date);
    draft.type = payment.type?.value;
    draft.payment_method = payment.payment_method?.value;
  });
};

/**
 * Transforms an option into the shape required
 * by the API
 *
 * @param option
 */
const transformOption: DocumentItemOptionTransformer = p => {
  return produce(p, (draft: ExtendedProductOption) => {
    // omit unwanted attributes
    if (!draft.type) {
      throw new Error(`Unknown type ${draft.type}`);
    }
    // delete draft.option_value;
    if (draft.type === 'COMPONENT') {
      draft.component_id = p.component_id;
    }
    if (draft.type === 'OPTION') {
      draft.customization_option_id = p.customization_option_id;
    }
  });
};

/**
 * Transforms a product into the shape required
 * by the API
 *
 * @param option
 * @returns {DocumentItemTransformer} note that this is a function
 */
const transformProduct =
  (
    options: CommercialProductOption[],
    products: CommercialProduct[]
  ): DocumentItemTransformer =>
  (p, i) => {
    return produce<any>(p, draft => {
      // transform boolean attributes
      draft.is_custom_made = !!draft.is_custom_made;
      draft.handel_stock = !!draft.handel_stock;
      // omit unwanted attributes
      delete draft.variant;
      delete draft.vat;
      delete draft.situation;
      delete draft.linked_products;
      draft.unit_price_ht = draft.unit_price_ht ?? undefined;
      draft.quantity = draft.quantity ?? undefined;
      draft.product_variant_id =
        p.commercial_product_type === 'PRODUCT' ? p.variant?.id : undefined;

      // append attributes
      draft.order = i;
      draft.vat_id = p.vat?.id;
      draft.options = options
        .filter(o => o.commercial_product_id === p.id && o.type === 'OPTION')
        .map(transformOption);
      draft.components = options
        .filter(o => o.commercial_product_id === p.id && o.type === 'COMPONENT')
        .map(transformOption);
      draft.custom_name = String(p.custom_name);
      draft.child_ids = getChildIds(p, products);
      draft.price_situation_id = p.situation?.id;
    });
  };

/**
 * Selects all required attributes from the state
 * and maps them into a request body that the API
 * requires
 *
 */
export default createSelector(
  [selectDomain, selectItems],
  (domain, products) => {
    const addresses = denormalizeObject(domain.addresses);
    const options = denormalizeObject(domain.itemOptions);
    const payments = denormalizeObject(domain.payments);
    return {
      products: products.map(transformProduct(options, products)),
      addresses: addresses.map(transformAddress),
      payments: payments.map(transformPayment),
      global_discount: domain.fields.globalDiscount,
      // private_comment: domain.fields.private_comment,
      public_comment: domain.fields.public_comment,
    };
  }
);

type DocumentItemTransformer = (
  p: CommercialProduct,
  index: number,
  array: CommercialProduct[]
) => unknown;

type DocumentItemOptionTransformer = (
  p: CommercialProductOption,
  index: number,
  array: CommercialProductOption[]
) => ExtendedProductOption;

type ExtendedProductOption = CommercialProductOption & {
  customization_option_id?: ObjectId;
  component_id?: ObjectId;
};
