import React, {FC, useState} from 'react';
import messages from '../messages';
import invoiceColumns from './columns';
import CollapseTable from 'components/collapse-table/collapse-table';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Button} from 'reactstrap';

interface Props {}

const InvoiceTable: FC<Props> = props => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const {id} = useParams();
  const filterDAte = [
    new Date('2015/01/01').toISOString(),
    new Date().toISOString(),
  ].join(',');
  return (
    <div>
      <Button color="light" onClick={toggle} className="mb-3 w-100">
        {t(...messages.invoice())}
      </Button>
      <CollapseTable
        isOpen={isOpen}
        columns={invoiceColumns}
        url={`invoices?filter[directory_id]=${id}&limit=10&filter[billing_date]=${filterDAte}&for_stats=true`}
        name="directory-invoices"
      />
    </div>
  );
};

export default InvoiceTable;
