import {StockValue} from 'features/stock/types/stock-value';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getCollectionsInStock = api.injectEndpoints({
  endpoints: builder => ({
    getCollectionsInStock: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `stock/product-collections`,
        params: {
          type: params.type,
        },
      }),
      forceRefetch: () => true,
    }),
  }),
});

type Response = ResponseData<
  {
    id: ObjectId;
    name: string;
    stock: StockValue[];
  }[]
> & {
  head: Record<string, string>;
  tail: Record<string, string>;
};

type Request = {
  type?: string;
};

export const {useGetCollectionsInStockQuery} = getCollectionsInStock;
