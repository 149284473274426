import React, {FC} from 'react';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {
  Button,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import messages from '../messages';
import DateInput from 'components/date-input';
import AsyncItemPicker from 'components/async-item-picker';
import {Invoice} from 'features/commercial/invoices/types/invoice';
import ItemPicker from 'components/item-picker';
import IManDaysForm from '../use-man-days-form';
import {Trash2} from 'react-feather';
import {User} from 'types/user';
import {Link} from 'react-router-dom';
import Icon from '@ailibs/feather-react-ts';
import {useParams} from 'react-router';
import Can from 'features/hylian-shield/can';
import useAbility from 'features/hylian-shield/use-ability';

const BodySection: FC<Props> = ({
  options,
  params,
  validationErros,
  subject,
  setParams,
  handleChangeManDay,
  handleRemoveManDay,
}) => {
  const {t} = useTranslation();

  const {team} = options;
  const {mandays} = params;

  const {id: project_id} = useParams();

  const {checkAbility} = useAbility();

  return (
    <CardBody>
      <div className="mb-4">
        <TextInput
          type="textarea"
          value={params.mandays_global_comment}
          label={t(...messages.comment())}
          onChange={mandays_global_comment =>
            setParams({...params, mandays_global_comment})
          }
        />
      </div>
      <hr />
      {mandays.length > 0 ? (
        <>
          {mandays.map((item, index) => (
            <div key={index}>
              <Row className="mt-2">
                <Row>
                  <Col lg={8}>
                    <ItemPicker
                      label={t(...messages.team())}
                      isMulti
                      errors={validationErros[`mandays.${index}.team`]}
                      //@ts-expect-error
                      value={item.team}
                      getOptionLabel={(v: User) => v.profile?.full_name}
                      options={team}
                      onChange={val => {
                        handleChangeManDay(index, 'team', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <div className="d-flex gap-1">
                      <div className="flex-grow-1">
                        <div>
                          <AsyncItemPicker
                            endPoint={`data/invoices?project_id=${project_id}`}
                            getOptionLabel={(val: Invoice) => (
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{zIndex: -1}}
                              >
                                {val.document_ref}

                                <div
                                  style={{
                                    zIndex: 1,
                                  }}
                                >
                                  <Link
                                    target="_blank"
                                    to={`/dashboard/invoices/${val.id}/details`}
                                  >
                                    <Icon name="external-link" size={15} />
                                  </Link>
                                </div>
                              </div>
                            )}
                            value={item.invoice}
                            errors={
                              validationErros[`mandays.${index}.invoice_id`]
                            }
                            label={t(...messages.invoiceNubmer())}
                            onChange={val => {
                              handleChangeManDay(index, 'invoice', val);
                            }}
                          />
                        </div>
                        <FormGroup switch>
                          <Input
                            checked={item.inculded_in_contract}
                            onChange={() => {
                              handleChangeManDay(
                                index,
                                'inculded_in_contract',
                                !item.inculded_in_contract
                              );
                            }}
                            type="switch"
                            role="switch"
                          />
                          <Label check>
                            {t(...messages.includedInContract())}
                          </Label>
                        </FormGroup>
                      </div>
                      <Can action="edit" subject={subject}>
                        <Button
                          color="danger"
                          className="d-inline-block align-self-center"
                          id={`tooltip-${index.toString()}`}
                          onDoubleClick={() => handleRemoveManDay(index)}
                        >
                          <Trash2 size={14} />
                        </Button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`tooltip-${index.toString()}`}
                        >
                          {t('common.doubleClickToRemove')}
                        </UncontrolledTooltip>
                      </Can>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.location())}
                      value={item.location}
                      errors={validationErros[`mandays.${index}.location`]}
                      onChange={val => {
                        handleChangeManDay(index, 'location', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      label={t(...messages.reason())}
                      value={item.reason}
                      errors={validationErros[`mandays.${index}.reason`]}
                      onChange={val => {
                        handleChangeManDay(index, 'reason', val);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <DateInput
                      label={t(...messages.date())}
                      value={item.date}
                      errors={validationErros[`mandays.${index}.date`]}
                      onChange={val => {
                        handleChangeManDay(index, 'date', val);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={4}>
                    <TextInput
                      type="number"
                      value={item.artistic_director_man_days}
                      label={t(...messages.manDayIndia())}
                      errors={
                        validationErros[
                          `mandays.${index}.artistic_director_man_days`
                        ]
                      }
                      onChange={val => {
                        handleChangeManDay(
                          index,
                          'artistic_director_man_days',
                          val
                        );
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      type="number"
                      value={item.project_manager_man_days}
                      label={t(...messages.manDayProjectManager())}
                      errors={
                        validationErros[
                          `mandays.${index}.project_manager_man_days`
                        ]
                      }
                      onChange={val => {
                        handleChangeManDay(
                          index,
                          'project_manager_man_days',
                          val
                        );
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <TextInput
                      type="number"
                      value={item.assistant_man_days}
                      errors={
                        validationErros[`mandays.${index}.assistant_man_days`]
                      }
                      label={t(...messages.manDayAssistant())}
                      onChange={val => {
                        handleChangeManDay(index, 'assistant_man_days', val);
                      }}
                    />
                  </Col>
                </Row>
              </Row>
              <hr
                className="mt-4"
                style={{
                  display: index < mandays.length - 1 ? 'block' : 'none',
                }}
              ></hr>{' '}
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-center py-4">
          <p>{t('common.noData')}</p>
        </div>
      )}
    </CardBody>
  );
};

type Props = IManDaysForm;

export default BodySection;
