import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import {DirectoryCategory} from 'types/directory-category';
import Button from 'components/button';
import Stocklocation from 'features/stock/types/stock-location';
import Can from 'features/hylian-shield/can';

interface Props {
  stockLocation: Stocklocation;
}

const StockLocationActions: FC<Props> = props => {
  const {stockLocation} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();
  const handleDestroy = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: stockLocation.id,
        name: 'stock-locations',
      })
    );
  };

  const handleEdit = () => {
    dispatch(showModal(MODAL.UPDATE_STOCK_LOCATION, {stockLocation}));
  };

  const hasActions = true;
  return (
    <div className="d-flex align-items-center h-100">
      <Can action="edit" subject="stock">
        <Button
          tag="button"
          className="btn-sm"
          color="transparent"
          onClick={handleEdit}
        >
          <Edit size={18} />
        </Button>

        <Button onClick={handleDestroy} className="btn-sm" color="transparent">
          <Trash2 className="text-danger" size={18} />
        </Button>
      </Can>
    </div>
  );
};

export default StockLocationActions;
