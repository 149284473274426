import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'ui/dismissModalWithResult',
  function prepare(result: unknown) {
    return {
      payload: result,
    };
  }
);
