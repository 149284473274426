import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const massUpdateCosts = api.injectEndpoints({
  endpoints: builder => ({
    massUpdateCosts: builder.mutation<Response, MassUpdateCostsRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/update-price-multiple`,
          body: {
            product_variants: params.ids,
            decorator_price_ht: params.decorator_price_ht,
            public_price_ht: params.public_price_ht,
            resseler_price_ht: params.resseler_price_ht,
          },
        };
      },
    }),
  }),
});

export type MassUpdateCostsRequest = {
  ids?: ObjectId[];
  decorator_price_ht: number | string;
  public_price_ht: number | string;
  resseler_price_ht: number | string;
};

type Response = ResponseData<ProductFamily>;

export const {useMassUpdateCostsMutation} = massUpdateCosts;

export const massUpdateCostsEndpoint =
  massUpdateCosts.endpoints.massUpdateCosts;
