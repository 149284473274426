import React from 'react';
import FamilyActions from './family-actions';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import {Trans} from 'react-i18next';
import {ProductFamily} from 'types/product-family';
import { ColumnDef } from '@tanstack/react-table';

const columns: ColumnDef<ProductFamily>[] = [
  {
    header: r => <Trans i18nKey="familyPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: true,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="familyPage.colections" />,
    accessorFn: row => <EmptyCell cell={row.product_collection_names} />,
    cell: r => r.getValue(),
    id: 'production_collection_names',
     enableSorting: false,
     meta: {costumClass: 'font-size-14', },
  },

/*   {
    header: r => <Trans i18nKey="familyPage.code" />,
     enableSorting: false,
     cell: r => r.getValue(),
    id: 'code',
    accessorFn: row => <EmptyCell cell={row.code} />,
  }, */
  {
    header: r => <Trans i18nKey="familyPage.inspiration" />,
     enableSorting: false,
     cell: r => r.getValue(),
    id: 'inspiration',
    accessorFn: row => <EmptyCell cell={row.inspiration} />,
    meta: {costumClass: 'font-size-14',size: '200px'},
    
  },
  {
    header: r => <Trans i18nKey="familyPage.createdAt" />,
     enableSorting: false,
     cell: r => r.getValue(),
    id: 'created_at',
    accessorFn: row => <FormattedDate value={row.created_at} format="date" />,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="productsPage.action" />,
     enableSorting: false,
    accessorFn: row => <FamilyActions family={row} />,
    cell: r => r.getValue(),
    id: 'actions',
    meta: {costumClass: 'font-size-14'},
  },
];

export default columns;
