import React from 'react';
import {productTabs} from '../constants';
import './product-tab-pane.styles.scss';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';

const ProductTabPane = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const prefix = `/dashboard/products/${id}`;

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4 tab-pane">
      {productTabs.map(x => (
        <NavItem key={x.to}>
          <NavLink
            className={active =>
              clsx({active: active.isActive}, 'px-3 nav-link')
            }
            to={`${prefix}/${x.to}`}
          >
            {t(`editProductPage.${x.title}`)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default ProductTabPane;
