import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {FetchArgs, fetchBaseQuery} from '@reduxjs/toolkit/query';
import {application} from 'constants/application';
import {RootState} from 'store';

const baseQuery = fetchBaseQuery({
  baseUrl: application.apiBaseUrl,
  prepareHeaders: (headers, {getState}) => {
    const {accessToken} = (getState() as RootState).auth;
    const {locale} = (getState() as RootState).global;
    headers.set('Accept', 'application/json');
    headers.set('Accept-Language', locale);
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const apiBlobBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    /**
     * Refresh token implementation
     */
    // get a new token
    // const refreshResult = await baseQuery('/refresh-token', api, extraOptions);
    // if (refreshResult.data) {
    // store the new token
    // api.dispatch(tokenReceived(refreshResult.data));
    // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    // api.dispatch(sessionExpired());
    // }
    api.dispatch(forceLogout());
  }
  return result;
};

const forceLogout = () => {
  const logout = require('store/auth/actions/purge-auth-state').default;
  return logout();
};

export default apiBlobBaseQuery;
