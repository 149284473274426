import React, {FC, useMemo} from 'react';
import messages from '../messages';
import {QuotationFilters} from './types';
import AsyncItemPicker from 'components/async-item-picker';
import DateRangeInput from 'components/date-range-input';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Label, Row} from 'reactstrap';
import {Option} from 'types/option';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<QuotationFilters>;
  onChangeFilters: (key: QuotationFilters, value: any) => void;
  onClearFilters?: () => void;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <SearchSection
      filters={filters}
      onChangeFilters={onChangeFilters}
      onClearFilters={onClearFilters}
    >
      <Row className="mb-3">
        <Col lg="6" md="6">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.quotationIssueDate())}
              value={filters['filter[created_at]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[created_at]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.deadline())}
              value={filters['filter[due_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[due_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-categories"
              className="mb-1"
              label={t(...messages.category())}
              value={filters['filter[document_category_id]']}
              onChange={o => onChangeFilters('filter[document_category_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/commercial-types?quotation"
              className="mb-1"
              defaultValue
              getDefaultOptions={(meta: any) => meta?.default_index}
              label={t(...messages.type())}
              value={filters['filter[commercial_type_id]']}
              onChange={o => onChangeFilters('filter[commercial_type_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/projects"
              label={t(...messages.projet())}
              onChange={o => onChangeFilters('filter[project]', o)}
              value={filters['filter[project]']}
              className="text-capitalize"
              getOptionLabel={(s: any) => s.name?.toLocaleLowerCase()}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-origins"
              className="mb-1"
              label={t(...messages.origin())}
              value={filters['filter[origin_id]']}
              onChange={o => onChangeFilters('filter[origin_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/quotation-statuses"
              className="text-capitalize"
              getOptionLabel={(s: Option) => s.label?.toLocaleLowerCase()}
              label={t(...messages.status())}
              isClearable
              getOptionValue={(s: Option) => s?.value}
              value={filters['filter[status]'] as Option}
              onChange={o => onChangeFilters('filter[status]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.internalDueDate())}
              value={
                filters['filter[production_declared_completion_date]'] as string
              }
              onChange={newDate => {
                onChangeFilters(
                  'filter[production_declared_completion_date]',
                  newDate
                );
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/users"
              className="text-capitalize"
              getOptionLabel={(u: Option) => u.name}
              label={t(...messages.user())}
              isClearable
              getOptionValue={(u: Option) => u?.id}
              value={filters['filter[contact]'] as Option}
              onChange={o => onChangeFilters('filter[contact]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_vintage"
                checked={!!filters['filter[has_variants_with_old_collection]']}
                onChange={(o: any) =>
                  onChangeFilters(
                    'filter[has_variants_with_old_collection]',
                    o.target.checked
                  )
                }
              />
              <Label className="form-check-label" htmlFor="is_vintage">
                {t(...messages.vintage())}
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
