import {EcoTax} from 'features/acounting/types/eco-tax';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateEcotax = api.injectEndpoints({
  endpoints: builder => ({
    updateEcotax: builder.mutation<Response, UpdateEcotaxRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `ecotaxes/${params.id}`,
          body: {
            product_code: params.product_code,
            customs_code: params.customs_code,
            ecopart_code: params.ecopart_code,
            ecopart_rate: params.ecopart_rate,
          },
        };
      },
    }),
  }),
});

export type UpdateEcotaxRequest = {
  id: ObjectId;
  product_code: string;
  customs_code: string;
  ecopart_code: string;
  ecopart_rate: number;
};

type Response = ResponseData<EcoTax>;

export const {useUpdateEcotaxMutation} = updateEcotax;
