import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useAssembleProductForm} from './use-assemble-product-form';
import {Link} from 'react-router-dom';
import {generateLink} from 'features/commercial/documents/containers/related-documents-section/related-documents-section';
import {StockProductToAssembleTable} from './product-to-assemble-table';

const AssembleProductForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    isLoadingSerialNumber,
    validationErrorsSerialNumber,
    ErrorMessageSerialNumber,
    validationErrors,
    errorMessage,
    setParams,
    product,
    submitSerialNumber,
    handleSearchSerialNumberSearch,
    serialNumber,
  } = useAssembleProductForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <div className="w-100">
              <TextInput
                onChange={code => handleSearchSerialNumberSearch(code)}
                value={serialNumber}
                label={t(...messages.serialNumber())}
                errors={
                  validationErrors.code || validationErrorsSerialNumber.code
                }
              />
            </div>
            <Button
              className="align-self-end"
              isLoading={isLoadingSerialNumber}
              color="important"
              onClick={() => (serialNumber ? submitSerialNumber() : null)}
            >
              {!isLoadingSerialNumber && (
                <i className="bx bx-search-alt align-middle"></i>
              )}
            </Button>
          </div>
          {product && (
            <>
              <div className="my-3 text-center">
                <h4>
                  <Link
                    to={`/dashboard/products/${product.variant.product_id}/variants/${product.variant.id}/general`}
                  >
                    {product.variant.name}
                  </Link>
                </h4>
                <Link to={`/dashboard/orders/${product.document_id}/details`}>
                  <p>{product.document_ref}</p>
                </Link>
              </div>
              <AsyncItemPicker
                endPoint="data/stock-locations"
                onChange={stock_location =>
                  setParams({...params, stock_location})
                }
                value={params.stock_location}
                label={t(messages.subLocation())}
                errors={validationErrors.stock_location_id}
              />{' '}
              <StockProductToAssembleTable
                productToAssemble={params.products}
              />
            </>
          )}

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div>
                {ErrorMessageSerialNumber && (
                  <Alert color="danger">{ErrorMessageSerialNumber}</Alert>
                )}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        {product?.should_show_join_action ? (
          <Button isLoading={isLoading} color="important" onClick={submit}>
            {t(messages.assemble())}
          </Button>
        ) : null}
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default AssembleProductForm;
