import {useMemo, useState} from 'react';
import {produce} from 'immer';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ObjectId} from 'types/object-id';
import {
  TransferQuoteRequest,
  TransferResponse,
  TransferType,
  useTransferQuoteMutation,
} from '../../store/endpoints/transfer-quote';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useDispatch} from 'react-redux';
import {uiActions} from 'store/ui/ui-slice';
import {selectQuotation} from '../../store/endpoints/show-quotation';
import denormalizeObject from 'utils/denormalize-object';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [transfer, {isLoading, error, data: response}] =
    useTransferQuoteMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useSelector(selectActiveModalParams);

  const [params, setParams] = useState<Params>({});

  const [items, setItems] = useState<Record<ObjectId, boolean>>({});

  const dispatch = useDispatch();

  const handleSubmit = async (type_to_transfer: TransferType) => {
    const result = await transfer({
      ...params,
      items,
      type_to_transfer,
      id,
    });

    if ('data' in result) {
      redirectByType(result?.data);
      dispatch(uiActions.dismissModal());
    }
  };

  const navigate = useNavigate();

  const redirectByType = (res: TransferResponse) => {
    const {redirect_to} = res;

    const linkByDoctype = {
      Order: `/dashboard/orders/${redirect_to.id}/details`,
      Invoice: `/dashboard/invoices/${redirect_to.id}/details`,
    };
    navigate(linkByDoctype[redirect_to.model_type]);
  };

  const updateItem = (id: ObjectId) => {
    setItems(
      produce(items, draft => {
        draft[id] = !draft[id];
      })
    );
  };

  const itemsLength = useMemo(() => {
    const commercial_products = Object.keys(items ?? {})
      .filter(x => items[x])
      .map(id => ({id}));
    return commercial_products.length;
  }, [items]);

  const quote = useSelector(selectQuotation({id}));

  const obj = quote.data?.data.commercial_products_objectified;

  const allItems = denormalizeObject(obj);

  const isAllSelected = useMemo(() => {
    return itemsLength === allItems.length;
  }, [items, itemsLength, allItems]);

  const toggleAll = () => {
    if (isAllSelected) {
      setItems({});
    } else {
      let tmp: any = {};
      allItems.forEach(x => {
        tmp[x.id] = true;
      });

      return setItems(tmp);
    }
  };

  return {
    setItems,
    itemsLength,
    setParams,
    submit: handleSubmit,
    updateItem,
    items,
    isAllSelected,
    toggleAll,
    errorMessage: serverError.message,
    isLoading,
    params,
    validationErrors: serverError.errors,
  };
};

type Params = Partial<TransferQuoteRequest>;
