import {ApiEndpointBuilder} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

const updateUserPasswordEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<ResponseData<User>, Request>({
    query: newPasswordInfo => ({
      url: `users/${newPasswordInfo.id}/update-password`,
      method: 'PUT',
      body: newPasswordInfo,
    }),
  });
};

type Request = {
  id: ObjectId;

  password?: string;
  password_confirmation?: string;
};

export default updateUserPasswordEndpoint;
