import React from 'react';
import columns from './columns';
import messages from './messages';
import OverpaymentControls from './controls';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import {Payment} from 'types/payment';

const PaymentsPage = () => {
  const {t} = useTranslation();

  const makeRowClassName = (row: Payment) =>
    row?.reconciled_payment ? `bg-success bg-opacity-10` : '';

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Table
          url="payments"
          otherParams={{
            delimiter: '|',
          }}
          hideSearch
          columns={columns}
          makeRowClassName={makeRowClassName}
          filtersComponent={OverpaymentControls}
          name="payments"
        />
      </Container>
    </div>
  );
};

export default PaymentsPage;
