import React, {useContext} from 'react';
import {TeamTypesEnum} from 'constants/team';
import {Trash2} from 'react-feather';
import {Link} from 'react-router-dom';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Col,
  Input,
  Label,
  Row,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import {ITeamForm} from '../use-team-form';
import {FC} from 'react';
import {AbilityContext} from 'features/hylian-shield';
import Can from 'features/hylian-shield/can';

export const TeamAccordion: FC<Props> = ({
  regroupedTeam,
  defaultOpenedAccordion,
  project,
  subject,
  handleRemoveInput,
  handleSwitchStatus,
}) => {
  const {t} = useTranslation();

  const {checkAbility} = useContext(AbilityContext);

  return (
    <UncontrolledAccordion stayOpen defaultOpen={defaultOpenedAccordion}>
      {regroupedTeam.map((element, x) => (
        <AccordionItem key={x} color="danger">
          <AccordionHeader targetId={x.toString()}>
            <div className="d-flex justify-content-between w-100">
              <h5 color="light">
                {t(`editProjectTeamSection.${element.type.toLowerCase()}`)}
                <Badge
                  color={element.data.length > 0 ? 'success' : 'light'}
                  className="mx-2"
                >
                  {element.data.length}
                </Badge>
              </h5>
              <h5 color="light">
                <Badge color="light" className="mx-2">
                  {project
                    ? t(...messages.scaleManDays(), {
                        scale: t('numbers.currency', {
                          val: project[
                            `${element.type.toLocaleLowerCase()}_mandays_cost`
                          ],
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 0,
                        }),
                      })
                    : ''}
                </Badge>
              </h5>
            </div>
          </AccordionHeader>
          <AccordionBody key={x} accordionId={x.toString()}>
            {element.data.length > 0 ? (
              <>
                {element.data.map((child, y) => {
                  return (
                    <div key={y}>
                      <Row style={{alignItems: 'center'}} className="py-3">
                        <Can action="edit" subject={subject}>
                          <Col md={1}>
                            <Button
                              color="transparent"
                              className="border-0"
                              disabled={
                                child.type ===
                                  TeamTypesEnum.ARTISTIC_DIRECTOR ||
                                !checkAbility('edit', subject)
                              }
                              id={`tooltip-${y}`}
                              onDoubleClick={() =>
                                handleRemoveInput(child.user.id)
                              }
                            >
                              <Trash2 className="text-danger" size={20} />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-${y}`}
                            >
                              {t('common.doubleClickToRemove')}
                            </UncontrolledTooltip>
                          </Col>
                        </Can>

                        <Col md={2}>
                          <Link
                            target="_BLANK"
                            className="fw-bold"
                            to={`/dashboard/users/${child.user.id}/profile`}
                          >
                            {child.user.profile?.full_name?.toLocaleUpperCase()}
                          </Link>
                        </Col>
                        <Col md={3}>
                          <a href={`mailto:${child.user.email}`}>
                            {child.user.email}
                          </a>
                        </Col>
                        <Col md={2}>{child.user.profile?.phone_number}</Col>
                        <Col md={2}>
                          <div className="d-flex flex-wrap gap-2">
                            <Input
                              onChange={() => handleSwitchStatus(child.user.id)}
                              disabled={
                                child.type ===
                                  TeamTypesEnum.ARTISTIC_DIRECTOR ||
                                !checkAbility('edit', subject)
                              }
                              type="checkbox"
                              id={child.identifier.toString()}
                              switch={child.is_active ? 'success' : 'default'}
                              checked={child.is_active}
                            />
                            <Label
                              htmlFor={child.identifier.toString()}
                              data-on-label="✓"
                              data-off-label="✗"
                            ></Label>
                          </div>
                        </Col>
                      </Row>
                      <hr
                        className="mt-2"
                        style={{
                          display:
                            y < element.data.length - 1 ? 'block' : 'none',
                        }}
                      ></hr>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="text-center">{t('common.noData')}</p>
            )}
          </AccordionBody>
        </AccordionItem>
      ))}
    </UncontrolledAccordion>
  );
};

type Props = ITeamForm;
