import {useState} from 'react';
import {faker} from '@faker-js/faker';
import addPaymentFrom from 'features/commercial/documents/store/actions/add-payment-from';
import {Wallet} from 'features/commercial/documents/types/wallet';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {ValidationErrors} from 'services/api/types';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {Option} from 'types/option';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import messages from './messages';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (): IPaymentForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {type} = useSelector(selectActiveModalParams);

  const handleSubmit = () => {
    if (!params.wallet || !params.amount)
      return dispatch(toast('error', t(...messages.fillRequiredInputs())));

    dispatch(addPaymentFrom(params.wallet, type, params.amount));
    dispatch(uiActions.dismissModal());
  };

  const handleSelect = (documentable: Wallet) => {
    setParams({
      ...params,
      wallet: documentable,
      amount: documentable.total.toString(),
    });
  };

  const [params, setParams] = useState<Params>({});

  return {
    params,
    setParams,
    submit: handleSubmit,
    handleSelect,
    validationErrors: {} as ValidationErrors,
  };
};

type Params = {
  wallet?: Wallet;
  method?: Option;
  amount?: string;
};

interface IPaymentForm {
  params: Params;
  setParams: (args: Params) => void;
  handleSelect: (args: Wallet) => void;
  submit: () => void;
  validationErrors: ValidationErrors;
}
