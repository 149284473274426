import {Order} from '../../types/order';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const showOrder = api.injectEndpoints({
  endpoints: builder => ({
    showOrder: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `orders/${params.id}/commercial-products`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'orders', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Order>;

export const {useLazyShowOrderQuery, useShowOrderQuery} = showOrder;

export const updateCachedOrderFromResource = (order: Order) => {
  return showOrder.util.updateQueryData(
    'showOrder',
    {id: String(order.id)},
    x => {
      x.data = order;
    }
  );
};

export const selectOrder = showOrder.endpoints.showOrder.select;
