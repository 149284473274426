/**
 * A small wrapper around fetch that executes an http request
 * and automatically parses the body to json
 *
 */
export const fetchRequest = async (
  url: Request | string,
  method: Method,
  options?: RequestInit | any,
  headers?: any
) => {
  const {body, ...rest} = options || {};
  const response = await fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(headers || {}),
    },
    body: serializeBody(method, body),
    ...rest,
  });
  const json = await validateErrors(response);
  return json;
};

const validateErrors = async (response: Response) => {
  const responseJson = await response.json();
  const json = Object.assign({}, responseJson);
  json.status_code = response.status;
  if (response.status >= 200 && response.status < 400) {
    json.ok = true;
    return json;
  }
  json.ok = false;
  return json;
};

const serializeBody = (method: string, body: any) => {
  if (method !== 'get') {
    return JSON.stringify(body);
  }
  return undefined;
};

type Method = 'GET' | 'POST' | 'DELETE' | 'PATCH';
