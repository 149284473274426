import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'costs/updatePublicPrice',
  function prepare(value: string | number) {
    return {
      payload: value,
    };
  }
);
