import clsx from 'clsx';
import React, {FC, useEffect, useRef} from 'react';

interface IndeterminateCheckboxProps {
  indeterminate: boolean;
  className?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
}

export const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = props => {
  const {
    indeterminate,
    className = '',
    checked,
    onChange,
    disabled,
    id,
  } = props;
  const ref = useRef<HTMLInputElement>(null!);
  useEffect(() => {
    if (disabled === true && typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate, disabled]);

  return (
    <input
      ref={ref}
      type="checkbox"
      className={clsx(
        {
          'border-black': !checked,
          'border-primary': checked,
        },
        className + 'form-check-input cursor-pointer border-opacity-50'
      )}
      id={id}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
