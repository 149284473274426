import React from 'react';
import GetOptionLabel from './get-option-label';
import messages from './messages';
import NoOptionMessage from './no-option-message';
import {useInvoiceForm} from './use-invoice-form';
import Icon from '@ailibs/feather-react-ts';
import Breadcrumb from 'components/Common/Breadcrumb';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import {Alert, Card, CardBody, Col, Row} from 'reactstrap';
import {Directory} from 'types/directory';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

const CreateInvoicePage = () => {
  const {t} = useTranslation();
  const {
    params,
    validationErrors,
    isLoading,
    errorMessage,
    submit,
    syncParams,
    setParams,
  } = useInvoiceForm();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.invoices()), path: '/dashboard/invoices'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <div>
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/directories"
                    className="my-2"
                    getOptionLabel={(directory: Directory) => (
                      <GetOptionLabel directory={directory} />
                    )}
                    label={t(...messages.client())}
                    optionLabel=""
                    errors={validationErrors.directory_id}
                    value={params.client}
                    onChange={async client => {
                      syncParams(client);
                    }}
                    noOptionsMessage={(e: any) => (
                      <NoOptionMessage newClient={e.inputValue} />
                    )}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/commercial-types?invoice"
                    defaultValue
                    className="my-2"
                    label={t(...messages.invoiceType())}
                    optionLabel=""
                    errors={validationErrors.commercial_type_id}
                    value={params.commercial_type}
                    onChange={commercial_type =>
                      setParams({...params, commercial_type})
                    }
                  />
                </Col>
                {params.commercial_type?.code === 'H' ? (
                  <Col md={12}>
                    <AsyncItemPicker
                      endPoint="data/project-fee-types"
                      className="my-2"
                      label={t('invoiceGeneralInformation.projectFeesType')}
                      getOptionLabel={(l: any) => l.label}
                      getOptionValue={(l: any) => l.value}
                      errors={validationErrors.project_fee_type}
                      value={params.projectFeesType}
                      onChange={projectFeesType =>
                        setParams({...params, projectFeesType})
                      }
                    />
                  </Col>
                ) : null}

                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/document-categories"
                    defaultValue
                    className="my-2"
                    label={t(...messages.invoiceCategory())}
                    optionLabel=""
                    errors={validationErrors.document_category_id}
                    value={params.category}
                    onChange={category => setParams({...params, category})}
                  />
                </Col>

                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/document-origins"
                    defaultValue
                    className="my-2"
                    label={t(...messages.invoiceOrigin())}
                    optionLabel=""
                    errors={validationErrors.origin_id}
                    value={params.origin}
                    onChange={origin => setParams({...params, origin})}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/languages"
                    defaultValue
                    className="my-2 text-capitalize"
                    label={t(...messages.invoiceLanguage())}
                    getOptionLabel={(l: any) => l.label}
                    errors={validationErrors.language}
                    value={params.language}
                    getOptionValue={(l: any) => l.label}
                    onChange={language => {
                      setParams({...params, language});
                    }}
                  />
                </Col>
                <Col md={12} className="my-2">
                  <DateInput
                    label={t(...messages.invoiceBillingDate())}
                    value={params.billing_date}
                    onChange={billing_date =>
                      setParams({...params, billing_date})
                    }
                    errors={validationErrors.billing_date}
                  />
                </Col>
                <Col md={12} className="mb-1">
                  <DateInput
                    label={t(...messages.invoiceDueDate())}
                    value={params.due_date}
                    onChange={due_date => setParams({...params, due_date})}
                    errors={validationErrors.due_date}
                  />
                </Col>
              </Row>

              <AppBarActions>
                <Button
                  isLoading={isLoading}
                  onClick={submit}
                  color="important"
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </AppBarActions>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default CreateInvoicePage;
