import React from 'react';
import accountingRoutes from './accounting';
import commercialRoutes from './commercial';
import directoryRoutes from './directory';
import productionRoutes from './production';
import {AppRoute} from './types';
import userRoutes from './admin';
import EmptyLayout from 'components/empty-layout';
import AccountPage from 'pages/account';
import Dashboard from 'pages/dashboard';
import ColorsPage from 'pages/dashboard/colors/colors-page';
import ExportsPage from 'pages/dashboard/exports';
import NotificationsPage from 'pages/dashboard/notifications';
import SettingsPage from 'pages/dashboard/settings/settings-page';
import WelcomePage from 'pages/welcome';
import {Navigate} from 'react-router';
import MaterialsPage from 'pages/dashboard/materials/materials-page';
import studioRoutes from './studio';
import stockRoutes from './stock';
import monitoringRoutes from 'features/stock/production-monitoring/routes/monitoring';

const authRoutes: Array<AppRoute> = [
  {
    path: '/welcome',
    layout: EmptyLayout,
    component: <WelcomePage />,
  },
  {
    path: '/account',
    component: <Navigate to="/account/profile" />,
  },
  {
    path: '/account/*',
    component: <AccountPage />,
  },

  {
    path: '/dashboard',
    component: <Dashboard />,
  },

  /* {
    path: '/dashboard/notifications',
    component: <NotificationsPage />,
  }, */
  {
    path: '/dashboard/exports',
    component: <ExportsPage />,
  },
  {
    path: '/dashboard/settings',
    component: <Navigate to="/dashboard/settings/general" />,
    subject: ['settings-general'],
  },
  {
    path: '/dashboard/colors',
    component: <ColorsPage />,
    subject: ['settings-colors'],
  },
  {
    path: '/dashboard/materials',
    component: <MaterialsPage />,
    subject: ['settings-matter'],
  },
  {
    path: '/dashboard/settings/*',
    component: <SettingsPage />,
    subject: ['settings-colors', 'settings-general', 'settings-matter'],
  },
  ...commercialRoutes,
  ...userRoutes,
  ...productionRoutes,
  ...directoryRoutes,
  ...accountingRoutes,
  ...studioRoutes,
  ...stockRoutes,
  ...monitoringRoutes,
];

export default authRoutes;
