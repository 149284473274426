import {CommercialDocumentState} from './types';

export const initialState: CommercialDocumentState = {
  stagedRecords: {},
  addresses: {},
  payments: {},
  items: {},
  itemOptions: {},
  itemOrder: {},
  fields: {
    globalDiscount: '0',
    private_comment: '',
    public_comment: '',
  },
};
