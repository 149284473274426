import React from 'react';
import {AppRoute} from './types';
import DirectoryPage from 'pages/dashboard/directories';
import DirectoryCategoriesPage from 'pages/dashboard/directories/categories';
import CreateCompanyPage from 'pages/dashboard/directories/create/companies';
import CreateContactPage from 'pages/dashboard/directories/create/contacts';
import DirectoryFamiliesPage from 'pages/dashboard/directories/families';
import DirectoryMasterDetails from 'pages/dashboard/directories/master-details';

const directoryRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/directories',
    component: <DirectoryPage />,
    subject: 'directory',
  },
  {
    path: '/dashboard/directories/contacts/create',
    component: <CreateContactPage />,
    subject: 'directory',
  },
  {
    path: '/dashboard/directories/companies/create',
    component: <CreateCompanyPage />,
    subject: 'directory',
  },
  {
    path: '/dashboard/directories/:id/*',
    component: <DirectoryMasterDetails />,
    subject: 'directory',
  },
  {
    path: '/dashboard/directories/categories',
    component: <DirectoryCategoriesPage />,
    subject: 'directory',
  },
  {
    path: '/dashboard/directories/families',
    component: <DirectoryFamiliesPage />,
    subject: 'directory',
  },
];

export default directoryRoutes;
