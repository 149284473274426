import React, {Fragment, useContext} from 'react';
import CostsContext from './costs-context';
import messages from './messages';
import updateDiscount from './slice/actions/update-discount';
import updateSubtotal from './slice/actions/update-subtotal';
import selectPurchasePrice from './slice/selectors/select-purchase-price';
import selectResellerCoeMargin from './slice/selectors/select-reseller-coe-margin';
import selectResellerPriceIncludingTax from './slice/selectors/select-reseller-price.including-tax';
import selectTotalFields from './slice/selectors/select-total-fields';
import selectVat from './slice/selectors/select-vat';
import clsx from 'clsx';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Col, Row} from 'reactstrap';

const ResellerPrice = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const purchasePrice = useSelector(selectPurchasePrice);

  const resellerCoeMarg = useSelector(selectResellerCoeMargin);

  const {id} = useParams();

  const {coe} = useSelector(selectVat(id));

  const resellerTotal = useSelector(selectResellerPriceIncludingTax(coe));

  const totalFields = useSelector(selectTotalFields);

  const {validationErrors} = useContext(CostsContext);

  return (
    <Fragment>
      <Col md={3}>
        <TextInput
          label={t(...messages.resellerSubtotal())}
          type="number"
          errors={validationErrors['prices.reseller_price.price_ht']}
          className={clsx({
            'border-warning': totalFields.reseller_price < purchasePrice,
          })}
          value={totalFields.reseller_price}
          onChange={v => dispatch(updateSubtotal('reseller', v))}
        />

        <Row>
          <Col md={6}>
            <TextInput
              label={t(...messages.coeMargin())}
              disabled
              type="number"
              value={resellerCoeMarg}
              onChange={() => {}}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={t(...messages.discount())}
              type="number"
              errors={validationErrors['prices.reseller_price.discount']}
              value={totalFields.reseller_price_discount}
              onChange={v => dispatch(updateDiscount('reseller', v))}
            />
          </Col>
        </Row>
        <TextInput
          label={t(...messages.resellerTotal())}
          disabled
          readOnly
          type="text"
          value={resellerTotal.toFixed(2)}
          onChange={() => {}}
        />
      </Col>
    </Fragment>
  );
};

export default ResellerPrice;
