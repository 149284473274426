import React, {FC, Fragment, useContext} from 'react';
import {AbilityContext} from '.';
import {Subject, Action} from './types';
import {AbilityContextType} from './types';

const Can: FC<Props> = props => {
  const {action, subject, children} = props;
  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const show = checkAbility(action, subject);

  return (
    <AbilityContext.Consumer>
      {() => <Fragment>{show ? children : null}</Fragment>}
    </AbilityContext.Consumer>
  );
};

interface Props {
  action: Action | Action[];
  subject: Subject | Subject[];
  children: any;
}
export default Can;
