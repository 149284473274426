import {CommercialProduct} from '../../types/commercial-product';
import {actions} from '../slice';
import {CommercialDocumentState} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(
  actions.reorderItems.type,
  function prepare(newItems: readonly CommercialProduct[]) {
    const itemOrder = {} as CommercialDocumentState['itemOrder'];
    const items = {} as CommercialDocumentState['items'];
    newItems.forEach((x, i) => {
      itemOrder[x.id] = i;
      items[x.id] = {
        ...x,
        order: i,
      };
    });
    return {
      payload: {
        itemOrder,
        items,
      },
    };
  }
);
