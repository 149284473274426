import {Dispatch, SetStateAction, createContext} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import {ObjectId} from 'types/object-id';
import {CommercialDocument} from '../../types/commercial-document';
import {CommercialDocumentType} from '../../types/commercial-document-type';

export default createContext<OtherParams>({
  submit() {},
  params: {} as OtherParamsFields,
  setParams: () => {},
});

export type OtherParams = {
  params: OtherParamsFields;
  setParams: Dispatch<SetStateAction<OtherParamsFields>>;
  submit: (payload: any) => void;
  doc?: CommercialDocument;
  doc_type?: CommercialDocumentType;
};

export type OtherParamsFields = Partial<CommercialProduct> & {id: ObjectId};
