import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useSubLocationForm} from './use-sub-location-form';

const UpdateStockSubLocationForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useSubLocationForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={name => setParams({...params, name})}
            value={params.name}
            label={t('createStockSubLocationModal.name')}
            errors={validationErrors.name}
          />

          <AsyncItemPicker
            endPoint="data/stock-locations"
            onChange={stock_location => setParams({...params, stock_location})}
            value={params.stock_location}
            label={t('createStockSubLocationModal.location')}
            errors={validationErrors.stock_location}
          />

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default UpdateStockSubLocationForm;
