import React, {FC} from 'react';
import messages from './messages';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';

interface Props {
  params: any;
  onChangeParams: (x: any) => void;
  validationErrors: ValidationErrors;
  is_locked?: boolean;
}

const QuotationProductionTab: FC<Props> = props => {
  const {t} = useTranslation();

  const {params, onChangeParams, validationErrors, is_locked} = props;

  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <Row>
        <Col md={12} className="mb-2">
          <TextInput
            label={t(...messages.quotationDepositRequested())}
            value={params.production_requested_deposit}
            disabled={is_locked}
            onChange={production_requested_deposit => {
              onChangeParams({
                ...params,
                production_requested_deposit,
              });
            }}
            errors={validationErrors.production_requested_deposit}
          />
        </Col>
        <Col md={12} className="my-2">
          <DateInput
            label={t(...messages.quotationAnnouncedDeadline())}
            value={params.production_declared_completion_date}
            disabled={is_locked}
            onChange={production_declared_completion_date =>
              onChangeParams({...params, production_declared_completion_date})
            }
            errors={validationErrors.production_declared_completion_date}
          />
        </Col>
        <Col md={12} className="my-2">
          <TextInput
            label={t(...messages.deadlineAnnouncedFr())}
            disabled={is_locked}
            errors={validationErrors.deadline_announced_fr}
            value={params.deadline_announced_fr}
            onChange={deadline_announced_fr => {
              onChangeParams({...params, deadline_announced_fr});
            }}
          />
        </Col>
        <Col md={12} className="my-2">
          <TextInput
            label={t(...messages.deadlineAnnouncedEn())}
            disabled={is_locked}
            errors={validationErrors.deadline_announced_en}
            value={params.deadline_announced_en}
            onChange={deadline_announced_en => {
              onChangeParams({...params, deadline_announced_en});
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default QuotationProductionTab;
