import {useContext} from 'react';
import {selectDomain} from '.';
import {ProjectFeeTypeEnum} from '../../types/commercial-document';
import {CommercialProduct} from '../../types/commercial-product';
import selectItemOptions from './select-item-options';
import {createSelector} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';
import documentContext from '../../containers/document-context/document-context';

export default (line: Partial<CommercialProduct> & {id: ObjectId}) =>
  createSelector(
    [selectDomain, selectItemOptions(line.id)],
    (domain, options) => {
      const {doc} = useContext(documentContext);
      const projectFeeType = doc?.project_fee_type?.value;

      const globalDiscount = Number(domain.fields.globalDiscount ?? 0);
      const optionsCost = options.reduce((a, c) => a + Number(c.price_ht), 0);

      const variantPrice = Number(line.unit_price_ht ?? 0);

      const quantity = Number(line.quantity ?? 0);

      const discount = Number(line.discount ?? 0);

      const vat = Number(line.vat?.value ?? 0);

      // this value is calculated as commission on the product price
      // if invoice project fee type is SOURCING_FEE
      const discountCoe =
        projectFeeType === ProjectFeeTypeEnum.SOURCING_FEE
          ? discount / 100
          : 1 - discount / 100;

      const globalDiscountCoe = 1 - globalDiscount / 100;

      const totalAfterDiscount =
        (variantPrice * quantity + optionsCost) * discountCoe;

      const totalAfterGlobalDiscount = totalAfterDiscount * globalDiscountCoe;

      const taxes = totalAfterGlobalDiscount * (vat / 100);

      const priceIncTaxes = totalAfterGlobalDiscount + taxes;

      const ecoTax = line?.variant?.ecotax?.price ?? 0;

      return {
        taxes: taxes,
        vat: line.vat,
        priceIncTaxes: priceIncTaxes,
        priceExclTaxes: totalAfterDiscount,
        totalAfterGlobalDiscount,
        ecoTax,
      };
    }
  );
