import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {truncate} from 'lodash';

interface TooltipProps {
  cell?: string;
  id: ObjectId;
}
const TooltipCell = ({cell, id}: TooltipProps) => {
  return !cell ? (
    <span className="w-100 text-center"></span>
  ) : (
    <div id={`tooltip-${id}`}>
      <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
        {cell}
      </UncontrolledTooltip>
      {truncate(cell, {length: 20})}
    </div>
  );
};

export default TooltipCell;
