import React, {ReactNode} from 'react';
import {FC, useMemo} from 'react';
import {styles} from './constant';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import 'react-phone-input-2/lib/bootstrap.css';
import {FormFeedback, InputProps, Label} from 'reactstrap';
import useInputId from 'utils/use-input-id';

const PhoneInputText: FC<Props> = props => {
  const {
    value,
    errors,
    onChange,
    label,
    disabled,
    id: inputId,
    containerStyle,
    required,

    ...rest
  } = props;

  const {i18n} = useTranslation();
  const currentLocale = i18n.language;

  const id = useInputId(inputId);

  return (
    <TextInput
      type="text"
      label={label}
      required={required}
      errors={errors}
      onChange={onChange}
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
};

interface Props extends Omit<InputProps, 'onChange'> {
  value?: string;
  id?: string;
  onChange: (text: string) => void;
  errors?: string | string[];
  errorMessageStyle?: any;
  label?: string | ReactNode;
  required?: boolean;
}

export default PhoneInputText;
