import {api} from 'services/api';
import {ProductAttachment} from 'types/attachements';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateAttachments = api.injectEndpoints({
  endpoints: builder => ({
    updateAttachments: builder.mutation<Response, VariantAttachmentsRequest>({
      query: params => ({
        method: 'POST',
        url: `/products/${params.id}/variants/${params.variant}/attachments`,
        body: {
          id: params.id,
          variant: params.variant,
          deleted_records: params.deleted_records,
          attachements: params.attachments.map(x => ({
            id: x.id,
            name: x.name,
            file_id: x.file?.id,
            date: x.date,
            in_catalog: params.in_catalog === x.id,
            type: x.type?.value,
          })),
        },
      }),
      // @ts-expect-error
      invalidatesTags: result => {
        return result ? [{type: 'attachments', id: result?.data.id}] : [];
      },
    }),
  }),
});

export type VariantAttachmentsRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  in_catalog?: ObjectId;
  attachments: ProductAttachment[];
  deleted_records: ObjectId[];
};

type Response = ResponseData<ProductVariant> & {in_catalog?: ObjectId};

export const {useUpdateAttachmentsMutation} = updateAttachments;
