import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ObjectId} from 'types/object-id';
import TableLocal from 'features/table/table-local';
import columns from './columns';

interface Props {
  id?: ObjectId;
  allItems: any[];
}

const TransferTable: FC<Props> = props => {
  const {allItems} = props;

  const {t} = useTranslation();

  const costumeAllItems = useMemo(
    () =>
      allItems?.map((item: any) => {
        return {
          ...item,
          name: item.custom_name,
          sku: item?.variant?.sku,
        };
      }),
    [allItems]
  );

  const handleGetSubRow = (row: any) => row.components;
  const handleSelect = (row: any) => row.uuid;

  return (
    <div className="table-responsive">
      <TableLocal
        columns={columns}
        data={costumeAllItems}
        name="transfer-to-supplier-order"
        otherParams={{
          delimiter: '|',
        }}
        getSubRow={handleGetSubRow}
        getRowId={handleSelect}
        enableSubRowSelection={false}
      ></TableLocal>
    </div>
  );
};

export default TransferTable;
