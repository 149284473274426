import {createAsyncThunk} from '@reduxjs/toolkit';
import {AuthResponse} from 'services/api/response-types';
import {saveAuthState, StoredAuthState} from 'services/auth-service';

const persistAuthState = createAsyncThunk(
  'auth/persist',
  async (authResponse: AuthResponse) => {
    const response = {
      user: authResponse.data,
      accessToken: authResponse.token,
    } as StoredAuthState;
    const result = saveAuthState(response);
    return result;
  }
);

export default persistAuthState;
