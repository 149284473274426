import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CardHeader, CardSubtitle, CardTitle, Col, Row} from 'reactstrap';
import messages from '../messages';
import ItemPicker from 'components/item-picker';
import {IPhasesForm} from '../use-phases-form';
import Button from 'components/button';
import {Plus} from 'react-feather';
import {GrandTotalRecap} from './grand-total-recap';
import TextInput from 'components/text-input';
import {ProjectPhasesEnum} from 'features/studio/projects/constants/project-phases';
import Can from 'features/hylian-shield/can';

const HeaderSection: FC<Props> = ({
  params,
  options,
  input,
  subject,
  handleAddInput,
  handleInputChange,
}) => {
  const {t} = useTranslation();

  return (
    <CardHeader>
      <CardTitle tag="h1">{t(...messages.phasesAndHonoraria())}</CardTitle>
      <GrandTotalRecap phases={params.phases} />
      <Can action="edit" subject={subject}>
        <CardSubtitle tag="h4" className="mt-4">
          {t(...messages.addNewPhase())}
        </CardSubtitle>
        <Row className="mb-2">
          <Col md={4}>
            <ItemPicker
              options={options.phases}
              value={input}
              label={t(...messages.phases())}
              onChange={handleInputChange}
            />
          </Col>
          {input.id === ProjectPhasesEnum.OTHERS && (
            <Col md={4}>
              <TextInput
                value={input?.other_type_name}
                label={t(...messages.specifyPhaseName())}
                onChange={handleInputChange}
              />
            </Col>
          )}
          <Col lg={3} md={3} className="d-flex">
            <Button
              color="important"
              disabled={
                !input.id ||
                (input.id === ProjectPhasesEnum.OTHERS &&
                  !input.other_type_name)
              }
              className="d-inline-block align-self-end"
              onClick={handleAddInput}
            >
              <Plus size={14} /> {t(...messages.addToList())}
            </Button>
          </Col>
        </Row>
      </Can>
    </CardHeader>
  );
};

type Props = IPhasesForm;

export default HeaderSection;
