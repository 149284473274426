import React, {useMemo} from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import './style.scss';
import {useActionPricingForm} from './use-action-pricing-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import Button from 'components/button';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, Col, FormGroup, Row} from 'reactstrap';
import {Input, Label, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const _options = [
  {id: 'PERCENT', name: 'percent'},
  {id: 'AMOUNT', name: 'amount'},
];

const PricingActionForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    toggleVariant,
    toggleSelectAll,
    selectAll,
    selectedVariants,
    validationErrors,
    decrease,
    setDecrease,
    errorMessage,
    params,
    updateParams,
    variants,
  } = useActionPricingForm(onDismiss);

  const options = useMemo(() => {
    return _options.map(x => ({
      id: x.id,
      name: t(`pricingActionModal.${x.name}`),
    }));
  }, []);
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <Row className="mb-3">
          <div className="mb-3">{t(...messages.increaseText())}</div>

          <Col md={7}>
            <TextInput
              label={t(...messages.value())}
              type="number"
              errors={validationErrors.value}
              value={params.value}
              onChange={x => updateParams('value', x)}
            />
          </Col>
          <Col md={4}>
            <ItemPicker
              label={t(...messages.increaseType())}
              options={options}
              errors={validationErrors.increase_type}
              value={params.increase_by}
              onChange={x => updateParams('increase_by', x)}
            />
          </Col>
        </Row>
        <Row>
          <div className="mb-3">{t(...messages.selectVariants())}</div>
          <div className="mb-3">
            <FormGroup check>
              <Input
                type="checkbox"
                id={`clone-variant-all`}
                checked={selectAll}
                onChange={toggleSelectAll}
              />
              <Label check for={`clone-variant-all`}>
                {t(...messages.selectAll())}
              </Label>
            </FormGroup>
          </div>
          <div className="scroll-modal-pricing">
            {variants?.map(v => (
              <Card
                key={v.variant.id}
                color={clsx({primary: selectedVariants[v.variant.id]})}
                outline
              >
                <CardBody className="p-2">
                  <FormGroup className="" check>
                    <Input
                      type="checkbox"
                      id={`clone-variant-${v.variant.id}`}
                      checked={selectedVariants[v.variant.id]}
                      onChange={() => toggleVariant(v)}
                    />
                    <Label
                      check
                      for={`clone-variant-${v.variant.id}`}
                      className="text-uppercase"
                    >
                      {v.variant.name}
                    </Label>
                  </FormGroup>
                </CardBody>
              </Card>
            ))}
          </div>
          <div>
            <div className="row mb-4">
              <div className="col">
                <div>
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                </div>
                <div className="mt-3 d-grid"></div>
              </div>
            </div>
          </div>
          <div>
            <div className="row mb-4">
              <div className="col">
                <div className="mt-3 d-grid"></div>
              </div>
            </div>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default PricingActionForm;
