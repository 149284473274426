import {VariantToOrder} from 'features/stock/products-stock-grid/containers/product-alert-details-table/types';
import {Order} from '../../types/order';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeOrder = api.injectEndpoints({
  endpoints: builder => ({
    storeOrder: builder.mutation<Response, StoreOrderRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `orders`,
          body: {
            document_type: params.document_type?.value,
            directory_id: params.client?.id,
            commercial_type_id: params.type?.id,
            document_category_id: params.category?.id,
            origin_id: params.origin?.id,
            language: params.language?.value,
            order_date: params.order_date,
            due_date: params.due_date,
            supplier_id: params.supplier?.id,
            supplier_commercial_type_id: params.supplier_type?.id,
            imh_stock_order: params.is_order_stock,
            project_fee_type: params.projectFeesType?.value,
            products: params.products,
          },
        };
      },
    }),
  }),
});

export type StoreOrderRequest = {
  client?: Directory;
  category?: Option;
  supplier?: Directory;
  supplier_type?: Option;
  document_type?: Option;
  is_order_stock?: boolean;
  type?: Option;
  origin?: Option;
  language?: any;
  order_date?: string;
  due_date?: string;
  projectFeesType?: Option;
  products?: VariantToOrder[];
};

type Response = ResponseData<Order>;

export const {useStoreOrderMutation} = storeOrder;
