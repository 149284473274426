import React from 'react';
import CreditsActions from './actions';
import StatusBadge from 'components/status-badge';
import TooltipCell from 'features/acounting/credits/components/tooltip-cell';
import {CreditCompact} from 'features/acounting/types/credit';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';

const columns: ColumnDef<CreditCompact>[] = [
  {
    header: r => <Trans i18nKey="creditsPage.client" />,
    accessorFn: row => (
      <Link to={`/dashboard/directory/${row.directory_id}`} target="_blank">
        <b>{row.display_name}</b>
      </Link>
    ),

    id: 'display_name',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="creditsPage.credit" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/invoices/${row.invoice_credit_id}/details`}
        target="_blank"
      >
        <b> {row.invoice_credit_ref}</b>
      </Link>
    ),
    id: 'invoice_credit_ref',
    cell: r => r.getValue(),

    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="creditsPage.invoice" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/invoices/${row.invoice_origin_id}/details`}
        target="_blank"
      >
        <b>{row.invoice_origin_document_ref}</b>
      </Link>
    ),
    id: 'invoice_origin_document_ref',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="creditsPage.amount" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.total_credit}} />
    ),
    id: 'total_credit',
    cell: r => r.getValue(),

    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="creditsPage.solde" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.solde}} />
    ),
    id: 'solde',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="creditsPage.amountUsed" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.total_amount_used}} />
    ),
    id: 'total_amount_used',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="creditsPage.usedon" />,
    accessorFn: row =>
      row.credit_usages && row.credit_usages.length > 0 ? (
        <div className="d-flex w-100  flex-column ">
          {row.credit_usages.map(relatedDocument => (
            <TooltipCell
              cell={relatedDocument}
              key={`tooltip-used-on-${relatedDocument.id}`}
            />
          ))}
        </div>
      ) : (
        <span className="w-100 text-center">-</span>
      ),
    id: 'used_on',
    cell: r => r.getValue(),

    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="creditsPage.status" />,
    accessorFn: row => <StatusBadge status={row.status} />,
    id: 'status',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="productsPage.action" />,
    accessorFn: row => <CreditsActions creditData={row} />,
    id: 'action',
    enableSorting: false,
    cell: r => r.getValue(),
  },
];

export default columns;
