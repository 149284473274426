import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  carbonFootprint: () => __(translations.productsOtherPage.carbonFootprint, 'Carbon footprint'),
  hsCode: () => __(translations.productsOtherPage.hsCode, 'H.S. Code'),
  maintenanceInstruction: () => __(translations.productsOtherPage.maintenanceInstruction, 'Maintenance instructions'),
  setup: () => __(translations.productsOtherPage.setup, 'Setup'),
  inspiration: () => __(translations.productsOtherPage.inspiration, 'Inspiration'),
};
