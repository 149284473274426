import PreparerOrderModal from '../containers/preparer-modal/preparer-modal';
import TransferOrderModal from '../containers/transfer-modal/transfer-modal';
import TransferStockOrderModal from '../containers/transfer-stock-order-modal/transfer-modal';
import TransferToSupplierOrderModal from '../containers/transfer-to-supplier-order-modal/transfer-modal';
import OrderArchiveActionConfirm from './actions-tab/archive';
import OrderDeleteActionConfirm from './actions-tab/delete';
import OrderDuplicatActionConfirm from './actions-tab/duplicate';
import OrderSendEmailAction from './actions-tab/send-email';
import OrderSendLabelAction from './actions-tab/send-label';
import {MODAL} from 'features/modal-notch/constants';

export default {
  [MODAL.ORDER_DUPLICAT_ACTIONS_MODAL]: OrderDuplicatActionConfirm,
  [MODAL.ORDER_DELETE_ACTIONS_MODAL]: OrderDeleteActionConfirm,
  [MODAL.ORDER_SEND_EMAIL_ACTIONS_MODAL]: OrderSendEmailAction,
  [MODAL.ORDER_SEND_LABEL_ACTIONS_MODAL]: OrderSendLabelAction,
  [MODAL.ORDER_ARCHIVE_ACTIONS_MODAL]: OrderArchiveActionConfirm,
  [MODAL.TRANSFER_ORDER_MODAL]: TransferOrderModal,
  [MODAL.TRANSFER_TO_SUPPLIER_ORDER_MODAL]: TransferToSupplierOrderModal,
  [MODAL.TRANSFER_STOCK_ORDER_MODAL]: TransferStockOrderModal,
  [MODAL.PREPERER_ORDER]: PreparerOrderModal,
};
