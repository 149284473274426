import React, {FC, Fragment, useMemo} from 'react';
import Product from './product';
import {useDispatch} from 'react-redux';
import {actions} from '../../store';
import {useSelector} from 'react-redux';
import selectIsFamilyExpanded from '../../store/selectors/select-is-family-expanded';
import {useGetProductsInStockQuery} from '../../store/endpoints/get-products-stock';
import {ObjectId} from 'types/object-id';
import clsx from 'clsx';
import {columnClassNames} from '../../constants/grid-classnames';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import {useParams} from 'react-router';
import Currency from 'components/currency/currency';

interface Props {
  collection: {
    id: ObjectId;
    name: string;
    stock: number[];
  };
  row: {
    id: ObjectId;
    name: string;
    stock: number[];
  };
}
const ProductFamily: FC<Props> = props => {
  const {row, collection} = props;
  const {type} = useParams();

  const dispatch = useDispatch();

  const isExpanded = useSelector(selectIsFamilyExpanded(row.id));

  const handleExpand = () => {
    dispatch(actions.expandFamily(row));
  };

  const {data, isFetching} = useGetProductsInStockQuery(
    {id: row.id, collectionId: collection.id, type: type || ''},
    {skip: !isExpanded}
  );

  const products = useMemo(() => {
    if (data) {
      return data.data;
    }
    return [];
  }, [data]);

  return (
    <Fragment>
      {/* parentRow */}
      <tr
        onClick={handleExpand}
        className={clsx({
          'highlighter-level--2': true,
          'cursor-state--idle': !isFetching,
          'cursor-state--loading': isFetching,
        })}
      >
        <td className="p-2">{`${row.name}`}</td>
        {row.stock.map((x, index) => {
          return (
            <td
              key={`product-family-${x}--${index}`}
              className={clsx({'p-2': true, [columnClassNames[index]]: true})}
            >
              {index === 10 || index === 11 || type === 'quantity' ? (
                <NaturalNumber value={x} />
              ) : (
                <Currency value={x} />
              )}
            </td>
          );
        })}
      </tr>
      {/* children */}
      {!isFetching &&
        isExpanded &&
        products.map((p: any) => (
          <Product
            key={p.id}
            product={p}
            family={row}
            collection={collection}
          />
        ))}
    </Fragment>
  );
};

export default ProductFamily;
