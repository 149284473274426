import React, {useContext} from 'react';
import messages from './messages';
import Tail from './tail';
import TimelineItem from './timeline-item';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import {MODAL} from 'features/modal-notch/constants';
import useAfterLife from 'features/modal-notch/soul-eater/use-after-life';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Row} from 'reactstrap';
import {Col} from 'reactstrap';
import {useGetDirectoryInteractionsQuery} from 'store/directory/endpoints/get-directory-interactions';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import showModal from 'store/ui/actions/show-modal';
import {Directory} from 'types/directory';
import denormalizeObject from 'utils/denormalize-object';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const InteractionsTab = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const {id} = useParams();

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data as Directory;

  const handleAddInteraction = () => {
    dispatch(showModal(MODAL.INTERACTION_FORM, {directory}));
  };

  const {data: response} = useGetDirectoryInteractionsQuery({directory_id: id});

  const {saints: aliveInteractions} = useAfterLife(
    'interactions',
    denormalizeObject(response?.data)
  );

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          <SectionSeparator
            title={t(...messages.pageTitle(), {
              count: aliveInteractions.length,
            })}
          />
        </Col>
        <Col md="auto" className="">
          {/* y */}
          <Button
            disabled={directory.is_archived || !canEdit}
            color="important"
            className="me-3"
            onClick={handleAddInteraction}
          >
            <Icon name="plus" className="me-1" size={18} />
            {t(...messages.add())}
          </Button>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xl={10}>
          <div className="timeline">
            <div className="timeline-container">
              <div className="timeline-end">
                <p className="font-size-11">{t(...messages.today())}</p>
              </div>
              <div className="timeline-continue">
                {aliveInteractions.map((x, i) => (
                  <TimelineItem
                    key={x.id}
                    direction={i % 2 === 0 ? 'left' : 'right'}
                    interaction={x}
                    directory={directory}
                    index={i}
                  />
                ))}
              </div>
              <div className="timeline-start">
                <p>{t(...messages.creation())}</p>
              </div>
              <Tail />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InteractionsTab;
