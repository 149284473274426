import React, {FC} from 'react';
import messages from '../messages';
import {CustomOrderFilters} from './types';
import AsyncItemPicker from 'components/async-item-picker';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Label, Row} from 'reactstrap';
import DateRangeInput from 'components/date-range-input';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<CustomOrderFilters>;
  onChangeFilters: (key: CustomOrderFilters, value: any) => void;
  onClearFilters?: () => void;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <SearchSection
      onChangeFilters={onChangeFilters}
      onClearFilters={onClearFilters}
      filters={filters}
    >
      <Row className="mb-3">
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t('customerOrderPage.orderDate')}
              value={filters['filter[order_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[order_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(messages.estimatedDate())}
              value={filters['filter[estimated_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[estimated_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/order-statuses?document_type=supplier"
              className="mb-1"
              getOptionLabel={(o: any) => o.label}
              getOptionValue={(o: any) => o.value}
              label={t(messages.status())}
              value={filters['filter[status]']}
              onChange={o => onChangeFilters('filter[status]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/commercial-types?supplier_order"
              defaultValue
              getDefaultOptions={(meta: any) => meta?.default_index}
              className="mb-1"
              label={t(...messages.type())}
              value={filters['filter[commercial_type_id]']}
              onChange={o => onChangeFilters('filter[commercial_type_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/users"
              className="mb-1"
              label={t('createDirectoryPage.referentContact')}
              value={filters['filter[user_id]']}
              onChange={o => onChangeFilters('filter[user_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <AsyncItemPicker
            endPoint="data/suppliers-type-company"
            getOptionLabel={(directory: any) => directory?.display_name}
            label={t('createSupplierOrderPage.supplier')}
            value={filters['filter[supplier_id]']}
            onChange={o => onChangeFilters('filter[supplier_id]', o)}
          />
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_after_sale_service"
                checked={!!filters['filter[is_after_sale_service]']}
                onChange={(o: any) =>
                  onChangeFilters(
                    'filter[is_after_sale_service]',
                    o.target.checked
                  )
                }
              />
              <Label
                className="form-check-label"
                htmlFor="is_after_sale_service"
              >
                {t('invoicesPage.isAfterSaleService')}
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
