import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CardHeader, CardSubtitle, CardTitle} from 'reactstrap';
import messages from '../messages';
import Currency from 'components/currency/currency';
import ISourcingFeesForm from '../use-sourcing-fees-form';

const HeaderSection: FC<Props> = ({grand_total_ht, grand_total_commission}) => {
  const {t} = useTranslation();

  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <div>
          <CardTitle tag="h5">{t(...messages.sourcingFees())}</CardTitle>
        </div>
        <div className="d-flex gap-4">
          <div>
            <CardTitle tag="h5">{t(...messages.totalHT())}</CardTitle>
            <CardSubtitle tag="p">
              <Currency value={grand_total_ht} />
            </CardSubtitle>
          </div>
          <div>
            <CardTitle tag="h5">{t(...messages.commission())}</CardTitle>
            <CardSubtitle tag="p">
              <Currency value={grand_total_commission} />
            </CardSubtitle>
          </div>
        </div>
      </div>
    </CardHeader>
  );
};

type Props = ISourcingFeesForm;

export default HeaderSection;
