import React, {FC} from 'react';
import Currency from 'components/currency/currency';
import {IDeposit} from 'types/project';
import messages from '../messages';
import {useTranslation} from 'react-i18next';

export const TotalRecap: FC<{deposits: IDeposit[]}> = ({deposits}) => {
  const {t} = useTranslation();

  const {honoraria, fees} = (() => {
    const sum = (key: keyof Pick<IDeposit, 'honoraria' | 'fees'>) =>
      deposits.reduce((acc, item) => {
        return acc + Number(item[key]);
      }, 0);

    return {
      honoraria: sum('honoraria'),
      fees: sum('fees'),
    };
  })();

  return (
    <div className="d-flex gap-5">
      <div>
        <span className="caption-top" style={styles.caption}>
          {t(...messages.honoraria())}
        </span>
        <br />
        <span>
          <Currency value={honoraria} />
        </span>
      </div>
      <div>
        <span className="caption-top" style={styles.caption}>
          {t(...messages.fees())}
        </span>
        <br />
        <span>
          <Currency value={fees} />
        </span>
      </div>
      <div>
        <span className="caption-top" style={styles.caption}>
          Total
        </span>
        <br />
        <span>
          <Currency value={fees + honoraria} />
        </span>
      </div>
    </div>
  );
};

const styles = {
  caption: {
    fontSize: '12px',
  },
};
