import React from 'react';
import {RelatedDocument} from 'features/acounting/types/overpayment';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';

interface TooltipProps {
  cell: RelatedDocument;
}

const TooltipCell = ({cell}: TooltipProps) => {
  const {t} = useTranslation();

  const link =
    cell.documentable_type === 'Invoice'
      ? `/dashboard/invoices/${cell.documentable_id}/details`
      : `/dashboard/orders/${cell.documentable_id}/details`;

  return (
    <div id={`tooltip-${cell.id}`} className="py-1">
      <UncontrolledTooltip placement="top" target={`tooltip-${cell.id}`}>
        <span>
          <Trans i18nKey="numbers.currency" values={{val: cell.amount_used}} />{' '}
          {cell.payment && ` | ${t(`creditsPage.${cell.payment.type}`)}`}
        </span>{' '}
      </UncontrolledTooltip>
      <Link to={link} target="_blank">
        <span className="me-1">{cell.document_ref}</span>
      </Link>
    </div>
  );
};

export default TooltipCell;
