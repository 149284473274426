import React, {FC} from 'react';
import {NAV_LINKS} from 'features/stock/constants/monitoring-listing-nav-links';
import {useTranslation} from 'react-i18next';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {MonitoringListingTypes} from 'features/stock/types/monitoring-listing-types';
import './project-tab-pane.styles.scss';

const MonitoringListingTabPane: FC<Props> = ({type, setType}) => {
  const {t} = useTranslation();

  const handleTabPane = (type: MonitoringListingTypes) => {
    setType(type);
  };

  return (
    <Nav>
      {NAV_LINKS.map(x => (
        <NavItem key={x.title} active={type === x.type}>
          <NavLink
            href="#"
            active={type === x.type}
            onClick={() => handleTabPane(x.type)}
          >
            {t(`monitoringListingPage.${x.title}`)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

type Props = {
  type: MonitoringListingTypes;
  setType: (arg: MonitoringListingTypes) => void;
};

export default MonitoringListingTabPane;
