import french from '../assets/images/flags/french.jpg';
import usFlag from '../assets/images/flags/us.jpg';

const languages: Object = {
  fr: {
    label: 'Français',
    flag: french,
  },
  en: {
    label: 'English',
    flag: usFlag,
  },
};

export default languages;
