import {AuthState} from '../types';
import {AnyAction, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {AuthResponse} from 'services/api/response-types';
import {accountApi} from 'store/account/endpoints';
import {updateAvatarEndpoint} from 'store/account/endpoints/update-avatar';
import {WritableDraft} from 'types/writable-draft';

/**
 * Matches all the endpoints that
 * update the user object
 *
 */
const updateUserMatcher = isAnyOf(
  updateAvatarEndpoint.endpoints.updateAvatar.matchFulfilled,
  accountApi.endpoints.updateProfile.matchFulfilled
);

/**
 * Updates the user object
 *
 */
const updateUserReducer = (
  state: WritableDraft<AuthState>,
  action: PayloadAction<AuthResponse> | AnyAction
) => {
  state.user = action.payload.data;
};

export const updateUser = {
  matcher: updateUserMatcher,
  reducer: updateUserReducer,
};
