import React, {FC} from 'react';
import {useCustomizationsForm} from './use-customizations-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import {Button, Col, Row} from 'reactstrap';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';

interface Props {
  customization?: any;
  index?: number;
  removeCustomization?: any;
  updateCustomization?: any;
  validationErrors?: any;
}

const OtherOption: FC<Props> = props => {
  const {
    customization,
    index,
    validationErrors,
    updateCustomization,
    removeCustomization,
  } = props;

  return (
    <Row className="mb-3">
      <Col>
        <AsyncItemPicker
          label="Option"
          endPoint="data/customization-categories"
          type="text"
          errors={
            validationErrors[
              `customizations.${index}.customization_category_id`
            ]
          }
          value={customization?.customization_category}
          onChange={x =>
            updateCustomization(customization, 'customization_category', x)
          }
        />
      </Col>

      <Col>
        <AsyncItemPicker
          label="Sous-option"
          key={`customization-options-${index}-${customization?.customization_category?.id}`}
          disabled={!customization?.customization_category}
          endPoint={`data/customization-categories/${customization?.customization_category?.id}/families`}
          errors={
            validationErrors[`customizations.${index}.customization_family_id`]
          }
          value={customization?.customization_family}
          onChange={x =>
            updateCustomization(customization, 'customization_family', x)
          }
        />
      </Col>
      <Col md="auto">
        <EmptyLabel />
        <Button
          color=""
          className="p-1"
          onClick={() => removeCustomization(customization.id)}
        >
          <Icon name="trash-2" size={16} className="text-danger me-1" />
        </Button>
      </Col>
    </Row>
  );
};

export default OtherOption;
