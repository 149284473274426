import clsx from 'clsx';
import React, {FC, Fragment} from 'react';
import {Link, useParams} from 'react-router-dom';
import {ObjectId} from 'types/object-id';
import {columnClassNames} from '../../constants/grid-classnames';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import {useDispatch} from 'react-redux';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import Currency from 'components/currency/currency';

interface Props {
  productId: ObjectId;
  row: {
    id: ObjectId;
    name: string;
    stock: number[];
  };
}

const ProductVariant: FC<Props> = props => {
  const {row, productId} = props;

  const dispatch = useDispatch();
  const {type} = useParams();

  const handlePreview = () => {
    dispatch(
      showModal(MODAL.VARIANT_PREVIEW, {
        id: row.id,
        name: row.name,
        productId,
      })
    );
  };

  return (
    <Fragment>
      <tr className="highlighter-level--4">
        <td>
          <Link
            target="_blank"
            to={`/dashboard/products/${productId}/variants/${row.id}`}
          >
            {row.name}
          </Link>
          <Button color="" className="text-primary" onClick={handlePreview}>
            <Icon name="eye" size={15} />
          </Button>
        </td>
        {row.stock.map((x, index) => {
          return (
            <td
              key={`product-variant-${x}--${index}`}
              className={clsx({'p-2': true, [columnClassNames[index]]: true})}
            >
              {index === 10 || index === 11 || type === 'quantity' ? (
                <NaturalNumber value={x} />
              ) : (
                <Currency value={x} />
              )}
            </td>
          );
        })}
      </tr>
    </Fragment>
  );
};

export default ProductVariant;
