import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.profileTab.title, 'Profile'),
  submit: () => __(translations.profileTab.submit, 'Submit'),
  phoneNumber: () => __(translations.profileTab.phoneNumber, 'Phone number'),
  lastName: () => __(translations.profileTab.lastName, 'Last name'),
  address: () => __(translations.profileTab.address, 'Address'),
  firstName: () => __(translations.profileTab.firstName, 'First name'),
  dateOfBirth: () => __(translations.profileTab.dateOfBirth, 'Date of birth'),
  changesCommited: () => __(translations.alertCloak.changesCommited, 'Your changes has been saved successfully!'),
};
