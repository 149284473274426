import React from 'react';
import messages from './messages';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Row, Col} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import LocationAvailability from './location-availability/location-availability';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Can from 'features/hylian-shield/can';

const StockTab = () => {
  const dispatch = useDispatch();

  const {id, variant} = useParams();

  const {data} = useSelector(selectVariant({id: variant, productId: id}));

  const handleAddMovement = () => {
    dispatch(
      showModal(MODAL.STOCK_MOVEMENT_FORM, {
        variantId: variant,
      })
    );
  };

  const {t} = useTranslation();

  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: data?.data.name?.toLocaleUpperCase(),
        })}
      />
      <Row>
        <Col>{!!variant && <LocationAvailability id={variant} />}</Col>
      </Row>
      <Can action="edit" subject="products">
        <Row>
          <Col className="text-end">
            <Button color="light" onClick={handleAddMovement}>
              <Icon name="plus" className="me-1" size={15} />
              {t(...messages.addStockMovement())}
            </Button>
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <Button
                color="light"
                onClick={() =>
                  dispatch(
                    showModal(MODAL.STOCK_MOVEMENT_LIST, {variantId: variant})
                  )
                }
              >
                <Icon name="list" className="me-1" size={15} />
                {t(...messages.viewStockMovements())}
              </Button>
            </div>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default StockTab;
