import React, {FC} from 'react';
import DataTable from './container/table';
import makeSelectEntityRequest from './slice/selectors/make-select-entity-request';
import {ListParams} from './types';
import {ColumnDef, Row} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectAppTheme} from 'store/layout/selectors';
import {ResourceName} from 'types/resource-name';
import {useMediaQuery} from 'usehooks-ts';
import useInitiateLocalTable from './hooks/use-initiate-local-table';

interface TableProps {
  data: any;
  routerParams?: boolean;
  name: ResourceName;
  subHeaderComponent?: React.ReactNode;
  otherParams?: ListParams;
  columns: ColumnDef<any>[];
  filtersComponent?: any;
  makeRowClassName?: (row: any) => string;
  getRowId?: (
    originalRow: any,
    index: number,
    parent?: Row<any> | undefined
  ) => string;
  getSubRow?: (originalRow: any, index: number) => any[] | undefined;
  enableSubRowSelection?: boolean;
}

const TableLocal: FC<TableProps> = props => {
  const {
    data,
    name,
    columns,
    makeRowClassName,
    getRowId,
    getSubRow,
    enableSubRowSelection,
  } = props;

  const {isLoading, errorMessage} = useSelector(makeSelectEntityRequest(name));

  const {t} = useTranslation();

  const theme = useSelector(selectAppTheme);

  const media = useMediaQuery('(max-width: 768px)');
  const {table} = useInitiateLocalTable(
    name,
    data,
    columns,
    getRowId,
    getSubRow,
    enableSubRowSelection
  );

  return (
    <>
      <DataTable makeRowClassName={makeRowClassName} table={table}></DataTable>
    </>
  );
};

export default TableLocal;
