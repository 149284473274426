import {STATUS} from 'constants/status';
import {ApiEndpointBuilder} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

const updateUserStatusEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Response, Request>({
    query: body => ({
      method: 'PUT',
      url: `users/${body.id}/status`,
      body,
    }),
  });
};

type Request = {
  id?: ObjectId;
  status: STATUS;
};

type Response = ResponseData<User>;

export default updateUserStatusEndpoint;
