import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Breadcrumbs from 'components/Common/Breadcrumb';
import messages from './messages';
import StockproductsInPreparationTabPane from './tab-pane';
import Table from 'features/table';
import columns from './columns';
import {StockProductsInPreparationStatusEnum} from 'features/stock/constants/stock-products-in-preparation-status-enum';

const StockProductsInPreparationPage = () => {
  const {t} = useTranslation();

  const [param, setParam] = useState<string>(
    StockProductsInPreparationStatusEnum.WAITING_FOR_DELIVERY
  );

  // hide actions column if param === DELIVERED
  const _columns = useMemo(() => {
    if (param === StockProductsInPreparationStatusEnum.DELIVERED)
      return columns.filter(cl => cl.id !== 'actions');

    return columns;
  }, [param]);

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},

            {title: t(...messages.title()), path: '#'},
          ]}
        />
      </Container>
      <StockproductsInPreparationTabPane setParam={setParam} param={param} />
      <Table
        columns={_columns}
        url={`stock/products-in-preparation?filter[status]=${param}`}
        name="stock-products-in-preparation"
      />
    </div>
  );
};

export default StockProductsInPreparationPage;
