import React, {Fragment} from 'react';
import messages from './messages';
import {useComponentsForm} from './use-components-tab';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import InputLikeContainer from 'features/commercial/documents/components/input-like-container/input-like-container';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Row, Col, Alert} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {ProductVariant} from 'types/product-variant';
import getVariantName from 'utils/row-getters/get-variant-name';
import Can from 'features/hylian-shield/can';

const ComponentsTab = () => {
  const {
    addComponent,
    components,
    errorMessage,
    isLoading,
    isSuccess,
    removeComponent,
    submit,
    updateComponent,
    validationErrors,
    filterOption,
  } = useComponentsForm();
  const {id, variant: variantId} = useParams();

  const {t} = useTranslation();
  const {data} = useSelector(selectVariant({id: variantId, productId: id}));
  const variant = data?.data as ProductVariant;

  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: variant?.name?.toLocaleUpperCase(),
        })}
      />

      {Object.values(components).map((p, i) => (
        <Row className="mb-3" key={p.id}>
          <Col>
            {variant.self_composed ? (
              <Fragment>
                <EmptyLabel />
                <InputLikeContainer className="bg-gray-light">
                  <Link
                    target="_blank"
                    to={`/dashboard/products/${variant?.product?.id}/variants/${variant?.id}/general`}
                  >
                    <span>{variant?.name}</span>
                  </Link>
                </InputLikeContainer>
              </Fragment>
            ) : (
              <AsyncItemPicker
                label={t(...messages.label())}
                disabled={variant?.self_composed}
                filterOption={filterOption}
                getOptionLabel={getVariantName}
                endPoint="data/components"
                errors={validationErrors[`components.${i}.variant_id`]}
                value={p.variant}
                onChange={x => updateComponent(p, 'variant', x)}
              />
            )}
          </Col>
          <Col md={3}>
            <TextInput
              label={t(...messages.quantity())}
              disabled={true}
              type="text"
              errors={validationErrors[`components.${i}.quantity`]}
              value={p.quantity}
              onChange={x => updateComponent(p, 'quantity', x)}
            />
          </Col>

          {variant?.self_composed ? null : (
            <Can action="edit" subject="products">
              <Col className="align-items-end d-flex" md={1}>
                <Button
                  color="link"
                  className="text-danger"
                  onClick={() => removeComponent(p.id)}
                >
                  <Icon name="trash-2" size={18} />
                </Button>
              </Col>
            </Can>
          )}
        </Row>
      ))}

      <Can action="edit" subject="products">
        {variant?.self_composed ? null : (
          <Row className="mb-3">
            <Col md={4}>
              <Button color="light" onClick={addComponent}>
                <Icon name="plus" className="me-1" size={18} />
                {t(...messages.addComponent())}
              </Button>
            </Col>
          </Row>
        )}
      </Can>
      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className=" mb-4">
            <AppBarActions>
              <Button
                disabled={data?.data?.self_composed}
                isLoading={isLoading}
                color="important"
                onClick={submit}
              >
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default ComponentsTab;
