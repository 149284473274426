import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {NAV_LINKS} from 'features/acounting/overpayments/constants/nav-links';
import {OverpaymentsStatuses} from 'features/acounting/overpayments/types';
import './tab-pane.styles.scss';

const OverpaymentsTabPane: FC<Props> = ({status, setStatus}) => {
  const {t} = useTranslation();

  const handleTabPane = (status: OverpaymentsStatuses) => {
    setStatus(status);
  };

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4 tab-pane">
      {NAV_LINKS.map((x, index) => (
        <NavItem key={index} active={status === x.status}>
          <NavLink
            href="#"
            active={status === x.status}
            onClick={() => handleTabPane(x.status)}
          >
            {t(...x.title())}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

type Props = {
  status: OverpaymentsStatuses;
  setStatus: (arg: OverpaymentsStatuses) => void;
};

export default OverpaymentsTabPane;
