import {getAllSettingsEndpoint} from './endpoints/get-all-settings';
import {put, takeLatest} from 'redux-saga/effects';
import {authApi} from 'store/auth/endpoints';

function* fetchAppSettings() {
  // @ts-expect-error actually saga does not recognize this type
  const promise = yield put(getAllSettingsEndpoint.initiate());
  promise.unsubscribe();
}

export function* globalSaga() {
  yield takeLatest(authApi.endpoints.refresh.matchFulfilled, fetchAppSettings);
  yield takeLatest(authApi.endpoints.login.matchFulfilled, fetchAppSettings);
}
