import {Invoice} from '../../types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedInvoiceFromResource} from './show-invoice';

const updateInvoiceDetails = api.injectEndpoints({
  endpoints: builder => ({
    updateInvoiceDetails: builder.mutation<Response, UpdateInvoiceRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `invoices/${params.id}/commercial-products`,
          body: {
            id: params.id,
            ...params,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const i = (await queryFulfilled).data?.data;
          dispatch(updateCachedInvoiceFromResource(i));
        } catch {}
      },
    }),
  }),
});

export type UpdateInvoiceRequest = {
  id?: ObjectId;
};

type Response = ResponseData<Invoice>;

export const {useUpdateInvoiceDetailsMutation} = updateInvoiceDetails;
