import {
  removeCachedVariantFromResource,
  updateCachedVariantFromResource,
} from './get-product-variants';
import {UpdateProductRequest} from './update-product';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const archiveVariant = api.injectEndpoints({
  endpoints: builder => ({
    archiveVariant: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'DELETE',
          url: `product-variants/${params.id}`,
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          (await queryFulfilled).data.data;
          dispatch(removeCachedVariantFromResource(arg));
        } catch {}
      },
    }),
  }),
});

type Request = ProductVariant;

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useArchiveVariantMutation} = archiveVariant;
