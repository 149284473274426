import React from 'react';
import UserActions from './user-actions';
import UserStatus from './user-status';
import {ColumnDef} from '@tanstack/table-core';
import {Trans} from 'react-i18next';
import {User} from 'types/user';

/**
 * User list columns
 *
 */
const columns: ColumnDef<User>[] = [
  {
    header: r => <Trans i18nKey="usersPage.avatar" />,
    accessorFn: r => (
      <img
        src={r.profile?.avatar?.square}
        className="user-avatar rounded rounded-5"
      />
    ),
    cell: r => r.getValue(),
    meta: {
      cellCostumClass: 'ps-4 ',
      costumClass: 'text-center ps-2',
    },
    enableSorting: false,
    id: 'id',
  },
  {
    header: r => <Trans i18nKey="usersPage.fullName" />,
    cell: row => row.getValue(),
    accessorFn: row => row.profile?.full_name,
    id: 'full_name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="usersPage.role" />,
    accessorFn: row => row.role?.label,
    cell: row => row.getValue(),
    id: 'role',
    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="usersPage.email" />,
    accessorFn: row => <a href={`mailto:${row.email}`}>{row.email}</a>,
    cell: row => row.getValue(),
    id: 'email',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="usersPage.status" />,
    accessorFn: row => <UserStatus user={row} />,
    cell: row => row.getValue(),
    enableSorting: true,
    id: 'status',
  },
  {
    header: r => <Trans i18nKey="usersPage.action" />,
    enableSorting: false,
    accessorFn: row => <UserActions user={row} />,
    cell: row => row.getValue(),
    id: 'action',
  },
];

export default columns;
