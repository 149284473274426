import Stocklocation from 'features/stock/types/stock-location';
import StockSublocation from 'features/stock/types/stock-sub-location';
import {api} from 'services/api';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeSubLocation = api.injectEndpoints({
  endpoints: builder => ({
    storeSubLocation: builder.mutation<Response, StoreSubLocationRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `stock-sub-locations`,
          body: {
            name: params.name,
            stock_location_id: params.stock_location?.id,
          },
        };
      },
    }),
  }),
});

export type StoreSubLocationRequest = {
  name: string;
  stock_location?: Option;
};

type Response = ResponseData<StockSublocation>;

export const {useStoreSubLocationMutation} = storeSubLocation;
