import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import {IRegroupedTeam} from '../types';

const TeamFilters: FC<Props> = ({regroupedTeam}) => {
  const {t} = useTranslation();

  return (
    <Row className="align-items-center ">
      <Col md={12}>
        <div>
          <div className="d-flex flex-wrap gap-2">
            {regroupedTeam.map((element, index) => (
              <Button
                key={index}
                color="transparent"
                active={false}
                size="sm"
                className="border-1 border-bottom-0 font-size-14 text-center px-3 py-2 border-gray-light"
              >
                {t(
                  `editProjectTeamSection.${element.type.toLocaleLowerCase()}`
                )}
                <span
                  className="badge ms-1 font-size-16 text-white bg-important "
                  style={{backgroundColor: '#c9f2d6'}}
                >
                  {element.data.length}
                </span>
              </Button>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

type Props = {
  regroupedTeam: IRegroupedTeam[];
};

export default TeamFilters;
