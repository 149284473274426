import React, {useEffect, useRef, useCallback} from 'react';
import withRouter from '../../../src/components/Common/withRouter';
import giftBox from '../../assets/images/giftbox.png';
import GlobalSearch from './GlobalSearch';
import SidebarItem from './sidebar-item';
import {sidebarRoutes} from './sidebar-routes';
import Icon from '@ailibs/feather-react-ts';
import {application} from 'constants/application';
import MetisMenu from 'metismenujs';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Can from 'features/hylian-shield/can';
import {isMobile} from 'utils/is-mobile';
import clsx from 'clsx';

const SidebarContent = (props: any) => {
  const ref: any = useRef();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = `/${application.baseUrl + path.pathname}`;
    let matchingMenuItem = null;
    const ul: any = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const appVersion = `v${application.version}`;

  return (
    <React.Fragment>
      <SimpleBar style={{maxHeight: '100%'}} ref={ref}>
        <div id="sidebar-menu">
          <ul
            className={clsx(
              {
                'position-fixed': isMobile(),
              },
              'metismenu list-unstyled'
            )}
            id="side-menu"
          >
            <li>
              <GlobalSearch />
            </li>
            <li className="menu-title">{props.t('Menu')} </li>

            {sidebarRoutes.map((route, i) => (
              <Can
                key={i}
                action={['show', 'edit', 'administration']}
                subject={route.subject}
              >
                <SidebarItem {...route} />
              </Can>
            ))}
          </ul>
          {!isMobile() && (
            <div className=" border-0 text-center mx-1 mb-0 mt-1">
              <p className="font-size-13 mb-0 text-muted">
                {' '}
                {new Date().getFullYear()} © {application.name} - {appVersion}
              </p>
            </div>
          )}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
