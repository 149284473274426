import {useLazySearchSerialNumberQuery} from 'features/stock/store/endpoint/get-search-serial-number-for-transfer';
import {
  transferProductRequest,
  useTransferProductMutation,
} from 'features/stock/store/endpoint/transfer-product';

import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ResourceName} from 'types/resource-name';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useTransferProductForm = () => {
  const [submit, {isLoading, error}] = useTransferProductMutation();
  const [
    submitSerialNumber,
    {isFetching: isLoadingSerialNumber, error: errorSerialNumber},
  ] = useLazySearchSerialNumberQuery();

  const serverError = parseValidationErrors(error);
  const serverErrorSerialNumber = parseValidationErrors(errorSerialNumber);
  const modalParams = useSelector(selectActiveModalParams);
  const {path} = modalParams as {
    path: {name: ResourceName; url: string};
  };
  const [params, setParams] = useState<Params>({
    serial_number: '',
    stock_sub_location: undefined,
    stockProduct: undefined,
  });
  const handleSearchSerialNumber = async (serial_number: string) => {
    const result = await submitSerialNumber({serial_number});
    if ('data' in result) {
      setParams(s => ({
        ...s,
        stock_sub_location: result?.data?.data.stock_sub_location,
        stockProduct: result?.data?.data,
      }));
    }
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(loadData({name: path.name, url: path.url, page: 1}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    validationErrorsSerialNumber: serverErrorSerialNumber.errors,
    ErrorMessageSerialNumber: serverErrorSerialNumber.message,
    isLoadingSerialNumber,
    submitSerialNumber: handleSearchSerialNumber,
    submit: handleSubmit,
  };
};

type Params = transferProductRequest;
