import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

export const getFiscalSettings = api.injectEndpoints({
  endpoints: builder => ({
    getFiscalSettings: builder.query<Response, void>({
      query: () => ({
        method: 'GET',
        url: `settings/fiscal`,
      }),
    }),
  }),
});

type Response = ResponseData<Settings['fiscal']>;

export const {useGetFiscalSettingsQuery} = getFiscalSettings;

export const getFiscalSettingsEndpoint =
  getFiscalSettings.endpoints.getFiscalSettings;
