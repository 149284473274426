import React from 'react';
import messages from './messages';
import {usePasswordForm} from './use-password-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Card, CardHeader, CardTitle, CardBody, Row, Col} from 'reactstrap';
import {Alert} from 'reactstrap';
import {selectUser} from 'store/auth/selectors';

const SecurityTab = () => {
  const {t} = useTranslation();

  const {
    submit: handleUpdatePassword,
    params,
    setParams,
    isLoading,
    errorMessage,
    validationErrors,
  } = usePasswordForm();

  const user = useSelector(selectUser);

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="font-size-15">{t(...messages.title())}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <div className="pb-3">
              <div>
                <Row>
                  {user?.has_password && (
                    <Col sm="6" className="mb-1">
                      <TextInput
                        label={t(...messages.oldPassword())}
                        errors={validationErrors.old_password}
                        value={params.old_password}
                        onChange={old_password =>
                          setParams({...params, old_password})
                        }
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col sm="6" className="mb-1">
                    <TextInput
                      label={t(...messages.password())}
                      errors={validationErrors.password}
                      value={params.password}
                      onChange={password => setParams({...params, password})}
                    />
                  </Col>
                  <Col sm="6" className="mb-1">
                    <TextInput
                      label={t(...messages.passwordConfirmation())}
                      errors={validationErrors.password_confirmation}
                      value={params.password_confirmation}
                      onChange={password_confirmation =>
                        setParams({...params, password_confirmation})
                      }
                    />
                  </Col>
                  <Col xs={12}>
                    <p className="fw-bolder">
                      {t(...messages.passwordRequirements())}
                    </p>
                    <ul className="ps-1 ms-25">
                      <li className="mb-50">
                        {t(...messages.passwordLength())}
                      </li>
                      <li className="mb-50">
                        {t(...messages.passwordLengthAdvice1())}
                      </li>
                      <li>{t(...messages.passwordLengthAdvice2())}</li>
                    </ul>
                  </Col>
                  <AppBarActions>
                    <Button
                      onClick={handleUpdatePassword}
                      className="me-1"
                      isLoading={isLoading}
                      color="important"
                    >
                      {t('common.saveChanges')}
                    </Button>
                  </AppBarActions>
                </Row>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SecurityTab;
