import React from 'react';
import OverpaymentsActions from './overpayments-actions';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import StatusBadge from 'components/status-badge';
import TooltipCell from 'features/acounting/overpayments/commpenents/tooltip-cell';
import {OverpaymentCompact} from 'features/acounting/types/overpayment';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import {STATUS} from 'constants/status';

const columns: ColumnDef<OverpaymentCompact>[] = [
  {
    header: r => <Trans i18nKey="overpaymentsPage.client" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.directory_id}/general`}
        target="_blank"
      >
        <b>{row.client_name}</b>
        <Icon name="external-link" className="ms-1" size={15} />
      </Link>
    ),
    cell: r => r.getValue(),

    id: 'client_name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.number" />,
    accessorFn: row => {
      const link = `/dashboard/${
        row.model_type === 'order' ? 'orders' : 'invoices'
      }/${row.model_id}/details`;

      return (
        <Link to={link} target="_blank">
          <b> {row.document_ref}</b>
        </Link>
      );
    },
    cell: r => r.getValue(),
    id: 'document_ref',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.date" />,
    accessorFn: row => row.created_at,
    id: 'created_at',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.total" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.total}} />
    ),
    id: 'total',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.sold" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.balance}} />
    ),
    enableSorting: true,
    id: 'balance',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.usedSold" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.total_amount_used}} />
    ),
    enableSorting: true,
    id: 'total_amount_used',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.usedOn" />,
    accessorFn: row =>
      row.used_on.length > 0 ? (
        row.used_on.map(relatedDocument => (
          <TooltipCell
            key={`tooltip-${relatedDocument.documentable_type}-${relatedDocument.documentable_id}`}
            cell={relatedDocument}
          />
        ))
      ) : (
        <span className="w-100 text-center">-</span>
      ),
    id: 'used_on',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="overpaymentsPage.status" />,
    accessorFn: row => <StatusBadge status={row.status} />,
    id: 'status',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productsPage.action" />,
    accessorFn: row =>
      row.status !== STATUS.REFUND && <OverpaymentsActions row={row} />,
    id: 'action',
    enableSorting: false,
    cell: r => r.getValue(),
  },
];

export default columns;
