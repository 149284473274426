export enum MODAL {
  ADDRESS_FORM = 'address-form',
  ADDRESS_PICKER_MODAL = 'address-picker',
  ALERT_CLOAK = 'alert-cloak',
  ATTACH_DIRECTORY = 'attach-directory',
  COLOR_ARCHIVE_MODAL = 'color-archive-modal',
  COLOR_MODAL = 'color-modal',
  DESTROY_DIRECTORY = 'destroy-directory',
  DETTACH_COMPANY = 'dettach-directory',
  DETTACH_CONTACT = 'delete-contact',
  ARCHIVE_DIRECTORY = 'archive-directory',
  DIRECTORY_CATEROGY_FORM = 'directory-caterogy-form',
  DIRECTORY_FAMILY_FORM = 'directory-family-form',
  DIRECTORY_FAMILY_FORM_UPDATE = 'directory-family-form-update',
  ECOTAX_CREATE = 'ecotax-create',
  ITEM_OPTIONS_MODAL = 'item-options-modal',
  ECOTAX_UPDATE = 'ecotax-update',
  EXPORT_PATRIMOINE = 'export-patrimoine',
  FAMILY_FORM = 'family-form',
  FAMILY_UPDATE_FORM = 'family-update-form',
  GLOBAL_SEARCH = 'global_search',
  INTERACTION_FORM = 'interaction-form',
  INVOICE_DUPLICAT = 'invoice-duplicate',
  INVOICE_GENERATE_ACCOUNTING_CREDIT = 'invoice-generate-accounting-credit',
  INVOICE_GENERATE_CREDIT = 'invoice-generate-credit',
  INVOICE_SEND_EMAIL = 'invoice-send-email',
  MERGE_DIRECTORY = 'merge-directory',
  ORDER_DELETE_ACTIONS_MODAL = 'order-delete-action-modal',
  ORDER_DUPLICAT_ACTIONS_MODAL = 'order-duplicate-action-modal',
  ORDER_SEND_EMAIL_ACTIONS_MODAL = 'order-send-email-action',
  ORDER_SEND_LABEL_ACTIONS_MODAL = 'order-send-label-action',
  PAYMENT_PICKER_MODAL = 'payment-picker-modal',
  PRICING_ACTION = 'pricing-action',
  PRICING_SITUATIONS = 'pricing-situations',
  PRODUCT_ACTIONS_MODAL = 'product-action-modal',
  PRODUCT_DELETE_ACTIONS_MODAL = 'product-delete-action-modal',
  QUOTATION_ARCHIVE_ACTIONS_MODAL = 'quotation-archive-action-modal',
  QUOTATION_DELETE_ACTIONS_MODAL = 'quotation-delete-action-modal',
  QUOTATION_DUPLICAT_ACTIONS_MODAL = 'quotation-duplicate-action-modal',
  QUOTATION_SEND_EMAIL_ACTIONS_MODAL = 'quotation-send-email-action',
  REINCARNATION_ANGEL = 'reincarnation-angel',
  RESTORE_DIRECTORY = 'restore-directory',
  SOUL_EATER = 'soul-eater',
  STOCK_MOVEMENT_FORM = 'stock-movement-form',
  TOGGLE_STATUS_OVERPAYEMENT = 'toggle-block-overpayement',
  TRANSFER_ORDER_MODAL = 'transfer-order-modal',
  TRANSFER_TO_SUPPLIER_ORDER_MODAL = 'transfer-to-supplier-order-modal',
  TRANSFER_STOCK_ORDER_MODAL = 'transfer-stock-order-modal',
  TRANSFER_QUOTE = 'transfer-quote',
  UPDATE_ADDRESS_FORM = 'update-address-form',
  UPDATE_CATALOG_FORM = 'update-catalog-form',
  UPDATE_COSTS_FORM = 'update-costs-form',
  UPDATE_CREDIT_STATUS = 'update-credit-status',
  UPDATE_DIRECTORY_CATEROGY_FORM = 'update-directory-caterogy-form',
  UPDATE_LIST_OF_CONTACT = 'update-list-of-contact',
  VARIANT_ACTIONS_MODAL = 'variant-action-modal',
  VARIANT_ARCHIVE_ACTIONS_MODAL = 'variant-archive-action-modal',
  VARIANT_BLOCK_ACTIONS_MODAL = 'variant-block-action-modal',
  VARIANT_DELETE_ACTIONS_MODAL = 'variant-delete-action-modal',
  VARIANT_DUPLICAT_ACTIONS_MODAL = 'variant-duplicate-action-modal',
  VARIANT_TO_PRODUCT_ACTIONS_MODAL = 'variant-to-product-action-modal',
  MATERIAL_MODAL = 'material-modal',
  MATERIAL_ARCHIVE_MODAL = 'material-archive-modal',
  ORDER_ARCHIVE_ACTIONS_MODAL = 'order-archive-action-modal',
  CREATE_STOCK_LOCATION = 'create-stock-location',
  UPDATE_STOCK_LOCATION = 'update-stock-location',
  UPDATE_STOCK_LOCATION_CATEGORY = 'update-stock-location-category',
  CREATE_STOCK_LOCATION_CATEGORY = 'create-stock-location-category',
  CREATE_STOCK_SUB_LOCATION = 'create-stock-sub-location',
  UPDATE_STOCK_SUB_LOCATION = 'update-stock-sub-location',
  UPDATE_STOCK_PRODUCT = 'update-stock-product',
  PREPERER_ORDER = 'prepare-order',
  UPDATE_STOCK_ENTRY = 'update-stock-entry',
  VARIANT_PREVIEW = 'variant-preview',
  STOCK_MOVEMENT_LIST = 'stock-movement-list',
  COMMERCIAL_PRODUCT_STOCK_MOVEMENT = 'commercial-product-stock-movement',
  EDIT_ORDER_INFO = 'edit-order-info',
  DETAILS_STOCK_PRODUCT = 'details-stock-product',
  DETAILS_STOCK_IN_PRODUCT = 'details-stock-in-product',
  DETAILS_STOCK_IN_PREPARATION_MODAL = 'details-stock-in-preparation-modal',
  EDIT_STOCK_IN_PREPARATION_MODAL = 'edit-stock-in-preparation-modal',
  STOCK_TRANSFER_PRODUCT = 'stock-transfer-product',
  ASSEMBLE_PRODUCT = 'assemble-product',
  CONFIG_MAN_DAYS_MODAL = 'config-man-days-modal',
  TRANSFER_STOCK_LOCATION_MODAL = 'transfer-stock-location-modal',
}
