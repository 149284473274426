import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.stockLocationPage.action, 'Action'),
  category: () => __(translations.stockLocationPage.category, 'Category'),
  name: () => __(translations.stockLocationPage.name, 'Stock location'),
  title: () => __(translations.stockLocationPage.title, 'Stock Location'),
  edit: () => __(translations.stockLocationPage.edit, 'Edit'),
  addbtn: () => __(translations.stockLocationPage.addbtn, 'Add'),
  isLocked: () => __(translations.stockLocationPage.isLocked, 'Locked'),
};
