import {VariantAlert} from 'features/stock/types/variant-alert';
import {useState} from 'react';
import {useLocalStorage} from 'usehooks-ts';
import {VariantToOrder} from './types';

export default () => {
  // used to store the selected variants to order
  const [variantsToOrder, setVariantToOrder] = useState<VariantToOrder[]>([]);
  // used to store the selected variants to order in local storage
  const [_, setvariantsToOrderOnLocalStorage] = useLocalStorage<
    VariantToOrder[]
  >('variantsToOrder', []);

  //function to handle the selection and unselection of a variant
  const handleSelect = (variant: VariantAlert, check: boolean) => {
    if (check) {
      setVariantToOrder(prev => [
        ...prev,
        {product_variant_id: variant.id, quantity: 1},
      ]);
    } else {
      setVariantToOrder(prev =>
        prev.filter(
          variantToOrder => variantToOrder.product_variant_id !== variant.id
        )
      );
    }
  };
  // clear local storage when the component is mounted

  const handleClick = () => {
    if (variantsToOrder === null) {
      return;
    }
    window.open(
      `/app/dashboard/stock-orders/create?fromAlertPage`,
      '_blank',
      'noopener noreferrer'
    );

    setvariantsToOrderOnLocalStorage(variantsToOrder);
  };
  return {handleSelect, handleClick, variantsToOrder};
};
