import {authApi} from '../endpoints';
import {AuthState} from '../types';
import {WritableDraft} from 'types/writable-draft';

const resetPasswordMatcher = authApi.endpoints.resetPassword.matchFulfilled;

/**
 * Resets the user password
 *
 */
const resetPasswordReducer = (state: WritableDraft<AuthState>) => {
  state.resetPassword.passwordResetFinished = true;
};

export const resetPassword = {
  matcher: resetPasswordMatcher,
  reducer: resetPasswordReducer,
};
