import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

export const getInvoiceDefaultMailContent = api.injectEndpoints({
  endpoints: builder => ({
    getInvoiceDefaultMailContent: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `/data/default-mail-content/for-invoice`,
        params,
      }),
    }),
  }),
});

type Request = {local: string};

type Response = ResponseData<{content: string}>;

export const {useGetInvoiceDefaultMailContentQuery} =
  getInvoiceDefaultMailContent;
