import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';
import {produce} from 'immer';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';
import messages from '../messages';
import SectionSeparator from 'components/separator/section-separator';

interface Props {
  emails: any;
  onChangeEmails: (e: any) => void;
  validationErrors: any;
}

const OtherEmailAddresses: FC<Props> = props => {
  const {emails, validationErrors, onChangeEmails} = props;

  const handleAddEmail = () => {
    onChangeEmails({
      ...emails,
      [makeId()]: '',
    });
  };
  const handleDeleteEmail = (id: ObjectId) => {
    onChangeEmails(
      produce(emails, (x: any) => {
        delete x[id];
      })
    );
  };

  const {t} = useTranslation();
  return (
    <div>
      <Row className="my-3 d-flex align-items-center">
        <Col>
          <SectionSeparator title={t('invoiceActionModal.invoiceEmailList')} />
        </Col>
        <Col md="auto">
          <Button color="light" onClick={handleAddEmail}>
            <Icon name="plus" size={15} className="mb-1" />
            {t(...messages.orderEmailAdd())}
          </Button>
        </Col>
      </Row>

      <div>
        {Object.keys(emails).map((id, index) => (
          <div key={id}>
            <Row>
              <Col>
                <TextInput
                  label={t(...messages.orderEmail())}
                  errors={validationErrors[`emails.${index}`]}
                  value={emails[id]}
                  onChange={e => onChangeEmails({...emails, [id]: e})}
                />
              </Col>
              <Col md="auto">
                <EmptyLabel />
                <Button
                  color=""
                  className="p-1"
                  onClick={() => handleDeleteEmail(id)}
                >
                  <Icon name="trash-2" size={16} className="text-danger me-1" />
                </Button>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherEmailAddresses;
