import {useRef} from 'react';
import {uniqueId} from 'lodash';

export default (defaultId?: string) => {
  const {current: id} = useRef<string>(defaultId ?? uniqueId());

  const prefix = 'input';

  return `${prefix}-${id}`;
};
