import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderSupplier.title, 'Supplier information'),
  orderSupplierDeadline: () => __(translations.orderSupplier.orderSupplierDeadline, 'Estimated supplier lead time'),
  orderDeliveryDate: () => __(translations.orderSupplier.orderDeliveryDate, 'Delivery date'),
  orderProductionComment: () => __(translations.orderSupplier.orderProductionComment, 'Production comment'),
  orderSupplierRef: () => __(translations.orderSupplier.orderSupplierRef, 'Supplier reference'),
  changesCommited: () => __(translations.orderSupplier.changesCommited, 'Your changes has been saved successfully!'),
  controlDate: () => __(translations.orderSupplier.controlDate, 'Control date'),
};
