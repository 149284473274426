import React from 'react';
import messages from './messages';
import RightSidebar from 'components/CommonForBoth/RightSidebar';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const SettingsTab = () => {
  const {t} = useTranslation();
  return (
    <div>
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="font-size-15">{t(...messages.title())}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <RightSidebar></RightSidebar>
        </CardBody>
      </Card>
    </div>
  );
};

export default SettingsTab;
