import {initialState} from './initial-state';
import {ContainerState} from './types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export const productsStockGridSlice = createSlice({
  name: 'productsStockGrid',
  initialState,
  reducers: {
    changeDisplayType(state, action: PayloadAction<ContainerState['display']>) {
      state.display = action.payload;
    },
    expandCollection(state, action: PayloadAction<any>) {
      const collection = action.payload;
      const id = collection?.id;
      // state.expandedCollections[id] = !state.expandedCollections[id];
      const oldValue = state.expandedCollections[id];
      state.expandedCollections = {}; // remove all other open collections
      state.expandedCollections[id] = oldValue ? undefined : action.payload;
      state.expandedFamilies = {};
      state.expandedProducts = {};
    },
    expandFamily(state, action: PayloadAction<any>) {
      const family = action.payload;
      const id = family?.id;
      // state.expandedFamilies[id] = !state.expandedFamilies[id];
      const oldValue = state.expandedFamilies[id];
      state.expandedFamilies = {}; // remove all other open families
      state.expandedFamilies[id] = oldValue ? undefined : action.payload;
      state.expandedProducts = {};
    },
    expandProduct(state, action: PayloadAction<any>) {
      const product = action.payload;
      const id = product?.id;
      // state.expandedProducts[id] = !state.expandedProducts[id];
      const oldValue = state.expandedProducts[id];
      state.expandedProducts = {}; // remove all other open products
      state.expandedProducts[id] = oldValue ? undefined : action.payload;
    },
  },
});

export const {actions, reducer} = productsStockGridSlice;

export const useListSlice = () => {
  return {actions: productsStockGridSlice.actions};
};
