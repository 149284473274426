import React from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useGeneralForm} from './use-general-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Icon from '@ailibs/feather-react-ts';
import Button from 'components/button';
import Informations from './informations';
import ExportsSection from '../../shared/exports-section';
import Can from 'features/hylian-shield/can';

const General = () => {
  const {t} = useTranslation();

  const form = useGeneralForm();

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h1">{t(...messages.general())}</CardTitle>
        <div>
          <FormGroup switch>
            <Input
              checked={form.params.is_online}
              onChange={() =>
                form.setParams({
                  ...form.params,
                  is_online: !form.params.is_online,
                })
              }
              type="switch"
              role="switch"
            />
            <Label check>{t(...messages.exposedOnWebsite())}</Label>
          </FormGroup>
        </div>

        <AppBarActions>
          <>
            <Can action="edit" subject={form.subject}>
              <Button
                isLoading={form.isLoading}
                onClick={form.submit}
                color="important"
              >
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </Can>
            <ExportsSection />
          </>
        </AppBarActions>
      </CardHeader>
      <CardBody>
        <Informations {...form} />
        <Row>
          <div className="my-3">
            {!!form.errorMessage && (
              <Alert color="danger">{form.errorMessage}</Alert>
            )}
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default General;
