import React from 'react';
import {FC} from 'react';
import './style.scss';
import {useNavigate} from 'react-router-dom';
import {Result, Search} from 'store/global/types';

interface ResultitemsProps {
  item: Search;
  onDismiss: () => void;
}

const urlMaker = (item: Result, type: string, key: string) => {
  switch (type) {
    case 'product-variant':
      return `/dashboard/products/${item.parent_id}/variants/${item.id}/general`;
    case 'directory':
      return `/dashboard/directories/${item.id}/general`;
    case 'user':
      return `/dashboard/users/${item.id}/profile`;

    case 'quotation':
    case 'order':
    case 'invoice':
      return `/dashboard/${key}/${item.id}/general`;
    default:
      return `/dashboard/${key}/${item.id}`;
  }
};

const ResultItems: FC<ResultitemsProps> = props => {
  const {item, onDismiss} = props;
  const navigate = useNavigate();
  const handleNavigation = (result: Result, type: string) => {
    navigate(urlMaker(result, type, item.key));
    onDismiss();
  };

  return (
    <div>
      <h3 className=" mb-3 fs-5">{item.label}</h3>
      {item.result.map((data: Result) => (
        <a
          onClick={() => {
            handleNavigation(data, item.type);
          }}
          className=" font-size-12"
          key={data.id}
        >
          <div className="result-item ">
            <p>{data.title}</p>
            <hr />
          </div>
        </a>
      ))}
    </div>
  );
};

export default ResultItems;
