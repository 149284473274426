import React from 'react';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import Currency from 'components/currency/currency';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {truncate} from 'lodash';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {CompactQuotation} from 'types/quotation';
import {ColumnDef} from '@tanstack/table-core';
import TtcColumn from 'features/commercial/documents/components/ttc-column/ttc-column';
import RelatedDocument from 'types/related-document';
import TooltipCell from 'components/tooltip-cell';
import {TYPE} from 'components/async-preview-tooltip/constant';

const columns: ColumnDef<CompactQuotation>[] = [
  {
    header: r => <Trans i18nKey="quotationsPage.client" />,
    accessorFn: row =>
      row.display_name ? (
        <Link
          to={`/dashboard/directories/${row.directory_id}/general`}
          target="_blank"
        >
          <b>{row.display_name}</b>
        </Link>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    cell: r => r.getValue(),
    id: 'display_name',

    meta: {size: '200px'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="quotationsPage.name" />,
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} type="QUOTATION" column="ref">
        <Link to={`/dashboard/quotations/${row.id}/details`} target="_blank">
          <b>{row.document_ref}</b>
        </Link>
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
    id: 'document_ref',

    meta: {size: '100px'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="quotationsPage.related_documents" />,
    accessorFn: row =>
      row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <AsyncPreviewTooltip
              id={related_document.model_id}
              key={`${related_document.model_type}-${related_document.model_id}-${row.id}`}
              type={related_document.model_type?.toLocaleUpperCase() as TYPE}
              column="ref"
            >
              <Link
                className="mb-1"
                to={guessUrl(related_document)}
                target="_blank"
              >
                <b>{related_document.model_ref}</b>
              </Link>
            </AsyncPreviewTooltip>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',

    meta: {size: '100px'},
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.date" />,
    enableSorting: true,
    id: 'created_at',
    accessorFn: row =>
      <FormattedDate value={row.created_at} format="date" /> ?? (
        <span className="w-100 text-center"></span>
      ),
    cell: r => r.getValue(),
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.user" />,
    enableSorting: true,
    id: 'user_name',
    accessorFn: row => <EmptyCell cell={row.user?.name} />,
    cell: r => r.getValue(),
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.total_ht" />,
    enableSorting: true,
    id: 'total_ht',
    accessorFn: row => (row?.total_ht ? <Currency value={row.total_ht} /> : ''),
    cell: r => r.getValue(),
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.total_ttc" />,
    enableSorting: true,
    id: 'total_ttc',
    accessorFn: row => <TtcColumn doc={row} />,
    cell: r => r.getValue(),
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.status" />,
    enableSorting: true,
    id: 'status',
    cell: r => r.getValue(),
    accessorFn: row => <StatusBadge status={row.status} />,
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.projet" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'project_name',
    accessorFn: row => <EmptyCell cell={row.project?.name} />,
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.document_category" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'document_category_name',
    accessorFn: row => <EmptyCell cell={row.document_category.name} />,
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.ref_client" />,
    enableSorting: true,
    id: 'ref_client',
    accessorFn: row => <EmptyCell cell={row.ref_client} />,
    cell: r => r.getValue(),
    meta: {size: '100px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.transporter_name" />,
    enableSorting: true,
    id: 'transporter_name',
    accessorFn: row => <EmptyCell cell={row.transporter_name} />,
    cell: r => r.getValue(),
    meta: {size: '150px'},
  },
  {
    header: r => <Trans i18nKey="quotationsPage.comment" />,
    enableSorting: true,
    id: 'comment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <TooltipCell cell={row?.private_comment ?? ''} id={row.id}></TooltipCell>
    ),
    meta: {size: '100px'},
  },
];

export default columns;

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};
