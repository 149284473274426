import {selectVariant} from '../endpoints/show-variant';
import {createSelector} from 'reselect';
import {ObjectId} from 'types/object-id';

export default (id?: ObjectId, productId?: ObjectId) => {
  return createSelector(
    [
      selectVariant({
        id,
        productId,
      }),
    ],
    variant => {
      const situations = variant.data?.data.price_situations ?? [];
      const main = situations.find(x => x.is_main);
      if (main) {
        return main;
      }

      return null;
    }
  );
};
