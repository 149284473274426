import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import messages from './messages';
import MonitoringListingView from 'features/stock/production-monitoring/containers/monitoring-listing';

const CalendarPage = () => {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <MonitoringListingView />
    </Fragment>
  );
};

export default CalendarPage;
