import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import {useDuplicatVariant} from './use-action-variant-duplicat';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const VariantDuplicatActionConfirm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {submit, isLoading, message} = useDuplicatVariant(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <p>{message} </p>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default VariantDuplicatActionConfirm;
