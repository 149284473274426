import React, {Fragment} from 'react';
import LoadRunner from 'components/load-runner';
import {Card, CardBody, CardHeader} from 'reactstrap';

const SkeletonLoader = () => {
  return (
    <>
      <Card>
        <CardBody>
          <LoadRunner width={'100%'} height={180} viewBox="0 0 100% 200">
            {/* LEFT HEADER */}
            <rect x="0%" rx="10" ry="10" width="20%" height={25} />
            <rect x="0%" rx="10" ry="10" y={40} width="30%" height={15} />
            <rect x="0%" rx="10" ry="10" y={70} width="25%" height={15} />

            {/* RIGHT HEADER */}
            <rect x="70%" rx="10" ry="10" width="30%" height={25} />
            <rect x="70%" rx="10" ry="10" y={40} width="25%" height={15} />
            <rect x="70%" rx="10" ry="10" y={70} width="20%" height={15} />

            <rect x="0%" rx="10" ry="10" y={120} width="100%" height={60} />
          </LoadRunner>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <LoadRunner width={'100%'} height={400} viewBox="0 0 100% 200">
            <rect x="0%" rx="10" ry="10" width="100%" height={400} />
          </LoadRunner>
        </CardBody>
      </Card>
    </>
  );
};

export default SkeletonLoader;
