import {FilterMap} from '../types';
import selectEntity from './select-entity';
import {createSelector} from '@reduxjs/toolkit';
import selectPaginationLimit from 'store/global/selectors/select-pagination-limit';
import {ResourceName} from 'types/resource-name';

export default (name: ResourceName) =>
  createSelector(selectEntity(name), selectPaginationLimit, (entity, limit) => {
    // entity
    const entityFilters = entity?.filters || {};
    return {
      ...entityFilters,
      limit: entityFilters?.limit ?? limit,
    } as FilterMap<any>;
  });
