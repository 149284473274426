import {
  loadObject,
  loadString,
  removeItem,
  saveObject,
  saveString,
} from './storage-service';
import {User} from 'types/user';

const AuthStorageKeys = {
  USER: '@auth/user',
  ACCESS_TOKEN: '@auth/access_token',
  REFRESH_TOKEN: '@auth/refresh_token',
};

export type StoredAuthState = {
  user: User;
  accessToken?: string | null;
  refreshToken?: string | null;
};

/**
 * Persist auth state
 */
export const saveAuthState = (authState: StoredAuthState): boolean => {
  if (!authState.user.hasOwnProperty('id')) {
    throw new Error('The given object is not a valid user.');
  }
  let {user, accessToken, refreshToken} = authState;
  saveObject(AuthStorageKeys.USER, user);
  if (accessToken) {
    saveString(AuthStorageKeys.ACCESS_TOKEN, accessToken);
  }
  if (refreshToken) {
    saveString(AuthStorageKeys.REFRESH_TOKEN, refreshToken);
  }
  return true;
};

/**
 * Load auth state from locale storage
 *
 * @returns {Promise<Object|null>} User informations and access token. If one of them is absent, null is returned.
 */
export const loadAuthState = async (): Promise<StoredAuthState | null> => {
  let user = await loadObject(AuthStorageKeys.USER);
  let accessToken = await loadString(AuthStorageKeys.ACCESS_TOKEN);
  if (user && accessToken) {
    return {
      user,
      accessToken,
    };
  }
  return null;
};

/**
 * Remove auth state
 *
 */
export const clearAuthState = async (): Promise<boolean> => {
  await removeItem(AuthStorageKeys.USER);
  await removeItem(AuthStorageKeys.ACCESS_TOKEN);
  await removeItem(AuthStorageKeys.REFRESH_TOKEN);
  return Promise.resolve(true);
};

/**
 * Returns an FCM token for this device.
 *
 */
export const getFcmToken = async (): Promise<string | undefined> => {
  let fcmToken;
  // fcmToken = await messaging().getToken();
  fcmToken = 'FCM-TOKEN-UNAVAILABLE';
  return fcmToken;
};

/**
 * Returns a unique device id
 */
export const getDeviceId = (): string => {
  //   return `${getModel()}-${getUniqueId()}`;
  return 'N/A';
};
