import React, {useEffect} from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalHeader} from 'reactstrap';
import EmptyState from 'features/table/compenents/empty-state';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import ItemPicker from 'components/item-picker';
import {
  Params,
  useEditStockInPreparationForm,
} from './use-edit-stock-in-preparation-form';
import TextInput from 'components/text-input';
import AsyncItemPicker from 'components/async-item-picker';
import {Directory} from 'types/directory';
import GetOptionLabel from './get-option-label';
import NoOptionMessage from './no-option-message';
import Button from 'components/button';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Option} from 'types/option';
import {Link} from 'react-router-dom';
import {useGetProductInPreparationDetailsQuery} from 'features/stock/products-stock-grid/store/endpoints/get-product-in-preparation-details';
import ListSkeleton from 'features/table/compenents/list-skeleton';

const EditStockInPreparation: FC<Props> = props => {
  const {onDismiss, stockInPreparation} = props;

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <h4 className="text-center">{stockInPreparation?.client_name}</h4>
        <h5 className="text-center">
          <Link
            target="_blank"
            to={`/dashboard/orders/${stockInPreparation.order?.id}/details`}
          >
            {stockInPreparation.order?.document_ref}
          </Link>
        </h5>
        <StockProductTable {...stockInPreparation} />
      </ModalBody>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
  stockInPreparation: StockProductInPreparation;
}

export default EditStockInPreparation;

const StockProductTable = (props: StockProductInPreparation) => {
  const {id} = props;
  const {data, isLoading} = useGetProductInPreparationDetailsQuery(
    {id},
    {refetchOnMountOrArgChange: true}
  );

  const commercial_products = data?.data?.commercial_products;
  const {t} = useTranslation();

  const {
    options,
    params,
    isSubmitting,
    validationErrors,
    errorMessage,
    setParams,
    handleChange,
    submit,
  } = useEditStockInPreparationForm();

  const {status, transporter, tracking_code, comment} = params;

  useEffect(() => {
    setParams({
      id: id,
      status: props.status,
      transporter: props.transporter,
      comment: props.comment,
      tracking_code: props.tracking_code,
    } as Params);
  }, []);

  return (
    <div>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      <div className="row mb-2">
        <div className="col">
          <ItemPicker
            label={t(...messages.status())}
            value={status}
            errors={validationErrors['status']}
            valueOnly
            onChange={val => handleChange('status', val as string)}
            options={options}
          />
        </div>
        <div className="col">
          <TextInput
            value={comment}
            errors={validationErrors['comment']}
            type="textarea"
            rows={1}
            label={t(...messages.description())}
            onChange={val => handleChange('comment', val)}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <AsyncItemPicker
            endPoint="data/directories"
            getOptionLabel={(directory: Directory) => (
              <GetOptionLabel directory={directory} />
            )}
            label={t(...messages.transporter())}
            optionLabel=""
            errors={validationErrors['transporter']}
            value={transporter}
            onChange={(val: Directory) => handleChange('transporter', val)}
            noOptionsMessage={(e: any) => (
              <NoOptionMessage newClient={e.inputValue} />
            )}
          />
        </div>
        <div className="col">
          <TextInput
            value={tracking_code}
            errors={validationErrors['tracking_code']}
            label={t(...messages.trackingNumber())}
            onChange={val => handleChange('tracking_code', val)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mb-2">
        <Button
          onClick={() => submit(id)}
          color="important"
          isLoading={isSubmitting}
        >
          <Icon name="check" size={18} className="me-2" />
          {t('common.saveChanges')}
        </Button>
      </div>
      {isLoading ? (
        <ListSkeleton></ListSkeleton>
      ) : (
        <>
          {commercial_products?.length === 0 ? (
            <EmptyState />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>{t(...messages.sku())}</th>
                  <th>{t(...messages.serialNumber())}</th>
                  <th className="text-center">{t(...messages.name())}</th>
                  <th className="text-center">{t(...messages.location())}</th>
                </tr>
              </thead>
              <tbody>
                {commercial_products?.map(product => {
                  return product.serial_numbers.map(sn => (
                    <tr key={product.id}>
                      <td>{product.product_variant?.sku}</td>
                      <td>{sn?.code}</td>
                      <td className="text-center">
                        {product.product_variant?.name}
                      </td>
                      <td className="text-center">
                        {sn.stock_sub_location?.name}
                      </td>
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};
