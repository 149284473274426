import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.overpaymentsPage.title, 'Overpayments list'),
  date: () => __(translations.overpaymentsPage.date, 'Date'),
  number: () => __(translations.overpaymentsPage.number, 'Number'),
  client: () => __(translations.overpaymentsPage.client, 'Client'),
  total: () => __(translations.overpaymentsPage.total, 'Total'),
  status: () => __(translations.overpaymentsPage.status, 'Status'),
  actions: () => __(translations.overpaymentsPage.actions, 'Actions'),
  usedSold: () => __(translations.overpaymentsPage.usedSold, 'Used sold'),
  usedOn: () => __(translations.overpaymentsPage.usedOn, 'Used on'),
  archive: () => __(translations.overpaymentsPage.archive, 'Archive'),
  removeFromArchives: () => __(translations.overpaymentsPage.removeFromArchives, 'Remove from archives'),
  export: () => __(translations.overpaymentsPage.export, 'Export'),
  sold: () => __(translations.overpaymentsPage.sold, 'Sold'),
  markAsUsed: () => __(translations.overpaymentsPage.markAsUsed, 'Mark as used'),
  markAsAvailable: () => __(translations.overpaymentsPage.markAsAvailable, 'Mark as available'),
  removeFromArchive: () => __(translations.overpaymentsPage.removeFromArchive, 'Remove from archives'),

  // tag rows
  totalSoldRemaining: () => __(translations.overpaymentsPage.totalSoldRemaining, 'Total remaining sold'),
  totalOverpaymentsGenerated: () => __(translations.overpaymentsPage.totalOverpaymentsGenerated, 'Total overpayments generated'),
};
