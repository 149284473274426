import {updateCachedDirectoryAddress} from './get-directory-addresses';
import {api} from 'services/api';
import {Address} from 'types/address';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateAddress = api.injectEndpoints({
  endpoints: builder => ({
    updateAddress: builder.mutation<Response, UpdateAddressRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `directories/${params.directory_id}/addresses/${params.id}`,
          body: {
            address_type: params.address_type?.id,
            city: params.city,
            comment: params.comment,
            country: params.country?.id,
            first_line: params.first_line,
            force_vat: params.force_vat,
            label: params.label,
            no_vat: params.no_vat,
            second_line: params.second_line,
            third_line: params.third_line,
            vat_mode: params.vat_mode?.value,
            zip_code: params.zip_code,
            is_default: params.is_default,
          },
        };
      },
      // @ts-ignore
      invalidatesTags: (result, error, arg) => [ { type: 'DirectoryAddresses', id: arg.directory_id } ]
      ,
    }),
  }),
});

export type UpdateAddressRequest = {
  id?: ObjectId;
  directory_id: ObjectId;
  address_type?: any;
  city?: string;
  comment?: string;
  country?: Option;
  first_line?: string;
  force_vat?: boolean;
  no_vat?: boolean;
  label?: string;
  second_line?: string;
  third_line?: string;
  vat_mode?: Option;
  zip_code?: string;
  is_default?: boolean;
};

type Response = ResponseData<Address>;

export const {useUpdateAddressMutation} = updateAddress;
