export enum STATUS {
  APPROVED = 'APPROVED',
  AVAILABLE = 'AVAILABLE',
  AWAITING_SHIPMENT = 'AWAITING_SHIPMENT',
  AWAITING_TRASNPORT = 'AWAITING_TRASNPORT',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
  CHARGED = 'CHARGED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  DEDUCTED = 'DEDUCTED',
  DEFAULTED = 'DEFAULTED',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  IN_PRODUCTION = 'IN_PRODUCTION',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  INVOICED = 'INVOICED',
  ONGOING = 'ONGOING',
  OPENED = 'OPENED',
  PARTIALLY_DEDUCTED = 'PARTIALLY_DEDUCTED',
  PARTIALLY_DEFAULTED = 'PARTIALLY_DEFAULTED',
  PARTIALLY_IN_PRODUCTION = 'PARTIALLY_IN_PRODUCTION',
  PARTIALLY_IN_STOCK = 'PARTIALLY_IN_STOCK',
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED',
  RECEIVED = 'RECEIVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  USED = 'USED',
  VALIDATED = 'VALIDATED',
  NEW = 'NEW',
  ARCHIVED = 'ARCHIVED',
  IN_STOCK = 'IN_STOCK',
  WITHOUT_FOLLOW_UP = 'WITHOUT_FOLLOW_UP',
  POTENTIAL = 'POTENTIAL',
  FINALIZED = 'FINALIZED',
  REFUND = 'REFUND',
  WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
}
