import {ResourceName} from 'types/resource-name';
import {ListConfig} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'table/loadLocalData',
  function prepare(cfg?: {name: ResourceName; data: any}) {
    return {
      payload: {
        ...cfg,
      },
    };
  }
);
