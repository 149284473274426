import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.complementaryInfoTab.title, 'Additional information of {{name}}'),
  legalStatus: () => __(translations.complementaryInfoTab.legalStatus, 'Legal status'),
  siret: () => __(translations.complementaryInfoTab.siret, 'SIRET'),
  ape: () => __(translations.complementaryInfoTab.ape, 'APE Code'),
  vat: () => __(translations.complementaryInfoTab.vat, 'V.A.T. Number'),
  vatType: () => __(translations.complementaryInfoTab.vatType, 'V.A.T. Type'),
  iban: () => __(translations.complementaryInfoTab.iban, 'IBAN'),
  bic: () => __(translations.complementaryInfoTab.bic, 'BIC/SWIFT'),
};
