import {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {
  VariantAttachmentsRequest,
  useUpdateAttachmentsMutation,
} from 'store/production/endpoints/update-attachments';
import {ProductAttachment} from 'types/attachements';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAttachmentsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateAttachmentsMutation();

  const serverError = parseValidationErrors(error);

  const {id, variant: variantId} = useParams();

  const [attachments, setAttachments] = useState<AttachmentMap>({});

  const [deletedRecords, setDeletedRecords] = useState<
    Record<ObjectId, boolean>
  >({});

  const [params, setParams] = useState<Params>({
    in_catalog: undefined,
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      attachments: Object.values(attachments),
      in_catalog: params.in_catalog,
      deleted_records: Object.keys(deletedRecords).filter(
        k => deletedRecords[k]
      ),
      id,
      variant: variantId,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const addAttachments = (type?: any) => {
    const p: ProductAttachment = {
      id: makeId(),
      type,
    };
    setAttachments(
      produce(attachments, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeAttachments = (id: ObjectId) => {
    const _attachments: AttachmentMap = Object.assign({}, attachments);
    delete _attachments[id];
    deletedRecords[id] = !deletedRecords[id];
    setAttachments({
      ..._attachments,
    });
  };
  const updateAttachment = (
    p: ProductAttachment,
    k: keyof ProductAttachment,
    v: any
  ) => {
    setAttachments(
      produce(attachments, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data;

  useEffect(() => {
    if (variant) {
      const tmp = variant.attachements.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {} as AttachmentMap);
      setAttachments(tmp);
      // @ts-ignore
      setParams({...params, in_catalog: variant?.cover?.id});
    }
  }, [variant]);

  return {
    addAttachments,
    attachments,
    variant,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    params,
    removeAttachments,
    setParams,
    submit: handleSubmit,
    updateAttachment,
    validationErrors: serverError.errors,
  };
};

type AttachmentMap = Record<ObjectId, ProductAttachment>;

type Params = Partial<VariantAttachmentsRequest>;
