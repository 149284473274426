import React, {FC} from 'react';
import CardRows from './compenents/card-rows';
import Paginator from './compenents/paginator';
import DataTable from './container/table';
import useFilters from './hooks/use-filters';
import useInitiateTable from './hooks/use-initiate-table';
import {useListSlice} from './slice';
import makeSelectEntityRequest from './slice/selectors/make-select-entity-request';
import {ListParams} from './types';
import {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Alert} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import {ResourceName} from 'types/resource-name';
import {useMediaQuery} from 'usehooks-ts';

interface TableProps {
  url: string;
  routerParams?: boolean;
  name: ResourceName;
  subHeaderComponent?: React.ReactNode;
  otherParams?: ListParams;
  columns: ColumnDef<any>[];
  hidePagination?: boolean;
  filtersComponent?: any;
}

const TableCompact: FC<TableProps> = props => {
  const {
    url,
    name,
    columns,
    routerParams,
    otherParams,
    hidePagination,
    filtersComponent: FiltersComponent,
  } = props;

  const {handleChangeFilters, handleClearFilters} = useFilters(
    name,
    url,
    routerParams,
    otherParams
  );

  const {actions} = useListSlice();

  const {isLoading, errorMessage} = useSelector(makeSelectEntityRequest(name));

  const dispatch = useDispatch();

  const onPageChange = (page: number) => {
    dispatch(actions.loadData({url, name, page, otherParams}));
  };

  const {t} = useTranslation();

  const theme = useSelector(selectAppTheme);

  const media = useMediaQuery('(max-width: 768px)');
  const {meta, filters, table} = useInitiateTable(
    name,
    url,
    columns,
    otherParams
  );

  return (
    <>
      {/* error message (in case of failure) */}
      {errorMessage && (
        <Alert color="danger" className="m-3">
          <div className="alert-body">{errorMessage}</div>
        </Alert>
      )}

      <div className="table-responsive">
        {media ? (
          <CardRows isLoading={isLoading} table={table}></CardRows>
        ) : (
          <DataTable table={table} isLoading={isLoading}></DataTable>
        )}
        {!hidePagination &&
        !isLoading &&
        !!meta &&
        table.getRowModel().rows.length !== 0 ? (
          <Paginator
            name={name}
            currentPage={meta?.current_page}
            pageCount={meta?.last_page}
            onPageChange={onPageChange}
            meta={meta}
            filters={filters}
            onChangeFilters={handleChangeFilters}
          />
        ) : null}
      </div>
    </>
  );
};

export default TableCompact;
