import React from 'react';
import {useState, useCallback, useRef, useEffect, Fragment} from 'react';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import ReactCrop, {Crop} from 'react-image-crop';
import {Input, Modal, ModalHeader, ModalBody, Label} from 'reactstrap';
import useInputId from 'utils/use-input-id';
import Button from 'components/button';

const ImageCropPicker = (props: Props) => {
  const {
    aspect,
    onCropFinished,
    label,
    id: inputId,
    isLoading,
    isVisible,
    onClose,
  } = props;

  const {t} = useTranslation();

  const [upImg, setUpImg] = useState<string>('');

  const imgRef = useRef<HTMLImageElement>(null);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    aspect,
  });

  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img: HTMLImageElement) => {
    // @ts-ignore

    imgRef.current = img;
  }, []);

  const generateImage = () => {
    if (!completedCrop || !previewCanvasRef.current) {
      return;
    }
    previewCanvasRef.current.toBlob(
      async (blob: Blob | null) => {
        if (blob) {
          const file = new File([blob], 'file.png');
          setCompletedCrop(null);
          setUpImg('');
          setCrop({
            unit: '%',
            width: 100,
            height: 100,
            x: 0,
            y: 0,
            aspect,
          });
          // @ts-ignore
          imgRef.current = null;
          onCropFinished(file);
        }
      },
      'image/png',
      1
    );
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Convert percentage-based dimensions to pixel values
    const pixelCrop = {
      x: (completedCrop.x * image.naturalWidth) / 100,
      y: (completedCrop.y * image.naturalHeight) / 100,
      width: (completedCrop.width * image.naturalWidth) / 100,
      height: (completedCrop.height * image.naturalHeight) / 100,
    };

    // Set canvas dimensions in pixels
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.imageSmoothingQuality = 'high';

    // Draw the cropped image on the canvas
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
  }, [completedCrop]);

  const inputRef = useRef<Input>(null);
  const isFileSelected = completedCrop !== null;

  const handleCloss = () => {
    setCompletedCrop(null);
    setUpImg('');
    onClose();
  };

  const id = useInputId(inputId);

  return (
    <div className="app-crop-picker">
      <div className="demo-inline-spacing">
        <Fragment>
          <Modal
            isOpen={isVisible}
            toggle={handleCloss}
            className={`modal-dialog-centered`}
          >
            <ModalHeader toggle={handleCloss}></ModalHeader>
            <ModalBody>
              <div>
                <Label htmlFor={id}>{label}</Label>
                {/* Input  */}
                <Input
                  ref={inputRef}
                  id={id}
                  onChange={onSelectFile}
                  type="file"
                  className="mb-2"
                  name="customFile"
                />
              </div>
              <ReactCrop
                src={upImg}
                ruleOfThirds
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
              />
              <div className="d-none">
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0),
                  }}
                />
              </div>
              {isFileSelected && <p>{t(...messages.instructions())}</p>}

              <Button className="me-2" onClick={handleCloss} color="light">
                {t(...messages.cancel())}
              </Button>
              <Button
                onClick={generateImage}
                isLoading={isLoading}
                disabled={!completedCrop?.width || !completedCrop?.height}
                color="important"
              >
                {t(...messages.confirm())}
              </Button>
            </ModalBody>
          </Modal>
        </Fragment>
      </div>
    </div>
  );
};

interface Props {
  aspect?: number;
  onCropFinished: (imgData: File) => void;
  onClose: () => void;
  isVisible: boolean;
  isLoading?: boolean;
  label?: string;
  id?: string;
}

interface FileEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
export default ImageCropPicker;
