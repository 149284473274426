import {ObjectId} from 'types/object-id';
import {initialState} from './initial-state';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {CalendarSupplier} from 'features/stock/types/calendar-supplier';

export const productionMonitoringSlice = createSlice({
  name: 'productionMonitoring',
  initialState,
  reducers: {
    toggleSupplier(state, action: PayloadAction<CalendarSupplier>) {
      const supplier = action.payload;
      const id = supplier.id;
      const oldValue = state.selectedSuppliers[id];
      state.selectedSuppliers[id] = oldValue ? undefined : supplier;
    },
    selectAllOf(state, action: PayloadAction<CalendarSupplier[]>) {
      const ids = action.payload;

      state.selectedSuppliers = ids.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {} as typeof state.selectedSuppliers);
    },

    unselectAll(state) {
      state.selectedSuppliers = {};
    },
  },
});

export const {actions, reducer} = productionMonitoringSlice;

export const useProductionMonitoringSlice = () => {
  return {actions: productionMonitoringSlice.actions};
};
