import React, {FC} from 'react';
import {Button, CardHeader, CardSubtitle, CardTitle} from 'reactstrap';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import ICommissionsForm from '../use-commissions-form';
import Currency from 'components/currency/currency';
import Can from 'features/hylian-shield/can';

const HeaderSection: FC<Props> = ({totalHT, subject, handleAddRow}) => {
  const {t} = useTranslation();

  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <div>
          <CardTitle tag="h5">{t(...messages.commissions())}</CardTitle>
          <Can action="edit" subject={subject}>
            <Button color="important" onClick={handleAddRow}>
              {t(...messages.addCommission())}
            </Button>
          </Can>
        </div>
        <div>
          <CardTitle tag="h5">{t(...messages.totalHT())}</CardTitle>
          <CardSubtitle tag="p">
            <Currency value={totalHT} />
          </CardSubtitle>
        </div>
      </div>
    </CardHeader>
  );
};

type Props = ICommissionsForm;

export default HeaderSection;
