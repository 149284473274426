import {createApi} from '@reduxjs/toolkit/query/react';
import apiBaseQuery from 'services/api-base-query';
import apiBlobBaseQuery from 'services/api-blob-base-query';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: apiBaseQuery,
  endpoints: () => ({}),
});

export const blobApi = createApi({
  reducerPath: 'blobApi',
  baseQuery: apiBlobBaseQuery,
  endpoints: () => ({}),
});
