import selectAllLineTotals from './select-all-line-totals';
import {createSelector} from '@reduxjs/toolkit';
import {groupBy} from 'lodash';
import {Option} from 'types/option';
import unwrapVats from 'utils/unwrap-vats';

export default createSelector([selectAllLineTotals], lineTotals => {
  const productsWithVat = lineTotals.filter(x => Number(x.vat?.value) > 0) as {
    taxes: number;
    vat: Option;
  }[];

  const grouppedByVat = groupBy(productsWithVat, 'vat.id');

  return unwrapVats(grouppedByVat);
});
