import React from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useRestoreDirectoryMutation} from 'store/directory/endpoints/restore-directory';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useRestoreDirectoryForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useRestoreDirectoryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, directory_id: id} = modalParams;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit({
      url: modalParams.url,
      id,
    });
    if ('data' in result) {
      dispatch(toast('success', result.data?.data?.message));
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
