import React from 'react';
import GetOptionLabel from './get-option-label';
import messages from './messages';
import NoOptionMessage from './no-option-message';
import {useQuotationForm} from './use-quotation-form';
import Icon from '@ailibs/feather-react-ts';
import Breadcrumb from 'components/Common/Breadcrumb';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import {Alert, Card, CardBody, Col, Row} from 'reactstrap';
import {Directory} from 'types/directory';

const CreateQuotationsPage = () => {
  const {t} = useTranslation();
  const {
    params,
    validationErrors,
    isLoading,
    errorMessage,
    submit,
    syncParams,
    setParams,
  } = useQuotationForm();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.quotation()), path: '/dashboard/quotations'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <div>
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/directories"
                    className="my-2"
                    getOptionLabel={(directory: Directory) => (
                      <GetOptionLabel directory={directory} />
                    )}
                    label={t(...messages.client())}
                    errors={validationErrors.directory_id}
                    value={params.client}
                    onChange={async client => {
                      syncParams(client);
                    }}
                    noOptionsMessage={(e: any) => (
                      <NoOptionMessage newClient={e.inputValue} />
                    )}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/commercial-types?quotation"
                    defaultValue
                    className="my-2"
                    label={t(...messages.quotationType())}
                    errors={validationErrors.commercial_type_id}
                    value={params.type}
                    onChange={type => setParams({...params, type})}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/document-categories"
                    defaultValue
                    className="my-2"
                    label={t(...messages.quotationCategory())}
                    errors={validationErrors.document_category_id}
                    value={params.category}
                    onChange={category => setParams({...params, category})}
                  />
                </Col>
                {params.category?.id === 3 && (
                  <Col md={12}>
                    <AsyncItemPicker
                      endPoint="data/projects"
                      className="my-2 "
                      label={t(...messages.quotationProject())}
                      errors={validationErrors.project_id}
                      value={params.project}
                      onChange={project => setParams({...params, project})}
                    />
                  </Col>
                )}
                <Col md={12}>
                  <AsyncItemPicker
                    defaultValue
                    endPoint="data/document-origins"
                    className="my-2"
                    label={t(...messages.quotationOrigin())}
                    errors={validationErrors.origin_id}
                    value={params.origin}
                    onChange={origin => setParams({...params, origin})}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    defaultValue
                    endPoint="data/languages"
                    className="my-2 text-capitalize"
                    label={t(...messages.quotationLanguage())}
                    getOptionLabel={(l: any) => l.label?.toLocaleLowerCase()}
                    errors={validationErrors.language}
                    value={params.language}
                    getOptionValue={(l: any) => l.label}
                    onChange={language => {
                      setParams({...params, language});
                    }}
                  />
                </Col>
                <Col md={6} className="">
                  <DateInput
                    label={t(...messages.quotationIssueDate())}
                    value={params.created_at}
                    onChange={created_at =>
                      setParams({
                        ...params,
                        created_at,
                      })
                    }
                    errors={validationErrors.created_at}
                  />
                </Col>
                <Col md={6} className="mb-1">
                  <DateInput
                    label={t(...messages.quotationDeadline())}
                    value={params.deadline}
                    onChange={deadline => setParams({...params, deadline})}
                    errors={validationErrors.due_date}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="my-3">
                    {!!errorMessage && (
                      <Alert color="danger">{errorMessage}</Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <AppBarActions>
                <Button
                  isLoading={isLoading}
                  onClick={submit}
                  color="important"
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </AppBarActions>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default CreateQuotationsPage;
