import {blobApi} from 'services/api';
import {ResponseData} from 'types/response-data';
import {TemporaryFile} from 'types/temporary-file';

const uploadTemporaryFile = blobApi.injectEndpoints({
  endpoints: builder => ({
    uploadTemporaryFile: builder.mutation<Response, UploadTemporaryFileRequest>(
      {
        query: params => {
          const jsonData = params.data;
          delete params.data;
          const body = new FormData();
          for (const key in jsonData) {
            if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
              const value = jsonData[key];
              body.append(key, value);
            }
          }

          return {
            method: 'POST',
            url: `temporary-uploads`,
            body,
          };
        },
      }
    ),
  }),
});

export type UploadTemporaryFileRequest = {
  data: any;
};

type Response = ResponseData<TemporaryFile>;

export const {useUploadTemporaryFileMutation} = uploadTemporaryFile;
