import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  accounting: () => __(translations.sidebar.accounting, 'Accounting'),
  admin: () => __(translations.sidebar.admin, 'Admin'),
  credits: () => __(translations.sidebar.credits, 'Credits'),
  bankCharges: () => __(translations.sidebar.bankCharges, 'Bank charges'),
  invoices: () => __(translations.sidebar.bill, 'Invoices'),
  commercial: () => __(translations.sidebar.commercial, 'Commercial'),
  customerOrder: () => __(translations.sidebar.customerOrder, 'Customer Order'),
  directory: () => __(translations.sidebar.directory, 'Directory'),
  directoryPage: () => __(translations.sidebar.directoryPage, 'Directory'),
  disputesAndAfterSalesService: () => __(translations.sidebar.disputesAndAfterSalesService, 'Disputes and after-sales service'),
  expenses: () => __(translations.sidebar.expenses, 'Expenses'),
  exports: () => __(translations.sidebar.exports, 'Exports'),
  exportsList: () => __(translations.sidebar.exportsList, 'exports list'),
  general: () => __(translations.sidebar.general, 'General'),
  giftInvoice: () => __(translations.sidebar.giftInvoice, 'Gift Invoice'),
  inStock: () => __(translations.sidebar.inStock, 'InStock'),
  inventory: () => __(translations.sidebar.inventory, 'inventory'),
  Location: () => __(translations.sidebar.Location, 'Location'),
  logistics: () => __(translations.sidebar.logistics, 'Logistics'),
  myExpenses: () => __(translations.sidebar.myExpenses, 'My Expenses'),
  notifications: () => __(translations.sidebar.notifications, 'Notifications'),
  onHold: () => __(translations.sidebar.onHold, 'OnHold'),
  overpayment: () => __(translations.sidebar.overpayment, 'Over payment'),
  payment: () => __(translations.sidebar.payment, 'Payment'),
  pricing: () => __(translations.sidebar.pricing, 'Pricing'),
  product: () => __(translations.sidebar.product, 'Product'),
  productInPreparation: () => __(translations.sidebar.productInPreparation, 'Product in preparation'),
  production: () => __(translations.sidebar.production, 'Production'),
  productionMonitoring: () => __(translations.sidebar.productionMonitoring, 'Production Monitoring'),
  projects: () => __(translations.sidebar.projects, 'Projects'),
  quotations: () => __(translations.sidebar.quotations, 'Quotations'),
  return: () => __(translations.sidebar.return, 'Return'),
  search: () => __(translations.sidebar.searchPlaceHolder, 'Search…'),
  settings: () => __(translations.sidebar.settings, 'Settings'),
  statistic: () => __(translations.sidebar.statistic, 'Statistic'),
  stock: () => __(translations.sidebar.stock, 'Stock'),
  stockOrder: () => __(translations.sidebar.stockOrder, 'Stockorder'),
  stockProduct: () => __(translations.sidebar.stockProduct, 'StockProduct'),
  studio: () => __(translations.sidebar.studio, 'Studio'),
  supplier: () => __(translations.sidebar.supplier, 'Supplier'),
  supplierOrder: () => __(translations.sidebar.supplierOrder, 'Supplier order'),
  timesheets: () => __(translations.sidebar.timesheets, 'Timesheets'),
  title: () => __(translations.sidebar.searchTitle, 'Search'),
  users: () => __(translations.sidebar.users, 'Users'),
  ecoTax: () => __(translations.sidebar.ecoTax, 'Ecotax'),
  colors: () => __(translations.sidebar.colors, 'Colors'),
  materials: () => __(translations.sidebar.materials, 'Materials'),
  oldErp: () => __(translations.sidebar.oldErp, 'Old ERP'),
};
