import {SubtotalPayload} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'costs/updateDiscount',
  function prepare(
    key: SubtotalPayload['key'],
    value: SubtotalPayload['value']
  ) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);
