import ItemPicker from 'components/item-picker';
import React, {FC, useContext} from 'react';
import transferContext from './transfer-context';
import {IndeterminateCheckbox} from 'features/table/compenents/column-checkbox';

interface Props {
  row: any;
}

export const TransferCheckbox: FC<Props> = props => {
  const {row} = props;
  const {isTransferred} = useContext(transferContext);

  return isTransferred(row.original) ? (
    <IndeterminateCheckbox
      {...{
        checked: row.getIsSelected(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler(),
      }}
    />
  ) : (
    <input
      type="checkbox"
      className={'form-check-input cursor-pointer'}
      checked={row.original.is_transferred_to_supplier_order}
      disabled={
        !row.original.is_abele_to_be_transfer_to_supplier_order ||
        row.original.is_transferred_to_supplier_order
      }
    />
  );
};
