import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdatePasswordMutation} from 'store/account/endpoints';
import {selectUser} from 'store/auth/selectors';
import toast from 'store/ui/actions/toast';

export const usePasswordForm = () => {
  const user = useSelector(selectUser);

  const [updatePassword, {isLoading, error}] = useUpdatePasswordMutation();

  const [params, setParams] = useState({
    email: user?.email,
    old_password: '',
    password: '',
    password_confirmation: '',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updatePassword({
      ...params,
      old_password: user?.has_password ? params.old_password : undefined,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const serverError = parseValidationErrors(error);

  return {
    setParams,
    params,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    isLoading,
    submit: handleSubmit,
  };
};
