import React, {FC} from 'react';
import {Fragment} from 'react';
import messages from '../messages';
import Actions from './actions';
import Sections from './sections';
import {DirectoryFilters} from './types';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import {FilterMap} from 'features/listing/list/slice/types';
import {useTranslation} from 'react-i18next';
import {CardBody, Row, Col, Label} from 'reactstrap';
import {FormGroup, Collapse} from 'reactstrap';
import {DirectoryCategory} from 'types/directory-category';
import {Option} from 'types/option';
import {useLocalStorage} from 'usehooks-ts';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<DirectoryFilters>;
  onChangeFilters: (key: DirectoryFilters, value: any) => void;
  onClearFilters?: () => void;
}

const CompanyDirectoryFilters: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  const directory_category =
    (filters['filter[directory_category_id]'] as DirectoryCategory[]) ?? [];

  const directory_category_id = directory_category
    .map((x: DirectoryCategory) => x.id)
    .join('|');

  return (
    <Fragment>
      <CardBody className="pb-0 mb-0">
        <Row className="mb-4">
          <Actions filters={filters} />
        </Row>

        {/* First row */}
        <SearchSection
          filters={filters}
          onChangeFilters={onChangeFilters}
          onClearFilters={onClearFilters}
        >
          <Row className="mb-3">
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/directory-categories"
                  getOptionLabel={(v: DirectoryCategory) => v.name}
                  className="mb-1"
                  label={t(...messages.category())}
                  value={filters['filter[directory_category_id]']}
                  isMulti
                  onChange={o =>
                    onChangeFilters('filter[directory_category_id]', o)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  key={`directory-families-${directory_category_id}`}
                  endPoint="data/directory-families"
                  otherParams={
                    directory_category_id
                      ? {directory_categories: directory_category_id}
                      : undefined
                  }
                  className="mb-1"
                  label={t(...messages.family())}
                  value={filters['filter[directory_family_id]']}
                  isMulti
                  onChange={o =>
                    onChangeFilters('filter[directory_family_id]', o)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <AsyncItemPicker
                endPoint="data/countries"
                label={t(...messages.country())}
                onChange={o => onChangeFilters('filter[country]', o)}
                value={filters['filter[country]']}
              />
            </Col>

            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/projects"
                  label={t(...messages.project())}
                  onChange={o => onChangeFilters('filter[project_id]', o)}
                  value={filters['filter[project_id]']}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/segments"
                  label={t(...messages.segment())}
                  onChange={o => onChangeFilters('filter[segment_id]', o)}
                  value={filters['filter[segment_id]']}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/turnovers"
                  getOptionLabel={(x: Option) => x?.label}
                  getOptionValue={(x: Option) => x?.value}
                  label={t(...messages.turnover())}
                  onChange={o => {
                    onChangeFilters(
                      'filter[turnover_id]',
                      o
                        ? {id: o?.value, value: o?.value, label: o?.label}
                        : null
                    );
                  }}
                  value={filters['filter[turnover_id]']}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/requirement-rates"
                  label={t(...messages.requirementRate())}
                  getOptionLabel={(x: Option) => x?.label}
                  getOptionValue={(x: Option) => x?.value}
                  onChange={o => onChangeFilters('filter[requirement_rate]', o)}
                  valueOnly
                  value={filters['filter[requirement_rate]']}
                />
              </FormGroup>
            </Col>

            <Col lg="3" md="3">
              <AsyncItemPicker
                endPoint="data/newsletter-lists"
                className="mb-1"
                label={t(...messages.newsletter())}
                onChange={o => {
                  onChangeFilters('filter[newsletter]', o);
                }}
                value={filters['filter[newsletter]']}
                isMulti
              />
            </Col>

            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_achived"
                    checked={!!filters['filter[trashed]']}
                    onChange={(o: any) =>
                      onChangeFilters('filter[trashed]', o.target?.checked)
                    }
                  />
                  <Label className="form-check-label" htmlFor="is_achived">
                    {t(...messages.archive())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_official_studio_supplier"
                    checked={!!filters['filter[is_official_studio_supplier]']}
                    onChange={(o: any) =>
                      onChangeFilters(
                        'filter[is_official_studio_supplier]',
                        o.target?.checked
                      )
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="is_official_studio_supplier"
                  >
                    {t(...messages.official_studio_supplier())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_official_furniture_supplier"
                    checked={
                      !!filters['filter[is_official_furniture_supplier]']
                    }
                    onChange={(o: any) =>
                      onChangeFilters(
                        'filter[is_official_furniture_supplier]',
                        o.target?.checked
                      )
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="is_official_furniture_supplier"
                  >
                    {t(...messages.official_furniture_supplier())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_official_suppliers_small_items"
                    checked={
                      !!filters['filter[is_official_suppliers_small_items]']
                    }
                    onChange={(o: any) =>
                      onChangeFilters(
                        'filter[is_official_suppliers_small_items]',
                        o.target?.checked
                      )
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="is_official_suppliers_small_items"
                  >
                    {t(...messages.official_suppliers_small_items())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </SearchSection>
        {/* Second row */}
        <Sections filters={filters} onChangeFilters={onChangeFilters} />
      </CardBody>
    </Fragment>
  );
};

export default CompanyDirectoryFilters;
