import {useState, useEffect} from 'react';
import {
  EditQuotationRequest,
  useEditQuotationMutation,
} from 'features/commercial/quotations/store/endpoints/edit-quotation';
import {selectQuotation} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {Quotation} from 'types/quotation';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useQuotationGeneralInformationForm = () => {
  const [submit, {isLoading, error, isSuccess}] = useEditQuotationMutation();

  const serverError = parseValidationErrors(error);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  const [params, setParams] = useState<Params>({
    created_at: '',
    deadline: '',
  });
  const {id} = useParams();

  const {data} = useSelector(selectQuotation({id}));

  const quotation = data?.data as Quotation;
  const is_locked = quotation?.status === 'VALIDATED';

  useEffect(() => {
    if (quotation) {
      setParams({
        id: quotation.id,
        client: quotation.directory,
        client_display_name: quotation.directory?.display_name,
        client_reference: quotation.ref_client,
        document_category: quotation.document_category,
        commercial_type: quotation.commercial_type,
        project: quotation.project,
        origin: quotation.origin,
        public_comment: quotation.public_comment,
        production_declared_completion_date:
          quotation.production_declared_completion_date,
        private_comment: quotation.private_comment,
        deadline_announced: quotation.deadline_announced,
        deadline_announced_fr: quotation.deadline_announced_fr,
        deadline_announced_en: quotation.deadline_announced_en,
        production_requested_deposit: quotation.production_requested_deposit,
        language: quotation.language,
        created_at: quotation.created_at,
        deadline: quotation.due_date,
        currency: quotation.currency,

        // vat and taxes
        vat_number: quotation.vat_number,
        vat_mode: quotation.vat_mode,
        with_vat: quotation.tax_mode === 'NO-VAT',
        is_detaxed: quotation.tax_mode === 'DETAXED',

        // transporter
        transporter: quotation.transporter,
        transporter_price: quotation.transporter_price,
      });
    }
  }, [quotation]);

  return {
    setParams,
    params,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    is_locked,
  };
};

type Params = EditQuotationRequest;
