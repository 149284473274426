import React from 'react';
import {Card, Col, Row} from 'reactstrap';
import HeaderSection from './header-section';
import BodySection from './body-section';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import Icon from '@ailibs/feather-react-ts';
import {useTranslation} from 'react-i18next';
import {useManDaysForm} from './use-man-days-form';
import ExportsSection from '../../shared/exports-section';
import Can from 'features/hylian-shield/can';

const ManDays = () => {
  const {t} = useTranslation();
  const form = useManDaysForm();

  return (
    <Card>
      <HeaderSection {...form} />
      <BodySection {...form} />
      <Row>
        <Col md={8}>
          <div className="justify-content-end d-flex">
            <AppBarActions>
              <>
                <Can action="edit" subject={form.subject}>
                  <Button
                    isLoading={form.isSubmitting}
                    onClick={form.submit}
                    color="important"
                  >
                    <Icon name="check" size={15} className="me-1" />
                    {t('common.saveChanges')}
                  </Button>
                </Can>
                <ExportsSection />
              </>
            </AppBarActions>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ManDays;
