import React, {FC, Fragment} from 'react';
import messages from '../messages';
import LabelLine from './label-line';
import {Label} from './use-action-order-send-label';
import {useTranslation} from 'react-i18next';
import {Table} from 'reactstrap';

const LabelRow: FC<Props> = props => {
  const {t} = useTranslation();
  const {labels, validationErrors, syncLabelSupplierEmail, updateLabel} = props;

  return (
    <div className="table-responsive-xl">
      <Table>
        <thead>
          <tr>
            <th>{t(...messages.sendLabelOrderSku())}</th>
            <th>{t(...messages.sendLabelOrderName())}</th>
            <th>{t(...messages.sendLabelOrderQuantity())}</th>
            <th>{t(...messages.sendLabelOrderArrival())}</th>
            <th>{t(...messages.sendLabelOrderGenerate())}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(labels).map((p, i) => (
            <Fragment key={i}>
              <LabelLine
                syncLabelSupplierEmail={syncLabelSupplierEmail}
                index={i}
                label={p}
                updateLabel={updateLabel}
                validationErrors={validationErrors}
              />
              {p.children &&
                Object.values(p.children).map((item, j) => (
                  <LabelLine
                    syncLabelSupplierEmail={syncLabelSupplierEmail}
                    key={`${i}-${j}`}
                    index={i}
                    label={item}
                    updateLabel={updateLabel}
                    childIndex={j}
                    validationErrors={validationErrors}
                  />
                ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

interface Props {
  labels: Label[];
  updateLabel: any;
  syncLabelSupplierEmail: any;
  validationErrors?: any;
}
export default LabelRow;
