import {useEffect} from 'react';
import {useCurrentQuote} from './quotation-context';
import {DocumentResponse} from 'features/commercial/documents/containers/document-context/document-context';
import hydrateFields from 'features/commercial/documents/store/actions/hydrate-fields';
import selectDocumentFormBody from 'features/commercial/documents/store/selectors/select-document-form-body';
import {actions} from 'features/commercial/documents/store/slice';
import {useUpdateQuotationDetailsMutation} from 'features/commercial/quotations/store/endpoints/update-quotation-details';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (): DocumentResponse => {
  const [commit, {isLoading, error, isSuccess, reset}] =
    useUpdateQuotationDetailsMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const payload = useSelector(selectDocumentFormBody);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await commit({
      id,
      ...payload,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const doc = useCurrentQuote();

  useEffect(() => {
    if (doc) {
      dispatch(hydrateFields(doc));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [doc]);

  return {
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    submit: handleSubmit,
    validationErrors: serverError.errors,
    reset,
    doc,
    doc_type: 'quotation',
    is_locked: doc?.status === 'VALIDATED',
  };
};
