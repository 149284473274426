import React, {FC} from 'react';
import {CommercialProduct} from 'features/commercial/documents/types/commercial-product';
import NameTD from '../../components/name-td/name-td';
import {ObjectId} from 'types/object-id';
import {Link} from 'react-router-dom';

interface Props {
  item: CommercialProduct;
  index: number;
  isSelected?: boolean;
  onToggle: (id: ObjectId) => void;
}

const TransferTableRow: FC<Props> = props => {
  const {item, isSelected, onToggle} = props;

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          onChange={() => onToggle(item.id)}
          checked={isSelected}
        />
      </td>
      <td>{item.variant?.sku}</td>
      <NameTD>
        <Link
          to={`/dashboard/products/${item.variant?.product?.id}/variants/${item.variant?.id}`}
        >
          {item.custom_name}
        </Link>
      </NameTD>
      <td>{item.quantity}</td>
    </tr>
  );
};

export default TransferTableRow;
