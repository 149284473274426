import React from 'react';
import messages from '../../messages';
import useContactForm from './use-contact-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import PhoneInputText from 'components/phone-input';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Row, Col, Alert, CardBody, Card} from 'reactstrap';
import {Option} from 'types/option';

const CreateContactForm = () => {
  const {t} = useTranslation();

  const {errorMessage, isLoading, params, setParams, submit, validationErrors} =
    useContactForm();

  const isSupplier =
    params.categories.findIndex((c: any) => c.type === 'SUPPLIER') !== -1;

  return (
    <Card>
      <CardBody>
        <Row className="mb-3">
          <Col md={2}>
            <AsyncItemPicker
              endPoint="data/user-titles"
              label={t(...messages.gender())}
              errors={validationErrors.gender}
              onChange={(gender: any) => setParams({...params, gender})}
              value={params.gender}
              getOptionLabel={(g: any) => g.label}
              getOptionValue={(g: any) => g?.value}
            />
          </Col>
          <Col md={3}>
            <TextInput
              type="text"
              label={t(...messages.first_name())}
              required
              errors={validationErrors.first_name}
              onChange={first_name => setParams({...params, first_name})}
              value={params.first_name}
            />
          </Col>
          <Col md={5}>
            <TextInput
              type="text"
              required
              label={t(...messages.last_name())}
              errors={validationErrors.last_name}
              onChange={last_name => setParams({...params, last_name})}
              value={params.last_name}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/countries"
              label={t(...messages.country())}
              errors={validationErrors.residency_country}
              onChange={residency_country =>
                setParams({...params, residency_country})
              }
              value={params.residency_country}
            />
          </Col>
          <Col md={5}>
            <TextInput
              type="text"
              label={t(...messages.job())}
              rows={4}
              value={params.job}
              errors={validationErrors.job}
              onChange={job => setParams({...params, job})}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={5}>
            <AsyncItemPicker
              required
              endPoint="data/directory-categories?directory_type=INDIVIDUAL "
              label={t(...messages.category())}
              onChange={categories => setParams({...params, categories})}
              value={params.categories}
              isMulti
              errors={validationErrors.directory_categories}
            />
          </Col>

          <Col md={5}>
            <AsyncItemPicker
              key={`directory-families-${params.categories
                ?.map(c => c.id)
                .join('|')}`}
              endPoint="data/directory-families"
              className="mb-1"
              otherParams={{
                directory_categories: params.categories
                  ?.map(c => c.id)
                  .join('|'),
              }}
              disabled={params.categories?.length === 0}
              label={t(...messages.family())}
              optionLabel=""
              errors={validationErrors.directory_families}
              isMulti
              value={params.families}
              onChange={families => setParams({...params, families})}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={2}>
            <AsyncItemPicker
              endPoint="data/phone-number-types"
              getOptionLabel={(x: Option) => x.label}
              getOptionValue={(x: Option) => x.value}
              label={t(...messages.type())}
              errors={validationErrors[`phone_number_type`]}
              onChange={phone_number_type =>
                setParams({...params, phone_number_type})
              }
              value={params.phone_number_type}
            />
          </Col>
          <Col md={3}>
            <PhoneInputText
              label={t(...messages.phone())}
              limitMaxLength={true}
              country="FR"
              enableSearch={true}
              international
              className="mb-1"
              value={params.phone}
              errors={validationErrors.phone}
              onChange={phone => setParams({...params, phone})}
            />
          </Col>
          <Col md={5}>
            <TextInput
              type="text"
              label={t(...messages.website())}
              rows={4}
              value={params.website}
              errors={validationErrors.website}
              onChange={website => setParams({...params, website})}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={5}>
            <TextInput
              type="text"
              label={t(...messages.email())}
              rows={4}
              value={params.email}
              errors={validationErrors.email}
              onChange={email => setParams({...params, email})}
            />
          </Col>
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/newsletter-lists"
              className="mb-1"
              label={t(...messages.newsletter())}
              optionLabel=""
              errors={validationErrors.newsletters}
              value={params.newsletters}
              isMulti
              onChange={newsletters => setParams({...params, newsletters})}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/users"
              label={t(...messages.referentContact())}
              getOptionLabel={(x: Option) => x.name}
              errors={validationErrors.reference_contact_id}
              onChange={reference_contact =>
                setParams({...params, reference_contact})
              }
              value={params.reference_contact}
            />
          </Col>
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/requirement-rates"
              label={t(...messages.requirementRate())}
              getOptionLabel={(x: Option) => x.label}
              getOptionValue={(x: Option) => x.value}
              errors={validationErrors.requirement_rate}
              onChange={requirement_rate =>
                setParams({...params, requirement_rate})
              }
              value={params.requirement_rate}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={10}>
            <TextInput
              rows={4}
              type="textarea"
              label={t(...messages.comment())}
              value={params.comment}
              errors={validationErrors.comment}
              onChange={comment => setParams({...params, comment})}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            {/* 1 */}
            {isSupplier && (
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_furniture_supplier: e.target.checked,
                      })
                    }
                    checked={params.is_official_furniture_supplier}
                    id="official-furniture-supplier"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-furniture-supplier"
                  >
                    {t(...messages.official_furniture_supplier())}
                  </label>
                </div>
                {/* 2 */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_studio_supplier: e.target.checked,
                      })
                    }
                    checked={params.is_official_studio_supplier}
                    id="official-studio-supplier"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-studio-supplier"
                  >
                    {t(...messages.official_studio_supplier())}
                  </label>
                </div>
                {/* 3 */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_suppliers_small_items: e.target.checked,
                      })
                    }
                    checked={params.is_official_suppliers_small_items}
                    id="official-suppliers-small-items"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-suppliers-small-items"
                  >
                    {t(...messages.official_suppliers_small_items())}
                  </label>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={10} className=" mb-4">
            <div>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </div>
            <AppBarActions>
              <Button isLoading={isLoading} onClick={submit} color="important">
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CreateContactForm;
