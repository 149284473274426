import React from 'react';
import {FC} from 'react';
import Can from 'features/hylian-shield/can';
import {MODAL} from 'features/modal-notch/constants';
import {Trash2} from 'react-feather';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {Product} from 'types/product';
import Button from 'components/button';

interface Props {
  product: Product;
}

const ProductMaterActions: FC<Props> = ({product}) => {
  const dispatch = useDispatch();

  const handleDeleteMany = () => {
    dispatch(
      showModal(MODAL.PRODUCT_DELETE_ACTIONS_MODAL, {
        productId: product.id ?? '',
        url: `/product-variants/force-delete-multiple`,
        path: '/dashboard/products',
      })
    );
  };

  /*   const destroyProduct = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: product.id,
        resourceName: 'products',
      })
    );
  }; */

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="administration" subject="products">
        <Button color="transparent" tag="button" onClick={handleDeleteMany}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default ProductMaterActions;
