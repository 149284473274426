import React, {FC} from 'react';
import messages from './messages';
import BarCharts from 'components/bar-charts';
import Currency from 'components/currency/currency';
import {useGetProductLinkedResourcesStatsQuery} from 'features/production/store/endpoints/get-product-linked-resources-stats';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

interface Props {}

const MasterProdudctStats: FC<Props> = props => {
  const {t} = useTranslation();

  const {id} = useParams();

  const {isLoading, data} = useGetProductLinkedResourcesStatsQuery({id});
  const stats = data?.data ?? [];
  const totalTurnOver = stats.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue.ca),
    0
  );
  const totalSales = stats.reduce(
    (accumulator, currentValue) =>
      accumulator + Number(currentValue.selling_count),
    0
  );

  const seriesS = [
    {
      name: t(...messages.turnover()),
      data: !stats || stats[0]?.year === 0 ? [] : stats.map(x => x.ca),
    },
  ];
  const years = stats.map(x => x.year.toString()) as string[];
  const seriesSNumberOfPoint = Math.max(...seriesS[0].data);

  const seriesX = [
    {
      name: t(...messages.sales()),
      data:
        !stats || stats[0]?.year === 0 ? [] : stats.map(x => x.selling_count),
    },
  ];
  const seriesXNumberOfPoint = Math.max(...seriesX[0].data);

  return (
    <Row>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0"> {t(messages.turnover())} </h4>

              <div>
                <span className="fw-bold">{t(...messages.total())} </span>
                <span className="float-right font-weight-bold">
                  {' '}
                  {<Currency value={totalTurnOver} />}{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={seriesS}
              categories={years}
              numberOfPoint={seriesSNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0"> {t(...messages.sales())} </h4>

              <div>
                <span className="fw-bold">{t(...messages.total())} </span>
                <span className="float-right font-weight-bold">
                  {' '}
                  {totalSales}{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={seriesX}
              categories={years}
              numberOfPoint={seriesXNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MasterProdudctStats;
