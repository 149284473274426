import React from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {NavLink} from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import showModal from 'store/ui/actions/show-modal';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import Can from 'features/hylian-shield/can';

const ActionsTab = () => {
  const {t} = useTranslation();

  const {id, variant} = useParams();

  const {data} = useSelector(selectVariant({id: variant, productId: id}));

  const {is_blocked} = data?.data || ({} as ProductVariant);

  const v = data?.data;
  const dispatch = useDispatch();

  const handleDuplicat = () => {
    dispatch(
      showModal(MODAL.VARIANT_DUPLICAT_ACTIONS_MODAL, {
        id: variant,
        productId: id as ObjectId,
        message: t(...messages.duplicateAlertMessage()),
      })
    );
  };

  const handleToProduct = () => {
    dispatch(
      showModal(MODAL.VARIANT_TO_PRODUCT_ACTIONS_MODAL, {
        variant: variant,
        productId: id as ObjectId
      })
    );
  };
  const handleDelete = () => {
    dispatch(
      showModal(MODAL.VARIANT_DELETE_ACTIONS_MODAL, {
        variant: v,

        message: t(...messages.deleteAlertMessage()),
      })
    );
  };
  const handleBlock = () => {
    dispatch(
      showModal(MODAL.VARIANT_BLOCK_ACTIONS_MODAL, {
        id: variant,

        message: t(...messages.blockAlertMessage()),
      })
    );
  };

  return (
    <div className="order-1 d-flex">
      <NavLink
        to={`/dashboard/products/${id}/general`}
        className="btn btn-light mx-2"
      >
        <i className="me-1"></i>
        <span>{t('productsPage.masterProduct')}</span>
      </NavLink>
      <Can action="create" subject="products">
        <NavLink
          to={`/dashboard/products/${id}/add-variant`}
          className="btn btn-light"
        >
          <Icon name="plus" size={16} />

          <i className="me-1"></i>
          <span>{t('editProductPage.createVariant')}</span>
        </NavLink>
      </Can>
      <Button
        color="light"
        className="text-nowrap ms-2"
        onClick={handleDuplicat}
      >
        <Icon name="copy" size={15} className="me-2" />
        {t('common.duplicat')}
      </Button>
      <Button color="light" className="ms-2" onClick={handleToProduct}>
        <Icon name="book" size={15} className="me-2" />
         {t(...messages.toOldCollection())}
      </Button>
      <Button color="light" className="ms-2" onClick={handleBlock}>
        <Icon name="archive" size={15} className="me-2" />
        {is_blocked
          ? t(...messages.unblockedVariant())
          : t(...messages.blockedVariant())}
      </Button>
      <Can action="export" subject="products">
        <UncontrolledDropdown className="ms-2">
          <DropdownToggle color="light">
            {t('productsPage.exports')}
            <Icon name="chevron-down" size={15} className="ms-1" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <li>
              <DropdownItem target={'_blank'} href={v?.pdf_links?.label}>
                {t('editProductPage.label')}{' '}
              </DropdownItem>
            </li>
            <li>
              <DropdownItem target={'_blank'} href={v?.pdf_links?.sheet}>
                {' '}
                {t('editProductPage.collection')}{' '}
              </DropdownItem>
            </li>
            <li>
              <DropdownItem target={'_blank'} href={v?.pdf_links?.costs}>
                {t('editProductPage.cost')}
              </DropdownItem>
            </li>

            <li>
              <DropdownItem
                target={'_blank'}
                href={v?.pdf_links?.technical_sheet_client}
              >
                {t('editProductPage.technicalSheetClient')}{' '}
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                target={'_blank'}
                href={v?.pdf_links?.technical_sheet_interne}
              >
                {t('editProductPage.technicalSheetInterne')}{' '}
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Can>
      <Can action="delete-and-merge" subject="products">
        <Button color="danger" className="ms-2" onClick={handleDelete}>
          {t(...messages.deleteVariant())}
        </Button>
      </Can>
    </div>
  );
};

export default ActionsTab;
