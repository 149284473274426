import React, {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import LoadRunner from 'components/load-runner';
import formatters from 'locales/messages';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Alert, Badge, Spinner, Table} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useGetPriceHistoryQuery} from 'store/production/endpoints/get-price-history';

interface Props {}

const HistoryTable: FC<Props> = props => {
  const {t} = useTranslation();

  const {variant} = useParams();

  const {isFetching, refetch, data, error, isError} = useGetPriceHistoryQuery({
    variant_id: variant,
  });

  const prices = data?.data ?? [];

  const {message} = parseValidationErrors(error);

  return (
    <div>
      <p>
        <strong>Historique des prix</strong>

        <Button
          onClick={refetch}
          isLoading={isFetching}
          size="sm"
          className="mx-2 "
          color="link"
        >
          {!isFetching && <Icon name="refresh-cw" size={16} />}
        </Button>
      </p>

      {isFetching && (
        <LoadRunner width={'100%'} height={400} viewBox="0 0 100% 200">
          <Fragment>
            <rect x="0%" y={'10%'} rx="10" ry="10" width="8%" height={20} />
            <rect x="10%" y={'10%'} rx="10" ry="10" width="45%" height={20} />
            <rect x="60%" y={'10%'} rx="10" ry="10" width="20%" height={20} />
          </Fragment>
        </LoadRunner>
      )}
      {isError && <Alert color="danger">{message}</Alert>}

      <Table>
        <thead>
          <tr>
            <th>{t(...messages.totalCosts())}</th>
            <th>{t(...messages.publicPriceTotal())}</th>
            <th>{t(...messages.date())}</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((item, index) => (
            <tr key={index}>
              <td>
                <Trans
                  i18nKey="numbers.currency"
                  values={{val: item.total_cost}}
                />
              </td>
              <td>
                <Trans
                  i18nKey="numbers.currency"
                  values={{val: item.public_price}}
                />
              </td>
              <td>
                {t(...formatters.intlDateTime(), {
                  val: new Date(item.created_at),
                  formatParams: {
                    val: {
                      year: 'numeric',
                      month: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      day: 'numeric',
                    },
                  },
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default HistoryTable;
