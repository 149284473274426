import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoiceActionModal.title, 'Actions on invoice'),
  cancel: () => __(translations.invoiceActionModal.cancel, 'Cancel'),
  yesImSure: () => __(translations.invoiceActionModal.yesImSure, "Yes, I'm sure"),
  duplicateInvoiceTitle: () => __(translations.invoiceActionModal.duplicateInvoiceTitle, 'Duplicate'),
  invoiceDirectory: () => __(translations.invoiceActionModal.invoiceDirectory, 'Client'),
  invoiceDuplicateSucees: () => __(translations.invoiceActionModal.invoiceDuplicateSucees, 'Invoice duplicated successfully…'),
  ///send email part start
  invoiceEmailSendSubject: () => __(translations.invoiceActionModal.invoiceEmailSendSubject, 'E-mail Subject'),
  invoiceEmailAdd: () => __(translations.invoiceActionModal.invoiceEmailAdd, 'Add'),
  invoiceEmailList: () => __(translations.invoiceActionModal.invoiceEmailList, 'Recipients'),
  invoiceEmail: () => __(translations.invoiceActionModal.invoiceEmail, 'E-mail'),
  withInvoice: () => __(translations.invoiceActionModal.withInvoice, 'invoice'),
  withStockList: () => __(translations.invoiceActionModal.withStockList, 'stock list'),
  withPackageList: () => __(translations.invoiceActionModal.withPackageList, 'package list'),

  ///send email part end
  ///generate credit part start
  invoiceCreditTitle: () => __(translations.invoiceActionModal.invoiceCreditTitle, 'Generate credit'),
  selectAll: () => __(translations.invoiceActionModal.selectAll, 'Select all'),
  invoiceCreditMessage: () =>
    __(
      translations.invoiceActionModal.invoiceCreditMessage,
      `Are you sure you want to generate a credit from this invoice? 
    you can select the products you want to generate a credit with.`
    ),
  invoiceCreditSuccess: () => __(translations.invoiceActionModal.invoiceCreditSuccess, 'Credit generated successfully…'),
  ///generate credit part end
  ///generate accounting credit part start
  invoiceAccountingCreditTitle: () => __(translations.invoiceActionModal.invoiceAccountingCreditTitle, 'Generate accounting credit'),
  invoiceAccountingCreditMessage: () =>
    __(translations.invoiceActionModal.invoiceAccountingCreditMessage, 'Are you sure you want to generate an accounting credit from this invoice? this action is irreversible.'),
};
