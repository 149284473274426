import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ObjectId} from 'types/object-id';
import removePayment from '../../store/actions/remove-payment';
import documentContext from '../../containers/document-context/document-context';
import {uiActions} from 'store/ui/ui-slice';
import {useSelector} from 'react-redux';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import {useParams} from 'react-router';

interface Props {
  id: ObjectId;
  is_locked?: boolean;
}

const PaymentRemovalButton: FC<Props> = props => {
  const {id} = props;

  const {t} = useTranslation();

  const {doc_type, doc, is_locked} = useContext(documentContext);

  const {id: doc_id} = useParams();

  const dispatch = useDispatch();

  const isRemote = useSelector(selectIsRemoteObject(id));

  const handleRemovePayment = () => {
    if (!doc_type) {
      throw new Error(`Unknown document type`);
    }
    if (isRemote) {
      dispatch(
        uiActions.confirmDelete({
          name: `payments`,
          otherParams: {doc_id},
          resourceId: id,
        })
      );
    } else {
      dispatch(removePayment(id));
    }
  };

  return is_locked ? null : (
    <Button onClick={handleRemovePayment} color="link" className="p-0 pt-1">
      <Icon name="trash-2" className="text-danger" />
    </Button>
  );
};

export default PaymentRemovalButton;
