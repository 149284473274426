import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import {useGenerateCredit} from './use-action-generate-accounting-credit';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';

const InvoiceGenerateAccountingCreditActionConfirm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {submit, isLoading, errorMessage} = useGenerateCredit(onDismiss);
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <p>{t(...messages.invoiceAccountingCreditMessage())} </p>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Alert color="danger" isOpen={!!errorMessage}>
            {errorMessage}
          </Alert>
        </Row>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.invoiceAccountingCreditTitle())}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default InvoiceGenerateAccountingCreditActionConfirm;
