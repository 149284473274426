import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.ecoTaxPage.title, 'Ecotax'),
  date: () => __(translations.ecoTaxPage.date, 'Date'),
  product_name: () => __(translations.ecoTaxPage.productName, 'Product name'),
  quantity: () => __(translations.ecoTaxPage.quantity, 'Quantity'),
  customs_code: () => __(translations.ecoTaxPage.customsCode, 'Customs code'),
  ecopart_code: () => __(translations.ecoTaxPage.ecopartCode, 'Ecopart code'),
  ecopart_rate: () => __(translations.ecoTaxPage.ecopartRate, 'Ecopart rate'),
  ecopart_rate_vat: () => __(translations.ecoTaxPage.ecopartRateVat, 'Ecopart rate vat'),
  eco_code: () => __(translations.ecoTaxPage.ecoCode, 'Eco code'),
  product_code: () => __(translations.ecoTaxPage.productCode, 'Product code'),
  addEcoTax: () => __(translations.ecoTaxPage.addEcoTax, 'Add ecotax'),
  status: () => __(translations.ecoTaxPage.status, 'Status'),
  delete: () => __(translations.ecoTaxPage.delete, 'Delete'),
  edit: () => __(translations.ecoTaxPage.edit, 'Edit'),
  label: () => __(translations.ecoTaxPage.label, 'Label'),
  unit_price: () => __(translations.ecoTaxPage.unitPrice, 'Unit price'),
  total_price: () => __(translations.ecoTaxPage.totalPrice, 'Total price'),
};
