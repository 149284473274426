import React from 'react';
import columns from './columns';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';

const MasterProductsPage = () => {
  const {t} = useTranslation();
  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t('common.products'), path: '/dashboard/products'},
            {title: t(...messages.masterProducts()), path: '#'},
          ]}
        />
        <Table columns={columns} url="products" name="products" />
      </Container>
    </div>
  );
};

export default MasterProductsPage;
