/**
 * Tag select
 *
 */
import React, {useMemo} from 'react';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {AsyncProps} from 'react-select/async';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
} from 'react-select/dist/declarations/src/types';
import {Label} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import useInputId from 'utils/use-input-id';

const TagSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ExtendedAsyncProps<Option, IsMulti, Group>
) => {
  const {
    options,
    reduceOption = 'name',
    pick = 'id',
    value,
    required,
    label,
    menuStyle,
    menuplacement,
    id: inputId,
    errors,
    onChange = () => {},
    ...rest
  } = props;

  const getOptionValue = (s: any) => {
    if (props.getOptionValue) {
      return props.getOptionValue(s);
    }
    const value = _.get(s, pick);
    return value;
  };

  const handleChange = (selected: Option) => {
    onChange(getOptionValue(selected));
  };

  const boundValue = useMemo(() => {
    return options?.find(x => getOptionValue(x) === value);
  }, [value, options]);

  const appTheme = useSelector(selectAppTheme);

  const selectThemeColors = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--bs-primary)', // for selected option bg-color
        neutral10: 'var(--bs-border-color)', // for tags border-color
        neutral20: 'var(--bs-border-color)', // for input border-color
        neutral0: appTheme === 'dark' ? 'var(--bs-input-bg)' : '#fff', // used
        neutral80: 'var(--bs-light-text)', // text color
      },
    };
  };

  const id = useInputId(inputId);

  return (
    <div className="">
      <span className="text-danger">{required && '* '}</span>
      <Label className="mb-0" htmlFor={id}>
        {label}
      </Label>
      <Select
        theme={selectThemeColors}
        className="react-select"
        menuPlacement={menuplacement}
        inputId={id}
        classNamePrefix="select"
        // @ts-ignore
        getOptionLabel={(v: Option) => v.name}
        // @ts-ignore
        getOptionValue={(v: Option) => v.id}
        styles={{
          control: (baseStyles: any) => ({
            ...baseStyles,
            borderColor: errors?.length ? 'red' : baseStyles.borderColor,
          }),
          menu: (provided: any) => ({
            ...provided,
            ...menuStyle,
          }),
        }}
        options={options}
        onChange={handleChange}
        value={boundValue ?? null}
        {...rest}
      />

      {errors ? <div className="invalid-feedback d-block">{errors}</div> : null}
    </div>
  );
};

TagSelect.defaultProps = {
  optionLabel: 'name',
  otherParams: {},
};

export default TagSelect;

// Additional props
interface ExtendedProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> {
  otherParams?: any;
  disabled?: boolean;
  defaultValue?: any;
  value?: any;
  reduceOption?: string;
  pick?: string;
  onChange: (x: string) => void;
  errors?: string | string[];
  required?: boolean;
  label?: string;
  menuplacement?: string;
  menuStyle?: any;
  // All other props
  // [x: string]: any;
}

// Merge the original AsyncProps with the ExtendedProps
type ExtendedAsyncProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = AsyncProps<Option, IsMulti, Group> & ExtendedProps<Option, IsMulti, Group>;
