import React, {FC, useContext, useState} from 'react';
import transferContext from './transfer-context';
import AsyncItemPicker from 'components/async-item-picker';
import {ObjectId} from 'types/object-id';

interface Props {
  row: any;
}

type Availability = {
  id: ObjectId;
  name: string;
  quantity: number;
};

export const ItemStock: FC<Props> = props => {
  let {row} = props;

  const [availability, setAvailability] = useState<Availability>();

  const {isTransferred, onUpdateItem} = useContext(transferContext);

  const uuid = row?.uuid ?? row?.uuid;
  const variant_id = row?.product_variant_id ?? row?.variant_id ?? '';

  return (
    <>
      <AsyncItemPicker
        endPoint={`data/stock-available/product-variants/${variant_id}`}
        value={availability}
        isClearable={false}
        getOptionLabel={(v: any) => `${v.name} | (${v?.quantity ?? 0})`}
        onChange={val => {
          setAvailability(val);
          onUpdateItem(uuid, 'stock_location_id', val.id);
        }}
        isDisabled={
          isTransferred(uuid) ||
          row.is_transferred_to_supplier_order ||
          !row?.is_abele_to_be_transfer_to_supplier_order ||
          !variant_id
        }
      />
    </>
  );
};
