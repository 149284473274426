import {ApiEndpointBuilder} from 'services/api/types';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

const updateProfileEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<ResponseData<User>, Request>({
    query: profileInfo => ({
      url: 'me/profile',
      method: 'PUT',
      body: profileInfo,
    }),
  });
};

type Request = {};

export default updateProfileEndpoint;
