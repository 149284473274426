import React, {FC} from 'react';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import {IPhasesForm} from '../use-phases-form';
import {Trash2} from 'react-feather';
import TextInput from 'components/text-input';
import DateInput from 'components/date-input';
import styled from 'styled-components';
import {CircularProgress} from 'components/circular-progress';
import AsyncItemPicker from 'components/async-item-picker';
import {Invoice} from 'features/commercial/invoices/types/invoice';
import {TotalRecap} from './total-recap';
import {ProjectPhasesEnum} from 'features/studio/projects/constants/project-phases';
import {Link} from 'react-router-dom';
import Icon from '@ailibs/feather-react-ts';
import {useParams} from 'react-router';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Can from 'features/hylian-shield/can';

const BodySection: FC<Props> = ({
  params,
  defaultOpenedAccordion,
  validationErrors,
  isDragging,
  subject,
  setIsDragging,
  onDragEnd,
  handleChangeGlobalComment,
  getPhaseProgression,
  handleRemoveInput,
  handleAddDeposit,
  handleChangeDeposit,
  handleRemoveDeposit,
}) => {
  const {t} = useTranslation();
  const {phases, phase_global_comment} = params;

  const {id: project_id} = useParams();

  const isDraggingStyle = {paddingBottom: isDragging ? '86px' : ''};

  return (
    <CardBody style={isDraggingStyle}>
      <TextInput
        label={t(...messages.globalComment())}
        value={phase_global_comment}
        rows={2}
        type="textarea"
        onChange={handleChangeGlobalComment}
      />
      <CardTitle tag="h4" className="mt-4">
        {t(...messages.phasesList())}
      </CardTitle>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {phases.length > 0 ? (
                <UncontrolledAccordion
                  stayOpen
                  defaultOpen={defaultOpenedAccordion}
                >
                  {phases.map((item, x) => (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={x}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="d-flex gap-2"
                        >
                          <Can action="edit" subject={subject}>
                            <Button
                              color="link"
                              size="sm"
                              className="dragHandle p-0"
                              {...provided.dragHandleProps}
                            >
                              <Icon name="menu" color="gray" size={20} />
                            </Button>
                          </Can>
                          <AccordionItem
                            key={x}
                            className={`flex-grow-1 ${
                              snapshot.isDragging && 'opacity-75'
                            }`}
                          >
                            <AccordionHeader targetId={x.toString()}>
                              <div className="d-flex justify-content-between w-100">
                                <h5
                                  color="light"
                                  className=" align-self-center"
                                >
                                  {item.type === ProjectPhasesEnum.OTHERS
                                    ? `${item.other_type_name}`
                                    : t(
                                        `editProjectPhaseSection.${item.type.toLowerCase()}`
                                      )}
                                </h5>
                                <CircularProgressWrapper className="me-2">
                                  <CircularProgress
                                    progression={getPhaseProgression(
                                      item.deposits
                                    )}
                                  />
                                </CircularProgressWrapper>
                              </div>
                            </AccordionHeader>
                            <AccordionBody accordionId={x.toString()}>
                              <div className="d-flex justify-content-between">
                                <TotalRecap deposits={item.deposits} />
                                <Can action="edit" subject={subject}>
                                  <div>
                                    <Button
                                      className=" me-2"
                                      onClick={() => handleAddDeposit(x)}
                                      color="important"
                                    >
                                      {t(...messages.addDeposit())}
                                    </Button>
                                    <Button
                                      color="danger"
                                      id={`tooltip-${x.toString()}`}
                                      onDoubleClick={() => handleRemoveInput(x)}
                                    >
                                      <Trash2 size={14} />
                                    </Button>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`tooltip-${x.toString()}`}
                                    >
                                      {t('common.doubleClickToRemove')}
                                    </UncontrolledTooltip>
                                  </div>
                                </Can>
                              </div>
                              <hr className="mt-4" />
                              {item.deposits.length > 0 ? (
                                <>
                                  {item.deposits.map((deposit, y) => (
                                    <div key={y}>
                                      <Row>
                                        <Col lg={2} md={4} sm={12}>
                                          <div className="d-flex gap-1">
                                            <TextInput
                                              type="number"
                                              label={t(...messages.honoraria())}
                                              value={deposit.honoraria}
                                              errors={
                                                validationErrors[
                                                  `phases.${x}.deposits.${y}.honoraria`
                                                ]
                                              }
                                              onChange={val =>
                                                handleChangeDeposit(
                                                  x,
                                                  y,
                                                  'honoraria',
                                                  val
                                                )
                                              }
                                            />
                                            <TextInput
                                              type="number"
                                              label={t(...messages.fees())}
                                              value={deposit.fees}
                                              errors={
                                                validationErrors[
                                                  `phases.${x}.deposits.${y}.fees`
                                                ]
                                              }
                                              onChange={val =>
                                                handleChangeDeposit(
                                                  x,
                                                  y,
                                                  'fees',
                                                  val
                                                )
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                          <DateInput
                                            label={t(
                                              ...messages.estimatedCompletionDate()
                                            )}
                                            value={
                                              deposit.estimated_completion_date
                                            }
                                            errors={
                                              validationErrors[
                                                `phases.${x}.deposits.${y}.estimated_completion_date`
                                              ]
                                            }
                                            onChange={val =>
                                              handleChangeDeposit(
                                                x,
                                                y,
                                                'estimated_completion_date',
                                                val
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                          <DateInput
                                            label={t(
                                              ...messages.completionDate()
                                            )}
                                            value={deposit.completion_date}
                                            errors={
                                              validationErrors[
                                                `phases.${x}.deposits.${y}.completion_date`
                                              ]
                                            }
                                            onChange={val =>
                                              handleChangeDeposit(
                                                x,
                                                y,
                                                'completion_date',
                                                val
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                          <AsyncItemPicker
                                            endPoint={`data/invoices?project_id=${project_id}`}
                                            errors={
                                              validationErrors[
                                                `phases.${x}.deposits.${y}.invoice`
                                              ]
                                            }
                                            getOptionLabel={(val: Invoice) => (
                                              <div
                                                className="d-flex justify-content-between align-items-center"
                                                style={{zIndex: -1}}
                                              >
                                                <div
                                                  style={{
                                                    width: '90%',
                                                  }}
                                                >
                                                  {val.document_ref}
                                                </div>
                                                <div
                                                  style={{
                                                    zIndex: 1,
                                                  }}
                                                >
                                                  <Link
                                                    target="_blank"
                                                    to={`/dashboard/invoices/${val.id}/details`}
                                                  >
                                                    <Icon
                                                      name="external-link"
                                                      size={15}
                                                    />
                                                  </Link>
                                                </div>
                                              </div>
                                            )}
                                            value={deposit.invoice}
                                            label={t(
                                              ...messages.invoiceNumber()
                                            )}
                                            onChange={val =>
                                              handleChangeDeposit(
                                                x,
                                                y,
                                                'invoice',
                                                val
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                          <DateInput
                                            label={t(...messages.paidOn())}
                                            value={deposit.paid_on}
                                            errors={
                                              validationErrors[
                                                `phases.${x}.deposits.${y}.paid_on`
                                              ]
                                            }
                                            onChange={val =>
                                              handleChangeDeposit(
                                                x,
                                                y,
                                                'paid_on',
                                                val
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                          <div className="d-flex gap-1">
                                            <TextInput
                                              type="text"
                                              label="Commentaire"
                                              value={deposit.comment}
                                              errors={
                                                validationErrors[
                                                  `phases.${x}.deposits.${y}.comment`
                                                ]
                                              }
                                              onChange={val =>
                                                handleChangeDeposit(
                                                  x,
                                                  y,
                                                  'comment',
                                                  val
                                                )
                                              }
                                            />
                                            <Can
                                              action="edit"
                                              subject={subject}
                                            >
                                              <Button
                                                color="danger"
                                                className="d-inline-block align-self-end"
                                                id={`tooltip-${(
                                                  x + y
                                                ).toString()}`}
                                                onDoubleClick={() =>
                                                  handleRemoveDeposit(x, y)
                                                }
                                              >
                                                <Trash2 size={14} />
                                              </Button>
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={`tooltip-${(
                                                  x + y
                                                ).toString()}`}
                                              >
                                                {t(
                                                  'common.doubleClickToRemove'
                                                )}
                                              </UncontrolledTooltip>
                                            </Can>
                                          </div>
                                        </Col>
                                      </Row>
                                      <hr
                                        className="mt-4"
                                        style={{
                                          display:
                                            y < item.deposits.length - 1
                                              ? 'block'
                                              : 'none',
                                        }}
                                      ></hr>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <p className=" text-center">
                                  {t('common.noData')}
                                </p>
                              )}
                            </AccordionBody>
                          </AccordionItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </UncontrolledAccordion>
              ) : (
                <div className="d-flex justify-content-center py-4">
                  <p>{t('common.noData')}</p>
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </CardBody>
  );
};

const CircularProgressWrapper = styled.div`
  width: 34px;
  height: 34px;
`;

type Props = IPhasesForm;

export default BodySection;
