import {api} from 'services/api';
import {Material} from 'types/materials';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const storeMaterial = api.injectEndpoints({
  endpoints: builder => ({
    storeMaterial: builder.mutation<Response, StoreMaterialRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `materials`,
          body: {
            name: {
              en: params.name.en,
              fr: params.name.fr,
            },
          },
        };
      },
    }),
  }),
});

export type StoreMaterialRequest = {
  id?: ObjectId;
  name: {en: string; fr: string};
};

type Response = ResponseData<Material>;

export const {useStoreMaterialMutation} = storeMaterial;
