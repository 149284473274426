import {extraReducers} from './extra-reducers';
import {initialState} from './initial-state';
import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {},
  extraReducers,
});

export const userActions = userSlice.actions;

export default userSlice;
