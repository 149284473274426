import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const exportStockComptableSupplierOrder = api.injectEndpoints({
  endpoints: builder => ({
    exportStockComptableSupplierOrder: builder.query<Response, Request>({
      query: () => {
        return {
          method: 'GET',
          url: `export/stock-comptable-supplier-order`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{}>;

export const {useLazyExportStockComptableSupplierOrderQuery} =
  exportStockComptableSupplierOrder;
