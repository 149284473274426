import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {DirectoryAttachment} from 'types/attachements';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateDirectoryAttachments = api.injectEndpoints({
  endpoints: builder => ({
    updateDirectoryAttachments: builder.mutation<
      Response,
      DirectoryAttachmentsRequest
    >({
      query: params => ({
        method: 'POST',
        url: `/directories/${params.id}/attachements`,
        body: {
          id: params.id,
          deleted_records: params.deleted_records,
          attachements: params.attachments.map(x => ({
            id: x.id,
            name: x.name,
            file_id: x.file?.id,
            date: x.date,
            type: x.type?.value,
          })),
        },
      }),
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;
          dispatch(updateCachedDirectoryFromResource(directory));
        } catch (e) {}
      },
    }),
  }),
});

export type DirectoryAttachmentsRequest = {
  id?: ObjectId;
  attachments: DirectoryAttachment[];
  deleted_records: ObjectId[];
};

type Response = ResponseData<Directory>;

export const {useUpdateDirectoryAttachmentsMutation} =
  updateDirectoryAttachments;
