import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  warning: () => __(translations.directorySummarySection.warning, 'Warning!'),
  duplicatesWarning: () =>
    __(
      translations.directorySummarySection.duplicatesWarning,
      'Possible duplicates were detected within this directory record. To maintain accurate and reliable data, an action is required to address this issue'
    ),
  email: () => __(translations.directorySummarySection.email, 'Email'),
  phone: () => __(translations.directorySummarySection.phone, 'Phone'),
  company: () => __(translations.directorySummarySection.company, 'Company'),
  status: () => __(translations.directorySummarySection.status, 'Status'),
  lastPurchase: () => __(translations.directorySummarySection.lastPurchase, 'Last Purchase'),
  avargePurchase: () => __(translations.directorySummarySection.avargePurchase, 'Average Purchase'),
};
