import {ListState} from './types';
import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

/**
 * Listing slice extra reducers
 *
 * @param builder
 */
export const extraReducers = (
  builder: ActionReducerMapBuilder<ListState>
) => {};
