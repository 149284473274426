import React, {FC, useState} from 'react';
import messages from '../messages';
import invoiceColumns from './columns';
import CollapseTable from 'components/collapse-table/collapse-table';
import {useTranslation} from 'react-i18next';
import {Button} from 'reactstrap';
import {ObjectId} from 'types/object-id';

interface Props {
  id?: ObjectId;
}

const InvoiceTable: FC<Props> = props => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const filterDAte = [
    new Date('2015/01/01').toISOString(),
    new Date().toISOString(),
  ].join(',');
  const {id} = props;
  return (
    <div>
      <Button color="light" onClick={toggle} className="mb-3 w-100">
        {t(...messages.invoice())}
      </Button>
      <CollapseTable
        isOpen={isOpen}
        columns={invoiceColumns}
        url={`invoices?filter[variant_id]=${id}&limit=10&filter[billing_date]=${filterDAte}&for_stats=true`}
        name="directory-invoices"
      />
    </div>
  );
};

export default InvoiceTable;
