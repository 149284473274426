import {selectDomain} from '.';
import {createSelector} from 'reselect';
import {User} from 'types/user';

/**
 * Returns the actual user status
 *
 */
export const selectUserStatus = (user: User) => {
  return createSelector([selectDomain], userState => {
    if (typeof userState.statuses[user.id] === 'string') {
      return userState.statuses[user.id];
    }
    return user.status;
  });
};
