import {AuthState} from './types';
import {createSelector} from 'reselect';
import {RootState} from 'store';

const selectDomain = (state: RootState): AuthState => state.auth;

export const selectFcmToken = createSelector([selectDomain], authState => {
  return authState.fcmToken;
});

export const selectUser = createSelector([selectDomain], authState => {
  return authState.user;
});

export const selectProfile = createSelector(
  [selectDomain],
  (authState: AuthState) => {
    return authState.user?.profile;
  }
);

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (authState: AuthState) => {
    return !!authState.user && !!authState.accessToken;
  }
);

export const selectIsAuthProviderSet = createSelector(
  [selectDomain],
  (authState: AuthState) => {
    return (
      authState.socialAuth.provider_token !== null &&
      authState.socialAuth.provider_name !== null
    );
  }
);

export const selectSocialAuthInfo = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.socialAuth
);

export const selectAccessToken = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.accessToken
);

export const selectIsPasswordResetTokenRequested = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.resetPassword.passwordResetTokenRequested
);

export const selectResetToken = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.resetPassword.resetToken
);

export const selectResetInfo = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.resetPassword
);

export const selectIsPasswordResetFinished = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.resetPassword.passwordResetFinished
);

export const selectSignupRole = createSelector(
  [selectDomain],
  (authState: AuthState) => authState.signupRole
);
