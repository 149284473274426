import en from './en/translation.json';
import fr from './fr/translation.json';
import {convertLanguageJsonToObject} from './translations';
import {application} from 'constants/application';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

export const translationsJson = {
  en: {translation: en},
  fr: {translation: fr},
};

convertLanguageJsonToObject(en);

export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translationsJson,
    lng: application.defaultLocale,
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
