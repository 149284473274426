import {
  getDirectoryInteractions,
  updateCachedDirectoryInteraction,
} from './get-directory-interactions';
import {api} from 'services/api';
import {Interaction} from 'types/interaction';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const upsertInteraction = api.injectEndpoints({
  endpoints: builder => ({
    upsertInteraction: builder.mutation<Response, UpsertInteractionRequest>({
      query: params => {
        const isEdit = !!params.id;
        const url = isEdit
          ? `directories/${params.directory_id}/interactions/${params.id}`
          : `directories/${params.directory_id}/interactions`;
        return {
          method: isEdit ? 'PUT' : 'POST',
          url,
          body: {
            comment: params.comment,
            directory_id: params.directory_id,
            interaction_type: params.interaction_type?.value,
          },
        };
      },
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const interaction = (await queryFulfilled).data.data;
          await dispatch(updateCachedDirectoryInteraction(interaction));
        } catch (e) {}
      },
    }),
  }),
});

export type UpsertInteractionRequest = {
  id?: ObjectId;
  comment?: string;
  interaction_type?: Option;
  directory_id?: ObjectId;
};

type Response = ResponseData<Interaction>;

export const {useUpsertInteractionMutation} = upsertInteraction;
