import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.updateTranslationsPage.title, 'Translations'),
  label: () => __(translations.updateTranslationsPage.label, 'Label'),
  dimensions: () => __(translations.updateTranslationsPage.dimensions, 'Dimensions'),
  finishes: () => __(translations.updateTranslationsPage.finishes, 'Finishes'),
  description: () => __(translations.updateTranslationsPage.description, 'description'),
  nothing: () => __(translations.updateTranslationsPage.nothing, 'Nothing…'),
  language: () => __(translations.updateTranslationsPage.language, 'Language'),
  addTranslation: () => __(translations.updateTranslationsPage.addTranslation, 'Add translation'),
};
