import React, {useContext} from 'react';
import CostsContext from './costs-context';
import DebugView from './debug-view';
import DecoratorPrice from './decorator-price';
import messages from './messages';
import PublicPrice from './public-price';
import PurchaseCosts from './purchase-costs';
import ResellerPrice from './reseller-price';
import changeSituation from './slice/actions/change-situation';
import copyVariantComponents from './slice/actions/copy-variant-components';
import updateSituation from './slice/actions/update-situation';
import selectCurrentSituation from './slice/selectors/select-current-situation';
import selectSituationFields from './slice/selectors/select-situation-fields';
import Icon from '@ailibs/feather-react-ts';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Button, Col, Input, Label, Row} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {ProductVariant} from 'types/product-variant';
import {Situation} from 'types/situation';
import Can from 'features/hylian-shield/can';

const __DEV__ = process.env.NODE_ENV === 'development';

const TotalTableHeader = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const situation = useSelector(selectCurrentSituation);

  const situationFields = useSelector(selectSituationFields);

  const {validationErrors, reset} = useContext(CostsContext);

  const {id, variant: variantId} = useParams();

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data as ProductVariant;

  const handleCreateNew = () => {
    dispatch(copyVariantComponents(variant));
    reset();
  };

  const handleChangeSituation = (s?: unknown | Situation) => {
    dispatch(changeSituation(s));
    reset();
  };

  return (
    <>
      {__DEV__ && <DebugView />}
      <Row>
        <Col md={3}>
          <ItemPicker
            options={variant.price_situations}
            getOptionLabel={(x: Situation) => (
              <div className="d-flex justify-content-between">
                {x.name}
                <span>
                  {x.is_main && (
                    <Icon
                      name="check-circle"
                      size={14}
                      color="var(--bs-info-text)"
                    />
                  )}
                </span>
              </div>
            )}
            value={situation}
            onChange={handleChangeSituation}
          />
        </Col>
        <Col md={6} />
        <Col md={3}>
          <div className="d-flex flex-row justify-content-end align-items-end h-100">
            <Can action="edit" subject="product-prices">
              <Button
                disabled={!situation}
                className="btn btn-light"
                onClick={handleCreateNew}
              >
                <Icon name="plus" size={18} className="me-1" />
                {t(...messages.addSituation())}
              </Button>
            </Can>
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={3}>
          <TextInput
            errors={validationErrors['situation_name']}
            label={t(...messages.name())}
            value={situationFields?.name}
            onChange={x => dispatch(updateSituation('name', x))}
          />
        </Col>
        <Col md={3}>
          <div className="form-check form-switch  mb-3">
            <div>
              <Label htmlFor="customSwitchsizelg">&nbsp;</Label>
            </div>
            <Input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizelg"
              checked={situationFields?.is_main}
              onChange={x =>
                dispatch(updateSituation('is_main', x.target.checked))
              }
            />
            <label
              className="form-check-label text-light-emphasis"
              htmlFor="customSwitchsizelg"
            >
              Utiliser cette situation par défaut
            </label>
          </div>
        </Col>
      </Row>
      <Row>
        <PurchaseCosts />

        <PublicPrice />
        <DecoratorPrice />
        <ResellerPrice />
      </Row>
    </>
  );
};

export default TotalTableHeader;
