import React, {Fragment} from 'react';
import ActionsTab from '../actions';
import OtherInfo from '../info-section';
import ProductRoutes from '../product-routes';
import ProductTabPane from '../product-tab-pane';
import SummarySection from '../summary-section';
import VariantDetailsSection from '../variant';
import VariantTabPane from '../variant-tab-pane';
import VariantsList from '../variants-list';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import LoadRunner from 'components/load-runner';
import {useParams} from 'react-router';
import {Row, Col, Card, CardBody, Alert, Button} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useShowProductQuery} from 'store/production/endpoints/show-product';
import {Product} from 'types/product';

const ProductDetailsSection = () => {
  const {id, variant} = useParams();

  const {data, isLoading, error, isError, isSuccess, refetch} =
    useShowProductQuery({id});
  const product = data?.data as Product;

  const serverError = parseValidationErrors(error);

  return (
    <React.Fragment>
      <Row>
        <Col xl={9} lg={8}>
          <Card>
            <CardBody className="pt-0">
              {isSuccess && <SummarySection product={product} />}
              {!!variant && <VariantTabPane />}
              {!variant && <ProductTabPane />}
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {isLoading && (
                <div className="d-flex justify-content-center">
                  <LoadRunner width={'100%'} height={80} viewBox="0 0 100% 60">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
                    <rect x="0" y="40" rx="5" ry="5" width="85%" height="20" />
                  </LoadRunner>
                </div>
              )}

              {isError && (
                <div>
                  <Alert color="danger">{serverError.message}</Alert>
                  <div className="d-flex justify-content-center py-3">
                    <Button onClick={refetch}>
                      <Icon name="refresh-cw" size={15} className="me-2" />
                      Reload
                    </Button>
                  </div>
                </div>
              )}
              {/* DO NOT TOUCH THIS CONDITION, NO MATTER WHAT */}
              {isSuccess && (
                <Fragment>
                  {!!variant ? <VariantDetailsSection /> : <ProductRoutes />}

                  {!variant && (
                    <AppBarActions>
                      <ActionsTab />
                    </AppBarActions>
                  )}
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} lg={4}>
          <VariantsList />
          {isSuccess && variant && <OtherInfo product={product} />}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProductDetailsSection;
