export const PerPageOptions = [
  {
    id: '100',
    name: 100,
  },
  {
    id: '200',
    name: 200,
  },
  {
    id: '300',
    name: 300,
  },
  {
    id: '500',
    name: 500,
  },
];
