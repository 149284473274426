import React, {FC, Fragment, ReactElement, useState} from 'react';
import OrderSectionByType from '../../components/order-section-by-type';
import {CompactSupplierOrder, Order} from '../../types/order';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import Percent from 'components/percent/percent';
import Currency from 'components/currency/currency';
import {Alert, Col, Collapse, Row} from 'reactstrap';
import {ArrowDown, ArrowUp} from 'react-feather';

interface Props {
  order: Order;
  onReload: () => void;
}
interface ItemProps {
  label: string;
  value: string | number | undefined | ReactElement;
}
const SummaryItem: FC<ItemProps> = props => {
  const {label, value} = props;
  return (
    <p className="me-3 mb-1">
      <span className="fw-bold">{label}: </span>
      <span className="ms-1">{value ? value : '-'}</span>
    </p>
  );
};
const OrderSummarySection: FC<Props> = props => {
  const {order, onReload, ...all} = props;

  const {t} = useTranslation();

  const [isCollapseOpen, setOpenCollapse] = useState(false);

  return (
    <div>
      <Fragment>
        <Row>
          <div className="col-sm order-2 order-sm-1">
            <div className="d-flex align-items-start mt-3">
              <div className="flex-grow-1">
                <div>
                  <h1 className="text-primary" data-testid="order-reference">
                    {order.document_ref}
                  </h1>
                </div>
                <div
                  style={{width: '60%'}}
                  className="d-flex flex-wrap mt-2 mb-2"
                >
                  {order.project && (
                    <SummaryItem
                      label={t(...messages.projectName())}
                      value={order.project?.name}
                    />
                  )}
                  {true && (
                    <SummaryItem
                      label={t(...messages.date())}
                      value={
                        <FormattedDate format="date" value={order?.due_date} />
                      }
                    />
                  )}
                </div>

                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 font-size-13">
                  <div>
                    <div className="d-flex flex-wrap gap-2">
                      <span className="fw-bold">{t(...messages.status())}</span>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex flex-wrap gap-2 font-size-16 mb-2">
                      <StatusBadge status={order.status} />
                    </div>
                  </div>
                </div>

                <div
                  style={{width: '60%'}}
                  className="d-flex flex-wrap mt-2 mb-2"
                >
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.client())}</span>
                    <span className="ms-1">
                      <Link
                        to={`/dashboard/directories/${order.directory?.id}/general`}
                        target="_blank"
                      >
                        {order.directory?.display_name}
                      </Link>
                    </span>
                  </p>
                  <SummaryItem
                    label={t(...messages.contact())}
                    value={order.user?.name}
                  />
                  <SummaryItem
                    label={t(...messages.clientFamily())}
                    value={order.directory?.directory_families
                      .map(fam => fam.name)
                      .join(',')}
                  />
                </div>

                <Collapse isOpen={isCollapseOpen}>
                  <OrderSectionByType orderTypeToDisplay={['SUPPLIER']}>
                    <div style={{width: '60%'}} className="d-flex flex-wrap">
                      <p className="me-3 mb-0">
                        <span className="fw-bold">
                          {t(...messages.supplier())}
                        </span>
                        <span className="ms-1">
                          <Link
                            to={`/dashboard/directories/${
                              (order as unknown as CompactSupplierOrder)
                                .supplier_id
                            }/general`}
                            target="_blank"
                          >
                            {
                              (order as unknown as CompactSupplierOrder)
                                .supplier_name
                            }
                            <Icon
                              className="ms-1"
                              name="external-link"
                              size={14}
                            />
                          </Link>
                        </span>
                      </p>
                    </div>
                  </OrderSectionByType>
                  <OrderSectionByType orderTypeToDisplay={['CLIENT']}>
                    <div style={{width: '60%'}} className="d-flex flex-wrap">
                      <p className="me-3 mb-1">
                        <span className="fw-bold">
                          {t(...messages.totalPurchaseCostsExcl())} :
                        </span>
                        <span className="ms-1">
                          <Trans
                            i18nKey="numbers.currency"
                            values={{val: order.marge_infos?.purchase_price_ht}}
                          />
                        </span>
                      </p>

                      <p className="me-3 mb-1">
                        <span className="fw-bold">
                          {t(...messages.totalSellingPriceExcl())} :
                        </span>
                        <span className="ms-1">
                          <Trans
                            i18nKey="numbers.currency"
                            values={{val: order.marge_infos?.selling_price_ht}}
                          />
                        </span>
                      </p>
                      <p className="me-3 mb-1">
                        <span className="fw-bold">
                          {t(...messages.marginExcl())} :
                        </span>
                        <span className="ms-1 d-inline">
                          <Currency value={order.marge_infos?.marge_ht} />

                          {` | `}
                          <Percent value={order.marge_infos?.marge_percent} />
                          {`  |  `}
                          <NaturalNumber
                            value={order.marge_infos?.marge_coef}
                          />
                        </span>
                      </p>
                    </div>
                  </OrderSectionByType>

                  <div
                    style={{width: '60%'}}
                    className="d-flex flex-wrap mt-2 mb-2"
                  >
                    <SummaryItem
                      label={t(...messages.transporter())}
                      value={order.transporter?.display_name}
                    />
                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.transparterPrice())} :
                      </span>
                      <span className="ms-1">
                        <Currency value={order.transporter_price} />
                      </span>
                    </p>
                  </div>
                </Collapse>
                <a
                  href="#"
                  onClick={() => setOpenCollapse(!isCollapseOpen)}
                  className="link-underline"
                  id="toggler"
                >
                  {t(...messages.moreInformations())}
                  {isCollapseOpen ? (
                    <ArrowUp size={14} />
                  ) : (
                    <ArrowDown size={14} />
                  )}
                </a>
              </div>
            </div>
          </div>
        </Row>

        <Row className="mt-3">
          <Col>
            {/* TODO: conditionally show this alert */}
            {false && (
              <Alert color="warning">
                <h4 className="alert-heading">Alert title</h4>
                <p className="mb-0">Something comes here</p>
              </Alert>
            )}
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

export default OrderSummarySection;
