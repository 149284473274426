import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {IContact} from 'types/project';

const updateProject = api.injectEndpoints({
  endpoints: builder => ({
    updateProject: builder.mutation<Response, UpdateProjectRequest>({
      query: params => ({
        method: 'PUT',
        url: `/projects/${params.id}`,
        body: {
          ...params,
          directory_id: params.client?.id,
        },
      }),
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type UpdateProjectRequest = {
  id?: ObjectId;
  name: string;
  internal_name?: string;
  description?: string;
  designation?: string;
  client?: Directory;
  client_notice: string;
  project_type: string;
  status: string;
  contacts: IContact[];
  starts_at: string | undefined;
  ends_at: string | undefined;
  is_online: boolean;
};

type Response = ResponseData<UpdateProjectRequest>;

export const {useUpdateProjectMutation} = updateProject;
