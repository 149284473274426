import {ApiEndpointBuilder} from 'services/api/types';

const forgotPasswordEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation({
    query: (info: {email: string}) => ({
      url: 'forgot-password',
      method: 'POST',
      body: {
        email: info.email,
      },
    }),
  });
};

export default forgotPasswordEndpoint;
