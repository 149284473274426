import React from 'react';
import LoadRunner from 'components/load-runner';

const CardSkeleton = () => {
  const height = 15;
  return (
    <LoadRunner width={'100%'} height={400} viewBox="0 0 100% 200">
      <rect x="6%" y={'5%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'5%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'15%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'15%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'25%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'25%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'35%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'35%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="0" y={'45%'} rx="20" ry="20" width="100%" height={1} />
      {/* second row */}
      <rect x="45%" y={'50%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'50%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'60%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'60%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'70%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'70%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="45%" y={'80%'} rx="10" ry="10" width="50%" height={height} />
      <rect x="6%" y={'80%'} rx="10" ry="10" width="30%" height={height} />{' '}
      <rect x="0" y={'90%'} rx="20" ry="20" width="100%" height={1} />
    </LoadRunner>
  );
};

export default CardSkeleton;
