import {STATUS} from 'constants/status';

export enum ProjectPhasesEnum {
  RETAINER = 'RETAINER', // Retainer ** NEW **
  DESIGN_PROGRAMMING = 'DESIGN_PROGRAMMING', // Esquisse / Programmation
  PROJECT_PRELIMINARY_DESIGN = 'PROJECT_PRELIMINARY_DESIGN', // Avant projet sommaire
  PROJECT_FINAL_DESIGN = 'PROJECT_FINAL_DESIGN', // Avant projet définitif
  FINAL_PROJECT = 'FINAL_PROJECT', // Projet définitif
  DESIGN_COORDINATION = 'DESIGN_COORDINATION', // Coordination de conception
  FURNITURE_FFE = 'FURNITURE_FFE', // Mobilier / FF&E ** NEW **
  STYLING = 'STYLING', // Stylisme ** NEW **
  SAMPLE_ROOM = 'SAMPLE_ROOM', // Chmabre témoin
  TYPOLOGY_BREAKDOWN = 'TYPOLOGY_BREAKDOWN', // Déclinaison des typologies
  ARCHITECTURAL_CHARTER = 'ARCHITECTURAL_CHARTER', // Charte architecturale
  INTELLECTUAL_PROPERTY_RIGHTS_TRANSFER = 'INTELLECTUAL_PROPERTY_RIGHTS_TRANSFER', // Cession de droits de propriété intellectuelle ** NEW *
  COMMUNICATION_PRESS = 'COMMUNICATION_PRESS', // Communication / Relation presse ** NEW **
  ADDITIONAL_SPACES = 'ADDITIONAL_SPACES', // Espaces additionnels ** NEW **
  OTHERS = 'OTHERS', // Autres ** NEW **
}

export enum ProjectFeesStatus {
  PENDING = STATUS.PENDING,
  COMPLETED = STATUS.COMPLETED,
}
