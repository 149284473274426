import React, {FC, Fragment} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import StockAvailabilityTable from './stock-availability-table';
import StockMovementsForm from './stock-movements-form';

interface Props {
  onDismiss?: () => void;
}

const StockMovementModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {item} = useSelector(selectActiveModalParams);

  const isLoading = false;

  const submit = () => {};

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>
      <StockMovementsForm
        onDismiss={onDismiss}
        availabilityTable={<StockAvailabilityTable item={item} />}
      />
    </Fragment>
  );
};

export default StockMovementModal;
