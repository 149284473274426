import styled from 'styled-components';

export const RemovalContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 27px;
  cursor: pointer;
`;

export default RemovalContainer;
