import messages from './messages';

export default [
  {
    to: 'general',
    title: messages.general,
  },
  {
    to: 'details',
    title: messages.details,
  },
  {
    to: 'related-resources',
    title: messages.relatedResources,
  },
 
  {
    to: 'logs',
    title: messages.logs,
  },
];
