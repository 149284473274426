import React from 'react';
import messages from './messages';
import {useOrderGeneralInformationForm} from './use-order-general-informtations-form';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import OrderSectionByType from 'features/commercial/orders/components/order-section-by-type';
import GetOptionLabel from 'pages/commercial/customer-orders/create/get-option-label';
import NoOptionMessage from 'pages/commercial/customer-orders/create/no-option-message';
import {useTranslation} from 'react-i18next';
import {Alert, Col, Row} from 'reactstrap';
import {Directory} from 'types/directory';
import {Option} from 'types/option';
import OrderVatAndTaxes from '../vat-and-taxes';
import OrderProduction from '../production';
import OrderSupplier from '../supplier';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import OrderTransporter from '../order-transporter';

const OrderGeneralInformation = () => {
  const {t} = useTranslation();
  const {
    params,
    validationErrors,
    isLoading,
    errorMessage,
    submit,
    isSuccess,
    is_locked,
    setParams,
  } = useOrderGeneralInformationForm();
  return (
    <div>
      <Row>
        <Col>
          <SectionSeparator title={t(...messages.title())} />

          <Row>
            <Col md={12}>
              <OrderSectionByType orderTypeToDisplay={['SUPPLIER']}>
                <TextInput
                  disabled
                  label={t(...messages.supplier())}
                  data-testid="product-name-input"
                  value={params.supplier_name}
                  onChange={supplier_name => {
                    setParams({
                      ...params,
                      supplier_name,
                    });
                  }}
                />
              </OrderSectionByType>
              <OrderSectionByType orderTypeToDisplay={['CLIENT', 'STOCK']}>
                <TextInput
                  disabled
                  label={t(...messages.client())}
                  data-testid="product-name-input"
                  value={params.client_display_name}
                  onChange={client_display_name => {
                    setParams({
                      ...params,
                      client_display_name,
                    });
                  }}
                />
              </OrderSectionByType>
            </Col>

            <Col md={12} className="my-2">
              <DateInput
                label={t(...messages.orderInvoiceDate())}
                disabled={is_locked}
                value={params.order_date}
                onChange={order_date => setParams({...params, order_date})}
                errors={validationErrors.order_date}
              />
            </Col>
            <Col md={12} className="my-2">
              <DateInput
                label={t(...messages.orderDeadline())}
                disabled={is_locked}
                value={params.due_date}
                onChange={due_date => setParams({...params, due_date})}
                errors={validationErrors.due_date}
              />
            </Col>

            <OrderSectionByType orderTypeToDisplay={['CLIENT', 'STOCK']}>
              <Col md={12} className="my-2">
                <AsyncItemPicker
                  endPoint="data/document-categories"
                  disabled={is_locked}
                  label={t(...messages.orderCategory())}
                  optionLabel=""
                  errors={validationErrors.document_category}
                  value={params.document_category}
                  onChange={document_category =>
                    setParams({...params, document_category})
                  }
                />
              </Col>
              {params.commercial_type?.code === 'CCH' ? (
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/project-fee-types"
                    className="my-2"
                    label={t('invoiceGeneralInformation.projectFeesType')}
                    getOptionLabel={(l: any) => l.label}
                    getOptionValue={(l: any) => l.value}
                    disabled={is_locked}
                    errors={validationErrors.project_fee_type}
                    value={params.projectFeesType}
                    onChange={projectFeesType =>
                      setParams({...params, projectFeesType})
                    }
                  />
                </Col>
              ) : null}

              <Col md={12} className="my-2">
                <AsyncItemPicker
                  endPoint="data/document-sub-type"
                  label={t(...messages.orderModel())}
                  disabled={is_locked}
                  getOptionLabel={(o: Option) => o?.label}
                  getOptionValue={(o: Option) => o?.value}
                  errors={validationErrors.document_sub_type}
                  value={params.document_sub_type}
                  onChange={document_sub_type =>
                    setParams({...params, document_sub_type})
                  }
                />
              </Col>
            </OrderSectionByType>

            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/users"
                label={t(...messages.orderContact())}
                disabled={is_locked}
                errors={validationErrors.user}
                value={params.user}
                onChange={user => setParams({...params, user})}
              />
            </Col>
            <OrderSectionByType orderTypeToDisplay={['SUPPLIER']}>
              <Col md={12} className="my-2">
                <AsyncItemPicker
                  endPoint="data/directories"
                  disabled={is_locked}
                  getOptionLabel={(directory: Directory) => (
                    <GetOptionLabel directory={directory} />
                  )}
                  label={t(...messages.countermark())}
                  optionLabel=""
                  errors={validationErrors.directory_id}
                  value={params.client}
                  onChange={client => setParams({...params, client})}
                  noOptionsMessage={(e: any) => (
                    <NoOptionMessage newClient={e.inputValue} />
                  )}
                />
              </Col>
            </OrderSectionByType>

            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/projects"
                label={t(...messages.orderProject())}
                disabled={is_locked}
                getoptionLabel={(p: Option) => p.label}
                errors={validationErrors.project_id}
                value={params.project}
                onChange={project => setParams({...params, project})}
              />
            </Col>

            <OrderSectionByType orderTypeToDisplay={['CLIENT', 'STOCK']}>
              {params.commercial_type?.code === 'CCH' ? (
                <Col md={12}>
                  <TextInput
                    label={t(...messages.designation())}
                    data-testid="product-name-input"
                    disabled={is_locked}
                    value={params.designation}
                    errors={validationErrors.designation}
                    onChange={designation => {
                      setParams({
                        ...params,
                        designation,
                      });
                    }}
                  />
                </Col>
              ) : null}
              <Col md={12} className="my-2">
                <AsyncItemPicker
                  endPoint="data/document-origins"
                  label={t(...messages.orderOrigin())}
                  disabled={is_locked}
                  optionLabel=""
                  errors={validationErrors.origin}
                  value={params.origin}
                  onChange={origin => setParams({...params, origin})}
                />
              </Col>
              <Col md={12} className="my-2">
                <TextInput
                  label={t(...messages.clientReference())}
                  data-testid="product-name-input"
                  disabled={is_locked}
                  value={params.ref_client}
                  errors={validationErrors.ref_client}
                  onChange={ref_client => {
                    setParams({
                      ...params,
                      ref_client,
                    });
                  }}
                />
              </Col>
            </OrderSectionByType>

            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/languages"
                label={t(...messages.orderLanguage())}
                getOptionLabel={(l: any) => l.label}
                disabled={is_locked}
                errors={validationErrors.language}
                value={params.language}
                getOptionValue={(l: any) => l.label}
                onChange={language => {
                  setParams({...params, language});
                }}
              />
            </Col>

            <Col md={12}>
              <TextInput
                type="textarea"
                label={t(...messages.privateComment())}
                errors={validationErrors.private_comment}
                disabled={is_locked}
                value={params.private_comment}
                onChange={private_comment => {
                  setParams({
                    ...params,
                    private_comment,
                  });
                }}
              />
            </Col>
            <Col md={12} className="my-2">
              <input
                className="form-check-input my-1"
                type="checkbox"
                id="is-after-sale-service"
                disabled={is_locked}
                onChange={() =>
                  setParams({
                    ...params,
                    is_after_sale_service: !params.is_after_sale_service,
                  })
                }
                checked={params.is_after_sale_service}
              />
              <label
                className="form-check-label my-1 mx-2"
                htmlFor="is-after-sale-service"
              >
                {params?.document_type === 'SUPPLIER'
                  ? t(...messages.isSupplierAfterSaleService())
                  : t(...messages.isAfterSaleService())}
              </label>
            </Col>

            <AppBarActions>
              <Button
                isLoading={isLoading}
                disabled={is_locked}
                onClick={submit}
                color="important"
              >
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Row>
        </Col>
        <Col>
          <div className="mb-4">
            <OrderVatAndTaxes
              validationErrors={validationErrors}
              params={params}
              onChangeParams={setParams}
              is_locked={is_locked}
            />
          </div>
          <div className="mb-1">
            <OrderSectionByType orderTypeToDisplay={['CLIENT', 'STOCK']}>
              <OrderProduction
                onChangeParams={setParams}
                is_locked={is_locked}
                params={params}
                validationErrors={validationErrors}
              />
            </OrderSectionByType>

            <OrderSectionByType orderTypeToDisplay={['SUPPLIER']}>
              <OrderSupplier
                params={params}
                is_locked={is_locked}
                validationErrors={validationErrors}
                onChangeParams={setParams}
              />
            </OrderSectionByType>
          </div>
          <OrderTransporter
            params={params}
            validationErrors={validationErrors}
            onChangeParams={setParams}
            is_locked={is_locked}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrderGeneralInformation;
