import React from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Link} from 'react-router-dom';
import {CompactVariant} from 'types/compact-variant';

const getVariantName = (v: CompactVariant) => {
  return (
    <div
      style={{zIndex: -1}}
      className="d-flex  justify-content-between align-items-center"
    >
      <div style={{width: '90%', overflow: 'hidden'}}>
        {v.name + ' | ' + v?.collection_name}
      </div>
      <div style={{zIndex: 1}}>
        <Link
          target="_blank"
          to={`/dashboard/products/${v?.product?.id}/variants/${v.id}/general`}
        >
          <Icon name="external-link" size={15} />
        </Link>
      </div>
    </div>
  );
};
export default getVariantName;
