import React from 'react';
import {Trans} from 'react-i18next';
import {Option} from 'types/option';

export const europeanUnionCountries = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GR: 'Greece',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
};
export const varModes: Option[] = [
  {
    // @ts-ignore
    label: <Trans i18nKey="addressForm.local" />,
    value: 'LOCAL',
    id: 'LOCAL',
  },
  {
    // @ts-ignore
    label: <Trans i18nKey="addressForm.intraCommunity" />,
    value: 'INTRA_COMMUNITY',
    id: 'INTRA_COMMUNITY',
  },

  {
    // @ts-ignore
    label: <Trans i18nKey="addressForm.outEU" />,
    value: 'OUTUE',
    id: 'OUTUE',
  },
];
