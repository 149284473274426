import React from 'react';
import {
  PublicPriceIndicator,
  ResellerPriceIndicator,
  DecoratorPriceIndicator,
} from './indicator';
import './pricing-grid.styles.scss';
import SituationsPreview from './situations-preview';
import {ColumnDef} from '@tanstack/table-core';
import Currency from 'components/currency/currency';
import EmptyCell from 'components/empty-cell';
import {IndeterminateCheckbox} from 'features/table/compenents/column-checkbox';
import {truncate} from 'lodash';
import ProductCover from 'pages/dashboard/products/product-cover';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';
import {Pricing} from 'types/pricing';
import Percent from 'components/percent/percent';

const makePricingGridColumns: () => ColumnDef<Pricing>[] = () => [
  {
    header: ({table}) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({row}) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    meta: {size: '50px', hide: true},
    enableSorting: false,
    id: 'checkbox',
  },
  {
    header: 'Cover',
    accessorFn: r => <ProductCover variant={{...r}} />,
    cell: r => r.getValue(),
    maxWidth: '80px',
    width: '70px',
    enableSorting: false,

    id: 'preview',
  },

  {
    header: r => <Trans i18nKey="productsPage.name" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/products/${row.product_id}/variants/${row.variant_id}/general`}
        target="_blank"
      >
        <b>
          {' '}
          <span id={`vtip--products-${row.product_id}-${row.variant_id}`}>
            <EmptyCell cell={truncate(row.name, {length: 40})} fallback="-" />
          </span>
          <UncontrolledTooltip
            target={`vtip--products-${row.product_id}-${row.variant_id}`}
          >
            {row.name}
          </UncontrolledTooltip>
        </b>
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {size: '300px'},
    id: 'name',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey={'pricingPage.preview'} />,
    id: 'situations',
    enableSorting: false,
    accessorFn: r => <SituationsPreview row={r} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey={'pricingPage.purchaseCost'} />,
    id: 'situation_cost_ht',
    enableSorting: true,
    accessorFn: x => <Currency value={x.situation_cost_ht} /> ?? '-',
    cell: r => r.getValue(),
  },
  // Public pricing
  {
    meta: {
      costumClass: 'w-100 text-center pe-4',
      cellCostumClass: 'border-start--1',
      headerCostumClass: 'border-start--1',
    },

    header: r => (
      <span className="w-100 text-center">
        <Trans i18nKey={'pricingPage.publicPricing'} />
      </span>
    ),
    id: 'public_price',

    colSpan: 3,
    columns: [
      {
        meta: {
          cellCostumClass: 'border-start--1',
          headerCostumClass: 'border-start--1',
        },
        id: 'public_price_ht',
        header: r => <Trans i18nKey={'pricingPage.price'} />,

        enableSorting: true,
        cell: r => r.getValue(),

        accessorFn: x => {
          return (
            <span className="w-100 text-center">
              <Currency value={x.public_price_ht} />
            </span>
          );
        },
      },
      {
        id: 'public_price_ttc',
        accessorFn: x => {
          return (
            <span className="w-100 text-center">
              <Currency value={x.public_price_ttc} />
            </span>
          );
        },
        enableSorting: true,

        cell: r => r.getValue(),

        header: r => <Trans i18nKey={'pricingPage.priceInclTaxes'} />,
      },
      {
        id: 'public_benefit',
        enableSorting: true,
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        accessorFn: x =>
          <PublicPriceIndicator value={x.public_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },

  // Reseller pricing
  {
    header: r => (
      <span className="w-100 text-center">
        <Trans i18nKey={'pricingPage.resellerPricing'} />
      </span>
    ),
    id: 'reseller_price',
    colSpan: 3,

    meta: {
      costumClass: ' w-100 text-center',
      cellCostumClass: 'border-start--1',
      headerCostumClass: 'border-start--1',
    },

    columns: [
      {
        meta: {
          cellCostumClass: 'border-start--1',
          headerCostumClass: 'border-start--1',
        },
        id: 'reseller_price_ht',
        enableSorting: true,
        header: r => <Trans i18nKey="pricingPage.price" />, //TRANS PRICE
        accessorFn: x => (
          <span className="w-100 text-center">
            <Currency value={x.reseller_price_ht} />
          </span>
        ),
        cell: r => r.getValue(),
      },
      {
        id: 'reseller_discount',
        accessorFn: x => (
          <span className="w-100 text-center">
            <Percent value={x.reseller_discount} />
          </span>
        ),
        enableSorting: true,
        cell: r => r.getValue(),
        header: r => <Trans i18nKey={'pricingPage.discount'} />,
      },

      {
        id: 'reseller_benefit',
        enableSorting: true,
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        accessorFn: x =>
          <ResellerPriceIndicator value={x.reseller_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },
  // Decorator pricing
  {
    meta: {
      cellCostumClass: 'border-start--1',
      costumClass: ' w-100 text-center',
      headerCostumClass: 'border-start--1',
    },

    header: r => (
      <span className="w-100 text-center ">
        <Trans i18nKey={'pricingPage.decoratorPricing'} />
      </span>
    ),
    id: 'decorator_price',
    colSpan: 3,

    columns: [
      {
        meta: {
          cellCostumClass: 'border-start--1 ',
          headerCostumClass: 'border-start--1',
        },
        header: r => <Trans i18nKey={'pricingPage.price'} />,
        id: 'decorator_price_ht',
        enableSorting: true,
        accessorFn: x => (
          <span className="w-100 text-center">
            <Currency value={x.decorator_price_ht} />
          </span>
        ),
        cell: r => r.getValue(),
      },
      {
        header: r => <Trans i18nKey={'pricingPage.discount'} />,
        id: 'decorator_discount',
        enableSorting: true,
        accessorFn: x => <Percent value={x.decorator_discount} />,
        cell: r => r.getValue(),
      },
      {
        id: 'decorator_price_ttc',
        enableSorting: true,
        accessorFn: x => (
          <span className="w-100 text-center">
            <Currency value={x.decorator_price_ttc} />
          </span>
        ),
        header: r => <Trans i18nKey={'pricingPage.priceInclTaxes'} />,
        cell: r => r.getValue(),
      },
      {
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        id: 'decorator_benefit',
        enableSorting: true,
        accessorFn: x =>
          <DecoratorPriceIndicator value={x.decorator_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },
  {
    meta: {
      cellCostumClass: 'border-start--highlighted  text-info',
      headerCostumClass: 'border-start--highlighted ',
    },
    header: r => <Trans i18nKey={'pricingPage.purchasePrice'} />,
    id: 'total_cost_ht',
    enableSorting: true,
    accessorFn: x => {
      return (
        <span className="w-100 text-center">
          <Currency value={x.total_cost_ht} />
        </span>
      );
    },
    cell: r => r.getValue(),
  },
  {
    meta: {cellCostumClass: ' text-info'},
    header: r => <Trans i18nKey={'pricingPage.unitSales'} />,
    id: 'sales_count',
    enableSorting: true,
    accessorFn: x => x.sales_count,

    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey={'pricingPage.profit'} />,
    id: 'sales_revenue',
    enableSorting: true,
    meta: {cellCostumClass: ' text-info'},
    accessorFn: x => <Currency value={x.sales_revenue} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey={'pricingPage.turnover'} />,
    id: 'turnover',
    enableSorting: true,
    meta: {cellCostumClass: ' text-info'},
    accessorFn: x => <Currency value={x.turnover} />,
    cell: r => r.getValue(),
  },
];

export default makePricingGridColumns;
