import {loginWithFacebook as loginWithFacebookAction} from '../actions/login-with-facebook';
import {AuthState} from '../types';
import {AnyAction, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {AUTH_PROVIDER} from 'constants/auth-provider';
import {FacebookLoginResponse} from 'services/facebook-signin/types';
import {WritableDraft} from 'types/writable-draft';

/**
 * Match successful facebook login actions
 *
 */
const facebookAuthMatcher = isAnyOf(
  loginWithFacebookAction.fulfilled,
  isFacebokSuccessfulAuth
);

function isFacebokSuccessfulAuth(
  action: PayloadAction<any>
): action is PayloadAction<FacebookLoginResponse> {
  return action.type === 'facebookUserRetrieved';
}

/**
 * Facebook login reducer
 *
 */
const facebookLoginReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<FacebookLoginResponse> | AnyAction
) => {
  state.socialAuth.id = payload.user.id;
  state.socialAuth.provider_token = payload.provider_token;
  state.socialAuth.first_name = payload.user.first_name;
  state.socialAuth.last_name = payload.user.last_name;
  state.socialAuth.email = payload.user.email;
  state.socialAuth.provider_name = AUTH_PROVIDER.FACEBOOK;
};

export const loginWithFacebook = {
  matcher: facebookAuthMatcher,
  reducer: facebookLoginReducer,
};
