import React from 'react';
import {Link} from 'react-router-dom';
import RelatedDocument from 'types/related-document';

interface TooltipProps {
  cell: RelatedDocument;
}

const TooltipCell = ({cell}: TooltipProps) => {
  const link =
    cell.model_type === 'Invoice'
      ? `/dashboard/invoices/${cell.model_id}/details`
      : cell.model_type === 'Order'
      ? `/dashboard/orders/${cell.model_id}/details`
      : `/dashboard/quotes/${cell.model_id}/details`;

  return (
    <div className=" py-1">
      <Link to={link} target="_blank">
        <b>{cell.model_ref}</b>
      </Link>
    </div>
  );
};

export default TooltipCell;
