import {useState} from 'react';
import {useDeleteQuotationMutation} from 'features/commercial/quotations/store/endpoints/action-quotation-delete';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDeleteQuotation = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDeleteQuotationMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, id} = modalParams;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = async () => {
    const result = await submit({id});
    if ('data' in result) {
      onSuccess(result?.data?.data?.affected_row);
      dispatch(toast('success', result?.data?.data?.message));

      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
