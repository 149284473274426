import {LayoutState} from './types';
import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'store';

const selectDomain = (state: RootState): LayoutState => state.layout;

export const selectAppTheme = createSelector(
  selectDomain,
  domain => domain.layoutMode
);

export const selectLayoutWidth = createSelector(
  selectDomain,
  domain => domain.layoutWidth
);

export const selectLayoutState = selectDomain;
