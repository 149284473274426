import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import EmailRow from './email-row';
import {useSendInvoiceAsEmail} from './use-action-invoice-send-email';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {
  ModalBody,
  Row,
  Col,
  ModalFooter,
  ModalHeader,
  FormGroup,
} from 'reactstrap';

const InvoiceSendEmailAction: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    addEmail,
    updateEmail,
    email,
    removeEmail,
    validationErrors,
    params,
    setParams,
  } = useSendInvoiceAsEmail(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <Row>
          <Col md={3}>
            <FormGroup className="d-flex align-items-center  h-100">
              <input
                type="checkbox"
                className="form-check-input mb-1 me-2"
                id="with_invoice"
                checked={params.with_invoice}
                onChange={o =>
                  setParams({...params, with_invoice: o.target.checked})
                }
              />
              <label className="form-check-label" htmlFor="with_invoice">
                {t(...messages.withInvoice())}
              </label>
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup className="d-flex align-items-center  h-100">
              <input
                type="checkbox"
                className="form-check-input mb-1 me-2"
                id="with_stock_list"
                checked={params.stock_summary}
                onChange={o =>
                  setParams({...params, stock_summary: o.target.checked})
                }
              />
              <label className="form-check-label" htmlFor="with_stock_list">
                {t(...messages.withStockList())}
              </label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="d-flex align-items-center  h-100">
              <input
                type="checkbox"
                className="form-check-input mb-1  me-2"
                id="with_package_list"
                checked={params.packing_list}
                onChange={o =>
                  setParams({...params, packing_list: o.target.checked})
                }
              />
              <label className="form-check-label" htmlFor="with_package_list">
                {t(...messages.withPackageList())}
              </label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <TextInput
            rows={12}
            type="textarea"
            label={t(...messages.invoiceEmailSendSubject())}
            value={params.body}
            errors={validationErrors.body}
            onChange={body => setParams({...params, body})}
          />
        </Row>

        <Row className="my-3 d-flex align-items-center">
          <Col>
            <SectionSeparator title={t(...messages.invoiceEmailList())} />
          </Col>
          <Col md="auto">
            <Button color="light" onClick={addEmail}>
              <Icon name="plus" size={15} className="mb-1" />
              {t(...messages.invoiceEmailAdd())}
            </Button>
          </Col>
        </Row>

        <EmailRow
          updateEmail={updateEmail}
          email={email}
          removeEmail={removeEmail}
          validationErrors={validationErrors}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t('common.send')}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default InvoiceSendEmailAction;
