import React from 'react';
import FormattedDate from 'components/formatted-date';
import {TableColumn} from 'react-data-table-component';
import {Trans} from 'react-i18next';
import {Quotationlog} from 'types/quotation-log';
import {ColumnDef} from '@tanstack/table-core';

const columns: ColumnDef<Quotationlog>[] = [
  {
    header: r => <Trans i18nKey="quotationLogsTab.logDate" />,
    accessorFn: row => (
      <FormattedDate value={row?.created_at} format="datetime" />
    ),
    cell: r => r.getValue(),
    id: 'date',
    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="quotationLogsTab.user" />,
    accessorFn: row => (row.causer ? row.causer?.full_name : '-'),
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="quotationLogsTab.action" />,
    accessorFn: row => row.description,
    cell: r => r.getValue(),
    id: 'action',
    enableSorting: false,
  },
];

export default columns;
