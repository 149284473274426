import React, {useMemo, useState} from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Table,
} from 'reactstrap';
import clsx from 'clsx';
import './products-stock-grid.scss';
import {useGetCollectionsInStockQuery} from '../../store/endpoints/get-collections-in-stock';
import ProductCollection from './product-collection';
import {columnClassNames} from '../../constants/grid-classnames';
import ExpansionBreadCrumbs from '../../components/expansion-bread-crumbs/expansion-bread-crumbs';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {useLazyExportStockComptableQuantityValuesQuery} from '../../store/endpoints/export-stock-comptable-quantity-values';
import {useLazyExportStockComptableSupplierOrderQuery} from '../../store/endpoints/export-stock-comptable-supplier-order';
import Icon from '@ailibs/feather-react-ts';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import Currency from 'components/currency/currency';

const ProductsStockGrid = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {type} = useParams();

  const {data} = useGetCollectionsInStockQuery({type});

  const headings = data?.head ?? {};

  const totals = data?.tail ?? {};

  const collections = useMemo(() => {
    if (data?.data) {
      return data.data;
    }
    return [];
  }, [data]);

  const [exportQuantityValues, {isLoading: isLoadingQuantityValues}] =
    useLazyExportStockComptableQuantityValuesQuery();
  const [exportSupplierOrder, {isLoading: isLoadingSupplierOrder}] =
    useLazyExportStockComptableSupplierOrderQuery();

  type ExportQuery = typeof exportQuantityValues | typeof exportSupplierOrder;

  const handleExport = async (query: ExportQuery) => {
    const result = await query({});

    if ('data' in result) {
      dispatch(toast('success', result.data?.message));
    }
  };

  const menu = [
    {
      label: t(...messages.exportStockByProducts()),
      query: exportQuantityValues,
    },
    {
      label: t(...messages.exportBySupplierOrder()),
      query: exportSupplierOrder,
    },
  ];

  const isLoading = useMemo(
    () => isLoadingQuantityValues || isLoadingSupplierOrder,
    [isLoadingQuantityValues, isLoadingSupplierOrder]
  );

  return (
    <div>
      <div className="mb-3"></div>
      <Table bordered responsive>
        <thead>
          <ExpansionBreadCrumbs />
          <tr>
            <th>
              {type === 'quantity'
                ? t(...messages.stockAndQuantities())
                : t(...messages.stockAndValues())}
            </th>
            {Object.values(headings).map((heading, index) => {
              return (
                <th
                  key={heading}
                  className={clsx({
                    'p-2': true,
                    [columnClassNames[index]]: true,
                  })}
                >
                  {heading}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {collections.map((f, i) => (
            <ProductCollection key={f.id} row={f} />
          ))}
        </tbody>
        <tfoot>
          <th className="p-2">{t(...messages.total())}</th>
          {Object.values(totals).map((heading, index) => {
            return (
              <th
                key={`${heading}--${index}`}
                className={clsx({
                  'p-2': true,
                  [columnClassNames[index]]: true,
                })}
              >
                {index === 10 || index === 11 || type === 'quantity' ? (
                  <NaturalNumber value={heading} />
                ) : (
                  <Currency value={heading} />
                )}
              </th>
            );
          })}
        </tfoot>
      </Table>
      <AppBarActions>
        <Dropdown
          disabled={isLoading}
          className="ms-2"
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
        >
          <DropdownToggle color="light">
            {isLoading ? <Spinner size={'sm'} /> : t(...messages.export())}
            <Icon name="chevron-down" size={15} className="ms-1" />
          </DropdownToggle>
          <DropdownMenu>
            {menu.map(({label, query}, key) => (
              <DropdownItem key={key} onClick={() => handleExport(query)}>
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </AppBarActions>
    </div>
  );
};

export default ProductsStockGrid;
