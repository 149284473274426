import React from 'react';
import DimensionTable from './dimension-table';
import messages from './messages';
import {useGeneralForm} from './use-general-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Col, Label, Row} from 'reactstrap';
import {useShowProductQuery} from 'store/production/endpoints/show-product';
import Can from 'features/hylian-shield/can';
import {Product} from 'types/product';

const GeneralTab = () => {
  const {
    addPackaging,
    dimensions,

    isLoading,
    params,
    removePackaging,
    setParams,

    submit,
    updatePackaging,
    variantName,
    validationErrors,
  } = useGeneralForm();

  const {t} = useTranslation();

  const {id} = useParams();

  const parent = useShowProductQuery({
    id,
  }).data?.data as Product;

  const hasComponentAsParent = parent?.product_collection.type == 'COMPONENT';

  return (
    <div>
      <Row className="mb-3">
        <Col md={6}>
          <TextInput
            disabled={true}
            label={t(...messages.skuCode())}
            value={params.sku}
            onChange={sku => {}}
          />
        </Col>
        <Col md={6}>
          <AsyncItemPicker
            endPoint="data/ecotaxes"
            label={t(...messages.ecoTax())}
            value={params.ecotax}
            errors={validationErrors.eco_tax}
            onChange={ecotax => setParams({...params, ecotax})}
          />
        </Col>
        {/* <Col md={6}>
          <AsyncItemPicker
            endPoint="data/product-variants-categories"
            label={t(...messages.category())}
            onChange={product_variant_category =>
              setParams({...params, product_variant_category})
            }
            value={params.product_variant_category}
            errors={validationErrors.product_variant_category_id}
          />
        </Col> */}
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <TextInput
            label={t(...messages.name())}
            value={params.name}
            errors={validationErrors.name}
            onChange={name =>
              setParams({
                ...params,
                name,
              })
            }
          />
        </Col>
        <Col md={6}>
          <AsyncItemPicker
            endPoint="data/colors"
            label={t(...messages.color())}
            errors={validationErrors.color_id}
            onChange={color => setParams({...params, colors: color})}
            isMulti
            value={params.colors}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <Label className="mb-0">{t(...messages.dimension())} </Label>
        </Col>
        <Col md={3}>
          <TextInput
            type="number"
            pattern="^\d+$"
            min="0"
            errors={validationErrors.height}
            onChange={height => setParams({...params, height})}
            value={params.height}
          />
        </Col>

        <Col md={3}>
          <TextInput
            type="number"
            pattern="^\d+$"
            min="0"
            errors={validationErrors.width}
            onChange={width => setParams({...params, width})}
            value={params.width}
          />
        </Col>
        <Col md={3}>
          <TextInput
            type="number"
            pattern="^\d+$"
            min="0"
            errors={validationErrors.depth}
            onChange={depth => setParams({...params, depth})}
            value={params.depth}
          />
        </Col>
        <Col md={3}>
          <TextInput
            type="number"
            pattern="^\d+$"
            min="0"
            errors={validationErrors.diameter}
            onChange={diameter => setParams({...params, diameter})}
            value={params.diameter}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <TextInput
            label={t(...messages.finition())}
            value={params.finition}
            errors={validationErrors.finition}
            onChange={finition =>
              setParams({
                ...params,
                finition,
              })
            }
          />
        </Col>
        <Col md={6}>
          <AsyncItemPicker
            endPoint="data/matters"
            label={t(...messages.matter())}
            onChange={matter => setParams({...params, matters: matter})}
            value={params.matters}
            isMulti
            errors={validationErrors.matter_id}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={12}>
          <TextInput
            label={t(...messages.description())}
            type="textarea"
            rows="5"
            value={params.description}
            onChange={description =>
              setParams({
                ...params,
                description,
              })
            }
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <TextInput
            label={t(...messages.comment())}
            type="textarea"
            rows="5"
            value={params.comment}
            onChange={comment =>
              setParams({
                ...params,
                comment,
              })
            }
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <DateInput
            label={t(...messages.creationDate())}
            value={params.creation_date}
            onChange={creation_date =>
              setParams({
                ...params,
                creation_date,
              })
            }
          />
        </Col>

        <Col md={4}>
          <TextInput
            label={t(...messages.stockMin())}
            value={params.stock_min}
            type="number"
            errors={validationErrors['stock_min']}
            onChange={stock_min =>
              setParams({
                ...params,
                stock_min,
              })
            }
          />
        </Col>
        <Col md={4}>
          <TextInput
            label={t(...messages.stockMax())}
            type="number"
            value={params.stock_max}
            errors={validationErrors['stock_max']}
            onChange={stock_max =>
              setParams({
                ...params,
                stock_max,
              })
            }
          />
        </Col>
      </Row>

      <Row className="justify-content-start">
        <Col md={2} className="d-flex justify-content-start">
          <div className="form-check my-3">
            <input
              className="form-check-input "
              type="checkbox"
              onChange={() => setParams({...params, catalog: !params.catalog})}
              id="is_catalog"
              checked={params.catalog}
            />
            <label className="form-check-label " htmlFor="is_catalog">
              {t(...messages.catalog())}
            </label>
          </div>
        </Col>
        <Col md={3} className="d-flex justify-content-start">
          <div className="form-check my-3">
            <input
              className="form-check-input "
              type="checkbox"
              onChange={() =>
                setParams({...params, is_transferable: !params.is_transferable})
              }
              id="is_transferable"
              checked={params.is_transferable}
            />
            <label className="form-check-label " htmlFor="is_transferable">
              {t(...messages.transferableProduct())}
            </label>
          </div>
        </Col>
        <Col md={3}>
          <div className="form-check my-3">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() =>
                setParams({...params, self_composed: !params.self_composed})
              }
              id="self_composed"
              disabled={hasComponentAsParent}
              checked={params.self_composed || hasComponentAsParent}
            />
            <label className="form-check-label" htmlFor="self_composed">
              {t(...messages.selfComposed())}
            </label>
          </div>
        </Col>

        <Col md={3}>
          <div className="form-check my-3">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() =>
                setParams({...params, is_published: !params.is_published})
              }
              id="is_published"
              checked={params.is_published}
            />
            <label className="form-check-label" htmlFor="is_published">
              {t(...messages.published())}
            </label>
          </div>
        </Col>

        <Col md={4} className="d-flex justify-content-start">
          <div className="form-check w-50 my-3">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={() =>
                setParams({
                  ...params,
                  is_limited_edition: !params.is_limited_edition,
                })
              }
              id="is_limited_edition"
              checked={params.is_limited_edition}
            />
            <label className="form-check-label" htmlFor="is_limited_edition">
              {t(...messages.limited())}
            </label>
          </div>
          <div>
            {!!params.is_limited_edition && (
              <TextInput
                label="Nombre de l'édition"
                type="number"
                onChange={x => setParams({...params, limited_edition_count: x})}
                value={params.limited_edition_count}
                errors={validationErrors.limited_edition_count}
              />
            )}
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <div>
            <Label>{t(...messages.dimensions())}</Label>
          </div>
        </Col>
        <Col md={6}>
          <div className="d-flex w-100 justify-content-end">
            <Button color="light" onClick={addPackaging}>
              <Icon name="plus" size={15} className="me-1" />
              {t(...messages.addPackaging())}
            </Button>
          </div>
        </Col>
      </Row>
      {/* Dimensions dynamic rows */}

      <DimensionTable
        dimensions={dimensions}
        validationErrors={validationErrors}
        updatePackaging={updatePackaging}
        removePackaging={removePackaging}
      ></DimensionTable>

      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className=" mb-4">
            <div className="mt-3">
              <AppBarActions>
                <Button
                  isLoading={isLoading}
                  color="important"
                  onClick={submit}
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </AppBarActions>
            </div>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default GeneralTab;
