import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import messages from '../messages';
import IExpensesForm from '../use-expenses-form';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import DateInput from 'components/date-input';
import {Trash2} from 'react-feather';
import DragDropFile from 'components/dragndrop';
import {TemporaryFile} from 'types/temporary-file';
import {User} from 'types/user';
import Can from 'features/hylian-shield/can';
import useAbility from 'features/hylian-shield/use-ability';

const BodySection: FC<Props> = ({
  regroupedExpenses,
  options,
  validationErrors,
  defaultOpenedAccordion,
  subject,
  handleAddExpense,
  handleChangeExpense,
  handleRemoveExpense,
}) => {
  const {t} = useTranslation();

  const {team, statuses} = options;

  return (
    <CardBody>
      <CardTitle tag="h5">{t(...messages.expensesList())}</CardTitle>
      <UncontrolledAccordion stayOpen defaultOpen={defaultOpenedAccordion}>
        {regroupedExpenses.map((item, x) => (
          <AccordionItem key={x}>
            <AccordionHeader targetId={x.toString()}>
              <h5 color="light" className=" align-self-center">
                {t(`editProjectExpensesSection.${item.type.toLowerCase()}`)}
                <Badge
                  color={item.data.length > 0 ? 'success' : 'light'}
                  className="mx-2"
                >
                  {item.data.length}
                </Badge>
              </h5>
            </AccordionHeader>
            <AccordionBody accordionId={x.toString()}>
              <div>
                <Can action="edit" subject={subject}>
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => handleAddExpense(item.type)}
                      color="important"
                    >
                      {t(...messages.addExpense())}
                    </Button>
                  </div>
                  <hr />
                </Can>

                {item.data.length > 0 ? (
                  <>
                    {item.data.map((child, y) => (
                      <div key={y}>
                        <Row>
                          <Col lg={3}>
                            <ItemPicker
                              label={t(...messages.name())}
                              value={child.user}
                              getOptionLabel={(v: User) => v.profile?.full_name}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.user_id`
                                ]
                              }
                              options={team}
                              onChange={val => {
                                handleChangeExpense(
                                  child.identifier,
                                  'user',
                                  val
                                );
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <TextInput
                              label={t(...messages.duration())}
                              value={child.duration}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.duration`
                                ]
                              }
                              onChange={val => {
                                handleChangeExpense(
                                  child.identifier,
                                  'duration',
                                  val
                                );
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <DateInput
                              label={t(...messages.date())}
                              value={child.date}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.date`
                                ]
                              }
                              onChange={val => {
                                handleChangeExpense(
                                  child.identifier,
                                  'date',
                                  val
                                );
                              }}
                            />
                          </Col>
                          <Col lg={2}>
                            <TextInput
                              type="number"
                              label={t(...messages.amount())}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.amount`
                                ]
                              }
                              value={child.amount}
                              onChange={val => {
                                handleChangeExpense(
                                  child.identifier,
                                  'amount',
                                  val
                                );
                              }}
                            />
                          </Col>
                          <Col lg={3}>
                            <div className="d-flex gap-1">
                              <div className="flex-grow-1">
                                <ItemPicker
                                  label={t(...messages.status())}
                                  value={child.status}
                                  options={statuses}
                                  errors={
                                    validationErrors[
                                      `expenses.${child.identifier}.status`
                                    ]
                                  }
                                  onChange={(val: any) => {
                                    handleChangeExpense(
                                      child.identifier,
                                      'status',
                                      val.id
                                    );
                                  }}
                                />
                              </div>
                              <Can action="edit" subject={subject}>
                                <Button
                                  color="danger"
                                  className="d-inline-block align-self-end"
                                  id={`tooltip-${(x + y).toString()}`}
                                  onDoubleClick={() =>
                                    handleRemoveExpense(child.identifier)
                                  }
                                >
                                  <Trash2 size={14} />
                                </Button>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`tooltip-${(x + y).toString()}`}
                                >
                                  {t('common.doubleClickToRemove')}
                                </UncontrolledTooltip>
                              </Can>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col lg={8}>
                            <TextInput
                              type="textarea"
                              rows={1}
                              label={t(...messages.description())}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.description`
                                ]
                              }
                              value={child.description}
                              onChange={val => {
                                handleChangeExpense(
                                  child.identifier,
                                  'description',
                                  val
                                );
                              }}
                            />
                          </Col>
                          <Col className="align-items-center">
                            <label className="mb-0">
                              {t(...messages.import())}
                            </label>
                            <DragDropFile
                              file={child?.attachment}
                              fileNameLength={60}
                              errors={
                                validationErrors[
                                  `expenses.${child.identifier}.attachment`
                                ]
                              }
                              onChangeFile={(x: TemporaryFile) =>
                                handleChangeExpense(
                                  child.identifier,
                                  'attachment',
                                  x
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <hr
                          className="mt-4"
                          style={{
                            display:
                              y < item.data.length - 1 ? 'block' : 'none',
                          }}
                        ></hr>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center">{t('common.noData')}</p>
                )}
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </CardBody>
  );
};

type Props = IExpensesForm;

export default BodySection;
