import React, {FC, useContext} from 'react';
import addItemOption from '../../store/actions/add-item-option';
import selectItemOptions from '../../store/selectors/select-item-options';
import {CommercialProduct} from '../../types/commercial-product';
import ItemOptionMapper from '../item-option-mapper/item-option-mapper';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {CommercialProductOptionType} from 'types/commercial-product-option';
import documentContext from '../document-context/document-context';
import classNames from 'classnames';

interface Props {
  item: CommercialProduct;
  lineIndex: number;
  type: CommercialProductOptionType;
}

const ItemOptionsSection: FC<Props> = props => {
  const {item, lineIndex, type} = props;

  const {t} = useTranslation();

  const options = useSelector(selectItemOptions(item.id, type));

  const dispatch = useDispatch();

  const handleAddItemOption = () => {
    dispatch(addItemOption(item.id, type));
  };
  const {is_locked} = useContext(documentContext);

  const newOptions = options.length;

  const availableOptions = item.variant?.customization_options?.length ?? 0;

  const canAddMore =
    (type === 'OPTION' && availableOptions > newOptions) ||
    item.commercial_product_type === 'EXTERNAL_PRODUCT';
  return (
    <div>
      {options.map((x, i) => (
        <ItemOptionMapper
          item={item}
          key={x.id}
          option={x}
          lineIndex={lineIndex}
          index={i}
        />
      ))}
      {canAddMore && (
        <Button
          color="link"
          size="sm"
          onClick={handleAddItemOption}
          className={classNames('ps-0', {
            'opacity-0': is_locked,
          })}
          disabled={is_locked}
        >
          <Icon name="plus-circle" size={20} className="me-2" />
          {t(...messages.addProductOption())}
        </Button>
      )}
    </div>
  );
};

export default ItemOptionsSection;
