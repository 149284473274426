import React, {FC} from 'react';
import messages from '../messages';
import Filters from './filters';
import TagsRow from './tags-row';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, CardBody, Col, Row} from 'reactstrap';
import {useLocalStorage} from 'usehooks-ts';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const SupplierOrderControls: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <Row>
      <Col>
        <CardBody>
          <TagsRow />

          <Filters
            filters={filters}
            onChangeFilters={onChangeFilters}
            onClearFilters={onClearFilters}
          />
          <AppBarActions>
            <Link
              to="/dashboard/supplier-orders/create"
              className="btn btn-important"
            >
              <Icon name="plus" className="me-1" size={15} />
              {t(...messages.addSupplierOrder())}
            </Link>
          </AppBarActions>
        </CardBody>
      </Col>
    </Row>
  );
};

export default SupplierOrderControls;
