import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Table} from 'reactstrap';
import {useSelector} from 'react-redux';
import denormalizeObject from 'utils/denormalize-object';
import {ObjectId} from 'types/object-id';
import {selectOrder} from '../../store/endpoints/show-order';
import NameTD from 'features/commercial/quotations/components/name-td/name-td';
import messages from './messages';

interface Props {
  id?: ObjectId;
  items: Record<ObjectId, boolean>;
  isAllSelected: boolean;
  onUpdateItem: (id: ObjectId) => void;
  onToggleAll: () => void;
  isPrepared: (id: ObjectId) => boolean;
}

const PreparerTable: FC<Props> = props => {
  const {id, onUpdateItem, isPrepared, items, isAllSelected, onToggleAll} =
    props;

  const {t} = useTranslation();

  const order = useSelector(selectOrder({id}));

  const obj = order.data?.data.commercial_products_objectified;

  const allItems = denormalizeObject(obj);

  return (
    <div className="table-responsive">
      <Table>
        <thead>
          <tr>
            <th style={{width: 10}}>
              {/* if you find a way to make this work, then please do it */}
              <input
                id="select-all-items-to-transfer"
                type="checkbox"
                onChange={onToggleAll}
                checked={isAllSelected}
              />{' '}
            </th>
            <th> {t(...messages.sku())}</th>
            <th>{t(...messages.label())}</th>
            <th>{t(...messages.quantity())}</th>
          </tr>
        </thead>
        <tbody>
          {allItems.map((item, index) => (
            <tr key={item.id}>
              <td>
                <input
                  id={`check--${item.id}`}
                  disabled={isPrepared(item.id)}
                  type="checkbox"
                  onChange={() => onUpdateItem(item.id)}
                  checked={items[item.id] || isPrepared(item.id)}
                />
              </td>
              <td>
                <label htmlFor={`check--${item.id}`}>{item.variant?.sku}</label>
              </td>
              <NameTD>{item.custom_name}</NameTD>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PreparerTable;
