import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  directory: () => __(translations.welcomePage.directory, 'Directory'),
  inventory: () => __(translations.welcomePage.inventory, 'Inventory'),
  stock: () => __(translations.welcomePage.stock, 'Stock'),
  invoices: () => __(translations.welcomePage.invoices, 'Invoices'),
  monitoring: () => __(translations.welcomePage.monitoring, 'Monitoring'),
  orders: () => __(translations.welcomePage.orders, 'Orders'),
  products: () => __(translations.welcomePage.products, 'Products'),
  quotations: () => __(translations.welcomePage.quotations, 'Quotations'),
  pricing: () => __(translations.welcomePage.pricing, 'Pricing'),
  statistics: () => __(translations.welcomePage.statistics, 'Statistics'),
  studio: () => __(translations.welcomePage.studio, 'Studio'),
  title: () => __(translations.welcomePage.title, 'Welcome'),
  pageTitle: () => __(translations.welcomePage.pageTitle, 'Welcome'),
};
