import {api} from 'services/api';
import {Address} from 'types/address';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const getDirectoryAddresses = api.injectEndpoints({
  endpoints: builder => ({
    getDirectoryAddresses: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `directories/${params.directory_id}/addresses`,
        };
      },
      forceRefetch: () => true,
      // @ts-ignore
      providesTags: (result, error, arg) => result ? [{ type: 'DirectoryAddresses', id: arg.directory_id }] : [],



    }),
  }),
});

type Request = {
  directory_id?: ObjectId;
};

type Response = ResponseData<Record<ObjectId, Address>>;

export const {useGetDirectoryAddressesQuery} = getDirectoryAddresses;

export const updateCachedDirectoryAddress = (address: Address) =>
  getDirectoryAddresses.util.updateQueryData(
    'getDirectoryAddresses',
    {directory_id: address.directory_id},
    x => {
      x.data[address.id] = address;
    }
  );
