import React, {useEffect, useMemo, useState} from 'react';
import {
  ExpensesStatusEnum,
  ExpensesTypesEnum,
} from 'features/studio/projects/constants/project-expenses';
import {selectProject} from 'features/studio/projects/store/show-project';
import {
  EnumMapping,
  useEnumAsOption,
} from 'features/studio/projects/utils/use-enum-as-option';
import {useGroupByType} from 'features/studio/projects/utils/use-regroup-by-type';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {ObjectId} from 'types/object-id';
import {IExpense} from 'types/project';
import {
  HandleAddExpense,
  HandleChangeExpense,
  HandleRemoveExpense,
  HandleSubmit,
} from './types';
import {
  IExpenseRequest,
  StoreProjectExpensesRequest,
  useStoreProjectExpensesMutation,
} from 'features/studio/projects/store/store-project-expenses';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import makeId from 'utils/make-id';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {User} from 'types/user';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

export const useExpensesForm = (): IExpensesForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState({
    expenses: [],
    deleted_records: [],
  } as Params);

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!project) return;

    setParams({...params, expenses: project.expenses ?? []});
  }, []);

  const handleAddExpense: HandleAddExpense = type => {
    const newExpenses = [...params.expenses];

    newExpenses.push({
      id: makeId(),
      status: ExpensesStatusEnum.PENDING,
      type,
    } as IExpense);

    setParams({...params, expenses: newExpenses});
  };

  const handleChangeExpense: HandleChangeExpense = (index, key, value) => {
    const updatedExpenses = [...params.expenses];
    if (typeof value === 'object' && key === 'attachment')
      value = {...value, file_id: value.id};

    updatedExpenses[index] = {
      ...updatedExpenses[index],
      [key]: value,
    };

    setParams({...params, expenses: updatedExpenses});
  };

  const handleRemoveExpense: HandleRemoveExpense = index => {
    const updatedExpenses = [...params.expenses];
    const deleted_records = [...params.deleted_records];

    if (project?.expenses.find(el => el.id === updatedExpenses[index].id))
      deleted_records.push(updatedExpenses[index].id);

    updatedExpenses.splice(index, 1);
    setParams({...params, expenses: updatedExpenses, deleted_records});
  };

  const [storeProjectExpenses, {isLoading: isSubmitting, error}] =
    useStoreProjectExpensesMutation();

  const serverError = parseValidationErrors(error);

  const shapePayload = (data: Params): StoreProjectExpensesRequest => {
    const {expenses: _expenses, deleted_records} = data;

    const expenses: IExpenseRequest[] = _expenses.map(element => {
      return {
        ...element,
        user_id: element.user?.id,
      };
    });

    return {
      id,
      expenses,
      deleted_records,
    } as StoreProjectExpensesRequest;
  };

  const handleSubmit: HandleSubmit = async () => {
    const result = await storeProjectExpenses(shapePayload(params));

    if ('data' in result) {
      const project = result.data?.data;

      setParams({expenses: project.expenses, deleted_records: []});
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const options = {
    expenses: useEnumAsOption(ExpensesTypesEnum, 'editProjectExpensesSection'),
    statuses: useEnumAsOption(ExpensesStatusEnum, 'editProjectExpensesSection'),
    team: project?.assistants
      .filter(el => el.is_active)
      .map(el => el.user) as User[],
  };

  const regroupedExpenses = useMemo(
    () => useGroupByType(params.expenses, 'expenses') as IRegroupedExpenses[],
    [params]
  );

  // open by default none empty accordions
  const defaultOpenedAccordion = useMemo(() => {
    return regroupedExpenses
      .map((el, index) => ({...el, identifier: index}))
      .filter(el => el.data.length > 0)
      .map(el => el.identifier.toString());
  }, [regroupedExpenses]);

  return {
    params,
    options,
    regroupedExpenses,
    isSubmitting,
    validationErrors: serverError.errors,
    defaultOpenedAccordion,
    subject,
    handleAddExpense,
    handleChangeExpense,
    handleRemoveExpense,
    submit: handleSubmit,
  };
};

export default interface IExpensesForm {
  params: Params;
  options: {
    expenses: EnumMapping<typeof ExpensesTypesEnum>[];
    statuses: EnumMapping<typeof ExpensesStatusEnum>[];
    team: User[];
  };
  regroupedExpenses: IRegroupedExpenses[];
  isSubmitting: boolean;
  validationErrors: any;
  defaultOpenedAccordion: string[];
  subject: Subject;
  handleAddExpense: HandleAddExpense;
  handleChangeExpense: HandleChangeExpense;
  handleRemoveExpense: HandleRemoveExpense;
  submit: HandleSubmit;
}

export interface IRegroupedExpenses {
  type: ExpensesTypesEnum;
  data: (IExpense & {identifier: number})[];
}

type Params = {
  expenses: IExpense[];
  deleted_records: ObjectId[];
};
