import {api} from 'services/api';
import {LangOption} from 'types/product-translation';
import {ResponseData} from 'types/response-data';

const getLanguages = api.injectEndpoints({
  endpoints: builder => ({
    getLanguages: builder.query<Response, Request>({
      query: () => ({
        method: 'GET',
        url: `data/languages`,
      }),
    }),
  }),
});

type Request = void;

type Response = ResponseData<LangOption[]>;

export const {useGetLanguagesQuery} = getLanguages;

export const selectLanguages = getLanguages.endpoints.getLanguages.select;
