import {CommercialProduct} from '../../types/commercial-product';
import makeOption from '../../utils/make-option';
import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import normalizeArray from 'utils/normalize-array';

export default createAction(
  actions.updateItemVariant.type,
  function prepare(
    item: CommercialProduct,
    value: CommercialProduct['variant']
  ) {
    const newOptionsArray = value?.customization_options.map(x => {
      const o = makeOption(item.id, 'OPTION');
      return {
        ...o,
        price_ht: x.selling_price_ht,
        name: x.name,
        customization_option_id: x.id,
        option_value: x,
      };
    });
    const newComponentsArray = value?.components?.map(x => {
      const o = makeOption(item.id, 'COMPONENT');
      return {
        ...o,
        name: x.name,
        quantity: x.quantity,
        component_id: x.id,
        option_value: x,
      };
    });
    const newOptions = normalizeArray(newOptionsArray ?? []);
    const newComponents = normalizeArray(newComponentsArray ?? []);

    return {
      payload: {
        item,
        value,
        newOptions: {
          ...newOptions,
          ...newComponents,
        },
      },
    };
  }
);
