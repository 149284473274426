import {api} from 'services/api';
import {Directory} from 'types/directory';
import {Interaction} from 'types/interaction';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

export const getDirectoryInteractions = api.injectEndpoints({
  endpoints: builder => ({
    getDirectoryInteractions: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `directories/${params.directory_id}/interactions`,
        };
      },
    }),
  }),
});

type Request = {
  directory_id?: ObjectId;
};

type Response = ResponseData<Record<ObjectId, Interaction>>;

export const {useGetDirectoryInteractionsQuery} = getDirectoryInteractions;

export const updateCachedDirectoryInteraction = (interaction: Interaction) =>
  getDirectoryInteractions.util.updateQueryData(
    'getDirectoryInteractions',
    {directory_id: String(interaction.directory_id)},
    x => {
      x.data[interaction.id] = interaction;
    }
  );
