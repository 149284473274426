import {Ability, AbilityBuilder} from '@casl/ability';
import {User} from 'types/user';
import {
  DocumentRoleType,
  documentPermissionMaker,
} from './document-permission-maker';

const canFormat = (str: string) =>
  str?.replaceAll('_', '-').toLocaleLowerCase();

export const upgradeShield = (user?: User | null) => {
  const permissions = user?.permission ?? [];
  const {can, rules} = new AbilityBuilder<Ability>();

  const userRole = (user?.role?.name as DocumentRoleType) ?? '';

  // permissions related to documents does not exists in response data
  // must be done localy
  const updatedPerms = permissions.concat(documentPermissionMaker(userRole));

  // This logic depends on how the
  // server sends you the permissions array
  if (permissions) {
    updatedPerms.forEach(p => {
      // hard extraction of action verb

      const parsed = p.split('__');
      const [subject, action] = [canFormat(parsed[0]), canFormat(parsed[1])];

      can(action, subject);
    });
  }

  return rules;
};
export default upgradeShield;
