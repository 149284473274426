import {useState, useEffect} from 'react';
import messages from '../messages';
import {useDuplicatQuotationMutation} from 'features/commercial/quotations/store/endpoints/action-quotation-duplicat';
import {selectQuotation} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDuplicatQuotation = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDuplicatQuotationMutation();
  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, id} = modalParams;

  const [params, setParams] = useState<Params>({
    id: undefined,
    directory: undefined,
  });

  const {data} = useSelector(selectQuotation({id}));

  const quotation = data?.data as Quotation;

  useEffect(() => {
    if (quotation) {
      setParams({
        id: quotation?.id,
        directory: quotation.directory,
      });
    }
  }, [quotation]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const directory_id = params.directory?.id;
    const result = await submit({
      id,
      directory_id,
    });
    if ('data' in result) {
      onSuccess(result?.data?.data.id);

      dispatch(toast('success', t(...messages.quotationDuplicateSucees())));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = {
  id?: ObjectId;
  directory?: Directory;
};
