import React, {FC, useState} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {useTranslation, withTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import {useGetRecentNotificationsQuery} from 'store/account/endpoints';
import {selectUnreadNotificationsCount} from 'store/account/selectors';

interface Props {}

const NotificationDropdown: FC<Props> = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const {t} = useTranslation();

  const unreadNotificationsCount = useSelector(selectUnreadNotificationsCount);

  const {data, isLoading} = useGetRecentNotificationsQuery();

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-lg" />
          <span
            className={clsx({
              'badge bg-danger rounded-pill': true,
              'd-none': unreadNotificationsCount < 1,
            })}
          >
            {unreadNotificationsCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {'Notifications'} </h6>
              </Col>
              <div className="col-auto">
                <Link
                  to="/dashboard/notifications"
                  onClick={() => setMenu(!menu)}
                  className="small"
                >
                  {' '}
                  {t(...messages.viewAll())}
                </Link>
              </div>
            </Row>
          </div>
          <SimpleBar style={{height: '230px'}}>
            <div className={clsx({'text-center': true, 'd-none': !isLoading})}>
              <Spinner />
            </div>
            {data?.data.map(n => (
              <Link
                key={n.id}
                to={`/dashboard/notifications/${n.id}`}
                className="text-reset notification-item"
              >
                <div className="d-flex">
                  <div className="avatar-sm me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-cart" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{n.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{n.type}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{' '}
                        {t('dates.ago', {date: new Date(n.created_at)})}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/dashboard/notifications"
              onClick={() => setMenu(!menu)}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{' '}
              {t(...messages.viewAll())}{' '}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
