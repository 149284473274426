import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  general: () => __(translations.editProjectPage.general, 'General'),
  contacts: () => __(translations.editProjectPage.contacts, 'Contacts'),
  team: () => __(translations.editProjectPage.team, 'Team'),
  phases: () => __(translations.editProjectPage.phases, 'Phases & Fees'),
  attachments: () => __(translations.editProjectPage.attachments, 'Attachments'),
  expenses: () => __(translations.editProjectPage.expenses, 'Expenses'),
  manDays: () => __(translations.editProjectPage.manDays, 'Man Days'),
  commissions: () => __(translations.editProjectPage.commissions, 'Commissions'),
  sourcingFees: () => __(translations.editProjectPage.sourcingFees, 'Sourcing Fees'),
  sellsFFE: () => __(translations.editProjectPage.sellsFFE, 'Mobilier et FF&E'),
  vintage: () => __(translations.editProjectPage.vintage, 'Vintage'),
  conclusion: () => __(translations.editProjectPage.conclusion, 'Conclusions'),

  editProject: () => __(translations.editProjectPage.editProduct, 'Edit Project'),
  pageTitle: () => __(translations.editProjectPage.pageTitle, 'Edit Project'),
  projectTitle: () => __(translations.editProjectPage.productsTitle, 'Projects'),
  startAt: () => __(translations.editProjectPage.startAt, 'Start at'),
  endAt: () => __(translations.editProjectPage.endAt, 'End at'),
  addNewCompanyClient: () => __(translations.editProjectPage.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.editProjectPage.addNewPrivateClient, 'Add Private'),

  exports: () => __(translations.editProjectPage.exports, 'Exports'),

  projectType: () => __(translations.editProjectPage.projectType, 'Type'),
  projectStatus: () => __(translations.editProjectPage.projectStatus, 'Status'),
  client: () => __(translations.editProjectPage.client, 'Client'),
  directoryFamily: () => __(translations.editProjectPage.directoryFamily, 'Family'),
};
