import React from 'react';
import ActionsTab from '../actions';
import CustomizationOptions from '../customization-options';
import GeneralProductPage from '../general';
import LinkedResources from '../linked-resources/linked-resources';
import OtherInfoTab from '../other';
import StemProduct from '../stem';
import CreateVariant from '../variant/create';
import {Routes, Route} from 'react-router';

const ProductRoutes = () => {
  return (
    <Routes>
      <Route path="/add-variant" element={<CreateVariant />} />
      <Route path="/customization-options" element={<CustomizationOptions />} />
      <Route path="/general" element={<GeneralProductPage />} />
      <Route path="/other" element={<OtherInfoTab />} />
      <Route path="/linked-resources" element={<LinkedResources />} />
      <Route
        path="/serial-number"
        element={<div>Product Components tab</div>}
      />
      <Route path={`/`} element={<StemProduct />} />
    </Routes>
  );
};

export default ProductRoutes;
