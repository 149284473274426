import React, {FC, ReactNode, createContext, useEffect, useRef} from 'react';
import RightSidebar from '../CommonForBoth/RightSidebar';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import {ActionBarElement, AppActionBarContext} from './app-bar-actions';
import StickyContainer from './sticky-container';
import {useSelector} from 'react-redux';
import {selectLayoutState} from 'store/layout/selectors';

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = props => {
  const {leftSideBarTheme, leftSideBarType, showRightSidebar} =
    useSelector(selectLayoutState);

  const isMobile: any = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => window.scrollTo(0, 0), []);

  const divRef = useRef(null);

  const container = useRef<ActionBarElement>(null);

  const updateContainer = (newValue?: ActionBarElement) => {
    container.current = newValue;
  };

  return (
    <React.Fragment>
      <StickyContainerContext.Provider value={divRef}>
        <AppActionBarContext.Provider value={{container, updateContainer}}>
          <div id="layout-wrapper">
            <Header />
            <Sidebar
              theme={leftSideBarTheme}
              type={leftSideBarType}
              isMobile={isMobile}
            />
            <div className="main-content">{props.children}</div>
            <StickyContainer ref={divRef} />
           {/*  <Footer /> */}
          </div>
          {showRightSidebar ? <RightSidebar /> : null}
        </AppActionBarContext.Provider>
      </StickyContainerContext.Provider>
    </React.Fragment>
  );
};

export default Layout;

export const StickyContainerContext = createContext<
  React.RefObject<HTMLDivElement> | undefined
>(undefined);
