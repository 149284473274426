import React, {Fragment, FC} from 'react';
import {selectOrder} from '../../store/endpoints/show-order';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {ObjectId} from 'types/object-id';

interface Props {
  orderTypeToDisplay: string[];
  children: any;
  idProps?: ObjectId;
}

const OrderSectionByType: FC<Props> = props => {
  const {id} = useParams();

  const {orderTypeToDisplay, idProps} = props;

  const orderId = id ? id : (idProps as string);

  const {data} = useSelector(selectOrder({id: orderId}));

  const document_type = data?.data.document_type ?? '';

  const children = props.children;

  return orderTypeToDisplay?.includes(document_type) ? (
    <Fragment>{children}</Fragment>
  ) : null;
};

export default OrderSectionByType;
