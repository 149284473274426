/**
 * Async item picker
 *
 */
import React, {
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _, {debounce, isArray} from 'lodash';
import {useSelector} from 'react-redux';
import AsyncSelect from 'react-select/async';
import {AsyncProps} from 'react-select/async';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
} from 'react-select/dist/declarations/src/types';
import {Label} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import {useLazyGetAsyncDataQuery} from 'store/ui/endpoints/get-async-data';
import serializeParams from 'utils/serialize-params';
import useInputId from 'utils/use-input-id';
import ItemPicker from 'components/item-picker';
import {uniqueId} from 'lodash';
import Select from 'react-select/dist/declarations/src/Select';
import {currency} from 'features/commercial/documents/utils/currency-parser';

const AsyncTagSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ExtendedAsyncProps<Option, IsMulti, Group>
) => {
  const {
    endPoint,
    value = null,
    reduceOption = 'name',
    disabled,
    getOptionValue,
    onChange = () => {},
    errors,
    required,
    loadOnClick,
    id: inputId,
    label,
    otherParams,
    defaultValue,
    ...rest
  } = props;

  const [fetchData] = useLazyGetAsyncDataQuery();

  const handleLoadOptions = useCallback(
    debounce((query: string, callBack: any) => {
      if (disabled) {
        callBack([]);
        return;
      }
      const params = {
        ...otherParams,
        search: query,
      };
      fetchData({
        url: serializeParams(endPoint, params),
      })
        .then(res => {
          if (res.data?.data) {
            callBack(
              res.data?.data.map(x => {
                const y = _.get(x, reduceOption);
                return {id: y, name: y};
              })
            );
          }
        })
        .catch(() => {
          // do nothing
        });
    }, 300),
    []
  );
  const [open, setOpen] = useState(loadOnClick);
  const appTheme = useSelector(selectAppTheme);
  const textColorSingle = appTheme === 'dark' ? '#fff' : '#000';

  const selectThemeColors = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--bs-primary)', // for selected option bg-color
        neutral10: 'var(--bs-primary)', // for tags bg-color
        neutral20: 'var(--bs-border-color)', // for input border-color
        neutral30: 'var(--bs-primary)', // for input hover border-color
        neutral0: appTheme === 'dark' ? 'var(--bs-input-bg)' : '#fff', // used
        neutral80: props.isMulti ? '#fff' : textColorSingle, // text color
      },
    };
  };

  const handleChange = (
    value: OnChangeValue<Option, IsMulti>,
    meta: ActionMeta<Option>
  ) => {
    if (!props?.isMulti) {
      const xa = _.get(value, 'name') as string;
      onChange(xa);
    }
    if (isArray(value) && props.isMulti) {
      const values = value.map(item => _.get(item, 'name'));
      onChange(values);
    }
  };

  const boundValue = useMemo(() => {
    if (!value) {
      return null;
    }
    if (props.isMulti) {
      if (isArray(value)) {
        const objectifiedValue = value?.map((x: string) => {
          return {id: x, name: x};
        });
        return objectifiedValue;
      }

      return [{id: value, name: value}];
    }

    return {
      id: value,
      name: value,
    };
  }, [value, props.isMulti]);

  const id = useInputId(inputId);
  //this part is fix the click bug which appeared with
  // 798-enhacement-make-the-asyncitempicker-load-data-only-onclick merge
  const inputRef = useRef(null);
  useEffect(() => {
    if (!open) {
      // @ts-ignore
      inputRef?.current?.focus();
    }
  }, [open]);

  const key = useMemo(() => {
    if (defaultValue) {
      const suffix = isArray(defaultValue)
        ? defaultValue.length
        : String(defaultValue).length;
      return `async-tag-${id}-${suffix}`;
    }
    return undefined;
  }, []);

  return (
    <div className="">
      <span className="text-danger">{required && '* '}</span>
      <Label className="mb-0" htmlFor={id}>
        {label}
      </Label>
      {open ? (
        <ItemPicker
          // @ts-ignore
          options={[]} // this is a dummy value
          onChange={() => {}}
          onClick={() => {
            setOpen(!open);
            // @ts-ignore
          }}
          theme={selectThemeColors}
          className="react-select"
          classNamePrefix="select"
          styles={{
            // @ts-ignore
            menu: provided => ({...provided, zIndex: 2001}),
            // @ts-ignore
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errors?.length ? 'red' : baseStyles.borderColor,
            }),
            // @ts-ignore
            input: baseStyles => ({
              ...baseStyles,

              color: textColorSingle,
            }),
          }}
          // @ts-ignore
          getOptionValue={x => _.get(x, 'name')}
          // @ts-ignore
          value={boundValue}
          // @ts-ignore
          getOptionLabel={x => x?.name}
          isDisabled={disabled}
          isClearable
          {...rest}
        />
      ) : (
        <AsyncSelect
          key={key}
          inputId={id}
          ref={inputRef}
          theme={selectThemeColors}
          className="react-select"
          classNamePrefix="select"
          defaultMenuIsOpen={loadOnClick}
          styles={{
            menu: provided => ({...provided, zIndex: 2001}),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errors?.length ? 'red' : baseStyles.borderColor,
            }),
            input: baseStyles => ({
              ...baseStyles,

              color: textColorSingle,
            }),
          }}
          cacheOptions
          // TODO:: Fix this type
          // @ts-ignore
          getOptionValue={x => _.get(x, 'name')}
          // @ts-ignore
          value={boundValue}
          // @ts-ignore
          getOptionLabel={x => x?.name}
          onChange={(
            newValue: OnChangeValue<Option, IsMulti>,
            actionMeta: ActionMeta<Option>
          ) => handleChange(newValue, actionMeta)}
          defaultOptions
          isDisabled={disabled}
          isClearable
          loadOptions={handleLoadOptions}
          {...rest}
        />
      )}

      {errors ? <div className="invalid-feedback d-block">{errors}</div> : null}
    </div>
  );
};

AsyncTagSelect.defaultProps = {
  optionLabel: 'name',
  otherParams: {},
};

export default AsyncTagSelect;

// Additional props
interface ExtendedProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> {
  endPoint: string;
  otherParams?: any;
  disabled?: boolean;
  onChange?: (x: string[] | string) => void;
  reduceOption?: string;
  errors?: string | string[];
  loadOnClick?: boolean;
  required?: boolean;
  label?: string;
  // All other props
  // [x: string]: any;
}

// Merge the original AsyncProps with the ExtendedProps
type ExtendedAsyncProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = AsyncProps<Option, IsMulti, Group> & ExtendedProps<Option, IsMulti, Group>;
