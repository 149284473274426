import React from 'react';
import GeneralCompanyDirectoryForm from './company';
import GeneralContactDirectoryForm from './contact';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {selectDirectory} from 'store/directory/endpoints/show-directory';

const GeneralInfoPage = () => {
  const {id} = useParams();
  const {data} = useSelector(selectDirectory({id}));

  return (
    <>
      {data?.data?.directory_type.value === 'COMPANY' ? (
        <GeneralCompanyDirectoryForm />
      ) : (
        <GeneralContactDirectoryForm />
      )}
    </>
  );
};

export default GeneralInfoPage;
