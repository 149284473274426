import React, {FC, Fragment, ReactNode} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Col, ModalBody, ModalFooter, Row, Table} from 'reactstrap';
import useStockMovementsForm from './use-stock-movements-form';
import messages from './messages';

interface Props {
  availabilityTable?: ReactNode;
  onDismiss?: () => void;
}

const StockMovementsForm: FC<Props> = props => {
  const {onDismiss, availabilityTable} = props;

  const {t} = useTranslation();

  const {
    addMovement,
    errorMessage,
    isLoading,
    movements,
    removeMovement,
    submit,
    validationErrors,
    updateMovement,
    movementType,
  } = useStockMovementsForm();

  return (
    <Fragment>
      <ModalBody
        className=""
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <div>{availabilityTable}</div>
        <Row className="mb-2">
          <div>
            <div>
              <Row className="mb-2">
                {movementType === 'IN' && (
                  <Col className="fw-bold">{t(...messages.to())}</Col>
                )}
                {movementType === 'OUT' && (
                  <Col className="fw-bold"> {t(...messages.from())}</Col>
                )}

                <Col md="2" className="fw-bold">
                  {' '}
                  {t(...messages.quantity())}
                </Col>
                <Col className="fw-bold"> {t(...messages.comments())}</Col>
                <Col md="1" className="fw-bold text-center ">
                  {t('productsPage.action')}
                </Col>
              </Row>

              {Object.values(movements).map((m, i) => (
                <Row className="mb-3" key={m.id}>
                  {movementType === 'OUT' && (
                    <Col>
                      <AsyncItemPicker
                        endPoint="data/stock-locations"
                        containerClassName="d-flex align-items-center flex-column w-100"
                        className="w-100"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        onChange={stock_location_start =>
                          updateMovement(
                            m,
                            'stock_location_start',
                            stock_location_start
                          )
                        }
                        value={m.stock_location_start}
                        errors={
                          validationErrors[
                            `movement_infos.${i}.stock_location_start_id`
                          ]
                        }
                      />
                    </Col>
                  )}
                  {movementType === 'IN' && (
                    <Col>
                      {' '}
                      <AsyncItemPicker
                        containerClassName="d-flex align-items-center flex-column w-100"
                        endPoint="data/stock-locations"
                        className="w-100"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        onChange={stock_location_arrival =>
                          updateMovement(
                            m,
                            'stock_location_arrival',
                            stock_location_arrival
                          )
                        }
                        value={m.stock_location_arrival}
                        errors={
                          validationErrors[
                            `movement_infos.${i}.stock_location_arrival_id`
                          ]
                        }
                      />
                    </Col>
                  )}

                  <Col md="2">
                    <TextInput
                      onChange={quantity =>
                        updateMovement(m, 'quantity', quantity)
                      }
                      value={m.quantity}
                      errors={validationErrors[`movement_infos.${i}.quantity`]}
                    />
                  </Col>
                  <Col>
                    <TextInput
                      onChange={comments =>
                        updateMovement(m, 'comment', comments)
                      }
                      rows={1}
                      value={m.comment}
                      type="textarea"
                      errors={validationErrors[`movement_infos.${i}.comment`]}
                    />
                  </Col>
                  <Col md="1" className="text-center">
                    <Button
                      color="transparent"
                      onClick={() => removeMovement(m.id)}
                    >
                      <Icon className="text-danger" name="trash-2" size={20} />
                    </Button>
                  </Col>
                </Row>
              ))}

              <Row className="mb-2">
                <Col md="2" className="">
                  <div className="d-flex align-items-center h-100">
                    <Button color="light" onClick={addMovement}>
                      <Icon name="plus" size={15} className="me-1" />
                      {t(messages.addMovement())}
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="row">
                <div className="col">
                  <div>
                    {errorMessage && (
                      <Alert color="danger">{errorMessage}</Alert>
                    )}
                  </div>
                  <div className="mt-1 d-grid"></div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default StockMovementsForm;
