import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  expenses: () => __(translations.editProjectExpensesSection.expenses, 'Expenses'),
  expensesList: () => __(translations.editProjectExpensesSection.expensesList, 'List of expenses'),
  addExpense: () => __(translations.editProjectExpensesSection.addExpense, 'Add an expense'),
  name: () => __(translations.editProjectExpensesSection.name, 'Name'),
  duration: () => __(translations.editProjectExpensesSection.duration, 'Duration'),
  date: () => __(translations.editProjectExpensesSection.date, 'Date'),
  amount: () => __(translations.editProjectExpensesSection.amount, 'Amount'),
  status: () => __(translations.editProjectExpensesSection.status, 'Status'),
  description: () => __(translations.editProjectExpensesSection.description, 'Description'),
  import: () => __(translations.editProjectExpensesSection.import, 'Import'),

  // types
  transport_modes: () => __(translations.editProjectExpensesSection.transport_modes, 'Transport Modes'),
  restaurant: () => __(translations.editProjectExpensesSection.restaurant, 'Restaurant'),
  hotel: () => __(translations.editProjectExpensesSection.hotel, 'Hotel'),
  taxi_paris: () => __(translations.editProjectExpensesSection.taxi_paris, 'Taxi Paris'),
  taxi_others: () => __(translations.editProjectExpensesSection.taxi_others, 'Taxi others'),
  car_rent: () => __(translations.editProjectExpensesSection.car_rent, 'Car rent'),
  parking: () => __(translations.editProjectExpensesSection.parking, 'Parking'),
  others: () => __(translations.editProjectExpensesSection.others, 'Others'),

  // statuses
  pending: () => __(translations.editProjectExpensesSection.pending, 'Pending'),
  processed: () => __(translations.editProjectExpensesSection.processed, 'Processed'),
  archived: () => __(translations.editProjectExpensesSection.archived, 'Archived'),
};
