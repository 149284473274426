import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useMergeDirectoryForm} from './use-merge-directory-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import ItemPicker from 'components/item-picker';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Directory} from 'types/directory';
import {Option} from 'types/option';

const MergeDirectoryForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useMergeDirectoryForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  const directory = useSelector(selectActiveModalParams) as Directory;

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <div className="row mb-2">
            <div className="col">
              <div>
                {params.directory && (
                  <Alert color="warning">
                    <span>
                      {t(...messages.mergeMessage(), {
                        source: params.directory?.display_name,
                        destination: directory.display_name,
                      })}
                    </span>
                  </Alert>
                )}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>

          <AsyncItemPicker
            onChange={(directory: any) => setParams({...params, directory})}
            value={params.directory}
            endPoint={'data/directories?type=' + directory.directory_type.value}
            filterOption={(x: any) => x?.data?.id !== directory.id}
            onKeyDown={handleKeyboardEventDown}
            label={t(...messages.mergeWith())}
            getOptionLabel={(x: Directory) => x.display_name}
            errors={validationErrors.directory}
          />

          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default MergeDirectoryForm;
