import {useState, useEffect} from 'react';
import {useDuplicatInvoiceMutation} from '../../../store/endpoints/action-invoice-duplicat';
import {Invoice} from '../../../types/invoice';
import messages from '../messages';
import {selectInvoice} from 'features/commercial/invoices/store/endpoints/show-invoice';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDuplicatInvoice = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDuplicatInvoiceMutation();
  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;

  const [params, setParams] = useState<Params>({
    id: undefined,
    directory: undefined,
  });

  const {data} = useSelector(selectInvoice({id}));

  const invoice = data?.data as Invoice;

  useEffect(() => {
    if (invoice) {
      setParams({
        id: invoice?.id,
        directory: invoice.directory,
      });
    }
  }, [invoice]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const directory_id = params.directory?.id;
    const result = await submit({
      id,
      directory_id,
    });
    if ('data' in result) {
      navigate(`/dashboard/invoices/${result?.data?.data.id}/details`);
      dispatch(toast('success', t(...messages.invoiceDuplicateSucees())));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = {
  id?: ObjectId;
  directory?: Directory;
};
