import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockInPreparation.title, 'Stock in preparation'),
  logistic: () => __(translations.stockInPreparation.logistic, 'Logistic'),
  orderNumber: () => __(translations.stockInPreparation.orderNumber, 'Order number'),
  preparationDate: () => __(translations.stockInPreparation.preparationDate, 'Preparation date'),
  transporter: () => __(translations.stockInPreparation.transporter, 'Transporter'),
  trackingNumber: () => __(translations.stockInPreparation.trackingNumber, 'Tracking number'),
  status: () => __(translations.stockInPreparation.status, 'Status'),
  details: () => __(translations.stockInPreparation.details, 'Details'),
  actions: () => __(translations.stockInPreparation.actions, 'Actions'),

  waiting_for_delivery: () => __(translations.stockInPreparation.waiting_for_delivery, 'Waiting for delivery'),
  delivered: () => __(translations.stockInPreparation.delivered, 'Delivered'),
};
