import React, {Fragment} from 'react';
import {Row, Col, Label} from 'reactstrap';

const TableSeparator = () => {
  return (
    <Fragment>
      <hr />
      <Row>
        <Col md={12}>
          <div>
            <Label>Détails Coûts € HT</Label>
          </div>
          <div></div>
        </Col>
      </Row>
      <hr />
    </Fragment>
  );
};

export default TableSeparator;
