import {Trans, useTranslation} from 'react-i18next';

export const currency = (value: number, currency_symbole: string) => {
  const {t} = useTranslation();

  if (currency_symbole) {
    return `${t('numbers.naturalNumber', {val: value})} ${currency_symbole}`;
  } else {
    return `${t('numbers.naturalNumber', {val: value})} €`;
  }
};
