import React, {FC, Fragment} from 'react';
import messages from '../messages';
import {useColorDeleteForm} from './use-color-delete-form';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {ObjectId} from 'types/object-id';

interface Props {
  message?: string;
  onDismiss: () => void;
  colorId: ObjectId;
}

const ColorArchiveModal: FC<Props> = props => {
  const {onDismiss, colorId} = props;
  const {validationErrors, isLoading, errorMessage, submit} =
    useColorDeleteForm(onDismiss, colorId);
  const {t} = useTranslation();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.actionOnColors())}</span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Row>{t(...messages.deleteAlertMessage())}</Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default ColorArchiveModal;
