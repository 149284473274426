import React from 'react';
import {FC} from 'react';
import {ModalClasses, Modals} from './modals';
import {selectModalProperties} from './selectors';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'reactstrap';
import {uiActions} from 'store/ui/ui-slice';

const ModalNotch: FC<Props> = props => {
  const {} = props;

  const {modalType, params} = useSelector(selectModalProperties);

  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(uiActions.dismissModal());
  };

  /**@ts-ignore TODO: add the proper type */
  const ModalComponent = modalType ? Modals[modalType] : null;

  if (!modalType || !ModalComponent) {
    return null;
  }

  const modalClassName =
    /**@ts-ignore TODO: add the proper type */
    modalType !== null ? ModalClasses[modalType] : 'modal-dialog-centered';

  return (
    <Modal
      isOpen={!!modalType}
      toggle={onDismiss}
      className={modalClassName}
      onClosed={onDismiss}
    >
      {modalType && <ModalComponent {...params} onDismiss={onDismiss} />}
    </Modal>
  );
};

interface Props {}

export default ModalNotch;
