import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  newPayment: () => __(translations.addPaymentDropdown.newPayment, 'New'),
  payment: () => __(translations.addPaymentDropdown.payment, 'Payment'),
  use: () => __(translations.addPaymentDropdown.use, 'use'),
  // case here is important
  credit: () => __(translations.addPaymentDropdown.credit, 'credit'),
  overpayment: () => __(translations.addPaymentDropdown.overpayment, 'overpayment'),
};
