import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  ape: () => __(translations.fiscalInfoTab.ape, 'APE'),
  capital: () => __(translations.fiscalInfoTab.capital, 'Capital'),
  companyName: () => __(translations.fiscalInfoTab.companyName, 'Company Name'),
  eoriNumber: () => __(translations.fiscalInfoTab.eoriNumber, 'EORI Number'),
  iban: () => __(translations.fiscalInfoTab.iban, 'IBAN'),
  interestRatesDisclaimer: () => __(translations.fiscalInfoTab.interestRatesDisclaimer, 'Interest rates disclaimer'),
  legalForm: () => __(translations.fiscalInfoTab.legalForm, 'Legal Form'),
  paymentMethodDisclaimer: () => __(translations.fiscalInfoTab.paymentMethodDisclaimer, 'Payment method disclaimer'),
  rcs: () => __(translations.fiscalInfoTab.rcs, 'RCS'),
  recoveryCostsDisclaimer: () => __(translations.fiscalInfoTab.recoveryCostsDisclaimer, 'Recovery costs disclaimer'),
  rexNumber: () => __(translations.fiscalInfoTab.rexNumber, 'REX Number'),
  ribAddress: () => __(translations.fiscalInfoTab.ribAddress, 'RIB Address'),
  ribOrBic: () => __(translations.fiscalInfoTab.ribOrBic, 'RIB/BIC'),
  title: () => __(translations.fiscalInfoTab.title, 'Legal Info'),
  vatNumber: () => __(translations.fiscalInfoTab.vatNumber, 'VAT Number'),
  vintageVatDisclaimer: () => __(translations.fiscalInfoTab.vintageVatDisclaimer, 'Vintage VAT disclaimer'),
};
