import React, {FC, useState} from 'react';
import CarouselPage from '../../auth-inner/CarouselPage';
import messages from './messages';
import logo from 'assets/images/india-logo.svg';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {application} from 'constants/application';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, Navigate} from 'react-router-dom';
import {Row, Col, Alert, Container} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useForgotPasswordMutation} from 'store/auth/endpoints';
import {selectIsPasswordResetTokenRequested} from 'store/auth/selectors';

interface Props {}

const ForgetPasswordPage: FC<Props> = props => {
  const {t} = useTranslation();

  const [params, setParams] = useState({
    email: '',
  });

  const passwordResetTokenRequested = useSelector(
    selectIsPasswordResetTokenRequested
  );

  const [recoverAccount, {isLoading, error}] = useForgotPasswordMutation();

  const handleSubmit = () => {
    recoverAccount(params);
  };

  const {errors: validationErrors, message: errorMessage} =
    parseValidationErrors(error);

  return (
    <React.Fragment>
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      {passwordResetTokenRequested && <Navigate to="/reset-password" />}
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="90" />{' '}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{t(...messages.reset())}</h5>
                      </div>

                      {errorMessage && (
                        <Alert color="danger" style={{marginTop: '13px'}}>
                          {errorMessage}
                        </Alert>
                      )}

                      <div className="custom-form mt-4">
                        <div className="mb-3">
                          <TextInput
                            label="E-mail"
                            onChange={email => setParams({...params, email})}
                            value={params.email}
                            errors={validationErrors.email}
                          />
                        </div>

                        <Row className="mb-3">
                          <Col className="text-end">
                            <Button
                              className="w-100 waves-effect waves-light"
                              isLoading={isLoading}
                              color="important"
                              onClick={handleSubmit}
                            >
                              {t(...messages.reset())}
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {t(...messages.backTo())}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {application.name}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
