import {ApiEndpointBuilder} from 'services/api/types';
import {Notification} from 'types/notification';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const markAllNotificationsAsRead = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Response, Request>({
    query: () => ({
      method: 'PUT',
      url: `me/notifications/all-read`,
    }),
  });
};

type Request = {};

type Response = ResponseData<ObjectId[]>;

export default markAllNotificationsAsRead;
