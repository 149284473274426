import {
  IManDayRequest,
  StoreProjectManDaysRequest,
  useStoreProjectManDaysMutation,
} from 'features/studio/projects/store/store-project-man-days';
import {
  HandleAddManDay,
  HandleChangeManDay,
  HandleRemoveManDay,
  HandleSubmit,
  SetParams,
} from './types';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {useEffect, useMemo, useState} from 'react';
import {IManDay, Project} from 'types/project';
import {ObjectId} from 'types/object-id';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import makeId from 'utils/make-id';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {User} from 'types/user';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

export const useManDaysForm = (): IManDaysForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState({
    mandays: [],
    deleted_records: [],
    mandays_global_comment: '',
  } as Params);

  const [storeProjectManDays, {isLoading: isSubmitting, error}] =
    useStoreProjectManDaysMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data as Project;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!project) return;

    setParams({...params, mandays: project.mandays ?? []});
  }, []);

  const handleAddManDay: HandleAddManDay = () => {
    const newManDays = [...params.mandays];

    newManDays.push({
      id: makeId(),
      team: [] as User[],
      inculded_in_contract: true,
      artistic_director_man_days: 0,
      project_manager_man_days: 0,
      assistant_man_days: 0,
    } as IManDay);
    setParams({...params, mandays: newManDays});
  };

  const handleChangeManDay: HandleChangeManDay = (index, key, val) => {
    const updatedManDays = [...params.mandays];

    updatedManDays[index] = {
      ...updatedManDays[index],
      [key]: val,
    };

    setParams({...params, mandays: updatedManDays});
  };

  const handleRemoveManDay: HandleRemoveManDay = index => {
    const updatedManDays = [...params.mandays];
    const deleted_records = [...params.deleted_records];

    if (project?.mandays.find(el => el.id === updatedManDays[index].id))
      deleted_records.push(updatedManDays[index].id);

    updatedManDays.splice(index, 1);

    setParams({...params, mandays: updatedManDays, deleted_records});
  };

  const shapePayload = (data: Params): StoreProjectManDaysRequest => {
    const _mandays = data.mandays.map((element: IManDayRequest) => {
      return {
        ...element,
        invoice_id: element.invoice?.id,
        team: element.team.map((el: any) => el.id),
      };
    });

    return {
      ...data,
      mandays: _mandays,
      id,
    } as StoreProjectManDaysRequest;
  };

  const handleSubmit = async () => {
    const result = await storeProjectManDays(shapePayload(params));

    if ('data' in result) {
      const project = result?.data.data;

      dispatch(toast('success', t('common.changesCommited')));
      setParams({
        mandays: project?.mandays,
        deleted_records: [],
        mandays_global_comment: project.mandays_global_comment,
      });
    }
  };

  const options = {
    team: project?.assistants.filter(el => el.is_active).map(el => el.user),
  };

  const {consumed, remain} = useMemo(() => {
    const consumed = {
      artistic_director:
        params.mandays.reduce(
          (acc, el) => acc + Number(el.artistic_director_man_days),
          0
        ) ?? 0,
      others:
        params.mandays.reduce(
          (acc, el) =>
            acc +
            Number(el.project_manager_man_days) +
            Number(el.assistant_man_days),
          0
        ) ?? 0,
    };

    const remain = {
      artistic_director: Number(
        project.artistic_director_mandays_rate - consumed.artistic_director
      ),
      others: Number(project.others_mandays_rate - consumed.others),
    };

    return {consumed, remain};
  }, [params.mandays]);

  const totals = {
    consumed,
    remain,
  };

  return {
    project,
    isSubmitting,
    params,
    options,
    totals,
    validationErros: serverError.errors,
    subject,
    setParams,
    handleAddManDay,
    handleChangeManDay,
    handleRemoveManDay,
    submit: handleSubmit,
  };
};

export default interface IManDaysForm {
  project: Project;
  isSubmitting: boolean;
  params: Params;
  options: {
    team: User[];
  };
  totals: {
    consumed: Totals;
    remain: Totals;
  };
  validationErros: any;
  subject: Subject;
  setParams: SetParams;
  handleAddManDay: HandleAddManDay;
  handleChangeManDay: HandleChangeManDay;
  handleRemoveManDay: HandleRemoveManDay;
  submit: HandleSubmit;
}

type Totals = {artistic_director: number; others: number};
export type Params = {
  mandays: IManDay[];
  mandays_global_comment?: string;
  deleted_records: ObjectId[];
};
