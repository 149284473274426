import React from 'react';
import useInvoiceForm from '../../components/use-invoice-form';
import messages from './messages';
import DocumentContext from 'features/commercial/documents/containers/document-context/document-context';
import DocumentHeader from 'features/commercial/documents/containers/document-header/document-header';
import FiscalInfoSection from 'features/commercial/documents/containers/fiscal-info-section/fiscal-info-section';
import ItemsSection from 'features/commercial/documents/containers/items-section/items-section';
import TableActions from 'features/commercial/documents/containers/table-actions/table-actions';
import TableFooter from 'features/commercial/documents/containers/table-footer/table-footer';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';

const DetailsTab = () => {
  const documentForm = useInvoiceForm();

  const {t} = useTranslation();

  return (
    <React.Fragment>
      <DocumentContext.Provider value={documentForm}>
        <Row>
          <Col lg="12">
            <DocumentHeader />
            <div className="py-2 mt-3">
              <h3 className="font-size-15 fw-bold">
                {t(...messages.invoiceSummary())}
              </h3>
            </div>
            <div className="p-4 border rounded">
              <div className="table-responsive">
                <ItemsSection />
                <TableFooter />
                <FiscalInfoSection />
              </div>
            </div>
            <TableActions />
          </Col>
        </Row>
      </DocumentContext.Provider>
    </React.Fragment>
  );
};

export default DetailsTab;
