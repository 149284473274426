import React, {FC} from 'react';
import {ColumnDef} from '@tanstack/table-core';
import TableCompact from 'features/table/table-compact';
import {Collapse} from 'reactstrap';
import {ResourceName} from 'types/resource-name';

interface Props {
  columns: ColumnDef<any>[];
  url: string;
  name: ResourceName;
  isOpen: boolean;
}

const CollapseTable: FC<Props> = props => {
  const {columns, url, name, isOpen} = props;

  return (
    <Collapse isOpen={isOpen}>
      {isOpen && (
        <TableCompact
          columns={columns}
          otherParams={{
            delimiter: '|',
          }}
          url={url}
          name={name}
        />
      )}
    </Collapse>
  );
};

export default CollapseTable;
