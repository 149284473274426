import React, {Fragment, useContext} from 'react';
import AddressesSection from '../addresses-section/addresses-section';
import documentContext from '../document-context/document-context';
import messages from './messages';
import logo from 'assets/images/india-logo.svg';
import FormattedDate from 'components/formatted-date';
import {application} from 'constants/application';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';

const DocumentHeader = () => {
  const {t} = useTranslation();

  const {doc, doc_type} = useContext(documentContext);

  return (
    <Fragment>
      <div className="invoice-title">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <div className="mb-4">
              <img src={logo} alt="" width="100" />
              <span className="logo-txt">{application.name}</span>
            </div>
          </div>
          <div className="flex-shrink-0">
            <div className="mb-4">
              <h4 className="float-end font-size-16">
                {doc_type === 'invoice' && t(...messages.invoice())}
                {doc_type === 'order' && t(...messages.order())}
                {doc_type === 'quotation' && t(...messages.quotation())}{' '}
                {t(...messages.documentNumber(), {id: doc?.document_ref})}
              </h4>
            </div>
          </div>
        </div>
        <address></address>
      </div>
      <hr className="my-4" />
      <Row>
        <Col sm="6">
          <div>
            <div>
              <h5 className="font-size-15">
                {doc_type === 'quotation' &&
                  t('quotationGeneralInformation.quotationIssueDate')}
                {doc_type === 'order' && t(...messages.documentDate())}
                {doc_type === 'invoice' && t(...messages.documentDate())}{' '}
              </h5>
              <p>
                {doc_type === 'quotation' && (
                  <FormattedDate format="date" value={doc?.created_at} />
                )}
                {doc_type === 'invoice' && (
                  <FormattedDate format="date" value={doc?.billing_date} />
                )}

                {doc_type === 'order' && (
                  <FormattedDate format="date" value={doc?.order_date} />
                )}
              </p>
            </div>
          </div>
        </Col>
        <AddressesSection />
      </Row>
    </Fragment>
  );
};

export default DocumentHeader;
