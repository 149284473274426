export enum ProjectAttachmentTypesEnum {
  HONORARIA_PROPOSAL = 'HONORARIA_PROPOSAL', // Proposition d'honoraires
  CONTRACT = 'CONTRACT', // Contrat
  CONTRACTUAL_PLAN = 'CONTRACTUAL_PLAN', // Plan contractuel
  THREE_D = 'THREE_D', // 3D
  IMAGES_PHOTOS = 'IMAGES_PHOTOS', // Images / Photos
  HUB = 'HUB', // Hub
  VIDEO = 'VIDEO', // Vidéo
  PRESS = 'PRESS', // Presse
}
