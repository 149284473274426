import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const duplicatQuotation = api.injectEndpoints({
  endpoints: builder => ({
    duplicatQuotation: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `/quotations/${params.id}/duplicate`,
          body: {
            directory_id: params.directory_id,
          },
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  directory_id?: ObjectId;
};

type Response = ResponseData<Quotation>;

export const {useDuplicatQuotationMutation} = duplicatQuotation;
