import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Product} from 'types/product';
import {ResponseData} from 'types/response-data';

const storeProduct = api.injectEndpoints({
  endpoints: builder => ({
    storeProduct: builder.mutation<Response, StoreProductRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `products`,
          body: {
            comment: params.comment,
            description: params.description,
            in_catalog: params.in_catalog ? 1 : 0,
            name: params.name,
            product_collection_id: params.collection?.id,
            product_family_id: params.families?.map(x => x.id),
            vat_id: params.vat?.id,
          },
        };
      },
    }),
  }),
});

export type StoreProductRequest = {
  collection?: Option;
  vat?: Option;
  comment: string;
  description?: string;
  families: Option[];
  id?: ObjectId;
  in_catalog: boolean;
  label?: string;
  name?: string;
};

type Response = ResponseData<Product>;

export const {useStoreProductMutation} = storeProduct;
