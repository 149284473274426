import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useActionProductForm} from './use-delete-product';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {Alert, Card, CardBody, FormGroup} from 'reactstrap';
import {Input, Label, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const ProductActionDeleteForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    toggleVariant,
    toggleSelectAll,
    selectAll,
    selectedVariants,
    validationErrors,
    messageAlert,
    errorMessage,

    variants,
  } = useActionProductForm(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div className="mb-2">{t('actionProductPage.deleteDetails')}</div>
        <div className="mb-3">
          <FormGroup>
            <Input
              type="checkbox"
              id={`clone-variant-all`}
              className="me-2"
              checked={selectAll}
              onChange={toggleSelectAll}
            />
            <Label for={`clone-variant-all`}>
              {t(...messages.selectAll())}
            </Label>
          </FormGroup>
        </div>
        <div>
          {variants.map(v => (
            <Card
              key={v.id}
              color={clsx({primary: selectedVariants[v.id]})}
              outline
            >
              <CardBody className="p-2">
                <FormGroup className="" check>
                  <Input
                    type="checkbox"
                    className="me-2"
                    id={`clone-variant-${v.id}`}
                    checked={selectedVariants[v.id]}
                    onChange={() => toggleVariant(v)}
                  />
                  <Label
                    check
                    for={`clone-variant-${v.id}`}
                    className="text-uppercase"
                  >
                    {v.name}
                  </Label>
                </FormGroup>
              </CardBody>
            </Card>
          ))}
        </div>
        <div>
          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
        <div>
          <div className="row mb-4">
            <div className="col">
              <div>
                {messageAlert && selectAll && (
                  <Alert color="warning">
                    {t('actionProductPage.alertsDeleteManyVariants')}
                  </Alert>
                )}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default ProductActionDeleteForm;
