import {ApiEndpointBuilder} from 'services/api/types';

const resetPasswordEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation({
    query: info => ({
      url: 'reset-password',
      method: 'POST',
      body: info,
    }),
  });
};

export default resetPasswordEndpoint;
