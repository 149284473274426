import React, {FC} from 'react';
import EmptyState from './empty-state';
import './gallery-view.styles.scss';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {Media} from 'types/media';

interface Props {
  images: Partial<Media>[];
  previewSize?: number;
  imageClassName?: string;
  onlyFirst?: boolean;
  size?: 'large' | 'medium' | 'small';
}

const GalleryView: FC<Props> = (props: Props) => {
  const {images, previewSize = 32, imageClassName, onlyFirst = true} = props;

  const {t} = useTranslation();
  return (
    <PhotoProvider pullClosable={false}>
      <div className="foo">
        {!images.length && <EmptyState size={props.size} />}
        {images.map((item, index) => (
          <PhotoView key={index} src={item.original}>
            {!onlyFirst || (onlyFirst && index === 0) ? (
              <img
                className={clsx({
                  'me-2  object-fit-cover null-cursor-pointer': 1,
                  [imageClassName ?? 'd-block']: !!imageClassName,
                })}
                src={item.thumb}
                width={previewSize}
                height={previewSize}
              />
            ) : (
              <></>
            )}
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
};

export default GalleryView;
