const V = ['CCV', 'DCV', 'V'];
const Z = ['CCZ', 'DCZ', 'Z'];
const H = ['CCH', 'CCR', 'CCC', 'C', 'H', 'R'];
const F = ['CFV', 'CFZ'];
const S = ['CCS'];
const OTHERS = ['G', 'L', 'R', 'E'];

const assignPermissions = (
  subjects: string[][],
  actions: DocumentActionType[]
) => {
  return actions
    .map(a => {
      return subjects.flat().map(s => {
        return `${s}__${a}`;
      });
    })
    .flat();
};

export const documentPermissionMaker = (role: DocumentRoleType) => {
  switch (role) {
    case 'STUDIO_ADMIN':
      return [
        ...assignPermissions(
          [V, Z, H, S, OTHERS],
          ['SHOW', 'EXPORT', 'CREDITS_OVERPAYMENTS_USE', 'PRINT']
        ),

        ...assignPermissions(
          [H, OTHERS],
          [
            'CREATE_AND_EDIT',
            'TRANSFER',
            'GENERATE_CREDIT',
            'PAYMENTS',
            'SEND',
            'DUPLICATE',
          ]
        ),
      ];
    case 'STUDIO_USER':
      return assignPermissions([V, Z, S], ['SHOW']);
    case 'PETIT_OBJETS_ADMIN':
      return [
        ...assignPermissions(
          [V, Z, H, F, S, OTHERS],
          [
            'SHOW',
            'CREATE_AND_EDIT',
            'EXPORT',
            'CREDITS_OVERPAYMENTS_USE',
            'PAYMENTS',
            'TRANSFER',
            'PREPARE',
            'SEND',
            'GENERATE_LABEL',
            'DUPLICATE',
            'PRINT',
          ]
        ),

        ...assignPermissions([Z], ['GENERATE_CREDIT', 'VALIDATE']),
      ];
    case 'MOBILIER_ADMIN':
    case 'MOBILIER_USER':
    case 'PETIT_OBJETS_USER':
      return [
        ...assignPermissions(
          [V, Z, H, F, S, OTHERS],
          [
            'SHOW',
            'CREATE_AND_EDIT',
            'EXPORT',
            'CREDITS_OVERPAYMENTS_USE',
            'PAYMENTS',
            'TRANSFER',
            'PREPARE',
            'SEND',
            'GENERATE_LABEL',
            'DUPLICATE',
            'PRINT',
          ]
        ),
      ];

    case 'LOGISTICIEN_ADMIN':
      return [
        ...assignPermissions([V, Z, H, F, S, OTHERS], ['SHOW']),
        ...assignPermissions(
          [V, Z, F, S],
          [
            'CREATE_AND_EDIT',
            'TRANSFER',
            'PREPARE',
            'PRINT',
            'SEND',
            'GENERATE_LABEL',
            'DUPLICATE',
            'EXPORT',
          ]
        ),
      ];
    case 'LOGISTICIEN_USER':
      return assignPermissions([V, Z, F, S], ['SHOW']);
    case 'ACCOUNTING_USER':
      return assignPermissions([H, V, F, S, Z, OTHERS], ['SHOW', 'EXPORT']);
    case 'ROOT':
    case 'MANAGER':
      return assignPermissions(
        [V, Z, F, S, H, OTHERS],
        [
          'SHOW',
          'CREATE_AND_EDIT',
          'EXPORT',
          'CREDITS_OVERPAYMENTS_USE',
          'PAYMENTS',
          'GENERATE_CREDIT',
          'TRANSFER',
          'PREPARE',
          'SEND',
          'GENERATE_LABEL',
          'DUPLICATE',
          'VALIDATE',
          'UNLOCK',
          'PRINT',
        ]
      );
    default:
      return [];
  }
};

type DocumentActionType =
  | 'SHOW'
  | 'CREATE_AND_EDIT'
  | 'TRANSFER'
  | 'EXPORT'
  | 'CREDITS_OVERPAYMENTS_USE'
  | 'PAYMENTS'
  | 'GENERATE_CREDIT'
  | 'DUPLICATE'
  | 'PREPARE'
  | 'SEND'
  | 'GENERATE_LABEL'
  | 'VALIDATE'
  | 'UNLOCK'
  | 'PRINT';

export type DocumentRoleType =
  | 'ROOT'
  | 'MANAGER'
  | 'ACCOUNTING_USER'
  | 'LOGISTICIEN_USER'
  | 'LOGISTICIEN_ADMIN'
  | 'PETIT_OBJETS_USER'
  | 'MOBILIER_USER'
  | 'MOBILIER_ADMIN'
  | 'PETIT_OBJETS_ADMIN'
  | 'STUDIO_USER'
  | 'STUDIO_ADMIN';
