import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {useStoreUserMutation} from 'store/users/endpoints';

export const useCreateUserForm = () => {
  const [storeUser, {isLoading, error}] = useStoreUserMutation();

  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '',
    password_confirmation: '',
    role: undefined,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeUser(params);

    if ('data' in result) {
      navigate('/dashboard/users');
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
