import React, {FC} from 'react';
import messages from '../messages';
import {PricingFilters} from '../pricing-grid/types';
import AsyncTagSelect from 'components/async-tag-select/async-tag-select';
import DateInput from 'components/date-input';
import TagSelect from 'components/tag-select/tag-select';
import {FilterMap} from 'features/table/slice/types';
import {
  OPTIONS,
  OPTIONSCatalog,
} from 'pages/commercial/pricing/grid-controls/constants';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Label, Row} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<PricingFilters>;
  onChangeFilters: (key: PricingFilters, value: any) => void;
  onClearFilters?: () => void;
  additionnalFilters?: any;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters, additionnalFilters} = props;

  const collection = (filters['filter[product_collection_id]'] ??
    []) as ObjectId[];

  return (
    <SearchSection
      onChangeFilters={onChangeFilters}
      onClearFilters={onClearFilters}
      filters={filters}
      additionalActions={additionnalFilters}
    >
      <Row className="mb-3">
        <Col lg="6" md="6">
          <FormGroup>
            <DateInput
              label={t(...messages.dateFrom())}
              value={filters['filter[date_from]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[date_from]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="6" md="6">
          <FormGroup>
            <DateInput
              label={t(...messages.dateTo())}
              value={filters['filter[date_to]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[date_to]', newDate);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              key={`directory-families-${collection}`}
              endPoint="data/product-families"
              otherParams={
                collection.length > 0
                  ? {
                      product_collections: collection,
                    }
                  : undefined
              }
              className="mb-1"
              label={t(...messages.families())}
              isMulti
              loadOnClick
              value={filters['filter[product_family_name]']}
              onChange={o => onChangeFilters('filter[product_family_name]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/products"
              className="mb-1"
              loadOnClick
              label={t(...messages.products())}
              value={filters['filter[products]']}
              onChange={o => onChangeFilters('filter[products]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/suppliers-type-company"
              reduceOption="display_name"
              loadOnClick
              className="mb-1"
              label={t(...messages.supplier())}
              value={filters['filter[supplier_name]']}
              onChange={o => onChangeFilters('filter[supplier_name]', o)}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <TagSelect
              label={t(...messages.catalog())}
              // @ts-ignore
              options={OPTIONSCatalog}
              // @ts-ignore
              getOptionLabel={(o: Option) => t(...o.label())}
              isClearable
              value={filters['filter[catalog]']}
              onChange={o => onChangeFilters('filter[catalog]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/colors"
              label={t(...messages.color())}
              loadOnClick
              onChange={o => onChangeFilters('filter[color_name]', o)}
              value={filters['filter[color_name]']}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/matters"
              label={t(...messages.material())}
              loadOnClick
              onChange={o => onChangeFilters('filter[matter_name]', o)}
              value={filters['filter[matter_name]']}
            />
          </FormGroup>
        </Col>
        {/* <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/product-variants-categories"
              label={t(...messages.category())}
              onChange={o =>
                onChangeFilters('filter[product_variant_category_name]', o)
              }
              value={filters['filter[product_variant_category_name]']}
            />
          </FormGroup>
        </Col> */}
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncTagSelect
              endPoint="data/projects"
              loadOnClick
              label={t(...messages.project())}
              onChange={o => onChangeFilters('filter[project]', o)}
              value={filters['filter[project]']}
            />
          </FormGroup>
        </Col>
        {/* <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_old_collection"
                checked={!!filters['filter[is_old_collection]']}
                onChange={(o: any) =>
                  onChangeFilters('filter[is_old_collection]', o.target.checked)
                }
              />
              <Label className="form-check-label" htmlFor="is_old_collection">
                {t(...messages.isoldCollection())}
              </Label>
            </div>
          </FormGroup>
        </Col> */}
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_blocked"
                checked={!!filters['filter[is_blocked]']}
                onChange={(o: any) =>
                  onChangeFilters('filter[is_blocked]', o.target?.checked)
                }
              />
              <Label className="form-check-label" htmlFor="is_blocked">
                {t(...messages.isBlocked())}
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
