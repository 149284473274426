import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import {useSaveQuotationtAsForm} from './use-action-quotation-archive';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const QuotationArchiveActionConfirm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {submit, isLoading, message} = useSaveQuotationtAsForm(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <p>{message} </p>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default QuotationArchiveActionConfirm;
