import React, {useState} from 'react';
import {Button, ButtonGroup} from 'reactstrap';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import messages from './messages';
import {useSelector} from 'react-redux';
import selectAlertCount from 'store/global/selectors/alert-count';
import Can from 'features/hylian-shield/can';

const ValueDisplayToggle = () => {
  const navigate = useNavigate();
  const {stock} = useSelector(selectAlertCount);
  const location = useLocation();

  const {t} = useTranslation();
  return (
    <div>
      <ButtonGroup>
        <Button
          color="light"
          outline
          onClick={() => navigate('/dashboard/products-stock/quantity')}
          active={location.pathname === '/dashboard/products-stock/quantity'}
        >
          {t(...messages.quantity())}
        </Button>
        <Button
          color="light"
          outline
          onClick={() => navigate('/dashboard/products-stock/value')}
          active={location.pathname === '/dashboard/products-stock/value'}
        >
          {t(...messages.value())}
        </Button>

        <Can action="alert" subject="recap-stock">
          <Button
            color="light"
            outline
            onClick={() =>
              navigate('/dashboard/products-stock/alerts/mobilier')
            }
            active={location.pathname === '/dashboard/products-stock/alerts'}
          >
            {t(...messages.alerts())}
            <span className="badge text-white bg-warning  ms-1 font-size-16">
              {stock}
            </span>
          </Button>
        </Can>
      </ButtonGroup>
    </div>
  );
};

export default ValueDisplayToggle;
