import {createSelector} from '@reduxjs/toolkit';
import {selectDomain} from '.';

export default createSelector([selectDomain], domain => {
  const allFamilies = domain.expandedFamilies;

  const expandFamily = Object.values(allFamilies).at(0);

  return expandFamily;
});
