import React, {useMemo} from 'react';
import GridControls from '../grid-controls/grid-controls';
import makeGridColumns from './pricing-grid-columns';
import Table from 'features/table';
import {Col, Row} from 'reactstrap';

const PricingGrid = () => {
  const columns = useMemo(() => makeGridColumns(), []);

  return (
    <Row>
      <Col>
        <Table
          routerParams
          hideSearch
          url="prices"
          otherParams={{
            delimiter: '|',
          }}
          columns={columns}
          filtersComponent={GridControls}
          name="product-variants-prices"
        />
      </Col>
    </Row>
  );
};

export default PricingGrid;
