import {useState} from 'react';
import messages from '../messages';
import {
  UpdateMaterialRequest,
  useUpdateMaterialMutation,
} from '../store/endpoints/update-material';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {Material} from 'types/materials';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUpdateMaterialForm = (onDismiss: () => void, material?: Material) => {
  const [updateMaterial, {isLoading, error}] = useUpdateMaterialMutation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);
  const {actions} = useListSlice();
  const name = 'materials';
  const url = 'materials';
  const handleSubmit = async () => {
    const result = await updateMaterial(updateParams);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.materialUpdatedSuccess())));
      dispatch(actions.clearSelection(name));
      dispatch(actions.loadData({url, name}));
      onDismiss();
    }
  };

  const [updateParams, setUpdateParams] = useState<Params>({
    id: material?.id,
    name: {
      en: material?.translation?.en ?? '',
      fr: material?.translation?.fr ?? '',
    },
  });

  return {
    updateParams,
    setUpdateParams,
    updateIsLoading: isLoading,
    updateValidationErrors: serverError.errors,
    updateErrorMessage: serverError.message,
    updateSubmit: handleSubmit,
  };
};
type Params = UpdateMaterialRequest;
