import {ObjectId} from './object-id';
import {Option} from './option';
import {Wallet} from 'features/commercial/documents/types/wallet';
import RelatedDocument from './related-document';
import {CommercialDocumentType} from 'features/commercial/documents/types/commercial-document-type';

export interface Payment {
  id: ObjectId;
  type?: Option;
  payment_method?: Option;
  reference?: string;
  wallet?: Wallet;
  amount?: string;
  credit_id?: ObjectId;
  overpayment_id?: ObjectId;
  date?: string;
  related_documents?: RelatedDocument[];
  model_document_ref?: string;
  model_id?: ObjectId;
  model_type?: CommercialDocumentType;
  status?: string;
  reconciled_payment?: boolean;
}

export enum PaymentTypeEnum {
  SETTLEMENT = 'SETTLEMENT',
  DEPOSIT = 'DEPOSIT',
  REFUND = 'REFUND',
  BANK_FEES = 'BANK_FEES',
}
