import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductCustomization} from 'types/product-customization';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateCustomization = api.injectEndpoints({
  endpoints: builder => ({
    updateCustomization: builder.mutation<Response, CustomizationsRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `/products/${params.id}/customizations`,
          body: {
            deleted_records: params.deletedRecords,
            customizations: params.customizations.map(x => ({
              id: x.id,
              customization_category_id: x.customization_category?.id,
              customization_family_id: x.customization_family?.id,
              product_variant_id: x?.product_variant?.id,
              is_variant: x?.is_variant,
            })),
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'product', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type CustomizationsRequest = {
  id?: ObjectId;
  deletedRecords: ObjectId[];
  customizations: ProductCustomization[];
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateCustomizationMutation} = updateCustomization;
