import React, {KeyboardEvent, useState} from 'react';
import messages from '../messages';
import './login-page.styles.scss';
import {useLoginForm} from './use-login-form';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert} from 'reactstrap';

const FormSection = () => {
  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useLoginForm();
  const [passwordShow, setPasswordShow] = useState(false);

  const {t} = useTranslation();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <div className="custom-form mt-4 pt-2">
      <TextInput
        onKeyDown={handleKeyboardEventDown}
        onChange={email => setParams({...params, email})}
        value={params.email}
        label={t(...messages.email())}
        errors={validationErrors.email}
      />

      <div className="my-3 d-flex align-items-center">
        <TextInput
          containerStyle="flex-grow-1"
          onChange={password => setParams({...params, password})}
          value={params.password}
          onKeyDown={handleKeyboardEventDown}
          label={t(...messages.password())}
          errors={validationErrors.password}
          type={passwordShow ? 'text' : 'password'}
        />
        <button
          onClick={() => setPasswordShow(!passwordShow)}
          className="btn btn-light align-self-end  shadow-none ms-0"
          type="button"
          id="password-addon"
        >
          <i className="mdi mdi-eye-outline"></i>
        </button>
      </div>

      <div className="row mb-4">
        <div className="col">
          <div className="form-check">
            <input
              className="form-check-input mb-2"
              type="checkbox"
              checked={params.remember_me}
              onChange={e =>
                setParams({...params, remember_me: e.target?.checked})
              }
              id="remember-check"
            />
            <label className="form-check-label " htmlFor="remember-check">
              {t(...messages.rememberMe())}
            </label>
          </div>
          <div>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </div>
          <div className="mt-3 d-grid">
            <Button
              isLoading={isLoading}
              className="btn bg-primary border-primary btn-block custom-text-button"
              onClick={submit}
            >
              {t(...messages.signIn())}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
