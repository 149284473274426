import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderProduction.title, 'Production'),
  despositRequest: () => __(translations.orderProduction.despositRequest, 'Deposit request'),
  statedAnnoucment: () => __(translations.orderProduction.statedAnnoucment, 'Stated Annoucment'),
  delayIsHidden: () => __(translations.orderProduction.delayIsHidden, 'Hide delay'),
  changesCommited: () => __(translations.orderProduction.changesCommited, 'Your changes has been saved successfully!'),
  deadlineAnnouncedFr: () => __(translations.orderProduction.deadlineAnnouncedFr, 'Deadline announced to client (FR)'),
  deadlineAnnouncedEn: () => __(translations.orderProduction.deadlineAnnouncedEn, 'Deadline announced to client (EN)'),
};
