import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useAttachDirectoryForm} from './use-attach-directory-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Option} from 'types/option';

const AttachDirectoryForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useAttachDirectoryForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  const directory = useSelector(selectActiveModalParams);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <AsyncItemPicker
            endPoint={`data/directories?type=COMPANY`}
            onChange={company => setParams({...params, company})}
            value={params.company}
            getOptionLabel={(option: Option) => option.display_name}
            onKeyDown={handleKeyboardEventDown}
            label={t(...messages.mergeWith())}
            errors={validationErrors.company}
          />

          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default AttachDirectoryForm;
