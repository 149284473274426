import React, {useState} from 'react';
import messages from '../messages';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {Button as RSButton} from 'reactstrap';
import {Alert} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {authActions} from 'store/auth/auth-slice';
import {useVerifyPasswordResetTokenMutation} from 'store/auth/endpoints';
import {selectResetInfo} from 'store/auth/selectors';

const VerifyTokenSection = () => {
  const {t} = useTranslation();

  const info = useSelector(selectResetInfo);

  const [params, setParams] = useState({
    token: '',
    email: info.email,
  });

  const [verifyToken, {isLoading, error}] =
    useVerifyPasswordResetTokenMutation();

  const {errors: validationErrors, message: errorMessage} =
    parseValidationErrors(error);

  const handleSubmit = () => {
    verifyToken(params);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const restart = () => {
    dispatch(authActions.resetPasswordForgot());
    navigate('/forgot-password');
  };

  return (
    <div>
      <div className="text-center">
        <h5 className="mb-0">{t(...messages.pageTitle())}</h5>
        <p className="text-muted mt-2">
          {t(...messages.resetCodeInstructions(), {email: info.email})}.
        </p>
      </div>
      <div className="mb-3">
        <TextInput
          label={t(...messages.token())}
          onChange={token => setParams({...params, token})}
          value={params.token}
          autoFocus
          errors={validationErrors.token}
        />
      </div>
      <div className="mb-3"></div>
      <div className="mb-3">
        {errorMessage ? (
          <Alert color="danger" style={{marginTop: '13px'}}>
            {errorMessage}
          </Alert>
        ) : null}
      </div>
      <div className="mb-3">
        <Button
          color="important"
          className="w-100 waves-effect waves-light"
          onClick={handleSubmit}
          isLoading={isLoading}
        >
          {t(...messages.submit())}
        </Button>
      </div>
      <div className="mt-5 text-center">
        <p className="text-muted mb-0">
          <Link to="/login" className="text-primary fw-semibold">
            {' '}
            {t(...messages.backToLogin())}{' '}
          </Link>{' '}
        </p>
        <p className="text-muted mb-0">
          <RSButton color="link" className="fw-semibold" onClick={restart}>
            {' '}
            {t(...messages.sendAgain())}{' '}
          </RSButton>{' '}
        </p>
      </div>
    </div>
  );
};

export default VerifyTokenSection;
