import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.exportsPage.pageTitle, 'Exports'),
  exports: () => __(translations.exportsPage.exports, 'exports list'),
  type: () => __(translations.exportsPage.type, 'Type'),
  id: () => __(translations.exportsPage.id, 'Id'),
  name: () => __(translations.exportsPage.name, 'Name'),
  date: () => __(translations.exportsPage.created_at, 'Date'),
  size: () => __(translations.exportsPage.size, 'Size'),
};
