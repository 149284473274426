import {CommercialProduct} from '../../types/commercial-product';
import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';

export default createAction(actions.updateItem.type, function prepare<
  T extends KeyType
>(item: CommercialProduct, key: T, value: CommercialProduct[T]) {
  // if (key === 'discount' && typeof value !== 'number') {
  //   throw new Error('Discount value must be a number');
  // }

  // if (key === 'notes' && typeof value !== 'string') {
  //   throw new Error('Notes value must be a string.');
  // }

  return {
    payload: {
      item,
      key,
      value,
    },
  };
});

type KeyType = keyof CommercialProduct;

type ValueByKeyType<T> = {
  discount: number;
  notes: string;
};

type ValueByKeyTypeExtended<T> = ValueByKeyType<T> & {};
