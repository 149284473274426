import {ApiEndpointBuilder} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {Role} from 'types/role';
import {User} from 'types/user';

const UpdateUserEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Response, UpdateUserRequest>({
    query: params => ({
      method: 'PUT',
      url: `users/${params.id}`,
      body: {
        first_name: params.first_name,
        last_name: params.last_name,
        username: params.username,
        email: params.email,
        role_id: params.role?.id,
        phone_number: params.phone_number,
      },
    }),
  });
};

export type UpdateUserRequest = {
  id?: ObjectId;
  last_name?: string;
  first_name?: string;
  email?: string;
  username?: string;
  role?: Role | undefined;
  phone_number?: string;
};

type Response = ResponseData<User>;

export default UpdateUserEndpoint;
