import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  categories: () => __(translations.familyForm.category, 'Categories'),
  category: () => __(translations.familyForm.category, 'Category'),
  code: () => __(translations.familyForm.code, 'Code'),
  inspiration: () => __(translations.familyForm.inspiration, 'Inspiration'),
  name: () => __(translations.familyForm.name, 'Name'),
  okay: () => __(translations.familyForm.okay, 'Okay'),
  title: () => __(translations.familyForm.alertTitle, 'Family'),
};
