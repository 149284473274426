import React from 'react';
import {AppRoute} from './types';
import ProjectsPage from 'pages/dashboard/studio';
import CreateProjectPage from 'pages/dashboard/studio/create';
import EditProjectPage from 'pages/dashboard/studio/edit';

const studioRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/projects',
    component: <ProjectsPage />,
    subject: ['studio-petits-projects', 'studio-projects'],
  },
  {
    path: '/dashboard/projects/create',
    component: <CreateProjectPage />,
    subject: ['studio-petits-projects', 'studio-projects'],
  },
  {
    path: '/dashboard/projects/:id/*',
    component: <EditProjectPage />,
    subject: ['studio-petits-projects', 'studio-projects'],
  },
];

export default studioRoutes;
