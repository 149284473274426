import React, {FC} from 'react';
import messages from './messages';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import {Trans, useTranslation} from 'react-i18next';
import {BaseCompactOrder} from '../../types/order';
import Currency from 'components/currency/currency';

interface PayCellProps {
  row: BaseCompactOrder;
}

const PayCell: FC<PayCellProps> = props => {
  const {row} = props;
  const {t} = useTranslation();

  if (row.payment_is_completed) {
    return (
      <AsyncPreviewTooltip type="ORDER" id={row.id} column="pay">
        {row.overpayment && row.overpayment > 0 ? (
          <span className="w-100 text-danger  text-center fw-bold  ">
            {t(...messages.overpaid())} ({row.overpayment})
          </span>
        ) : (
          <span className="w-100 text-success text-center fw-bold ">
            {t(...messages.paid())}
          </span>
        )}
      </AsyncPreviewTooltip>
    );
  } else {
    return <Currency value={row.pay} />;
  }
};

export default PayCell;
