import React, {useEffect, useState} from 'react';
import {useShowOrderQuery} from '../../store/endpoints/show-order';
import {Order} from '../../types/order';
import OrderDetailsRouter from '../order-details-router/order-details-router';
import OrderSummarySection from '../order-summary-section/order-summary-section';
import OrderTabPane from '../order-tab-pane/order-tab-pane';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Button from 'components/button';
import DocumentSkeleton from 'features/commercial/documents/components/document-skeleton';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import ActionsTab from '../actions-tab/actions-tab';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

const OrderMasterDetails = () => {
  const {t} = useTranslation();

  const [pageInfo, setPageInfo] = useState({title: '', path: ''});

  const {id} = useParams();

  const {data, isFetching, error, isError, isSuccess, refetch} =
    useShowOrderQuery({id});

  const order = data?.data as Order;

  const serverError = parseValidationErrors(error);

  const orderType = order?.document_type;

  useEffect(() => {
    if (orderType === 'SUPPLIER') {
      setPageInfo({
        title: t(...messages.supplierOrdersListPageTitle()),
        path: '/dashboard/supplier-orders',
      });
    } else if (orderType === 'CLIENT') {
      setPageInfo({
        title: t(...messages.customerOrdersListPageTitle()),
        path: '/dashboard/customer-orders',
      });
    } else if (orderType === 'STOCK') {
      setPageInfo({
        title: t(...messages.stockOrdersListPageTitle()),
        path: '/dashboard/stock-orders',
      });
    }
  }, [orderType]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>
            {t(...messages.helmetTitle(), {ref: order?.document_ref})}
          </title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            breadcrumbItems={[
              {
                title: t('common.dashboard'),
                path: '/dashboard',
              },
              {
                title: pageInfo.title,
                path: pageInfo.path,
              },
            ]}
          />
          {/* Content */}

          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  {isSuccess && (
                    <OrderSummarySection order={order} onReload={refetch} />
                  )}
                  <OrderTabPane />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {isFetching && <DocumentSkeleton />}

                  {isError && (
                    <div>
                      <Alert color="danger">{serverError.message}</Alert>
                      <div className="d-flex justify-content-center py-3">
                        <Button onClick={refetch}>
                          <Icon name="refresh-cw" size={15} className="me-2" />
                          Reload
                        </Button>
                      </div>
                    </div>
                  )}

                  {isSuccess && <OrderDetailsRouter />}
                  {isSuccess && (
                    <AppBarActions>
                      <ActionsTab order={order} />
                    </AppBarActions>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrderMasterDetails;
