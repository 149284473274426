import {createAsyncThunk} from '@reduxjs/toolkit';
import googleSigninService from 'services/google-signin';

/**
 * Setup google auth
 *
 */
export const setupGoogleAuth = createAsyncThunk(
  'oauth/setupGoogleAuth',
  async () => {
    googleSigninService.configure();
  }
);
