import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectGlobalComments = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectGlobalComments: builder.mutation<
      Response,
      StoreProjectGlobalCommentsRequest
    >({
      query: (body: StoreProjectGlobalCommentsRequest) => {
        const url = body.sourcing_fees_global_comment
          ? `projects/${body.id}/global-comments?for_sourcing_fees`
          : body.sells_ffe_global_comment
          ? `projects/${body.id}/global-comments?for_sells_ffe`
          : body.sells_vintage_global_comment
          ? `projects/${body.id}/global-comments?for_sells_vintage`
          : `projects/${body.id}/global-comments?for_conclusion`;

        return {
          method: 'POST',
          url,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type StoreProjectGlobalCommentsRequest = {
  id: ObjectId;
  sourcing_fees_global_comment?: string;
  sells_ffe_global_comment?: string;
  sells_vintage_global_comment?: string;
  conclusion_global_comment?: string;
};

type Response = ResponseData<Project>;

export const {useStoreProjectGlobalCommentsMutation} =
  storeProjectGlobalComments;
