import React, {FC, Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, Row, Col, Spinner, Alert} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import SliderVariant from 'pages/dashboard/products/edit/info-section/slider';
import {useShowVariantQuery} from 'store/production/endpoints/show-variant';
import {Link} from 'react-router-dom';
import Currency from 'components/currency/currency';
import {parseValidationErrors} from 'services/api/utils/parse-error';

interface Props {
  onDismiss: () => void;
}

const VariantPreviewModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {id, name, productId} = useSelector(selectActiveModalParams);

  const {data, isFetching, error} = useShowVariantQuery({
    id,
    productId,
  });

  const {message} = parseValidationErrors(error);

  const variant = data?.data;

  const manufacturers = variant?.production_times;
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title(), {name})} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <div>
          {isFetching && (
            <div className="text-center py-5">
              <Spinner />
            </div>
          )}
          {!isFetching && !!message && (
            <div className=" py-5">
              <Alert color="danger">{message}</Alert>
            </div>
          )}
          {!!variant && (
            <Row>
              <Col>
                <SliderVariant variant={variant} />
              </Col>
              <Col>
                <div>
                  <h3 className="center-block">{variant?.name}</h3>
                  <br />
                  <p className="center-block">
                    {t(...messages.matters())}
                    <ul>
                      {variant?.matters?.map(matter => (
                        <li key={matter.id}>{matter.name}</li>
                      ))}
                    </ul>
                  </p>
                  <p className="center-block">{variant?.dimensions}</p>
                  <br />
                  <p className="center-block">
                    {manufacturers?.map(m => (
                      <div key={m.id}>
                        <Link
                          to={`/dashboard/directories/${m.id}/general `}
                          target="_blank"
                        >
                          {m.directory?.display_name}
                        </Link>
                      </div>
                    ))}
                  </p>
                  <br />
                  <p className="center-block">
                    <Currency value={variant?.selling_price} />
                    {` `}
                    {t(...messages.includingTaxes())}
                  </p>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </ModalBody>
    </Fragment>
  );
};

export default VariantPreviewModal;
