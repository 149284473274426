import {useState, useEffect} from 'react';
import {
  useEditOrderMutation,
  EditOrderRequest,
} from 'features/commercial/orders/store/endpoints/edit-order';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';
import {Order} from 'features/commercial/orders/types/order';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useOrderGeneralInformationForm = () => {
  const [editOrder, {isLoading, error, isSuccess}] = useEditOrderMutation();

  const serverError = parseValidationErrors(error);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await editOrder(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  const [params, setParams] = useState<Params>({
    id: undefined,
    with_vat: false,
    is_detaxed: false,
  });
  const {id} = useParams();

  const {data} = useSelector(selectOrder({id}));

  const order = data?.data as Order;
  const is_locked = order?.status === 'VALIDATED';

  useEffect(() => {
    if (order) {
      setParams({
        id: order.id,
        order_type: order.document_type,
        client_display_name: order.directory?.display_name,
        client: order.directory,
        order_date: order.order_date,
        due_date: order.due_date,
        document_type: order.document_type,
        order: order,
        commercial_type: order.commercial_type,
        document_category: order.document_category,
        project: order.project,
        user: order.user,
        origin: order.origin,
        ref_client: order.ref_client,
        private_comment: order.private_comment,
        production_declared_completion_date:
          order.production_declared_completion_date,
        language: order.language,
        currency: order.currency,
        is_after_sale_service: order.is_after_sale_service,
        supplier_name: order.supplier_name,
        deadline_announced_en: order.deadline_announced_en,
        deadline_announced_fr: order.deadline_announced_fr,
        hide_delay: order.hide_delay,
        document_sub_type: order.document_sub_type,
        production_requested_deposit: order.production_requested_deposit,
        estimated_supplier_time: order.estimated_supplier_time,
        delivery_date: order.delivery_date,
        production_comments: order.production_comments,
        supplier_reference: order.supplier_reference,
        currency_conversion: order.currency_conversion,
        projectFeesType: order.project_fee_type,
        designation: order.designation,

        // vat and taxes
        // document_type: order.document_type,
        vat_number: order.vat_number,
        vat_mode: order.vat_mode,
        with_vat: order.tax_mode === 'NO-VAT',
        is_detaxed: order.tax_mode === 'DETAXED',
        // transporter
        transporter: order.transporter,
        transporter_price: order.transporter_price,
      });
    }
  }, [order]);

  return {
    setParams,
    params,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    is_locked,
  };
};

type Params = EditOrderRequest;
