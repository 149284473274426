import React, {useContext} from 'react';
import {ContactItem} from './contact-item';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Row, Col} from 'reactstrap';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {uiActions} from 'store/ui/ui-slice';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const ContactListTab = () => {
  const dispatch = useDispatch();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const {id} = useParams();

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  const contactList = directory?.contacts ?? [];

  const handleContact = () => {
    if (directory) {
      dispatch(
        uiActions.showModal({
          modalType: MODAL.UPDATE_LIST_OF_CONTACT,
          params: {
            directory: directory,
          },
        })
      );
    }
  };

  const {t} = useTranslation();
  return (
    <div>
      <Row className="mb-3">
        <Col md={10}>
          <SectionSeparator
            title={t(...messages.title(), {name: directory?.display_name})}
          />
        </Col>
        <Col md={2}>
          <Button
            disabled={directory?.is_archived || !canEdit}
            className="w-100"
            color="important"
            onClick={handleContact}
          >
            <Icon name="plus" className="me-1" size={18} />
            {t(...messages.addContactList())}
          </Button>
        </Col>
      </Row>
      <Row>
        {Object.values(contactList).map((p, i) => (
          <ContactItem
            key={p.id}
            contact={p}
            directory={directory}
          ></ContactItem>
        ))}
      </Row>
    </div>
  );
};

export default ContactListTab;
