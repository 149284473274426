import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockMovements.title, 'Stock movements'),
  addStockMovement: () => __(translations.stockMovements.addStockMovement, 'Add stock movement'),
  viewStockMovements: () => __(translations.stockMovements.viewStockMovement, 'View stock movement'),
  clickToEdit: () => __(translations.stockMovements.clickToEdit, 'Click to insert a comment...'),
  stockLocation: () => __(translations.stockMovements.stockLocation, 'Stock location'),
  quantity: () => __(translations.stockMovements.quantity, 'Quantity'),
  comment: () => __(translations.stockMovements.comment, 'Comment'),
  status: () => __(translations.stockMovements.status, 'Status'),
  totalInStock: () => __(translations.stockMovements.totalInStock, 'Total in Stock'),

  serialNumber: () => __(translations.stockMovements.serialNumber, 'Serial Number'),
  order: () => __(translations.stockMovements.order, 'Order'),
  transfer: () => __(translations.stockMovements.transfer, 'Transfer'),
};
