import React, {FC, useContext} from 'react';
import OptionRemovalButton from '../../components/option-removal-button/option-removal-button';
import EmptyLabel from '../../components/spacing/empty-label';
import updateItemOption from '../../store/actions/update-item-option';
import updateItemOptionVariant from '../../store/actions/update-item-option-variant';
import {CommercialProduct} from '../../types/commercial-product';
import invoiceContext from '../document-context/document-context';
import messages from './messages';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Col, Row} from 'reactstrap';
import {CommercialProductOption} from 'types/commercial-product-option';
import {Option} from 'types/option';
import {CompactCustomizationOption} from 'types/product-customization';
import RemoveContainer from '../../components/spacing/remove-container';
import RemovalContainer from '../../components/spacing/removal-cross-container';
import Icon from '@ailibs/feather-react-ts/dist/Icon';

interface Props {
  option: CommercialProductOption;
  item: CommercialProduct;
  index: number;
  lineIndex: number;
}

const OptionItem: FC<Props> = props => {
  const {option, item, index, lineIndex} = props;

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProductOption, v: any) => {
    dispatch(updateItemOption(option, key, v));
  };

  const handleUpdateOption = (v?: CompactCustomizationOption) => {
    dispatch(updateItemOptionVariant(option, v));
  };

  const {t} = useTranslation();

  const {validationErrors, is_locked} = useContext(invoiceContext);

  const validate = (k: string) =>
    validationErrors[`products.${lineIndex}.options.${index}.${k}`];

  const options =
    option.type === 'COMPONENT'
      ? item.variant?.components
      : item?.variant?.customization_options;

  const isExternalProduct = item.commercial_product_type === 'EXTERNAL_PRODUCT';

  const showAsEditableInput = !!option.option_value?.id || isExternalProduct;

  return (
    <div>
      <Row>
        <Col md={4} lg={4}>
          {showAsEditableInput ? (
            <div>
              <TextInput
                disabled={is_locked}
                label={t(...messages.option())}
                onChange={x => handleUpdate('name', x)}
                value={option.name}
              />
              <RemoveContainer>
                <RemovalContainer>
                  <Icon
                    name="x"
                    onClick={() => handleUpdateOption(undefined)}
                    size={15}
                    className="text-danger"
                  />
                </RemovalContainer>
              </RemoveContainer>
            </div>
          ) : (
            <ItemPicker
              onChange={(v: any) => {
                handleUpdateOption(v);
              }}
              disabled={is_locked}
              getOptionLabel={(v: Option) => v?.name}
              value={option.option_value}
              getOptionValue={(v: Option) => v?.id}
              label={t(...messages.option())}
              errors={validate('name')}
              options={options ?? []}
            />
          )}
        </Col>
        <Col md={2}>
          <TextInput
            onChange={x => handleUpdate('price_ht', x)}
            errors={validate('price_ht')}
            disabled={is_locked}
            value={option.price_ht}
            label={t(...messages.amount())}
          />
        </Col>
        <Col>
          <EmptyLabel />
          <OptionRemovalButton option={option} item={item} />
        </Col>
      </Row>
    </div>
  );
};

export default OptionItem;
