import React from 'react';
import tabs from '../../constants/tabs';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';

const OrderTabPane = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const prefix = `/dashboard/orders/${id}`;

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-2 tab-pane">
      {tabs.map(x => (
        <NavItem key={x.to}>
          <NavLink
            className={active =>
              clsx({active: active.isActive}, 'px-3 nav-link')
            }
            to={`${prefix}/${x.to}`}
          >
            {t(...x.title())}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default OrderTabPane;
