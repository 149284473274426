import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderVtaAndTaxes.title, 'VAT & Taxes'),
  orderVtaNumber: () => __(translations.orderVtaAndTaxes.orderVtaNumber, 'VAT Number'),
  orderVtaMode: () => __(translations.orderVtaAndTaxes.orderVtaMode, 'VAT Mode'),
  orderVatIncluded: () => __(translations.orderVtaAndTaxes.orderVatIncluded, 'VAT exemption (force VAT)'),
  orderVatExcluded: () => __(translations.orderVtaAndTaxes.orderVatExcluded, 'Without VAT'),
  changesCommited: () => __(translations.orderVtaAndTaxes.changesCommited, 'Your changes has been saved successfully!'),
};
