import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.productEcoTaxPage.title, 'Ecotax product'),
  date: () => __(translations.productEcoTaxPage.date, 'Date'),
  product_name: () => __(translations.productEcoTaxPage.productName, 'Product name'),
  quantity: () => __(translations.productEcoTaxPage.quantity, 'Quantity'),
  customs_code: () => __(translations.productEcoTaxPage.customsCode, 'Customs code'),
  ecopart_code: () => __(translations.productEcoTaxPage.ecopartCode, 'Ecopart code'),
  ecopartFurniture: () => __(translations.productEcoTaxPage.ecopartFurniture, 'Ecoparticipation furniture .excl'),
  totalEcopartFurniture: () => __(translations.productEcoTaxPage.totalEcopartFurniture, 'Total ecoparticipation furniture .excl'),
  codeEcoFuriniture: () => __(translations.productEcoTaxPage.codeEcoFuriniture, 'Code Eco-furniture'),
  total_ecopart_code: () => __(translations.productEcoTaxPage.totalEcopartCode, 'Total Ecopart Code'),
  ecopart_rate: () => __(translations.productEcoTaxPage.ecopartRate, 'Ecopart rate'),
  ecopart_rate_vat: () => __(translations.productEcoTaxPage.ecopartRateVat, 'Ecopart rate vat'),
  eco_code: () => __(translations.productEcoTaxPage.ecoCode, 'Eco code'),
  product_code: () => __(translations.productEcoTaxPage.productCode, 'Product code'),
  ecoTax: () => __(translations.productEcoTaxPage.exotax, 'Add ecotax'),
  status: () => __(translations.productEcoTaxPage.status, 'Status'),
  delete: () => __(translations.productEcoTaxPage.delete, 'Delete'),
  export: () => __(translations.productEcoTaxPage.export, 'Export'),
};
