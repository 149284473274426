import React, {FC} from 'react';
import CardSkeleton from './card-skeleton';
import {Table, flexRender} from '@tanstack/react-table';
import styled from 'styled-components';

interface TableHeaderProps {
  table: Table<unknown>;
  isLoading?: boolean;
}

const CardRows: FC<TableHeaderProps> = props => {
  const {table, isLoading} = props;

  return (
    <>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        table?.getRowModel()?.rows.map(row => (
          <MobileRowStyle key={row.id}>
            {table?.getHeaderGroups()?.map(headerGroup => (
              <div key={headerGroup.id}>
                {headerGroup.headers.map((header, index) =>
                  header.column.columnDef.meta?.hide ? null : (
                    <MobileCellStyle key={header.id}>
                      <span className="me-2 fw-bold ">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </span>
                      <span className="text-end ">
                        {flexRender(
                          row?.getVisibleCells()[index]?.column.columnDef.cell,
                          row?.getVisibleCells()[index]?.getContext()
                        )}
                      </span>
                    </MobileCellStyle>
                  )
                )}
              </div>
            ))}
          </MobileRowStyle>
        ))
      )}
    </>
  );
};

const MobileCellStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 5px;
`;
const MobileRowStyle = styled.div`
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid var(--bs-gray-200);
`;

const RowStyle = styled.tr<{sorted?: string; sortable?: boolean}>`
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  max-height: 100px;

  padding: 0;
  &:hover {
    background-color: var(--bs-gray-200);
    color: var(--bs-gray-700);
  }
`;

export default CardRows;
