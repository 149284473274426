import {useState} from 'react';
import {addMonths} from 'date-fns';
import {
  useStoreInvoiceMutation,
  StoreInvoiceRequest,
} from 'features/commercial/invoices/store/endpoints/store-invoice';
import {useShowDocumentCategoriesQuery} from 'features/commercial/quotations/store/endpoints/show-document-category';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {Directory} from 'types/directory';
import {DocumentCategory} from 'types/document-category';
import {now} from 'utils/now';
import realDate from 'utils/real-date';
import {useSeeder} from 'utils/use-seeder';
import toast from 'store/ui/actions/toast';
import {useDispatch} from 'react-redux';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useCreditForm = () => {
  const [storeCredit, {isLoading, error}] = useStoreInvoiceMutation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeCredit(params);

    if ('data' in result) {
      const invoice = result?.data.data;
      navigate(`/dashboard/invoices/${invoice.id}/details`);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const [params, setParams] = useState<Params>({
    document_type: 'CREDIT',
    client: undefined,
    category: undefined,
    commercial_type: undefined,
    project: undefined,
    origin: undefined,
    language: undefined,
    billing_date: now(),
    due_date: realDate(addMonths(new Date(), 1)),
  });

  const {data} = useShowDocumentCategoriesQuery();

  const categories = (data?.data || []) as DocumentCategory[];

  const syncParams = (client: Directory) => {
    const updatedParams = {...params};

    updatedParams.client = client;

    updatedParams.category = guessCategory(client, categories);

    setParams(updatedParams);
  };

  return {
    setParams,
    params,
    syncParams,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreInvoiceRequest;

const guessCategory = (client: Directory, categories: DocumentCategory[]) => {
  let maybeCategory;

  if (client) {
    if (client.is_private) {
      maybeCategory = categories?.filter(c => c.type === 'PRIVATE').at(0);
    }
    if (client.is_reseller) {
      maybeCategory = categories?.filter(c => c.type === 'RESELLER').at(0);
    }
    if (client.is_decorator) {
      maybeCategory = categories?.filter(c => c.type === 'DECORATOR').at(0);
    }
    if (client.is_luxury_brand) {
      maybeCategory = categories?.filter(c => c.type === 'LUXURY_BRAND').at(0);
    }
  }

  return maybeCategory;
};
