import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import {AppActionBarContext} from './app-bar-actions';
import logoWhite from 'assets/images/india-logo-white.svg';
import logoDark from 'assets/images/india-logo.svg';
import {application} from 'constants/application';
import {leftSidebarTypes} from 'constants/layout';
import {Link} from 'react-router-dom';
import {useLocalStorage, useMediaQuery} from 'usehooks-ts';
import './header-styles.scss';

interface Props {}

const Header: FC<Props> = props => {
  const {} = props;

  const isMobile = useMediaQuery('(max-width: 996px)');

  const [localCount, setLocalCount] = useLocalStorage('count', 1);

  const [count, setCount] = useState<number>(localCount);

  const screens = isMobile ? ['sm', 'none'] : ['sm', 'lg'];

  useEffect(() => {
    setTimeout(() => {
      const index = localCount;
      if (screens[index] === 'none') {
        document.body.classList.remove('sidebar-enable');
        document.body.setAttribute(
          'data-sidebar-size',
          leftSidebarTypes.DEFAULT
        );
      } else {
        document.body.classList.add('sidebar-enable');
        document.body.setAttribute('data-sidebar-size', screens[index]);
      }
    }, 0);
  }, []);

  useLayoutEffect(() => {
    const index = count % 2;

    if (screens[index] === 'none') {
      document.body.classList.remove('sidebar-enable');
    } else {
      document.body.classList.add('sidebar-enable');
      document.body.setAttribute('data-sidebar-size', screens[index]);
    }

    setLocalCount(index);
  }, [isMobile, count]);

  const tToggle = () => {
    setCount(count + 1);
  };

  const actionBar = useContext(AppActionBarContext);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/welcome" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoDark} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="24" />{' '}
                  <span className="logo-txt">{application.name}</span>
                </span>
              </Link>

              <Link to="/welcome" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoWhite} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoWhite} alt="" height="24" />{' '}
                  <span className="logo-txt">{application.name}</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div
            ref={actionBar.updateContainer}
            className="d-flex text-nowrap flex-grow-1 align-items-center action-bar  "
          />
          <div className="d-flex">
            {/* light / dark mode */}

            {/* <NotificationDropdown /> */}

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
