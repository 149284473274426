import {useState} from 'react';
import {useArchiveQuotationMutation} from 'features/commercial/quotations/store/endpoints/action-quotation-archive';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSaveQuotationtAsForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useArchiveQuotationMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleSubmit = async () => {
    const result = await submit({id});
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
