import React, {FC} from 'react';
import Actions from './app-bar-actions';
import Filters from './filter';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Button, CardBody, Col, Row} from 'reactstrap';
import {useLocalStorage} from 'usehooks-ts';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const OverpaymentControls: FC<FilterProps> = props => {
  const {filters, onChangeFilters, onClearFilters} = props;
  const {t} = useTranslation();

  return (
    <Row>
      <Col>
        <CardBody>
          <Row className="mb-3">
            <Col md={2} className=" d-flex inline-block">
              <Actions filters={filters} />
            </Col>
          </Row>
          <Filters
            filters={filters}
            onChangeFilters={onChangeFilters}
            onClearFilters={onClearFilters}
          />
        </CardBody>
      </Col>
    </Row>
  );
};

export default OverpaymentControls;
