import {accountApi} from '../endpoints';
import {AccountState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';
import {WritableDraft} from 'types/writable-draft';

const toggleNotificationsMatcher =
  accountApi.endpoints.toggleNotifications.matchFulfilled;

/**
 * Toggle notifications on or off
 *
 */
const toggleNotificationsReducer = (
  state: WritableDraft<AccountState>,
  action: PayloadAction<ResponseData<User>>
) => {
  state.notificationsEnabled = action.payload.data.notification_status;
};

export const toggleNotifications = {
  matcher: toggleNotificationsMatcher,
  reducer: toggleNotificationsReducer,
};
