import React, {FC} from 'react';
import GeneralInfoSection, {UserNav} from '../general-info-section';
import AvatarWithText from '../general-info-section/skeleton';
import ShowUserRoutes from '../show-user-router';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Container, Row} from 'reactstrap';
import {Card, CardBody, Col} from 'reactstrap';
import {useShowUserQuery} from 'store/users/endpoints';

interface Props {}

const ShowUserMasterDetail: FC<Props> = () => {
  const {t} = useTranslation();

  const {id = Infinity} = useParams();

  const {data, isFetching} = useShowUserQuery({id});

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t(...messages.dashboard()), path: '/dashboard'},
            {title: t(...messages.users()), path: '/dashboard/users'},
            {title: t(...messages.pageTitle()), path: '#'},
          ]}
        />
        <Row>
          <React.Fragment>
            <Row>
              <Col xl={2} lg={2} />

              <Col xl={9} lg={8}>
                <Card>
                  <CardBody>
                    {!!data?.data ? (
                      <GeneralInfoSection user={data?.data} />
                    ) : (
                      <AvatarWithText />
                    )}

                    <UserNav user={data?.data}></UserNav>
                  </CardBody>
                </Card>

                <ShowUserRoutes user={data?.data} />
              </Col>
            </Row>
          </React.Fragment>
        </Row>
      </Container>
    </div>
  );
};

export default ShowUserMasterDetail;
