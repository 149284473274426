import React, {FC} from 'react';
import messages from '../messages';
import {CustomOrderPaginationMeta} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Card, Col, Row} from 'reactstrap';
import TagsItems from 'components/tags-card';
import Currency from 'components/currency/currency';

interface TagsProps {}

const TagsRow: FC<TagsProps> = props => {
  const {t} = useTranslation();

  const meta = useSelector(
    selectEntityMeta<CustomOrderPaginationMeta>('customer-orders')
  );

  return (
    <Row className="align-items-center justify-content-between mb-3">
      <Col sm={2}>
        <TagsItems title={t(...messages.totalCustomertotalHt())}>
          
          <Currency value={meta?.clients_total_ht} />
        </TagsItems>
      </Col>

      <Col sm={2}>
        <TagsItems title={t(...messages.totalCustomertotalTtc())} >
          
          <Currency value={meta?.clients_total_ttc} />
          </TagsItems>
      </Col>
      <Col sm={2}>
        <TagsItems title={t(...messages.totalCustomertotalAcompte())}>
       
            
   
          <Currency value={meta?.clients_total_deposit_payments } />
        </TagsItems>
      </Col>
      <Col sm={2}>
        <TagsItems title={t(...messages.totalCustomertotalPayment())}>
          <Currency value={meta?.clients_total_payment } />
        </TagsItems>
      </Col>
      <Col sm={2}>
        <TagsItems title= {t(...messages.totalCustomertotalSolde())} >
           
          <Currency value={meta?.clients_solde_payment ?? 0} />
        </TagsItems>
      </Col>
    </Row>
  );
};

interface TagItemsProps {
  tag: number;
}



export default TagsRow;
