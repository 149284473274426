import {LarvaTears, ValidationErrors} from '../types';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}
/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isServerError(
  error: unknown
): error is {message: string; errors?: ValidationErrors} {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export const parseValidationErrors = (error: unknown) => {
  const e = error as any;
  return {
    errors: e?.data?.errors ?? {},
    message: e?.data?.message,
  } as LarvaTears;
};
