import React from 'react';
import {AppRoute} from './types';
import CreditsPage from 'pages/accounting/credit';
import EcoTaxPage from 'pages/accounting/eco-tax/ecotax-page';
import OverpaymentsPage from 'pages/accounting/overpayments/overpayments-page';
import ProductEcoTaxPage from 'pages/accounting/product-ecotax/product-ecotax-page';
import PaymentsPage from 'pages/accounting/payments';

const accountingRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/credits/*',
    component: <CreditsPage />,
    subject: 'accounting-credit-overpayment',
  },
  {
    path: '/dashboard/payments',
    component: <PaymentsPage />,
    subject: 'accounting-payments',
  },
  {
    path: '/dashboard/overpayments',
    component: <OverpaymentsPage />,
    subject: 'accounting-credit-overpayment',
  },
  {
    path: '/dashboard/ecotaxes',
    component: <EcoTaxPage />,
    subject: 'accounting-others',
  },
  {
    path: '/dashboard/product-ecotaxes',
    component: <ProductEcoTaxPage />,
    subject: 'accounting-others',
  },
];

export default accountingRoutes;
