import React from 'react';
import EmptyCell from 'components/empty-cell';
import {ProductEcoTax} from 'features/acounting/types/eco-tax';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';

const columns: ColumnDef<ProductEcoTax>[] = [
  {
    header: r => <Trans i18nKey="productEcoTaxPage.productName" />,
    accessorFn: row => (
      <Link
        target="_blank"
        to={`/dashboard/products/${row.product_id}/variants/${row.product_variant_id}`}
      >
        <b>
          <EmptyCell cell={row.product_variant_name} />
        </b>
      </Link>
    ),
    id: 'product_name',
    cell: r => r.getValue(),
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="productEcoTaxPage.quantity" />,
    accessorFn: row => row.quantity ?? '-',
    id: 'quantity',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productEcoTaxPage.ecopartFurniture" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.ecopart_rate}} />
    ),
    id: 'ecopart_rate',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productEcoTaxPage.totalEcopartFurniture" />,
    accessorFn: row => (
      <Trans
        i18nKey="numbers.currency"
        values={{val: row.total_ecopart_rate}}
      />
    ),
    id: 'total_ecopart_code',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productEcoTaxPage.codeEcoFuriniture" />,
    accessorFn: row => <EmptyCell cell={row.ecopart_code} />,
    id: 'eco_code',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productEcoTaxPage.productCode" />,
    accessorFn: row => <EmptyCell cell={row.product_code} />,
    id: 'product_code',
    enableSorting: true,
    cell: r => r.getValue(),
  },
];

export default columns;
