import {loginWithApple as loginWithAppleAction} from '../actions/login-with-apple';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {isAnyOf} from '@reduxjs/toolkit';
import {AUTH_PROVIDER} from 'constants/auth-provider';
import {AppleLoginResult} from 'services/apple-signin/types';
import {WritableDraft} from 'types/writable-draft';

/**
 * Match successful apple login actions
 *
 */
const loginWithAppleMatcher = isAnyOf(loginWithAppleAction.fulfilled);

/**
 * Apple login reducer
 *
 */
const loginWithAppleReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<AppleLoginResult>
) => {
  state.socialAuth.id = payload.user?.id;
  state.socialAuth.provider_token = payload.provider_token;
  state.socialAuth.first_name = payload.user?.first_name ?? '';
  state.socialAuth.isEmailHidden = !!payload.user?.is_email_hidden;
  state.socialAuth.last_name = payload.user?.last_name ?? '';
  state.socialAuth.email = payload.user?.email ?? '';
  state.socialAuth.provider_name = AUTH_PROVIDER.APPLE;
};

export const loginWithApple = {
  matcher: loginWithAppleMatcher,
  reducer: loginWithAppleReducer,
};
