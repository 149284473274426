import {Invoice} from 'features/commercial/invoices/types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getProjectVintageInvoices = api.injectEndpoints({
  endpoints: builder => ({
    getProjectVintageInvoices: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: params.product_type
            ? `projects/${params.id}/invoices?for_vintage&${params.product_type}`
            : `projects/${params.id}/invoices?for_vintage`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  product_type?: string;
};

type Response = ResponseData<Invoice[]>;

export const {useLazyGetProjectVintageInvoicesQuery} =
  getProjectVintageInvoices;
