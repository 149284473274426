import TransferQuoteModal from '../containers/transfer-quote-modal/transfer-quote-modal';
import QuotationArchiveActionConfirm from './action/archive';
import QuotationDeleteActionConfirm from './action/delete';
import QuotationDuplicatActionConfirm from './action/duplicate';
import QuotationSendEmailAction from './action/send-email';
import AddressPickerModal from './address-picker-modal/address-picker-modal';
import PaymentPickerModal from './payment-picker-modal/payment-picker-modal';
import {MODAL} from 'features/modal-notch/constants';

export default {
  [MODAL.QUOTATION_DUPLICAT_ACTIONS_MODAL]: QuotationDuplicatActionConfirm,
  [MODAL.QUOTATION_ARCHIVE_ACTIONS_MODAL]: QuotationArchiveActionConfirm,
  [MODAL.QUOTATION_DELETE_ACTIONS_MODAL]: QuotationDeleteActionConfirm,
  [MODAL.QUOTATION_SEND_EMAIL_ACTIONS_MODAL]: QuotationSendEmailAction,
  [MODAL.ADDRESS_PICKER_MODAL]: AddressPickerModal,
  [MODAL.PAYMENT_PICKER_MODAL]: PaymentPickerModal,
  [MODAL.TRANSFER_QUOTE]: TransferQuoteModal,
};
