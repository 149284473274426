import React, {Fragment} from 'react';
import {FC} from 'react';
import messages from './messages';
import {useContactListForm} from './use-update-list-of-contact';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  Button,
  Col,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {Directory} from 'types/directory';

interface Props {
  directory: Directory;
  onDismiss: () => void;
}

export const updateContactList: FC<Props> = props => {
  const {directory, onDismiss, ...all} = props;
  const {t} = useTranslation();
  const {
    contactList,
    updateContactList,
    isLoading,
    submit,
    filterOption,
    validationErrors,
    isdisabled,
  } = useContactListForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <Row>
          <Col md={6}>
            <AsyncItemPicker
              label={t(...messages.contact())}
              getOptionLabel={(xx: Directory) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    {xx.display_name}

                    {contactList?.directory?.id !== xx.id && (
                      <Link
                        target="_blank"
                        to={`/dashboard/directories/${xx.id}/general`}
                      >
                        <Icon name="external-link" size={15} />
                      </Link>
                    )}
                  </div>
                );
              }}
              disabled={isdisabled}
              filterOption={filterOption}
              noOptionsMessage={() => (
                <Link
                  target="_blank"
                  to={`/dashboard/directories/contacts/create`}
                >
                  <Icon name="plus" size={15} />
                  <span>{t(...messages.addNewContact())}</span>
                </Link>
              )}
              endPoint="data/directories?type=INDIVIDUAL&only_unlinked"
              errors={validationErrors[`contact_list.directory_id`]}
              value={contactList.directory}
              onChange={x => updateContactList(contactList, 'directory', x)}
            />
          </Col>

          <Col md={6}>
            <TextInput
              label={t(...messages.job())}
              type="text"
              errors={validationErrors[`contact_list.job`]}
              value={contactList.job}
              onChange={x => updateContactList(contactList, 'job', x)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};
