import React, {FC} from 'react';
import messages from '../messages';
import {PricingFilters, PricingPaginationMeta} from '../pricing-grid/types';
import TagsItem from 'components/tags-card';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Col, Row} from 'reactstrap';
import Currency from 'components/currency/currency';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {useGetPricingProductTotalsQuery} from 'features/commercial/quotations/store/endpoints/get-pricing-product-totals';
import {FilterMap} from 'features/table/slice/types';

interface TagsProps {
  filters: FilterMap<PricingFilters>;
}

const TagsRow: FC<TagsProps> = props => {
  const {filters} = props;
  const {t} = useTranslation();

  const {data: totalsData, isFetching: isLoadingTotals} =
    useGetPricingProductTotalsQuery(filters);

  const meta = totalsData?.data;
  return (
    <Row className="align-items-center justify-content-center mb-3">
      <Col sm={3}>
        <TagsItem
          isLoading={isLoadingTotals}
          title={t(...messages.avrgProfit())}
        >
          <NaturalNumber value={meta?.marge_avg} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem
          isLoading={isLoadingTotals}
          title={t(...messages.nomberofSellls())}
        >
          <NaturalNumber value={meta?.sales_count} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem
          isLoading={isLoadingTotals}
          title={t(...messages.totalBenefit())}
        >
          <Currency value={meta?.benefits} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem
          isLoading={isLoadingTotals}
          title={t(...messages.totalTurnover())}
        >
          <Currency value={meta?.revenue} />
        </TagsItem>
      </Col>
    </Row>
  );
};

export default TagsRow;
