import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {CardHeader, CardSubtitle, CardTitle} from 'reactstrap';
import messages from '../messages';
import Currency from 'components/currency/currency';
import ISellsFFEForm from '../use-sells-ffe-form';
import ItemPicker from 'components/item-picker';
import {Option} from 'types/option';

const HeaderSection: FC<Props> = ({
  params,
  grand_total_ht,
  grand_margin_excl,
  options,
  setParams,
}) => {
  const {t} = useTranslation();

  const {product_types} = options;

  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <div className="w-25">
          <div>
            <CardTitle tag="h5">{t(...messages.sellsFFE())}</CardTitle>
          </div>
          <div>
            <ItemPicker
              options={product_types}
              value={params.product_type}
              isClearable
              getOptionValue={(v: Option) => v?.value}
              getOptionLabel={(v: Option) => v?.label}
              onChange={(product_type: Option | any) => {
                setParams({...params, product_type});
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-4">
          <div>
            <CardTitle tag="h5">{t(...messages.totalHT())}</CardTitle>
            <CardSubtitle tag="p">
              <Currency value={grand_total_ht} />
            </CardSubtitle>
          </div>
          <div>
            <CardTitle tag="h5">{t(...messages.globalMarginExcl())}</CardTitle>
            <CardSubtitle tag="p">
              <Currency value={grand_margin_excl} />
            </CardSubtitle>
          </div>
        </div>
      </div>
    </CardHeader>
  );
};

type Props = ISellsFFEForm;

export default HeaderSection;
