// Import necessary modules and functions from external packages
import {selectProject} from 'features/studio/projects/store/show-project';
import {useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';

import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ContactStatusEnum, ContactTypesEnum} from 'constants/contact';
import {IContact} from 'types/project';

import {
  useStoreProjectContactsMutation,
  StoreProjectContactsRequest,
} from 'features/studio/projects/store/store-projects-contacts';
import {
  EnumMapping,
  useEnumAsOption,
} from 'features/studio/projects/utils/use-enum-as-option';
import toast from 'store/ui/actions/toast';
import _ from 'lodash';
import {ObjectId} from 'types/object-id';
import {useGroupByType} from 'features/studio/projects/utils/use-regroup-by-type';
import {ValidationErrors} from 'services/api/types';
import {
  HandleAddInput,
  HandleInputChange,
  HandleRemoveInput,
  HandleRowsInputsChange,
  HandleSubmit,
  SetParams,
  SetSearch,
} from './types';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

// Define an interface for regrouping contacts
export interface IRegroupedContact {
  type: ContactTypesEnum;
  data: (IContact & {identifier: number})[];
}

// Define a custom hook for managing contact forms
export const useContactsForm = (): IContactForm => {
  const {t} = useTranslation();

  const [search, setSearch] = useState<string>('');

  const [input, setInput] = useState<IContact>({} as IContact);
  const [params, setParams] = useState<Params>({
    contacts: [],
    deleted_records: [],
  });

  const dispatch = useDispatch();

  // Use the updateProject mutation hook to update project data
  const [storeProjectContacts, {isLoading, error, data: response}] =
    useStoreProjectContactsMutation();

  // Parse server validation errors
  const serverError = parseValidationErrors(error);

  // Retrieve the project ID from the URL parameters
  const {id} = useParams();

  // Retrieve project data from the Redux store
  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!data?.data) return;

    setParams({contacts: data.data.contacts});
  }, [data]);

  // adapt data to awaited payload shape
  const shapePayload = (params: Params): StoreProjectContactsRequest => {
    const contacts = params.contacts.map(({contact, type, comment, status}) => {
      return {
        directory_id: contact.id,
        type,
        comment,
        status,
      };
    });

    return {
      id,
      contacts,
      deleted_records: params.deleted_records,
    };
  };

  // Handle form submission
  const handleSubmit = async () => {
    const result = await storeProjectContacts(shapePayload(params));

    // Update form parameters if data is received from the server
    if ('data' in result) {
      const {contacts} = result?.data.data;
      dispatch(toast('success', t('common.changesCommited')));

      setParams({contacts});
    }
  };

  // Add a new input to the form
  const handleAddInput = () => {
    if (!input.type || !input.contact) return;

    // Remove object from deleted_records if exists
    if (params.deleted_records?.indexOf(input.contact.id) !== -1) {
      const index = params.deleted_records?.indexOf(input.contact.id);
      const deleted_records = [...(params.deleted_records || [])];

      deleted_records.slice(index, 1);
      setParams({
        ...params,
        deleted_records,
      });
    }

    // Overwrite object if already exists
    const isAlreadyExist = !!params.contacts.find(
      e => e.contact.id === input.contact.id
    );
    if (isAlreadyExist) {
      const updateEntry = [...params.contacts];

      const index = params.contacts.findIndex(
        el => el.contact.id === input.contact.id
      );
      updateEntry[index] = input;

      setParams({
        contacts: updateEntry,
      });
    } else {
      setParams({contacts: [...params.contacts, input]});
    }

    // Empty input object
    return setInput({} as IContact);
  };

  // Remove an input from the form based on its index
  const handleRemoveInput = (index: number) => {
    if (params.contacts.length === 0) return;

    const contacts = [...params.contacts];
    const deleted_records = [
      ...(params.deleted_records || []),
      contacts[index].contact.id,
    ];

    contacts.splice(index, 1);
    setParams({contacts, deleted_records});
  };

  // Handle changes in input values
  const handleInputChange = (key: keyof IContact, value: any) => {
    if (key === 'type') value = value.id;
    setInput({...input, [key]: value});
  };

  const handleRowsInputsChange = (key: string, value: any, index: number) => {
    if (typeof value === 'object') value = value.id;

    const updatedContacts = [...params.contacts];

    updatedContacts[index] = {
      ...updatedContacts[index],
      [key]: value,
    };

    setParams({
      ...params,
      contacts: updatedContacts,
    });
  };

  // Regroup contacts based on their types using useMemo to optimize performance
  const regroupedContacts = useMemo(() => {
    const filtred = search
      ? params.contacts.filter(el =>
          el.contact?.display_name
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        )
      : params.contacts;

    return useGroupByType(filtred, 'contacts') as IRegroupedContact[];
  }, [params.contacts, search]);

  // Generate options
  const options = {
    types: useEnumAsOption(ContactTypesEnum, 'editProjectPage'),
    status: useEnumAsOption(ContactStatusEnum, 'editProjectPage'),
  };

  // open by default none empty accordions
  const defaultOpenedAccordion = useMemo(() => {
    return regroupedContacts
      .map((el, index) => ({...el, identifier: index}))
      .filter(el => el.data.length > 0)
      .map(el => el.identifier.toString());
  }, [regroupedContacts]);

  // Return the values and functions needed for managing the contact form
  return {
    isLoading,
    params,
    search,
    regroupedContacts,
    input,
    statusText: response?.message,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    options,
    defaultOpenedAccordion,
    subject,
    setParams,
    setSearch,
    handleAddInput,
    handleInputChange,
    handleRowsInputsChange,
    handleRemoveInput,
    submit: handleSubmit,
  };
};

export interface IContactForm {
  isLoading: boolean;
  params: Params;
  search: string;
  regroupedContacts: IRegroupedContact[];
  input: IContact;
  statusText?: string;
  validationErrors?: ValidationErrors;
  errorMessage?: string;
  options: {
    types: EnumMapping<typeof ContactTypesEnum>[];
    status: EnumMapping<typeof ContactStatusEnum>[];
  };
  defaultOpenedAccordion: string[];
  subject: Subject;
  setParams: SetParams;
  setSearch: SetSearch;
  handleAddInput: HandleAddInput;
  handleInputChange: HandleInputChange;
  handleRowsInputsChange: HandleRowsInputsChange;
  handleRemoveInput: HandleRemoveInput;
  submit: HandleSubmit;
}

// Define a custom type for the form parameters
export type Params = {contacts: IContact[]; deleted_records?: ObjectId[]};
