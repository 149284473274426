import {accountApi} from '../endpoints';
import {AccountState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {Notification} from 'types/notification';
import {ResponseData} from 'types/response-data';
import {WritableDraft} from 'types/writable-draft';

const markNotificationAsReadMatcher =
  accountApi.endpoints.markNotificationAsRead.matchFulfilled;

/**
 * Delete document
 *
 */
const markNotificationAsReadReducer = (
  state: WritableDraft<AccountState>,
  action: PayloadAction<ResponseData<Notification>>
) => {
  const id = action.payload.data.id;
  state.readNotifications[id] = true;
};

export const markNotificationAsRead = {
  matcher: markNotificationAsReadMatcher,
  reducer: markNotificationAsReadReducer,
};
