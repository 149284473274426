import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.stockProductsDisputSAVPage.action, 'Action'),
  title: () => __(translations.stockProductsDisputSAVPage.title, 'Stock Products'),
  edit: () => __(translations.stockProductsDisputSAVPage.edit, 'Edit'),
  enter: () => __(translations.stockProductsDisputSAVPage.enter, 'Enter'),
  product: () => __(translations.stockProductsDisputSAVPage.product, 'Product'),
  status: () => __(translations.stockProductsDisputSAVPage.status, 'Status'),
  stockLocation: () => __(translations.stockProductsDisputSAVPage.stockLocation, 'Stock location'),
  subLocation: () => __(translations.stockProductsDisputSAVPage.subLocation, 'Sub-location'),
};
