import {createAction} from '@reduxjs/toolkit';
import {ProductVariant} from 'types/product-variant';

export default createAction(
  'costs/updateComponents',
  function prepare(variant: ProductVariant) {
    return {
      payload: variant,
    };
  }
);
