import React, {FC} from 'react';
import {IconName} from '@ailibs/feather-react-ts';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useLocalStorage} from 'usehooks-ts';
import {useSelector} from 'react-redux';
import selectAlertCount from 'store/global/selectors/alert-count';
import {Subject} from 'features/hylian-shield/types';
import Can from 'features/hylian-shield/can';
import clsx from 'clsx';

export interface RouteItem {
  path: string;
  name: string | (() => [string, ...any[]]);
  icon?: IconName;
  children?: RouteItem[];
  subject: Subject | Subject[];
  newTab?: boolean;
}

const SidebarItem: FC<RouteItem> = ({path, name, icon, children, newTab}) => {
  const {stock} = useSelector(selectAlertCount);

  const {t} = useTranslation();

  const [c] = useLocalStorage('count', 1);

  const badge = (
    <span className="badge text-white bg-warning  ms-1 font-size-13">
      {stock}
    </span>
  );

  return (
    <li>
      <Link
        to={path}
        target={newTab ? '_blank' : '_self'}
        className={clsx({
          'has-arrow': !!children,
        })}
      >
        <Name name={name} icon={icon} />
        {path.includes('/dashboard/products-stock') && badge}
      </Link>
      {children && (
        <ul className="sub-menu">
          {children.map((child, key) => (
            <Can
              key={key}
              subject={child.subject}
              action={['show', 'edit', 'administration']}
            >
              <SidebarItem key={child.path} {...child} />
            </Can>
          ))}
        </ul>
      )}
    </li>
  );
};

const Name: FC<Partial<RouteItem> & {visible?: boolean}> = ({
  name,
  visible,
  icon,
}) => {
  const {t} = useTranslation();

  return (
    <>
      {!!icon && <Icon name={icon} />}
      <span>{typeof name === 'function' ? t(...name()) : name}</span>
    </>
  );
};
export default SidebarItem;
