import React from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {CompactCustomerOrder} from 'features/commercial/orders/types/order';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import TtcColumn from 'features/commercial/documents/components/ttc-column/ttc-column';
import Currency from 'components/currency/currency';
import CostumeCurrency from 'components/costume-currency/costume-currency';
import PayCell from 'features/commercial/orders/components/cells-features/pay-cell';
import TooltipCell from 'components/tooltip-cell';
import RelatedDocument from 'types/related-document';
import {TYPE} from 'components/async-preview-tooltip/constant';

const columns: ColumnDef<CompactCustomerOrder>[] = [
  {
    header: r => <Trans i18nKey="customerOrderPage.countermark" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.client_id}/general`}
        target="_blank"
      >
        <b>{row.client_name}</b>
      </Link>
    ),
    id: 'client_name',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="customerOrderPage.number" />,
    accessorFn: row => (
      <AsyncPreviewTooltip type="ORDER" column="ref" id={row.id}>
        <Link to={`/dashboard/orders/${row.id}/details`} target="_blank">
          <b className="text-nowrap">{row.document_ref}</b>
        </Link>
      </AsyncPreviewTooltip>
    ),
    id: 'order_ref',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.related_documents" />,
    accessorFn: row =>
      row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <AsyncPreviewTooltip
              id={related_document.model_id}
              key={`${related_document.model_type}-${related_document.model_id}-${row.id}`}
              type={related_document.model_type?.toLocaleUpperCase() as TYPE}
              column="ref"
            >
              <Link
                className="mb-1"
                to={guessUrl(related_document)}
                target="_blank"
              >
                <b className="text-nowrap">{related_document.model_ref}</b>
              </Link>
            </AsyncPreviewTooltip>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.date" />,
    enableSorting: true,
    id: 'order_date',
    accessorFn: row => <FormattedDate value={row.order_date} format="date" />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.model" />,
    enableSorting: true,
    id: 'document_sub_type',
    accessorFn: row => row.document_sub_type,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.category" />,
    enableSorting: true,
    id: 'document_category_name',
    accessorFn: row => <EmptyCell cell={row.document_category_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.ref_client" />,
    enableSorting: true,
    id: 'ref_client',
    accessorFn: row => <EmptyCell cell={row.ref_client} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.projet" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'project_name',
    accessorFn: row => <EmptyCell cell={row.project?.name} />,
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.contact" />,
    enableSorting: true,
    id: 'contact',
    accessorFn: row => <EmptyCell cell={row.user_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.total_ht" />,
    enableSorting: true,
    id: 'total_ht',
    accessorFn: row => (
      <span className="text-nowrap">
        <CostumeCurrency value={row.total_ht} symbol={row.currency_symbole} />
      </span>
    ),
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.total_ttc" />,
    enableSorting: true,
    id: 'total_ttc',
    accessorFn: row => (
      <span className="text-nowrap">
        <TtcColumn doc={row} />
      </span>
    ),
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.advances" />,
    enableSorting: true,
    id: 'total_deposit_payments',
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} column="deposits" type="ORDER">
        <Currency value={row.deposits} />
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.payments" />,
    enableSorting: true,
    id: 'total_payment',
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} column="payments" type="ORDER">
        <Currency value={row.payments} />
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.balance" />,
    enableSorting: true,
    id: 'solde_payment',
    accessorFn: row => <PayCell row={row} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.status" />,
    enableSorting: true,
    id: 'status',
    accessorFn: row => <StatusBadge status={row.status} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.transporter_name" />,
    enableSorting: true,
    id: 'transporter_name',
    accessorFn: row => <EmptyCell cell={row.transporter?.display_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.comment" />,
    enableSorting: true,
    id: 'comment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <TooltipCell cell={row?.private_comment ?? ''} id={row.id}></TooltipCell>
    ),
  },
];

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};

export default columns;
