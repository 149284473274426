import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  addProduct: () => __(translations.commercialDocumentComponents.addProduct, 'Add…'),
  amount: () => __(translations.commercialDocumentComponents.amount, 'Amount'),
  chooseProductToLink: () => __(translations.commercialDocumentComponents.chooseProductToLink, 'Choose product to link'),
  comment: () => __(translations.commercialDocumentComponents.comment, 'Private comment'),
  publicComment: () => __(translations.commercialDocumentComponents.publicComment, 'Public comment'),
  discount: () => __(translations.commercialDocumentComponents.discount, 'Discount (%)'),
  documentSummary: () => __(translations.commercialDocumentComponents.documentSummary, 'summary'),
  globalDiscount: () => __(translations.commercialDocumentComponents.globalDiscount, 'Global discount (%):'),
  importAddress: () => __(translations.commercialDocumentComponents.importAddress, 'Import address'),
  newAddress: () => __(translations.commercialDocumentComponents.newAddress, 'New address'),
  option: () => __(translations.commercialDocumentComponents.option, 'Option'),
  paymentMethod: () => __(translations.commercialDocumentComponents.paymentMethod, 'Payment Method:'),
  product: () => __(translations.commercialDocumentComponents.product, 'Product'),
  quantity: () => __(translations.commercialDocumentComponents.quantity, 'Quantity'),
  select: () => __(translations.commercialDocumentComponents.select, 'Select…'),
  subtitle: () => __(translations.commercialDocumentComponents.subtitle, 'Subtitle'),
  subTotal: () => __(translations.commercialDocumentComponents.subTotal, 'Sub-total'),
  title: () => __(translations.commercialDocumentComponents.title, 'Title'),
  totalExclTaxes: () => __(translations.commercialDocumentComponents.totalExclTaxes, 'Total (excl taxes):'),
  totalIncTaxes: () => __(translations.commercialDocumentComponents.totalIncTaxes, 'Total:'),
  unitPrice: () => __(translations.commercialDocumentComponents.unitPrice, 'Unit price'),
  vat: () => __(translations.commercialDocumentComponents.vat, 'VAT'),
  isCustomProduct: () => __(translations.commercialDocumentComponents.isCustomProduct, 'Is custom product'),
  managesStock: () => __(translations.commercialDocumentComponents.managesStock, 'Manages stock'),
  externalProduct: () => __(translations.commercialDocumentComponents.externalProduct, 'External product'),
  furnitureEcoTax: () => __(translations.commercialDocumentComponents.furnitureEcoTax, 'Total eco participation furniture'),
};
