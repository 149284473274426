import React, {FC} from 'react';
import {Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {CardBody, Row, Col, FormGroup} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: any;
  onChangeFilters: (key: string, value: any) => void;
}

const FamilyFilters: FC<FilterProps> = props => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {filters, onChangeFilters} = props;
  const handleUpdateFamily = () => {
    dispatch(showModal(MODAL.FAMILY_FORM));
  };

  const handleChangeFilters = (key: string, value: any) => {
    onChangeFilters(key, value);
  };

  return (
    <Fragment>
      <CardBody className="py-0">
        {/* First row */}
        <Row className="align-items-center">
          <Col lg="3" md="3">
            <FormGroup>
              <AsyncItemPicker
                endPoint="data/product-collections"
                className="mb-1"
                label={t(...messages.colections())}
                value={
                  filters ? filters['filter[product_collection_id]'] : null
                }
                onChange={o =>
                  handleChangeFilters('filter[product_collection_id]', o)
                }
                isMulti
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <AppBarActions>
              <Can action="administration" subject="products">
                <div
                  className="btn btn-important "
                  onClick={() => handleUpdateFamily()}
                >
                  <Icon name="plus" className="me-1" size={15} />
                  {t(...messages.addFamily())}
                </div>
              </Can>
            </AppBarActions>
          </Col>
        </Row>
        {/* <!-- end row --> */}
        {/* First row */}
      </CardBody>
    </Fragment>
  );
};

export default FamilyFilters;
