import {Invoice} from 'features/commercial/invoices/types/invoice';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeInvoice = api.injectEndpoints({
  endpoints: builder => ({
    storeInvoice: builder.mutation<Response, StoreInvoiceRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `invoices`,
          body: {
            directory_id: params.client?.id,
            document_category_id: params.category?.id,
            commercial_type_id: params.commercial_type?.id,
            project_id: params.project?.id,
            origin_id: params.origin?.id,
            language: params.language?.value,
            due_date: params.due_date,
            billing_date: params.billing_date,
            document_type: params.document_type,
            project_fee_type: params.projectFeesType?.value,
          },
        };
      },
    }),
  }),
});

export type StoreInvoiceRequest = {
  client?: Directory;
  category?: Option;
  commercial_type?: Option;
  origin?: Option;
  project?: Option;
  language?: any;
  due_date?: string;
  billing_date?: string;
  document_type: string;
  projectFeesType?: Option;
};

type Response = ResponseData<Invoice>;

export const {useStoreInvoiceMutation} = storeInvoice;
