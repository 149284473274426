import React from 'react';
import LoadRunner from 'components/load-runner';

const DocumentSkeleton = () => {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <LoadRunner width={'100%'} height={80} viewBox="0 0 100% 60">
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
          <rect x="0" y="40" rx="5" ry="5" width="85%" height="20" />
        </LoadRunner>
      </div>
    </div>
  );
};

export default DocumentSkeleton;
