import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Eye, Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import Button from 'components/button';
import {StockProduct} from 'features/stock/types/stock-products';
import {STATUS} from 'constants/status';
import {tableSlice} from 'features/table/slice';
import {uiActions} from 'store/ui/ui-slice';
import Can from 'features/hylian-shield/can';

interface Props {
  stockProduct: StockProduct;
}

const StockProductDisputSAVActions: FC<Props> = props => {
  const {stockProduct} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.UPDATE_STOCK_PRODUCT, {
        stockProduct: stockProduct,
        path: {
          url: 'stock/products-dispute-sav',
          name: 'stock-products-dispute-sav',
        },
      })
    );
  };

  const handleDetails = () => {
    dispatch(showModal(MODAL.DETAILS_STOCK_PRODUCT, {stockProduct}));
  };
  const destroyStockProduct = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: stockProduct.id,
        resourceName: 'stock-products-dispute-sav',
      })
    );
  };
  const handleDelete = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: stockProduct.id,
        name: 'stock-products-dispute-sav',
        onDestroy: destroyStockProduct,
        message: t(t('soulEater.deleteConfirmation')),
      })
    );
  };

  return (
    <div className="d-flex align-items-center text-nowrap h-100">
      <Can action="edit" subject="stock">
        <Button tag="button" className="me-2" onClick={handleEdit}>
          <Edit size={14} />
          <span className="align-middle ms-3">{t(...messages.edit())}</span>
        </Button>
      </Can>

      <Button onClick={handleDetails} color="important">
        <Eye size={14} />
      </Button>
      <Can action="edit" subject="stock">
        <Button onClick={handleDelete} className="ms-3" color="transparent">
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default StockProductDisputSAVActions;
