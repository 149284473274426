import React from 'react';
import columns from './columns';
import FamilyFilters from './family-filters';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';

const FamiliesPage = () => {
  const {t} = useTranslation();
  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t('common.products'), path: '/dashboard/products'},
            {title: t(...messages.families()), path: '#'},
          ]}
        />
        <Table
          columns={columns}
          hideSearch
          url="product-families"
          name="families"
          filtersComponent={FamilyFilters}
        />
      </Container>
    </div>
  );
};

export default FamiliesPage;
