import {ApiEndpointBuilder} from 'services/api/types';
import {ListResponse} from 'types/list-response';
import {Notification} from 'types/notification';

const getRecentNotificationsEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<ListResponse<Notification>, void>({
    query: () => ({
      method: 'GET',
      url: 'me/recent-notifications',
      params: {},
    }),
  });
};

export default getRecentNotificationsEndpoint;
