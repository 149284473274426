import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  okay: () => __(translations.stockMovementForm.okay, 'Okay'),
  title: () => __(translations.stockMovementForm.alertTitle, 'New Stock Movement'),
  movement_type: () => __(translations.stockMovementForm.movementType, 'Movement Type'),
  from: () => __(translations.stockMovementForm.from, 'From'),
  to: () => __(translations.stockMovementForm.to, 'To'),
  quantity: () => __(translations.stockMovementForm.quantity, 'Quantity'),
  comments: () => __(translations.stockMovementForm.comments, 'Comments'),
  addMovement: () => __(translations.stockMovementForm.addMovement, 'Add Movement'),
  moveIn: () => __(translations.stockMovementForm.moveIn, 'In'),
  moveOut: () => __(translations.stockMovementForm.moveOut, 'Out'),
  moveTransfer: () => __(translations.stockMovementForm.moveTransfer, 'Transfer'),
};
