import React, {Fragment, useContext} from 'react';
import AddPayment from '../../components/add-payment-drop-down/add-payment';
import PaymentItem from '../../components/payment-item/payment-item';
import selectPayments from '../../store/selectors/select-payments';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Button, Col, Row} from 'reactstrap';
import selectItems from '../../store/selectors/select-items';
import clsx from 'clsx';
import documentContext from '../document-context/document-context';
import Currency from 'components/currency/currency';
import {Link} from 'react-router-dom';
import DocumentIs from '../document-is/document-is';
import {useDispatch} from 'react-redux';
import addRefundPayment from '../../store/actions/add-refund-payment';
import {PaymentTypeEnum} from 'types/payment';
import {Option} from 'types/option';
import Can from 'features/hylian-shield/can';

const PaymentsSection = () => {
  const payments = useSelector(selectPayments);

  const {doc} = useContext(documentContext);

  const summary = doc?.payment_summary;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const hasProductsReactive = useSelector(selectItems)?.length > 0;

  const handleRefund = () =>
    dispatch(
      addRefundPayment(summary?.overpayment, {
        label: t('statusBadge.refund'),
        value: PaymentTypeEnum.REFUND,
      } as Option)
    );

  const isRefundDisabled = !!payments.find(
    el =>
      el.amount == summary?.overpayment &&
      el.type?.value === PaymentTypeEnum.REFUND
  );

  return (
    <div>
      <h6>{t(...messages.payments())}</h6>
      {payments.map((p, i) => (
        <PaymentItem key={p.id} payment={p} index={i} />
      ))}

      <Can
        subject={doc?.commercial_type?.code?.toLocaleLowerCase() as any}
        action="payments"
      >
        <div className="py-3">
          <AddPayment />
        </div>
      </Can>

      <div className={clsx({'d-none': !hasProductsReactive})}>
        <DocumentIs oneOf={['invoice']}>
          <Row className={clsx({'d-none': !hasProductsReactive})}>
            <Col>
              <p>
                {!!doc?.is_related_to_credit && (
                  <Fragment>
                    <b>{t(...messages.credits())}</b>{' '}
                    <Link
                      target="_blank"
                      to={`/dashboard/invoices/${doc.related_credit?.id}/details`}
                    >
                      {doc.related_credit?.document_ref}
                    </Link>
                  </Fragment>
                )}

                {!!doc?.is_related_to_invoice && (
                  <Fragment>
                    <b>{t(...messages.invoice())}</b>{' '}
                    <Link
                      target="_blank"
                      to={`/dashboard/invoices/${doc.related_invoice?.id}/details`}
                    >
                      {doc.related_invoice?.document_ref}
                    </Link>
                  </Fragment>
                )}
              </p>
            </Col>
          </Row>
        </DocumentIs>
        <Row
          className={`${clsx({
            'd-none': !summary?.has_overpayment,
          })} mb-2`}
        >
          <div className="d-flex justify-content-end">
            <p
              style={{width: '22%'}}
              className="d-flex justify-content-between"
            >
              <span>
                <b>{t(...messages.overpaid())} </b>
              </span>
              <span>
                <b>
                  <Currency value={summary?.overpayment} />
                </b>
              </span>
            </p>
          </div>
          <div>
            <Can
              subject={doc?.commercial_type?.code?.toLocaleLowerCase() as any}
              action="payments"
            >
              <Button
                color="light"
                disabled={isRefundDisabled}
                onClick={handleRefund}
              >
                {t(...messages.refunding())}
              </Button>
            </Can>
          </div>
        </Row>

        <Row className={clsx({'d-none': (summary?.sold ?? 0) <= 0})}>
          <div className="d-flex justify-content-end">
            <p
              style={{width: '35%'}}
              className="d-flex justify-content-between"
            >
              <span>
                <b>{t(...messages.totalDue())}</b>{' '}
              </span>
              <span>
                <b>
                  <Currency value={summary?.sold} />
                </b>
              </span>
            </p>
          </div>
        </Row>

        <Row className={clsx({'d-none': !summary?.payment_is_completed})}>
          <Col>
            <p>
              <b>{summary?.last_payment}</b>
            </p>
          </Col>
        </Row>
        <Row
          className={`${clsx({
            'd-none': !(summary && summary?.overpayment_usage?.length > 0),
          })} `}
        >
          {summary &&
            summary.overpayment_usage?.length > 0 &&
            summary.overpayment_usage.map((usage, i) => (
              <p key={i} className="d-flex w-100 justify-content-between">
                <span className="me-2">
                  <b>{usage.label} </b>
                </span>
                <span>
                  <b>
                    <Currency value={usage.amount} />
                  </b>
                </span>
              </p>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default PaymentsSection;
