import React, {FC, useState} from 'react';
import './info-section.styles.scss';
import GalleryView from 'components/gallery-view';
import SimpleBar from 'simplebar-react';
import {ProductVariant} from 'types/product-variant';

interface Props {
  variant: ProductVariant;
}

const SliderVariant: FC<Props> = props => {
  const {variant} = props;

  const imgs =
    variant?.attachements.filter(x => x.type?.value === 'IMAGE') ?? [];

  return (
    <React.Fragment>
      <div>
        <div className="simple-gallery-wrapper">
          <SimpleBar className="simple-bar-gallery-container">
            <GalleryView previewSize={96} images={imgs} onlyFirst={false} />
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SliderVariant;
