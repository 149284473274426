import React from 'react';
import CommercialTab from './commercial-tab/commercial-tab';
import FiscalInfoTab from './fiscal-info-tab/fiscal-info-tab';
import GeneralTab from './general-tab/general-tab';
import MailingTab from './mailing-tab/mailing-tab';
import messages from './messages';
import clsx from 'clsx';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Card, CardBody, Col, Container, Nav, NavItem, Row} from 'reactstrap';

const SettingsPage = () => {
  const {t} = useTranslation();
  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Row>
          <React.Fragment>
            <Row>
              <Col xl={2} lg={2}></Col>
              <Col xl={9} lg={8}>
                <Card>
                  <CardBody>
                    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                      <NavItem>
                        <NavLink
                          to="/dashboard/settings/general"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          {t(...messages.general())}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/dashboard/settings/mailing"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          {t(...messages.mailing())}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/dashboard/settings/commercial"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          {t(...messages.commercial())}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/dashboard/settings/fiscal"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          {t(...messages.fiscal())}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
                <Routes>
                  <Route path="/general" element={<GeneralTab />} />
                  <Route path="/fiscal" element={<FiscalInfoTab />} />
                  <Route path="/commercial" element={<CommercialTab />} />
                  <Route path="/mailing" element={<MailingTab />} />
                  <Route
                    path="/settings"
                    element={<Navigate to="/settings/general" />}
                  />
                </Routes>
                <Outlet />
              </Col>
            </Row>
          </React.Fragment>
        </Row>
      </Container>
    </div>
  );
};

export default SettingsPage;
