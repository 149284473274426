import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  actions: () => __(translations.invoiceActionTab.actions, 'Actions on invoice'),
  duplicateInvoiceTitle: () => __(translations.invoiceActionTab.duplicateInvoiceTitle, 'Duplicate invoice'),
  duplicateInvoiceDescription: () =>
    __(
      translations.invoiceActionTab.duplicateInvoiceDescription,
      'This is useful if you want to create another invoice that has the same items as the current one, and make some minor changes to it '
    ),
  duplicateAlertMessage: () => __(translations.invoiceActionTab.duplicateAlertMessage, 'Are you sure you want to duplicate this invoice?'),
  generateCreditTitle: () => __(translations.invoiceActionTab.generateCreditTitle, 'Generate credit'),
  generateCreditDescription: () => __(translations.invoiceActionTab.generateCreditDescription, 'This will generate a credit for this invoice'),
  generateCredit: () => __(translations.invoiceActionTab.generateCredit, 'Generate'),
  generateAccountingCreditTitle: () => __(translations.invoiceActionTab.generateAccountingCreditTitle, 'Generate accounting credit'),
  generateAccountingCreditDescription: () => __(translations.invoiceActionTab.generateAccountingCreditDescription, 'This will generate an accounting credit for this invoice'),
  generateAccountingCredit: () => __(translations.invoiceActionTab.generateAccountingCredit, 'Generate'),

  sendEmailTitle: () => __(translations.invoiceActionTab.sendEmailTitle, 'Send via e-mail'),
  sendEmailDetails: () => __(translations.invoiceActionTab.sendEmailDetails, 'This will send the invoice as email'),
  sendEmailInvoice: () => __(translations.invoiceActionTab.sendEmailInvoice, 'Send'),

  /// print part
  printEnglishVersion: () => __(translations.commercialDocumentComponents.printEnglishVersion, 'Print format (EN)'),
  printFrenchVersion: () => __(translations.commercialDocumentComponents.printFrenchVersion, 'Print format (FR)'),
  print: () => __(translations.commercialDocumentComponents.print, 'Print'),
  exportInvoiceFormatEN: () => __(translations.invoiceSummarySection.exportInvoiceFormatEN, 'Print invoice ( EN )'),
  exportInvoiceFormatFR: () => __(translations.invoiceSummarySection.exportInvoiceFormatFR, 'Print invoice ( FR )'),
  exportStockResume: () => __(translations.invoiceSummarySection.exportStockResume, 'Print stock resume'),
  exportPackageListFr: () => __(translations.invoiceSummarySection.exportPackageListFr, 'Print package list ( FR )'),
  exportPackageListEn: () => __(translations.invoiceSummarySection.exportPackageListEn, 'Print package list ( EN )'),
};
