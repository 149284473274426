import React, {FC} from 'react';
import AvatarSection from './avatar-section';
import messages from './messages';
import ProfileTab from './profile';
import SecurityTab from './security';
import SettingsTab from './settings';
import classnames from 'classnames';
import clsx from 'clsx';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Navigate, Outlet, Routes} from 'react-router';
import {Link, Route} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Container, Row} from 'reactstrap';
import {Card, CardBody, CardTitle, Col, Nav, NavItem} from 'reactstrap';
import {selectProfile, selectUser} from 'store/auth/selectors';

interface Props {}

const AccountPage: FC<Props> = () => {
  const {t} = useTranslation();

  const profile = useSelector(selectProfile);

  const user = useSelector(selectUser);
  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t(...messages.dashboard()), path: '/dashboard'},
            {title: t(...messages.pageTitle()), path: '#'},
          ]}
        />
        <Row>
          <React.Fragment>
            <Row>
              <Col xl={2} lg={2}></Col>
              <Col xl={9} lg={8}>
                <Card>
                  <CardBody>
                    <AvatarSection />

                    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                      <NavItem>
                        <NavLink
                          to="/account/profile"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          <Trans i18nKey="profileTab.title" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/account/security"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          <Trans i18nKey="securityTab.title" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="/account/settings"
                          className={active =>
                            clsx({active: active.isActive}, 'px-3 nav-link')
                          }
                        >
                          <Trans i18nKey="settingsTab.title" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardBody>
                </Card>
                <Routes>
                  <Route path="/profile" element={<ProfileTab />} />
                  <Route path="/security" element={<SecurityTab />} />
                  <Route path="/settings" element={<SettingsTab />} />
                  <Route
                    path="/account"
                    element={<Navigate to="/account/profile" />}
                  />
                </Routes>
                <Outlet />
              </Col>
              {/* <Col xl={3} lg={4}>
                  <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <h5 className="font-size-15">Skills</h5>
                    </CardTitle>

                    <div className="d-flex flex-wrap gap-2 font-size-16">
                      <Link to="#" className="badge badge-soft-primary">
                        Photoshop
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        illustrator
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        HTML
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        CSS
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        Javascript
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        Php
                      </Link>
                      <Link to="#" className="badge badge-soft-primary">
                        Python
                      </Link>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <h5 className="font-size-15">Portfolio</h5>
                    </CardTitle>

                    <div>
                      <ul className="list-unstyled mb-0">
                        <li>
                          <Link to="#" className="py-2 d-block text-muted">
                            <i className="mdi mdi-web text-primary me-1"></i>{' '}
                            Website
                          </Link>
                        </li>
                        <li>
                          <Link to="#" className="py-2 d-block text-muted">
                            <i className="mdi mdi-note-text-outline text-primary me-1"></i>{' '}
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      <h5 className="font-size-15">Similar Profiles</h5>
                    </CardTitle>

                    <div className="list-group list-group-flush">
                      <Link
                        to="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0 me-3">
                            <img
                              src={'avatar'}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14 mb-1">James Nix</h5>
                              <p className="font-size-13 text-muted mb-0">
                                Full Stack Developer
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0 me-3">
                            <img
                              src={'avatar'}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14 mb-1">
                                Darlene Smith
                              </h5>
                              <p className="font-size-13 text-muted mb-0">
                                UI/UX Designer
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="#"
                        className="list-group-item list-group-item-action"
                      >
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0 me-3">
                            <div className="avatar-title bg-soft-light text-light rounded-circle font-size-22">
                              <i className="bx bxs-user-circle"></i>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-14 mb-1">
                                William Swift
                              </h5>
                              <p className="font-size-13 text-muted mb-0">
                                Backend Developer
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </React.Fragment>
        </Row>
      </Container>
    </div>
  );
};

export default AccountPage;
