import React from 'react';
import Icon from '@ailibs/feather-react-ts';
import {useDispatch, useSelector} from 'react-redux';
import {layoutActions} from 'store/layout/layout-slice';
import {selectAppTheme} from 'store/layout/selectors';

const LightDark = () => {
  const dispatch = useDispatch();

  const theme = useSelector(selectAppTheme);

  const toggle = () => {
    dispatch(
      layoutActions.changeLayoutTheme(theme === 'dark' ? 'light' : 'dark')
    );
  };
  return (
    <div className="dropdown d-none d-sm-inline-block">
      <button onClick={toggle} type="button" className="btn header-item">
        {theme === 'dark' ? (
          <Icon name="sun" className="icon-lg layout-mode-light" />
        ) : (
          <Icon name="moon" className="icon-lg layout-mode-dark" />
        )}
      </button>
    </div>
  );
};

export default LightDark;
