import React, {FC} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import getVariantName from 'utils/row-getters/get-variant-name';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';

interface Props {
  customization?: any;
  index?: number;
  removeCustomization?: any;
  updateCustomization?: any;
  validationErrors?: any;
}

const VariantOption: FC<Props> = props => {
  const {
    customization,
    index,
    validationErrors,
    updateCustomization,
    removeCustomization,
  } = props;
  const {t} = useTranslation();

  const costumOptions = [{id: 'variant', name: t(...messages.tissue())}];

  return (
    <Row className="mb-3">
      <Col>
        <ItemPicker
          label={t(...messages.option())}
          options={costumOptions}
          value={costumOptions[0]}
          onChange={o => {}}
          isDisabled
        />
      </Col>

      <Col>
        <AsyncItemPicker
          label={t(...messages.subOption())}
          endPoint={`data/tissues`}
          getOptionLabel={getVariantName}
          errors={
            validationErrors[`customizations.${index}.product_variant_id`]
          }
          value={customization.product_variant}
          onChange={x =>
            updateCustomization(customization, 'product_variant', x)
          }
        />
      </Col>
      <Col md="auto">
        <EmptyLabel />
        <Button
          color=""
          className="p-1"
          onClick={() => removeCustomization(customization.id)}
        >
          <Icon name="trash-2" size={16} className="text-danger me-1" />
        </Button>
      </Col>
    </Row>
  );
};

export default VariantOption;
