import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoicesPage.title, 'Invoices'),
  add: () => __(translations.invoicesPage.add, 'Add'),
  addInvoice: () => __(translations.invoicesPage.addInvoice, 'Add invoice'),
  addCredit: () => __(translations.invoicesPage.addCredit, 'Add credit'),
  exports: () => __(translations.invoicesPage.export, 'Exports'),
  exportInvoices: () => __(translations.invoicesPage.exportInvoices, 'Export invoices'),
  exportTable: () => __(translations.invoicesPage.exportTable, 'Export table'),
  invoiceType: () => __(translations.invoicesPage.invoiceType, 'Model'),
  credit: () => __(translations.invoicesPage.credit, 'Credit'),
  invoice: () => __(translations.invoicesPage.invoice, 'Invoice'),
  user: () => __(translations.invoicesPage.user, 'Contact'),
  date: () => __(translations.invoicesPage.date, 'Date'),
  status: () => __(translations.invoicesPage.status, 'Status'),
  subTotalHt: () => __(translations.invoicesPage.subTotalHt, 'Sub Total'),
  totalHt: () => __(translations.invoicesPage.totalHt, 'Total HT'),
  totalTtc: () => __(translations.invoicesPage.totalTtc, 'Total TTC'),
  invoiceName: () => __(translations.invoicesPage.name, 'Number'),
  type: () => __(translations.invoicesPage.type, 'Type'),
  client: () => __(translations.invoicesPage.client, 'Client'),
  category: () => __(translations.invoicesPage.category, 'Category'),
  deliveryDate: () => __(translations.invoicesPage.deliveryDate, 'Delivery date'),
  dueDate: () => __(translations.invoicesPage.dueDate, 'Due date'),
  billingDate: () => __(translations.invoicesPage.billingDate, 'Billing date'),
  payementDate: () => __(translations.invoicesPage.payementDate, 'Payment date'),
  balance: () => __(translations.invoicesPage.balance, 'Balance'),
  deposits: () => __(translations.invoicesPage.deposits, 'Deposits'),
  payments: () => __(translations.invoicesPage.payments, 'Payments'),
  invoiceDate: () => __(translations.invoicesPage.invoiceDate, 'Invoice date'),
  vatMode: () => __(translations.invoicesPage.vatMode, 'VAT mode'),
  refClient: () => __(translations.invoicesPage.refClient, 'Ref client'),
  totalInvoicesHT: () => __(translations.invoicesPage.totalInvoicesHT, 'Total invoices HT'),
  totalInvoicesTTC: () => __(translations.invoicesPage.totalInvoicesTTC, 'Total invoices TTC'),
  totalDeposit: () => __(translations.invoicesPage.totalDeposit, 'Total deposit'),
  totalPayments: () => __(translations.invoicesPage.totalPayments, 'Total payments'),
  totalPay: () => __(translations.invoicesPage.totalPay, 'Total pay'),
  totalCredit: () => __(translations.invoicesPage.totalCredit, 'Total credit'),
  totalOverpayment: () => __(translations.invoicesPage.totalOverpayment, 'Total overpayment'),
  totalBenefit: () => __(translations.invoicesPage.totalBenefit, 'Total benefit'),
  totalBenefitPerYear: () => __(translations.invoicesPage.totalBenefitPerYear, 'Total benefit per year'),
  totalBenefitPerMonth: () => __(translations.invoicesPage.totalBenefitPerMonth, 'Total benefit per month'),
  month: () => __(translations.invoicesPage.month, 'Month'),
  previousMonth: () => __(translations.invoicesPage.previousMonth, 'Month-1'),
  vintage: () => __(translations.invoicesPage.vintage, 'Vintage product'),
  unpaid: () => __(translations.invoicesPage.unpaid, 'Unpaid'),
  productionStatedDate: () => __(translations.invoicesPage.productionStatedDate, 'Production stated date'),
  documentCategory: () => __(translations.invoicesPage.documentCategory, 'Document category'),
  thresholdDeposit: () => __(translations.invoicesPage.thresholdDeposit, 'Threshold deposit'),
  thresholdPay: () => __(translations.invoicesPage.thresholdSolde, 'Threshold pay'),
  year: () => __(translations.invoicesPage.year, 'Year'),
  previousYear: () => __(translations.invoicesPage.previousYear, 'Year-1'),
  projet: () => __(translations.invoicesPage.projet, 'Project'),
  isAfterSaleService: () => __(translations.invoicesPage.isAfterSaleService, 'After-sales service invoice'),
  referalContact: () => __(translations.invoicesPage.referalContact, 'Referal contact'),
};
