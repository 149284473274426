import {FilterMap} from '../types';

export default (params: URLSearchParams) => {
  const filters: FilterMap = {};

  params.forEach((value, key) => {
    const isArray = value.includes(',');
    let parsedValue;
    if (isArray) {
      parsedValue = value.split(',').filter(x => x.length > 0);
    } else {
      parsedValue = value;
    }
    if (parsedValue) {
      filters[key] = parsedValue;
    }
  });
  return filters;
};
