import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {CommercialProductType} from 'types/commercial-product-type';

export default createAction(
  actions.addItem.type,
  function prepare(at: number, type: CommercialProductType) {
    return {
      payload: {
        at,
        type,
      },
    };
  }
);
