import {accountApi} from '../endpoints';
import {AccountState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';
import {WritableDraft} from 'types/writable-draft';

const toggleNewsletterMatcher =
  accountApi.endpoints.toggleNewsLetter.matchFulfilled;

/**
 * Toggle newsletter on or off
 *
 */
const toggleNewsletterReducer = (
  state: WritableDraft<AccountState>,
  action: PayloadAction<ResponseData<User>>
) => {
  state.newsletterEnabled = action.payload.data.newsletter_status;
};

export const toggleNewsletter = {
  matcher: toggleNewsletterMatcher,
  reducer: toggleNewsletterReducer,
};
