import React from 'react';
import App from './app/app';
import './index.css';
import './locales/i18n';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import configureAppStore from 'store/configure-app-store';
import {ErrorBoundary} from 'react-error-boundary';
import fallbackRenderer from 'components/error-fallback';

const getId = document.getElementById('root') as HTMLDivElement;
const root = ReactDOM.createRoot(getId);

const store = configureAppStore();
store.runSaga();

root.render(
  <Provider store={store}>
    <ErrorBoundary fallbackRender={fallbackRenderer}>
      <App />
    </ErrorBoundary>
  </Provider>
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
