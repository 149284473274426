import React from 'react';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {CompactStockOrder} from 'features/commercial/orders/types/order';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import TtcColumn from 'features/commercial/documents/components/ttc-column/ttc-column';
import Currency from 'components/currency/currency';
import RelatedDocument from 'types/related-document';
import TooltipCell from 'components/tooltip-cell';
import {TYPE} from 'components/async-preview-tooltip/constant';

const columns: ColumnDef<CompactStockOrder>[] = [
  {
    header: r => <Trans i18nKey="stockOrderPage.contremark" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.client_id}/general`}
        target="_blank"
      >
        <b>{row.client_name}</b>
      </Link>
    ),
    id: 'client_name',
    cell: r => r.getValue(),
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="stockOrderPage.ref" />,
    accessorFn: row => (
      <AsyncPreviewTooltip type="ORDER" column="ref" id={row.id}>
        <Link target="_blank" to={`/dashboard/orders/${row.id}/details`}>
          <b>{row.document_ref}</b>
        </Link>
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
    meta: {size: '100px'},
    id: 'order_ref',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="quotationsPage.related_documents" />,
    accessorFn: row =>
      row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <AsyncPreviewTooltip
              id={related_document.model_id}
              key={`${related_document.model_type}-${related_document.model_id}`}
              type={related_document.model_type?.toLocaleUpperCase() as TYPE}
              column="ref"
            >
              <Link
                className="mb-1"
                key={`${related_document.model_type}-${related_document.model_id}`}
                to={guessUrl(related_document)}
                target="_blank"
              >
                <b>{related_document.model_ref}</b>
              </Link>
            </AsyncPreviewTooltip>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="stockOrderPage.orderDate" />,
    enableSorting: true,
    id: 'order_date',
    accessorFn: row => <FormattedDate value={row.order_date} format="date" />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="stockOrderPage.stockOrderTotalCost" />,
    enableSorting: true,
    id: 'total_ht',
    accessorFn: row => <Currency value={row.total_costs} />,
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockOrderPage.status" />,
    enableSorting: true,
    id: 'status',
    accessorFn: row => <StatusBadge status={row.status} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="stockOrderPage.contact" />,
    enableSorting: true,
    id: 'contact',
    accessorFn: row => <EmptyCell cell={row.user_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.transporter_name" />,
    enableSorting: true,
    id: 'transporter_name',
    accessorFn: row => <EmptyCell cell={row.transporter?.display_name} />,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.comment" />,
    enableSorting: true,
    id: 'comment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <TooltipCell cell={row?.private_comment ?? ''} id={row.id}></TooltipCell>
    ),
  },
];

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};

export default columns;
