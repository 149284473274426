import React from 'react';
import DirectoryActions from './directory-actions';
import Icon from '@ailibs/feather-react-ts';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import GenerateStars from 'components/generate-stars/generate-stars';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Directory} from 'types/directory';

const companiesColumns: ColumnDef<Directory>[] = [
  {
    header: r => <Trans i18nKey="directoryPage.id" />,
    accessorFn: r => r?.id,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'id',
    meta: {size: '70px', costumClass: 'font-size-14'},
  },

  {
    header: r => <Trans i18nKey="directoryPage.company" />,
    accessorFn: row =>
      row.is_archived ? (
        <EmptyCell cell={row.display_name} />
      ) : (
        <Link target="_blank" to={`/dashboard/directories/${row.id}/general`}>
          <Icon name="eye" size={15} className="me-1 " />

          <GenerateStars starsNumber={row.stars_number} />
          <b>{row.display_name.toLocaleUpperCase()}</b>
        </Link>
      ),
    cell: r => r.getValue(),
    id: 'company-name',
    meta: {size: '200px', costumClass: 'font-size-14'},
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="directoryPage.category" />,
    accessorFn: row => (
      <EmptyCell cell={row.directory_categories?.map(x => x.name).join(',')} />
    ),
    cell: r => r.getValue(),
    id: 'category',
    meta: {size: '200px', costumClass: 'font-size-14'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.family" />,
    accessorFn: row => (
      <EmptyCell cell={row.directory_families?.map(x => x.name).join(',')} />
    ),
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},

    id: 'families',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.email" />,
    accessorFn: row => (
      <EmptyCell
        cell={
          row?.main_directory_email?.email ? (
            <a href={`mailto:${row.main_directory_email?.email}`}>
              <b> {row.main_directory_email?.email}</b>
            </a>
          ) : null
        }
      />
    ),
    cell: r => r.getValue(),
    id: 'email',
    meta: {size: '200px', costumClass: 'font-size-14'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="directoryPage.phone" />,
    accessorFn: row => <EmptyCell cell={row.main_phone?.number} />,
    cell: r => r.getValue(),
    id: 'phone',
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="directoryPage.action" />,
    enableSorting: false,
    accessorFn: row => <DirectoryActions directory={row} />,
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    id: 'action',
  },
];

export default companiesColumns;
