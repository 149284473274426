import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {IManDay, Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectManDays = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectManDays: builder.mutation<Response, StoreProjectManDaysRequest>(
      {
        query: (body: StoreProjectManDaysRequest) => {
          return {
            method: 'POST',
            url: `projects/${body.id}/man-days-crud`,
            body,
          };
        },
        // @ts-expect-error
        invalidatesTags: result => {
          if (result) {
            return [{type: 'projects', id: result?.data.id}];
          }
          return [];
        },
      }
    ),
  }),
});

export interface IManDayRequest extends IManDay {
  invoice_id?: ObjectId;
}

export type StoreProjectManDaysRequest = {
  id: string | undefined;
  mandays: IManDayRequest[];
  mandays_global_comment: string;
  deleted_records: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectManDaysMutation} = storeProjectManDays;
