import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getDirectoryStats = api.injectEndpoints({
  endpoints: builder => ({
    getDirectoryStats: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `directories/${params.id}/stats`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<
  {
    year: number;
    total_ht: number;
    total_ttc: number;
    product_count: string;
  }[]
>;

export const {useGetDirectoryStatsQuery} = getDirectoryStats;
