import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.mailingSettingsTab.title, 'Mailing'),
  changesCommited: () => __(translations.mailingSettingsTab.changesCommited, 'All changes were saved!'),
  smallObject: () => __(translations.mailingSettingsTab.smallObject, 'Small object'),
  addressLine1: () => __(translations.mailingSettingsTab.adressLine1, 'Adress line 1'),
  addressLine2: () => __(translations.mailingSettingsTab.adressLine2, 'Adress line 2'),
  email: () => __(translations.mailingSettingsTab.email, 'Email'),
  phone: () => __(translations.mailingSettingsTab.phone, 'Phone number'),
  studio: () => __(translations.mailingSettingsTab.studio, 'Studio'),
  showroom: () => __(translations.mailingSettingsTab.showroom, 'Showroom'),
  labelsMailContent: () => __(translations.mailingSettingsTab.labelsMailContent, 'Labels mail content'),
  orderMailContent: () => __(translations.mailingSettingsTab.orderMailContent, 'Order mail content'),
  orderLabelsMail: () => __(translations.mailingSettingsTab.orderLabelsMail, 'Order labels mail content'),
};
