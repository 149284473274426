import {api} from 'services/api';
import {DirectoryFamily} from 'types/directory-family';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeDirectoryFamily = api.injectEndpoints({
  endpoints: builder => ({
    storeDirectoryFamily: builder.mutation<
      Response,
      StoreDirectoryFamilyRequest
    >({
      query: params => {
        return {
          method: 'POST',
          url: `directory-families`,
          body: {
            comment: params.comment,
            name: params.name,
            categories: params.categories?.map(x => x.id),
          },
        };
      },
    }),
  }),
});

export type StoreDirectoryFamilyRequest = {
  name?: string;
  comment?: string;
  categories?: Option[];
};

type Response = ResponseData<DirectoryFamily>;

export const {useStoreDirectoryFamilyMutation} = storeDirectoryFamily;
