import {uiActions} from '../ui-slice';
import {MODAL} from 'features/modal-notch/constants';
import {ObjectId} from 'types/object-id';

export default (directory_id?: Payload, hideDefault?: boolean) => {
  return uiActions.showModal({
    modalType: MODAL.ADDRESS_FORM,
    params: {
      directory: directory_id,
      hideDefault,
    },
  });
};

type Payload = ObjectId;
