import {api} from 'services/api';
import {Material} from 'types/materials';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const archiveMaterial = api.injectEndpoints({
  endpoints: builder => ({
    archiveMaterial: builder.mutation<Response, ArchiveMaterialRequest>({
      query: params => {
        return {
          method: 'DELETE',
          url: `materials/${params.id}`,
        };
      },
    }),
  }),
});

export type ArchiveMaterialRequest = {
  id: ObjectId;
};

type Response = ResponseData<Material>;

export const {useArchiveMaterialMutation} = archiveMaterial;
