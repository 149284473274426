import React, {FC} from 'react';
import Table from 'features/table';
import {useParams} from 'react-router';
import {columns} from './columns';

const SerialNumberTab: FC = () => {
  const {variant} = useParams();

  return (
    <Table
      hideFilters
      url={`product-variants/${variant}/serial-numbers`}
      name="product-serial-numbers"
      columns={columns}
    />
  );
};

export default SerialNumberTab;
