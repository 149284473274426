import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  projectName: () => __(translations.orderSummarySection.projectName, 'Project'),
  date: () => __(translations.orderSummarySection.date, 'Date'),
  client: () => __(translations.orderSummarySection.client, 'Client'),
  supplier: () => __(translations.orderSummarySection.supplier, 'Supplier'),
  contact: () => __(translations.orderSummarySection.contact, 'Contact'),
  clientFamily: () => __(translations.orderSummarySection.clientFamily, 'Family'),
  totalPurchaseCostsExcl: () => __(translations.orderSummarySection.totalPurchaseCostsExcl, 'Total purchase costs excl'),
  totalSellingPriceExcl: () => __(translations.orderSummarySection.totalSellingPriceExcl, ' Total selling price excl '),
  marginExcl: () => __(translations.orderSummarySection.marginExcl, 'Margin excl'),
  transporter: () => __(translations.orderSummarySection.transporter, ' Transporter'),
  transparterPrice: () => __(translations.orderSummarySection.transparterPrice, 'price '),
  status: () => __(translations.orderSummarySection.status, 'Status '),
  moreInformations: () => __(translations.orderSummarySection.moreInformations, 'More informations'),
};
