import {CostField} from '../types';
import {createAction} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';

export default createAction(
  'costs/updateCost',
  function prepare(
    id: ObjectId,
    categoryId: ObjectId,
    field: CostField,
    value: string
  ) {
    return {
      payload: {
        id,
        categoryId,
        field,
        value,
      },
    };
  }
);
