import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  overPayment: () => __(translations.paymentPickerModal.overpayment, 'Overpayment'),
  credit: () => __(translations.paymentPickerModal.credit, 'Credit'),
  selectACredit: () => __(translations.paymentPickerModal.selectACredit, 'Select a credit'),
  selectAnInvoice: () => __(translations.paymentPickerModal.selectAnInvoice, 'Select an invoice'),
  amountToUse: () => __(translations.paymentPickerModal.amountToUse, 'Amount to use'),
  fillRequiredInputs: () => __(translations.paymentPickerModal.fillRequiredInputs, 'Please, fill the required fiels.'),
};
