import React from 'react';
import EmptyStateIllustration from 'assets/images/empty-state.svg';
import {useTranslation} from 'react-i18next';

const NoProductSelected = () => {
  const {t} = useTranslation();
  return (
    <div className="py-4">
      <div className="justify-content-center mb-3">
        <div className="justify-content-center d-flex mb-2">
          <img className="img-fluid" src={EmptyStateIllustration} width={120} />
          <div style={{width: 120}} />
        </div>
        <div className="text-center text-muted">
          {t('galleryView.noRecordsWereFound')}
        </div>
      </div>
    </div>
  );
};

export default NoProductSelected;
