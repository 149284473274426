import {isWithinInterval, parseISO} from 'date-fns';

/**
 * Checks if date is inside a range
 * Example: const dateArgument = "2024-01-17T12:00:00.000Z"; const dateDebutPlage = "2024-01-01T00:00:00.000Z"; const dateFinPlage = "2024-02-01T00:00:00.000Z"; const result = estDansPlage(dateArgument, dateDebutPlage, dateFinPlage); console.log(result); // Renvoie true ou false en fonction de la vérification
 */
export default (arg1: Date, dateDebut: string, dateFin: string) => {
  if (!dateDebut || !dateFin) {
    return false;
  }
  const debutPlage = parseISO(dateDebut);
  const finPlage = parseISO(dateFin);
  const estDansPlage = isWithinInterval(arg1, {
    start: debutPlage,
    end: finPlage,
  });
  return estDansPlage;
};
