import selectDecoratorPrice from './select-decorator-price';
import selectPurchasePrice from './select-purchase-price';
import {createSelector} from '@reduxjs/toolkit';

export default createSelector(
  [selectDecoratorPrice, selectPurchasePrice],
  (decoratorPrice, purchasePrice) => {
    return (decoratorPrice / Math.max(1, purchasePrice)).toFixed(2);
  }
);
