import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {
  UpdateDirectoryComplementaryInfoFromRequest,
  useUpdateDirectoryComplementaryInfoFromMutation,
} from 'store/directory/endpoints/update-directory-complementairy-info';
import toast from 'store/ui/actions/toast';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [send, {isLoading, error, isSuccess}] =
    useUpdateDirectoryComplementaryInfoFromMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const [params, setParams] = useState<Params>({
    id: id as string,
    legal_form: undefined,
    siret: '',
    ape: '',
    vat_number: '',
    vat_type: undefined,
    iban: '',
    bic_swift: '',
  });
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await send(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  const {data} = useSelector(selectDirectory({id}));
  const directory = data?.data;
  useEffect(() => {
    if (directory) {
      setParams({
        ...params,
        legal_form: directory?.directory_additional_data?.legal_form,
        siret: directory?.directory_additional_data?.siret,
        ape: directory?.directory_additional_data?.ape,
        vat_number: directory?.directory_additional_data?.vat_number,
        vat_type: directory?.directory_additional_data?.vat_type,
        iban: directory?.directory_additional_data?.iban,
        bic_swift: directory?.directory_additional_data?.bic_swift,
      });
    }
  }, [directory]);

  return {
    setParams,
    params,
    directory,
    isSuccess,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    canEdit,
  };
};

type Params = UpdateDirectoryComplementaryInfoFromRequest;
