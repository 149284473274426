import {CreditStatusType} from 'features/acounting/credits/types/credit-status-type';
import messagesStatuses from 'components/status-badge/messages';
import messages from '../messages';
export default [
  {
    tab: 'AVAILABLE',
    title: messagesStatuses.available,
  },
  {
    tab: 'USED',
    title: messagesStatuses.used,
  },
  {
    tab: 'REFUND',
    title: messagesStatuses.refunded,
  },
  {
    tab: 'ARCHIVED',
    title: messagesStatuses.archived,
  },
  {
    tab: 'ACCOUNTING',
    title: messages.creditsListArchived,
  },
] as {
  tab: CreditStatusType;
  title: () => [string, ...any[]];
}[];
