import {useState} from 'react';
import {addMonths} from 'date-fns';
import {
  useStoreOrderMutation,
  StoreOrderRequest,
} from 'features/commercial/orders/store/endpoints/store-order';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {now} from 'utils/now';
import realDate from 'utils/real-date';
import {useSeeder} from 'utils/use-seeder';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSupplierOrdersForm = () => {
  const [storeCustomerOrder, {isLoading, error}] = useStoreOrderMutation();

  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeCustomerOrder(params);
    if ('data' in result) {
      const supplier_order = result?.data.data;
      navigate(`/dashboard/orders/${supplier_order.id}/details`);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const [params, setParams] = useState<Params>({
    client: undefined,
    document_type: {
      id: 1,
      value: 'SUPPLIER',
    },
    category: undefined,
    type: undefined,
    supplier: undefined,
    origin: undefined,
    language: undefined,
    order_date: now(),
    due_date: realDate(addMonths(new Date(), 1)),
  });

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreOrderRequest;
