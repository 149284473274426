import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import queryParams from 'utils/query-params';

const getPatrimoine = api.injectEndpoints({
  endpoints: builder => ({
    getPatrimoine: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `export/pdf/product-patrimony`,
          params: queryParams(params, '|'),
        };
      },
    }),
  }),
});

type Request = {};

type Response = ResponseData<{}>;

export const {useLazyGetPatrimoineQuery} = getPatrimoine;
