import {Order} from '../../types/order';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';
import {PartialEditOrderTaxesRequest} from './edit-order-taxes';
import {updateCachedOrderFromResource} from './show-order';

const editOrder = api.injectEndpoints({
  endpoints: builder => ({
    editOrder: builder.mutation<Response, EditOrderRequest>({
      query: params => {
        const body: Partial<PartialEditOrderTaxesRequest> = {};

        let taxMode: Order['tax_mode'] = 'NO-VAT-NO-DETAX';

        if (!params.with_vat && params.is_detaxed) {
          taxMode = 'DETAXED';
        }
        if (params.with_vat && !params.is_detaxed) {
          taxMode = 'NO-VAT';
        }

        if (taxMode === 'DETAXED' || taxMode === 'NO-VAT-NO-DETAX') {
          body.vat_number = params.vat_number;
          body.vat_mode = params.vat_mode?.value;
        }

        return {
          method: 'PUT',
          url: `orders/${params.id}`,
          body: {
            order_type: params.order_type,
            order_date: params?.order_date,
            due_date: params?.due_date,
            order_model_id: params.order?.id,
            commercial_type_id: params.commercial_type?.id,
            document_category_id: params.document_category?.id,
            document_sub_type: params.document_sub_type?.value,
            project_id: params.project?.id,
            origin_id: params.origin?.id,
            directory_id: params?.client?.id,
            language: params.language?.value,
            currency: params.currency?.value,
            ref_client: params?.ref_client,
            is_after_sale_service: params.is_after_sale_service,
            deadline_announced_fr: params.deadline_announced_fr,
            deadline_announced_en: params.deadline_announced_en,
            private_comment: params.private_comment,
            currency_conversion: params.currency_conversion,
            project_fee_type: params.projectFeesType?.value,
            designation: params.designation,

            // vat
            ...body,
            tax_mode: taxMode,

            // production section
            production_requested_deposit: params.production_requested_deposit,
            production_declared_completion_date:
              params.production_declared_completion_date,
            estimated_supplier_time: params.estimated_supplier_time,
            delivery_date: params.delivery_date,
            production_comments: params.production_comments,
            supplier_reference: params.supplier_reference,
            hide_delay: params.hide_delay,
            // transporter
            transporter_id: params.transporter?.id,
            transporter_name: params.transporter?.display_name,
            transporter_price: params.transporter_price,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

export type EditOrderRequest = {
  id?: ObjectId;
  client?: Directory;
  order_type?: any;
  document_type?: string;
  announced_deadline?: string;
  order_model?: Option;
  client_display_name?: string;
  origin?: Option;
  order?: Option;
  user?: Option;
  language?: Option;
  project?: Option;
  ref_client?: string;
  private_comment?: string;
  order_date?: string;
  due_date?: string;
  currency?: Option;
  document_category?: Option;
  document_sub_type?: Option;
  commercial_type?: Option;
  supplier_name?: string;
  deadline_announced_fr?: string;
  deadline_announced_en?: string;
  is_after_sale_service?: boolean;
  currency_conversion?: string;
  projectFeesType?: Option;
  designation?: string;

  // taxes and vat
  vat_number?: string;
  vat_mode?: Option;
  is_detaxed?: boolean;
  with_vat?: boolean;

  // production
  production_requested_deposit?: string | number;
  production_declared_completion_date?: string;
  estimated_supplier_time?: string;
  delivery_date?: string;
  production_comments?: string;
  supplier_reference?: string;

  hide_delay?: boolean;
  // transporter
  transporter?: Option;
  transporter_price?: string;
};

type Response = ResponseData<Order>;

export const {useEditOrderMutation} = editOrder;
