import React from 'react';
import General from '../sections/general';
import Contacts from '../sections/contacts';
import Team from '../sections/team';
import Phases from '../sections/phases';
import Attachments from '../sections/attachments';
import ManDays from '../sections/man-days';
import SourcingFees from '../sections/sourcing-fees';
import Commissions from '../sections/commissions';
import Vintage from '../sections/vintage/index';
import SellFFE from '../sections/sells-ffe';
import Expenses from '../sections/expenses';
import Conclusion from '../sections/conclusion';
import {Action} from 'features/hylian-shield/types';

export const routes: RouteType[] = [
  {
    path: '/general',
    element: <General />,
    action: ['administration', 'show'],
  },
  {
    path: '/contacts',
    element: <Contacts />,
    action: ['administration', 'show'],
  },
  {
    path: '/team',
    element: <Team />,
    action: ['administration', 'show'],
  },
  {
    path: '/phases',
    element: <Phases />,
    action: ['invoicing'],
  },
  {
    path: '/attachments',
    element: <Attachments />,
    action: ['administration', 'show'],
  },
  {
    path: '/expenses',
    element: <Expenses />,
    action: ['administration', 'show'],
  },
  {
    path: '/man-days',
    element: <ManDays />,
    action: ['invoicing'],
  },
  {
    path: '/commissions',
    element: <Commissions />,
    action: ['invoicing'],
  },
  {
    path: '/sourcing-fees',
    element: <SourcingFees />,
    action: ['invoicing'],
  },
  {
    path: '/sells-ffe',
    element: <SellFFE />,
    action: ['administration', 'show'],
  },
  {
    path: '/vintage',
    element: <Vintage />,
    action: ['administration', 'show'],
  },
  {
    path: '/conclusion',
    element: <Conclusion />,
    action: ['invoicing'],
  },
];

type RouteType = {
  path: string;
  element: JSX.Element;
  action: Action | Action[];
};
