import React, {FC, Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import ItemOtherInfoForm from './item-other-info-form';
import OtherParamsContext from './other-params-context';
import useOtherParamsForm from './use-other-params-form';

interface Props {
  onDismiss: () => void;
}

const ItemOptionsModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {params, setParams, submit} = useOtherParamsForm();

  const {item, index, doc, doc_type} = useSelector(selectActiveModalParams);

  return (
    <Fragment>
      <OtherParamsContext.Provider
        value={{params, submit, setParams, doc, doc_type}}
      >
        <ModalHeader className="bg-primary" toggle={onDismiss}>
          <span className="text-light">
            <Trans i18nKey={t(...messages.title())} />
          </span>
        </ModalHeader>

        <ModalBody className="">
          <ItemOtherInfoForm item={item} index={index} />
        </ModalBody>
        <ModalFooter>
          <Button color="important" onClick={submit}>
            {t('common.saveChanges')}
          </Button>
          <Button color="secondary" onClick={onDismiss}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </OtherParamsContext.Provider>
    </Fragment>
  );
};

export default ItemOptionsModal;
