import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  addCost: () => __(translations.costsTab.addCost, 'Add cost'),
  addSituation: () => __(translations.costsTab.addSituation, 'Add situation'),
  coeMargin: () => __(translations.costsTab.coeMargin, 'Coe-marg'),
  components: () => __(translations.costsTab.components, 'Components'),
  date: () => __(translations.costsTab.date, 'Date'),
  decoratorSubtotal: () => __(translations.costsTab.decoratorSubtotal, 'Decorator Sub-total'),
  decoratorTotal: () => __(translations.costsTab.decoratorTotal, 'Decorator total'),
  development: () => __(translations.costsTab.development, 'Development'),
  discount: () => __(translations.costsTab.discount, 'Discount'),
  logistic: () => __(translations.costsTab.logistic, 'Logistic'),
  margin: () => __(translations.costsTab.margin, 'Margin'),
  name: () => __(translations.costsTab.name, 'Name'),
  other: () => __(translations.costsTab.other, 'Other'),
  publicPriceSubtotal: () => __(translations.costsTab.publicPriceSubtotal, 'Public price subtotal'),
  publicPriceTotal: () => __(translations.costsTab.publicPriceTotal, 'Public price Total'),
  purchaseCostsSubtotal: () => __(translations.costsTab.purchaseCostsSubtotal, 'Purchase costs subtotal'),
  purchaseCostsTotal: () => __(translations.costsTab.purchaseCostsTotal, 'Purchase costs total'),
  resellerSubtotal: () => __(translations.costsTab.resellerSubtotal, 'Reseller Sub-total'),
  resellerTotal: () => __(translations.costsTab.resellerTotal, 'Reseller total'),
  situation: () => __(translations.costsTab.situation, 'Situation'),
  tissue: () => __(translations.costsTab.tissue, 'Tissue'),
  title: () => __(translations.costsTab.title, 'Costs of {{name}}'),
  totalCosts: () => __(translations.costsTab.totalCosts, 'Total costs'),
  vat: () => __(translations.costsTab.vat, 'VAT'),
  type: () => __(translations.costsTab.type, 'Type'),
  supplier: () => __(translations.costsTab.supplier, 'Supplier'),
  costPerUnit: () => __(translations.costsTab.costPerUnit, 'Cost per unit'),
  totalCost: () => __(translations.costsTab.totalCost, 'Total Cost'),
  deprecatedOn: () => __(translations.costsTab.deprecatedOn, 'Deprecated on'),
  comment: () => __(translations.costsTab.comment, 'Comment'),
};
