import React, {FC, Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, ModalFooter, Alert, Col, Row} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import usePreparerOrderModal from './use-preparer-document-modal';
import Button from 'components/button';
import PreparerTable from './preparer-table';
import AsyncItemPicker from 'components/async-item-picker';
import TextInput from 'components/text-input';
import EmptyLabel from 'features/commercial/documents/components/spacing/empty-label';
import Icon from '@ailibs/feather-react-ts';
import SectionSeparator from 'components/separator/section-separator';

interface Props {
  onDismiss: () => void;
}

const PreparerOrderModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    params,
    setParams,
    errorMessage,
    emails,
    handleChangeEmail,
    handleDeleteEmail,
    handleAddEmail,
    updateItem,
    isLoading,
    isPrepared,
    onToggleAll,
    isAllSelected,
    itemsLength,
    validationErrors,
    items,
    submit,
  } = usePreparerOrderModal(onDismiss);

  const {id} = useSelector(selectActiveModalParams);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody>
        <TextInput
          type="textarea"
          label={t(...messages.emailSubject())}
          value={params.body}
          onChange={val => {
            setParams({
              ...params,
              body: val,
            });
          }}
          rows={8}
        />
        <Row className="my-3 d-flex align-items-center">
          <Col>
            <SectionSeparator
              title={t('invoiceActionModal.invoiceEmailList')}
            />
          </Col>
          <Col md="auto">
            <Button color="light" onClick={handleAddEmail}>
              <Icon name="plus" size={15} className="mb-1" />
              {t(...messages.prepareEmailAdd())}
            </Button>
          </Col>
        </Row>
        <div>
          {emails.map((el, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col>
                  <TextInput
                    label={t(...messages.prepareEmail())}
                    errors={validationErrors[`emails.${index}`]}
                    value={el.value}
                    onChange={val => handleChangeEmail(index, val)}
                  />
                </Col>
                <Col md="auto">
                  <EmptyLabel />
                  <Button
                    color=""
                    className="p-1"
                    onClick={() => handleDeleteEmail(index)}
                  >
                    <Icon
                      name="trash-2"
                      size={16}
                      className="text-danger me-1"
                    />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        </div>
        <PreparerTable
          isPrepared={isPrepared}
          onToggleAll={onToggleAll}
          isAllSelected={isAllSelected}
          items={items}
          onUpdateItem={updateItem}
          id={id}
        />
        <AsyncItemPicker
          endPoint="data/stock-locations"
          onChange={stock_location => setParams({...params, stock_location})}
          value={params.stock_location}
          label={t(messages.logistic())}
          errors={validationErrors.stock_location}
        />
        {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button
          color="important"
          disabled={itemsLength === 0}
          isLoading={isLoading}
          onClick={submit}
        >
          {t('common.saveChanges')}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default PreparerOrderModal;
