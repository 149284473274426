import {tableSlice} from 'features/table/slice';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDeleteVariantMutation} from 'store/production/endpoints/action-variant-delete';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDeleteVAriant = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDeleteVariantMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, variant} = modalParams;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = async () => {
    const result = await submit(variant);
    if ('data' in result) {
      navigate(`/dashboard/products`);
      dispatch(
        tableSlice.actions.destroyResource({
          resourceId: variant.id,
          resourceName: 'variants',
        })
      );
      dispatch(toast('success', result?.data?.data.message));
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
