export default [
  {
    id: 'mobilier',
    name: 'mobilier',
  },
  {
    id: 'small_object',
    name: 'smallObjects',
  },
  {
    id: 'component',
    name: 'component',
  },
  {
    id: 'tissus',
    name: 'tissus',
  },
];
