import {useEffect, useState} from 'react';
import {SetParams} from './types';
import {IConclusion, Project} from 'types/project';
import {useParams} from 'react-router';
import {useLazyGetProjectConclusionQuery} from 'features/studio/projects/store/get-project-conclusion';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';
import {
  StoreProjectGlobalCommentsRequest,
  useStoreProjectGlobalCommentsMutation,
} from 'features/studio/projects/store/store-project-global-comments';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {differenceInCalendarMonths, endOfMonth, parse} from 'date-fns';

export const useConclusionForm = (): IConclusionForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState<Params>({
    conclusion_global_comment: '',
    conclusion: {} as Conclusion,
  });

  const {id} = useParams();

  const [getConclusion, {isLoading}] = useLazyGetProjectConclusionQuery();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data as Project;

  useEffect(() => {
    setParams({
      ...params,
      conclusion_global_comment: project?.conclusion_global_comment,
    });

    (async () => {
      const result = await getConclusion({id});

      if ('data' in result) {
        if (!result.data?.data) return;
        const conclusion = result.data?.data as Conclusion;

        setParams(prev => {
          return {
            ...prev,
            conclusion,
          };
        });
      }
    })();
  }, []);

  const [updateGlobalComment, {isLoading: isSubmitting}] =
    useStoreProjectGlobalCommentsMutation();

  const handleSubmit = async () => {
    const result = await updateGlobalComment({
      id,
      conclusion_global_comment: params.conclusion_global_comment,
    } as StoreProjectGlobalCommentsRequest);

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const round = (int: number) => (int ? int.toFixed(2) : 0);

  return {
    params,
    isSubmitting,
    isLoading,
    setParams,
    submit: handleSubmit,
    round,
  };
};

export default interface IConclusionForm {
  params: Params;
  isSubmitting: boolean;
  isLoading: boolean;
  setParams: SetParams;
  submit: () => void;
  round: (int: number) => number | string;
}

type Conclusion = IConclusion;

export type Params = {
  conclusion_global_comment?: string;
  conclusion: Conclusion;
};
