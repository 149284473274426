import React from 'react';
import {AppRoute} from 'routes/types';
import MonitoringMasterDetailRouter from '../containers/master-detail-router/master-detail-router';

const monitoringRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/monitoring/*',
    component: <MonitoringMasterDetailRouter />,
  },
];

export default monitoringRoutes;
