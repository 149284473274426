import React from 'react';
import {FC} from 'react';
import messages from './messages';
import Button from 'components/button';
import Can from 'features/hylian-shield/can';
import {MODAL} from 'features/modal-notch/constants';
import {Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {ProductVariant} from 'types/product-variant';

interface Props {
  productVariant: ProductVariant;
}

const ProductActions: FC<Props> = ({productVariant}) => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleDelete = () => {
    dispatch(
      showModal(MODAL.VARIANT_DELETE_ACTIONS_MODAL, {
        variant: productVariant,

        message: t(...messages.deleteAlertMessage()),
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="delete-and-merge" subject="products">
        <Button color="transparent" onClick={e => handleDelete()}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default ProductActions;
