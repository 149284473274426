import {DeleteResourceAction} from './types';
import {PayloadAction} from '@reduxjs/toolkit';
import {actions} from 'features/listing/list/slice';
import {call, take} from 'redux-saga/effects';

/**
 * Eats the soul off of a resource
 *
 */
export function* eatSoul(action: PayloadAction<DeleteResourceAction>) {
  const {onDestroy} = action.payload;

  yield take(actions.destroyResource.type);
  

  if (typeof onDestroy === 'function') {
    yield call(onDestroy);
  }
}
