import React, {useState} from 'react';

import CreditsColumns from './pages/credits-list/columns';
import CreditsControls from './pages/credits-list/controls';

import AccountingCreditsColumns from './pages/credits-list-archived/columns';
import AccountingCreditsControls from './pages/credits-list-archived/controls';

import messages from './messages';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import {CreditsTabPane} from './credits-tab-pane';
import {CreditStatusType} from 'features/acounting/credits/types/credit-status-type';
import {InvoiceTypesEnum} from 'features/acounting/credits/types/invoice-types-enum';

const CreditsList = () => {
  const {t} = useTranslation();

  const [tab, setTab] = useState<CreditStatusType>('AVAILABLE');

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.creditsList())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},

            {title: t(...messages.creditsList()), path: '#'},
          ]}
        />
        <CreditsTabPane {...{tab, setTab}} />
        <Table
          hideSearch
          url={
            tab === 'ACCOUNTING'
              ? `invoices?filter[invoice_type]=${InvoiceTypesEnum.CREDIT_ACCOUNTING}`
              : `credit-invoices?filter[status]=${tab}`
          }
          otherParams={{
            delimiter: '|',
          }}
          columns={
            tab === 'ACCOUNTING' ? AccountingCreditsColumns : CreditsColumns
          }
          filtersComponent={
            tab === 'ACCOUNTING' ? AccountingCreditsControls : CreditsControls
          }
          name="credit-invoices"
        />
      </Container>
    </div>
  );
};

export default CreditsList;
