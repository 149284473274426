import React, {useState} from 'react';
import languages from '../../../common/languages';
import {get, map} from 'lodash';
import {useTranslation, withTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {AppDispatch} from 'store';
import {changeAppLocale} from 'store/global/change-app-locale';
import {selectAppLocale} from 'store/global/selectors/select-app-locale';

const LanguageDropdown = () => {
  const {i18n} = useTranslation();

  const selectedLang = useSelector(selectAppLocale);

  const [menu, setMenu] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const changeLanguageAction = (lang: string) => {
    dispatch(changeAppLocale(lang));
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            height="16"
            className="me-1"
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? 'active' : 'none'
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
