import React, {FC, useContext} from 'react';
import {
  SituationPrices,
  useLazyShowSituationPricesQuery,
} from '../../store/endpoints/show-situation-prices';
import {CommercialProduct} from '../../types/commercial-product';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import {useTranslation} from 'react-i18next';
import {Situation} from 'types/situation';
import otherParamsContext from '../../containers/item-options-modal/other-params-context';
import {Alert} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';

interface Props {
  item: CommercialProduct;
  index: number;
  onChangeSituation: (situation?: Situation, prices?: SituationPrices) => void;
}

const SituationPicker: FC<Props> = props => {
  const {item, onChangeSituation} = props;

  const {t} = useTranslation();

  const {params, doc, doc_type} = useContext(otherParamsContext);

  const [fetchPrices, {error}] = useLazyShowSituationPricesQuery();

  const {message} = parseValidationErrors(error);

  const handleUpdateSituation = async (v?: Situation) => {
    const result = await fetchPrices({
      id: v?.id,
      variantId: item.variant?.id,
      document_id: doc?.id,
      document_category_id: doc?.document_category.id,
      doc_type: doc_type,
    });
    onChangeSituation(v, result.data?.data);
  };

  const is_locked = doc?.status === 'VALIDATED';

  return (
    <div className="mb-2">
      <AsyncItemPicker
        key={`item-${item.id}-situations-of-${item.variant?.id}`}
        disabled={is_locked}
        endPoint={`product-variants/${item.variant?.id}/situations`}
        label={t(...messages.chooseSituation())}
        value={params.situation}
        getOptionLabel={(x: any) => x.name}
        getOptionValue={(x: any) => x?.id}
        // @ts-expect-error
        onChange={(v?: Option) => handleUpdateSituation(v)}
      />
      {message && (
        <Alert color="danger" className="p-1 mt-2">
          {message}
        </Alert>
      )}
    </div>
  );
};

export default SituationPicker;
