import {RestoreResourceUrlMap, RestoreResourceAction} from './types';
import {toast} from 'react-toastify';

export const makeRestoreResourceUrl = (
  action: RestoreResourceAction
): string => {
  const urlGenerator = urls[action.name];

  if (typeof urlGenerator === 'function') {
    return urlGenerator(action);
  }
  return `url-undefined/${action.resourceId}`;
};

/**
 * All resource removal endpoints
 *
 */
const urls: RestoreResourceUrlMap = {
  users: action => `trashed/users/${action.resourceId}/restore`,
  variants: action => `trashed/product-variants/${action.resourceId}/restore`,
  directories: action => `trashed/directories/${action.resourceId}/restore`,
  administrators: action =>
    `trashed/administrators/${action.resourceId}/restore`,
  products: action => `trashed/products/${action.resourceId}/restore`,
  families: action => `trashed/product-families/${action.resourceId}/restore`,
  address: action => `trashed/directory-addresses/${action.resourceId}/restore`,
};

export const toastConfig = {
  position: toast.POSITION.TOP_RIGHT,
};
