import markAsReadEndpoint from '../endpoints/mark-as-read';
import toggleNotificationsEndpoint from '../endpoints/toggle-notifications';
import updatePasswordEndpoint from '../endpoints/update-password';
import updateProfileEndpoint from '../endpoints/update-profile';
import getRecentNotificationsEndpoint from './get-recent-notifications';
import markAllNotificationsAsRead from './mark-all-notifications-as-read';
import toggleNewsLetterEndpoint from './toggle-news-letter';
import {api} from 'services/api';

export const accountApi = api.injectEndpoints({
  endpoints: build => ({
    markAllNotificationsAsRead: markAllNotificationsAsRead(build),
    getRecentNotifications: getRecentNotificationsEndpoint(build),
    markNotificationAsRead: markAsReadEndpoint(build),
    toggleNewsLetter: toggleNewsLetterEndpoint(build),
    toggleNotifications: toggleNotificationsEndpoint(build),
    updatePassword: updatePasswordEndpoint(build),
    updateProfile: updateProfileEndpoint(build),
  }),
});

export const {
  useGetRecentNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useToggleNewsLetterMutation,
  useToggleNotificationsMutation,
  useMarkAllNotificationsAsReadMutation,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
} = accountApi;
