import React from 'react';
import columns from './columns';
import messages from './messages';
import UserFilters from './user-filters';
import './users.styles.scss';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from 'features/listing/list';
import selectEntity from 'features/listing/list/slice/selectors/select-entity';
import Table from 'features/table';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Container} from 'reactstrap';

const UsersPage = () => {
  const {t} = useTranslation();

  const {filters} = useSelector(selectEntity('users'));

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t(...messages.dashboard()), path: '/dashboard'},
            {title: t(...messages.users()), path: '#'},
          ]}
        />

        <Table
          url="users"
          name="users"
          filtersComponent={UserFilters}
          columns={columns}
        ></Table>
      </Container>
    </div>
  );
};

export default UsersPage;
