import {api} from 'services/api';
import {DocumentCategory} from 'types/document-category';
import {ResponseData} from 'types/response-data';

export const showDocumentCategories = api.injectEndpoints({
  endpoints: builder => ({
    showDocumentCategories: builder.query<Response, void>({
      query: () => ({
        method: 'GET',
        url: `data/document-categories`,
      }),
    }),
  }),
});

type Response = ResponseData<DocumentCategory[]>;

export const {useShowDocumentCategoriesQuery} = showDocumentCategories;
