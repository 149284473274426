import React, {FC, Fragment} from 'react';
import messages from './messages';
import {FilterMap} from 'features/listing/list/slice/types';
import {useTranslation} from 'react-i18next';
import {CardBody, Row, Col, FormGroup, Label, Collapse} from 'reactstrap';
import AsyncItemPicker from 'components/async-item-picker';
import {StockInProductsFilters} from './types';
import {useLocalStorage} from 'usehooks-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';
import {useDispatch} from 'react-redux';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap<StockInProductsFilters>;
  onChangeFilters: (key: StockInProductsFilters, value: any) => void;
}

const FiltersSection: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters} = props;
  const dispatch = useDispatch();
  const [v, tt] = useLocalStorage('is-advanced-search-open', false);
  const handleTransfer = () => {
    dispatch(
      showModal(MODAL.STOCK_TRANSFER_PRODUCT, {
        path: {
          url: 'stock/products-in-stock',
          name: 'stock-products-in-stock',
        },
      })
    );
  };
  const handleAssamble = () => {
    dispatch(
      showModal(MODAL.ASSEMBLE_PRODUCT, {
        path: {
          url: 'stock/products-in-stock',
          name: 'stock-products-in-stock',
        },
      })
    );
  };

  return (
    <Fragment>
      <AppBarActions>
        <Can action="edit" subject="stock">
          <Button color="light" className="ms-2" onClick={handleAssamble}>
            {t(...messages.assamble())}
          </Button>
          <Button color="light" className="ms-2" onClick={handleTransfer}>
            {t(messages.transfer())}
          </Button>
        </Can>
      </AppBarActions>
    </Fragment>
  );
};

export default FiltersSection;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
