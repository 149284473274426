import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.assembleProduct.title, 'Assemble products'),
  serialNumber: () => __(translations.assembleProduct.serialNumber, 'Serial number'),
  subLocation: () => __(translations.assembleProduct.Location, 'location'),
  scan: () => __(translations.assembleProduct.scan, 'Scan'),
  sku: () => __(translations.assembleProduct.sku, 'SKU'),
  name: () => __(translations.assembleProduct.name, 'Name'),
  assemble: () => __(translations.assembleProduct.assemble, 'Assemble'),
};
