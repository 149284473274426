import React, {useEffect} from 'react';
import 'assets/scss/preloader.scss';
import 'assets/scss/theme.scss';
import HorizontalLayout from 'components/HorizontalLayout';
import NonAuthLayout from 'components/NonAuthLayout';
import VerticalLayout from 'components/VerticalLayout';
import {application} from 'constants/application';
import HylianShield from 'features/hylian-shield';
import ModalNotch from 'features/modal-notch';
import LoadingPage from 'pages/loading';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import routes from 'routes/all-routes';
import Authmiddleware from 'routes/middleware/auth';
import GuestMiddleware from 'routes/middleware/guest';
import {AppRoute} from 'routes/types';
import {AppDispatch} from 'store';
import {authApi} from 'store/auth/endpoints';
import {selectIsAuthenticated} from 'store/auth/selectors';
import initializeApplication from 'store/global/initialize-application';
import selectIsApplicationBusy from 'store/global/selectors/select-is-application-busy';
import {selectLayoutState} from 'store/layout/selectors';

const App = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(initializeApplication());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(authApi.endpoints.refresh.initiate());
    }
  }, [dispatch, isAuthenticated]);

  const isBusy = useSelector(selectIsApplicationBusy);

  const {layoutType} = useSelector(selectLayoutState);

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout: any = getLayout();

  const bubleWrap = (route: AppRoute) => {
    let PageLayout = Layout;
    if (route.layout) {
      PageLayout = route.layout;
    }

    if (route.requireGuest) {
      return (
        <GuestMiddleware>
          <NonAuthLayout>{route.component}</NonAuthLayout>
        </GuestMiddleware>
      );
    }
    if (route.requireAuth) {
      return (
        <Authmiddleware {...route}>
          <PageLayout>{route.component}</PageLayout>
        </Authmiddleware>
      );
    } else {
      return <NonAuthLayout>{route.component}</NonAuthLayout>;
    }
  };

  return (
    <React.Fragment>
      <BrowserRouter basename={application.baseUrl}>
        <HylianShield>
          <>
            <Helmet titleTemplate={`%s | ${application.name}`} />
            <Routes>
              {isBusy && <Route path="*" element={<LoadingPage />} />}
              {!isBusy &&
                routes.map((route, idx) => (
                  <Route
                    path={route.path}
                    element={bubleWrap(route)}
                    key={idx}
                  />
                ))}
            </Routes>
            <ModalNotch />
            <ToastContainer
              position="top-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </>
        </HylianShield>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
