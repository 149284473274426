import React from 'react';
import {variantTabs} from '../constants';
import './variant-tab-pane.styles.scss';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';

const VariantTabPane = () => {
  const {t} = useTranslation();

  const {id, variant} = useParams();

  const prefix = `/dashboard/products/${id}/variants`;

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4 tab-pane">
      {variantTabs.map(x => (
        <NavItem key={x.to}>
          <NavLink
            className={active =>
              clsx({active: active.isActive}, 'px-3 nav-link')
            }
            to={`${prefix}/${variant}/${x.to}`}
          >
            {t(`editProductPage.${x.title}`)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default VariantTabPane;
