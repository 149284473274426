import React, {FC} from 'react';
import {MODAL} from 'features/modal-notch/constants';
import {Eye} from 'react-feather';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import Button from 'components/button';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';

interface Props {
  stockInPreparation: StockProductInPreparation;
}

const DetailsAction: FC<Props> = props => {
  const {stockInPreparation} = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.DETAILS_STOCK_IN_PREPARATION_MODAL, {
        stockInPreparation,
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Button tag="button" color="transparent" onClick={handleEdit}>
        <Eye size={20} />
      </Button>
    </div>
  );
};

export default DetailsAction;
