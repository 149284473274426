import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.customizationOptionsTab.title, 'Customization Options'),
  addOption: () => __(translations.customizationOptionsTab.addOption, 'Add option'),
  tissue: () => __(translations.customizationOptionsTab.tissue, 'Tissue'),
  option: () => __(translations.customizationOptionsTab.option, 'Option'),
  subOption: () => __(translations.customizationOptionsTab.subOption, 'Sub-option'),
  other: () => __(translations.customizationOptionsTab.other, 'Other'),
};
