import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const sendEmailInvoice = api.injectEndpoints({
  endpoints: builder => ({
    sendEmailInvoice: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `invoices/${params.id}/send-mail`,
          body: {
            body: params.body,
            emails: params.emails,
            stock_summary: params.stock_summary,
            with_invoice: params.with_invoice,
            packing_list: params.packing_list,
          },
        };
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
  body?: string;
  emails?: string[];
  stock_summary?: boolean;
  with_invoice?: boolean;
  packing_list?: boolean;
};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useSendEmailInvoiceMutation} = sendEmailInvoice;
