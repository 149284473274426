import React from 'react';
import {FC} from 'react';
import Can from 'features/hylian-shield/can';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, FileText, Trash2} from 'react-feather';
import {Trans} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {Color} from 'types/colors';
import Button from 'components/button';

interface Props {
  color: Color;
}

const ColorActions: FC<Props> = ({color}) => {
  const dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(showModal(MODAL.COLOR_MODAL, {store: false, color: color}));
  };

  const handleArchive = () => {
    dispatch(
      showModal(MODAL.COLOR_ARCHIVE_MODAL, {
        colorId: color.id,
      })
    );
  };
  return (
    <div className="d-flex">
      <Button color="transparent" className="me-2" onClick={handleUpdate}>
        <Edit size={20} />
      </Button>

      <Can action="delete" subject="products">
        <Button color="transparent" onClick={handleArchive}>
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default ColorActions;
