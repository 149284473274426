import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {CommercialProduct} from '../../types/commercial-product';
import {actions} from '../../store/slice';
import {uiActions} from 'store/ui/ui-slice';
import {OtherParamsFields} from './other-params-context';

export default () => {
  const {item} = useSelector(selectActiveModalParams) as {
    item: CommercialProduct;
    index: number;
  };

  const [params, setParams] = useState<OtherParamsFields>({
    id: item.id,
    situation: item.situation,
    public_comment: item.public_comment,
    private_comment: item.private_comment,
    vat: item.vat,
    is_custom_made: item.is_custom_made,
    handel_stock: item.handel_stock,
  });

  const dispatch = useDispatch();

  const submit = () => {
    dispatch(actions.upsertItem(params));
    dispatch(uiActions.dismissModal());
  };

  return {
    params,
    submit,
    setParams,
  };
};
