import {ApiEndpointBuilder} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {Role} from 'types/role';
import {User} from 'types/user';

const showUserEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<Response, Request>({
    query: params => ({
      method: 'GET',
      url: `users/${params.id}`,
    }),
  });
};

type Request = {
  id: ObjectId;
};

type Response = ResponseData<User & {role: Role}>;

export default showUserEndpoint;
