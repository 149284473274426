import {markNotificationAsRead} from './reducers/mark-notification-as-read';
import {toggleNewsletter} from './reducers/toggle-newsletter';
import {toggleNotifications} from './reducers/toggle-notifications';
import {AccountState} from './types';
import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

export const extraReducers = (
  builder: ActionReducerMapBuilder<AccountState>
) => {
  builder.addMatcher(markNotificationAsRead.matcher, markNotificationAsRead.reducer); // prettier-ignore

  builder.addMatcher(toggleNewsletter.matcher, toggleNewsletter.reducer);

  builder.addMatcher(toggleNotifications.matcher, toggleNotifications.reducer);
};
