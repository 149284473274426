import React, {FC, useState} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Table} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {ProductDimension} from 'types/product-dimension';

interface DimensionTableProps {
  dimensions: Record<ObjectId, ProductDimension>;
  validationErrors: any;
  updatePackaging: (
    p: ProductDimension,
    k: keyof ProductDimension,
    v: any
  ) => void;
  removePackaging: (id: ObjectId) => void;
}

const DimensionTable: FC<DimensionTableProps> = props => {
  const {dimensions, validationErrors, updatePackaging, removePackaging} =
    props;

  const {t} = useTranslation();

  return (
    <Table responsive>
      <thead>
        <tr>
          <th> {t(...messages.label())} </th>
          <th> {t(...messages.width())} </th>
          <th> {t(...messages.height())} </th>
          <th> {t(...messages.length())} </th>
          <th> {t(...messages.volume())} </th>
          <th> {t(...messages.weight())} </th>
          <th> {t(...messages.netWeight())} </th>
          <th> {t('productsPage.action')} </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(dimensions).map((p, i) => (
          <tr key={p.id}>
            <>
              <td>
                <TextInput
                  containerStyle={'flex-grow-1'}
                  type="text"
                  value={p.name}
                  onChange={x => updatePackaging(p, 'name', x)}
                  errors={validationErrors[`package_dimensions.${i}.name`]}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={p.width}
                  onChange={x => updatePackaging(p, 'width', x)}
                  errors={validationErrors[`package_dimensions.${i}.width`]}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={p.height}
                  onChange={x => updatePackaging(p, 'height', x)}
                  errors={validationErrors[`package_dimensions.${i}.height`]}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={p.depth}
                  onChange={x => updatePackaging(p, 'depth', x)}
                  errors={validationErrors[`package_dimensions.${i}.depth`]}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={(
                    Number(p.width) *
                    Number(p.depth) *
                    Number(p.height) *
                    10 ** -6
                  ).toFixed(6)}
                  readOnly
                  disabled
                  onChange={() => {}}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={p.weight}
                  errors={validationErrors[`package_dimensions.${i}.weight`]}
                  onChange={x => updatePackaging(p, 'weight', x)}
                />
              </td>
              <td>
                <TextInput
                  type="number"
                  value={p.net_weight}
                  errors={
                    validationErrors[`package_dimensions.${i}.net_weight`]
                  }
                  onChange={x => updatePackaging(p, 'net_weight', x)}
                />
              </td>
              <td>
                <Button
                  color="link"
                  className="text-danger"
                  onClick={() => removePackaging(p.id)}
                >
                  <Icon name="trash-2" size={15} />
                </Button>
              </td>
            </>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DimensionTable;
