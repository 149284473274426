import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useLocationForm} from './use-location-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const UpdateStockLocationForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useLocationForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={name => setParams({...params, name})}
            value={params.name}
            label={t('createStockLocationModal.name')}
            errors={validationErrors.name}
          />

          <AsyncItemPicker
            endPoint="data/stock-location-categories"
            onChange={stock_location_category =>
              setParams({...params, stock_location_category})
            }
            value={params.stock_location_category}
            label={t('createStockLocationModal.category')}
            errors={validationErrors.stock_location_category}
          />
          <div className="form-check my-3">
            <label className="form-check-label" htmlFor="form-check-left">
              {t('createStockLocationModal.isLocked')}
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="form-check-left"
              checked={params.is_locked}
              onChange={ev =>
                setParams({...params, is_locked: ev.target.checked})
              }
            />
          </div>

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default UpdateStockLocationForm;
