import makePayment from '../../utils/make-payment';
import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {Option} from 'types/option';

export default createAction(
  actions.addRefundPayment.type,
  (amount?: number, type?: Option) => {
    const payment = makePayment();

    if (!amount || !type)
      return {
        payload: payment,
      };

    payment.amount = `-${amount.toString()}`;
    payment.type = type;

    return {
      payload: payment,
    };
  }
);
