import React, {FC} from 'react';
import CreateProjectForm from './create-project-form';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

interface Props {}

const CreateProjectPage: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.projects()), path: '/dashboard/projects'},
            {title: t(...messages.create()), path: '#'},
          ]}
        />
        <div>
          <CreateProjectForm />
        </div>
      </Container>
    </div>
  );
};

export default CreateProjectPage;
