import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  actions: () => __(translations.directoryPage.actions, 'Actions'),
  add: () => __(translations.directoryPage.addBtn, 'Add'),
  addCompany: () => __(translations.directoryPage.addCompany, 'Add Company'),
  addContact: () => __(translations.directoryPage.addContact, 'Add Contact'),
  additionalInfo: () => __(translations.directoryPage.additionalInfo, 'Additional Info'),
  addresses: () => __(translations.directoryPage.addresses, 'Addresses'),
  archive: () => __(translations.directoryPage.archive, 'Archive'),
  archiveAlertMessage: () => __(translations.directoryPage.archiveAlertMessage, 'Archive this contact?'),
  attachments: () => __(translations.directoryPage.attachments, 'Attachments'),
  category: () => __(translations.directoryPage.category, 'Category'),
  companyList: () => __(translations.directoryPage.companyList, 'Companies'),
  exportCompany: () => __(translations.directoryPage.exportCompany, 'Export Company'),
  exportContact: () => __(translations.directoryPage.exportContact, 'Export Contact'),
  exportAll: () => __(translations.directoryPage.exportAll, 'Export All'),
  contacts: () => __(translations.directoryPage.contacts, 'Contacts'),
  coordinate: () => __(translations.directoryPage.coordinate, 'Coordinate (s)'),
  coordinates: () => __(translations.directoryPage.coordinates, 'Coordinates'),
  deleteAlertMessage: () => __(translations.directoryPage.deleteAlertMessage, 'Are you sure you want to delete this contact?'),
  exports: () => __(translations.directoryPage.exports, 'Exports'),
  family: () => __(translations.directoryPage.family, 'Family'),
  general: () => __(translations.directoryPage.general, 'General'),
  interactions: () => __(translations.directoryPage.interactions, 'Interactions'),
  stat: () => __(translations.directoryPage.stat, 'linkedResources'),
  occupation: () => __(translations.directoryPage.occupation, 'Occupation'),
  pageTitle: () => __(translations.directoryPage.pageTitle, 'Directory'),
  pricing: () => __(translations.directoryPage.pricing, 'Pricing'),
  viewDirectory: () => __(translations.directoryPage.viewDirectory, 'View directory'),
  id: () => __(translations.directoryPage.id, 'ID'),
  gender: () => __(translations.directoryPage.gender, 'Gender'),
  first_name: () => __(translations.directoryPage.first_name, 'First Name'),
  last_name: () => __(translations.directoryPage.last_name, 'Last Name'),
  country: () => __(translations.directoryPage.country, 'Country'),
  job: () => __(translations.directoryPage.job, 'Job'),
  type: () => __(translations.directoryPage.type, 'Type'),
  description: () => __(translations.directoryPage.description, 'Description'),
  email: () => __(translations.directoryPage.email, 'Email'),
  phone: () => __(translations.directoryPage.phone, 'Phone'),
  address: () => __(translations.directoryPage.address, 'Address'),
  website: () => __(translations.directoryPage.website, 'Website'),
  company: () => __(translations.directoryPage.company, 'Company'),
  comment: () => __(translations.directoryPage.comment, 'Comment'),
  name: () => __(translations.directoryPage.name, 'Name'),
  action: () => __(translations.directoryPage.action, 'Action'),
  newsletter: () => __(translations.directoryPage.newsletter, 'subscribed to newsletter'),
  project: () => __(translations.directoryPage.project, 'Project'),
  referentContact: () => __(translations.directoryPage.referentContact, 'Referent contact'),
  requirementRate: () => __(translations.directoryPage.requirementRate, 'Requirement rate'),
  segment: () => __(translations.directoryPage.segment, 'Segment'),
  turnover: () => __(translations.directoryPage.turnover, 'Turnover'),
  official_studio_supplier: () => __(translations.directoryPage.officialSupplierStudio, 'Official supplier studio'),
  official_furniture_supplier: () => __(translations.directoryPage.officialSupplierFurniture, 'Official supplier furniture'),
  official_suppliers_small_items: () => __(translations.directoryPage.officialSupplierSmallItems, 'Official supplier small items'),
};
