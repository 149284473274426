import {useEffect} from 'react';
import {useCurrentOrder} from '../containers/order-context/order-context';
import {useUpdateOrderDetailsMutation} from '../store/endpoints/update-order-details';
import {DocumentResponse} from 'features/commercial/documents/containers/document-context/document-context';
import hydrateFields from 'features/commercial/documents/store/actions/hydrate-fields';
import {actions} from 'features/commercial/documents/store/slice';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (): DocumentResponse => {
  const [commit, {isLoading, error, isSuccess, reset}] =
    useUpdateOrderDetailsMutation();

  const serverError = parseValidationErrors(error);

  const doc = useCurrentOrder();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await commit({
      id: doc.id,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (doc) {
      dispatch(hydrateFields(doc));
    }
    return () => {
      dispatch(actions.reset());
    };
  }, [doc]);

  return {
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    submit: handleSubmit,
    validationErrors: serverError.errors,
    reset,
    doc,
    doc_type: 'order',
    is_locked: doc?.status === 'VALIDATED',
  };
};
