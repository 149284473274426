import React, {useContext} from 'react';
import AddressItem from './address-item';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import useAfterLife from 'features/modal-notch/soul-eater/use-after-life';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Col, Row} from 'reactstrap';
import {useGetDirectoryAddressesQuery} from 'store/directory/endpoints/get-directory-addresses';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import showAddressModal from 'store/ui/actions/show-address-modal';
import denormalizeObject from 'utils/denormalize-object';
import LoadRunner from 'components/load-runner';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const AddressesTab = () => {
  const dispatch = useDispatch();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const {id} = useParams();

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  const handleAddAddress = () => {
    if (directory) {
      dispatch(showAddressModal(directory?.id));
    }
  };

  const {data: response, isLoading} = useGetDirectoryAddressesQuery({
    directory_id: directory?.id,
  });

  const {saints: directoryAddresses} = useAfterLife(
    'address',
    denormalizeObject(response?.data)
  );

  const {t} = useTranslation();

  return (
    <div>
      <Row className="mb-3">
        <Col md={10}>
          <SectionSeparator
            title={`${t(...messages.pageTitle())}  (${
              directoryAddresses?.length
            })`}
          />
        </Col>
        <Col md="2" className="">
          <Button
            color="important"
            disabled={directory?.is_archived || !canEdit}
            className="w-100"
            onClick={handleAddAddress}
          >
            <Icon name="plus" size={16} className="me-2" />
            {t(...messages.add())}
          </Button>
        </Col>
      </Row>

      <Row>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <LoadRunner width={'100%'} height={80} viewBox="0 0 100% 60">
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
              <rect x="0" y="40" rx="5" ry="5" width="85%" height="20" />
            </LoadRunner>
          </div>
        )}
        {directoryAddresses.map(x => (
          <AddressItem key={x.id} address={x} />
        ))}
      </Row>
    </div>
  );
};

export default AddressesTab;
