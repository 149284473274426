import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  phasesAndHonoraria: () => __(translations.editProjectPhaseSection.phasesAndHonoraria, 'Phases & Honoraires'),
  addNewPhase: () => __(translations.editProjectPhaseSection.addNewPhase, 'Ajouter une nouvelle phase'),
  phases: () => __(translations.editProjectPhaseSection.phases, 'Phases'),
  phasesList: () => __(translations.editProjectPhaseSection.phasesList, 'Phases List'),
  globalComment: () => __(translations.editProjectPhaseSection.globalComment, 'Global Comment'),

  //phases
  retainer: () => __(translations.editProjectPhaseSection.retainer, 'Retainer'),
  design_programming: () => __(translations.editProjectPhaseSection.design_programming, 'Design / Programming'),
  project_preliminary_design: () => __(translations.editProjectPhaseSection.project_preliminary_design, 'Project Preliminary Design'),
  project_final_design: () => __(translations.editProjectPhaseSection.project_final_design, 'Project Final Design'),
  final_project: () => __(translations.editProjectPhaseSection.final_project, 'Final Project'),
  design_coordination: () => __(translations.editProjectPhaseSection.design_coordination, 'Design Coordination'),
  furniture_ffe: () => __(translations.editProjectPhaseSection.furniture_ffe, 'Furniture / FF&E'),
  styling: () => __(translations.editProjectPhaseSection.styling, 'Styling'),
  sample_room: () => __(translations.editProjectPhaseSection.sample_room, 'Sample Room'),
  typology_breakdown: () => __(translations.editProjectPhaseSection.typology_breakdown, 'Typology Breakdown'),
  architectural_charter: () => __(translations.editProjectPhaseSection.architectural_charter, 'Architectural Breakdown'),
  intellectual_property_rights_transfer: () => __(translations.editProjectPhaseSection.intellectual_property_rights_transfer, 'Transfer of intellectual property rights'),
  communication_press: () => __(translations.editProjectPhaseSection.communication_press, 'Communication / Press relations'),
  additional_spaces: () => __(translations.editProjectPhaseSection.additional_spaces, 'Additional Spaces'),
  others: () => __(translations.editProjectPhaseSection.others, 'Others'),

  amount: () => __(translations.editProjectPhaseSection.amount, 'Amount'),
  estimatedCompletionDate: () => __(translations.editProjectPhaseSection.estimatedCompletionDate, 'Estimated completion date'),
  completionDate: () => __(translations.editProjectPhaseSection.completionDate, 'Completion date'),
  invoiceNumber: () => __(translations.editProjectPhaseSection.invoiceNumber, 'Invoice number'),
  paidOn: () => __(translations.editProjectPhaseSection.paidOn, 'Paid on'),
  honoraria: () => __(translations.editProjectPhaseSection.honoraria, 'Honoraria'),
  fees: () => __(translations.editProjectPhaseSection.fees, 'Fees'),
  specifyPhaseName: () => __(translations.editProjectPhaseSection.specifyPhaseName, 'Specify phase name'),

  totalToInvoice: () => __(translations.editProjectPhaseSection.totalToInvoice, 'Σ to invoice'),
  totalPending: () => __(translations.editProjectPhaseSection.totalPending, 'Σ pending'),
  totalPaid: () => __(translations.editProjectPhaseSection.totalPaid, 'Σ paid'),
  grandTotal: () => __(translations.editProjectPhaseSection.grandTotal, 'Σ Honoraria & fees'),
  addToList: () => __(translations.editProjectPhaseSection.addToList, 'Add to list'),

  // actions
  doubleClickToRemovePhase: () => __(translations.editProjectPhaseSection.doubleClickToRemovePhase, 'Double click to remove phase'),
  addDeposit: () => __(translations.editProjectPhaseSection.addDeposit, 'Add Deposit'),
};
