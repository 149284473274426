import React from 'react';
import {AppRoute} from './types';
import InvoiceMasterDetail from 'features/commercial/invoices/containers/invoice-master-detail/invoice-master-detail';
import OrderMasterDetails from 'features/commercial/orders/container/order-master-detail/order-master-detail';
import CreateCustomerOrdersPage from 'pages/commercial/customer-orders/create/create-customer-order-page';
import CustomerOrdersPage from 'pages/commercial/customer-orders/customer-orders-page';
import CreateCreditPage from 'pages/commercial/invoices/create/credit/create-credit-page';
import CreateInvoicePage from 'pages/commercial/invoices/create/invoice/create-invoice-page';
import InvoicesPage from 'pages/commercial/invoices/invoices-page';
import PricingPage from 'pages/commercial/pricing/pricing-page';
import CreateQuotationPage from 'pages/commercial/quotations/create/create-quotation-page';
import QuotationMasterDetails from 'pages/commercial/quotations/master-details/quotation-master-details';
import QuotationsPage from 'pages/commercial/quotations/quotations-page';

const commercialRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/pricing',
    component: <PricingPage />,
    subject: ['product-prices'],
  },
  {
    path: '/dashboard/quotations',
    component: <QuotationsPage />,
    subject: ['dcv', 'dcz'],
  },
  // we may have to only pick one of customer-orders and orders
  // but we can also keep both
  {
    path: '/dashboard/orders',
    component: <CustomerOrdersPage />,
    subject: ['ccc', 'cch', 'ccr', 'ccs', 'ccv', 'ccz', 'cfv', 'cfz'],
  },
  {
    path: '/dashboard/customer-orders',
    component: <CustomerOrdersPage />,
    subject: ['ccc', 'cch', 'ccr', 'ccv', 'ccz'],
  },
  {
    path: '/dashboard/quotations/create',
    component: <CreateQuotationPage />,

    subject: ['dcv', 'dcz'],
    action: ['create-and-edit'],
  },
  {
    path: '/dashboard/customer-orders/create',
    component: <CreateCustomerOrdersPage />,
    subject: ['ccc', 'cch', 'ccr', 'ccv', 'ccz'],
    action: ['create-and-edit'],
  },
  {
    path: '/dashboard/quotations/:id/*',
    component: <QuotationMasterDetails />,
    subject: ['dcv', 'dcz'],
  },
  {
    path: '/dashboard/orders/:id/*',
    component: <OrderMasterDetails />,
    subject: ['ccc', 'cch', 'ccr', 'ccs', 'ccv', 'ccz', 'cfv', 'cfz'],
  },
  {
    path: '/dashboard/invoices',
    component: <InvoicesPage />,
    subject: ['h', 'r', 'c', 'z', 'v'],
  },
  {
    path: '/dashboard/invoices/create',
    component: <CreateInvoicePage />,
    subject: ['h', 'r', 'c', 'z', 'v'],
    action: ['create-and-edit'],
  },
  {
    path: '/dashboard/credits/create',
    component: <CreateCreditPage />,
    subject: ['h', 'r', 'c', 'z', 'v'],
    action: ['create-and-edit'],
  },
  {
    path: '/dashboard/invoices/:id',
    component: <InvoiceMasterDetail />,
    subject: ['h', 'r', 'c', 'z', 'v'],
  },
  {
    path: '/dashboard/invoices/:id/*',
    component: <InvoiceMasterDetail />,
    subject: ['h', 'r', 'c', 'z', 'v'],
  },
];

export default commercialRoutes;
