import React from 'react';
import columns from './columns';
import messages from './messages';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import MaterialControls from './materials-controls/materials-controls';

const MaterialsPage = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Table
          url="materials"
          otherParams={{
            delimiter: '|',
          }}
          columns={columns}
          filtersComponent={MaterialControls}
          name="materials"
        />
      </Container>
    </div>
  );
};

export default MaterialsPage;
