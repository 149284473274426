import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  UpdateFiscalSettingsRequest,
  useUpdateFiscalSettingsMutation,
} from 'store/global/endpoints/update-fiscal-settings';
import selectFiscalSettings from 'store/global/selectors/select-fiscal-settings';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [submit, {isLoading, error, isSuccess}] =
    useUpdateFiscalSettingsMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({});
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const fSettings = useSelector(selectFiscalSettings);

  useEffect(() => {
    if (fSettings) {
      setParams({
        ...fSettings,
      });
    }
  }, [fSettings]);

  return {
    setParams,
    params,
    isSuccess,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = UpdateFiscalSettingsRequest;
