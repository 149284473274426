import React, {FC} from 'react';
import messages from './messages';
import OtherOption from './other-option';
import {useCustomizationsForm} from './use-customizations-form';
import VariantOption from './variant-option';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {
  Alert,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Can from 'features/hylian-shield/can';

interface Props {}

const CustomizationOptions: FC<Props> = props => {
  const {
    addCustomization,
    updateCustomization,
    removeCustomization,
    customizations,
    errorMessage,
    isLoading,
    isSuccess,
    validationErrors,
    submit,
  } = useCustomizationsForm();
  const costumOptions = [
    {code: 'variant', label: messages.tissue},
    {code: 'other', label: messages.other},
  ];

  const {t} = useTranslation();

  return (
    <div className="mb-5">
      <Row>
        <Col md={12}>
          <div>
            <Label>{t(...messages.title())}</Label>
            <hr />
          </div>
        </Col>
      </Row>
      {/* Dimensions dynamic rows */}

      {Object.values(customizations).map((p, i) =>
        p.is_variant ? (
          <VariantOption
            customization={p}
            updateCustomization={updateCustomization}
            removeCustomization={removeCustomization}
            index={i}
            validationErrors={validationErrors}
            key={p.id}
          />
        ) : (
          <OtherOption
            validationErrors={validationErrors}
            customization={p}
            updateCustomization={updateCustomization}
            removeCustomization={removeCustomization}
            index={i}
            key={p.id}
          />
        )
      )}
      <Can action="edit" subject="products">
        <Row className="mb-3">
          <Col md={4}>
            <ButtonGroup className="my-2">
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle caret color="light">
                    {t(...messages.addOption())}
                    <Icon name="chevron-down" className="ms-1" size={15} />
                  </DropdownToggle>
                  <DropdownMenu>
                    {costumOptions.map(x => (
                      <DropdownItem
                        onClick={() => addCustomization(x.code)}
                        key={x.code}
                      >
                        {t(...x.label())}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonGroup>
            </ButtonGroup>
          </Col>
        </Row>
      </Can>
      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className=" mb-4">
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

            {isSuccess && (
              <Alert color="success">
                <Trans i18nKey="common.changesCommited" />
              </Alert>
            )}
            <AppBarActions>
              <Button isLoading={isLoading} color="important" onClick={submit}>
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </Can>
    </div>
  );
};

export default CustomizationOptions;
