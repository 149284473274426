import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  comment: () => __(translations.createProjectPage.comment, 'Comment'),
  create: () => __(translations.createProjectPage.create, 'New project'),
  pageTitle: () => __(translations.createProjectPage.pageTitle, 'New project'),
  projects: () => __(translations.createProjectPage.products, 'Projects'),
  description: () => __(translations.createProjectPage.description, 'Description'),
  designation: () => __(translations.createProjectPage.designation, 'Designation'),
  saveAs: () => __(translations.createProjectPage.saveAs, 'Save as'),
  name: () => __(translations.createProjectPage.name, 'Project Name'),
  projectCodeName: () => __(translations.createProjectPage.projectCodeName, 'Project Code Name'),
  projectType: () => __(translations.createProjectPage.type, 'Project type'),
  projectStatus: () => __(translations.createProjectPage.projectStatus, 'Project Status'),
  projectCategory: () => __(translations.createProjectPage.category, 'Project category'),
  customer: () => __(translations.createProjectPage.customer, 'Customer'),
  clientNotice: () => __(translations.createProjectPage.clientNotice, 'Billing informations'),
  startDate: () => __(translations.createProjectPage.startDate, 'Estimated start date'),
  endDate: () => __(translations.createProjectPage.endDate, 'Estimated end date'),
  hotel: () => __(translations.createProjectPage.hotel, 'Hostel'),
  restaurant: () => __(translations.createProjectPage.restaurant, 'Restaurant'),
  private: () => __(translations.createProjectPage.private, 'Private '),
  store: () => __(translations.createProjectPage.store, 'Store '),
  archi: () => __(translations.createProjectPage.archi, 'Archi '),
  design: () => __(translations.createProjectPage.design, 'Design '),
  addNewCompanyClient: () => __(translations.createCustomerOrderPage.addNewCompanyClient, 'Add Company '),
  addNewPrivateClient: () => __(translations.createCustomerOrderPage.addNewPrivateClient, 'Add Private '),

  // project types
  archi_residence: () => __(translations.createProjectPage.archi_residence, 'Residences'),
  archi_hotel_restaurant: () => __(translations.createProjectPage.archi_hotel_restaurant, 'Hotels & restaurants'),
  archi_retail: () => __(translations.createProjectPage.archi_retail, 'Retail'),
  scenography: () => __(translations.createProjectPage.scenography, 'Scenography'),
  design_collaborations: () => __(translations.createProjectPage.design_collaborations, 'Design'),
  petits_projets: () => __(translations.createProjectPage.petits_projets, 'Petits projets'),
};
