import React, {KeyboardEvent, FC, useEffect} from 'react';
import messages from '../messages';
import FormSectionSkeletonoader from './formSectionSkeleton';
import {useEditPasswordUserForm, useEditUserForm} from './use-store-user-form';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Alert, Col, Row} from 'reactstrap';
import {useShowUserQuery} from 'store/users/endpoints';
import {ObjectId} from 'types/object-id';
import {Role} from 'types/role';

interface Props {
  id: ObjectId;
}

const FormSection: FC<Props> = props => {
  const {
    submit,
    params,
    validationErrors,
    isLoading: isSubmitting,
    errorMessage,
    isSuccess,
    setParams,
  } = useEditUserForm();

  const {
    submit: submitPassword,
    params: paramsPassword,
    validationErrors: validationErrorsPassword,
    isLoading: isSubmittingPassword,
    errorMessage: errorMessagePassword,
    isSuccess: isSuccessPassword,
    setParams: setParamsPassword,
  } = useEditPasswordUserForm();

  const {isLoading, data} = useShowUserQuery({id: props.id});
  useEffect(() => {
    if (data && data.data) {
      const result = data.data;
      setParams(params => ({
        ...params,
        id: result.id,
        first_name: result.profile ? result.profile.first_name : '',
        last_name: result.profile ? result.profile.last_name : '',
        phone_number: result.profile ? result.profile.phone_number : '',
        email: result.email,
        role: result.role,
      }));
      setParamsPassword(params => ({
        ...paramsPassword,
        id: result.id,
      }));
    }
  }, [data]);

  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return isLoading ? (
    <FormSectionSkeletonoader />
  ) : (
    <Row className="custom-form mt-4 px-2 pt-2" style={{marginBottom: '150px'}}>
      <Col>
        <h2>{t(...messages.personalInformation())}</h2>
        <div className="row mb-4">
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={first_name => setParams({...params, first_name})}
            value={params.first_name}
            label={t(...messages.firstName())}
            errors={validationErrors.first_name}
          />
        </div>

        <div className="row mb-4">
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={last_name => setParams({...params, last_name})}
            value={params.last_name}
            label={t(...messages.lastName())}
            errors={validationErrors.last_name}
          />
        </div>
        <div className="row mb-4">
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={email => setParams({...params, email})}
            value={params.email}
            label={t(...messages.email())}
            errors={validationErrors.email}
          />
        </div>
        <div className="row mb-4">
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={phone_number => setParams({...params, phone_number})}
            value={params.phone_number}
            label={t(...messages.phone())}
            errors={validationErrors.phone_number}
          />
        </div>
        <div className="row mb-4">
          <AsyncItemPicker
            endPoint="data/roles"
            className="mb-1"
            autoComplete="off"
            label={t(...messages.role())}
            getOptionLabel={(r: Role) => r.label}
            value={params.role}
            errors={validationErrors.role_id}
            onChange={role => setParams({...params, role})}
          />
        </div>

        <div className="row mb-4">
          <Row md={2}>
            <Col>
              <div className="mt-3 d-grid">
                <Button
                  isLoading={isLoading}
                  className="btn btn-light btn-block"
                  onClick={() => navigate('/dashboard/users')}
                >
                  {t(...messages.cancel())}
                </Button>
              </div>
            </Col>
            <Col>
              <div className="mt-3 d-grid">
                <Button
                  color="important"
                  isLoading={isSubmitting}
                  className="btn  btn-block"
                  onClick={submit}
                >
                  {t('common.saveChanges')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <h2>{t(...messages.security())} </h2>
        <div className="mb-3">
          <TextInput
            autoComplete="new-password"
            onChange={password =>
              setParamsPassword({...paramsPassword, password})
            }
            value={paramsPassword.password}
            onKeyDown={handleKeyboardEventDown}
            label={t(...messages.password())}
            errors={validationErrorsPassword.password}
            type="password"
          />
        </div>
        <div className="mb-3">
          <TextInput
            autoComplete="new-password"
            onChange={password_confirmation =>
              setParamsPassword({...paramsPassword, password_confirmation})
            }
            value={paramsPassword.password_confirmation}
            onKeyDown={handleKeyboardEventDown}
            label={t(...messages.passwordConfirmation())}
            errors={validationErrorsPassword.password_confirmation}
            type="password"
          />
          <div className="mt-3">
            {errorMessagePassword && (
              <Alert color="danger">{errorMessagePassword}</Alert>
            )}
          </div>
          <div className="mt-3">
            {isSuccessPassword && (
              <Alert color="success">{t('alertCloak.changesCommited')}</Alert>
            )}
          </div>
        </div>
        <Row md={2}>
          <Col>
            <div className="mt-3 d-grid">
              <Button
                className="btn btn-light btn-block"
                onClick={() => navigate('/dashboard/users')}
              >
                {t(...messages.cancel())}
              </Button>
            </div>
          </Col>
          <Col>
            <div className="mt-3 d-grid">
              <Button
                color="important"
                isLoading={isSubmittingPassword}
                className="btn  btn-block"
                onClick={submitPassword}
              >
                {t('common.saveChanges')}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormSection;
