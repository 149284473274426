import React, {FC, useMemo, useState} from 'react';
import OrderSectionByType from '../../components/order-section-by-type';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {MODAL} from 'features/modal-notch/constants';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import showModal from 'store/ui/actions/show-modal';
import {ObjectId} from 'types/object-id';
import {useValidateOrderMutation} from '../../store/endpoints/action-order-validate';
import {Order} from '../../types/order';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import toast from 'store/ui/actions/toast';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import denormalizeObject from 'utils/denormalize-object';
import {PaymentMethod} from 'features/commercial/documents/types/payment-method';
import Can from 'features/hylian-shield/can';
import {Subject} from 'features/hylian-shield/types';
interface Props {
  order: Order;
}

const ActionsTab: FC<Props> = props => {
  const {order} = props;
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [validateOrder, {isLoading, error}] = useValidateOrderMutation();
  const serverError = parseValidationErrors(error);

  const dispatch = useDispatch();

  const {id} = useParams();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [transferDropDown, setTransferDropDown] = useState(false);

  const toggle = () => setDropdownOpen(s => !s);

  const handleValidation = async () => {
    const result = await validateOrder({id: order.id});
    if ('error' in result) {
      dispatch(toast('error', serverError.message));
    } else if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const handleDuplicat = () => {
    dispatch(
      showModal(MODAL.ORDER_DUPLICAT_ACTIONS_MODAL, {
        id: id,
        message: t(...messages.duplicateAlertMessage()),
        onSuccess: (id: ObjectId) => {
          navigate(`/dashboard/orders/${id}/details`);
        },
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      showModal(MODAL.ORDER_DELETE_ACTIONS_MODAL, {
        id: id,
        onSuccess: () => {
          navigate(`/dashboard/orders/`);
        },
        message: t(...messages.deleteAlertMessage()),
      })
    );
  };
  const handleTransfer = (to: string) => {
    if (to === '2') {
      dispatch(
        showModal(MODAL.TRANSFER_ORDER_MODAL, {
          id,
          onSuccess: (id: ObjectId) => {
            navigate(`/dashboard/orders/${id}/details`);
          },
        })
      );
    } else {
      dispatch(
        showModal(MODAL.TRANSFER_TO_SUPPLIER_ORDER_MODAL, {
          id,
          onSuccess: (id: ObjectId) => {
            navigate(`/dashboard/orders/${id}/details`);
          },
        })
      );
    }
  };

  const handleTransferStockOrder = () => {
    dispatch(
      showModal(MODAL.TRANSFER_STOCK_ORDER_MODAL, {
        id,
        onSuccess: (id: ObjectId) => {
          navigate(`/dashboard/orders/${id}/details`);
        },
      })
    );
  };
  const handleSendEmail = () => {
    dispatch(
      showModal(MODAL.ORDER_SEND_EMAIL_ACTIONS_MODAL, {
        id: id,
      })
    );
  };
  const handleArchive = () => {
    dispatch(
      showModal(MODAL.ORDER_ARCHIVE_ACTIONS_MODAL, {
        id: id,
      })
    );
  };

  const handleSendLabel = () => {
    dispatch(
      showModal(MODAL.ORDER_SEND_LABEL_ACTIONS_MODAL, {
        id: id,
        onSuccess: () => {},
      })
    );
  };
  const handlePreparer = () => {
    dispatch(
      showModal(MODAL.PREPERER_ORDER, {
        id: id,
      })
    );
  };

  const handleImportPayment = (type: PaymentMethod) => {
    const typeOption = {
      value: type,
      label: t(`paymentPickerModal.${type?.toLocaleLowerCase()}`),
    };
    dispatch(
      showModal(MODAL.PAYMENT_PICKER_MODAL, {
        type: typeOption,
        directory_id: order?.directory_id,
        model_type: order.document_type,
        model_id: order?.id,
      })
    );
  };

  const cannotTransfer = useMemo(() => {
    const items = denormalizeObject(order.commercial_products_objectified);
    return items.length < 1;
  }, [order.commercial_products_objectified]);

  return (
    <div className="order-1 d-flex align-items-center">
      <Button
        onClick={handleValidation}
        isLoading={isLoading}
        color="light mx-2"
      >
        {order?.status === 'VALIDATED' ? (
          <>
            <Icon name="unlock" size={15} className="me-2" />
            {t('common.unlock')}
          </>
        ) : (
          <>
            <Icon name="lock" size={15} className="me-2" />
            {t('common.validate')}
          </>
        )}
      </Button>
      <Can
        subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
        action="print"
      >
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="btn-light me-2" caret>
            {t('commercialDocumentComponents.print')}
            <Icon name="chevron-down" className="ms-1" size={15} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <>
              {' '}
              <li>
                <DropdownItem target={'_blank'} href={order?.pdf_links?.fr}>
                  {t(...messages.exportOrderFr())}
                </DropdownItem>
              </li>
              <li>
                <DropdownItem target={'_blank'} href={order?.pdf_links?.en}>
                  {t(...messages.exportOrderEn())}
                </DropdownItem>
              </li>
            </>
            <li>
              <DropdownItem
                target={'_blank'}
                href={order?.pdf_links?.packing_list_fr}
              >
                {t(...messages.exportPackingListFr())}
              </DropdownItem>
            </li>
            <li>
              <DropdownItem
                target={'_blank'}
                href={order?.pdf_links?.packing_list_en}
              >
                {t(...messages.exportPackingListEn())}
              </DropdownItem>
            </li>
          </DropdownMenu>
        </Dropdown>
      </Can>
      <Can
        subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
        action="duplicate"
      >
        <Button
          color="light"
          className="text-nowrap me-2"
          onClick={handleDuplicat}
        >
          <Icon name="copy" size={15} className="me-2" />
          {t(...messages.duplicateOrder())}
        </Button>
      </Can>
      <Can
        subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
        action="send"
      >
        <Button color="light" onClick={handleSendEmail} className="me-2">
          <Icon name="send" size={15} className="me-2" />
          {t(...messages.sendEmailOrder())}
        </Button>
      </Can>

      <OrderSectionByType orderTypeToDisplay={['SUPPLIER']}>
        <Can
          subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
          action="send"
        >
          {order?.commercial_type?.code !== 'CFZ' ? (
            <Button color="light" onClick={handleSendLabel} className="me-2">
              <Icon name="share" size={15} className="me-2" />
              {t(...messages.sendLabelOrder())}
            </Button>
          ) : null}
        </Can>
      </OrderSectionByType>
      <OrderSectionByType orderTypeToDisplay={['CLIENT']}>
        <Can
          subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
          action="transfer"
        >
          <Dropdown
            disabled={cannotTransfer}
            isOpen={transferDropDown}
            toggle={() => setTransferDropDown(!transferDropDown)}
          >
            <DropdownToggle
              disabled={cannotTransfer}
              className="btn-light me-2"
              caret
            >
              {t(...messages.transfer())}
              <Icon name="chevron-down" className="ms-1" size={15} />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <li>
                <DropdownItem onClick={() => handleTransfer('1')}>
                  {t(...messages.supplierOrder())}
                </DropdownItem>
              </li>
              <li>
                <DropdownItem onClick={() => handleTransfer('2')}>
                  {t(...messages.invoice())}
                </DropdownItem>
              </li>
            </DropdownMenu>
          </Dropdown>
        </Can>
        <Can
          subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
          action="prepare"
        >
          <Button
            color="light"
            className="text-nowrap me-2"
            onClick={handlePreparer}
          >
            <Icon name="box" size={15} className="me-2" />
            {t(...messages.prepareOrder())}
          </Button>
        </Can>
      </OrderSectionByType>
      <OrderSectionByType orderTypeToDisplay={['STOCK']}>
        {' '}
        <Can
          subject={order.commercial_type?.code?.toLocaleLowerCase() as Subject}
          action="transfer"
        >
          <Dropdown
            disabled={cannotTransfer}
            isOpen={transferDropDown}
            toggle={() => setTransferDropDown(!transferDropDown)}
          >
            <DropdownToggle
              disabled={cannotTransfer}
              className="btn-light me-2"
              caret
            >
              {t(...messages.transfer())}
              <Icon name="chevron-down" className="ms-1" size={15} />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <li>
                <DropdownItem onClick={() => handleTransferStockOrder()}>
                  {t(...messages.supplierOrder())}
                </DropdownItem>
              </li>
            </DropdownMenu>
          </Dropdown>
        </Can>
      </OrderSectionByType>
      <Button color="light" onClick={handleArchive} className="me-2">
        <Icon name="folder" size={15} className="me-2" />
        {t('common.archive')}
      </Button>

      {order?.document_type === 'DEFAULT' ||
      order?.document_type === 'CLIENT' ? (
        <>
          <Can
            subject={
              order.commercial_type?.code?.toLocaleLowerCase() as Subject
            }
            action="credits-overpayments-use"
          >
            <Button
              onClick={() => handleImportPayment('OVERPAYMENT')}
              disabled={(order?.directory?.overpayment_count ?? 0) < 1}
              color="light"
              className="me-2"
            >
              <Trans i18nKey={`addPaymentDropdown.overpayment`} />{' '}
              {order?.directory && (
                <Badge
                  color={`${
                    order.directory.overpayment_count > 0 ? 'success' : 'dark'
                  }`}
                >
                  {order.directory.overpayment_count}
                </Badge>
              )}
            </Button>
          </Can>
          <Can
            subject={
              order.commercial_type?.code?.toLocaleLowerCase() as Subject
            }
            action="credits-overpayments-use"
          >
            <Button
              onClick={() => handleImportPayment('CREDIT')}
              disabled={(order?.directory?.credit_count ?? 0) < 1}
              color="light"
            >
              <Trans i18nKey={`addPaymentDropdown.credit`} />{' '}
              {order?.directory && (
                <Badge
                  color={`${
                    order.directory.credit_count > 0 ? 'success' : 'dark'
                  }`}
                >
                  {order.directory.credit_count}
                </Badge>
              )}
            </Button>
          </Can>
        </>
      ) : null}
    </div>
  );
};

export default ActionsTab;
