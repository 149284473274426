import React from 'react';
import messages from './messages';
import {useTranslationsForm} from './use-translations-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {
  Row,
  Col,
  Alert,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {useGetLanguagesQuery} from 'store/global/endpoints/get-languages';
import {selectVariant} from 'store/production/endpoints/show-variant';
import Can from 'features/hylian-shield/can';

const TranslationsTab = () => {
  const {
    addTranslation,
    errorMessage,
    isLoading,
    isSuccess,
    removeTranslation,
    translations,
    updateTranslation,
    submit,
    validationErrors,
  } = useTranslationsForm();

  const {t} = useTranslation();
  const {id, variant} = useParams();

  const {data: variantdata} = useSelector(
    selectVariant({id: variant, productId: id})
  );

  const {data} = useGetLanguagesQuery();

  const langs = data?.data ?? [];

  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: variantdata?.data?.name?.toLocaleUpperCase(),
        })}
      />

      {Object.values(translations).map((p, i) => (
        <Row className="mb-3" key={i}>
          <Col md={2}>
            <TextInput
              label={t(...messages.language())}
              onChange={() => {}}
              disabled
              className="text-capitalize "
              value={p.lang?.label}
            />
          </Col>
          <Col md={3}>
            <TextInput
              label={t(...messages.label())}
              type="text"
              errors={validationErrors[`translations.${i}.label`]}
              value={p.label}
              onChange={x => updateTranslation(p, 'label', x)}
            />
          </Col>
          <Col md={3}>
            <TextInput
              label={t(...messages.finishes())}
              type="text"
              value={p.finition}
              onChange={x => updateTranslation(p, 'finition', x)}
              errors={validationErrors[`translations.${i}.finition`]}
            />
          </Col>
          <Col md={3}>
            <TextInput
              label={t(...messages.description())}
              type="textarea"
              value={p.description}
              onChange={x => updateTranslation(p, 'description', x)}
              errors={validationErrors[`translations.${i}.description`]}
            />
          </Col>

          <Col className="pt-3" md={1}>
            {p.lang?.code !== 'fr' && (
              <Can action="edit" subject="products">
                <Button
                  color="link"
                  className="text-danger"
                  onClick={() => removeTranslation(p.lang?.code)}
                >
                  <Icon name="trash-2" size={18} />
                </Button>
              </Can>
            )}
          </Col>
        </Row>
      ))}

      <Can action="edit" subject="products">
        <Row className="mb-3">
          <Col md={4}>
            <ButtonGroup className="my-2">
              <ButtonGroup>
                <UncontrolledDropdown>
                  <DropdownToggle caret color="light">
                    {t(...messages.addTranslation())}
                    <Icon name="chevron-down" className="ms-1" size={15} />
                  </DropdownToggle>
                  <DropdownMenu>
                    {langs.map(x =>
                      !translations[x.code] ? (
                        <DropdownItem
                          onClick={() => addTranslation(x)}
                          key={x.code}
                        >
                          {x.label}
                        </DropdownItem>
                      ) : null
                    )}
                  </DropdownMenu>
                  {langs?.length === Object.keys(translations ?? {}).length ? (
                    <DropdownMenu>
                      <DropdownItem disabled>
                        {t(...messages.nothing())}
                      </DropdownItem>
                    </DropdownMenu>
                  ) : null}
                </UncontrolledDropdown>
              </ButtonGroup>
            </ButtonGroup>
          </Col>
        </Row>
      </Can>
      <Can action="edit" subject="products">
        <AppBarActions>
          <Button isLoading={isLoading} color="important" onClick={submit}>
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </AppBarActions>
      </Can>
    </div>
  );
};

export default TranslationsTab;
