/**
 * Async item picker
 *
 */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FC} from 'react';
import {AsyncItemPickerProps} from './types';
import {debounce} from 'lodash';
import {useSelector} from 'react-redux';
import AsyncSelect from 'react-select/async';
import {Input, Label} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import {useLazyGetAsyncDataQuery} from 'store/ui/endpoints/get-async-data';
import {Option} from 'types/option';
import serializeParams from 'utils/serialize-params';
import useInputId from 'utils/use-input-id';
import ItemPicker from 'components/item-picker';

const AsyncItemPicker: FC<AsyncItemPickerProps> = props => {
  const {
    endPoint,
    optionLabel,
    value = null,
    onChange,
    disabled,
    errors,
    required,
    method = 'GET',
    id: inputId,
    valueOnly,
    zIndex,
    getOptionValue = (v: Option) => v?.id,
    getDefaultOptions = (meta: any) => meta?.default,
    label,
    defaultValue,
    loadOnClick,
    otherParams,
    menuPlacement,
    containerClassName,
    inputClassName,
    ...rest
  } = props;

  const [fetchData] = useLazyGetAsyncDataQuery();

  const handleLoadOptions = useCallback(
    debounce((query: string, callBack: any) => {
      if (disabled) {
        callBack([]);
        return;
      }
      const params = {
        ...otherParams,
        search: query,
      };
      fetchData({
        url: serializeParams(endPoint, params),
        method,
      })
        .then(res => {
          callBack(res.data?.data);
          if (defaultValue && query.length === 0) {
            setSelectedDefaultValue(getDefaultOptions(res.data?.meta));
          }
        })
        .catch(() => {
          // do nothing
        });
    }, 300),
    []
  );
  const appTheme = useSelector(selectAppTheme);
  const textColorSingle = appTheme === 'dark' ? '#fff' : '#000';

  const selectThemeColors = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--bs-primary)', // for selected option bg-color
        neutral10: 'var(--bs-primary)', // for tags bg-color
        neutral20: 'var(--bs-border-color)', // for input border-color
        neutral30: 'var(--bs-primary)', // for input hover border-color
        neutral0: appTheme === 'dark' ? 'var(--bs-input-bg)' : '#fff', // used
        neutral80: props.isMulti ? '#fff' : textColorSingle, // text color
      },
    };
  };

  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState<
    Option | undefined
  >(undefined);

  const handleChange = (option: Option, action: any) => {
    if (valueOnly) {
      setSelectedValue(option);
      if (props.isMulti && option) {
        // if you want to change id attribute, this is the right place to do it
        // preferrable, by passing getOptionValue prop
        // @ts-ignore
        onChange(option.map((x: Option) => x.id).join('|'), action);
      } else {
        onChange(getOptionValue(option), action);
      }
    } else {
      onChange(option, action);
    }
  };
  const [open, setOpen] = useState(loadOnClick);
  useEffect(() => {
    if (defaultValue) {
      handleChange(selectedDefaultValue as Option, 'select-option');
    }
  }, [selectedDefaultValue, defaultValue]);
  const boundValue = useMemo(() => {
    if (value === null || !value) {
      return null;
    }
    return selectedValue;
  }, [value, selectedValue]);

  const id = useInputId(inputId);
  //this part is fix the click bug which appeared with
  // 798-enhacement-make-the-asyncitempicker-load-data-only-onclick merge
  const inputRef = useRef(null);
  useEffect(() => {
    if (loadOnClick && !open) {
      // @ts-ignore
      inputRef?.current?.focus();
    }
  }, [open]);
  return (
    <div className={containerClassName}>
      <span className="text-danger">{required && '* '}</span>
      <Label className="mb-0" htmlFor={id}>
        {label}
      </Label>

      {open ? (
        <ItemPicker
          // @ts-ignore
          options={[]} // this is a dummy value
          onChange={() => {}}
          onClick={() => {
            setOpen(!open);
            // @ts-ignore
          }}
          theme={selectThemeColors}
          className="react-select"
          classNamePrefix="select"
          styles={{
            // @ts-ignore
            menu: provided => ({...provided, zIndex: 2001}),
            // @ts-ignore
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errors?.length ? 'red' : baseStyles.borderColor,
            }),
            // @ts-ignore
            input: baseStyles => ({
              ...baseStyles,

              color: textColorSingle,
            }),
          }}
          // @ts-ignore
          getOptionValue={x => _.get(x, 'name')}
          // @ts-ignore
          value={boundValue}
          // @ts-ignore
          getOptionLabel={x => x?.name}
          isDisabled={disabled}
          isClearable
          {...rest}
        />
      ) : (
        <AsyncSelect
          inputId={id}
          ref={inputRef}
          theme={selectThemeColors}
          className="react-select bg-gray-light"
          classNamePrefix="select"
          onChange={handleChange}
          defaultMenuIsOpen={loadOnClick}
          menuPlacement={menuPlacement ?? 'auto'}
          classNames={{
            control: state => inputClassName,
          }}
          styles={{
            menu: provided => ({
              ...provided,
              zIndex: zIndex ?? 2001,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errors?.length ? 'red' : baseStyles.borderColor,
            }),
            input: baseStyles => ({
              ...baseStyles,

              color: textColorSingle,
            }),
          }}
          cacheOptions
          defaultOptions
          getOptionLabel={v => v.name}
          getOptionValue={getOptionValue}
          value={valueOnly ? boundValue : value}
          isDisabled={disabled}
          defaultValue={defaultValue}
          isClearable
          loadOptions={handleLoadOptions}
          {...rest}
        />
      )}

      {errors ? <div className="invalid-feedback d-block">{errors}</div> : null}
    </div>
  );
};

AsyncItemPicker.defaultProps = {
  optionLabel: 'name',
  otherParams: {},
};

export default AsyncItemPicker;
