import {useCallback, useMemo, useEffect} from 'react';
import {actions} from '../slice';
import loadData from '../slice/actions/load-data';
import toggleSelection from '../slice/actions/toggle-selection';
import selectDestroyedResourcesCount from '../slice/selectors/select-destroyed-resources-count';
import selectEntity from '../slice/selectors/select-entity';
import selectSelectedRows from '../slice/selectors/select-selected-rows';
import {ListParams} from '../types';
import useSortColumns from './use-sort-columns';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {useDispatch, useSelector} from 'react-redux';
import {ResourceName} from 'types/resource-name';
import useAfterLife from 'features/modal-notch/soul-eater/use-after-life';

const useInitiateTable = (
  name: ResourceName,
  url: string,
  columns: ColumnDef<any>[],
  otherParams?: ListParams
) => {
  const dispatch = useDispatch();

  const {data, meta, filters} = useSelector(selectEntity(name));

  const eatenSoulsCount = useSelector(selectDestroyedResourcesCount(name));

  const {sort, setSort} = useSortColumns(name, url, otherParams);

  const rowSelection = useSelector(selectSelectedRows(name));
  const handleSelectionChange = (selectedRowIds: any) => {
    dispatch(toggleSelection(name, selectedRowIds(rowSelection)));
  };
  useEffect(() => {
    dispatch(loadData({url, name, otherParams}));
  }, [name, url, eatenSoulsCount]);

  const memorizedColumns = useMemo(() => columns, [columns]);
  const {saints} = useAfterLife(name, data);
  const table = useReactTable({
    data: saints || [],
    columns: memorizedColumns,
    state: {
      sorting: sort,
      rowSelection,
    },
    enableRowSelection: true,
    manualSorting: true,
    onRowSelectionChange: handleSelectionChange,
    manualPagination: true,

    onSortingChange: setSort,
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row: any) => row.id,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
    meta,
    filters,
  };
};

export default useInitiateTable;
