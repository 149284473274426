import React, {FC} from 'react';
import {Fragment} from 'react';
import messages from '../master-details/messages';
import clsx from 'clsx';
import {STATUS} from 'constants/status';
import {Trans, useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {Row, Nav, NavItem} from 'reactstrap';
import {User} from 'types/user';

interface Props {
  user: User;
}

const GeneralInfoSection: FC<Props> = props => {
  const {t} = useTranslation();

  const {user} = props;
  const profile = user.profile;
  const status =
    user.status === 'ENABLED'
      ? t(...messages.enabled())
      : t(...messages.disabled());

  const [avatar, fallbackAvatar] = [
    profile?.avatar?.square,
    'https://placehold.co/400x400',
  ];

  return (
    <Fragment>
      <Row>
        <div className="col-sm order-2 order-sm-1">
          <div className="d-flex align-items-start mt-3 mt-sm-0">
            <div className="flex-shrink-0">
              {/* CONTENT */}
              <div className="avatar-upload">
                <div className="avatar-preview">
                  <img
                    className="profile-user-img img-fluid rounded-circle d-block"
                    id="imagePreview"
                    src={avatar ?? fallbackAvatar}
                    alt="Profile picture"
                  />
                </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div>
                <h5 className="font-size-16 mb-1">
                  {`${profile?.first_name} ${profile?.last_name}`}
                </h5>

                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                  <div>
                    <i
                      className={clsx({
                        'mdi mdi-circle-medium me-1  align-middle': true,
                        'text-success': user.status === STATUS.ENABLED,
                        'text-danger': user.status === STATUS.DISABLED,
                      })}
                    ></i>
                    {status}
                  </div>
                  <div>
                    <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                    {user.role?.label}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};
interface NavProps {
  user?: User;
}

export const UserNav: FC<NavProps> = props => {
  const {user} = props;
  return (
    <Row>
      <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
        <NavItem>
          <NavLink
            to={user ? `/dashboard/users/${user?.id}/profile` : '#'}
            className={active =>
              clsx({active: user && active.isActive}, 'px-3 nav-link')
            }
          >
            <Trans i18nKey="profileTab.title" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={user ? `/dashboard/users/${user?.id}/permission` : '#'}
            className={active =>
              clsx({active: user && active.isActive}, 'px-3 nav-link')
            }
          >
            <Trans i18nKey="prmissionTab.title" />
          </NavLink>
        </NavItem>
      </Nav>
    </Row>
  );
};

export default GeneralInfoSection;
