import React, {FC, useState} from 'react';
import messages from './messages';
import PropTypes from 'prop-types';
import {useTranslation, withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {AppDispatch} from 'store';
import purgeAuthState from 'store/auth/actions/purge-auth-state';
import {selectProfile, selectUser} from 'store/auth/selectors';

interface Props {}

const ProfileMenu: FC<Props> = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const profile = useSelector(selectProfile);

  const user = useSelector(selectUser);

  const {t} = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const handleLogout = () => {
    dispatch(purgeAuthState());
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profile?.avatar?.square}
            alt=""
          />

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <Link to={'/account/profile'} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />{' '}
            {t(...messages.profile())}
          </Link>
          <Link to="/account/settings" className="dropdown-item">
            <i className="bx bx-wrench font-size-16 align-middle me-1" />{' '}
            {t(...messages.settings())}
          </Link>
          <div className="dropdown-divider" />
          <Link to="#" onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t(...messages.logout())}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
