import React from 'react';
import StemDirectory from './stem-directory';
import ActionsTab from './tabs/actions';
import AddressesTab from './tabs/addresses';
import AttachmentsTab from './tabs/attachments';
import ComplementaryInfoTab from './tabs/complementary-info';
import ContactListTab from './tabs/contact-list';
import CoordinatesTab from './tabs/coordinate';
import GeneralInfoPage from './tabs/general';
import InteractionsTab from './tabs/interactions/interactions-tab';
import LinkedResourcesTab from './tabs/linked-resources';
import PricingComponentsTab from './tabs/variant-components';
import {Route, Routes} from 'react-router';

const DirectoryDetailsRouter = () => {
  return (
    <Routes>
      <Route path="/addresses" element={<AddressesTab />} />
      <Route path="/attachments" element={<AttachmentsTab />} />
      <Route path="/complementary-info" element={<ComplementaryInfoTab />} />
      <Route path="/contact-list" element={<ContactListTab />} />
      <Route path="/coordinates" element={<CoordinatesTab />} />
      <Route path="/general" element={<GeneralInfoPage />} />
      <Route path="/interactions" element={<InteractionsTab />} />
      <Route path="/linked-resources" element={<LinkedResourcesTab />} />
      <Route path="/pricing" element={<PricingComponentsTab />} />
      <Route path={`/`} element={<StemDirectory />} />
    </Routes>
  );
};

export default DirectoryDetailsRouter;
