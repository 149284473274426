import {SupplierGroup} from 'features/stock/types/supplier-group';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

const getSupplierGroups = api.injectEndpoints({
  endpoints: builder => ({
    getSupplierGroups: builder.query<Response, GetSupplierGroupsRequest>({
      query: () => {
        return {
          method: 'GET',
          url: `calendar/suppliers`,
        };
      },
    }),
  }),
});

export type GetSupplierGroupsRequest = void;

type Response = ResponseData<SupplierGroup[]>;

export const {useGetSupplierGroupsQuery} = getSupplierGroups;
