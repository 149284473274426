import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import {Payment} from 'types/payment';
import PaymentsActions from './controls/actions';
import DocumentCell from 'features/acounting/payments/components/document-cell';

const columns: ColumnDef<Payment>[] = [
  {
    header: r => <Trans i18nKey="paymentsPage.invoiceNumber" />,
    accessorFn: row => {
      const link =
        row.model_type &&
        (row.model_type.toLowerCase() === 'invoice'
          ? `/dashboard/invoices/${row.model_id}/details`
          : row.model_type.toLowerCase() === 'order'
          ? `/dashboard/orders/${row.model_id}/details`
          : `/dashboard/quotes/${row.model_id}/details`);

      return (
        <div className=" py-1">
          <Link
            to={link ?? `/dashboard/invoices/${row.model_id}/details`}
            target="_blank"
          >
            <b>{row.model_document_ref}</b>
          </Link>
        </div>
      );
    },

    cell: r => r.getValue(),

    id: 'model_document_ref',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="paymentsPage.relatedDocument" />,
    accessorFn: row =>
      row.related_documents && row.related_documents.length > 0 ? (
        <div className="d-flex w-100  flex-column ">
          {row.related_documents.map(item => (
            <DocumentCell cell={item} key={item.model_id} />
          ))}
        </div>
      ) : (
        <span className="w-100 text-center">-</span>
      ),
    id: 'relatedDocument',
    cell: r => r.getValue(),

    enableSorting: false,
  },
  {
    header: r => <Trans i18nKey="paymentsPage.amount" />,
    accessorFn: row => (
      <Trans i18nKey="numbers.currency" values={{val: row.amount}} />
    ),
    id: 'amount',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="paymentsPage.paymentType" />,
    accessorFn: row => <Trans i18nKey={`paymentsPage.${row.type}`} />,
    id: 'paymentType',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="paymentsPage.paymentMethod" />,
    accessorFn: row => <Trans i18nKey={`paymentsPage.${row.payment_method}`} />,
    enableSorting: true,
    id: 'paymentMethod',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="paymentsPage.paymentDate" />,
    accessorFn: row => new Date(row.date as string).toLocaleDateString(),
    enableSorting: true,
    id: 'paymentDate',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="productsPage.action" />,
    accessorFn: row => <PaymentsActions {...row} />,
    id: 'reconciled_payment',
    enableSorting: true,
    cell: r => r.getValue(),
  },
];

export default columns;
