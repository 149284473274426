import {authApi} from '../endpoints';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {MutationThunkArg} from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {WritableDraft} from 'types/writable-draft';

const verifyPasswordResetTokenMatcher =
  authApi.endpoints.verifyPasswordResetToken.matchFulfilled;

/**
 * Verify password reset token
 *
 */
const verifyPasswordResetTokenReducer = (
  state: WritableDraft<AuthState>,
  action: ActionType
) => {
  state.resetPassword.resetToken = action.meta.arg.originalArgs.token;
};

export const verifyPasswordResetToken = {
  matcher: verifyPasswordResetTokenMatcher,
  reducer: verifyPasswordResetTokenReducer,
};

type ActionType = PayloadAction<
  unknown,
  string,
  {
    arg: MutationThunkArg & {
      originalArgs: {
        token?: string;
      };
    };
    requestId: string;
    requestStatus: 'fulfilled';
  } & {
    fulfilledTimeStamp: number;
    baseQueryMeta: unknown;
  },
  never
>;
