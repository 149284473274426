import {DocumentType} from 'features/commercial/documents/types/commercial-document';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

export const getOrderDefaultMailContent = api.injectEndpoints({
  endpoints: builder => ({
    getOrderDefaultMailContent: builder.query<Response, Request>({
      query: ({type, local}) => {
        const url = `/data/default-mail-content/${
          type === 'CLIENT' ? 'for-order' : 'for-supplier-order'
        }`;
        return {
          method: 'GET',
          url,
          params: {
            local,
          },
        };
      },
    }),
  }),
});

type Request = {
  local: string;
  type: DocumentType;
};

type Response = ResponseData<{content: string}>;

export const {useGetOrderDefaultMailContentQuery} = getOrderDefaultMailContent;
