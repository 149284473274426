import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.ordersRelatedPartsTab.title, 'Related documents'),
  code: () => __(translations.ordersRelatedPartsTab.code, 'Code'),
  client: () => __(translations.ordersRelatedPartsTab.client, 'Client'),
  supplier: () => __(translations.ordersRelatedPartsTab.supplier, 'Supplier'),
  date: () => __(translations.ordersRelatedPartsTab.date, 'Date'),
  totalExcl: () => __(translations.ordersRelatedPartsTab.totalExcl, 'Total excl taxes'),
  status: () => __(translations.ordersRelatedPartsTab.status, 'Status'),
  unlink: () => __(translations.ordersRelatedPartsTab.unlink, 'Unlink'),
  linkToSupplierOrder: () => __(translations.ordersRelatedPartsTab.linkToSupplierOrder, 'Link to supplier order'),
  changesCommited: () => __(translations.ordersRelatedPartsTab.changesCommited, 'Your changes has been saved successfully!'),
};
