import React from 'react';
import DetailsTab from '../../containers/details-tab/details-tab';
import GeneralInfoTab from '../general-info-tab/general-info-tab';
import LogsTab from '../logs-tab/logs-tab';
import RelatedResourcesTab from '../related-resources-tab/related-resources-tab';
import {Route, Routes} from 'react-router';

const OrderDetailsRouter = () => {
  return (
    <Routes>
      <Route path="/general" element={<GeneralInfoTab />} />
      <Route path="/details" element={<DetailsTab />} />
      <Route path="/related-resources" element={<RelatedResourcesTab />} />
      <Route path="/logs" element={<LogsTab />} />
      <Route path={`/`} element={<div>Nothing…</div>} />
    </Routes>
  );
};

export default OrderDetailsRouter;
