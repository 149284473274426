import React, {useMemo} from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {
  Alert,
  Col,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import AsyncTagSelect from 'components/async-tag-select/async-tag-select';
import {useExportPatrimoine} from './use-export-patrimoine';
import ItemPicker from 'components/item-picker';

const ExportPatrimoinePerLocalisationForm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    languages,
    setParams,
    errorMessage,
    validationErrors,
  } = useExportPatrimoine();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Row>
            <Col>
              <div className="mb-3">
                <ItemPicker
                  onKeyDown={handleKeyboardEventDown}
                  options={languages}
                  onChange={o => setParams({...params, locale: o})}
                  label={t(...messages.langue())}
                  value={params.locale}
                  errors={validationErrors.locale}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <FormGroup>
              <AsyncTagSelect
                key={`product-families-${8}`}
                endPoint="data/product-families"
                otherParams={{product_collections: 8}}
                className="mb-1"
                label={t('productsPage.familie')}
                value={params['filter[product_family_name]']}
                isMulti
                onChange={o =>
                  setParams({...params, 'filter[product_family_name]': o})
                }
                errors={validationErrors['filter.product_family_name']}
              />
            </FormGroup>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <AsyncItemPicker
                  onKeyDown={handleKeyboardEventDown}
                  endPoint="data/stock-locations"
                  onChange={o =>
                    setParams({...params, 'filter[localisations]': o})
                  }
                  label={t(...messages.stockLocalisation())}
                  value={params['filter[localisations]']}
                  isMulti
                  errors={validationErrors['filter.localisations']}
                />
              </div>
            </Col>
          </Row>

          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          {t(messages.export())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default ExportPatrimoinePerLocalisationForm;
