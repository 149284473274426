import {useState} from 'react';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {getDeviceId} from 'services/auth-service';
import {useLoginMutation} from 'store/auth/endpoints';

const {REACT_APP_TEST_EMAIL, REACT_APP_TEST_PASSWORD} = process.env;

const __DEV__ = process.env.NODE_ENV === 'development';

export const useLoginForm = () => {
  const [login, {isLoading, error}] = useLoginMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState({
    email: __DEV__ ? REACT_APP_TEST_EMAIL : '',
    password: __DEV__ ? REACT_APP_TEST_PASSWORD : '',
    remember_me: false,
    device_name: getDeviceId(),
  });

  const handleSubmit = () => {
    login(params);
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
