import {europeanUnionCountries, varModes} from '../constants/countries';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';

export default (countryId: ObjectId): Option | undefined => {
  const europeanUnionIds = Object.keys(europeanUnionCountries) as ObjectId[];
  if (countryId === 'FR') {
    return varModes[0];
  }
  if (europeanUnionIds.includes(countryId)) {
    return varModes[1];
  }
  return varModes[2];
};
