import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductTranslation} from 'types/product-translation';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateTranslations = api.injectEndpoints({
  endpoints: builder => ({
    updateTranslations: builder.mutation<Response, TranslationsRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `/products/${params.id}/variants/${params.variant}/translations`,
          body: {
            deleted_records: params.deletedRecords,
            variant: params.variant,
            translations: params.translations.map(x => ({
              description: x.description,
              finition: x.finition,
              id: x.id,
              label: x.label,
              lang: x.lang?.code,
            })),
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'variants', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type TranslationsRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  deletedRecords: ObjectId[];
  translations: ProductTranslation[];
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateTranslationsMutation} = updateTranslations;
