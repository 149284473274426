import React, {FC} from 'react';
import messages from '../messages';
import {SupplierOrderPaginationMeta} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Card, Col, Row} from 'reactstrap';
import TagsItems from 'components/tags-card';
import Currency from 'components/currency/currency';

interface TagsProps {}

const TagsRow: FC<TagsProps> = props => {
  const {t} = useTranslation();

  const meta = useSelector(
    selectEntityMeta<SupplierOrderPaginationMeta>('supplier-orders')
  );

  return (
    <Row className="align-items-center justify-content-center mb-3">
      <Col sm={6}>
        <TagsItems title={t(...messages.supplierOrderTotalHt())}>
        
          <Currency value={meta?.suppliers_total_ht ?? 0} />
        </TagsItems>
      </Col>

    </Row>
  );
};

interface TagItemsProps {
  tag: number;
}

const TagItem: FC<TagItemsProps> = props => {
  const {tag} = props;
  return (
    <p className="text-muted m-0 fs-3">
      {tag ? <Trans i18nKey="numbers.currency" values={{val: tag}} /> : 0}
    </p>
  );
};

export default TagsRow;
