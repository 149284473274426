import CostumeCurrency from 'components/costume-currency/costume-currency';
import AddItemDropDown from '../../components/add-item-drop-down/add-item-drop-down';
import messages from '../../components/messages';
import changeField from '../../store/actions/change-field';
import selectField from '../../store/selectors/select-field';
import selectTotals from '../../store/selectors/select-totals';
import documentContext from '../document-context/document-context';
import DocumentIs from '../document-is/document-is';
import PaymentsSection from '../payments-section/payments-section';
import clsx from 'clsx';
import TextInput from 'components/text-input';
import React, {Fragment, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {Col, Row} from 'reactstrap';

const TableFooter = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const discount = useSelector(selectField('globalDiscount'));

  const comment = useSelector(selectField('public_comment'));

  const totals = useSelector(selectTotals);

  const vats = totals.vats;

  const {validationErrors, doc, is_locked} = useContext(documentContext);

  const showVat = doc?.with_vat;

  return (
    <Fragment>
      <footer className="commercial-document-footer pb-5">
        <hr />
        <Row>
          <Col md={4}>
            <AddItemDropDown />
          </Col>
          <Col md={6} className="flex-column align-items-end d-flex ">
            <div>
              <Trans i18nKey={messages.subTotal()} />
            </div>
          </Col>
          <Col className="flex-column align-items-end ">
            <p className="text-end">
              <CostumeCurrency
                value={totals.subtotalExclTax}
                symbol={doc?.currency_symbole}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} />
          <Col />
          <Col md={3} className="text-end">
            {/* Sous-total HT */}
            <p>
              <Trans i18nKey={messages.globalDiscount()} />
            </p>
            <p>
              <Trans i18nKey={messages.furnitureEcoTax()} />
            </p>
            <p>
              <Trans i18nKey={messages.totalExclTaxes()} />
            </p>
            {showVat && (
              <Fragment>
                {vats.map(v => (
                  <p key={v?.name}>{`${t(...messages.vat())} ${v?.name}`}</p>
                ))}

                <strong>
                  <Trans i18nKey={messages.totalIncTaxes()} />
                </strong>
              </Fragment>
            )}
          </Col>
          <Col md={2} className="text-end">
            {/* Sous-total HT */}
            <div className="mb-2">
              <TextInput
                className={clsx('form-control-sm', {
                  'bg-gray-light': is_locked,
                })}
                label=""
                value={discount}
                disabled={is_locked}
                type="number"
                errors={validationErrors.global_discount}
                max="99"
                min="0"
                onChange={d => dispatch(changeField('globalDiscount', d))}
              />
            </div>
            <p>
              <CostumeCurrency
                value={totals.ecoTax}
                symbol={doc?.currency_symbole}
              />
            </p>
            {/* Sous-total HT */}
            <p>
              <CostumeCurrency
                value={totals.subTotalAfterGlobalDiscount}
                symbol={doc?.currency_symbole}
              />
            </p>
            {/* TVA */}
            {showVat && (
              <Fragment>
                {vats.map(v => (
                  <p key={v?.name}>
                    <CostumeCurrency
                      value={v?.value}
                      symbol={doc?.currency_symbole}
                    />
                  </p>
                ))}
                {/* Total TTC: */}
                <strong>
                  <CostumeCurrency
                    value={totals.totalInclTax}
                    symbol={doc?.currency_symbole}
                  />
                </strong>
              </Fragment>
            )}
          </Col>
        </Row>
        <DocumentIs oneOf={['invoice']}>
          <PaymentsSection />
        </DocumentIs>
        {doc?.document_type === 'CLIENT' && <PaymentsSection />}
        <Row>
          <Col>
            <TextInput
              label={t(...messages.publicComment())}
              value={comment}
              disabled={is_locked}
              type="textarea"
              errors={validationErrors.comment}
              max="99"
              min="0"
              onChange={c => dispatch(changeField('public_comment', c))}
            />
          </Col>
        </Row>
      </footer>
    </Fragment>
  );
};

export default TableFooter;
