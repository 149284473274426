import {MODAL} from 'features/modal-notch/constants';
import CreateStockLocationForm from './create-location';
import UpdateStockLocationForm from './update-location';
import CreateStockLocationCategoryForm from './create-location-category';
import UpdateStockLocationCategoryForm from './update-location-category';
import CreateStockSubLocationForm from './create-sub-location';
import UpdateStockSubLocationForm from './update-sub-location';
import UpdateStockStockEntryForm from './update-stock-entry';
import VariantPreviewModal from '../products-stock-grid/containers/variant-preview-modal/variant-preview-modal';
import EditOrderInfoModal from '../production-monitoring/containers/edit-order-info-modal/edit-order-info-modal';
import UpdateStockProductForm from './update-stock-product';
import DetailsStockProduct from './details-stock-product';
import DetailsStockInProduct from './details-stock-in-product';
import TransferProductForm from './transfer-product';
import AssembleProductForm from './assembe-product';
import DetailsStockInPreparation from './stock-in-preparation-details-modal';
import EditStockInPreparation from './stock-in-preparation-edit-modal';

export default {
  [MODAL.CREATE_STOCK_LOCATION]: CreateStockLocationForm,
  [MODAL.UPDATE_STOCK_LOCATION]: UpdateStockLocationForm,
  [MODAL.CREATE_STOCK_LOCATION_CATEGORY]: CreateStockLocationCategoryForm,
  [MODAL.UPDATE_STOCK_LOCATION_CATEGORY]: UpdateStockLocationCategoryForm,
  [MODAL.CREATE_STOCK_SUB_LOCATION]: CreateStockSubLocationForm,
  [MODAL.UPDATE_STOCK_SUB_LOCATION]: UpdateStockSubLocationForm,
  [MODAL.UPDATE_STOCK_ENTRY]: UpdateStockStockEntryForm,
  [MODAL.VARIANT_PREVIEW]: VariantPreviewModal,
  [MODAL.EDIT_ORDER_INFO]: EditOrderInfoModal,
  [MODAL.UPDATE_STOCK_PRODUCT]: UpdateStockProductForm,
  [MODAL.DETAILS_STOCK_PRODUCT]: DetailsStockProduct,
  [MODAL.DETAILS_STOCK_IN_PRODUCT]: DetailsStockInProduct,
  [MODAL.STOCK_TRANSFER_PRODUCT]: TransferProductForm,
  [MODAL.ASSEMBLE_PRODUCT]: AssembleProductForm,
  [MODAL.DETAILS_STOCK_IN_PREPARATION_MODAL]: DetailsStockInPreparation,
  [MODAL.EDIT_STOCK_IN_PREPARATION_MODAL]: EditStockInPreparation,
};
