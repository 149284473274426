import {createContext} from 'react';
import {TransferSupplierOrderRow} from '../../store/endpoints/transfer-to-supplier-order';
import {ObjectId} from 'types/object-id';

export default createContext<TransferContext>({
  validationErrors: {},
  onUpdateItem: () => {},
  items: {},
  isTransferred: () => false,
  initItems: () => {},
});

export type TransferContext = {
  validationErrors: any;
  items: any;
  onUpdateItem: (
    sku: ObjectId,
    key: keyof TransferSupplierOrderRow,
    value?: any
  ) => void;
  isTransferred: (row: TransferSupplierOrderRow) => boolean;
  initItems: (row: any) => void;
};
