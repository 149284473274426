import React from 'react';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {IndeterminateCheckbox} from 'features/table/compenents/column-checkbox';
import {Trans} from 'react-i18next';
import ExpandRow from 'features/table/compenents/expand-row';
import {ItemSuplier} from '../transfer-to-supplier-order-modal/item-suplier';
import {ItemStock} from '../transfer-to-supplier-order-modal/item-stock';
import {TransferCheckbox} from '../transfer-to-supplier-order-modal/transfer-checkbox';
import {TransferExpandAll} from '../transfer-to-supplier-order-modal/transfer-expand-all';
import {Link} from 'react-router-dom';

const columns: ColumnDef<any>[] = [
  {
    header: ({table}) => (
      <div className="d-flex ">
        <div className="me-2">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
        <TransferExpandAll table={table} />
      </div>
    ),
    cell: ({row, getValue}) => (
      <div className="d-flex  ">
        <div className="me-2">
          <TransferCheckbox row={row}></TransferCheckbox>
        </div>
        {row.getCanExpand() ? (
          <ExpandRow
            onClick={row.getToggleExpandedHandler()}
            isExpanded={row.getIsExpanded()}
          />
        ) : null}
      </div>
    ),
    meta: {hide: true},
    enableSorting: false,

    id: 'checkbox',
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.sku" />
      </span>
    ),
    accessorFn: r => (
      <EmptyCell cell={r.sku?.toLocaleUpperCase()} className="fw-bold" />
    ),
    cell: r => r.getValue(),

    enableSorting: false,
    id: 'sku',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.name" />
      </span>
    ),
    accessorFn: row => {
      const link = row.variant_id
        ? `/dashboard/products/${row.product_id}/variants/${row.variant_id}/general`
        : row.variant
        ? `/dashboard/products/${row.variant.product.id}/variants/${row.variant.id}/general`
        : '';

      if (link) {
        return (
          <Link target="_blank" to={link}>
            {row.name?.toLocaleUpperCase()}
          </Link>
        );
      }

      return <EmptyCell cell={row.name?.toLocaleUpperCase()} />;
    },
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: false,
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="orderTransferModal.quantity" />
      </span>
    ),
    accessorFn: row => <EmptyCell cell={row.quantity} />,
    cell: r => r.getValue(),
    id: 'product.name',
    enableSorting: false,
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="orderTransferModal.supplier" />
      </span>
    ),
    accessorFn: row => <ItemSuplier row={row} />,
    cell: r => r.getValue(),
    meta: {size: '15vw'},
    id: 'suplier',
    enableSorting: false,
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="orderTransferModal.stock" />
      </span>
    ),
    accessorFn: row => <ItemStock row={row} />,
    cell: r => r.getValue(),
    meta: {size: '15vw'},
    id: 'stock  ',
    enableSorting: false,
  },
];

export default columns;
