import React from 'react';
import styled from 'styled-components';

const InputLikeContainer = styled.div`
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem; /* Bootstrap input padding */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.47rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color);
`;

export default InputLikeContainer;
