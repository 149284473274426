import {Payment} from 'types/payment';
import makeId from 'utils/make-id';
import {now} from 'utils/now';

export default (): Payment => {
  return {
    id: makeId(),
    amount: '0',
    reference: '',
    date: now(),
  };
};
