import {actions} from '../slice';
import {DocumentFields} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(actions.changeField.type, function prepare<
  T extends keyof DocumentFields
>(key: T, value: DocumentFields[T]) {
  return {
    payload: {
      key,
      value,
    },
  };
});
