import React, {Fragment, useState} from 'react';
import AttachmentActions from './attachment-actions';
import messages from './messages';
import {useAttachmentsForm} from './use-attachments-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import DragDropFile from 'components/dragndrop';
import ImageCropPicker from 'components/image-crop-picker';
import ImageInput from 'components/image-input';
import ItemPicker from 'components/item-picker';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Col, Dropdown, DropdownItem} from 'reactstrap';
import {DropdownMenu, DropdownToggle, FormGroup} from 'reactstrap';
import {Input, Label, Row} from 'reactstrap';
import {useUploadTemporaryFileMutation} from 'store/global/endpoints/upload-temporary-file';
import {ProductAttachment} from 'types/attachements';
import {Option} from 'types/option';
import {TemporaryFile} from 'types/temporary-file';
import {useToggle} from 'usehooks-ts';
import {now} from 'utils/now';
import Can from 'features/hylian-shield/can';

const AttachmentsTab = () => {
  const {
    addAttachments,
    attachments,
    errorMessage,
    isLoading,
    isSuccess,
    params,
    removeAttachments,
    setParams,
    submit,
    updateAttachment,
    validationErrors,
    variant,
  } = useAttachmentsForm();

  const {t} = useTranslation();

  const [v, toggle] = useToggle();

  const [cropImage, showCropperFor] = useState<ProductAttachment | undefined>();

  const [sendTempFile, {isLoading: isUploadingTemp}] =
    useUploadTemporaryFileMutation({});

  const handleUploadImage = async (file: File) => {
    if (cropImage) {
      const tempFileResult = await sendTempFile({data: {file}});
      if ('data' in tempFileResult) {
        updateAttachment(cropImage, 'file', tempFileResult.data.data);
      }
    }
    showCropperFor(undefined);
  };

  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: variant?.name?.toLocaleUpperCase(),
        })}
      />

      {Object.values(attachments).map((p, i) => (
        <Row className="mb-5 mt-4 d-flex justify-content-between  " key={p.id}>
          <Col>
            {p.type?.value === 'IMAGE' ? (
              <ItemPicker
                value={1}
                options={[{id: 1, name: t(...messages.image())}]}
                label={t(...messages.type())}
                isDisabled
                onChange={() => {}}
              />
            ) : (
              <AsyncItemPicker
                label={t(...messages.type())}
                className={clsx({'d-none': p.type?.value === 'IMAGE'})}
                filterOption={(x: any) => x.value !== 'IMAGE'}
                endPoint="data/attachment-types"
                value={p.type}
                errors={validationErrors[`attachements.${i}.type`]}
                getOptionLabel={(p: Option) => p.label}
                getOptionValue={(p: Option) => p.value}
                onChange={x => updateAttachment(p, 'type', x)}
              />
            )}
          </Col>
          <Col md={2}>
            <TextInput
              label={t(...messages.fileName())}
              type="text"
              errors={validationErrors[`attachements.${i}.name`]}
              value={p.name}
              onChange={x => updateAttachment(p, 'name', x)}
            />
          </Col>
          <Col className="align-items-center">
            {p.type?.value !== 'IMAGE' ? (
              <Fragment>
                <label className="mb-0" htmlFor="">
                  {t(...messages.file())}
                </label>
                <DragDropFile
                  file={p?.file}
                  errors={validationErrors[`attachements.${i}.file_id`]}
                  onChangeFile={(x: TemporaryFile) =>
                    updateAttachment(p, 'file', x)
                  }
                />
              </Fragment>
            ) : (
              <ImageInput
                file={p.file}
                isLoading={isUploadingTemp}
                errors={validationErrors[`attachements.${i}.file_id`]}
                onUploadClick={() => showCropperFor(p)}
                onChange={x => updateAttachment(p, 'file', x)}
              />
            )}
          </Col>

          <Col md={2}>
            <DateInput
              errors={validationErrors[`attachements.${i}.date`]}
              value={p.date ? p.date : now()}
              label={t(messages.date())}
              onChange={x => updateAttachment(p, 'date', x)}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <Label htmlFor={`switcher-${p.id}`} className="mb-0">
              &nbsp;
            </Label>
            {p.type?.value === 'IMAGE' && (
              <>
                <Label>
                  <FormGroup switch className="align-items-center pt-3 d-flex ">
                    <Input
                      className="me-1"
                      type="switch"
                      id={`switcher-${p.id}`}
                      role="switch"
                      checked={params.in_catalog === p.id}
                      errors={validationErrors[`attachements.${i}.in_catalog`]}
                      onChange={(x: any) =>
                        setParams({...params, in_catalog: p.id})
                      }
                    />
                    <Label htmlFor={`switcher-${p.id}`} className="mb-0">
                      {t(...messages.inCatalog())}
                    </Label>
                  </FormGroup>
                </Label>
              </>
            )}
          </Col>

          <Col className="align-items-end d-flex" md={1}>
            {/* @ts-ignore*/}
            <Can action="edit" subject="products">
              <Button
                color="link"
                className="text-danger"
                onClick={() => removeAttachments(p.id)}
              >
                <Icon name="trash-2" size={18} />
              </Button>
            </Can>
          </Col>
        </Row>
      ))}

      <Can action="edit" subject="products">
        <Row className="mb-3">
          <Col md={4}>
            <Dropdown isOpen={v} toggle={toggle} direction="down">
              <DropdownToggle color="light">
                {t(...messages.add())}
                <Icon name="chevron-down" className="ms-1" size={15} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    addAttachments({
                      label: t(...messages.image()),
                      value: 'IMAGE',
                    });
                  }}
                >
                  {t(...messages.image())}
                </DropdownItem>
                <DropdownItem onClick={() => addAttachments()}>
                  {t(...messages.file())}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </Can>

      <Can action="edit" subject="products">
        <Row>
          <Col md={12} className="mb-4">
            <AppBarActions>
              <Button isLoading={isLoading} color="important" onClick={submit}>
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </Can>
      <ImageCropPicker
        onCropFinished={handleUploadImage}
        isLoading={isUploadingTemp}
        onClose={() => showCropperFor(undefined)}
        isVisible={!!cropImage}
      />
    </div>
  );
};

export default AttachmentsTab;
