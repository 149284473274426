import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const projectConclusionExport = api.injectEndpoints({
  endpoints: builder => ({
    projectConclusionExport: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `export/${params.id}/conclusion`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{
  download_link: string;
  document_name: string;
}>;

export const {useLazyProjectConclusionExportQuery} = projectConclusionExport;
