import React, {FC, useMemo} from 'react';
import messages from '../messages';
import {STATUSES, INVOICE_TYPES} from './constant';
import {InvoiceFilters} from './types';
import AsyncItemPicker from 'components/async-item-picker';
import DateRangeInput from 'components/date-range-input';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Col, Collapse, FormGroup, Label, Row} from 'reactstrap';
import {Option} from 'types/option';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<InvoiceFilters>;
  onChangeFilters: (key: InvoiceFilters, value: any) => void;
  onClearFilters?: () => void;
}

const Filters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <SearchSection
      onChangeFilters={onChangeFilters}
      onClearFilters={onClearFilters}
      filters={filters}
    >
      <Row className="mb-3">
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.deliveryDate())}
              value={filters['filter[delivery_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[delivery_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.billingDate())}
              value={filters['filter[billing_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[billing_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <DateRangeInput
              label={t(...messages.payementDate())}
              value={filters['filter[payment_date]'] as string}
              onChange={newDate => {
                onChangeFilters('filter[payment_date]', newDate);
              }}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/invoices-document-types"
              className="mb-1"
              label={t(...messages.invoiceType())}
              getOptionLabel={(x: any) => x.label}
              getOptionValue={(x: any) => x.value}
              isClearable
              value={filters['filter[invoice_type]'] as Option}
              onChange={o => onChangeFilters('filter[invoice_type]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/document-categories"
              className="mb-1"
              label={t(...messages.category())}
              value={filters['filter[document_category_id]']}
              onChange={o => onChangeFilters('filter[document_category_id]', o)}
            />
          </FormGroup>
        </Col>

        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/commercial-types?invoice"
              defaultValue
              getDefaultOptions={(meta: any) => meta?.default_index}
              className="mb-1"
              label={t(...messages.type())}
              value={filters['filter[commercial_type_id]']}
              onChange={o => onChangeFilters('filter[commercial_type_id]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/invoice-statuses"
              className="mb-1"
              getOptionLabel={(x: any) => x.label}
              getOptionValue={(x: any) => x.value}
              label={t(...messages.status())}
              isClearable
              value={filters['filter[status]'] as Option}
              onChange={o => onChangeFilters('filter[status]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/users"
              className="mb-1"
              getOptionLabel={(x: any) => x.name}
              label={t(...messages.referalContact())}
              isClearable
              value={filters['filter[contact]'] as Option}
              onChange={o => onChangeFilters('filter[contact]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup>
            <AsyncItemPicker
              endPoint="data/vat-modes"
              className="mb-1"
              label={t(...messages.vatMode())}
              getOptionLabel={(o: any) => o.label}
              getOptionValue={(o: any) => o.value}
              isClearable
              value={filters['filter[vat_mode]'] as Option}
              onChange={o => onChangeFilters('filter[vat_mode]', o)}
            />
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_vintage"
                checked={!!filters['filter[is_vintage]']}
                onChange={(o: any) =>
                  onChangeFilters('filter[is_vintage]', o.target.checked)
                }
              />
              <Label className="form-check-label" htmlFor="is_vintage">
                {t(...messages.vintage())}
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_paid"
                checked={!!filters['filter[is_unpaid]']}
                onChange={(o: any) =>
                  onChangeFilters('filter[is_unpaid]', o.target.checked)
                }
              />
              <Label className="form-check-label" htmlFor="is_unpaid">
                {t(...messages.unpaid())}
              </Label>
            </div>
          </FormGroup>
        </Col>
        <Col lg="3" md="3">
          <FormGroup className="d-flex align-items-end h-100">
            <div
              className="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="is_after_sale_service"
                checked={!!filters['filter[is_after_sale_service]']}
                onChange={(o: any) =>
                  onChangeFilters(
                    'filter[is_after_sale_service]',
                    o.target.checked
                  )
                }
              />
              <Label
                className="form-check-label"
                htmlFor="is_after_sale_service"
              >
                {t(...messages.isAfterSaleService())}
              </Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </SearchSection>
  );
};

export default Filters;
