import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  export: () => __(translations.exportPatrimoinePerLocalisationPage.okay, 'Export'),
  langue: () => __(translations.exportPatrimoinePerLocalisationPage.langue, 'Langue'),
  title: () => __(translations.exportPatrimoinePerLocalisationPage.alertTitle, 'Export patrimoine catalog per localisation'),
  stockLocalisation: () => __(translations.exportPatrimoinePerLocalisationPage.stockLocalisation, 'Stock Localisation'),
  fr: () => __(translations.exportPatrimoinePerLocalisationPage.fr, 'Français'),
  en: () => __(translations.exportPatrimoinePerLocalisationPage.en, 'Anglais'),

};
