import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  artisticDirector: () => __(translations.configureManDaysModal.artisticDirector, 'Artistic Director'),
  project_manager: () => __(translations.configureManDaysModal.project_manager, 'Project Manager'),
  artistic_director: () => __(translations.configureManDaysModal.artistic_director, 'Artistic Director'),
  assistant: () => __(translations.configureManDaysModal.assistant, 'Assistant'),
  manDays: () => __(translations.configureManDaysModal.manDays, 'Man Days'),
  configureManDays: () => __(translations.configureManDaysModal.configureManDays, 'Configure Man Days'),
  dailyScale: () => __(translations.configureManDaysModal.dailyScale, 'Daily scale'),
  numberOfManDays: () => __(translations.configureManDaysModal.numberOfManDays, 'Number of Man Days'),
};
