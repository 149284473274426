import {CommercialProduct} from './commercial-product';
import {PdfLinks} from './pdf-links';
import {STATUS} from 'constants/status';
import {Address} from 'types/address';
import {Directory} from 'types/directory';
import {DocumentCategory} from 'types/document-category';
import {Model} from 'types/model';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Payment} from 'types/payment';
import {PaymentSummary} from './payment-summary';

/**
 * This interface has all common attributes of invoices, orders and quotations
 *
 */
export interface CommercialDocument extends Model {
  address_records: Record<ObjectId, Address>;
  project_fee_type?: Option;
  public_comment?: string;
  private_comment?: string;
  commercial_products_objectified: Record<ObjectId, CommercialProduct>;
  global_discount: string;
  payments: Record<ObjectId, Payment>;
  directory_id: ObjectId;
  due_date: string;
  document_ref: string;
  document_category: DocumentCategory;
  pdf_links?: PdfLinks;
  document_type?: DocumentType;
  is_after_sale_service?: boolean;
  ref_client: string;
  with_vat?: boolean;
  status: STATUS;
  user_id: ObjectId;
  directory?: Directory;
  estimated_supplier_time?: string;
  user: Option;
  currency_conversion: string;
  currency_name: string;
  currency_symbole: string;
  payment_summary: PaymentSummary;
  is_related_to_credit?: boolean;
  is_related_to_invoice?: boolean;
  related_credit: Partial<CommercialDocument>;
  related_invoice: Partial<CommercialDocument>;
  commercial_type?: Option;
  order_date: string;
  billing_date: string;
}

export enum ProjectFeeTypeEnum {
  SOURCING_FEE = 'SOURCING_FEE',
  MAN_DAY = 'MAN_DAY',
  PHASES = 'PHASES',
}

export type DocumentType =
  | 'CREDIT_ACCOUNTING'
  | 'CREDIT'
  | 'DEFAULT'
  | 'STOCK'
  | 'CLIENT'
  | 'SUPPLIER';
