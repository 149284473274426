import React, {FC, Fragment, ReactElement, ReactNode, useEffect} from 'react';
import CostDropDown from './cost-drop-down';
import CostsContext from './costs-context';
import HistoryTable from './history';
import Components from './lines/components';
import Development from './lines/development';
import Logistic from './lines/logistic';
import Other from './lines/other';
import messages from './messages';
import changeSituation from './slice/actions/change-situation';
import copyVariantComponents from './slice/actions/copy-variant-components';
import {costCategories} from './slice/constants';
import TableSeparator from './table-separator';
import TotalTableHeader from './total-table-header';
import {useCostsForm} from './use-costs-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Row, Col, Alert} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import selectMainSituation from 'store/production/selectors/select-main-situation';
import {CostCategory} from 'types/cost-category';
import Can from 'features/hylian-shield/can';

const CostsTab = () => {
  const {errorMessage, isLoading, submit, reset, isSuccess, validationErrors} =
    useCostsForm();

  const {t} = useTranslation();

  const {variant, id} = useParams();

  const {isLoading: isLoadingVariant, data} = useSelector(
    selectVariant({id: variant, productId: id})
  );

  const dispatch = useDispatch();

  const mainSituation = useSelector(selectMainSituation(variant, id));

  useEffect(() => {
    if (mainSituation) {
      dispatch(changeSituation(mainSituation));
    } else {
      if (data?.data) {
        dispatch(copyVariantComponents(data.data));
      }
    }
  }, [mainSituation?.id]);

  return (
    <div>
      <SectionSeparator
        title={t(...messages.title(), {
          name: data?.data.name?.toLocaleUpperCase(),
        })}
      />
      <CostsContext.Provider
        value={{
          validationErrors,
          isLoading,
          reset,
          isSuccess,
          errorMessage,
        }}
      >
        {/* Row 1 */}
        <Fragment>
          <TotalTableHeader />

          <TableSeparator />

          {costCategories.map((x, index) => {
            const Comp = LineComps[x];
            return <Comp key={x} categoryId={x} index={index} />;
          })}

          <Can action="edit" subject="product-prices">
            <CostDropDown />
          </Can>

          <hr />
          <AppBarActions>
            <Can action="edit" subject="product-prices">
              <Button isLoading={isLoading} color="important" onClick={submit}>
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </Can>
          </AppBarActions>
          <HistoryTable></HistoryTable>
        </Fragment>
      </CostsContext.Provider>
    </div>
  );
};

const LineComps: Record<CostCategory, any> = {
  components: Components,
  development: Development,
  logistic: Logistic,
  other: Other,
};
export default CostsTab;
