import React, {FC} from 'react';
import Table from 'features/table';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Button from 'components/button';
import messages from './messages';
import columns from './columns';
import TableCompact from 'features/table/table-compact';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';

interface Props {
  message?: string;
  onDismiss: () => void;
}

const StockMouvementModal: FC<Props> = props => {
  const {message, onDismiss} = props;
  const {variantId} = useSelector(selectActiveModalParams);
  const {t} = useTranslation();
  return (
    <>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <TableCompact
          columns={columns}
          url={`product-variants/${variantId}/stock-movements`}
          name="stock-movements"
          hidePagination={true}
          otherParams={{
            delimiter: '|',
          }}
        ></TableCompact>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default StockMouvementModal;
