import React, {FC, useContext} from 'react';
import {commercialProductTypes} from '../../constants/commercial-product-types';
import addItem from '../../store/actions/add-item';
import selectItems from '../../store/selectors/select-items';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown} from 'reactstrap';
import {CommercialProductType} from 'types/commercial-product-type';
import documentContext from '../../containers/document-context/document-context';

interface Props {
  primary?: boolean;
}

const AddItemDropDown: FC<Props> = props => {
  const {primary} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {is_locked} = useContext(documentContext);

  const lines = useSelector(selectItems);

  const handleAddLine = (type: CommercialProductType) => {
    dispatch(addItem(lines.length, type));
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        disabled={is_locked}
        caret
        color={primary ? 'primary' : 'important'}
      >
        {t(...messages.addProduct())}
        <Icon name="chevron-down" className="ms-1" size={15} />
      </DropdownToggle>
      <DropdownMenu>
        {commercialProductTypes.map(x => (
          <DropdownItem key={x} onClick={() => handleAddLine(x)}>
            <Trans i18nKey={`addItemDropDown.${x.toLocaleLowerCase()}`} />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AddItemDropDown;
