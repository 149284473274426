import {AppleLoginResult} from './types';

const appleSignInService = {
  configure: async () => {},
  signIn: async () => {
    // const authResponse = await appleAuth.performRequest(
    //   application.oauth.apple
    // );

    // const isEmailHidden = authResponse?.email === null;
    // const hasToken = !!authResponse?.identityToken;
    // const hasUserId = !!authResponse?.user;

    // if (!hasToken || !hasUserId) {
    //   return {
    //     isSuccessful: false,
    //     user: null,
    //   };
    // }

    return {
      isSuccessful: false,
      //   provider_token: authResponse.identityToken,
      //   user: {
      //     id: authResponse.user,
      //     is_email_hidden: isEmailHidden,
      //     first_name: authResponse.fullName?.givenName,
      //     last_name: authResponse.fullName?.familyName,
      //     email: authResponse.email,
      //   },
    } as AppleLoginResult;
  },
  signOut: async () => {
    // const result = await appleAuth.performRequest({
    //   requestedOperation: appleAuth.Operation.LOGOUT,
    // });
    // return result;
    return null;
  },
};

export default appleSignInService;
