import React, {FC, Fragment} from 'react';
import GalleryView from 'components/gallery-view';
import {ProductVariant} from 'types/product-variant';

const ProductCover: FC<{variant: Partial<ProductVariant>}> = props => {
  const {variant} = props;

  const images =
    variant.attachements?.filter(x => x.type?.value === 'IMAGE') ?? [];

  const imgCount = images.length;

  const p =
    imgCount < 1 && variant?.cover ? [variant?.cover, ...images] : images;

  return (
    <Fragment>
      <GalleryView images={p} imageClassName="" previewSize={36} />
    </Fragment>
  );
};

export default ProductCover;
