import React, {FC} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import ComponentItem from '../component-item/component-item';
import OptionItem from '../option-item/option-item';
import {CommercialProductOption} from 'types/commercial-product-option';

interface Props {
  option: CommercialProductOption;
  item: CommercialProduct;
  index: number;
  lineIndex: number;
}

const ItemOptionMapper: FC<Props> = props => {
  const {option, index, item, ...rest} = props;

  if (!option.type) {
    throw Error(`Unknown option type ${option.type}`);
  }

  const C = componentsMap[option.type];

  return <C item={item} option={option} index={index} {...rest} />;
};

const componentsMap = {
  OPTION: OptionItem,
  COMPONENT: ComponentItem,
};

export default ItemOptionMapper;
