import {useCallback, useMemo, useEffect, useState} from 'react';
import loadLocalData from '../slice/actions/load-local-data';
import toggleSelection from '../slice/actions/toggle-selection';
import selectDestroyedResourcesCount from '../slice/selectors/select-destroyed-resources-count';
import selectEntity from '../slice/selectors/select-entity';
import selectSelectedRows from '../slice/selectors/select-selected-rows';
import {
  ColumnDef,
  ExpandedState,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {useDispatch, useSelector} from 'react-redux';
import {ResourceName} from 'types/resource-name';

const useInitiateLocalTable = (
  name: ResourceName,
  localData: any,
  columns: ColumnDef<any>[],
  getRowId?: (
    originalRow: any,
    index: number,
    parent?: Row<any> | undefined
  ) => string,
  getSubRow?: (originalRow: any, index: number) => any[] | undefined,
  enableSubRowSelection?: boolean
) => {
  const dispatch = useDispatch();

  const {data, meta, filters} = useSelector(selectEntity(name));

  const eatenSoulsCount = useSelector(selectDestroyedResourcesCount(name));
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const rowSelection = useSelector(selectSelectedRows(name));
  const handleSelectionChange = (selectedRowIds: any) => {
    dispatch(toggleSelection(name, selectedRowIds(rowSelection)));
  };
  useEffect(() => {
    dispatch(loadLocalData({name, data: localData}));
  }, [name, localData]);
  const getRowIdCallback = useCallback(() => {
    if (getRowId) {
      return getRowId;
    }
    return (row: any) => {
      return row.id;
    };
  }, []);
  const getSubRows = useCallback(() => {
    if (getSubRow) {
      return getSubRow;
    }
    return (row: any, index: number) => {
      return row.subRows;
    };
  }, []);

  const memorizedColumns = useMemo(() => columns, [columns]);
  const memorizedData = useMemo(() => data, [data]);

  const table = useReactTable({
    data: memorizedData || [],
    columns: memorizedColumns,
    state: {
      rowSelection,
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: getSubRows(),
    enableRowSelection: true,
    manualSorting: true,
    onRowSelectionChange: handleSelectionChange,
    manualPagination: true,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: getRowIdCallback(),
    getCoreRowModel: getCoreRowModel(),
    enableSubRowSelection: enableSubRowSelection,
  });

  return {
    table,
    meta,
    filters,
  };
};

export default useInitiateLocalTable;
