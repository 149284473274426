import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateStockLocationComment = api.injectEndpoints({
  endpoints: builder => ({
    updateStockLocationComment: builder.mutation<Response, Request>({
      query: params => ({
        method: 'POST',
        url: `product-variants/${params.id}/stock-locations/${params.location_id}/comment`,
        body: {
          comment: params.comment,
        },
      }),
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{location: 1}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  location_id?: ObjectId;
  comment?: string;
};

type Response = ResponseData<string>;

export const {useUpdateStockLocationCommentMutation} =
  updateStockLocationComment;
