import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {Trans, useTranslation} from 'react-i18next';
import {Button, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const AlertCloak: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.alertTitle()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <p>{message ?? <Trans i18nKey={messages.changesCommited()} />}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={onDismiss}>
          {t(...messages.okay())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default AlertCloak;
