import React, {KeyboardEvent} from 'react';
import messages from '../messages';
import {useCreateUserForm} from './use-store-user-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Alert, Col, Row} from 'reactstrap';
import {Role} from 'types/role';

const FormSection = () => {
  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useCreateUserForm();

  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <div className="custom-form mt-4 px-2 pt-2">
      <div className="row mb-4">
        <TextInput
          onKeyDown={handleKeyboardEventDown}
          onChange={first_name => setParams({...params, first_name})}
          value={params.first_name}
          label={t(...messages.firstName())}
          errors={validationErrors.first_name}
        />
      </div>
      <div className="row mb-4">
        <TextInput
          onKeyDown={handleKeyboardEventDown}
          onChange={last_name => setParams({...params, last_name})}
          value={params.last_name}
          label={t(...messages.lastName())}
          errors={validationErrors.first_name}
        />
      </div>
      <div className="row mb-4">
        <AsyncItemPicker
          endPoint="data/roles"
          className="mb-1"
          label={t(...messages.role())}
          getOptionLabel={(r: Role) => r.label}
          errors={validationErrors.role_id}
          value={params.role}
          onChange={role => setParams({...params, role})}
        />
      </div>
      <div className="row mb-4">
        <TextInput
          onKeyDown={handleKeyboardEventDown}
          onChange={phone_number => setParams({...params, phone_number})}
          value={params.phone_number}
          label={t('usersEditPage.phone')}
          errors={validationErrors.phone_number}
        />
      </div>
      <div className="row mb-4">
        <TextInput
          onKeyDown={handleKeyboardEventDown}
          onChange={email => setParams({...params, email})}
          value={params.email}
          label={t(...messages.email())}
          errors={validationErrors.email}
        />
      </div>

      <div className="mb-3">
        <TextInput
          onChange={password => setParams({...params, password})}
          value={params.password}
          onKeyDown={handleKeyboardEventDown}
          label={t(...messages.password())}
          errors={validationErrors.password}
          type="password"
        />
      </div>
      <div className="mb-3">
        <TextInput
          onChange={password_confirmation =>
            setParams({...params, password_confirmation})
          }
          value={params.password_confirmation}
          onKeyDown={handleKeyboardEventDown}
          label={t(...messages.passwordConfirmation())}
          errors={validationErrors.password_confirmation}
          type="password"
        />
      </div>

      <AppBarActions>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          {t('common.saveChanges')}
        </Button>
      </AppBarActions>
    </div>
  );
};

export default FormSection;
