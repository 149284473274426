import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  product: () => __(translations.productAlertDetailsTable.product, 'Product'),
  stockAvailable: () => __(translations.productAlertDetailsTable.stockAvailable, 'Stock available'),
  stockCurrent: () => __(translations.productAlertDetailsTable.stockCurrent, 'Stock current'),
  stockMin: () => __(translations.productAlertDetailsTable.stockMin, 'Stock min'),
  quantityToOrder: () => __(translations.productAlertDetailsTable.quantityToOrder, 'Quantity to order'),
  orderNow: () => __(translations.productAlertDetailsTable.orderNow, 'Order'),
  subFamily: () => __(translations.productAlertDetailsTable.subFamily, 'Sub Family'),
  smallObjects: () => __(translations.productAlertDetailsTable.smallObjects, 'Small objects'),
  component: () => __(translations.productAlertDetailsTable.component, 'Component'),
  tissus: () => __(translations.productAlertDetailsTable.tissus, 'Tissus'),
  mobilier: () => __(translations.productAlertDetailsTable.mobilier, 'Mobilier'),
};
