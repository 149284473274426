import React, {FC, Fragment, useState} from 'react';
import messages from '../messages';
import {ProductsFilters} from './types';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/listing/list/slice/types';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownToggle} from 'reactstrap';
import {Spinner, DropdownMenu, DropdownItem} from 'reactstrap';
import {useLazyGetCostsExportsQuery} from 'store/production/endpoints/get-costs-export';
import {useLazyGetProductExportsQuery} from 'store/production/endpoints/get-product-export';
import toast from 'store/ui/actions/toast';
import ExportPatrimoine from 'pages/commercial/pricing/grid-controls/export-patrimoine';
import Can from 'features/hylian-shield/can';

interface Props {
  filters: FilterMap<ProductsFilters>;
}

const Actions: FC<Props> = props => {
  const {filters} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleSubmitProducts = async (filters: any) => {
    const result = await submitProducts(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const handleSubmitCosts = async (filters: any) => {
    const result = await submitCosts(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [submitProducts, {isFetching: isLoadingProduct}] =
    useLazyGetProductExportsQuery();

  const [submitCosts, {isFetching: isLoadingCost}] =
    useLazyGetCostsExportsQuery();

  return (
    <div className="justify-content-end d-flex">
      <AppBarActions>
        <Fragment>
          <Can action="create" subject="products">
            <Link to="/dashboard/products/create" className="btn btn-important">
              <Plus size={15} className="me-1" />
              {t(...messages.addProduct())}
            </Link>
          </Can>
          {/* Action */}
          <Can action="export" subject="products">
            <Dropdown
              disabled={isLoadingProduct || isLoadingCost}
              className="ms-2"
              isOpen={dropdownOpen}
              toggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <DropdownToggle color="light">
                {isLoadingProduct || isLoadingCost ? (
                  <Spinner size={'sm'} />
                ) : (
                  t(...messages.exports())
                )}
                <Icon name="chevron-down" size={15} className="ms-1" />
              </DropdownToggle>
              <DropdownMenu style={{width: '240px'}}>
                <DropdownItem
                  disabled={isLoadingProduct}
                  onClick={() => handleSubmitProducts(filters)}
                >
                  {t(...messages.productsList())}
                </DropdownItem>
                <DropdownItem divider />
                <Can action="export" subject="product-prices">
                  <DropdownItem
                    disabled={isLoadingCost}
                    onClick={() => handleSubmitCosts(filters)}
                  >
                    {t(...messages.listOfCosts())}
                  </DropdownItem>
                </Can>
                <DropdownItem divider />

                <ExportPatrimoine />
              </DropdownMenu>
            </Dropdown>
          </Can>
          <Can action="administration" subject="products">
            <Link to="/dashboard/families" className="btn btn-light ms-2">
              <Icon name="list" size={15} className="me-1" />
              {t(...messages.families())}
            </Link>
          </Can>
          <Link to="/dashboard/master-products" className="btn btn-light ms-2">
            {t(...messages.masterProducts())}
          </Link>
        </Fragment>
      </AppBarActions>
    </div>
  );
};

export default Actions;
