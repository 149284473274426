import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.infoSection.title, 'Product information'),
  date: () => __(translations.infoSection.date, 'Creation date'),
  suppliers: () => __(translations.infoSection.suppliers, 'Suppliers'),
  priceHt: () => __(translations.infoSection.priceHt, 'Sells price HT'),
  priceTtc: () => __(translations.infoSection.priceTtc, 'Sells price TTC'),
  vat: () => __(translations.infoSection.vat, 'VAT'),
  stockAvilable: () => __(translations.infoSection.stockAvilable, 'Stock available'),
  stockTotal: () => __(translations.infoSection.stockTotal, 'Stock total'),
  totalStock: () => __(translations.infoSection.totalStock, 'Total stock'),
  slider: () => __(translations.infoSection.slider, 'Slider'),
};
