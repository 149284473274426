import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  selectVariants: () => __(translations.pricingActionModal.selectVariants, 'Please, select all variants that you wish to affect'),
  title: () => __(translations.pricingActionModal.alertTitle, 'Save product as'),
  selectAll: () => __(translations.pricingActionModal.selectAll, 'Select all'),
  increaseText: () => __(translations.pricingActionModal.increaseText, 'Please,input the value you want to increase the price by'),
  value: () => __(translations.pricingActionModal.value, 'Value'),
  increaseType: () => __(translations.pricingActionModal.increaseBy, 'Increase type'),
  percent: () => __(translations.pricingActionModal.percent, 'Percent'),
  amount: () => __(translations.pricingActionModal.amount, 'Amount'),
};
