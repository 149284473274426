import Stocklocation from 'features/stock/types/stock-location';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getStockLocations = api.injectEndpoints({
  endpoints: builder => ({
    getStockLocations: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `product-variants/${params.id}/stock-locations`,
      }),
      // @ts-ignore
      providesTags: () => [{location: 1}],
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Stocklocation[]>;

export const {useGetStockLocationsQuery} = getStockLocations;
