import React, {useEffect, useState} from 'react';
import {useMemo, FC} from 'react';
import './styles.scss';
import Icon from '@ailibs/feather-react-ts';
import classNames from 'classnames';
import {format} from 'date-fns';
import {French} from 'flatpickr/dist/l10n/fr.js';
import Flatpickr from 'react-flatpickr';
import {useTranslation} from 'react-i18next';
import {Label} from 'reactstrap';
import realDate from 'utils/real-date';
import useInputId from 'utils/use-input-id';

const DateInput: FC<Props> = props => {
  const {
    value: propValue,
    id: inputId,
    label = '',
    errors = [],
    disabled,
    mode,
    onChange,
    className,
  } = props;
  const {i18n} = useTranslation();
  const currentLocale = i18n.language;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    // When the propValue changes, update the selectedDate state.

    if (propValue) {
      setSelectedDate(new Date(propValue));
    } else {
      setSelectedDate(null);
    }
  }, [propValue]);

  const errorMessage = useMemo(() => {
    return Array.isArray(errors) ? errors[0] : errors;
  }, [errors]);

  const handleChange = (dates: Date[]) => {
    // Update the selected date in state.
    // Call the provided onChange handler with the selected date.
    if (onChange) {
      onChange(realDate(dates[0]));
    }
  };

  const id = useInputId(inputId);
  const isValueSelected = useMemo(() => {
    return !!propValue;
  }, [propValue]);

  return (
    <div>
      <Label className="mb-0" htmlFor={id}>
        {label}
      </Label>
      <div className="position-relative">
        <Icon
          name={isValueSelected ? 'x' : 'calendar'}
          size={18}
          className="position-absolute   end-0 me-2  h-100"
          onClick={() => {
            onChange('');
          }}
          color="gray"
        />

        <Flatpickr
          id={id}
          className={classNames(
            'form-control',
            {
              'is-invalid': !!errorMessage,
              'bg-gray-light': disabled,
            },
            className
          )}
          format="dd/MM/yyyy"
          disabled={disabled}
          options={{
            locale: currentLocale === 'fr' ? French : null,

            formatDate: () => {
              return selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '-';
            },
          }}
          value={propValue}
          onChange={handleChange}
        />
      </div>

      {errorMessage ? (
        <div className="invalid-feedback d-block">{errorMessage} </div>
      ) : null}
    </div>
  );
};

interface Props {
  value?: string;
  label?: string;
  id?: string;
  errors?: string | string[];
  mode?: 'range' | 'single';
  onChange: (newDate?: string) => void;
  disabled?: boolean;
  className?: string;
}

export default DateInput;
