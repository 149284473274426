import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDuplicatVariantMutation} from 'store/production/endpoints/action-variant-duplicat';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDuplicatVariant = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDuplicatVariantMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {id, productId, onSuccess, message} = modalParams;

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const result = await submit({
      id,
    });
    if ('data' in result) {
      navigate(
        `/dashboard/products/${productId}/variants/${result?.data?.data.id}/general`
      );
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
