import React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import EmptyCell from 'components/empty-cell';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import {Trans} from 'react-i18next';
import DetailsAction from './controls/details-action';
import EditAction from './controls/edit-action';
import {Link} from 'react-router-dom';
import StatusBadge from 'components/status-badge';

const columns: ColumnDef<StockProductInPreparation>[] = [
  {
    header: r => <span className="font-size-14"></span>,
    enableSorting: false,
    accessorFn: row => <DetailsAction stockInPreparation={row} />,
    id: 'details',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.logistic" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => <EmptyCell cell={row.stock_location_name} />,
    id: 'stock_location_name',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.orderNumber" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => (
      <>
        {row?.client_name}
        <br />
        <Link
          target="_blank"
          to={`/dashboard/orders/${row?.order?.id}/details`}
        >
          {row?.order?.document_ref}
        </Link>
      </>
    ),
    id: 'order',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.preparationDate" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => (
      <EmptyCell cell={new Date(row?.date).toLocaleDateString()} />
    ),
    id: 'preparation_date',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.transporter" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => <EmptyCell cell={row?.transporter?.display_name} />,
    id: 'transporter',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.trackingNumber" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => <EmptyCell cell={row.tracking_code} />,
    id: 'tracking_code',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.status" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => <StatusBadge status={row.status} />,
    id: 'status',
    cell: r => r.getValue(),
  },
  {
    header: r => (
      <span className="font-size-14">
        <Trans i18nKey="stockInPreparation.actions" />
      </span>
    ),
    enableSorting: true,
    accessorFn: row => <EditAction stockInPreparation={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
