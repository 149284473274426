import React from 'react';
import MaterialActions from './material-actions';
import {Trans} from 'react-i18next';
import { ColumnDef } from '@tanstack/table-core';
import { Material } from 'types/materials';

const columns: ColumnDef<Material>[] = [
  {
    header: r => <Trans i18nKey="materialsPage.nameFrench" />,
    accessorFn: row => row.translation?.fr?.toLocaleUpperCase(),
    id: 'name_fr',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="materialsPage.nameEnglish" />,
    accessorFn: row => row.translation?.en?.toLocaleUpperCase(),
    id: 'name_en',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  
  {
    header: r => <Trans i18nKey="productPage.actions" />,
    accessorFn: row => <MaterialActions material={row} />,
    id: 'product_code',
    enableSorting: false,
    cell: r => r.getValue(),

  },
];

export default columns;


