import React from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Link} from 'react-router-dom';
import {Directory} from 'types/directory';

const getDirectoryName = (v: Directory) => {
  return (
    <div
      //style={{zIndex: -1}}
      className="d-flex  justify-content-between align-items-center"
    >
      <div>{v.display_name}</div>
      <div style={{zIndex: 1, textAlign: 'center'}}>
        <Link target="_blank" to={`/dashboard/directories/${v.id}/general `}>
          <Icon name="external-link" size={15} />
        </Link>
      </div>
    </div>
  );
};
export default getDirectoryName;
