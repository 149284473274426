import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {useUpdateCatalogForm} from './use-update-catalog-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {Alert, Card, CardBody, FormGroup} from 'reactstrap';
import {Input, Label, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const ProductActionForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, isLoading, errorMessage} = useUpdateCatalogForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <p>{t(...messages.description())} </p>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t('actionVariantModal.yesImSure')}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t('actionVariantModal.cancel')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default ProductActionForm;
