import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const projectSourcingfeesExport = api.injectEndpoints({
  endpoints: builder => ({
    projectSourcingfeesExport: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `projects/${params.id}/export-sourcing-fees`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{
  download_link: string;
  document_name: string;
}>;

export const {useLazyProjectSourcingfeesExportQuery} =
  projectSourcingfeesExport;
