import {selectDomain} from '.';
import {FilterMap} from '../types';
import {createSelector} from '@reduxjs/toolkit';

export default (name: string) =>
  createSelector([selectDomain], domain => {
    return {
      data: domain.entities[name],
      meta: domain.meta[name],
      filters: domain.filters[name] || ({} as FilterMap),
    };
  });
