import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductCustomization} from 'types/product-customization';
import {ResponseData} from 'types/response-data';

const updateOtherProductInfo = api.injectEndpoints({
  endpoints: builder => ({
    updateOtherProductInfo: builder.mutation<Response, UpdateOtherInfoRequest>({
      query: params => ({
        method: 'PUT',
        url: `/products/${params.id}/other-info`,
        body: {
          setup: params.setup,
          hs_code: params.hs_code,
          carbon_footprint: params.carbon_footprint,

          inspiration: params.inspiration,
          maintenance_instruction: params.maintenance_instruction,
          customization_options: params.customization_options,
        },
      }),
    }),
  }),
});

export type UpdateOtherInfoRequest = {
  id?: ObjectId;
  setup?: string;
  hs_code?: string;
  inspiration?: string;

  carbon_footprint?: string;
  maintenance_instruction?: string;
  customization_options?: ProductCustomization[];
};

type Response = ResponseData<UpdateOtherInfoRequest>;

export const {useUpdateOtherProductInfoMutation} = updateOtherProductInfo;
