import {extraReducers} from './extra-reducers';
import {
  DestroyResourceAction,
  FilterMap,
  RestoreResourceAction,
  SelectAllResourceAction,
  SelectResourceAction,
} from './types';
import {ListConfig, ListingSortAction, ListState} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {ResourceName} from 'types/resource-name';

export const initialState: ListState = {
  loading: {},
  errorMessage: {},
  entities: {},
  meta: {},
  filters: {},
  deletedResources: {},
  selectedResources: {},
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    loadData(state, action: PayloadAction<ListConfig>) {
      state.loading[action.payload.name] = true;
      state.errorMessage[action.payload.name] = null;
    },
    loadDataSuccess(
      state,
      action: PayloadAction<{name: ResourceName; meta: any; data: any}>
    ) {
      state.loading[action.payload.name] = false;
      state.errorMessage[action.payload.name] = null;
      state.entities[action.payload.name] = action.payload.data;
      state.meta[action.payload.name] = action.payload.meta;
    },
    loadDataError(
      state,
      action: PayloadAction<{
        name: string;
        meta: any;
      }>
    ) {
      state.loading[action.payload.name] = false;
      state.errorMessage[action.payload.name] = action.payload.meta.message;
    },
    loadLocalData(
      state,
      action: PayloadAction<{name: ResourceName; data: any}>
    ) {
      state.errorMessage[action.payload.name] = null;
      state.entities[action.payload.name] = action?.payload?.data;
    },
    changeFilter(state, action: PayloadAction<any>) {
      const oldFilters = state.filters[action.payload.name] || {};
      state.filters[action.payload.name] = {
        ...oldFilters,
        [action.payload.key]: action.payload.value,
      };
    },
    clearFilters(state, action: PayloadAction<{name: ResourceName}>) {
      const oldFilters = state.filters[action.payload.name];
      state.filters[action.payload.name] = {limit: oldFilters?.limit};
      delete state.meta[action.payload.name];
    },
    hydrateFilters(
      state,
      action: PayloadAction<{name: ResourceName; filters: FilterMap}>
    ) {
      state.filters[action.payload.name] = action.payload.filters;
    },
    changeSort(state, action: ListingSortAction) {
      const oldFilters = state.filters[action.payload.name] || {};
      state.filters[action.payload.name] = {
        ...oldFilters,
        sort:
          action.payload.direction === 'desc'
            ? '-' + action.payload.sort
            : action.payload.sort,
      };
    },
    destroyResource(state, action: PayloadAction<DestroyResourceAction>) {
      const {payload} = action;
      const oldResources = state.deletedResources[payload.resourceName] || {};
      const affectedRow = payload.resourceId;
      state.deletedResources[payload.resourceName] = {
        ...oldResources,
        [affectedRow]: true,
      };
      const oldEntities = state.entities[payload.resourceName] || [];
      state.entities[payload.resourceName] = oldEntities.filter(
        x => x.id !== payload.resourceId
      );
    },
    restoreResource(state, action: PayloadAction<RestoreResourceAction>) {
      const {payload} = action;
      const {resource, resourceName} = payload;
      const oldEntities = state.entities[resourceName] || [];
      delete state.deletedResources[resourceName];
      state.entities[payload.resourceName] = oldEntities
        .filter(x => x.id !== resource.id)
        .concat(resource);
    },
    toggleSelection(state, action: PayloadAction<SelectResourceAction>) {
      const {payload} = action;
      const res = payload.resourceName;
      const selectedRows = payload.selected || {};
      state.selectedResources[res] = selectedRows;
    },

    clearSelection(state, action: PayloadAction<ResourceName>) {
      const {payload} = action;
      state.selectedResources[payload] = {};
    },
    clearAllSelection(state) {
      state.selectedResources = initialState.selectedResources;
    },
  },
  extraReducers,
});

export const {actions, reducer} = tableSlice;

export const useListSlice = () => {
  return {actions: tableSlice.actions};
};
