import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateProfileMutation} from 'store/account/endpoints';
import {selectProfile, selectUser} from 'store/auth/selectors';
import toast from 'store/ui/actions/toast';
import {now} from 'utils/now';

export const useProfileForm = () => {
  const profile = useSelector(selectProfile);

  const user = useSelector(selectUser);

  const [updateProfile, {isLoading, error, isSuccess}] =
    useUpdateProfileMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState({
    first_name: profile?.first_name ?? '',
    last_name: profile?.last_name ?? '',
    phone_number: profile?.phone_number ?? '',
    email: user?.email,
    address: profile?.address ?? '',
    born_at: profile?.born_at,
    gender: profile?.gender,
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updateProfile(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  return {
    setParams,
    params,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    isLoading,
    submit: handleSubmit,
    isSuccess,
  };
};
