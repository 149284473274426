import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  actions: () => __(translations.quotationActionTab.actions, 'Actions on quotation'),
  duplicateQuotationTitle: () => __(translations.quotationActionTab.duplicateQuotationTitle, 'Duplicate quotation'),
  duplicateQuotationDescription: () =>
    __(
      translations.quotationActionTab.duplicateQuotationDescription,
      'This is useful if you want to create another quotation that has the same items as the current one, and make some minor changes to it '
    ),
  duplicateAlertMessage: () => __(translations.quotationActionTab.duplicateAlertMessage, 'Are you sure you want to duplicate this quotation?'),
  archiveTitle: () => __(translations.quotationActionTab.archiveTitle, 'Archive'),
  archiveDetails: () => __(translations.quotationActionTab.archiveDetails, 'This will move this quotation to the archive. Thus, simplifies the management of quotes.'),
  archiveQuotation: () => __(translations.quotationActionTab.archiveQuotation, 'Archive'),
  transfer: () => __(translations.quotationActionTab.transfer, 'Transfer'),
  archiveAlertMessage: () => __(translations.quotationActionTab.archiveAlertMessage, 'are you sure you want to archive this quotation?'),
  deleteQuotationTitle: () => __(translations.quotationActionTab.deleteQuotationTitle, 'Delete quotation'),
  deleteQuotationDescription: () => __(translations.quotationActionTab.deleteQuotationDescription, "Once you've deleted a quotation, there's no going back. This action is irreversible."),
  deleteQuotation: () => __(translations.quotationActionTab.deleteQuotation, 'Delete'),
  deleteAlertMessage: () => __(translations.quotationActionTab.deleteAlertMessage, 'are you sure you want to delete this quotation?'),

  sendEmailTitle: () => __(translations.quotationActionTab.sendEmailTitle, 'Send via e-mail'),
  sendEmailDetails: () => __(translations.quotationActionTab.sendEmailDetails, 'This will send the quotation as email'),
  sendEmailQuotation: () => __(translations.quotationActionTab.sendEmailQuotation, 'Send'),
  

};
