import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockInPreparation.editModal.title, 'Edit product in preparation'),
  sku: () => __(translations.stockInPreparation.editModal.sku, 'SKU'),
  name: () => __(translations.stockInPreparation.editModal.name, 'Name'),
  quantity: () => __(translations.stockInPreparation.editModal.quantity, 'Quantity'),
  location: () => __(translations.stockInPreparation.editModal.location, 'Localisation'),
  serialNumber: () => __(translations.stockInPreparation.editModal.serialNumber, 'Serial number'),
  status: () => __(translations.stockInPreparation.editModal.status, 'Statut'),
  transporter: () => __(translations.stockInPreparation.editModal.transporter, 'Transporter'),
  trackingNumber: () => __(translations.stockInPreparation.editModal.trackingNumber, 'Tracking Number'),
  description: () => __(translations.stockInPreparation.editModal.description, 'Description'),

  addNewCompanyClient: () => __(translations.stockInPreparation.editModal.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.stockInPreparation.editModal.addNewPrivateClient, 'Add Private'),

  // statuses
  delivered: () => __(translations.stockInPreparation.editModal.delivered, 'Delivered'),
  waiting_for_delivery: () => __(translations.stockInPreparation.editModal.awaiting_expedition, 'Awaiting expedition'),
};
