import {RestoreResourceAction} from './types';
import {PayloadAction} from '@reduxjs/toolkit';
import {actions} from 'features/listing/list/slice';
import {call, take} from 'redux-saga/effects';

/**
 * Bridges the gap between the mortal realm and the ethereal plane.
 * With a touch that radiates boundless compassion, a call to this angelic being
 * has the power to resurrect fallen resources, granting them a chance at
 * redemption and new life.
 *
 */
export function* resurrect(action: PayloadAction<RestoreResourceAction>) {
  const {onRestore} = action.payload;

  yield take(actions.restoreResource.type);

  if (typeof onRestore === 'function') {
    yield call(onRestore);
  }
}
