import messages from 'components/status-badge/messages';
import {STATUS} from 'constants/status';

export const STATUSES = [
  {
    id: STATUS.AVAILABLE,
    name: messages.available,
  },

  {
    id: STATUS.USED,
    name: messages.used,
  },
  {
    id: STATUS.BLOCKED,
    name: messages.blocked,
  },
  {
    id: STATUS.REFUND,
    name: messages.refund,
  },
];
