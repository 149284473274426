import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockInPreparation.detailsModal.title, 'Product in preparation details'),
  sku: () => __(translations.stockInPreparation.detailsModal.sku, 'SKU'),
  name: () => __(translations.stockInPreparation.detailsModal.name, 'Name'),
  quantity: () => __(translations.stockInPreparation.detailsModal.quantity, 'Quantity'),
  location: () => __(translations.stockInPreparation.detailsModal.location, 'Localisation'),
};
