import React, {FC} from 'react';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {Option} from 'types/option';
import {ValidationErrors} from 'services/api/types';
import {EditOrderRequest} from 'features/commercial/orders/store/endpoints/edit-order';

interface Props {
  params: EditOrderRequest;
  validationErrors: ValidationErrors;
  onChangeParams: (p: any) => void;
  is_locked?: boolean;
}

const OrderVatAndTaxes: FC<Props> = props => {
  const {validationErrors, params, onChangeParams, is_locked} = props;

  const {t} = useTranslation();

  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <Row>
        <Col md={12}>
          <TextInput
            label={t(...messages.orderVtaNumber())}
            data-testid="product-name-input"
            disabled={params.with_vat || is_locked}
            value={params.vat_number}
            errors={validationErrors.vat_number}
            onChange={vat_number => {
              onChangeParams({
                ...params,
                vat_number,
              });
            }}
          />
        </Col>
        <Col md={12} className="my-2">
          <AsyncItemPicker
            key={'with-vat-' + !!params.with_vat}
            endPoint="data/vat-modes"
            disabled={params.with_vat || is_locked}
            label={t(...messages.orderVtaMode())}
            getOptionLabel={(x: Option) => x.label}
            getOptionValue={(x: Option) => x.value}
            errors={validationErrors.vat_mode}
            value={params.vat_mode}
            onChange={vat_mode => onChangeParams({...params, vat_mode})}
          />
        </Col>
        <Col md={12}>
          <div className="form-check my-3">
            <input
              className="form-check-input"
              disabled={is_locked}
              type="checkbox"
              id="is-detaxed"
              onChange={() =>
                onChangeParams({
                  ...params,
                  is_detaxed: !params.is_detaxed,
                  with_vat: false,
                })
              }
              checked={params.is_detaxed}
            />
            <label className="form-check-label" htmlFor="is-detaxed">
              {t(...messages.orderVatIncluded())}
            </label>
          </div>
          <div className="form-check my-1">
            <input
              className="form-check-input"
              type="checkbox"
              disabled={is_locked}
              id="with-vat"
              onChange={() =>
                onChangeParams({
                  ...params,
                  with_vat: !params.with_vat,
                  is_detaxed: false,
                })
              }
              checked={params.with_vat}
            />
            <label className="form-check-label" htmlFor="with-vat">
              {t(...messages.orderVatExcluded())}
            </label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderVatAndTaxes;
