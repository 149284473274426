import {useState, useEffect} from 'react';
import {useSendEmailOrderMutation} from 'features/commercial/orders/store/endpoints/action-order-send-email';
import {useGetOrderDefaultMailContentQuery} from 'features/commercial/orders/store/endpoints/get-order-default-mail-content';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';
import {Order} from 'features/commercial/orders/types/order';
import {produce} from 'immer';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Email} from 'types/phone';
import {Label} from '../send-label/use-action-order-send-label';
import {Option} from 'types/option';
import denormalizeObject from 'utils/denormalize-object';
import {ProductVariants} from '../send-label/use-action-order-send-label';
import {DocumentType} from 'features/commercial/documents/types/commercial-document';
import makeId from 'utils/make-id';
const __DEV__ = process.env.NODE_ENV === 'development';

export const useSendOrderAsEmail = (onDismiss: () => void) => {
  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;

  const {data} = useSelector(selectOrder({id}));

  const [emails, onChangeEmails] = useState({});

  const order = data?.data as Order;

  const local = order.language;

  const [submit, {isLoading, error}] = useSendEmailOrderMutation();
  const response = useGetOrderDefaultMailContentQuery({
    local: local?.value,
    type: order.document_type as DocumentType,
  });

  const serverError = parseValidationErrors(error);

  let initialLabels = denormalizeObject(
    order.commercial_products_objectified
  ) as Label[];

  const updatedLabels = initialLabels
    .map(label => {
      if (label.components === (null || undefined)) {
        return null;
      }
      const children: Label[] = [];
      const lpos = label.components;
      lpos.forEach((lpo, i) => {
        const child = {
          generate: false,
          id: lpo.id,
          commercial_product_id: lpo.commercial_product_id,
          commercial_product_type: lpo.type,
          custom_name: lpo.name,
          // this does not always exist
          suppliers: lpo.suppliers,
        };
        // @ts-ignore
        children.push(child);
      });

      if (children.length > 0) {
        const updatedLabel = {
          ...label,
          children: children,
        };
        return updatedLabel;
      }
      return label;
    })
    .filter(Boolean);

  const [labels, setLabels] = useState<Label[]>(updatedLabels as Label[]);

  const updateLabel = (
    i: number,
    ci: number | undefined,
    lableName: any,
    v: any
  ) => {
    setLabels(
      produce(labels, draft => {
        const commercialProduct = draft[i];
        if (lableName === 'stock_location') {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              debugger;
              // @ts-ignore
              draft[i]['stock_location'] = v;
              // @ts-ignore
              draft[i]['stock_location_id'] = v?.id;
            }
          } else {
            // @ts-ignore
            draft[i][lableName] = v === 'boolean' ? !draft[i][lableName] : v;
          }

          return;
        }

        if (commercialProduct) {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              // @ts-ignore
              label[lableName] = v === 'boolean' ? !label[lableName] : v;
            }
          } else {
            // @ts-ignore
            draft[i][lableName] = v === 'boolean' ? !draft[i][lableName] : v;
          }
        }
      })
    );
  };

  const syncLabelSupplierEmail = (
    i: number,
    ci: number | undefined,
    v: any,
    emails: {
      [x: string]: any;
      index?: number;
      id: ObjectId;
      email: ObjectId;
    }[]
  ) => {
    setLabels(
      produce(labels, draft => {
        const commercialProduct = draft[i];

        const filteredEmails: Option[] = v
          ? emails
              ?.filter(sa =>
                sa.some((i: {index: number}) => i.index === v.index)
              )
              .map(sa => {
                const supplieremails = sa.find(
                  (i: {index: number}) => i.index === v.index
                );
                return {
                  id: supplieremails.id,
                  value: supplieremails.email,
                };
              })
          : [];

        if (commercialProduct) {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              label.email = null;
              label.supplier = v ? v : null;
              label.supplier_id = v ? v.id : null;
              label.emails = v ? filteredEmails : [];
            }
          } else {
            draft[i].email = null;
            draft[i].supplier = v ? v : null;
            draft[i].supplier_id = v ? v.id : null;
            draft[i].emails = v ? filteredEmails : [];
          }
        }
      })
    );
  };

  const [params, setParams] = useState<Params>({
    id: id,
    body: undefined,
    emails: undefined,
    product_variants: [],
    has_order_attachment: true,
    has_options_sheet_attachment: undefined,
    has_stock_summary_attachment: undefined,
    has_packing_list_attachment: undefined,
  });

  useEffect(() => {
    if (response.data?.data && response.data?.data.content) {
      const templateEmail = response.data?.data.content;
      const fullFilledEmail = templateEmail
        .replace(
          '__order_ref__',
          order.related_documents?.map(el => el.model_ref).join('; ') ?? ''
        )
        .replace('__client_name__', order.directory?.display_name ?? '');

      setParams(prevParams => ({
        ...prevParams,
        body: fullFilledEmail,
      }));
    }
  }, [response.data?.data]);

  useEffect(() => {
    const email =
      order.document_type === 'SUPPLIER'
        ? order?.supplier_email
        : order.directory?.main_directory_email?.email;

    if (email) {
      onChangeEmails({...emails, [makeId()]: email});
    }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (labels) {
      const pv = labels.map(label => ({
        commercial_product_id: label?.id,
        sku: label.variant?.sku,

        product_variant_id: label?.variant?.id,
        quantity: label?.quantity,
        stock_location_id: label?.stock_location?.id,
        email: label?.email?.value,
        generate: label?.generate ? label?.generate : false,
        is_component: false,
        supplier_id: label?.supplier_id,
        transfer: label?.transfer ? label?.transfer : false,
      }));
      labels
        .filter(label => label.children)
        .forEach(label => {
          label.children
            ?.filter(label => label.transfer)
            .forEach(element => {
              pv.push({
                // @ts-ignore
                commercial_product_id: element?.commercial_product_id,
                product_variant_id: element?.variant?.id,
                quantity: element?.quantity,
                email: element?.email?.value,
                generate: element.generate ? element.generate : false,
                is_component: true,
                supplier_id: element?.supplier_id,
                transfer: element?.transfer ? element?.transfer : false,
              });
            });
        });

      setParams(prevParams => ({
        ...prevParams,
        product_variants: pv,
      }));
    }
  }, [labels]);

  const handleSubmit = async () => {
    const result = await submit({
      id,
      body: params?.body,
      document_type: order.document_type as DocumentType,
      emails: Object.values(emails),
      product_variants: params.product_variants,
      has_packing_list_attachment: params?.has_packing_list_attachment,
      has_order_attachment: params?.has_order_attachment,
      has_stock_summary_attachment: params?.has_stock_summary_attachment,
      has_options_sheet_attachment: params?.has_options_sheet_attachment,
    });
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    order,
    emails,
    labels,
    syncLabelSupplierEmail,
    updateLabel,
    onChangeEmails,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = {
  id: ObjectId;
  body?: string;
  emails?: string[];
  product_variants: ProductVariants[];
  has_order_attachment?: boolean;
  has_options_sheet_attachment?: boolean;
  has_stock_summary_attachment?: boolean;
  has_packing_list_attachment?: boolean;
};

export type EmailMap = Record<ObjectId, Email>;
