import React, {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import {truncate} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Badge} from 'reactstrap';
import {TemporaryFile} from 'types/temporary-file';
import useInputId from 'utils/use-input-id';

interface Props {
  errors?: string[] | string;
  file?: TemporaryFile;
  id?: string;
  isLoading?: boolean;
  onChange?: (f?: TemporaryFile | null) => void;
  onUploadClick?: () => void;
}

const ImageInput: FC<Props> = props => {
  const {file, id: inputId, onUploadClick, errors, onChange = () => {}} = props;

  const {t} = useTranslation();

  const id = useInputId(inputId);
  return (
    <div>
      <Fragment>
        <div>
          <label className="mb-0" htmlFor={id}>
            {t(...messages.file())}{' '}
          </label>
        </div>

        {file ? (
          <a href={file.url} target='_blank' rel="noreferrer" >
          <Badge
            
            className="label-file-upload upload-button d-flex"
          >
            <div className='d-flex align-items-center' >
            <Icon name="file" className="me-1" size={15} />

              {truncate(file?.name, {length: 20})}
            </div>
          </Badge>
          </a>
        ) : (
          <label
            htmlFor={id}
            className={clsx({
              'label-file-upload upload-button text-secondary': true,
              'border-danger text-danger': !!errors,
            })}
            onClick={onUploadClick}
          >
            {t(...messages.select())}
          </label>
        )}

        <div className="invalid-feedback d-block">{errors}</div>
      </Fragment>
    </div>
  );
};

export default ImageInput;
