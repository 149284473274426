import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  sourcingFees: () => __(translations.editProjectSourcingFeesSection.sourcingFees, 'Sourcing Fees'),
  invoiceNumber: () => __(translations.editProjectSourcingFeesSection.invoiceNumber, 'Invoice Number'),
  billingDate: () => __(translations.editProjectSourcingFeesSection.billingDate, 'Billing Date'),
  totalHT: () => __(translations.editProjectSourcingFeesSection.totalHT, 'Total HT'),
  marginExcl: () => __(translations.editProjectSourcingFeesSection.marginExcl, 'Margin. Excl'),
  dueDate: () => __(translations.editProjectSourcingFeesSection.dueDate, 'Due Date'),
  products: () => __(translations.editProjectSourcingFeesSection.products, 'Product(s)'),
  quantity: () => __(translations.editProjectSourcingFeesSection.quantity, 'Quantity'),
  commission: () => __(translations.editProjectSourcingFeesSection.commission, 'Commission'),

  // inputs
  comment: () => __(translations.editProjectSourcingFeesSection.comment, 'Comment'),

  // status
  toInvoice: () => __(translations.editProjectSourcingFeesSection.toInvoice, 'To invoice'),
  pending: () => __(translations.editProjectSourcingFeesSection.pending, 'Pending'),
  paid: () => __(translations.editProjectSourcingFeesSection.paid, 'Paid'),
};
