import {useState, useEffect} from 'react';
import {CommercialProduct} from 'features/commercial/documents/types/commercial-product';
import {useSendLabelOrderMutation} from 'features/commercial/orders/store/endpoints/action-order-send-label';
import {useGetOrderLabelDefaultMailContentQuery} from 'features/commercial/orders/store/endpoints/get-order-label-default-mail-content';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';
import {Order} from 'features/commercial/orders/types/order';
import {produce} from 'immer';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import denormalizeObject from 'utils/denormalize-object';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSendLabelOrder = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useSendLabelOrderMutation();
  const {t} = useTranslation();

  const [emails, onChangeEmails] = useState({});

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, id} = modalParams;

  const {data} = useSelector(selectOrder({id}));

  const o = data?.data;

  const local = o?.language;

  const response = useGetOrderLabelDefaultMailContentQuery({
    local: local?.value,
  });

  const order = data?.data as Order;

  let initialLabels = denormalizeObject(
    order.commercial_products_objectified
  ) as Label[];

  const updatedLabels = initialLabels
    .map(label => {
      if (label.components === (null || undefined)) {
        return null;
      }
      const children: Label[] = [];
      const lpos = label.components;
      lpos.forEach((lpo, i) => {
        const child = {
          generate: false,
          id: lpo.id,
          commercial_product_id: lpo.commercial_product_id,
          commercial_product_type: lpo.type,
          custom_name: lpo.name,
          // this does not always exist
          suppliers: lpo.suppliers,
        };
        // @ts-ignore
        children.push(child);
      });

      if (children.length > 0) {
        const updatedLabel = {
          ...label,
          children: children,
        };
        return updatedLabel;
      }
      return label;
    })
    .filter(Boolean)
    .filter(el => el?.commercial_product_type === 'PRODUCT');

  const [labels, setLabels] = useState<Label[]>(updatedLabels as Label[]);

  const updateLabel = (
    i: number,
    ci: number | undefined,
    lableName: any,
    v: any
  ) => {
    setLabels(
      produce(labels, draft => {
        const commercialProduct = draft[i];
        if (lableName === 'stock_location') {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              debugger;
              // @ts-ignore
              draft[i]['stock_location'] = v;
              // @ts-ignore
              draft[i]['stock_location_id'] = v?.id;
            }
          } else {
            // @ts-ignore
            draft[i][lableName] = v === 'boolean' ? !draft[i][lableName] : v;
          }

          return;
        }

        if (commercialProduct) {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              // @ts-ignore
              label[lableName] = v === 'boolean' ? !label[lableName] : v;
            }
          } else {
            // @ts-ignore
            draft[i][lableName] = v === 'boolean' ? !draft[i][lableName] : v;
          }
        }
      })
    );
  };
  const syncLabelSupplierEmail = (
    i: number,
    ci: number | undefined,
    v: any,
    emails: {
      [x: string]: any;
      index?: number;
      id: ObjectId;
      email: ObjectId;
    }[]
  ) => {
    setLabels(
      produce(labels, draft => {
        const commercialProduct = draft[i];

        const filteredEmails: Option[] = v
          ? emails
              ?.filter(sa =>
                sa.some((i: {index: number}) => i.index === v.index)
              )
              .map(sa => {
                const supplieremails = sa.find(
                  (i: {index: number}) => i.index === v.index
                );
                return {
                  id: supplieremails.id,
                  value: supplieremails.email,
                };
              })
          : [];

        if (commercialProduct) {
          if (ci || ci === 0) {
            const label = commercialProduct.children?.[ci];
            if (label) {
              label.email = null;
              label.supplier = v ? v : null;
              label.supplier_id = v ? v.id : null;
              label.emails = v ? filteredEmails : [];
            }
          } else {
            draft[i].email = null;
            draft[i].supplier = v ? v : null;
            draft[i].supplier_id = v ? v.id : null;
            draft[i].emails = v ? filteredEmails : [];
          }
        }
      })
    );
  };

  const [params, setParams] = useState<Params>({
    body: undefined,
  });

  useEffect(() => {
    if (response.data?.data && response.data?.data.content) {
      const emailTemplate = response.data?.data.content;

      const fullfilledEmail = emailTemplate.replace(
        '__product__',
        Object.values(order.commercial_products_objectified)
          .map(el => el.custom_name)
          .join('; ')
      );
      setParams(prevParams => ({
        ...prevParams,
        body: fullfilledEmail,
      }));
    }
  }, [response.data?.data]);

  useEffect(() => {
    if (labels) {
      const pv = labels.map(label => ({
        commercial_product_id: label?.id,
        sku: label.variant?.sku,

        product_variant_id: label?.variant?.id,
        quantity: label?.quantity,
        stock_location_id: label?.stock_location?.id,
        email: label?.email?.value,
        generate: label?.generate ? label?.generate : false,
        is_component: false,
        supplier_id: label?.supplier_id,
        transfer: label?.transfer ? label?.transfer : false,
      }));
      labels
        .filter(label => label.children)
        .forEach(label => {
          label.children
            ?.filter(label => label.transfer)
            .forEach(element => {
              pv.push({
                // @ts-ignore
                commercial_product_id: element?.commercial_product_id,
                product_variant_id: element?.variant?.id,
                quantity: element?.quantity,
                email: element?.email?.value,
                generate: element.generate ? element.generate : false,
                is_component: true,
                supplier_id: element?.supplier_id,
                transfer: element?.transfer ? element?.transfer : false,
              });
            });
        });

      setParams(prevParams => ({
        ...prevParams,
        product_variants: pv,
      }));
    }
  }, [labels]);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit({
      id,
      body: params?.body,
      emails: Object.values(emails),
      products: params.product_variants,
    });
    if ('data' in result) {
      onSuccess(result?.data?.data);
      dispatch(toast('success', result?.data?.message));
      onDismiss();
    }
  };

  return {
    labels,
    syncLabelSupplierEmail,
    updateLabel,
    params,
    setParams,
    message,
    isLoading,
    validationErrors: serverError.errors,
    emails,
    onChangeEmails,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
export interface Label extends CommercialProduct {
  supplier?: string;
  suppliers?: any;
  supplier_id?: ObjectId;
  arrival?: string;
  departure?: string;
  email?: any;
  emails?: Option[];
  parent_id?: ObjectId;
  generate?: boolean | undefined;
  transfer?: boolean | undefined;
  stock_location?: Option;
  children?: Label[];
  label_is_sent?: boolean;
}
export type ProductVariants = {
  commercial_product_id?: ObjectId;
  product_variant_id?: ObjectId;
  quantity?: string | undefined;
  email?: string | undefined;
  generate?: boolean | undefined;
  transfer?: boolean | undefined;
};

export type Params = {
  body?: string;
  product_variants?: ProductVariants[];
};
