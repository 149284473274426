import React, {useEffect} from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import EmailRow from './email-row';
import {useSendOrderAsEmail} from './use-action-order-send-email';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import OrderSectionByType from 'features/commercial/orders/components/order-section-by-type';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, Alert, Row, Col, ModalFooter, ModalHeader} from 'reactstrap';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';
import LabelRow from '../send-label/label-row';
import OtherEmailAddresses from '../send-label/other-email-addresses';
import makeId from 'utils/make-id';

const OrderSendEmailAction: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    errorMessage,
    validationErrors,
    params,
    emails,
    setParams,
    syncLabelSupplierEmail,
    onChangeEmails,
    updateLabel,
    labels,
    order,
  } = useSendOrderAsEmail(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          {t(...messages.orderEmailModalTitle())}
        </span>
      </ModalHeader>

      <ModalBody>
        <Row className="my-3">
          <Col className="d-flex justify-content-center align-items-center h-100">
            <input
              className="form-check-input my-1"
              type="checkbox"
              id="has_order_attachment"
              onChange={() =>
                setParams({
                  ...params,
                  has_order_attachment: !params.has_order_attachment,
                })
              }
              checked={params.has_order_attachment}
            />
            <label
              className="form-check-label my-1 mx-2"
              htmlFor="has_order_attachment"
            >
              {t(...messages.emailOrderAttachment())}
            </label>
          </Col>
          <OrderSectionByType
            orderTypeToDisplay={['SUPPLIER']}
            idProps={params.id}
          >
            <Col className="d-flex  justify-content-center align-items-center h-100">
              <input
                className="form-check-input my-1"
                type="checkbox"
                id="has_options_sheet_attachment"
                onChange={() =>
                  setParams({
                    ...params,
                    has_options_sheet_attachment:
                      !params.has_options_sheet_attachment,
                  })
                }
                checked={params.has_options_sheet_attachment}
              />
              <label
                className="form-check-label my-1 mx-2"
                htmlFor="has_options_sheet_attachment"
              >
                {t(...messages.emailOptionsSheetAttachment())}
              </label>
            </Col>
            <Col className="d-flex justify-content-center align-items-center h-100">
              <input
                className="form-check-input my-1"
                type="checkbox"
                id="has_stock_summary_attachment"
                onChange={() =>
                  setParams({
                    ...params,
                    has_stock_summary_attachment:
                      !params.has_stock_summary_attachment,
                  })
                }
                checked={params.has_stock_summary_attachment}
              />
              <label
                className="form-check-label my-1 mx-2"
                htmlFor="has_stock_summary_attachment"
              >
                {t(...messages.emailStockSummaryAttachment())}
              </label>
            </Col>
          </OrderSectionByType>
          <Col className="d-flex justify-content-center align-items-center h-100">
            <input
              className="form-check-input my-1"
              type="checkbox"
              id="has_packing_list_attachment"
              onChange={() =>
                setParams({
                  ...params,
                  has_packing_list_attachment:
                    !params.has_packing_list_attachment,
                })
              }
              checked={params.has_packing_list_attachment}
            />
            <label
              className="form-check-label my-1 mx-2"
              htmlFor="has_packing_list_attachment"
            >
              {t(...messages.emailPackingListAttachment())}
            </label>
          </Col>
        </Row>
        <OrderSectionByType
          orderTypeToDisplay={['SUPPLIER']}
          idProps={params.id}
        >
          <LabelRow
            syncLabelSupplierEmail={syncLabelSupplierEmail}
            updateLabel={updateLabel}
            labels={labels}
            validationErrors={validationErrors}
          />
        </OrderSectionByType>

        <TextInput
          rows={12}
          type="textarea"
          label={t(...messages.orderEmailSendSubject())}
          value={params.body}
          errors={validationErrors.body}
          onChange={body => setParams({...params, body})}
        />

        <OtherEmailAddresses
          emails={emails}
          onChangeEmails={onChangeEmails}
          validationErrors={validationErrors}
        />
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default OrderSendEmailAction;
