import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {useUpdateManifactureTimeMutation} from 'store/production/endpoints/update-manifacture-time';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductManifactureTime} from 'types/product-manifacture-time';
import makeId from 'utils/make-id';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useManifactureTimeForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateManifactureTimeMutation();

  const serverError = parseValidationErrors(error);

  const {id, variant: variantId} = useParams();

  const [manifactureTime, setManifactureTime] = useState<ProductionTimeMap>({});

  const [deleteRecords, setdeleteRecords] = useState([] as ObjectId[]);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      manifactureTime: Object.values(manifactureTime),
      deleteRecords,
      id,
      variant: variantId,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const addManifactureTime = () => {
    const p: ProductManifactureTime = {
      id: makeId(),
      days: 0,
    };
    setManifactureTime(
      produce(manifactureTime, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeManifactureTime = (id: ObjectId) => {
    const _manifactureTime: ProductionTimeMap = Object.assign(
      {},
      manifactureTime
    );
    delete _manifactureTime[id];
    setManifactureTime({
      ..._manifactureTime,
    });
    setdeleteRecords([...deleteRecords, id]);
  };

  const updateManifactureTime = (
    p: ProductManifactureTime,
    k: keyof ProductManifactureTime,
    v: any
  ) => {
    setManifactureTime(
      produce(manifactureTime, draft => {
        draft[p.id][k] = v;
      })
    );
  };

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data;

  useEffect(() => {
    if (variant) {
      let tmp = {};
      variant.production_times.map((p: any) => {
        const pt = produce({} as ProductionTimeMap, draft => {
          draft[p.id] = p;
        });
        tmp = {...tmp, ...pt};
      });

      setManifactureTime({...tmp});
    }
  }, [variant]);

  const filterOption = (option: Option<Directory>, inputValue: string) => {
    const isSelected = Object.values(manifactureTime).some(
      x => x.id === option.value
    );

    if (isSelected) {
      return false;
    }
    const n = option.data?.display_name?.toString() ?? '';
    return n.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
  };

  return {
    addManifactureTime,
    filterOption,
    removeManifactureTime,
    submit: handleSubmit,
    updateManifactureTime,
    manifactureTime,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
  };
};

type ProductionTimeMap = Record<ObjectId, ProductManifactureTime>;
