import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {VariantAttachmentsRequest} from 'store/production/endpoints/update-attachments';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';
import {
  StockMovement,
  useSaveStockMovementMutation,
} from '../../store/endpoints/store-stock-movement';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {CommercialProduct} from '../../types/commercial-product';
import {uiActions} from 'store/ui/ui-slice';
import {useDispatch} from 'react-redux';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [update, {isLoading, error, isSuccess}] =
    useSaveStockMovementMutation();

  const serverError = parseValidationErrors(error);

  const [movements, setMovements] = useState<MovementMap>({});

  const [deletedRecords, setDeletedRecords] = useState<
    Record<ObjectId, boolean>
  >({});

  const {item, type, doc} = useSelector(selectActiveModalParams);

  const variant = (item as CommercialProduct).variant;

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      movements,
      id: variant?.id,
      commercial_product_id: item.id,
      type,
      doc,
    });
    if ('data' in result) {
      dispatch(uiActions.dismissModal());
    }
  };

  const addMovement = () => {
    const p: StockMovement = {
      id: makeId(),
    };
    setMovements(
      produce(movements, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeMovement = (id: ObjectId) => {
    const _attachments: MovementMap = Object.assign({}, movements);
    delete _attachments[id];
    deletedRecords[id] = !deletedRecords[id];
    setMovements({
      ..._attachments,
    });
  };
  const updateMovement = (p: StockMovement, k: keyof StockMovement, v: any) => {
    setMovements(
      produce(movements, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };

  //   const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  //   const variant = data?.data;

  //   useEffect(() => {
  //     if (variant) {
  //       const tmp = variant.attachements.reduce((a, c) => {
  //         a[c.id] = c;
  //         return a;
  //       }, {} as MovementMap);
  //       setMovements(tmp);
  //       // @ts-ignore
  //       setParams({...params, in_catalog: variant?.cover?.id});
  //     }
  //   }, [variant]);

  useEffect(() => {
    addMovement();
  }, []);

  return {
    movements,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
    addMovement,
    removeMovement,
    submit: handleSubmit,
    updateMovement,
    movementType: type,
  };
};

type MovementMap = Record<ObjectId, StockMovement>;

type Params = Partial<VariantAttachmentsRequest>;
