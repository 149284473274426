import React, {FC} from 'react';
import messages from './messages';
import BarCharts from 'components/bar-charts';
import Currency from 'components/currency/currency';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, CardHeader, Col} from 'reactstrap';
import {useGetDirectoryStatsQuery} from 'store/directory/endpoints/get-directory-stats';
import {ObjectId} from 'types/object-id';
import {useParams} from 'react-router';

interface Props {
  id: ObjectId;
  is_supplier: boolean;
}

const DirectoryCharts: FC<Props> = props => {
  const {t} = useTranslation();
  const {is_supplier} = props;
  const {id} = useParams();

  const {data} = useGetDirectoryStatsQuery({id});
  const stats = data?.data ?? [];

  const totalTurnOver = stats?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.total_ht,
    0
  );
  const totalSales = stats?.reduce(
    (accumulator, currentValue) =>
      accumulator + parseInt(currentValue?.product_count),
    0
  );
  const turnoverSeries = [
    {
      name: is_supplier
        ? t(...messages.supplierTurnover())
        : t(...messages.turnover()),
      data: !stats || stats[0]?.year === 0 ? [] : stats?.map(x => x?.total_ht),
    },
  ];

  const invoiceSeries = [
    {
      name: is_supplier
        ? t(...messages.supplierProductCount())
        : t(...messages.sales()),
      data:
        !stats || stats[0]?.year === 0
          ? []
          : stats?.map(x => parseInt(x?.product_count)),
    },
  ];
  const years = stats?.map(x => x?.year.toString()) as string[];
  const invoiceNumberOfPoint = Math.max(...invoiceSeries[0].data);
  const turnoverNumberOfPoint = Math.max(...turnoverSeries[0].data);
  return (
    <>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0">
                {' '}
                {is_supplier
                  ? t(...messages.supplierTurnover())
                  : t(...messages.turnover())}{' '}
              </h4>
              <div>
                <span className="fw-bold">{t(...messages.total())}</span>
                <span className="float-right font-weight-bold">
                  {' '}
                  <Currency value={totalTurnOver} />{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={turnoverSeries}
              categories={years}
              numberOfPoint={turnoverNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-0">
                {' '}
                {is_supplier
                  ? t(...messages.supplierProductCount())
                  : t(...messages.sales())}{' '}
              </h4>

              <div>
                <span className="fw-bold">{t(...messages.total())}</span>
                <span className="float-right font-weight-bold">
                  {' '}
                  {totalSales}{' '}
                </span>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <BarCharts
              series={invoiceSeries}
              categories={years}
              numberOfPoint={invoiceNumberOfPoint}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default DirectoryCharts;
