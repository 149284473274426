import ItemPicker from 'components/item-picker';
import React, {FC, useContext} from 'react';
import transferContext from './transfer-context';

interface Props {
  row: any;
}

export const ItemSuplier: FC<Props> = props => {
  const {row} = props;
  const {validationErrors, onUpdateItem, items, isTransferred} =
    useContext(transferContext);
  const uuid = row?.uuid;

  return (
    <>
      <ItemPicker
        options={row.suppliers ?? []}
        isDisabled={
          isTransferred(uuid) ||
          row.is_transferred_to_supplier_order ||
          !row?.is_abele_to_be_transfer_to_supplier_order
        }
        value={items[uuid ?? -1]?.supplier}
        onChange={x => onUpdateItem(uuid, 'supplier', x)}
      />
    </>
  );
};
