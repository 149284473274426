import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  duplicateProductTitle: () => __(translations.actionProductPage.duplicateProductTitle, 'Deplucat variant'),
  duplicateProductDescription: () =>
    __(
      translations.actionProductPage.duplicateDetails,
      'This is useful if you want to create another variant that has the same properties as the current one, and make some minor changes to it (e.g color, size, dimensions…)'
    ),
  deleteProductTitle: () => __(translations.actionProductPage.deleteProductTitle, 'Delete variant'),
  deleteProduct: () => __(translations.common.delete, 'Delete'),
  deleteProductDescription: () =>
    __(translations.actionProductPage.deleteDetails, 'This deletes the product and all its variants permanently. Once this is done, there is no going back. Please be certain.'),
  archiveTitle: () => __(translations.actionProductPage.archiveTitle, 'Archive variant'),
  alertsArchiveManyVariants: () => __(translations.actionProductPage.alertsArchiveManyVariants, 'selecting all variants will archive the product'),
  archive: () => __(translations.common.archive, 'Archive'),
  archiveDetails: () => __(translations.actionProductPage.archiveDetails, 'This will move this variant to old collection/ patrimoine IMH  list'),
  deleteSelectionTitle: () => __(translations.actionProductPage.deleteSelectionTitle, 'Delete selection'),
  alertsDeleteManyVariants: () => __(translations.actionProductPage.alertsDeleteManyVariants, 'selecting all variants will delete the product'),
  deleteSelectionDescription: () =>
    __(translations.actionProductPage.deleteSelectionDetails, 'This deletes a selection of variant of  product  permanently. Once this is done, there is no going back. Please be certain.'),
  blockedTitle: () => __(translations.actionProductPage.blockedTitle, 'Block variant'),
  blocked: () => __(translations.common.blocked, 'Block'),
  blockedDetails: () => __(translations.actionProductPage.blockedDetails, 'This will block this variant'),
  alertsBlockManyVariants: () => __(translations.actionProductPage.alertsBlockedManyVariants, 'selecting all variants will block the product'),
  toOldCollectionTitle: () => __(translations.actionProductPage.toOldCollectionTitle, 'Move to old collection'),
  toOldCollection: () => __(translations.actionProductPage.toOldCollection, 'Move'),
  toOldCollectionDetails: () => __(translations.actionProductPage.toOldCollectionDetails, 'This will move this variant to old collection/ patrimoine IMH  list'),
  alertsToOldCollectionManyVariants: () => __(translations.actionProductPage.alertsToOldCollectionManyVariants, 'selecting all variants will move the product to old collection'),
};
