import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  sellsFFE: () => __(translations.editProjectSellsFFESection.sellsFFE, 'Sells FF&E'),
  invoiceNumber: () => __(translations.editProjectSellsFFESection.invoiceNumber, 'Invoice Number'),
  billingDate: () => __(translations.editProjectSellsFFESection.billingDate, 'Billing Date'),
  totalHT: () => __(translations.editProjectSellsFFESection.totalHT, 'Total HT'),
  globalMarginExcl: () => __(translations.editProjectSellsFFESection.globalMarginExcl, 'Global margin. Excl'),
  dueDate: () => __(translations.editProjectSellsFFESection.dueDate, 'Date de paiement'),
  products: () => __(translations.editProjectSellsFFESection.products, 'Product(s)'),
  quantity: () => __(translations.editProjectSellsFFESection.quantity, 'Quantity'),

  // inputs
  comment: () => __(translations.editProjectSellsFFESection.comment, 'Comment'),

  // status
  toInvoice: () => __(translations.editProjectSellsFFESection.toInvoice, 'To invoice'),
  pending: () => __(translations.editProjectSellsFFESection.pending, 'Pending'),
  paid: () => __(translations.editProjectSellsFFESection.paid, 'Paid'),

  // options
  mobilier: () => __(translations.editProjectSellsFFESection.mobilier, 'Mobilier'),
  petitsObjets: () => __(translations.editProjectSellsFFESection.petitsObjets, 'Petits Objets'),
};
