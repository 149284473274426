import {mergeUser as mergeUserAction} from '../actions/merge-user';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {StoredAuthState} from 'services/auth-service';
import {WritableDraft} from 'types/writable-draft';

const mergeUserMatcher = mergeUserAction.fulfilled;

/**
 * User already a member, no need to sign him up again
 *
 * @param state
 * @param param1
 */
const mergeUserReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<StoredAuthState>
) => {
  state.accessToken = payload.accessToken;
  state.user = payload.user;
};

export const mergeUser = {
  matcher: mergeUserMatcher,
  reducer: mergeUserReducer,
};
