export const lastDecade = (): string[] => {
  const currentYear = new Date().getFullYear() + 1;
  const years: string[] = [];

  for (let i = 0; i < 10; i++) {
    years.push((currentYear - i).toString());
  }

  return years;
};
