import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.creditsPage.title, 'Credits list'),
  credit: () => __(translations.creditsPage.credit, 'Credit'),
  client: () => __(translations.creditsPage.client, 'Client'),
  invoice: () => __(translations.creditsPage.invoice, 'Invoice'),
  amount: () => __(translations.creditsPage.amount, 'Amount'),
  amountUsed: () => __(translations.creditsPage.amountUsed, 'Used amount'),
  solde: () => __(translations.creditsPage.solde, 'Solde'),
  usedon: () => __(translations.creditsPage.usedon, 'Used on'),
  creditType: () => __(translations.creditsPage.creditType, 'Credit type'),
  markAsUsed: () => __(translations.creditsPage.markAsUsed, 'Mark as used'),
  markAsAvailable: () => __(translations.creditsPage.markAsAvailable, 'Mark as available'),
  archive: () => __(translations.creditsPage.archive, 'Archive'),
  removeFromArchive: () => __(translations.creditsPage.removeFromArchive, 'Remove from archives'),
  status: () => __(translations.creditsPage.status, 'Status'),
  date: () => __(translations.creditsPage.date, 'Date'),
  billingDate: () => __(translations.creditsPage.billingDate, 'Billing date'),

  //tabs
  creditsList: () => __(translations.creditsPage.creditsList, 'Credits list'),
  creditsListArchived: () => __(translations.creditsPage.creditsListArchived, 'Accounting credits'),

  //tag rows
  totalSoldRemaining: () => __(translations.creditsPage.totalSoldRemaining, 'Total remaining sold'),
  totalCreditsGenerated: () => __(translations.creditsPage.totalCreditsGenerated, 'Total credits generated'),
};
