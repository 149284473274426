import {STATUS} from 'constants/status';
import messages from 'components/status-badge/messages';
import {OverpaymentsStatuses} from '../types';

export const NAV_LINKS: {
  title: () => [string, ...any[]];
  status: OverpaymentsStatuses;
}[] = [
  {
    title: messages.available,
    status: 'AVAILABLE',
  },

  {
    title: messages.used,
    status: 'USED',
  },
  {
    title: messages.refunded,
    status: 'REFUND',
  },
  {
    title: messages.archived,
    status: 'ARCHIVED',
  },
];
