import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderMasterDetail.title, 'Order #{{id}}'),
  helmetTitle: () => __(translations.orderMasterDetail.helmetTitle, 'Order {{ref}}'),
  ordersListPageTitle: () => __(translations.orderMasterDetail.ordersListPageTitle, 'Orders'),
  customerOrdersListPageTitle: () => __(translations.orderMasterDetail.customerOrdersListPageTitle, 'Customer orders'),
  stockOrdersListPageTitle: () => __(translations.orderMasterDetail.stockOrdersListPageTitle, 'Stock orders'),
  supplierOrdersListPageTitle: () => __(translations.orderMasterDetail.supplierOrdersListPageTitle, 'Supplier orders'),
};
