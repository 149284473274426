import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  dashboard: () => __(translations.userDetailsPage.dashboard, 'Dashboard'),
  pageTitle: () => __(translations.userDetailsPage.pageTitle, 'User profile'),
  enabled: () => __(translations.userDetailsPage.enabled, 'Enabled'),
  disabled: () => __(translations.userDetailsPage.disabled, 'Disabled'),
  users: () => __(translations.userDetailsPage.users, 'Users'),
  title: () => __(translations.userDetailsPage.title, 'Profile'),
  phoneNumber: () => __(translations.userDetailsPage.phoneNumber, 'Phone number'),
  lastName: () => __(translations.userDetailsPage.lastName, 'Last name'),
  address: () => __(translations.userDetailsPage.address, 'Address'),
  firstName: () => __(translations.userDetailsPage.firstName, 'First name'),
  dateOfBirth: () => __(translations.userDetailsPage.dateOfBirth, 'Date of birth'),
  permissions: () => __(translations.userDetailsPage.permissions, 'Permissions'),
  email: () => __(translations.userDetailsPage.email, 'Email'),
};
