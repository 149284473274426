import React, {FC} from 'react';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import PayCell from 'features/commercial/invoices/compenent/cells-features/pay-cell';
import {InvoiceCompact} from 'features/commercial/invoices/types/invoice';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import Currency from 'components/currency/currency';
import TtcColumn from 'features/commercial/documents/components/ttc-column/ttc-column';
import CostumeCurrency from 'components/costume-currency/costume-currency';
import TooltipCell from 'components/tooltip-cell';
import RelatedDocument from 'types/related-document';
import clsx from 'clsx';

const columns: ColumnDef<InvoiceCompact>[] = [
  {
    header: r => <Trans i18nKey="invoicesPage.client" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.directory_id}/general`}
        target="_blank"
      >
        <b> {row.display_name}</b>
      </Link>
    ),
    cell: r => r.getValue(),
    id: 'display_name',
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="creditsPage.credit" />,
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} column="ref" type="INVOICE">
        <Link to={`/dashboard/invoices/${row.id}/details`} target="_blank">
          <b>{row.document_ref}</b>
        </Link>
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
    id: 'document_ref',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="creditsPage.invoice" />,
    accessorFn: row =>
      row.related_documents && row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <Link
              key={`${related_document.model_type}-${related_document.model_id}`}
              to={guessUrl(related_document)}
              target="_blank"
            >
              <b>{related_document.model_ref}</b>
            </Link>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.status" />,
    enableSorting: true,
    id: 'status',
    cell: r => r.getValue(),
    accessorFn: row => <StatusBadge status={row.status} />,
  },
];

export default columns;

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};
