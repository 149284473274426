import {AccountState} from './types';

const initialState: AccountState = {
  newsletterEnabled: undefined, // please, note that this is not "false"
  notificationsEnabled: undefined, // please, note that this is not "false"
  readNotifications: {},
  recentNotifications: [],
  unreadNotificationsCount: 0,
};

export default initialState;
