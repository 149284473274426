import {useState} from 'react';
import addMonths from 'date-fns/addMonths';
import {useShowDocumentCategoriesQuery} from 'features/commercial/quotations/store/endpoints/show-document-category';
import {
  StoreQuotationRequest,
  useStoreQuotationMutation,
} from 'features/commercial/quotations/store/endpoints/store-quotation';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {Directory} from 'types/directory';
import {DocumentCategory} from 'types/document-category';
import {now} from 'utils/now';
import realDate from 'utils/real-date';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useQuotationForm = () => {
  const [storeQuotation, {isLoading, error}] = useStoreQuotationMutation();

  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const result = await storeQuotation(params);

    if ('data' in result) {
      const quotation = result?.data.data;
      navigate(`/dashboard/quotations/${quotation.id}/details`);
    }
  };

  const [params, setParams] = useState<Params>({
    client: undefined,
    category: undefined,
    type: undefined,
    project: undefined,
    origin: undefined,
    language: undefined,
    created_at: now(),
    deadline: realDate(addMonths(new Date(), 1)),
  });

  const {data} = useShowDocumentCategoriesQuery();

  const categories = (data?.data || []) as DocumentCategory[];

  const syncParams = (client: Directory) => {
    const updatedParams = {...params};

    updatedParams.client = client;

    updatedParams.category = guessCategory(client, categories);

    setParams(updatedParams);
  };

  return {
    setParams,
    params,
    syncParams,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = StoreQuotationRequest;

const guessCategory = (client: Directory, categories: DocumentCategory[]) => {
  let maybeCategory;

  if (client) {
    if (client.is_private) {
      maybeCategory = categories?.filter(c => c.type === 'PRIVATE').at(0);
    }
    if (client.is_reseller) {
      maybeCategory = categories?.filter(c => c.type === 'RESELLER').at(0);
    }
    if (client.is_decorator) {
      maybeCategory = categories?.filter(c => c.type === 'DECORATOR').at(0);
    }
    if (client.is_luxury_brand) {
      maybeCategory = categories?.filter(c => c.type === 'LUXURY_BRAND').at(0);
    }
  }

  return maybeCategory;
};
