import retrieveFcmToken from '../actions/retrieve-fcm-token';
import {AuthState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {WritableDraft} from 'types/writable-draft';

const fcmTokenRetrievedMatcher = retrieveFcmToken.fulfilled;

/**
 * Updates the fcm token
 *
 */
const fcmTokenRetrievedReducer = (
  state: WritableDraft<AuthState>,
  action: PayloadAction<string | undefined>
) => {
  state.fcmToken = action.payload;
};

export const fcmTokenRetrieved = {
  matcher: fcmTokenRetrievedMatcher,
  reducer: fcmTokenRetrievedReducer,
};
