import React, {FC} from 'react';
import messages from './messages';
import {actions} from './slice';
import {costCategories} from './slice/constants';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown} from 'reactstrap';

interface Props {
  primary?: boolean;
}

const CostDropDown: FC<Props> = props => {
  const {primary} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret color={primary ? 'primary' : 'light'}>
        {t(...messages.addCost())}
        <Icon name="chevron-down" className="ms-1" size={15} />
      </DropdownToggle>
      <DropdownMenu>
        {costCategories
          .filter(x => x !== 'components')
          .map(x => (
            <DropdownItem key={x} onClick={() => dispatch(actions.addCost(x))}>
              <Trans i18nKey={`costsTab.${x}`} />
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default CostDropDown;
