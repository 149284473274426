import {createAsyncThunk} from '@reduxjs/toolkit';
import {SharedPrefs} from 'constants/shared-prefs';
import appService from 'services/app-service';
import i18nService from 'services/i18n-service';
import {saveString} from 'services/storage-service';
import {delay} from 'utils/delay';

/**
 * Changes the application locale
 *
 */
export const changeAppLocale = createAsyncThunk(
  'global/changeAppLocale',
  async (locale: string) => {
    i18nService.applyLocale(locale);
    await saveString(SharedPrefs.APP_LOCALE, locale);
    await appService.applyLocaleChangeAdaptations(locale);
    await delay(2000);
    return locale;
  }
);
