import React, {FC} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {MODAL} from 'features/modal-notch/constants';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {Pricing} from 'types/pricing';

interface Props {
  row: Pricing;
}

const SituationsPreview: FC<Props> = props => {
  const {row} = props;

  const dispatch = useDispatch();

  const preview = () => {
    dispatch(showModal(MODAL.PRICING_SITUATIONS, row));
  };

  return (
    <div>
      <Button onClick={preview} color="link" size="sm">
        <Icon name="eye" size={14} />
      </Button>
    </div>
  );
};

export default SituationsPreview;
