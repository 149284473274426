import {selectDomain} from '.';
import {createSelector} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';

export default (name: string) =>
  createSelector(selectDomain, listing => {
    const vals = listing.deletedResources[name] ?? {};
    const zombies = Object.keys(vals);
    const dead = zombies.filter(z => !!vals[z]) as ObjectId[];
    return dead;
  });
