import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderGeneralInformation.title, 'General information'),
  client: () => __(translations.orderGeneralInformation.client, 'Client'),
  supplier: () => __(translations.orderGeneralInformation.supplier, 'Supplier'),
  countermark: () => __(translations.orderGeneralInformation.countermark, 'Countermark'),
  orderInvoiceDate: () => __(translations.orderGeneralInformation.orderInvoiceDate, 'Invoice date'),
  orderDeadline: () => __(translations.orderGeneralInformation.orderDeadline, 'Deadline date'),
  privateComment: () => __(translations.orderGeneralInformation.privateComment, 'Private comment'),
  orderCategory: () => __(translations.orderGeneralInformation.orderCategory, 'Invoice Category'),
  orderModel: () => __(translations.orderGeneralInformation.orderModel, 'Model'),
  orderContact: () => __(translations.orderGeneralInformation.orderContact, 'Contact'),
  orderProject: () => __(translations.orderGeneralInformation.orderProject, 'Project'),
  orderOrigin: () => __(translations.orderGeneralInformation.orderOrigin, 'Origin'),
  clientReference: () => __(translations.orderGeneralInformation.clientReference, 'Client reference'),
  orderLanguage: () => __(translations.orderGeneralInformation.orderLanguage, 'Language'),
  orderCurrency: () => __(translations.orderGeneralInformation.orderCurrency, 'Currency'),
  isSupplierAfterSaleService: () => __(translations.orderGeneralInformation.isSupplierAfterSaleService, 'Supplier order for after-sales service'),
  isAfterSaleService: () => __(translations.orderGeneralInformation.isAfterSaleService, 'Invoice for after-sales service.'),
  changesCommited: () => __(translations.orderGeneralInformation.changesCommited, 'Your changes has been saved successfully!'),
  announcedDeadline: () => __(translations.orderGeneralInformation.announcedDeadline, 'Announced deadline'),
  orderCurrencyConversion: () => __(translations.orderGeneralInformation.orderCurrencyConversion, 'Currency conversion'),
  designation: () => __(translations.orderGeneralInformation.designation, 'Designation'),
};
