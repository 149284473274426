import {initialState} from './initial-state';
import {LayoutState} from './types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeLayout(state, action: PayloadAction<LayoutState['layoutType']>) {
      state.layoutType = action.payload;
    },
    changePreloader(state, action: PayloadAction<boolean>) {
      state.isPreloader = action.payload;
    },
    changeLayoutWidth(
      state,
      action: PayloadAction<LayoutState['layoutWidth']>
    ) {
      state.layoutWidth = action.payload;
    },
    changeSidebarTheme(
      state,
      action: PayloadAction<LayoutState['leftSideBarTheme']>
    ) {
      state.leftSideBarTheme = action.payload;
    },
    changeSidebarType(
      state,
      action: PayloadAction<LayoutState['leftSideBarType']>
    ) {
      state.leftSideBarType = action.payload;
    },
    changeTopbarTheme(
      state,
      action: PayloadAction<LayoutState['topbarTheme']>
    ) {
      state.topbarTheme = action.payload;
    },
    changeLayoutPosition(state, action: PayloadAction<boolean>) {
      state.layoutPosition = action.payload;
    },
    changeLayoutTheme(state, action: PayloadAction<LayoutState['layoutMode']>) {
      state.layoutMode = action.payload;
    },
    showRightSidebar(state, action: PayloadAction<boolean>) {
      state.showRightSidebar = action.payload;
    },
    showSidebar(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
    toggleLeftmenu(state, action: PayloadAction<boolean>) {
      state.leftMenu = action.payload;
    },
  },
  extraReducers: build => {},
});

export default layoutSlice;

export const layoutActions = layoutSlice.actions;
