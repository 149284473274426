import React from 'react';
import {FC} from 'react';
import Icon from '@ailibs/feather-react-ts';
import {Link} from 'react-router-dom';
import {User} from 'types/user';

interface GetOptionLabelProps {
  user: User;
}

const GetOptionLabel: FC<GetOptionLabelProps> = props => {
  const {user} = props;

  return (
    <div className="d-flex justify-content-between align-items-center">
      {user.profile?.full_name}

      <Link target="_blank" to={`/dashboard/users/${user.id}/profile`}>
        <Icon name="external-link" size={15} />
      </Link>
    </div>
  );
};

export default GetOptionLabel;
