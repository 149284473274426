import React, {FC} from 'react';
import PhoneInputText from '../../../../../components/phone-input';
import messages from './messages';
import {PhoneMap} from './use-coordinate-tab';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Row, Col, FormGroup, Input, Label} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Phone} from 'types/phone';

interface Props {
  phone: PhoneMap;
  removePhone: (id: ObjectId) => void;
  updatePhone: (p: Phone, k: keyof Phone, v: any) => void;
  setMainPhoneId: (id: ObjectId) => void;
  mainPhoneId: ObjectId;
  validationErrors: ValidationErrors;
}

const PhoneRow: FC<Props> = props => {
  const {
    phone,
    removePhone,
    updatePhone,
    validationErrors,
    mainPhoneId,
    setMainPhoneId,
  } = props;

  const {t} = useTranslation();

  return (
    <>
      {Object.values(phone).map((p, i) => (
        <Row className="mb-3" key={p.id}>
          <Col md={3}>
            <AsyncItemPicker
              endPoint="data/phone-number-types"
              getOptionLabel={(x: Option) => x.label}
              getOptionValue={(x: Option) => x.value}
              label={t(...messages.type())}
              errors={validationErrors[`phone_numbers.${i}.type`]}
              onChange={x => updatePhone(p, 'type', x)}
              value={p.type}
            />
          </Col>
          <Col md={3}>
            <PhoneInputText
              label={t(...messages.number())}
              limitMaxLength={true}
              country="FR"
              enableSearch={true}
              international
              className="mb-1"
              value={p.number}
              errors={validationErrors[`phone_numbers.${i}.number`]}
              onChange={number => updatePhone(p, 'number', number)}
            />
          </Col>
          <Col md={4}>
            <TextInput
              label={t(...messages.comment())}
              type="textarea"
              rows={1}
              errors={validationErrors[`phone_numbers.${i}.comment`]}
              value={p.comment}
              onChange={x => updatePhone(p, 'comment', x)}
            />
          </Col>
          <Col md={1}>
            <Label className="h-100">
              <FormGroup switch className="align-items-center h-100  d-flex ">
                <Input
                  className="me-1"
                  type="switch"
                  id={`switcher-${p.id}`}
                  role="switch"
                  checked={mainPhoneId === p.id}
                  errors={validationErrors[`phones.${i}.is_main`]}
                  onChange={x => setMainPhoneId(p.id)}
                />
                <Label htmlFor={`switcher-${p.id}`} className="mb-0">
                  {t(...messages.main())}
                </Label>
              </FormGroup>
            </Label>
          </Col>
          <Col md={1}>
            {mainPhoneId !== p.id && (
              <div className="d-flex align-items-center justify-content-end py-4">
                <Button
                  color="link"
                  className="text-danger p-0 py-1 text-center"
                  onClick={() => removePhone(p.id)}
                >
                  <Icon name="trash-2" size={18} />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ))}
    </>
  );
};

export default PhoneRow;
