import React, {FC} from 'react';
import {CalendarOrder} from 'features/stock/types/calendar-order';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';

interface Props {
  event?: any;
  row: CalendarOrder;
}

const CalendarEvent: FC<Props> = props => {
  const {row} = props;

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showModal(MODAL.EDIT_ORDER_INFO, row));
  };
  return (
    <div
      id={`supplier-order-code-${row.id}`}
      className="text-start ps-1 calendar-event"
      onClick={handleClick}
      style={{
        backgroundColor: row.supplier_color_code,
        border: `2px solid ${row.supplier_color_code}`, // shows on print
        borderRadius: 3,
      }}
    >
      <AsyncPreviewTooltip column="ref" type="ORDER" id={row.id}>
        <div>
          {row.document_ref}
          <div>
            {row.related_documents.map(doc => (
              <div key={doc.id}>
                <div>{doc.model_ref}</div>
                <div>{doc?.client?.display_name}</div>
              </div>
            ))}
          </div>
          <div>{row.client_name}</div>
        </div>
      </AsyncPreviewTooltip>
    </div>
  );
};

export default CalendarEvent;
