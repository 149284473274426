import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

export const getAllSettings = api.injectEndpoints({
  endpoints: builder => ({
    getAllSettings: builder.query<Response, void>({
      query: () => {
        return {
          method: 'GET',
          url: `settings/all`,
        };
      },
    }),
  }),
});

type Response = ResponseData<Settings>;

export const {useGetAllSettingsQuery, useLazyGetAllSettingsQuery} =
  getAllSettings;

export const {util} = getAllSettings;

export const getAllSettingsEndpoint = getAllSettings.endpoints.getAllSettings;

export const selectAllSettings = getAllSettings.endpoints.getAllSettings.select;
