import React, {FC, useContext, useState} from 'react';
import AddressItem from '../../components/address-item/address-item';
import messages from '../../components/messages';
import selectAddresses from '../../store/selectors/select-addresses';
import invoiceContext from '../document-context/document-context';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {CompactSupplierOrder} from 'features/commercial/orders/types/order';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Col, DropdownMenu, Dropdown, Row} from 'reactstrap';
import {DropdownItem, DropdownToggle} from 'reactstrap';
import showAddressModal from 'store/ui/actions/show-address-modal';
import showModal from 'store/ui/actions/show-modal';

const AddressesSection: FC<{}> = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const addresses = useSelector(selectAddresses);

  const {validationErrors, doc, is_locked} = useContext(invoiceContext);

  const cannotAddMore = is_locked || addresses.length > 1;

  const [isOpen, setIsOpen] = useState(false);

  const isSupplierOrder = doc?.document_type === 'SUPPLIER';

  const directoryId = isSupplierOrder
    ? (doc as unknown as CompactSupplierOrder).supplier_id
    : doc?.directory_id;

  const handleCreateNew = () => {
    if (directoryId) {
      dispatch(showAddressModal(directoryId, true));
    }
  };

  return (
    <Col sm="6">
      <div className="align-self-end">
        {!cannotAddMore && (
          <div className="d-flex justify-content-end">
            <Dropdown
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              className="dropdown d-inline-block"
            >
              <DropdownToggle
                className="btn  btn-link text-important text-decoration-none ps-0 pt-0"
                tag="button"
              >
                <Icon name="plus-circle" size={20} className="ms-0 me-2" />
                {t(...messages.newAddress())}
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <li>
                  <DropdownItem
                    disabled={cannotAddMore}
                    onClick={() =>
                      dispatch(
                        showModal(MODAL.ADDRESS_PICKER_MODAL, {
                          directory_id: directoryId ?? '',
                        })
                      )
                    }
                  >
                    {t(...messages.importAddress())}
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem
                    onClick={handleCreateNew}
                    disabled={!directoryId || cannotAddMore}
                  >
                    {t(...messages.newAddress())}
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
        <Row className="justify-content-end">
          {addresses.map((a, i) => (
            <AddressItem key={a.id} address={a} index={i} />
          ))}
        </Row>
        <div className="d-flex justify-content-end">
          <p className="text-danger">{validationErrors[`addresses`]}</p>
        </div>
      </div>
    </Col>
  );
};

export default AddressesSection;
