import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.directoryFamiliesPage.title, 'Directory families'),
  families: () => __(translations.directoryFamiliesPage.families, 'Families'),
  name: () => __(translations.directoryFamiliesPage.name, 'Name'),
  action: () => __(translations.directoryFamiliesPage.action, 'Action'),
  createdAt: () => __(translations.directoryFamiliesPage.createdAt, 'Created at'),
  category: () => __(translations.directoryFamiliesPage.category, 'Category'),
  edit: () => __(translations.directoryFamiliesPage.edit, 'Edit'),
  remove: () => __(translations.directoryFamiliesPage.remove, 'Remove'),
  directories: () => __(translations.directoryFamiliesPage.directoryPageTitle, 'Directories'),
  addbtn: () => __(translations.directoryFamiliesPage.addbtn, 'Add'),
};
