import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUploadTemporaryFileMutation} from 'store/global/endpoints/upload-temporary-file';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUploadFile = () => {
  const [uploadFlie, {isLoading, error}] = useUploadTemporaryFileMutation();

  const serverError = parseValidationErrors(error);

  const handleSubmit = async (params: {file: File}) => {
    const result = await uploadFlie({data: params});
    if ('data' in result) {
      return result;
    }
  };

  return {
    errorMessage: serverError.message,
    isLoading,
    submit: handleSubmit,
    validationErrors: serverError.errors,
  };
};
