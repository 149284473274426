import React, {FC, useMemo} from 'react';
import ItemPicker from 'components/item-picker';
import {useTranslation} from 'react-i18next';
import messages from './messages';

interface Props {
  value?: boolean;
  onChange?: (v?: boolean) => void;
  label?: string;
}

const YesNoPicker: FC<Props> = props => {
  const {value, onChange, ...rest} = props;

  const {t} = useTranslation();

  const booleanOptions = useMemo(
    () => [
      {
        id: 0,
        name: t(...messages.no()),
      },
      {
        id: 1,
        name: t(...messages.yes()),
      },
    ],
    []
  );

  return (
    <ItemPicker
      value={!!value ? 1 : 0}
      // @ts-ignore
      onChange={v => onChange?.call(null, v?.id ? true : false)}
      options={booleanOptions}
      getOptionValue={(v: any) => v.id}
      {...rest}
    />
  );
};

export default YesNoPicker;
