import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.createSupplierOrderPage.title, 'Create supplier orders'),
  supplierOrders: () => __(translations.createSupplierOrderPage.supplierOrders, 'Supplier orders'),
  supplierOrderType: () => __(translations.createSupplierOrderPage.supplierOrderType, 'Type of supplier order '),
  supplierOrderLanguage: () => __(translations.createSupplierOrderPage.supplierOrderLanguage, 'Language '),
  supplierOrderDate: () => __(translations.createSupplierOrderPage.supplierOrderDate, 'Order date '),
  supplierOrderDeadline: () => __(translations.createSupplierOrderPage.supplierOrderDeadline, 'Deadline date '),
  supplier: () => __(translations.createSupplierOrderPage.supplier, 'Supplier '),
  addNewCompanyClient: () => __(translations.createSupplierOrderPage.addNewCompanyClient, 'Add Company '),
  addNewPrivateClient: () => __(translations.createSupplierOrderPage.addNewPrivateClient, 'Add Private'),
};
