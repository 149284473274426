import {CommercialDocument} from '../../types/commercial-document';
import {actions} from '../slice';
import {CommercialDocumentState} from '../types';
import {PayloadAction, createAction} from '@reduxjs/toolkit';
import {CommercialProductOption} from 'types/commercial-product-option';
import denormalizeObject from 'utils/denormalize-object';
import normalizeArray from 'utils/normalize-array';

export default createAction(
  actions.hydrateFields.type,
  function prepare(doc: CommercialDocument): PayloadAction<ReturnType> {
    const products = denormalizeObject(doc?.commercial_products_objectified);

    const productOptions = products.reduce(
      (a, c) => a.concat(c?.options ?? []).concat(c.components ?? []),
      [] as CommercialProductOption[]
    );

    return {
      type: '',
      payload: {
        addresses: doc.address_records ?? {},
        payments: doc.payments ?? {},
        items: doc.commercial_products_objectified ?? {},
        itemOrder: {},
        itemOptions: normalizeArray(productOptions),
        fields: {
          globalDiscount: doc.global_discount,
          private_comment: doc.private_comment ?? '',
          public_comment: doc.public_comment ?? '',
        },
      },
    };
  }
);

type ReturnType = Partial<CommercialDocumentState>;
