import React, {FC} from 'react';
import messages from '../messages';
import {EmailMap} from './use-action-quotation-send-email';
import Icon from '@ailibs/feather-react-ts';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Row, Col, Label, FormGroup, Input} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';

interface Props {
  email: EmailMap;
  removeEmail: (id: ObjectId) => void;
  updateEmail: any;
  validationErrors: any;
}

const EmailRow: FC<Props> = props => {
  const {email, removeEmail, updateEmail, validationErrors} = props;
  const {t} = useTranslation();

  return (
    <>
      {Object.values(email).map((p, i) => (
        <Row className="mb-3 " key={p.id}>
          <Col md={10}>
            <TextInput
              type="text"
              errors={validationErrors[`directory_emails.${i}.email`]}
              value={p.email}
              onChange={x => updateEmail(p, 'email', x)}
            />
          </Col>

          <Col md={2}>
            <div className="d-flex align-items-center py-0">
              <Button color="transparent " onClick={() => removeEmail(p.id)}>
                <Icon name="trash-2" className="text-danger" size={18} />
              </Button>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default EmailRow;
