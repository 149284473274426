import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockInProduct.title, 'Stock in product'),
  serialNumber: () => __(translations.stockInProduct.serialNumber, 'Serial number'),
  product: () => __(translations.stockInProduct.product, 'Product'),
  quantity: () => __(translations.stockInProduct.quantity, 'Quantity'),
  action: () => __(translations.stockInProduct.action, 'Action'),
  sku: () => __(translations.stockInProduct.sku, 'SKU'),
  logistic: () => __(translations.stockInProduct.logistic, 'Logistic'),
  transfer: () => __(translations.stockInProduct.transfer, 'Transfer'),
  assamble: () => __(translations.stockInProduct.assamble, 'Assamble'),
};
