import {useEffect, useState} from 'react';
import {MODAL} from 'features/modal-notch/constants';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {selectProduct} from 'store/production/endpoints/show-product';
import {
  UpdateProductRequest,
} from 'store/production/endpoints/update-product';
import showModal from 'store/ui/actions/show-modal';
import {Product} from 'types/product';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useProductActions = () => {
  const {id} = useParams();
  const data = useSelector(selectProduct({id}));
  const {is_all_variant_blocked} = data?.data?.data as Product ?? {};	

  const dispatch = useDispatch();
  const duplicate = () => {
    dispatch(
      showModal(MODAL.PRODUCT_ACTIONS_MODAL, {
        productId: id ?? '',
        url: `/products/${id}/duplicate`,
        path: `duplicate`,
        detailsMessage: 'actionProductPage.duplicateDetails',
      })
    );
  };

  const handleDeleteMany = () => {
    dispatch(
      showModal(MODAL.PRODUCT_DELETE_ACTIONS_MODAL, {
        productId: id ?? '',
        url: `/product-variants/force-delete-multiple`,
        path: '/dashboard/products',
      })
    );
  };
  const handleToOldCollectionMany = () => {
    dispatch(
      showModal(MODAL.PRODUCT_ACTIONS_MODAL, {
        productId: id ?? '',
        url: `/product-variants/to-old-collection-multiple`,
        path: `/dashboard/products/${id}/general`,
        detailsMessage: 'actionProductPage.toOldCollectionDetails',
      })
    );
  };
  const handleArchiveMany = () => {
    dispatch(
      showModal(MODAL.PRODUCT_ACTIONS_MODAL, {
        productId: id ?? '',
        url: `/product-variants/archive-multiple`,
        path: `/dashboard/products`,
        detailsMessage: 'actionProductPage.archivedDetails',
      })
    );
  };
  const handleBlockMany = () => {
    dispatch(
      showModal(MODAL.PRODUCT_ACTIONS_MODAL, {
        productId: id ?? '',
        url: `/product-variants/block-multiple`,
        path: `/dashboard/products/${id}/general`,
        detailsMessage: 'actionProductPage.blockedDetails',
      })
    );
  };

  return {
    duplicate,
    handleDeleteMany,
    handleArchiveMany,
    handleBlockMany,
    handleToOldCollectionMany,
    is_all_variant_blocked,
  };
};

type Params = UpdateProductRequest;
