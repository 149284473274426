import React from 'react';
import {FC} from 'react';
import messages from './messages';
import {EcoTax} from 'features/acounting/types/eco-tax';
import Can from 'features/hylian-shield/can';
import {tableSlice} from 'features/table/slice';
import {MODAL} from 'features/modal-notch/constants';
import {Trash2, Edit} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {DropdownMenu, DropdownItem} from 'reactstrap';
import {UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import Button from 'components/button';

interface Props {
  ecotaxData: EcoTax;
}

const EcotaxActions: FC<Props> = ({ecotaxData}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleUpdate = () => {
    dispatch(
      showModal(MODAL.ECOTAX_UPDATE, {
        ecotax: ecotaxData,
        onSuccess: () => {
          navigate(`/dashboard/ecotaxes`);
        },
      })
    );
  };

  const destroyEcotax = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: ecotaxData.id,
        resourceName: 'ecotaxes',
      })
    );
  };

  return (
    <div className="d-flex">
      <Can action="edit" subject="accounting">
        <Button color="transparent" onClick={handleUpdate}>
          <Edit size={20} />
        </Button>
      </Can>
      <Can action="delete-and-merge" subject="accounting">
        <Button
          color="transparent"
          onClick={e =>
            dispatch(
              uiActions.confirmDelete({
                resourceId: ecotaxData.id ?? -1,
                name: 'ecotaxes',
                otherParams: {productId: ecotaxData.id ?? -1},
                onDestroy: destroyEcotax,
              })
            )
          }
        >
          <Trash2 className="text-danger" size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default EcotaxActions;
