import React from 'react';
import {useParams} from 'react-router';
import {Col, Card, CardBody, Alert, Button} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import ProjectRoutes from '../project-routes';
import ProjectTabPane from '../project-tab-pane';
import SummarySection from '../summary-section';
import {Project} from 'types/project';
import {useShowProjectQuery} from 'features/studio/projects/store/show-project';
import Icon from '@ailibs/feather-react-ts';
import {useTranslation} from 'react-i18next';
import SkeletonLoader from './skeleton';

const ProjectDetailsSection = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const {data, isLoading, error, isError, isSuccess, refetch} =
    useShowProjectQuery({id});

  const serverError = parseValidationErrors(error);

  const project = data?.data as Project;

  return (
    <React.Fragment>
      {isLoading && <SkeletonLoader />}
      {isSuccess && (
        <Col xl={12} lg={12}>
          <Card>
            <CardBody className="pt-0">
              <SummarySection project={project} />
              <ProjectTabPane />
            </CardBody>
          </Card>
          <ProjectRoutes />
        </Col>
      )}
      {isError && (
        <div>
          <Alert color="danger">{serverError.message}</Alert>
          <div className="d-flex justify-content-center py-3">
            <Button onClick={refetch}>
              <Icon name="refresh-cw" size={15} className="me-2" />
              {t('common.reload')}
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProjectDetailsSection;
