import {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {useUpdateComponentsPriceMutation} from 'store/directory/endpoints/update-components-price';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductComponent} from 'types/product-component';
import {ProductVariant} from 'types/product-variant';
import makeId from 'utils/make-id';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useComponentsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateComponentsPriceMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const [components, setComponents] = useState<ComponentMap>({});

  const [delete_records, setdeleteRecords] = useState([] as ObjectId[]);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      variants: Object.values(components),
      delete_records,
      id,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const addComponent = () => {
    const p: Single = {
      id: makeId(),
    };
    setComponents(
      produce(components, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeComponent = (id: ObjectId, variant_id?: ObjectId) => {
    const _components = {...components};

    if (!variant_id) {
      delete _components[id];
    } else {
      Object.keys(_components).forEach(key => {
        if (_components[key]?.variant?.id === variant_id) {
          delete _components[key];
        }
      });

      setdeleteRecords([...delete_records, variant_id]);
    }

    setComponents({
      ..._components,
    });
  };

  const updateComponent = (p: Single, k: keyof Single, v: any) => {
    setComponents(
      produce(components, draft => {
        Object.values(draft).map(df => {
          if (df.variant?.id === p.variant?.id) {
            df[k] = v;
          }
        });
      })
    );
  };

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  useEffect(() => {
    if (directory) {
      setComponents(directory.directory_product_variants);
    }
  }, [directory]);

  const filterOption = (option: Option<ProductVariant>, inputValue: string) => {
    const isSelected = Object.values(components).some(
      x => x?.variant?.id === option.value
    );
    return !isSelected;
  };

  return {
    addComponent,
    filterOption,
    removeComponent,
    submit: handleSubmit,
    updateComponent,
    directory,
    components,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
  };
};

export type Single = {
  id: ObjectId;
  variant?: ProductVariant;
  cost?: number;
  weeks?: number;
};

type ComponentMap = Record<ObjectId, ProductComponent>;
