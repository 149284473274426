import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockOrderTransferModal.title, 'Transfer'),
  transfer: () => __(translations.stockOrderTransferModal.transfer, 'Transfer'),
  areYouSure: () => __(translations.stockOrderTransferModal.areYouSure, 'Are you sure?'),
  label: () => __(translations.stockOrderTransferModal.label, 'Label'),
  quantity: () => __(translations.stockOrderTransferModal.quantity, 'Quantity'),
  sku: () => __(translations.stockOrderTransferModal.sku, 'SKU'),
  stock: () => __(translations.stockOrderTransferModal.stock, 'Stock location'),
  supplier: () => __(translations.stockOrderTransferModal.supplier, 'Supplier'),
  availability: () => __(translations.stockOrderTransferModal.availability, 'Availability'),
};
