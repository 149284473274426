import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  newPayment: () => __(translations.commercialDocumentPaymentsSection.newPayment, 'New payment'),
  payments: () => __(translations.commercialDocumentPaymentsSection.payments, 'Payments'),
  overpaid: () => __(translations.commercialDocumentPaymentsSection.overpaid, 'Overpayments:'),
  paidCompletely: () => __(translations.commercialDocumentPaymentsSection.paidCompletely, 'Invoice paid in full on:'),
  totalDue: () => __(translations.commercialDocumentPaymentsSection.totalDue, 'Total including vat before shipping:'),
  credits: () => __(translations.commercialDocumentPaymentsSection.credits, 'Credits:'),
  invoice: () => __(translations.commercialDocumentPaymentsSection.invoice, 'Original nvoice:'),
  refunding: () => __(translations.commercialDocumentPaymentsSection.refunding, 'Refunding'),
};
