import React, {FC} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {Directory} from 'types/directory';

interface Props {
  directory: Directory;
}
const ToggleAttach: FC<Props> = props => {
  const {directory} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  return !directory?.company_id ? (
    <Button
      className="ms-2"
      color="light"
      onClick={() => dispatch(showModal(MODAL.ATTACH_DIRECTORY, directory))}
    >
      <Icon name="link" size={15} className="me-2" />
      {t(...messages.attach())}
    </Button>
  ) : (
    <Button
      className="ms-2"
      color="light"
      onClick={() => dispatch(showModal(MODAL.DETTACH_COMPANY, directory))}
    >
      <Icon name="link" size={15} className="me-2" />
      {t(...messages.dettach())}
    </Button>
  );
};

export default ToggleAttach;
