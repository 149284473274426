import Stocklocation from 'features/stock/types/stock-location';
import {api} from 'services/api';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeLocationCategory = api.injectEndpoints({
  endpoints: builder => ({
    storeLocationCategory: builder.mutation<
      Response,
      StoreLocationCategoryRequest
    >({
      query: params => {
        return {
          method: 'POST',
          url: `stock-location-categories`,
          body: {
            name: params.name,
          },
        };
      },
    }),
  }),
});

export type StoreLocationCategoryRequest = {
  name: string;
};

type Response = ResponseData<Stocklocation>;

export const {useStoreLocationCategoryMutation} = storeLocationCategory;
