import React, {FC} from 'react';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';

interface Props {
  params: any;
  onChangeParams: (x: any) => void;
  validationErrors: ValidationErrors;
  is_locked?: boolean;
}

const InvoiceTransporter: FC<Props> = props => {
  const {params, validationErrors, onChangeParams, is_locked} = props;

  const {t} = useTranslation();

  return (
    <div className="">
      <SectionSeparator title={t(...messages.title())} />

      <Row>
        <Col md={12} className="">
          <AsyncItemPicker
            endPoint="data/directories-transporter"
            label={t(...messages.transporterName())}
            getOptionLabel={(x: any) => x.display_name}
            getOptionValue={(x: any) => x.id}
            disabled={is_locked}
            errors={
              validationErrors.transporter_name ||
              validationErrors.transporter_id
            }
            value={params.transporter}
            onChange={transporter => {
              onChangeParams({
                ...params,
                transporter: transporter,
              });
            }}
          />
        </Col>
        <Col md={12} className="my-2">
          <TextInput
            label={t(...messages.transporterPrice())}
            value={params.transporter_price}
            disabled={is_locked}
            errors={validationErrors.transporter_price}
            onChange={transporter_price => {
              onChangeParams({
                ...params,
                transporter_price,
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceTransporter;
