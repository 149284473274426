import React from 'react';
import {FC} from 'react';
import LoadRunner from 'components/load-runner';

interface Props {}

const SearchSkeleton: FC<Props> = props => {
  return (
    <LoadRunner
      speed={2}
      width={'100%'}
      height={300}
      viewBox="0 0 100% 100%"
      backgroundColor="#f5f5f5"
      foregroundColor="#ebebeb"
      {...props}
    >
      <rect x="0" y="0" rx="4" ry="4" width="90%" height="31" />
      <rect x="0" y="50" rx="4" ry="4" width="85%" height="19" />
      <rect x="0" y="80" rx="4" ry="4" width="80%" height="19" />
      <rect x="0" y="110" rx="4" ry="4" width="60%" height="19" />

      <rect x="0" y="150" rx="4" ry="4" width="90%" height="31" />
      <rect x="0" y="200" rx="4" ry="4" width="85%" height="19" />
      <rect x="0" y="230" rx="4" ry="4" width="80%" height="19" />
      <rect x="0" y="260" rx="4" ry="4" width="60%" height="19" />
    </LoadRunner>
  );
};

export default SearchSkeleton;
