import React, {useContext, useEffect} from 'react';
import QuotationProductionTab from '../../production/quotation-production-tab';
import QuotationTransporter from '../quotation-transporter';
import QuotationVtaAndTaxes from '../quotation-vta-and-taxes';
import messages from './messages';
import {useQuotationGeneralInformationForm} from './use-quotation-general-informations-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Col, Row} from 'reactstrap';
import Can from 'features/hylian-shield/can';
import documentContext from 'features/commercial/documents/containers/document-context/document-context';
import {useCurrentQuote} from '../../details/quotation-context';
import {Subject} from 'features/hylian-shield/types';

const QuotationGeneralInformation = () => {
  const {t} = useTranslation();
  const {
    params,
    validationErrors,
    isLoading,
    errorMessage,
    submit,
    isSuccess,
    setParams,
    is_locked,
  } = useQuotationGeneralInformationForm();

  const doc = useCurrentQuote();

  return (
    <div>
      <Row>
        <Col>
          <SectionSeparator title={t(...messages.title())} />
          <Row>
            <Col md={12}>
              <TextInput
                label={t(...messages.client())}
                disabled
                data-testid="product-name-input"
                value={params.client_display_name}
                onChange={client_display_name => {
                  setParams({
                    ...params,
                    client_display_name,
                  });
                }}
              />
            </Col>
            <Col md={12} className="my-2">
              <DateInput
                label={t(...messages.quotationIssueDate())}
                value={params.created_at}
                disabled={is_locked}
                onChange={created_at => setParams({...params, created_at})}
                errors={validationErrors.created_at}
              />
            </Col>
            <Col md={12} className="my-2">
              <DateInput
                label={t(...messages.quotationDeadline())}
                value={params.deadline}
                disabled={is_locked}
                onChange={deadline => setParams({...params, deadline})}
                errors={validationErrors.deadline}
              />
            </Col>
            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/document-categories"
                label={t(...messages.quotationCategory())}
                optionLabel=""
                disabled={is_locked}
                errors={validationErrors.document_category}
                value={params.document_category}
                onChange={document_category =>
                  setParams({...params, document_category})
                }
              />
            </Col>
            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/projects"
                disabled={is_locked}
                label={t(...messages.quotationProject())}
                optionLabel=""
                errors={validationErrors.project_id}
                value={params.project}
                onChange={project => setParams({...params, project})}
              />
            </Col>
            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/document-origins"
                label={t(...messages.quotationOrigin())}
                optionLabel=""
                disabled={is_locked}
                errors={validationErrors.origin}
                value={params.origin}
                onChange={origin => setParams({...params, origin})}
              />
            </Col>
            <Col md={12} className="my-2">
              <TextInput
                label={t(...messages.clientReference())}
                data-testid="product-name-input"
                value={params.client_reference}
                disabled={is_locked}
                errors={validationErrors.ref_client}
                onChange={client_reference => {
                  setParams({
                    ...params,
                    client_reference,
                  });
                }}
              />
            </Col>
            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/languages"
                className="text-capitalize"
                label={t(...messages.quotationLanguage())}
                getOptionLabel={(l: any) => l.label}
                disabled={is_locked}
                errors={validationErrors.language}
                value={params.language}
                getOptionValue={(l: any) => l.label}
                onChange={language => {
                  setParams({...params, language});
                }}
              />
            </Col>

            {/*     <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/currencies"
                label={t(...messages.quotationCurrency())}
                getOptionLabel={(l: any) => l.label}
                disabled={is_locked}
                errors={validationErrors.currency}
                value={params.currency}
                getOptionValue={(l: any) => l.label}
                onChange={currency => {
                  setParams({...params, currency});
                }}
              />
            </Col> */}
            <Col md={12} className="my-2">
              <TextInput
                type="textarea"
                label={t(...messages.privateComment())}
                errors={validationErrors.private_comment}
                value={params.private_comment}
                disabled={is_locked}
                onChange={private_comment => {
                  setParams({...params, private_comment});
                }}
              />
            </Col>

            <AppBarActions>
              <Can
                subject={
                  doc.commercial_type?.code?.toLocaleLowerCase() as Subject
                }
                action="create-and-edit"
              >
                <Button
                  isLoading={isLoading}
                  onClick={submit}
                  disabled={is_locked}
                  color="important"
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </Can>
            </AppBarActions>
          </Row>
        </Col>
        <Col>
          {/* Vat and taxes */}
          <QuotationVtaAndTaxes
            validationErrors={validationErrors}
            onChangeParams={setParams}
            is_locked={is_locked}
            params={params}
          />
          {/* transporter */}
          <QuotationTransporter
            validationErrors={validationErrors}
            onChangeParams={setParams}
            is_locked={is_locked}
            params={params}
          />
          <QuotationProductionTab
            validationErrors={validationErrors}
            onChangeParams={setParams}
            is_locked={is_locked}
            params={params}
          />
        </Col>
      </Row>
    </div>
  );
};

export default QuotationGeneralInformation;
