import React from 'react';
import {FC, useState} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {MODAL} from 'features/modal-notch/constants';
import clearSelection from 'features/table/slice/actions/clear-selection';
import selectSelectedResources from 'features/table/slice/selectors/select-selected-resources';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {Props} from 'react-select';
import {Badge, Dropdown} from 'reactstrap';
import {DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';

interface Props {}

const MassActions: FC<Props> = props => {
  const [isOpen, setIsOpen] = useState(false);

  const {t} = useTranslation();

  const handleShowCostsForm = () => {
    dispatch(
      showModal(MODAL.UPDATE_COSTS_FORM, {
        resourceName: 'variants',
        url: 'product-variants',
      })
    );
  };
  const handleShowInCatalogModal = () => {
    dispatch(
      showModal(MODAL.UPDATE_CATALOG_FORM, {
        resourceName: 'variants',
        url: 'update-incatalog-multiple',
        path: 'product-variants',
      })
    );
  };
  const handleShowOutofCatalogModal = () => {
    dispatch(
      showModal(MODAL.UPDATE_CATALOG_FORM, {
        resourceName: 'variants',
        url: 'update-outcatalog-multiple',
        path: 'product-variants',
      })
    );
  };
  const handleClearSelection = () => {
    dispatch(clearSelection('variants'));
  };

  const dispatch = useDispatch();

  const selectedVariants = useSelector(selectSelectedResources('variants'));

  const hasSelectedItems = selectedVariants.length > 0;
  return (
    <Dropdown
      isOpen={isOpen}
      className="me-2 "
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle color="light" disabled={selectedVariants.length < 1}>
        {t(...messages.actionMultipleButton())}
        {hasSelectedItems && (
          <Badge className="ms-1">{selectedVariants.length}</Badge>
        )}
        <Icon name="chevron-down" size={15} className="ms-1" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem disabled={false} onClick={handleShowCostsForm}>
          {t(...messages.pricing())}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem disabled={false} onClick={handleShowInCatalogModal}>
          {t(...messages.putInCatalog())}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem disabled={false} onClick={handleShowOutofCatalogModal}>
          {t(...messages.outCatalog())}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem disabled={false} onClick={handleClearSelection}>
          {t(...messages.clearSelection())}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MassActions;
