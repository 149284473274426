import {makeRestoreResourceUrl} from 'features/modal-notch/reincarnation-angel/constants';
import {api} from 'services/api';
import {Model} from 'types/model';
import {ObjectId} from 'types/object-id';
import {ResourceName} from 'types/resource-name';
import {ResponseData} from 'types/response-data';

const restoreResource = api.injectEndpoints({
  endpoints: builder => ({
    restoreResource: builder.mutation<ResponseData<Response>, Request>({
      query: ({resourceId, name, otherParams}) => ({
        method: 'POST',
        url: makeRestoreResourceUrl({
          resourceId,
          name,
          otherParams,
        }),
      }),
    }),
  }),
});

type Request = {
  resourceId: ObjectId;
  name: ResourceName;
  otherParams?: any;
};

type Response = Model;

export const {useRestoreResourceMutation} = restoreResource;

export const restoreResourceEndpoint =
  restoreResource.endpoints.restoreResource;
