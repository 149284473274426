export enum ExpensesTypesEnum {
  TRANSPORT_MODES = 'TRANSPORT_MODES', // Modes de transports
  RESTAURANT = 'RESTAURANT', // Restaurant
  HOTEL = 'HOTEL', // Hôtel
  TAXI_PARIS = 'TAXI_PARIS', // Taxi Paris
  TAXI_OTHERS = 'TAXI_OTHERS', // Taxi autres
  CAR_RENT = 'CAR_RENT', // Location de voiture
  PARKING = 'PARKING', // Parking
  OTHERS = 'OTHERS', // Autres
}

export enum ExpensesStatusEnum {
  PENDING = 'PENDING', // En cours
  PROCESSED = 'PROCESSED', // Traité
  ARCHIVED = 'ARCHIVED', // Archived
}
