import {useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {usePricingActionMutation} from 'store/directory/endpoints/action-pricing';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Directory} from 'types/directory';
import {DirectoryProductVariant} from 'types/directory-product-variant';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import denormalizeObject from 'utils/denormalize-object';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useActionPricingForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error, isSuccess}] = usePricingActionMutation();
  const [selectAll, setSelectAll] = useState(false);
  const [decrease, setDecrease] = useState(false);
  const [params, setParams] = useState<Params>({
    value: '',
    increase_by: undefined,
  });

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const modalParams = useSelector(selectActiveModalParams);

  const directory = modalParams as Directory;

  const [selectedVariants, setSelectedVariants] = useState<
    Record<ObjectId, boolean>
  >({});
  const handleSubmit = async () => {
    const result = await submit({
      selectedVariants,
      all_are_selected: selectAll,
      value: params.value,
      increase_type: params.increase_by,
      id: directory.id,
      decrease: decrease,
    });
    if ('data' in result) {
      toast.success(result?.data?.message);
      onDismiss();
    }
  };

  const variants = denormalizeObject(directory.directory_product_variants);

  const updateParams = (k: keyof Params, v: any) => {
    setParams({...params, [k]: v});
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedVariants(
      variants?.reduce(
        (acc: Record<ObjectId, boolean>, v: DirectoryProductVariant) => ({
          ...acc,
          [v.variant.id]: !selectAll,
        }),
        {}
      )
    );
  };

  const toggleVariant = (v: DirectoryProductVariant) => {
    setSelectAll(false);

    setSelectedVariants({
      ...selectedVariants,
      [v.variant.id]: !selectedVariants[v.variant.id],
    });
  };
  return {
    variants,
    selectedVariants,
    toggleVariant,
    toggleSelectAll,
    decrease,
    setDecrease,
    params,
    selectAll,
    isLoading,
    updateParams,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = {
  increase_by?: Option;
  value?: string;
};
