import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProject = api.injectEndpoints({
  endpoints: builder => ({
    storeProject: builder.mutation<Response, StoreProjectRequest>({
      query: (body: StoreProjectRequest) => {
        body.directory_id = body.directory?.id;
        delete body.directory;
        return {
          method: 'POST',
          url: `projects`,
          body,
        };
      },
    }),
  }),
});

export type StoreProjectRequest = {
  name: string;
  internal_name: string;
  description: string;
  client_notice: string;
  designation: string;
  project_type: string;
  directory?: Directory;
  directory_id: ObjectId | undefined;
  status: string;
  starts_at: string | undefined;
  ends_at: string | undefined;
};

type Response = ResponseData<Project>;

export const {useStoreProjectMutation} = storeProject;
