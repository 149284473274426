import React, {FC, useEffect, useRef, useState} from 'react';
import EmptyState from '../compenents/empty-state';
import ListSkeleton from '../compenents/list-skeleton';
import TableBody from '../compenents/table-body';
import TableHeader from '../compenents/table-header';
import {Table as TableType} from '@tanstack/react-table';
import './style.scss';
import styled from 'styled-components';

interface DataTableProps {
  table: TableType<unknown>;
  isLoading?: boolean;
  makeRowClassName?: (row: any) => string;
}

const DataTable: FC<DataTableProps> = props => {
  const {table, isLoading, makeRowClassName} = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const fakeScrollRef = useRef<HTMLDivElement>(null);

  const [tableWidth, setTableWidth] = useState(0);

  // Effect to update the width
  useEffect(() => {
    const updateTableWidth = () => {
      const currentTableWidth = tableRef.current
        ? tableRef.current.scrollWidth
        : 0;

      setTableWidth(currentTableWidth);
    };

    // Update table width on mount and window resize
    window.addEventListener('resize', updateTableWidth);
    updateTableWidth();
    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', updateTableWidth);
    };
  }, [isLoading]);
  // Handlers to sync the scroll positions
  const syncScroll = (scrollRef: string) => {
    if (scrollRef === 'fake' && fakeScrollRef.current && tableRef.current) {
      tableRef.current.scrollLeft = fakeScrollRef.current.scrollLeft;
    } else if (
      scrollRef === 'real' &&
      fakeScrollRef.current &&
      tableRef.current
    ) {
      fakeScrollRef.current.scrollLeft = tableRef.current.scrollLeft;
    }
  };

  return (
    <>
      {isLoading ? (
        <ListSkeleton />
      ) : table.getRowModel().rows.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <FakescrollContainer
            ref={fakeScrollRef}
            className="border-bottom scroll-table"
            onScroll={() => syncScroll('fake')}
          >
            {/* Fake div to take up space and create a scrollbar */}
            <FakeScroll style={{width: tableWidth}} />
          </FakescrollContainer>
          <TableContainer
            onScroll={() => syncScroll('real')}
            ref={tableRef}
            className="table-responsive scroll-table scroll-table  "
          >
            <table className="table ">
              <TableHeader table={table} />

              <TableBody makeRowClassName={makeRowClassName} table={table} />
            </table>
          </TableContainer>
        </>
      )}
    </>
  );
};

const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  height: 75vh;
  position: relative;
`;
const FakescrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  position: relative;
`;
const FakeScroll = styled.div<{tableWidth?: Number}>`
  background-color: 'inherit';
  height: 10px;
  opacity: 0;
  width: ${props => (props.tableWidth ? `${props.tableWidth}px` : '0')};
`;

export default DataTable;
