import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

const updateCommercialSettings = api.injectEndpoints({
  endpoints: builder => ({
    updateCommercialSettings: builder.mutation<
      Response,
      UpdateCommercialSettingsRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `settings/commercial`,
          body: {
            ...params,
          },
        };
      },
    }),
  }),
});

export type UpdateCommercialSettingsRequest = Settings['commercial'];

type Response = ResponseData<Settings['commercial']>;

export const {useUpdateCommercialSettingsMutation} = updateCommercialSettings;
