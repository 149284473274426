import React, {FC} from 'react';
import messages from '../messages';
import Filters from './filters';
import TagsRow from './tags-row';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button, CardBody, Col, Row} from 'reactstrap';
import {useLocalStorage} from 'usehooks-ts';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const StockOrderControls: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <Row>
      <Col>
        <CardBody>
          <TagsRow />

          <Row className="mb-3">
            <AppBarActions>
              <Can subject="ccs" action="create-and-edit">
                <Link
                  to="/dashboard/stock-orders/create"
                  className="btn btn-important"
                >
                  <Icon name="plus" className="me-1" size={15} />
                  {t(...messages.addStockOrder())}
                </Link>
              </Can>
            </AppBarActions>
          </Row>
          <Filters
            onClearFilters={onClearFilters}
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        </CardBody>
      </Col>
    </Row>
  );
};

export default StockOrderControls;
