import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, CardHeader, CardSubtitle, Col, Row} from 'reactstrap';
import messages from '../messages';
import TextInput from 'components/text-input';
import {Plus} from 'react-feather';
import TeamFilters from './filters';
import {ITeamForm} from '../use-team-form';
import TeamPicker from '../team-picker';
import ItemPicker from 'components/item-picker';
import Can from 'features/hylian-shield/can';

const HeaderSection: FC<Props> = ({
  regroupedTeam,
  options,
  input,
  status,
  subject,
  handleShowModal,
  handleInputChange,
  handleAddInput,
  handleFilter,
}) => {
  const {t} = useTranslation();

  return (
    <CardHeader className="">
      <div className="mb-3">
        <CardSubtitle tag="h5" className="my-2">
          {t(...messages.team())}
        </CardSubtitle>
        <Row>
          <Col lg={5}>
            <TeamFilters regroupedTeam={regroupedTeam} />
          </Col>
          <Col className="d-flex justify-content-end gap-2" lg={7}>
            <div className="flex-grow-1">
              <ItemPicker
                value={status}
                options={options.statuses}
                onChange={(val: any) => handleFilter(val)}
              />
            </div>
            <Can action="edit" subject={subject}>
              <Button
                onClick={handleShowModal}
                color="important"
                className="align-self-lg-start"
              >
                {t(...messages.configureManDays())}
              </Button>
            </Can>
          </Col>
        </Row>
      </div>
      <Can action="edit" subject={subject}>
        <CardSubtitle tag="h5" className="my-4">
          {t(...messages.configureTeam())}
        </CardSubtitle>
        <Row className="my-3">
          <Col md={4}>
            <TextInput
              label={t(...messages.artisticDirector())}
              value="India"
              disabled
              onChange={() => {}}
            />
          </Col>
          <Col md={4} className="d-flex gap-2">
            <div className="flex-grow-1">
              <TeamPicker
                label={t(...messages.project_manager())}
                value={input.project_manager.user}
                onChange={val => handleInputChange('project_manager', val)}
              />
            </div>
            <Button
              color="important"
              disabled={!input.project_manager.user}
              onClick={() => handleAddInput('project_manager')}
              style={{display: 'inline-block', alignSelf: 'flex-end'}}
            >
              <Plus size={14} /> Ajouter à la liste
            </Button>
          </Col>
          <Col md={4} className="d-flex gap-2">
            <div className="flex-grow-1">
              <TeamPicker
                label={t(...messages.assistant())}
                value={input.assistant.user}
                onChange={val => handleInputChange('assistant', val)}
              />
            </div>
            <Button
              color="important"
              disabled={!input.assistant.user}
              onClick={() => handleAddInput('assistant')}
              style={{display: 'inline-block', alignSelf: 'flex-end'}}
            >
              <Plus size={14} /> Ajouter à la liste
            </Button>
          </Col>
        </Row>
      </Can>
    </CardHeader>
  );
};

type Props = ITeamForm;

export default HeaderSection;
