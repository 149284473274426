import React from 'react';
import CarouselPage from '../../auth-inner/CarouselPage';
import FormSection from './form-section';
import messages from './messages';
import logo from 'assets/images/india-logo.svg';
import withRouter from 'components/Common/withRouter';
import {application} from 'constants/application';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Row, Col, Container} from 'reactstrap';

const LoginPage = () => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <div className="auth-page">
        <Container fluid className="p-0  ">
          <Row className="g-0 w-100">
            <Col lg={4} md={5} className="col-xxl-3  ">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className=" text-center">
                      <img src={logo} alt="" height="90" />{' '}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{t(...messages.title())}</h5>
                        <p className="text-muted mt-2">
                          {t(...messages.subTitle())}
                        </p>
                      </div>
                      <FormSection />

                      <div className="text-center">
                        <p className="text-muted mb-0">
                          {t(...messages.forgotYourPassword())}
                          <Link
                            to="/forgot-password"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            {t(...messages.recoverAccount())}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {application.name}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LoginPage);
