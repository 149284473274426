import {ListConfig} from '../types';
import {createAction} from '@reduxjs/toolkit';

export default createAction(
  'table/loadData',
  function prepare(cfg?: ListConfig) {
    return {
      payload: {
        ...cfg,
        url: cfg?.url ?? cfg?.name,
      },
    };
  }
);
