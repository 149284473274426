import {useContext, useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {produce} from 'immer';
import {set} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {useUpdateCoordinateMutation} from 'store/directory/endpoints/update-coordinate';
import {ObjectId} from 'types/object-id';
import {Email, Phone} from 'types/phone';
import makeId from 'utils/make-id';
import {useSeeder} from 'utils/use-seeder';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useCoordinateForm = () => {
  const [update, {isLoading, error, isSuccess}] = useUpdateCoordinateMutation();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const serverError = parseValidationErrors(error);
  const {id} = useParams();

  const [phone, setPhone] = useState<PhoneMap>({});
  const [email, setEmail] = useState<EmailMap>({});
  const [mainPhoneId, setMainPhoneId] = useState<ObjectId>('');
  const [mainEmailId, setMainEmailId] = useState<ObjectId>('');

  const [deletedEmailRecords, setDeletedEmailRecords] = useState(
    [] as ObjectId[]
  );
  const [deletedPhoneRecords, setDeletedPhoneRecords] = useState(
    [] as ObjectId[]
  );
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await update({
      phones: Object.values(phone),
      emails: Object.values(email),
      mainEmailId,
      mainPhoneId,
      deletedEmailRecords,
      deletedPhoneRecords,
      id,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const fake = useSeeder();

  const addPhone = () => {
    const p: Phone = {
      id: makeId(),
      comment: __DEV__ ? fake.paragraph : '',
      number: __DEV__ ? fake.phone() : '',
    };
    setPhone(
      produce(phone, draft => {
        draft[p.id] = p;
      })
    );
  };
  const addEmail = () => {
    const p: Email = {
      id: makeId(),
      comment: __DEV__ ? fake.paragraph : '',
      email: __DEV__ ? fake.email : '',
    };
    setEmail(
      produce(email, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removePhone = (id: ObjectId) => {
    const _phone: PhoneMap = Object.assign({}, phone);
    delete _phone[id];
    setPhone({
      ..._phone,
    });
    setDeletedPhoneRecords([...deletedPhoneRecords, id]);
  };
  const removeEmail = (id: ObjectId) => {
    const _email: EmailMap = Object.assign({}, email);
    delete _email[id];
    setEmail({
      ..._email,
    });
    setDeletedEmailRecords([...deletedEmailRecords, id]);
  };

  const updatePhone = (p: Phone, k: keyof Phone, v: any) => {
    setPhone(
      produce(phone, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };
  const updateEmail = (p: Email, k: keyof Email, v: any) => {
    setEmail(
      produce(email, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };
  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  useEffect(() => {
    if (directory) {
      let phoneTmp = {};
      let emailTmp = {};

      directory.phones?.map((p: any) => {
        const pt = produce({} as PhoneMap, draft => {
          draft[p.id] = p;
        });
        phoneTmp = {...phoneTmp, ...pt};
      });
      directory.directory_emails?.map((p: any) => {
        const pt = produce({} as EmailMap, draft => {
          draft[p.id] = p;
        });
        emailTmp = {...emailTmp, ...pt};
      });

      setPhone(phoneTmp);
      setMainPhoneId(directory.phones.find((p: any) => p.is_main)?.id || '');
      setMainEmailId(
        directory.directory_emails.find((p: any) => p.is_main)?.id || ''
      );
      setEmail(emailTmp);
    }
  }, [directory]);

  return {
    addPhone,
    removePhone,
    removeEmail,
    submit: handleSubmit,
    updatePhone,
    updateEmail,
    setMainPhoneId,
    setMainEmailId,
    mainPhoneId,
    mainEmailId,
    addEmail,
    directory,
    phone,
    email,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
    canEdit,
  };
};

export type PhoneMap = Record<ObjectId, Phone>;
export type EmailMap = Record<ObjectId, Email>;
