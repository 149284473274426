import React, {useState} from 'react';
import columns from './columns';
import messages from './messages';
import OverpaymentControls from './overpayments-controls';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import OverpaymentsTabPane from './tab-pane';
import {OverpaymentsStatuses} from 'features/acounting/overpayments/types';

const OverpaymentsPage = () => {
  const {t} = useTranslation();

  const [status, setStatus] = useState<OverpaymentsStatuses>('AVAILABLE');

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <OverpaymentsTabPane {...{status, setStatus}} />

        <Table
          url={`overpayments?filter[status]=${status}`}
          otherParams={{
            delimiter: '|',
          }}
          hideSearch
          columns={columns}
          filtersComponent={OverpaymentControls}
          name="overpayments"
        />
      </Container>
    </div>
  );
};

export default OverpaymentsPage;
