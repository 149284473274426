import React from 'react';
import ProductActions from './product-actions';
import ProductCover from './product-cover';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {IndeterminateCheckbox} from 'features/table/compenents/column-checkbox';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ProductVariant} from 'types/product-variant';

const columns: ColumnDef<ProductVariant>[] = [
  {
    header: ({table}) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({row}) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    meta: {hide: true},
    enableSorting: false,
    id: 'checkbox',
  },
  {
    header: () => (
      <span className="font-size-14 ">
        <Trans i18nKey="productsPage.preview" />
      </span>
    ),
    accessorFn: row => <ProductCover variant={row} />,
    cell: r => r.getValue(),
    meta: {size: '10px'},

    enableSorting: false,
    id: 'preview',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.sku" />
      </span>
    ),
    accessorFn: r => <EmptyCell cell={r.sku} />,
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'sku',
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.name" />
      </span>
    ),
    accessorFn: row =>
      row.is_archived ? (
        <EmptyCell cell={row.name?.toLocaleUpperCase()} />
      ) : (
        <Link
          to={`/dashboard/products/${row.product?.id}/variants/${row.id}/general`}
          target="_blank"
        >
          <EmptyCell
            cell={<b>{row.name?.toLocaleUpperCase()}</b>}
            fallback="-"
          />
        </Link>
      ),
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: true,
    meta: {size: '16vw'},
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.familie" />
      </span>
    ),
    accessorFn: row => <EmptyCell cell={row.product_familles} />,
    cell: r => r.getValue(),
    id: 'family',
    enableSorting: true,
    meta: {size: '7vw'},
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.masterProduct" />
      </span>
    ),
    accessorFn: row => (
      <EmptyCell
        cell={
          <Link
            target="_blank"
            to={`/dashboard/products/${row?.product?.id}/general`}
          >
            {/* can't fallback here to a dash in case name is empty, product is mandatory here */}
            <b>{row?.product?.name}</b>
          </Link>
        }
      />
    ),
    cell: r => r.getValue(),
    id: 'product.name',
    enableSorting: true,
  },
  {
    meta: {size: '5vw'},
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.totalExclTaxes" />
      </span>
    ),
    accessorFn: row =>
      (
        <Trans
          i18nKey="numbers.currency"
          values={{val: row.selling_price_vat_exc}}
        />
      ) ?? '',
    cell: r => r.getValue(),
    id: 'selling_price_vat_exc',
    enableSorting: true,
  },
  {
    meta: {size: '5vw'},
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.totalInclTaxes" />
      </span>
    ),
    accessorFn: row =>
      <Trans i18nKey="numbers.currency" values={{val: row.selling_price}} /> ??
      '',
    cell: r => r.getValue(),
    id: 'selling_price',
    enableSorting: true,
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.dimensions" />
      </span>
    ),
    accessorFn: row => <EmptyCell cell={row.dimensions} />,
    cell: r => r.getValue(),
    id: 'dimensions',
    meta: {size: '7vw'},
    enableSorting: true,
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.finish" />
      </span>
    ),
    accessorFn: row => <EmptyCell cell={row.finition} />,
    cell: r => r.getValue(),
    id: 'finition',
    enableSorting: true,
    meta: {size: '9vw'},
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.stockAvailable" />
      </span>
    ),
    cell: r => r.getValue(),
    accessorFn: row => (
      <EmptyCell cell={`${row.stock_available} / ${row.stock_total}`} />
    ),
    id: 'stock',
    meta: {cellCostumClass: 'text-center align-middle'},

    enableSorting: false,
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.action" />
      </span>
    ),
    cell: r => r.getValue(),
    enableSorting: false,
    accessorFn: row => <ProductActions productVariant={row} />,
    id: 'action',
  },
];

export default columns;
