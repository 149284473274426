import AddressForm from './address-form';
import ArchiveDirectoryConfirm from './archive-directory';
import AttachDirectoryForm from './attach-directory-form';
import DestroyDirectoryConfirm from './destroy-directory';
import DettachCompanyConfirm from './dettach-company';
import DettachContactConfirm from './dettach-contact';
import DirectoryCaterogyForm from './directory-category-form';
import DirectoryFamilyForm from './directory-family-form';
import DirectoryFamilyFormUpdate from './directory-family-form-update';
import InteractionForm from './interaction-form/interaction-form';
import MergeDirectoryForm from './merge-directory-form';
import PricingActionForm from './pricing-action';
import RestoreDirectoryConfirm from './restore-directory';
import UpdateAddressForm from './update-address-form';
import UpdateDirectoryCaterogyForm from './update-directory-category-form';
import {updateContactList} from './update-list-of-contact';
import {MODAL} from 'features/modal-notch/constants';

export default {
  [MODAL.ADDRESS_FORM]: AddressForm,
  [MODAL.ATTACH_DIRECTORY]: AttachDirectoryForm,
  [MODAL.DESTROY_DIRECTORY]: DestroyDirectoryConfirm,
  [MODAL.ARCHIVE_DIRECTORY]: ArchiveDirectoryConfirm,
  [MODAL.DETTACH_COMPANY]: DettachCompanyConfirm,
  [MODAL.DETTACH_CONTACT]: DettachContactConfirm,
  [MODAL.DIRECTORY_CATEROGY_FORM]: DirectoryCaterogyForm,
  [MODAL.DIRECTORY_FAMILY_FORM_UPDATE]: DirectoryFamilyFormUpdate,
  [MODAL.DIRECTORY_FAMILY_FORM]: DirectoryFamilyForm,
  [MODAL.MERGE_DIRECTORY]: MergeDirectoryForm,
  [MODAL.RESTORE_DIRECTORY]: RestoreDirectoryConfirm,
  [MODAL.UPDATE_ADDRESS_FORM]: UpdateAddressForm,
  [MODAL.UPDATE_DIRECTORY_CATEROGY_FORM]: UpdateDirectoryCaterogyForm,
  [MODAL.INTERACTION_FORM]: InteractionForm,
  [MODAL.PRICING_ACTION]: PricingActionForm,

  [MODAL.UPDATE_LIST_OF_CONTACT]: updateContactList,
};
