import {VariantAlert} from 'features/stock/types/variant-alert';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getVariantAlertsInStock = api.injectEndpoints({
  endpoints: builder => ({
    getVariantAlertsInStock: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `stock-accounting/alerts?type=${params.id}`,
      }),
      forceRefetch: () => true,
    }),
  }),
});

type Response = ResponseData<VariantAlert[]>;

type Request = {
  id?: ObjectId;
  type?: string;
};

export const {useGetVariantAlertsInStockQuery} = getVariantAlertsInStock;
