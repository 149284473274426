import React from 'react';
//import Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

const Dashboard = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[{title: t(...messages.dashboard()), path: '#'}]}
        />
      </Container>
    </div>
  );
};

export default Dashboard;
