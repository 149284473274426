import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectProduct} from 'store/production/endpoints/show-product';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {
  VariantInfoRequest,
  useUpdateVariantInfoMutation,
} from 'store/production/endpoints/update-variant-general-info';
import {ObjectId} from 'types/object-id';
import {Product} from 'types/product';
import {ProductDimension} from 'types/product-dimension';
import {ProductVariant} from 'types/product-variant';
import makeId from 'utils/make-id';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useGeneralForm = () => {
  const [updateVariant, {isLoading, error, data: response}] =
    useUpdateVariantInfoMutation();

  const serverError = parseValidationErrors(error);

  const {variant: id, id: productId} = useParams();

  const parent = useSelector(selectProduct({id: productId})).data
    ?.data as Product;

  const hasSelfComposedParent =
    parent?.product_collection?.type === 'COMPONENT';

  const [params, setParams] = useState<Params>({
    sku: '',
    depth: '',
    width: '',
    diameter: '',
    height: '',
    finition: '',
    comment: '',
    name: '',
    limited_edition_count: '',
    is_limited_edition: false,
    catalog: false,
    self_composed: false,
    is_published: false,
    creation_date: undefined,
    stock_min: '',
    stock_max: '',
    description: '',
    hasSelfComposedParent,
    ecotax: undefined,
  });

  const [dimensions, setDimensions] = useState<
    Record<ObjectId, ProductDimension>
  >({});
  const [deleteRecords, setdeleteRecords] = useState([] as ObjectId[]);

  const {data} = useSelector(selectVariant({id, productId}));

  const variant = data?.data as ProductVariant;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updateVariant({
      ...params,
      package_dimensions: dimensions,
      deleteRecords,
      id: productId,
      variant: id,
    });

    if ('data' in result) {
      redirectByVariant(result.data.data);
      dispatch(toast('success', result.data.message));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const redirectByVariant = (v: ProductVariant) => {
    searchParams.set(
      'filter[product_variant_category_name]',
      String(v.product_variant_category?.name)
    );
    const newParams = searchParams.toString();
    navigate(
      `/dashboard/products/${v.product_id}/variants/${v.id}/general?${newParams}`
    );
  };

  const addPackaging = () => {
    const p = {
      id: makeId(),
      net_weight: '',
      name: '',
      depth: '',
      width: '',
      height: '',
      weight: '',
      volume: '',
    };
    setDimensions(
      produce(dimensions, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removePackaging = (id: ObjectId) => {
    const _dimensions: Record<ObjectId, ProductDimension> = Object.assign(
      {},
      dimensions
    );
    delete _dimensions[id];
    setDimensions({
      ..._dimensions,
    });
    setdeleteRecords([...deleteRecords, id]);
  };

  const updatePackaging = (
    p: ProductDimension,
    k: keyof ProductDimension,
    v: any
  ) => {
    setDimensions(
      produce(dimensions, draft => {
        draft[p.id][k] = v;
      })
    );
  };
  const initiatePackaging = (p: ProductDimension[]) => {
    let package_dimension = {} as Record<ObjectId, ProductDimension>;
    p.map((p: any) => {
      const d = produce(package_dimension, draft => {
        draft[p.id] = p;
      });
      package_dimension = {...package_dimension, ...d};
    });
    setDimensions(package_dimension);
  };
  useEffect(() => {
    setParams({
      ...params,
      sku: variant?.sku ?? '',
      depth: variant?.depth ?? '',
      width: variant?.width ?? '',
      height: variant?.height ?? '',
      diameter: variant?.diameter ?? '',
      stock_max: variant?.stock_max,
      stock_min: variant?.stock_min,
      finition: variant?.finition ?? '',
      comment: variant?.comment ?? '',
      name: variant?.name ?? '',
      limited_edition_count: variant?.limited_edition_count ?? '',
      is_limited_edition: variant?.is_limited_edition ?? false,
      self_composed: variant?.self_composed ?? false,
      is_published: variant?.is_published ?? false,
      creation_date: variant?.creation_date,
      ecotax: variant?.ecotax
        ? {
            id: variant?.ecotax.id,
            name: variant?.ecotax.product_code,
          }
        : undefined,
      catalog: variant?.catalog ?? false,
      product_variant_category:
        variant?.product_variant_category ?? ({} as any),
      description: variant?.description ?? '',
      matters: variant?.matters,
      colors: variant?.colors,
      is_transferable: variant?.is_transferable,
    });
    initiatePackaging(variant?.package_dimension ?? []);
  }, [variant]);

  return {
    addPackaging,
    initiatePackaging,
    removePackaging,
    setDimensions,
    setParams,
    submit: handleSubmit,
    variantName: variant?.name,
    updatePackaging,
    dimensions,

    isLoading,
    params,

    validationErrors: serverError.errors,
  };
};

type Params = Partial<VariantInfoRequest>;
