import {useContext, useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {
  useUpdateDirectoryAttachmentsMutation,
  DirectoryAttachmentsRequest,
} from 'store/directory/endpoints/update-attachments';
import {DirectoryAttachment} from 'types/attachements';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAttachmentsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateDirectoryAttachmentsMutation();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const [attachments, setAttachments] = useState<AttachmentMap>({});

  const [deletedRecords, setDeletedRecords] = useState<
    Record<ObjectId, boolean>
  >({});

  const [params, setParams] = useState<Params>({});
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      attachments: Object.values(attachments),
      deleted_records: Object.keys(deletedRecords).filter(
        k => deletedRecords[k]
      ),
      id,
    });

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const addAttachments = (type?: any) => {
    const p: DirectoryAttachment = {
      id: makeId(),
      type,
    };
    setAttachments(
      produce(attachments, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeAttachments = (id: ObjectId) => {
    const _attachments: AttachmentMap = Object.assign({}, attachments);
    delete _attachments[id];
    deletedRecords[id] = !deletedRecords[id];
    setAttachments({
      ..._attachments,
    });
  };
  const updateAttachment = (
    p: DirectoryAttachment,
    k: keyof DirectoryAttachment,
    v: any
  ) => {
    setAttachments(
      produce(attachments, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;

  useEffect(() => {
    if (directory) {
      const tmp = directory.attachements?.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {} as AttachmentMap);
      setAttachments(tmp);
      // @ts-ignore
      setParams({...params, directory_id: directory.id});
    }
  }, [directory]);

  return {
    addAttachments,
    attachments,
    directory,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    params,
    removeAttachments,
    setParams,
    submit: handleSubmit,
    updateAttachment,
    validationErrors: serverError.errors,
    canEdit,
  };
};

type AttachmentMap = Record<ObjectId, DirectoryAttachment>;

type Params = Partial<DirectoryAttachmentsRequest>;
