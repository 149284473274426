import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationsRelatedPartsTab.title, 'Related documents'),
  code: () => __(translations.quotationsRelatedPartsTab.code, 'Code'),
  client: () => __(translations.quotationsRelatedPartsTab.client, 'Client'),
  date: () => __(translations.quotationsRelatedPartsTab.date, 'Date'),
  totalExcl: () => __(translations.quotationsRelatedPartsTab.totalExcl, 'Total excl taxes'),
  status: () => __(translations.quotationsRelatedPartsTab.status, 'Status'),
};
