import React from 'react';
import {ColumnDef} from '@tanstack/table-core';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import Currency from 'components/currency/currency';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CompactQuotation} from 'types/quotation';

const quoteColumns: ColumnDef<CompactQuotation>[] = [
  {
    header: r => <Trans i18nKey="customerOrderPage.number" />,
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} type="QUOTATION" column="ref">
        <Link to={`/dashboard/quotations/${row.id}/details`} target="_blank">
          {row.document_ref}
        </Link>
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'document_ref',
    meta: {costumClass: 'font-size-14'},
  },

  {
    header: r => <Trans i18nKey="customerOrderPage.date" />,
    accessorFn: row =>
      (
        <FormattedDate
          value={row.production_declared_completion_date}
          format="date"
        />
      ) ?? <span className="w-100 text-center">-</span>,
    id: 'production_declared_completion_date',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="customerOrderPage.total_ht" />,
    accessorFn: row => <Currency value={row.total_ht} />,

    id: 'total_ht',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.status" />,
    enableSorting: true,
    accessorFn: row => <StatusBadge status={row.status} />,
    meta: {costumClass: 'font-size-14'},
    cell: r => r.getValue(),
    id: 'status',
  },
];

export default quoteColumns;
