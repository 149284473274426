import React from 'react';
import authRoutes from './auth';
import guestRoutes from './guest';
import {AppRoute} from './types';
import {Navigate} from 'react-router-dom';

const protectedAuthRoutes: AppRoute[] = authRoutes.map(x => ({
  ...x,
  requireAuth: true,
}));
const protectedGuestRoutes: AppRoute[] = guestRoutes.map(x => ({
  ...x,
  requireGuest: true,
}));

const routes: Array<AppRoute> = [
  ...protectedAuthRoutes,
  ...protectedGuestRoutes,
  // this route should be at the end of all other routes
  {path: '/', exact: true, component: <Navigate to="/welcome" />},
];

export default routes;
