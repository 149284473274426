import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const toggleCreditArchive = api.injectEndpoints({
  endpoints: builder => ({
    toggleCreditArchive: builder.mutation<Response, Request>({
      query: ({id}) => {
        return {
          method: 'POST',
          url: `credit-invoices/${id}/toggle-archive`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{affected_row: ObjectId}>;

export const {useToggleCreditArchiveMutation} = toggleCreditArchive;
