import {FilterMap} from 'features/listing/list/slice/types';
import {isArray} from 'lodash';
import {Option} from 'types/option';

export default function queryParams(
  params: FilterMap,
  delimiter: string = ',',
  optionValue: keyof Option = 'id'
) {
  let queryParams = {};

  Object.keys(params).forEach(key => {
    if (!!key) {
      const val = params[key];
      let param;
      if (val) {
        if (typeof val === 'string') {
          param = val;
        }

        if (typeof val === 'number') {
          param = val.toString();
        }
        if (typeof val === 'boolean') {
          param = val ? '1' : '0';
        }
        if (typeof val === 'object' && !isArray(val)) {
          param = val[optionValue] as string;
        }

        if (typeof val === 'object' && Array.isArray(val)) {
          const arr = val as Option[];
          param = arr
            .map(x => (typeof x === 'string' ? x : x[optionValue]))
            .join(delimiter);
        }
      }
      if (param) {
        Object.assign(queryParams, {[key]: param});
      }
    }
  });
  return queryParams;
}
