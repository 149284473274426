import {createAsyncThunk} from '@reduxjs/toolkit';
import {application} from 'constants/application';
import {SharedPrefs} from 'constants/shared-prefs';
import i18nService from 'services/i18n-service';
import {loadString} from 'services/storage-service';
import hydrateAuthState from 'store/auth/actions/hydrate-auth-state';
import retrieveFcmToken from 'store/auth/actions/retrieve-fcm-token';
import loadLayoutPreferences from 'store/layout/actions/load-layout-preferences';
import {delay} from 'utils/delay';

const loadSharedPreferences = async () => {
  let appLocale = await loadString(SharedPrefs.APP_LOCALE);
  let tutorialSeenAt = await loadString(SharedPrefs.TUTORIAL_KEY);

  return {
    appLocale: appLocale || application.defaultLocale,
    showTutorial: !tutorialSeenAt,
  };
};

const initializeApplication = createAsyncThunk(
  'global/initializeApplication',
  async (_, thunkApi) => {
    const sharedPrefs = await loadSharedPreferences();
    i18nService.applyLocale(sharedPrefs.appLocale);
    await thunkApi.dispatch(hydrateAuthState());
    thunkApi.dispatch(retrieveFcmToken());
    thunkApi.dispatch(loadLayoutPreferences());
    await delay(500);
    return sharedPrefs;
  }
);

export default initializeApplication;
