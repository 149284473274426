import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.directoryCategoriesPage.action, 'Action'),
  categories: () => __(translations.directoryCategoriesPage.categories, 'Categories'),
  name: () => __(translations.directoryCategoriesPage.name, 'Name'),
  remove: () => __(translations.directoryCategoriesPage.remove, 'Remove'),
  createdAt: () => __(translations.directoryCategoriesPage.createdAt, 'Created at'),
  title: () => __(translations.directoryCategoriesPage.title, 'Directory categories'),
  edit: () => __(translations.directoryCategoriesPage.edit, 'Edit'),
  type: () => __(translations.directoryCategoriesPage.type, 'Type'),
  directories: () => __(translations.directoryCategoriesPage.directoryPageTitle, 'Directories'),
  family: () => __(translations.directoryCategoriesPage.family, 'Families'),
  addbtn: () => __(translations.directoryCategoriesPage.addbtn, 'Add'),
};
