import TextInput from 'components/text-input';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showSearchModal from 'store/ui/actions/show-search-modal';
import {useLocalStorage} from 'usehooks-ts';

const GlobalSearch = () => {
  const [sideBarState] = useLocalStorage('count', 1);
  const {t} = useTranslation();
  const [serchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const handleSearchModal = () => {
    dispatch(showSearchModal());
  };
  const handleSearch = (value: any) => {
    setSearchValue(value);
    dispatch(showSearchModal(value));
  };

  return (
    <div
      onClick={handleSearchModal}
      className={sideBarState === 1 ? 'app-search  d-lg-block' : 'px-3'}
    >
      <div className={sideBarState === 1 ? 'position-relative' : ''}>
        {sideBarState === 1 && (
          <TextInput
            type="text"
            onClick={handleSearchModal}
            onChange={handleSearch}
            className="form-control  bg-white"
            placeholder={t('globalSearchModal.placeHolder')}
          />
        )}
        <button className="btn btn-secondary" type="button">
          <i className="bx bx-search-alt align-middle"></i>
        </button>
      </div>
    </div>
  );
};

export default GlobalSearch;
