import {CommercialProduct} from '../types/commercial-product';
import makeItem from '../utils/make-item';
import makeOption from '../utils/make-option';
import makePayment from '../utils/make-payment';
import {SituationPrices} from './endpoints/show-situation-prices';
import {initialState} from './initial-state';
import {DocumentFields, OptionMap, UpsertAction} from './types';
import {CommercialDocumentState} from './types';
import {KeyValueChangeAction} from './types';
import {PartialChangeAction, ReorderPayload} from './types';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {merge} from 'lodash';
import {Address} from 'types/address';
import {CommercialProductOption} from 'types/commercial-product-option';
import {CommercialProductOptionType} from 'types/commercial-product-option';
import {CommercialProductType} from 'types/commercial-product-type';
import {CompactVariant} from 'types/compact-variant';
import {ObjectId} from 'types/object-id';
import {Payment} from 'types/payment';
import {Situation} from 'types/situation';
import denormalizeObject from 'utils/denormalize-object';

const commercialDocumentSlice = createSlice({
  name: 'commercialDocument',
  initialState,
  reducers: {
    addItem(
      state,
      action: PayloadAction<{at: number; type: CommercialProductType}>
    ) {
      const item = makeItem(action.payload);
      state.stagedRecords[item.id] = true;
      state.items[item.id] = item;
    },
    updateItem(state, action: PartialChangeAction<CommercialProduct>) {
      const {item, key, value} = action.payload;
      // @ts-expect-error
      state.items[item.id][key] = value;
    },
    upsertItem(state, action: UpsertAction) {
      const partiallyUpdatedValues = action.payload;
      const id = partiallyUpdatedValues.id;
      const old = {...state.items[id]};
      state.items[id] = merge(old, partiallyUpdatedValues);
    },
    updateItemVariant(
      state,
      action: PayloadAction<{
        item: CommercialProduct;
        value: CommercialProduct['variant'];
        newOptions: OptionMap;
      }>
    ) {
      const {item, value, newOptions} = action.payload;
      state.items[item.id].variant = value;
      state.items[item.id].unit_price_ht = value?.selling_price_ht ?? '';
      state.items[item.id].discount = value?.discount ?? '';
      state.items[item.id].custom_name = value?.name ?? '';
      state.items[item.id].vat = value?.vat;
      (state.items[item.id].handel_stock = value?.default_handel_stock ?? true),
        delete state.items[item.id].situation;
      state.itemOptions = {...state.itemOptions, ...newOptions};
      Object.keys(newOptions).forEach(x => {
        state.stagedRecords[x] = true;
      });
    },
    deleteOptionsById(state, action: PayloadAction<ObjectId>) {
      const allOptions = denormalizeObject(state.itemOptions);
      allOptions.forEach(o => {
        if (o.commercial_product_id === action.payload) {
          delete state.itemOptions[o.id];
        }
      });
    },
    updateItemSituation(
      state,
      action: PayloadAction<{
        item: CommercialProduct;
        situation?: Situation;
        situationPrices?: SituationPrices;
      }>
    ) {
      const {item, situation, situationPrices} = action.payload;
      state.items[item.id].situation = situation;
      state.items[item.id].unit_price_ht =
        situationPrices?.selling_price_ht ?? '';
      state.items[item.id].discount = situationPrices?.discount ?? '';
    },
    changeField(state, action: KeyValueChangeAction<DocumentFields>) {
      const {key, value} = action.payload;
      state.fields[key] = value;
    },
    removeItem(state, action: PayloadAction<ObjectId>) {
      const id = action.payload;
      delete state.items[id];
    },
    addItemOption(
      state,
      action: PayloadAction<{line: number; type: CommercialProductOptionType}>
    ) {
      const {type, line} = action.payload;
      const option = makeOption(line, type);
      state.stagedRecords[option.id] = true;
      state.itemOptions[option.id] = option;
    },
    updateItemOption(
      state,
      action: PartialChangeAction<CommercialProductOption>
    ) {
      const {item, key, value} = action.payload;
      state.itemOptions[item.id][key] = value;
    },
    updateItemOptionVariant(
      state,
      action: PayloadAction<{
        item: CommercialProductOption;
        value: CompactVariant['customization_options'][number];
      }>
    ) {
      const {item, value} = action.payload;
      state.itemOptions[item.id].option_value = value;
      state.itemOptions[item.id].name = value?.name;
      state.itemOptions[item.id].price_ht = value?.selling_price_ht;
    },
    removeItemOption(state, action: PayloadAction<ObjectId>) {
      const id = action.payload;
      delete state.itemOptions[id];
    },
    reorderItems(state, action: PayloadAction<ReorderPayload>) {
      const {itemOrder, items} = action.payload;
      state.items = items;
      state.itemOrder = itemOrder;
    },
    removeAddress(state, action: PayloadAction<ObjectId>) {
      const id = action.payload;
      delete state.addresses[id];
    },
    upsertAddress(state, action: PayloadAction<Address>) {
      const address = action.payload;
      state.addresses[address.id] = address;
    },
    addPayment(state) {
      const payment = makePayment();
      state.stagedRecords[payment.id] = true;
      state.payments[payment.id] = payment;
    },
    addRefundPayment(state, action: PayloadAction<Payment>) {
      const payment = action.payload;
      state.stagedRecords[payment.id] = true;
      state.payments[payment.id] = payment;
    },
    addPaymentFrom(state, action: PayloadAction<Payment>) {
      const payment = action.payload;
      state.stagedRecords[payment.id] = true;
      state.payments[payment.id] = payment;
    },
    updatePayment(state, action: PartialChangeAction<Payment>) {
      const {item, key, value} = action.payload;
      // @ts-expect-error
      state.payments[item.id][key] = value;
    },
    removePayment(state, action: PayloadAction<ObjectId>) {
      const id = action.payload;
      delete state.payments[id];
    },
    hydrateFields(state, action: PayloadAction<CommercialDocumentState>) {
      state.addresses = action.payload.addresses;
      state.fields = action.payload.fields;
      state.itemOptions = action.payload.itemOptions;
      state.itemOrder = action.payload.itemOrder;
      state.items = action.payload.items;
      state.payments = action.payload.payments;
      state.stagedRecords = {};
    },
    reset(state) {
      state = {...initialState};
    },
  },
  extraReducers() {},
});

export const {actions, reducer} = commercialDocumentSlice;

export default commercialDocumentSlice;
