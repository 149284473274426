import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {
  useUpdateUserPasswordMutation,
  useUpdateUserMutation,
} from 'store/users/endpoints';
import {UpdateUserRequest} from 'store/users/endpoints/update-user';
import {ObjectId} from 'types/object-id';

export const useEditUserForm = () => {
  const [updateUser, {isLoading, error, isSuccess}] = useUpdateUserMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({
    id: '' as ObjectId,
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    phone_number: '',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await updateUser(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    isSuccess,
    submit: handleSubmit,
  };
};
export const useEditPasswordUserForm = () => {
  const [UpdateUserPassword, {isLoading, error, isSuccess}] =
    useUpdateUserPasswordMutation();
  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState({
    id: '' as ObjectId,
    password: '',
    password_confirmation: '',
  });
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await UpdateUserPassword(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    isSuccess,
    submit: handleSubmit,
  };
};

type Params = {} & Partial<UpdateUserRequest>;
