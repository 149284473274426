import React from 'react';
import {useToggleCreditUsageMutation} from 'pages/accounting/store/endpoints/toggle-credit-usage';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useListSlice} from 'features/table/slice';
import {useToggleCreditArchiveMutation} from 'pages/accounting/store/endpoints/toggle-credit-archive';
import {STATUS} from 'constants/status';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUpdateCreditStatusForm = (onDismiss: () => void) => {
  const [toggleUsage, {isLoading: isTogglingUsage, error: usageError}] =
    useToggleCreditUsageMutation();
  const [toggleArchive, {isLoading: isTogglingArchive, error: archiveError}] =
    useToggleCreditArchiveMutation();

  const serverError = parseValidationErrors(usageError || archiveError);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, credit_id: id, status, currentStatus} = modalParams;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const query = status === STATUS.ARCHIVED ? toggleArchive : toggleUsage;

    const result = await query({
      id,
    });

    if ('data' in result) {
      dispatch(toast('success', result.data?.message));

      const {actions} = useListSlice();
      dispatch(
        actions.loadData({
          url: `credit-invoices?filter[status]=${currentStatus}`,
          name: 'credit-invoices',
          page: 1,
        })
      );

      onDismiss();
    }
  };

  return {
    message,
    isLoading: isTogglingUsage || isTogglingArchive,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
