import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateProduct = api.injectEndpoints({
  endpoints: builder => ({
    updateProduct: builder.mutation<Response, UpdateProductRequest>({
      query: params => ({
        method: 'PUT',
        url: `/products/${params.id}`,
        body: {
          comment: params.comment,
          description: params.description,
          in_catalog: params.in_catalog ? 1 : 0,
          name: params.name,
          product_collection_id: params.product_collection?.id,
          product_family_id: params.product_family?.map(x => x.id),
          vat_id: params.vat?.id,
        },
      }),
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'product', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type UpdateProductRequest = {
  product_collection?: Option;
  vat?: Option;
  comment: string;
  description?: string;
  product_family: Option[];
  id?: ObjectId;
  in_catalog: boolean;
  is_new?: boolean;
  label: string;
  name: string;
};

type Response = ResponseData<UpdateProductRequest>;

export const {useUpdateProductMutation} = updateProduct;
