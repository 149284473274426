import {createAsyncThunk} from '@reduxjs/toolkit';
import {loadAuthState} from 'services/auth-service';

const hydrateAuthState = createAsyncThunk(
  'auth/hydrateFromLocaleStorage',
  async (_, thunkApi) => {
    const authState = await loadAuthState();
    if (!authState?.accessToken || !authState.user) {
      return thunkApi.rejectWithValue(null);
    }
    return {
      token: authState.accessToken,
      data: authState.user,
    };
  }
);

export default hydrateAuthState;
