import React, {Fragment} from 'react';
import messages from './messages';
import selectPurchasePrice from './slice/selectors/select-purchase-price';
import selectPurchasePriceIncludingTax from './slice/selectors/select-purchase-price-including-tax';
import selectVat from './slice/selectors/select-vat';
import AsyncItemPicker from 'components/async-item-picker';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Col} from 'reactstrap';

const PurchaseCosts = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const purchasePrice = useSelector(selectPurchasePrice);

  const {id} = useParams();

  const {coe, vat} = useSelector(selectVat(id));

  const purchasePriceIncludingTax = useSelector(
    selectPurchasePriceIncludingTax(coe)
  );

  return (
    <Fragment>
      <Col md={3}>
        <TextInput
          label={t(...messages.purchaseCostsSubtotal())}
          disabled
          readOnly
          value={purchasePrice.toFixed(2)}
          type="text"
          onChange={() => {}}
        />
        <AsyncItemPicker
          endPoint="data/vats"
          label={t(...messages.vat())}
          type="text"
          getOptionLabel={(v: any) => v.value}
          value={vat}
          disabled
          onChange={v => {}}
        />
        <TextInput
          label={t(...messages.purchaseCostsTotal())}
          disabled
          readOnly
          type="number"
          value={purchasePriceIncludingTax.toFixed(2)}
          onChange={() => {}}
        />
      </Col>
    </Fragment>
  );
};

export default PurchaseCosts;
