import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.createStockOrderPage.title, 'Create stock orders'),
  stockOrders: () => __(translations.createStockOrderPage.stockOrders, 'Stock orders'),
  client: () => __(translations.createStockOrderPage.client, 'Client'),
  type: () => __(translations.createStockOrderPage.type, 'Type of order'),
  typeSupplier: () => __(translations.createStockOrderPage.typeSupplier, 'Order type'),
  category: () => __(translations.createStockOrderPage.category, 'Category of order'),
  origin: () => __(translations.createStockOrderPage.origin, 'Origin'),
  language: () => __(translations.createStockOrderPage.language, 'Language'),
  invoiceDate: () => __(translations.createStockOrderPage.invoiceDate, 'Invoice date'),
  dueDate: () => __(translations.createStockOrderPage.dueDate, 'Due date'),
  addNewCompanyClient: () => __(translations.createStockOrderPage.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.createStockOrderPage.addNewPrivateClient, 'Add Private'),
  imh: () => __(translations.createStockOrderPage.imh, 'IMH'),
  stockOrderType: () => __(translations.createStockOrderPage.stockOrderType, 'CCS - Stock'),
};
