import React, {FC, Fragment, useState} from 'react';
import messages from '../messages';
import {InvoiceFilters} from './types';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {useLazyGetInvoicesExportQuery} from 'features/commercial/invoices/store/endpoints/get-invoices';
import {useLazyGetInvoicesCommercialProductsExportQuery} from 'features/commercial/invoices/store/endpoints/get-invoices-commercial-products';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Dropdown, DropdownToggle} from 'reactstrap';
import {Spinner, DropdownMenu, DropdownItem} from 'reactstrap';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';

interface Props {
  filters: FilterMap<InvoiceFilters>;
}

const Actions: FC<Props> = props => {
  const {filters} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [submitInvoicesProduct, {isFetching: isLoadingInvoices}] =
    useLazyGetInvoicesCommercialProductsExportQuery();

  const [submitInvoices, {isFetching: isLoadingTable}] =
    useLazyGetInvoicesExportQuery();

  const handleSubmitTables = async (filters: any) => {
    const result = await submitInvoices(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };
  const handleSubmitInvoiceProducts = async (filters: any) => {
    const result = await submitInvoicesProduct(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <AppBarActions>
      <Fragment>
        <Can subject={['c', 'r', 'h', 'v', 'z']} action="create-and-edit">
          <Button
            color="important"
            onClick={() => {
              navigate('/dashboard/invoices/create');
            }}
          >
            <Icon name="plus" className="me-1" size={15} />
            {t(...messages.addInvoice())}
          </Button>
        </Can>
        {/* Action */}
        <Can subject={['c', 'r', 'h', 'v', 'z']} action="export">
          <Dropdown
            disabled={isLoadingInvoices || isLoadingTable}
            isOpen={dropdownOpen}
            className="ms-2"
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle color="light">
              {isLoadingInvoices || isLoadingTable ? (
                <Spinner size={'sm'} />
              ) : (
                t(...messages.exports())
              )}
              <Icon name="chevron-down" size={15} className="ms-1" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={isLoadingTable}
                onClick={() => handleSubmitTables(filters)}
              >
                {t(...messages.exportTable())}
              </DropdownItem>
              <DropdownItem
                disabled={isLoadingInvoices}
                onClick={() => handleSubmitInvoiceProducts(filters)}
              >
                {t(...messages.exportInvoices())}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Can>

        {/* Add */}
      </Fragment>
    </AppBarActions>
  );
};

export default Actions;
