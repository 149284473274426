import {api} from 'services/api';
import {ProjectAttachment} from 'types/attachements';
import {ObjectId} from 'types/object-id';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectAttachments = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectAttachments: builder.mutation<
      Response,
      StoreProjectAttachmentsRequest
    >({
      query: (body: StoreProjectAttachmentsRequest) => {
        return {
          method: 'POST',
          url: `projects/${body.id}/attachments`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type StoreProjectAttachmentsRequest = {
  id: ObjectId;
  attachments: ProjectAttachment[];
  deleted_records: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectAttachmentsMutation} = storeProjectAttachments;
