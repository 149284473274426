import React, {FC} from 'react';
import {IAttachmentsForm} from '../use-attachments-form';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import {Eye, Trash2, X} from 'react-feather';
import TextInput from 'components/text-input';
import DragDropFile from 'components/dragndrop';
import {TemporaryFile} from 'types/temporary-file';
import DateInput from 'components/date-input';
import {now} from 'utils/now';
import {ProjectAttachmentTypesEnum} from 'features/studio/projects/constants/project-attachments';
import Can from 'features/hylian-shield/can';

export const AttachmentAccordion: FC<Props> = ({
  regroupedAttachments,
  validationErrors,
  subject,
  handleRemoveAttachment,
  handleChangeAttachment,
  handleAddAttachment,
}) => {
  const {t} = useTranslation();

  return (
    <UncontrolledAccordion stayOpen>
      {regroupedAttachments.map((item, x) => (
        <AccordionItem key={x}>
          <AccordionHeader targetId={x.toString()}>
            <h5 color="light" className=" align-self-center">
              {t(`editProjectAttachmentSection.${item.type.toLowerCase()}`)}
              <Badge
                color={item.data.length > 0 ? 'success' : 'light'}
                className="mx-2"
              >
                {item.data.length}
              </Badge>
            </h5>
          </AccordionHeader>
          <AccordionBody accordionId={x.toString()}>
            <div>
              <Can action="edit" subject={subject}>
                <div className="d-flex justify-content-end">
                  <Button
                    className=" me-2"
                    onClick={() => handleAddAttachment(item.type)}
                    color="important"
                  >
                    {t(...messages.addAttachment())}
                  </Button>
                </div>
                <hr />
              </Can>

              {item.data.length > 0 ? (
                <>
                  {item.data.map((child, y) => (
                    <div key={x + y}>
                      {/* HUB */}
                      <div className="d-flex gap-2 mb-2">
                        {item.type ===
                          ProjectAttachmentTypesEnum.IMAGES_PHOTOS && (
                          <FormGroup
                            switch
                            className="d-flex justify-content-end gap-1"
                          >
                            <Input
                              checked={child.is_primary_image}
                              onChange={() => {
                                handleChangeAttachment(
                                  child.identifier,
                                  'is_primary_image',
                                  !child.is_primary_image
                                );
                              }}
                              type="switch"
                              role="switch"
                            />
                            <Label check>
                              {t(...messages.principalImage())}
                            </Label>
                          </FormGroup>
                        )}

                        {(item.type === ProjectAttachmentTypesEnum.HUB ||
                          item.type === ProjectAttachmentTypesEnum.PRESS) && (
                          <FormGroup
                            switch
                            className="d-flex justify-content-end gap-1"
                          >
                            <Input
                              checked={child.is_published}
                              onChange={() => {
                                handleChangeAttachment(
                                  child.identifier,
                                  'is_published',
                                  !child.is_published
                                );
                              }}
                              type="switch"
                              role="switch"
                            />
                            <Label check>{t(...messages.publish())}</Label>
                          </FormGroup>
                        )}
                      </div>
                      <Row className=" align-items-center">
                        <Col md={3}>
                          <TextInput
                            label={t(...messages.fileName())}
                            type="text"
                            value={child.name}
                            onChange={x =>
                              handleChangeAttachment(
                                child.identifier,
                                'name',
                                x
                              )
                            }
                          />
                        </Col>
                        {item.type === ProjectAttachmentTypesEnum.HUB && (
                          <Col md={3}>
                            <TextInput
                              label={t(...messages.legendAndCredits())}
                              type="text"
                              value={child.credits}
                              onChange={x =>
                                handleChangeAttachment(
                                  child.identifier,
                                  'credits',
                                  x
                                )
                              }
                            />
                          </Col>
                        )}
                        <Col className="align-items-center">
                          <div className="d-flex justify-content-between">
                            <label className="mb-0" htmlFor="">
                              {t(...messages.file())}
                            </label>
                            <Can action="edit" subject={subject}>
                              <a
                                className="primary"
                                onClick={() =>
                                  handleChangeAttachment(
                                    child.identifier,
                                    'file',
                                    ''
                                  )
                                }
                              >
                                <X size={18} />
                              </a>
                            </Can>
                          </div>
                          <DragDropFile
                            file={child?.file}
                            fileNameLength={60}
                            errors={
                              validationErrors[
                                `attachments.${child.identifier}.file_id`
                              ]
                            }
                            onChangeFile={(x: TemporaryFile) =>
                              handleChangeAttachment(
                                child.identifier,
                                'file',
                                x
                              )
                            }
                          />
                        </Col>
                        <Col lg={3}>
                          <div className="d-flex gap-1">
                            <div className="flex-grow-1">
                              <DateInput
                                value={child.date ? child.date : now()}
                                label={t(...messages.date())}
                                onChange={x =>
                                  handleChangeAttachment(
                                    child.identifier,
                                    'date',
                                    x
                                  )
                                }
                              />
                            </div>
                            <a
                              color="light"
                              className="btn btn-light d-inline-block align-self-end"
                              target="_blank"
                              rel="noreferrer"
                              href={child.original}
                            >
                              <Eye size={14} />
                            </a>
                            <Can action="edit" subject={subject}>
                              <Button
                                color="transparent"
                                className="d-inline-block align-self-end"
                                id={`tooltip-${(x + y).toString()}-remove`}
                                onDoubleClick={() =>
                                  handleRemoveAttachment(child.identifier)
                                }
                              >
                                <Trash2 className="text-danger" size={14} />
                              </Button>
                              <UncontrolledTooltip
                                placement="top"
                                target={`tooltip-${(x + y).toString()}-remove`}
                              >
                                {t('common.doubleClickToRemove')}
                              </UncontrolledTooltip>
                            </Can>
                          </div>
                        </Col>
                        <hr
                          className="mt-4"
                          style={{
                            display:
                              y < item.data.length - 1 ? 'block' : 'none',
                          }}
                        ></hr>
                      </Row>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-center">{t('common.noData')}</p>
              )}
            </div>
          </AccordionBody>
        </AccordionItem>
      ))}
    </UncontrolledAccordion>
  );
};

type Props = IAttachmentsForm;
