import {api} from 'services/api';
import {DirectoryCategory} from 'types/directory-category';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateDirectoryCategory = api.injectEndpoints({
  endpoints: builder => ({
    updateDirectoryCategory: builder.mutation<
      Response,
      UpdateDirectoryCategoryRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `directory-categories/${params.id}`,
          body: {
            comment: params.comment,
            name: params.name,
          },
        };
      },
    }),
  }),
});

export type UpdateDirectoryCategoryRequest = {
  id?: ObjectId;
  name?: string;
  comment?: string;
};

type Response = ResponseData<DirectoryCategory>;

export const {useUpdateDirectoryCategoryMutation} = updateDirectoryCategory;
