import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.updateAddressModal.title, 'Address'),
  addressType: () => __(translations.updateAddressModal.addressType, 'Address type'),
  city: () => __(translations.updateAddressModal.city, 'City'),
  country: () => __(translations.updateAddressModal.country, 'Country'),
  firstLine: () => __(translations.updateAddressModal.firstLine, 'Line 1'),
  secondLine: () => __(translations.updateAddressModal.secondLine, 'Line 2'),
  thirdLine: () => __(translations.updateAddressModal.thirdLine, 'Line 3'),
  vatMode: () => __(translations.updateAddressModal.vatMode, 'Vat mode'),
  zipCode: () => __(translations.updateAddressModal.zipCode, 'Zip code'),
  comment: () => __(translations.updateAddressModal.comment, 'Comment'),
  name: () => __(translations.updateAddressModal.name, 'Name'),
  defaultAddress: () => __(translations.updateAddressModal.defaultAddress, 'Default address'),
};
