import {makeDeleteResourceUrl} from 'features/modal-notch/soul-eater/constants';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResourceName} from 'types/resource-name';
import {ResponseData} from 'types/response-data';

const destroyResource = api.injectEndpoints({
  endpoints: builder => ({
    destroyResource: builder.mutation<
      ResponseData<Response>,
      DestroyResourceRequest
    >({
      query: ({resourceId, name, otherParams}) => ({
        method: 'DELETE',
        url: makeDeleteResourceUrl({
          resourceId,
          name,
          otherParams,
        }),
      }),
    }),
  }),
});

export type DestroyResourceRequest = {
  resourceId: string | number;
  name: ResourceName;
  otherParams?: any;
};

type Response = {
  affected_row: ObjectId;
  message: string;
};

export const {useDestroyResourceMutation} = destroyResource;

export const destroyResourceEndpoint =
  destroyResource.endpoints.destroyResource;
