import React from 'react';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import StockProductActions from './stock-products-actions';
import {StockProduct} from 'features/stock/types/stock-products';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import {Link} from 'react-router-dom';
import StatusBadge from 'components/status-badge';
import {CUSTOM_SERIAL_NUMBER_STATUSES} from 'pages/dashboard/products/edit/variant/serial-number/constants';

const columns: ColumnDef<StockProduct>[] = [
  {
    header: r => <Trans i18nKey="stockProductsPage.serialNumber" />,
    accessorFn: row => (
      <Link to={row.pdf_links.label} target="_blank">
        <EmptyCell cell={row.code} />
      </Link>
    ),
    id: 'code',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.sku" />
      </span>
    ),
    accessorFn: r => <EmptyCell cell={r.product_variant?.sku} />,
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'sku',
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.number" />,
    accessorFn: row => (
      <AsyncPreviewTooltip type="ORDER" column="ref" id={row.order.id}>
        <Link to={`/dashboard/orders/${row.order.id}/details`} target="_blank">
          {row.order.client_name ? row.order.client_name + ' - ' : null}
          {row.order.order_ref}
        </Link>
      </AsyncPreviewTooltip>
    ),
    id: 'order_ref',
    enableSorting: true,
    cell: r => r.getValue(),
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="productsPage.name" />
      </span>
    ),
    accessorFn: row => (
      <Link
        to={`/dashboard/products/${row.product_variant.product_id}/variants/${row.product_variant?.id}/general`}
        target="_blank"
      >
        <EmptyCell
          cell={<b>{row.product_variant.name?.toLocaleUpperCase()}</b>}
          fallback="-"
        />
      </Link>
    ),
    cell: r => r.getValue(),
    id: 'name',
    enableSorting: true,
    meta: {size: '16vw'},
  },
  {
    header: r => <Trans i18nKey="stockProductsPage.location" />,
    enableSorting: true,
    accessorFn: row => <EmptyCell cell={row.stock_location_name} />,
    id: 'stock_location',
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="customerOrderPage.status" />,
    enableSorting: true,
    id: 'status',
    accessorFn: row => (
      <StatusBadge
        status={row.status}
        text={CUSTOM_SERIAL_NUMBER_STATUSES[row.status].text}
        color={CUSTOM_SERIAL_NUMBER_STATUSES[row.status].color}
      />
    ),
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockLocationPage.action" />,
    enableSorting: false,
    accessorFn: row => <StockProductActions stockProduct={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
