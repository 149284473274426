import React from 'react';
import {Fragment} from 'react';
import {PerPageOptions} from '../../listing/constants';
import messages from '../../listing/list/messages';
import selectEntityPaginationLimit from '../slice/selectors/select-entity-pagination-limit';
import {PaginationMeta} from '../slice/types';
import Button from 'components/button';
import TagSelect from 'components/tag-select/tag-select';
import Input from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CardBody, Row, Col} from 'reactstrap';
import {FormGroup} from 'reactstrap';
import {ResourceName} from 'types/resource-name';

interface HeaderProps {
  filters: any;
  name: ResourceName;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
  hideSearch?: boolean;
  meta: PaginationMeta;
}

const Header = (props: HeaderProps) => {
  const {t} = useTranslation();
  // these are required props
  // for the datatable
  const {filters, onChangeFilters, meta, hideSearch} = props;

  const handleChangeFilters = async (key: string, value: any) => {
    if (key === 'keyword') {
      await setTimeout(() => {
        return onChangeFilters(key, value);
      }, 750);
    }

    onChangeFilters(key, value);
  };

  const handleClearFilters = () => {
    if (typeof props.onClearFilters === 'function') {
      props.onClearFilters();
    }
  };

  const limit = useSelector(selectEntityPaginationLimit(props.name));
  return (
    <Fragment>
      {!hideSearch ? (
        <CardBody className="pb-0">
          <Row className="mt-1">
            <Col lg="4"></Col>
            <Col lg="4"></Col>

            <Col lg="4" className="justify-content d-flex mt-3">
              <FormGroup className="w-100 me-2 ">
                <Input
                  id="search-input"
                  placeholder={t(...messages.search())}
                  value={filters ? filters['filter[keyword]'] : ''}
                  onChange={x => handleChangeFilters('filter[keyword]', x)}
                />
              </FormGroup>
              <div>
                <Button
                  onClick={handleClearFilters}
                  className="btn btn-sm btn-light text-info "
                >
                  <p className="my-1 mx-1 font-size-14  text-dark">
                    {t('common.reset')}
                  </p>
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      ) : null}
    </Fragment>
  );
};

export default Header;
