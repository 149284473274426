import {selectInvoice} from '../../store/endpoints/show-invoice';
import {Invoice} from '../../types/invoice';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

export const useCurrentInvoice = () => {
  const {id} = useParams();

  const {data} = useSelector(selectInvoice({id}));

  return data?.data as Invoice;
};
