import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  cancel: () => __(translations.usersEditPage.cancel, 'Cancel'),
  dashboard: () => __(translations.usersEditPage.dashboard, 'Dashboard'),
  email: () => __(translations.usersEditPage.email, 'E-mail'),
  password: () => __(translations.usersEditPage.password, 'Password'),
  passwordConfirmation: () => __(translations.usersEditPage.passwordConfirmation, 'Confirm password'),
  role: () => __(translations.usersEditPage.role, 'Role'),
  submit: () => __(translations.usersEditPage.submit, 'Submit'),
  title: () => __(translations.usersEditPage.title, 'Create User'),
  firstName: () => __(translations.usersEditPage.firstName, 'First name'),
  lastName: () => __(translations.usersEditPage.lastName, 'Last name'),
  users: () => __(translations.usersEditPage.users, 'Users'),
  editTitle: () => __(translations.usersEditPage.editTitle, 'Edit user'),
  editPageTitle: () => __(translations.usersEditPage.editPageTitle, 'Edit user'),
  edit: () => __(translations.usersEditPage.edit, 'Edit'),
  security: () => __(translations.usersEditPage.security, 'Security'),
  personalInformation: () => __(translations.usersEditPage.personalInformation, 'personal information'),
  successMessage: () => __(translations.usersEditPage.successMessage, 'User updated successfully'),
  passwordChanged: () => __(translations.usersEditPage.passwordChanged, 'Password changed successfully'),
  phone: () => __(translations.usersEditPage.phone, 'Phone'),
};
