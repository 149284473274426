import React from 'react';
import FamilyActions from './family-actions';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';

import {Trans} from 'react-i18next';
import {DirectoryFamily} from 'types/directory-family';

const columns: ColumnDef<DirectoryFamily>[] = [
  {
    header: r => <Trans i18nKey="directoryFamiliesPage.name" />,
    accessorFn: row => <EmptyCell cell={row.name} />,
    id: 'name',
    enableSorting: true,
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
  },
  /* {
    header: r => <Trans i18nKey="directoryFamiliesPage.createdAt" />,
    enableSorting: false,
    accessorFn: row =>
      <FormattedDate value={row.created_at} format="datetime" /> ?? '-',
    id: 'created_at',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
  }, */
  {
    header: r => <Trans i18nKey="directoryFamiliesPage.category" />,
    enableSorting: false,
    accessorFn: row => (
      <EmptyCell cell={row.categories.map(x => x.name).join(',')} />
    ),
    id: 'categories',
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="directoryFamiliesPage.action" />,
    enableSorting: false,
    accessorFn: row => <FamilyActions family={row} />,
    cell: r => r.getValue(),
    meta: {costumClass: 'font-size-14'},

    id: 'actions',
  },
];

export default columns;
