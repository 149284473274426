import React from 'react';
import messages from './messages';
import {useStockOrdersForm} from './use-stock-order-form';
import Icon from '@ailibs/feather-react-ts';
import Breadcrumb from 'components/Common/Breadcrumb';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import {Card, CardBody, Col, Row} from 'reactstrap';
import TextInput from 'components/text-input';

const CreateStockOrderPage = () => {
  const {t} = useTranslation();
  const {params, validationErrors, isLoading, errorMessage, submit, setParams} =
    useStockOrdersForm();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {
              title: t(...messages.stockOrders()),
              path: '/dashboard/stock-orders',
            },
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <div>
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col md={12}>
                  <TextInput
                    disabled
                    label={t(...messages.client())}
                    className="mb-2 bg-gray-light "
                    value={t(...messages.imh())}
                    onChange={() => {}}
                  />
                </Col>
                {/* <Col md={12}>
                  <TextInput
                    disabled
                    label={t(...messages.type())}
                    className="mb-2 bg-light "
                    value={t(...messages.stockOrderType())}
                    onChange={() => {}}
                  />
                </Col> */}

                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/commercial-types?supplier_order"
                    className="my-2"
                    defaultValue
                    label={t(...messages.typeSupplier())}
                    optionLabel=""
                    errors={validationErrors.supplier_commercial_type_id}
                    value={params.supplier_type}
                    onChange={supplier_type =>
                      setParams({...params, supplier_type})
                    }
                  />
                </Col>

                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/languages"
                    className="my-2 text-capitalize"
                    defaultValue
                    label={t(...messages.language())}
                    getOptionLabel={(l: any) => l.label?.toLocaleLowerCase()}
                    errors={validationErrors.language}
                    value={params.language}
                    getOptionValue={(l: any) => l.label}
                    onChange={language => {
                      setParams({...params, language});
                    }}
                  />
                </Col>
                <Col md={12} className="my-2">
                  <DateInput
                    label={t(...messages.invoiceDate())}
                    value={params.order_date}
                    onChange={order_date => setParams({...params, order_date})}
                    errors={validationErrors.order_date}
                  />
                </Col>
                <Col md={12} className="mb-1">
                  <DateInput
                    label={t(...messages.dueDate())}
                    value={params.due_date}
                    onChange={due_date => setParams({...params, due_date})}
                    errors={validationErrors.due_date}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  <AppBarActions>
                    <Button
                      isLoading={isLoading}
                      onClick={submit}
                      color="important"
                    >
                      <Icon name="check" size={15} className="me-1" />
                      {t('common.saveChanges')}
                    </Button>
                  </AppBarActions>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default CreateStockOrderPage;
