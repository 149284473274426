import messages from './messages';
import {RouteItem} from './sidebar-item';

export const sidebarRoutes: RouteItem[] = [
  {
    path: '/dashboard/directories',
    name: messages.directory,
    icon: 'phone',
    subject: 'directory',
  },
  {
    path: '/dashboard/sales',
    name: messages.commercial,
    icon: 'credit-card',
    subject: [
      'dcv',
      'dcz',
      'ccz',
      'ccv',
      'cch',
      'ccr',
      'ccc',
      'h',
      'z',
      'c',
      'r',
      'v',
      'product-prices',
      'stats',
    ],
    children: [
      {
        path: '/dashboard/quotations',
        name: messages.quotations,
        // SET THE RIGHT PERMISSION HERE
        subject: ['dcv', 'dcz'],
      },
      {
        path: '/dashboard/customer-orders',
        name: messages.customerOrder,
        // SET THE RIGHT PERMISSION HERE
        subject: ['ccz', 'ccv', 'cch', 'ccr', 'ccc'],
      },
      {
        path: '/dashboard/invoices',
        name: messages.invoices,
        // SET THE RIGHT PERMISSION HERE
        subject: ['h', 'z', 'c', 'r', 'v'],
      },
      {
        path: '/dashboard/pricing',
        name: messages.pricing,
        // SET THE RIGHT PERMISSION HERE
        subject: 'product-prices',
      },
      /*  {
        path: '/dashboard/statistic',
        name: messages.statistic,
      }, */
    ],
  },
  {
    path: '/production',
    name: messages.production,
    icon: 'archive',
    subject: [
      'products',
      'recap-stock',
      'production-monitoring',
      'ccs',
      'cfz',
      'cfv',
    ],
    children: [
      {
        path: '/dashboard/products',
        name: messages.product,
        subject: 'products',
      },
      {
        path: '/dashboard/products-stock/quantity',
        name: messages.inventory,
        // SET THE RIGHT PERMISSION HERE
        subject: 'recap-stock',
      },
      {
        path: '/dashboard/stock-orders',
        name: messages.stockOrder,
        // SET THE RIGHT PERMISSION HERE
        subject: 'ccs',
      },
      {
        path: '/dashboard/supplier-orders',
        name: messages.supplierOrder,
        // SET THE RIGHT PERMISSION HERE
        subject: ['cfv', 'cfz'],
      },
      {
        path: '/dashboard/monitoring/listing',
        name: messages.productionMonitoring,
        subject: 'production-monitoring',
      },
    ],
  },
  {
    path: '/#',
    name: messages.stock,
    icon: 'box',
    subject: 'stock',
    children: [
      {
        path: '/dashboard/stock-products',
        name: messages.inStock,
        subject: 'stock',
      },

      {
        path: '/dashboard/stock-products-in-stock',
        name: messages.stockProduct,
        subject: 'stock',
      },
      {
        path: '/dashboard/stock-products-in-preparation',
        name: messages.productInPreparation,
        subject: 'stock',
      },
      {
        path: '/dashboard/stock-products-dispute-sav',
        name: messages.disputesAndAfterSalesService,
        subject: 'stock',
      },

      {
        path: '/dashboard/stock-locations',
        name: messages.Location,
        subject: 'stock',
      },
    ],
  },

  {
    path: '/accounting',
    name: messages.accounting,
    icon: 'briefcase',
    // SET THE RIGHT PERMISSION HEREsub
    subject: [
      'accounting-credit-overpayment',
      'accounting-payments',
      'accounting-others',
    ],
    children: [
      {
        path: '/dashboard/credits/list',
        name: messages.credits,
        // SET THE RIGHT PERMISSION HERE
        subject: 'accounting-credit-overpayment',
      },
      {
        path: '/dashboard/payments',
        name: messages.payment,
        // SET THE RIGHT PERMISSION HERE
        subject: 'accounting-payments',
      },
      {
        path: '/dashboard/overpayments',
        name: messages.overpayment,
        // SET THE RIGHT PERMISSION HERE
        subject: 'accounting-credit-overpayment',
      },
      {
        path: '/dashboard/product-ecotaxes',
        name: messages.ecoTax,
        // SET THE RIGHT PERMISSION HERE
        subject: 'accounting-others',
      },
    ],
  },
  {
    path: '/dashboard/studio',
    name: messages.studio,
    icon: 'columns',
    subject: ['studio-projects', 'studio-petits-projects'],
    children: [
      {
        path: '/dashboard/projects',
        name: messages.projects,
        subject: ['studio-projects', 'studio-petits-projects'],
      },
    ],
  },
  {
    path: '/#admin',
    name: messages.admin,
    icon: 'user-check',
    subject: 'any',
    children: [
      /*    {
        path: '/dashboard/notifications',
        name: messages.notifications,
        // SET THE RIGHT PERMISSION HERE
        subject: 'any',
      }, */
      {
        path: '/dashboard/users',
        name: messages.users,
        subject: 'administration-user',
      },
      {
        path: '/dashboard/expense',
        name: messages.expenses,
        // SET THE RIGHT PERMISSION HERE
        subject: 'any',
      },
    ],
  },
  {
    path: '/dashboard/exports',
    name: messages.exports,
    icon: 'download-cloud',
    // SET THE RIGHT PERMISSION HERE
    subject: 'any',
    children: [
      {
        path: '/dashboard/exports',
        // SET THE RIGHT PERMISSION HERE
        subject: 'any',
        name: messages.exportsList,
      },
    ],
  },

  {
    path: '/dashboard/settings',
    name: messages.settings,
    icon: 'settings',
    subject: ['settings-general', 'settings-colors', 'settings-matter'],
    children: [
      {
        path: '/dashboard/settings/general',
        name: messages.general,
        subject: 'settings-general',
      },
      {
        path: '/dashboard/colors',
        name: messages.colors,
        subject: 'settings-colors',
      },
      {
        path: '/dashboard/materials',
        name: messages.materials,
        subject: 'settings-matter',
      },
    ],
  },
  {
    path: 'https://oldair.indiamahdavi.com',
    name: messages.oldErp,
    icon: 'database',
    subject: 'any',
    newTab: true,
  },
];
