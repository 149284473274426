import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import useInteractionForm from './use-interaction-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Option} from 'types/option';

const InteractionForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useInteractionForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div className="mb-3">
          <AsyncItemPicker
            endPoint="data/interaction-types"
            onChange={interaction_type =>
              setParams({...params, interaction_type})
            }
            getOptionLabel={(x: Option) => x.label}
            getOptionValue={(x: Option) => x.value}
            value={params.interaction_type}
            onKeyDown={handleKeyboardEventDown}
            label={t(...messages.type())}
            errors={validationErrors.interaction_type}
          />
        </div>
        <div className="mb-3">
          <TextInput
            type="textarea"
            rows={5}
            onChange={comment => setParams({...params, comment})}
            value={params.comment}
            label={t(...messages.comment())}
            errors={validationErrors.comment}
          />
        </div>
        <div>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        </div>
        <div className="mt-3 d-grid"></div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default InteractionForm;
