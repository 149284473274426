import React from 'react';
import DirectoryDetailsRouter from '../directory-details-router';
import SummarySection from '../summary-section';
import TabPane from '../tab-pane';
import ActionsTab from '../tabs/actions';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Breadcrumbs from 'components/Common/Breadcrumb';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import LoadRunner from 'components/load-runner';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useShowDirectoryQuery} from 'store/directory/endpoints/show-directory';
import {Directory} from 'types/directory';

const DirectoryMasterDetails = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const {data, isFetching, error, isError, isSuccess, refetch} =
    useShowDirectoryQuery({id});

  const directory = data?.data as Directory;

  const serverError = parseValidationErrors(error);

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>
            {t('directoryPage.pageTitle') + ' - ' + directory?.display_name}
          </title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            showHeading={false}
            breadcrumbItems={[
              {
                title: t('common.dashboard'),
                path: '/dashboard',
              },
              {
                title: t('directoryPage.pageTitle'),
                path: '/dashboard/directories',
              },
              {
                title: t('directoryDetailsPage.pageTitle'),
                path: '#',
              },
            ]}
          />
          {/* Content */}

          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  {isSuccess && (
                    <SummarySection directory={directory} onReload={refetch} />
                  )}
                  <TabPane />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {isFetching && (
                    <div className="d-flex justify-content-center">
                      <LoadRunner
                        width={'100%'}
                        height={80}
                        viewBox="0 0 100% 60"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="20"
                        />
                        <rect
                          x="0"
                          y="40"
                          rx="5"
                          ry="5"
                          width="85%"
                          height="20"
                        />
                      </LoadRunner>
                    </div>
                  )}

                  {isError && (
                    <div>
                      <Alert color="danger">{serverError.message}</Alert>
                      <div className="d-flex justify-content-center py-3">
                        <Button onClick={refetch}>
                          <Icon name="refresh-cw" size={15} className="me-2" />
                          Reload
                        </Button>
                      </div>
                    </div>
                  )}
                  {/* DO NOT TOUCH THIS CONDITION, NO MATTER WHAT */}
                  {isSuccess && <DirectoryDetailsRouter />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {isSuccess && (
        <AppBarActions>
          <ActionsTab />
        </AppBarActions>
      )}
    </React.Fragment>
  );
};

export default DirectoryMasterDetails;
