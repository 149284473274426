/**
 * Item picker
 *
 */
import React from 'react';
import {FC} from 'react';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {Label} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import useInputId from 'utils/use-input-id';

interface Props {
  errors?: string[] | string;
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  zIndex?: number;
  options: any[];
  valueOnly?: boolean;
  // All other props
  [x: string]: any;
}

const ItemPickerMultiple: FC<Props> = props => {
  const {options, id: inputId, label, errors, zIndex, ...rest} = props;

  const appTheme = useSelector(selectAppTheme);

  const selectThemeColors = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--bs-primary)', // for selected option bg-color
        neutral10: 'var(--bs-border-color)', // for tags border-color
        neutral20: 'var(--bs-border-color)', // for input border-color
        neutral0: appTheme === 'dark' ? 'var(--bs-input-bg)' : '#fff', // used
        neutral80: 'var(--bs-light-text)', // text color
      },
    };
  };

  const id = useInputId(inputId);

  return (
    <div>
      <Label className="mb-0" htmlFor={id}>
        {label}
      </Label>
      <Select
        theme={selectThemeColors}
        className="react-select"
        inputId={id}
        classNamePrefix="select"
        styles={{
          // @ts-ignore
          menu: provided => ({...provided, zIndex: zIndex ?? 2001}),
          control: (baseStyles: any) => ({
            ...baseStyles,
            borderColor: errors?.length ? 'red' : baseStyles.borderColor,
          }),
        }}
        options={options}
        {...rest}
      />
      {errors ? <div className="invalid-feedback d-block">{errors}</div> : null}
    </div>
  );
};

export default ItemPickerMultiple;
