import {api} from 'services/api';
import {Color} from 'types/colors';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateColor = api.injectEndpoints({
  endpoints: builder => ({
    updateColor: builder.mutation<Response, UpdateColorRequest>({
      query: params => {

        return {
          method: 'PUT',
          url: `colors/${params.id}`,
          body: {
            name: params.name,
            code: params.code,
          },
        };
      },
    }),
  }),
});

export type UpdateColorRequest = {
  id?: ObjectId;
  name: {
    fr?: string;
    en?: string;
  };
  code?: string;
};

type Response = ResponseData<Color>;

export const {useUpdateColorMutation} = updateColor;
