import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateDirectoryCategoryMutation} from 'store/directory/endpoints/update-directory-category';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [submit, {isLoading, error}] = useUpdateDirectoryCategoryMutation();

  const serverError = parseValidationErrors(error);

  const category = useSelector(selectActiveModalParams);

  const fake = useSeeder();

  const [params, setParams] = useState({
    id: category.id,
    name: category.name,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(loadData({name: 'directory-categories', page: 1}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
