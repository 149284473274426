import React, {FC, useEffect, useState} from 'react';
import {COLUMN, TYPE} from './constant';
import messages from 'pages/commercial/quotations/messages';
import {useTranslation} from 'react-i18next';
import {Spinner, UncontrolledTooltip} from 'reactstrap';
import {useLazyShowAsyncPreviewTooltipQuery} from 'store/global/endpoints/show-async-preview-tooltip';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';

export interface Props {
  id: ObjectId | undefined;
  type: TYPE;
  column: COLUMN;
  children: React.ReactNode;
}

const AsyncPreviewTooltip: FC<Props> = props => {
  const {id, type, column, children} = props;
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [fetchAsyncPreviewTooltip, {data, isLoading}] =
    useLazyShowAsyncPreviewTooltipQuery();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isOpen) {
        fetchAsyncPreviewTooltip({id, type, column});
      }
    }, 750);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  const tooltips = data?.data?.contents as Option[];
  const onToggle = () => {
    setIsOpen(x => !x);
  };

  const dataUnavailable = isLoading || !tooltips;

  return (
    <div id={`${type}-${column}-code-${id}`}>
      <UncontrolledTooltip
        placement="top"
        isOpen={isOpen}
        target={`${type}-${column}-code-${id}`}
        toggle={onToggle}
      >
        {dataUnavailable && <Spinner size="sm" />}
        {tooltips?.length === 0 && (
          <div>{t(...messages.nullQuoteTooltip())}</div>
        )}
        {tooltips?.map(item => (
          <div key={item.id}>{item?.name}</div>
        ))}
      </UncontrolledTooltip>
      {children}
    </div>
  );
};

export default AsyncPreviewTooltip;
