import React from 'react';
import {Orderlog} from '../../types/order-log';
import FormattedDate from 'components/formatted-date';

import {Trans} from 'react-i18next';
import {ColumnDef} from '@tanstack/table-core';

const columns: ColumnDef<Orderlog>[] = [
  {
    header: r => <Trans i18nKey="orderLogsTab.logDate" />,
    accessorFn: row => (
      <FormattedDate value={row?.created_at} format="datetime" />
    ),
    id: 'date',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="orderLogsTab.user" />,
    accessorFn: row => (row.causer ? row.causer?.full_name : '-'),
    id: 'name',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="orderLogsTab.action" />,
    accessorFn: row => row.description,
    id: 'action',
    enableSorting: false,
    cell: r => r.getValue(),
  },
];

export default columns;
