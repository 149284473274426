import React, {FC, useContext, useEffect} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import AsyncItemPicker from 'components/async-item-picker';
import TextInput from 'components/text-input';
import SituationPicker from '../../components/situation-picker/situation-picker';
import {useTranslation} from 'react-i18next';
import {Situation} from 'types/situation';
import {SituationPrices} from '../../store/endpoints/show-situation-prices';
import otherParamsContext from './other-params-context';
import YesNoPicker from '../../components/yes-no-picker/yes-no-picker';

interface Props {
  item: CommercialProduct;
  index: number;
}

const ItemOtherInfoForm: FC<Props> = props => {
  const {item, index} = props;

  const {t} = useTranslation();

  const {params, setParams} = useContext(otherParamsContext);

  const onChangeSituation = (
    situation?: Situation,
    prices?: SituationPrices
  ) => {
    setParams({
      ...params,
      situation,
      unit_price_ht: prices?.selling_price_ht,
      discount: prices?.discount,
    });
  };

  useEffect(() => {
    if (item.commercial_product_type === 'EXTERNAL_PRODUCT')
      return setParams({
        ...params,
        handel_stock: false,
      });

    setParams({
      ...params,
      handel_stock: item.variant?.default_handel_stock,
    });
  }, []);

  return (
    <div>
      {item.commercial_product_type === 'PRODUCT' && (
        <SituationPicker
          onChangeSituation={onChangeSituation}
          item={item}
          index={index}
        />
      )}

      <AsyncItemPicker
        endPoint="data/vats"
        className="mb-2"
        label={t('commercialDocumentComponents.vat')}
        isClearable={false}
        value={params.vat}
        onChange={vat => setParams({...params, vat})}
      />

      <TextInput
        label={t('commercialDocumentComponents.publicComment')}
        type="textarea"
        className="mb-2"
        value={params.public_comment}
        onChange={public_comment => setParams({...params, public_comment})}
      />

      <TextInput
        label={t('commercialDocumentComponents.comment')}
        type="textarea"
        className="mb-2"
        value={params.private_comment}
        onChange={private_comment => setParams({...params, private_comment})}
      />

      <div className="mb-2">
        <YesNoPicker
          label={t('commercialDocumentComponents.isCustomProduct')}
          value={params.is_custom_made}
          onChange={is_custom_product =>
            setParams({...params, is_custom_made: is_custom_product})
          }
        />
      </div>
      <div className="mb-2">
        <YesNoPicker
          label={t('commercialDocumentComponents.managesStock')}
          value={params.handel_stock}
          onChange={handel_stock => setParams({...params, handel_stock})}
        />
      </div>
    </div>
  );
};

export default ItemOtherInfoForm;
