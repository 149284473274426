import {Invoice} from '../../types/invoice';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedInvoiceFromResource} from './show-invoice';

const validateInvoice = api.injectEndpoints({
  endpoints: builder => ({
    validateInvoice: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'PUT',
          url: `/invoices/${params.id}/toggle-lock`,
          body: {},
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const i = (await queryFulfilled).data?.data;
          dispatch(updateCachedInvoiceFromResource(i));
        } catch {}
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Invoice>;

export const {useValidateInvoiceMutation} = validateInvoice;
