import React, {FC, Fragment} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import {CalendarSupplier} from 'features/stock/types/calendar-supplier';
import {useSelector} from 'react-redux';
import selectIsSupplierSelected from '../../store/selectors/select-is-supplier-selected';
import {useDispatch} from 'react-redux';
import {actions} from '../../store';

interface Props {
  supplier: CalendarSupplier;
}

const SupplierItem: FC<Props> = props => {
  const {supplier} = props;

  const isSelected = useSelector(selectIsSupplierSelected(supplier.id));

  const dispatch = useDispatch();

  const handleToggleSelection = () => {
    dispatch(actions.toggleSupplier(supplier));
  };

  return (
    <Fragment>
      <a
        onClick={handleToggleSelection}
        role="button"
        className={clsx({
          'list-group-item list-group-item-action': true,
          'bg-gray-light': isSelected,
        })}
      >
        <Icon
          name="airplay"
          strokeWidth={55}
          size={12}
          color={supplier.color}
          className="rounded-sm rounded"
        />
        <span className="m-0 ms-2 me-2">{supplier.name}</span>
      </a>
    </Fragment>
  );
};

export default SupplierItem;
