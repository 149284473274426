import Stocklocation from 'features/stock/types/stock-location';
import {api} from 'services/api';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const storeLocation = api.injectEndpoints({
  endpoints: builder => ({
    storeLocation: builder.mutation<Response, StoreLocationRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `stock-locations`,
          body: {
            name: params.name,
            stock_location_category_id: params.stock_location_category?.id,
            is_locked: params.is_locked,
          },
        };
      },
    }),
  }),
});

export type StoreLocationRequest = {
  name: string;
  stock_location_category?: Option;
  is_locked: boolean;
};

type Response = ResponseData<Stocklocation>;

export const {useStoreLocationMutation} = storeLocation;
