import React, {FC, Fragment} from 'react';
import messages from '../../../production/containers/action-product/messages';
import useUpdateDirectoryCategoryForm from './use-update-directory-category-form';
import Icon from '@ailibs/feather-react-ts';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation, Trans} from 'react-i18next';
import {ModalHeader, ModalBody, Alert, ModalFooter} from 'reactstrap';
import {DirectoryCategory} from 'types/directory-category';

interface Props {
  directoryCategory?: DirectoryCategory;
  onDismiss: () => void;
}

const DirectoryCaterogyForm: FC<Props> = props => {
  const {directoryCategory} = props;

  const {onDismiss} = props;

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useUpdateDirectoryCategoryForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <TextInput
            onKeyDown={handleKeyboardEventDown}
            onChange={name => setParams({...params, name})}
            value={params.name}
            label="Nom"
            errors={validationErrors.name}
          />

          <div className="row mb-4">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default DirectoryCaterogyForm;
