import React from 'react';
import GridControls from './grid-controls/grid-controls';
import messages from './messages';
import PricingGrid from './pricing-grid/pricing-grid';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

const PricingPage = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <div>
          <PricingGrid />
        </div>
      </Container>
    </div>
  );
};

export default PricingPage;
