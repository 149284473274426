import {FacebookLoginResult, FacebookUser, FbR} from './types';
import {fetchRequest} from 'services/fetch-request';

/**
 * Facebook sign in service
 *
 */
const facebookSignInService = {
  signIn: async (response: FbR): Promise<FacebookLoginResult> => {
    const provider_token = response?.accessToken;

    if (!provider_token) {
      return {isSuccessful: false, message: 'no-provider-token'};
    }

    const requestUrl = composeGraphUrl(provider_token);

    const data = (await fetchRequest(requestUrl, 'GET')) as FacebookUser & {
      ok: boolean;
    };

    if (data.ok) {
      return {isSuccessful: true, user: data};
    }
    return {isSuccessful: false, message: 'unknown-error'};
  },
};

/**
 * Composes the graph request url
 *
 */
const composeGraphUrl = (token: string) => {
  return (
    'https://graph.facebook.com/v11.0/me?fields=' +
    'email,first_name,last_name' +
    `&access_token=${token}`
  );
};

export default facebookSignInService;
