import React, {FC} from 'react';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {Button, CardHeader, CardSubtitle, Col, Row} from 'reactstrap';
import ContactsFilters from './filters';
import messages from '../messages';
import {IContactForm} from '../use-contacts-form';
import ItemPicker from 'components/item-picker';
import DirectoryPicker from 'features/studio/projects/components/contact-picker';
import Can from 'features/hylian-shield/can';

const HeaderSection: FC<IContactForm> = ({
  input,
  options,
  regroupedContacts,
  subject,
  handleInputChange,
  handleAddInput,
}) => {
  const {t} = useTranslation();

  return (
    <CardHeader className="">
      <CardSubtitle tag="h5" className="my-2">
        {t(...messages.contacts())}
      </CardSubtitle>
      <ContactsFilters regroupedContacts={regroupedContacts} />
      <Can action="edit" subject={subject}>
        <CardSubtitle tag="h5" className="my-4">
          {t(...messages.addContact())}
        </CardSubtitle>
        <Row className="mb-2">
          <Col md={7}>
            <DirectoryPicker
              label={t(...messages.contact())}
              value={input.contact}
              onChange={val => handleInputChange('contact', val)}
            />
          </Col>
          <Col md={5} className="d-flex gap-3">
            <div className="flex-grow-1">
              <ItemPicker
                options={options.types}
                value={input.type}
                label={t(...messages.category())}
                onChange={val => handleInputChange('type', val)}
              />
            </div>
            <Button
              color="important"
              style={{display: 'inline-block', alignSelf: 'flex-end'}}
              disabled={!input.contact || !input.type}
              onClick={handleAddInput}
            >
              <Plus size={18} /> {t(...messages.addToList())}
            </Button>
          </Col>
        </Row>
      </Can>
    </CardHeader>
  );
};

type Props = IContactForm;

export default HeaderSection;
