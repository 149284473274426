import React, {FC, Fragment, useMemo} from 'react';
import messages from './messages';
import {FilterMap} from 'features/listing/list/slice/types';
import {useTranslation} from 'react-i18next';
import {CardBody, Row, Col, FormGroup, Label, Collapse} from 'reactstrap';
import AsyncItemPicker from 'components/async-item-picker';
import {StockProductsFilters} from './types';
import {useLocalStorage} from 'usehooks-ts';
import ItemPicker from 'components/item-picker';
import {Option} from 'types/option';

const STOCK_STATUS = [
  {id: 'OPENED', name: 'serialNumberTab.opened'},
  {id: 'PENDING', name: 'serialNumberTab.pending'},
];

interface FilterProps {
  filters: FilterMap<StockProductsFilters>;
  onChangeFilters: (key: string, value: any) => void;
}

const FiltersSection: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters} = props;
  const [v, tt] = useLocalStorage('is-advanced-search-open', false);
  const statues = useMemo(
    () => STOCK_STATUS.map(x => ({name: t(x.name), id: x.id})),
    []
  );

  return (
    <Fragment>
      <CardBody className="py-0">
        {/* First row */}
        <Collapse isOpen={v}>
          <Row className="mb-3">
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/commercial/product-variants"
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.product())}
                  value={filters['filter[product_variant_id]']}
                  onChange={o =>
                    onChangeFilters('filter[product_variant_id]', o)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/stock-locations"
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.stockLocation())}
                  value={filters['filter[stock_location_id]']}
                  onChange={o =>
                    onChangeFilters('filter[stock_location_id]', o)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <ItemPicker
                  options={statues}
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.status())}
                  value={filters['filter[status]'] as Option}
                  onChange={o => onChangeFilters('filter[status]', o)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Fragment>
  );
};

export default FiltersSection;
