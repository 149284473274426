import React, {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CardBody, Col, FormGroup, Row} from 'reactstrap';
import {useLazyGetEcotaxesExportQuery} from 'pages/accounting/store/endpoints/export-ecotaxes';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import Button from 'components/button';
import DateRangeInput from 'components/date-range-input';

interface FilterProps {
  filters: FilterMap<'filter[invoice_date]'>;
  onChangeFilters: (key: string, value: any) => void;
}

const ProductEcoTaxControls: FC<FilterProps> = props => {
  const {filters, onChangeFilters} = props;
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [onExport, {isLoading}] = useLazyGetEcotaxesExportQuery();

  const onClick = async () => {
    const result = await onExport(filters);

    if ('data' in result) {
      dispatch(toast('success', result.data?.message));
    }
  };

  return (
    <Row>
      <Col>
        <CardBody>
          <Col lg="3" md="3">
            <FormGroup>
              <DateRangeInput
                label={t(...messages.date())}
                value={filters['filter[invoice_date]'] as string}
                onChange={newDate => {
                  onChangeFilters('filter[invoice_date]', newDate);
                }}
              />
            </FormGroup>
          </Col>
          <AppBarActions>
            <div className="d-flex">
              <div>
                <Link to="/dashboard/ecotaxes" className="btn btn-light me-2">
                  <Icon name="list" size={15} className="me-1" />
                  {t(...messages.ecoTax())}
                </Link>
              </div>
              <div>
                <Button onClick={onClick} isLoading={isLoading} color="light">
                  {t(...messages.export())}
                </Button>
              </div>
            </div>
          </AppBarActions>
        </CardBody>
      </Col>
    </Row>
  );
};

export default ProductEcoTaxControls;
