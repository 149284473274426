import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderTransferModal.title, 'Transfer'),
  transfer: () => __(translations.orderTransferModal.transfer, 'Transfer'),
  areYouSure: () => __(translations.orderTransferModal.areYouSure, 'Are you sure?'),
  label: () => __(translations.orderTransferModal.label, 'Label'),
  quantity: () => __(translations.orderTransferModal.quantity, 'Quantity'),
  sku: () => __(translations.orderTransferModal.sku, 'SKU'),
  stock: () => __(translations.orderTransferModal.stock, 'Stock location'),
  supplier: () => __(translations.orderTransferModal.supplier, 'Supplier'),
  availability: () => __(translations.orderTransferModal.availability, 'Availability'),
  generateLabel: () => __(translations.orderTransferModal.generateLabel, 'Generate label'),
};
