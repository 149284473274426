import React, {FC} from 'react';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Col, Row} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';
import {UpdateInvoiceRequest} from 'features/commercial/invoices/store/endpoints/update-invoice';

interface Props {
  params: UpdateInvoiceRequest;
  validationErrors: ValidationErrors;
  onChangeParams: (p: any) => void;
  is_locked?: boolean;
  isCurrencyVisible?: boolean;
  isSubTypeVisible?: boolean;
}

const CreditGeneralInformation: FC<Props> = props => {
  const {t} = useTranslation();

  const {
    validationErrors,
    params,
    onChangeParams,
    is_locked,
    isCurrencyVisible,
    isSubTypeVisible,
  } = props;
  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <Row>
        <Col md={12}>
          <TextInput
            label={t(...messages.client())}
            disabled
            data-testid="product-name-input"
            value={params.client_display_name}
            onChange={client_display_name => {
              onChangeParams({
                ...params,
                client_display_name,
              });
            }}
          />
        </Col>
        <Col md={12} className="my-2">
          <DateInput
            label={t(...messages.creditBillingDate())}
            value={params.billing_date}
            disabled={is_locked}
            onChange={billing_date => onChangeParams({...params, billing_date})}
            errors={validationErrors.billing_date}
          />
        </Col>
        <Col md={12} className="my-2">
          <DateInput
            label={t(...messages.creditDueDate())}
            value={params.due_date}
            disabled={is_locked}
            onChange={due_date => onChangeParams({...params, due_date})}
            errors={validationErrors.due_date}
          />
        </Col>

        <Col md={12} className="my-2">
          <AsyncItemPicker
            endPoint="data/document-categories"
            label={t(...messages.creditCategory())}
            optionLabel=""
            disabled={is_locked}
            errors={validationErrors.document_category}
            value={params.document_category}
            onChange={document_category =>
              onChangeParams({...params, document_category})
            }
          />
        </Col>
        {isSubTypeVisible ? (
          <Col md={12} className="my-2">
            <AsyncItemPicker
              endPoint="data/project-fee-types"
              label={t('invoiceGeneralInformation.projectFeesType')}
              optionLabel=""
              disabled={is_locked}
              getOptionLabel={(l: any) => l.label}
              getOptionValue={(l: any) => l.value}
              errors={validationErrors.project_fee_type}
              value={params.projectFeesType}
              onChange={projectFeesType =>
                onChangeParams({...params, projectFeesType})
              }
            />
          </Col>
        ) : null}
        <Col md={12} className="my-2">
          <AsyncItemPicker
            endPoint="data/projects"
            label={t(...messages.creditProject())}
            optionLabel=""
            errors={validationErrors.project_id}
            disabled={is_locked}
            value={params.project}
            onChange={project => onChangeParams({...params, project})}
          />
        </Col>
        {isSubTypeVisible ? (
          <Col md={12} className="my-2">
            <TextInput
              label={t('invoiceGeneralInformation.designation')}
              data-testid="product-name-input"
              disabled={is_locked}
              errors={validationErrors.designation}
              value={params.designation}
              onChange={designation => {
                onChangeParams({
                  ...params,
                  designation,
                });
              }}
            />
          </Col>
        ) : null}
        <Col md={12} className="my-2">
          <AsyncItemPicker
            endPoint="data/document-origins"
            disabled={is_locked}
            label={t(...messages.creditOrigin())}
            optionLabel=""
            errors={validationErrors.origin}
            value={params.origin}
            onChange={origin => onChangeParams({...params, origin})}
          />
        </Col>
        <Col md={12} className="my-2">
          <TextInput
            label={t(...messages.clientReference())}
            disabled={is_locked}
            data-testid="product-name-input"
            value={params.client_reference}
            errors={validationErrors.ref_client}
            onChange={client_reference => {
              onChangeParams({
                ...params,
                client_reference,
              });
            }}
          />
        </Col>
        <Col md={12} className="my-2">
          <AsyncItemPicker
            endPoint="data/languages"
            disabled={is_locked}
            className="text-capitalize"
            label={t(...messages.creditLanguage())}
            getOptionLabel={(l: any) => l.label}
            errors={validationErrors.language}
            value={params.language}
            getOptionValue={(l: any) => l.label}
            onChange={language => {
              onChangeParams({...params, language});
            }}
          />
        </Col>
        {isCurrencyVisible ? (
          <>
            {' '}
            <Col md={12} className="my-2">
              <AsyncItemPicker
                endPoint="data/currencies"
                disabled={is_locked}
                label={t(...messages.creditCurrency())}
                getOptionLabel={(l: any) => l.label}
                errors={validationErrors.currency}
                value={params.currency}
                getOptionValue={(l: any) => l.label}
                onChange={currency => {
                  onChangeParams({...params, currency});
                }}
              />
            </Col>
            <Col md={12} className="my-2">
              <TextInput
                label={t(...messages.creditCurrencyConversion())}
                value={params.currency_conversion}
                type="number"
                errors={validationErrors.currency_conversion}
                onChange={currency_conversion => {
                  onChangeParams({
                    ...params,
                    currency_conversion,
                  });
                }}
              />{' '}
            </Col>
          </>
        ) : null}

        <Col md={12} className="my-2">
          <TextInput
            type="textarea"
            label={t(...messages.privateComment())}
            errors={validationErrors.private_comment}
            disabled={is_locked}
            value={params.private_comment}
            onChange={private_comment => {
              onChangeParams({
                ...params,
                private_comment,
              });
            }}
          />
        </Col>

        <Col md={12} className="my-2">
          <input
            className="form-check-input my-1"
            disabled={is_locked}
            type="checkbox"
            id="is_after_sale_service"
            onChange={() =>
              onChangeParams({
                ...params,
                is_after_sale_service: !params.is_after_sale_service,
              })
            }
            checked={params.is_after_sale_service}
          />
          <label
            className="form-check-label my-1 mx-2"
            htmlFor="is_after_sale_service"
          >
            {t(...messages.afterSalesServiceInvoice())}
          </label>
        </Col>
      </Row>
    </div>
  );
};

export default CreditGeneralInformation;
