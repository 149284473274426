import React, {FC, Fragment} from 'react';
import messages from './messages';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {FilterMap} from 'features/listing/list/slice/types';
import {MODAL} from 'features/modal-notch/constants';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {CardBody, Row, Col, FormGroup, Label} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {useNavigate} from 'react-router';
import AsyncItemPicker from 'components/async-item-picker';
import {LocationFilters} from './types';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap<LocationFilters>;
  onChangeFilters: (key: string, value: any) => void;
}

const FiltersSection: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters} = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreate = () => {
    dispatch(showModal(MODAL.CREATE_STOCK_LOCATION));
  };

  return (
    <Fragment>
      <CardBody className="py-0">
        <Row className="mt-1">
          <Col lg="3" md="3">
            <FormGroup>
              <AsyncItemPicker
                endPoint="data/stock-location-categories"
                className="mb-1"
                label={t(...messages.category())}
                value={filters['filter[stock_location_category_id]']}
                onChange={o =>
                  onChangeFilters('filter[stock_location_category_id]', o)
                }
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3">
            <FormGroup className="d-flex align-items-end h-100">
              <div
                className="form-check form-switch form-switch-md mb-3"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_locked"
                  checked={!!filters['filter[is_locked]']}
                  onChange={(o: any) =>
                    onChangeFilters('filter[is_locked]', o.target?.checked)
                  }
                />
                <Label className="form-check-label" htmlFor="is_locked">
                  {t(...messages.isLocked())}
                </Label>
              </div>
            </FormGroup>
          </Col>
          <AppBarActions>
            <>
              <Button color="important" onClick={handleCreate}>
                <Plus size={15} className="me-1" />
                {t('stockLocationPage.addbtn')}
              </Button>
              <Button
                color="light"
                className="ms-2"
                onClick={() => navigate('/dashboard/stock-location-categories')}
              >
                {t(messages.category())}
              </Button>
              <Button
                color="light"
                className="ms-2"
                onClick={() => navigate('/dashboard/stock-sub-locations')}
              >
                {t('stockSubLocationPage.title')}
              </Button>
            </>
          </AppBarActions>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default FiltersSection;
