import React, {FC} from 'react';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import PayCell from 'features/commercial/invoices/compenent/cells-features/pay-cell';
import {InvoiceCompact} from 'features/commercial/invoices/types/invoice';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ColumnDef} from '@tanstack/table-core';
import Currency from 'components/currency/currency';
import TtcColumn from 'features/commercial/documents/components/ttc-column/ttc-column';
import CostumeCurrency from 'components/costume-currency/costume-currency';
import TooltipCell from 'components/tooltip-cell';
import RelatedDocument from 'types/related-document';
import clsx from 'clsx';
import {TYPE} from 'components/async-preview-tooltip/constant';

const columns: ColumnDef<InvoiceCompact>[] = [
  {
    header: r => <Trans i18nKey="invoicesPage.client" />,
    accessorFn: row => (
      <Link
        to={`/dashboard/directories/${row.directory_id}/general`}
        target="_blank"
      >
        <b>{row.display_name}</b>
      </Link>
    ),
    cell: r => r.getValue(),
    id: 'display_name',
    enableSorting: true,
  },

  {
    header: r => <Trans i18nKey="invoicesPage.name" />,
    accessorFn: row => (
      <AsyncPreviewTooltip id={row.id} column="ref" type="INVOICE">
        <Link
          className={clsx({
            'text-danger fw-bold':
              row.document_type === 'CREDIT' ||
              row.document_type === 'CREDIT_ACCOUNTING',
          })}
          to={`/dashboard/invoices/${row.id}/details`}
          target="_blank"
        >
          <b> {row.document_ref}</b>
        </Link>
      </AsyncPreviewTooltip>
    ),
    cell: r => r.getValue(),
    id: 'document_ref',
    enableSorting: true,
  },
  {
    header: r => <Trans i18nKey="quotationsPage.related_documents" />,
    accessorFn: row =>
      row.related_documents.length !== 0 ? (
        <div className="w-100 d-flex flex-column  ">
          {row.related_documents.map(related_document => (
            <AsyncPreviewTooltip
              id={related_document.model_id}
              key={`${related_document.model_type}-${related_document.model_id}`}
              type={related_document.model_type?.toLocaleUpperCase() as TYPE}
              column="ref"
            >
              <Link
                className={clsx({
                  'text-danger fw-bold':
                    related_document.document_type === 'CREDIT' ||
                    related_document.document_type === 'CREDIT_ACCOUNTING',
                })}
                key={`${related_document.model_type}-${related_document.model_id}`}
                to={guessUrl(related_document)}
                target="_blank"
              >
                <b>{related_document.model_ref}</b>
              </Link>
            </AsyncPreviewTooltip>
          ))}
        </div>
      ) : (
        <span className="w-100 text-center"></span>
      ),
    id: 'related_documents',
    enableSorting: false,
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.invoiceDate" />,
    enableSorting: true,
    id: 'billing_date',
    cell: r => r.getValue(),
    accessorFn: row => (
      <span className={row.document_type === 'CREDIT' ? 'text-danger' : ''}>
        <FormattedDate value={row.billing_date} format="date" />
      </span>
    ),
  },

  {
    header: r => <Trans i18nKey="invoicesPage.documentCategory" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'document_category_name',
    accessorFn: row => (
      <EmptyCell
        className={row.document_type === 'CREDIT' ? 'text-danger' : ''}
        cell={row.document_category?.name}
      />
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.refClient" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'ref_client',
    accessorFn: row => (
      <EmptyCell
        className={row.document_type === 'CREDIT' ? 'text-danger' : ''}
        cell={row.ref_client}
      />
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.projet" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'project_name',
    accessorFn: row => (
      <EmptyCell
        className={row.document_type === 'CREDIT' ? 'text-danger' : ''}
        cell={row.project?.name}
      />
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.user" />,
    enableSorting: true,
    cell: r => r.getValue(),
    id: 'user_name',
    accessorFn: row => (
      <EmptyCell
        className={row.document_type === 'CREDIT' ? 'text-danger' : ''}
        cell={row.user?.name}
      />
    ),
  },

  {
    header: r => <Trans i18nKey="invoicesPage.subTotalHt" />,
    enableSorting: true,
    id: 'sub_total_ht',
    cell: r => r.getValue(),

    accessorFn: row => (
      <span
        className={
          row.document_type === 'CREDIT'
            ? ' text-nowrap text-danger'
            : 'text-nowrap'
        }
      >
        <CostumeCurrency
          value={row?.sub_price_ht}
          symbol={row?.currency_symbole}
        />
      </span>
    ),
  },

  {
    header: r => <Trans i18nKey="invoicesPage.totalHt" />,
    enableSorting: true,
    id: 'total_ht',
    cell: r => r.getValue(),

    accessorFn: row => (
      <span
        className={
          row.document_type === 'CREDIT'
            ? ' text-nowrap text-danger'
            : 'text-nowrap'
        }
      >
        <CostumeCurrency value={row?.total_ht} symbol={row?.currency_symbole} />
      </span>
    ),
  },

  {
    header: r => <Trans i18nKey="invoicesPage.totalTtc" />,
    enableSorting: true,
    id: 'total_ttc',
    cell: r => r.getValue(),
    accessorFn: row => (
      <span
        className={
          row.document_type === 'CREDIT'
            ? 'text-nowrap text-danger'
            : 'text-nowrap'
        }
      >
        <TtcColumn doc={row} />
      </span>
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.deposits" />,
    enableSorting: true,
    id: 'deposits',
    cell: r => r.getValue(),
    accessorFn: row => (
      <AsyncPreviewTooltip type="INVOICE" id={row.id} column="deposits">
        <span className={row.document_type === 'CREDIT' ? 'text-danger' : ''}>
          <Currency value={row.deposits} />
        </span>
      </AsyncPreviewTooltip>
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.payments" />,
    enableSorting: true,
    id: 'total_payment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <AsyncPreviewTooltip type="INVOICE" id={row.id} column="payments">
        <span className={row.document_type === 'CREDIT' ? 'text-danger' : ''}>
          <Currency value={row.payments} />
        </span>
      </AsyncPreviewTooltip>
    ),
  },
  {
    header: r => <Trans i18nKey="invoicesPage.balance" />,
    enableSorting: true,
    id: 'pay',
    cell: r => r.getValue(),

    accessorFn: row => (
      <span className={row.document_type === 'CREDIT' ? 'text-danger' : ''}>
        <PayCell row={row} />
      </span>
    ),
  },

  {
    header: r => <Trans i18nKey="invoicesPage.status" />,
    enableSorting: true,
    id: 'status',
    cell: r => r.getValue(),
    accessorFn: row => <StatusBadge status={row.status} />,
  },
  {
    header: r => <Trans i18nKey="quotationsPage.transporter_name" />,
    enableSorting: true,
    id: 'transporter_name',
    accessorFn: row => (
      <EmptyCell
        className={row.document_type === 'CREDIT' ? 'text-danger' : ''}
        cell={row.transporter_name}
      />
    ),
    cell: r => r.getValue(),
  },
  {
    header: r => <Trans i18nKey="quotationsPage.comment" />,
    enableSorting: false,
    id: 'comment',
    cell: r => r.getValue(),
    accessorFn: row => (
      <span className={row.document_type === 'CREDIT' ? 'text-danger' : ''}>
        <TooltipCell
          cell={row?.private_comment ?? ''}
          id={row.id}
        ></TooltipCell>
      </span>
    ),
  },
];

export default columns;

const guessUrl = (d: RelatedDocument) => {
  const urlMap: Record<RelatedDocument['model_type'], string> = {
    Invoice: 'invoices',
    Order: 'orders',
    Quotation: 'quotations',
  };
  const type = urlMap[d.model_type];
  const id = d.model_id;
  return `/dashboard/${type}/${id}/details`;
};
