import React from 'react';
import {Action} from 'features/hylian-shield/types';
import useAbility from 'features/hylian-shield/use-ability';
import {selectProject} from 'features/studio/projects/store/show-project';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {FC, Fragment} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

export const Guardian: FC<IGuardian> = ({children, action}) => {
  const {id} = useParams();

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const project = useSelector(selectProject({id})).data?.data;

  const subject = useCurrentSubject(project?.project_type);

  const {checkAbility} = useAbility();

  const can = checkAbility(action, subject);

  if (!can) {
    dispatch(toast('error', t('common.notAllowed')));

    navigate(-1);
    return <Fragment />;
  }

  return <Fragment>{children}</Fragment>;
};

interface IGuardian {
  children: JSX.Element;
  action: Action | Action[];
}
