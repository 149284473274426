import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const exportStockComptableQuantityValues = api.injectEndpoints({
  endpoints: builder => ({
    exportStockComptableQuantityValues: builder.query<Response, Request>({
      query: () => {
        return {
          method: 'GET',
          url: `export/stock-comptable-quantity-values`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<{}>;

export const {useLazyExportStockComptableQuantityValuesQuery} =
  exportStockComptableQuantityValues;
