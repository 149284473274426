import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  conclusion: () => __(translations.editProjectConclusionSection.conclusion, 'Conclusion'),
  projectProfitabiliy: () => __(translations.editProjectConclusionSection.projectProfitabiliy, 'Project profitability'),
  projectProfitabiliyPaid: () => __(translations.editProjectConclusionSection.projectProfitabiliyPaid, 'Project profitability paid'),

  totalCAProject: () => __(translations.editProjectConclusionSection.totalCAProject, 'Σ CA Project'),
  projectDurationToDate: () => __(translations.editProjectConclusionSection.projectDurationToDate, 'Project duration to date'),
  months: () => __(translations.editProjectConclusionSection.months, 'Months'),
  totalCAMonthlyPaid: () => __(translations.editProjectConclusionSection.totalCAMonthlyPaid, 'Σ CA Monthly paid'),

  totalHonoraria: () => __(translations.editProjectConclusionSection.totalHonoraria, 'Σ Honoraria'),
  totalFees: () => __(translations.editProjectConclusionSection.totalFees, 'Σ Fees'),
  totalSourcingFees: () => __(translations.editProjectConclusionSection.totalSourcingFees, 'Σ Sourcing Fees'),
  totalCommissions: () => __(translations.editProjectConclusionSection.totalCommissions, 'Σ Commissions'),
  totalSellsFFE: () => __(translations.editProjectConclusionSection.totalSellsFFE, 'Σ Sells FF&E'),
  totalSellsVintage: () => __(translations.editProjectConclusionSection.totalSellsVintage, 'Σ Sells Vintage'),

  // inputs
  comment: () => __(translations.editProjectConclusionSection.comment, 'Comment'),
};
