import React, {FC, useRef} from 'react';
import {Table, flexRender} from '@tanstack/react-table';
import styled from 'styled-components';

interface TableHeaderProps {
  table: Table<unknown>;
}

const TableHeader: FC<TableHeaderProps> = props => {
  const {table} = props;

  return (
    <StickyThead className="border-bottom z-3">
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <th
              key={header.column.id}
              scope="col"
              colSpan={header.colSpan}
              className={`${header.column.columnDef.meta?.headerCostumClass}`}
            >
              {header.isPlaceholder ? null : (
                <HeaderStyle
                  {...{
                    sortable: header.column.getCanSort(),
                    sorted: header.column.getIsSorted() as string,
                    className: `text-nowrap   `,
                    size: header.column.columnDef.meta?.size,
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  <div
                    className={`text-nowrap ${header.column.columnDef.meta?.costumClass}`}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>

                  <StyledSpan
                    sorted={header.column.getIsSorted() as string}
                    sortable={header.column.getCanSort()}
                  >
                    <i className="bx bx-chevron-up" aria-hidden="true"></i>
                    <i className="bx bx-chevron-down" aria-hidden="true"></i>
                  </StyledSpan>
                </HeaderStyle>
              )}
            </th>
          ))}
        </tr>
      ))}
    </StickyThead>
  );
};

const HeaderStyle = styled.div<{
  sorted?: string;
  sortable?: boolean;
  size?: string;
}>`
  cursor: ${props => (props.sortable ? 'pointer' : 'default')};
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  width: ${props => props.size};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: 'flex-start';

  div {
    height: 100%;
    text-align: center;
  }
`;
const StyledSpan = styled.span<{
  sortable?: boolean;
  sorted?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  i {
    opacity: ${props => (props.sortable ? '1' : '0')};
    color: inherit;
    text-align: center;
    font-size: 18px;
    height: 12px;
    width: 12px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition-duration: 95ms;
    transition-property: transform;
  }
  .bx-chevron-down {
    transform: translate(0, -4px);
  }
  .bx-chevron-up {
  }
  ${props => {
    if (props.sorted === 'asc') {
      return `
      .bx-chevron-down {
        opacity: 0;
   
  }
  `;
    } else if (props.sorted === 'desc') {
      return `
      .bx-chevron-up {
        opacity: 0;
      }`;
    }
  }}
`;

const StickyThead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
`;
export default TableHeader;
