import React, {Fragment, ReactNode, useContext} from 'react';
import {CommercialDocumentType} from '../../types/commercial-document-type';
import documentContext from '../document-context/document-context';

interface DocumentIsProps {
  oneOf: CommercialDocumentType[];
  children?: ReactNode;
}

const DocumentIs: React.FC<DocumentIsProps> = props => {
  const {oneOf, children} = props;

  const {doc_type} = useContext(documentContext);

  if (!doc_type) {
    throw new Error('Doc type was not provided');
  }

  return oneOf.includes(doc_type) ? <Fragment>{children}</Fragment> : null;
};

export default DocumentIs;
