import React, {FC, useState} from 'react';
import TextInput from 'components/text-input';
import {Badge, Col, Row} from 'reactstrap';
import Button from 'components/button';
import Icon from '@ailibs/feather-react-ts';
import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import messages from '../messages';
import {useUpdateStockLocationCommentMutation} from 'features/production/store/endpoints/update-stock-location-comment';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';

interface Props {
  location: any;
  hasStatus?: boolean;
}

const LocationItem: FC<Props> = props => {
  const {location, hasStatus} = props;

  const [submitComment, {isLoading, error}] =
    useUpdateStockLocationCommentMutation();

  const {message} = parseValidationErrors(error);

  const [isEditingComment, setisEditingComment] = useState(false);

  const [comment, setComment] = useState(location.comment);

  const [lastCommittedComment, setLastCommittedComment] = useState(
    location.comment
  );

  const cancel = () => {
    setComment(location.comment);
    setisEditingComment(false);
  };

  const {variant: id} = useParams();

  const submit = async () => {
    setisEditingComment(false);
    const result = await submitComment({
      id,
      comment,
      location_id: location.id,
    });
    if ('data' in result) {
      setLastCommittedComment(result.data.data);
    } else {
      setComment(lastCommittedComment);
    }
  };

  const {t} = useTranslation();

  return (
    <FixedHeightRow>
      <Row>
        <Col className="d-flex align-items-center">{location.name}</Col>
        <Col className="d-flex align-items-center" md={2}>
          {location.quantity}
        </Col>

        {hasStatus && (
          <Col className="d-flex align-items-center" md={2}>
            <Badge color="success">En stock</Badge>
          </Col>
        )}
        <Col>
          {isEditingComment ? (
            <div className="d-flex flex-row gap-2">
              <div className="flex-grow-1">
                <TextInput value={comment} onChange={setComment} />
              </div>
              <Button color="light" onClick={submit}>
                <Icon name="check" size={15} />
              </Button>
              <Button color="light" onClick={cancel}>
                <Icon name="x" size={15} />
              </Button>
            </div>
          ) : (
            <CommentParagraph>
              <CommentField
                onClick={() => setisEditingComment(!isEditingComment)}
              >
                {!isEmpty(comment) ? comment : t(...messages.clickToEdit())}
              </CommentField>
            </CommentParagraph>
          )}
          <ErrorText className="text-danger font-size-10">
            {message}
            {` `}
          </ErrorText>
        </Col>
        {!hasStatus && <Col className="d-flex align-items-center" md={1}></Col>}
      </Row>
    </FixedHeightRow>
  );
};

export default LocationItem;

const FixedHeightRow = styled.div`
  min-height: 60px;
`;

const ErrorText = styled.div`
  padding: 0 13px;
  white-space: pre-wrap;
`;

const CommentParagraph = styled.div`
  min-height: 38px;
  display: flex;
  align-items: center;
`;

const CommentField = styled.div`
  min-height: 38px;
  display: flex;
  padding: 0 13px;
  align-items: center;
  &:hover {
    background-color: var(--ds-surface, #ededed);
    background-size: 20px 20px;
    background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
    border-radius: var(--ds-border-radius, 3px) var(--ds-border-radius, 3px) 0 0;
  }
`;
