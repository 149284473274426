import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useBlockVariantActionMutation} from 'store/production/endpoints/action-variant-block';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ProductVariant} from 'types/product-variant';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useBLockVariant = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useBlockVariantActionMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {id, onSuccess, message} = modalParams;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const result = await submit({
      id,
    });
    if ('data' in result) {
      const v = result.data.data;
      redirectByVariant(v);
      dispatch(toast('success', result?.data?.message));

      onDismiss();
    }
  };

  const [searchParams] = useSearchParams();

  const redirectByVariant = (v: ProductVariant) => {
    searchParams.set('filter[is_blocked]', v.is_blocked ? '1' : '0');
    navigate(
      `/dashboard/products/${v.product_id}/variants/${
        v.id
      }/general?${searchParams.toString()}`
    );
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
