import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {useUpdateTranslationsMutation} from 'store/production/endpoints/update-translations';
import {ObjectId} from 'types/object-id';
import {LangOption, ProductTranslation} from 'types/product-translation';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useTranslationsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateTranslationsMutation();

  const serverError = parseValidationErrors(error);

  const {id, variant: variantId} = useParams();

  const [translations, setTranslations] = useState<TranslationMap>({});
  const [deletedRecords, setdeletedRecords] = useState([] as ObjectId[]);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await update({
      translations: Object.values(translations),
      deletedRecords,
      id,
      variant: variantId,
    });
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const addTranslation = (x: LangOption) => {
    const p: ProductTranslation = {
      id: x.code,
      label: '',
      finition: '',
      description: '',
      lang: x,
    };
    setTranslations(
      produce(translations, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeTranslation = (id?: ObjectId) => {
    if (!id) {
      return;
    }
    const _translations: Record<ObjectId, ProductTranslation> = Object.assign(
      {},
      translations
    );

    delete _translations[id];
    setTranslations({
      ..._translations,
    });
    setdeletedRecords([...deletedRecords, id]);
  };

  const updateTranslation = (
    p: ProductTranslation,
    k: keyof ProductTranslation,
    v: any
  ) => {
    setTranslations(
      produce(translations, draft => {
        if (p.lang?.code) {
          draft[p.lang?.code][k] = v;
        }
      })
    );
  };

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data;

  useEffect(() => {
    if (variant) {
      const tmp = variant.translations.reduce((a, c) => {
        if (c.lang?.code) {
          a[c.lang.code] = c;
        }
        return a;
      }, {} as TranslationMap);
      setTranslations(tmp);
    }
  }, [variant]);

  return {
    addTranslation,
    removeTranslation,
    submit: handleSubmit,
    updateTranslation,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    translations,
    validationErrors: serverError.errors,
  };
};

type TranslationMap = Record<ObjectId, ProductTranslation>;
