import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedOrderFromResource} from './show-order';
import {Order} from '../../types/order';

const archiveOrder = api.injectEndpoints({
  endpoints: builder => ({
    archiveOrder: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'PUT',
          url: `orders/${params.id}/archive`,
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

type Request = {id: ObjectId};

type Response = ResponseData<Order>;

export const {useArchiveOrderMutation} = archiveOrder;
