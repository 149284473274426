import React, {useEffect, useMemo, useState} from 'react';
import MonitoringListingTabPane from './tab-pane';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {useTranslation} from 'react-i18next';
import Table from 'features/table';
import columns from './columns';
import {MonitoringListingTypes} from 'features/stock/types/monitoring-listing-types';
import {useDispatch} from 'react-redux';
import loadData from 'features/table/slice/actions/load-data';
import {NAV_LINKS} from 'features/stock/constants/monitoring-listing-nav-links';
import {Card, CardBody, CardHeader} from 'reactstrap';

const MonitoringListingView = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [type, setType] = useState<MonitoringListingTypes>('v_client');

  useEffect(() => {
    dispatch(
      loadData({
        name: 'production-monitoring',
        url: 'production-monitoring',
        otherParams: {type},
      })
    );
  }, [type]);

  const title = useMemo(() => {
    return NAV_LINKS.find(el => el.type === type)?.title;
  }, [type]);

  // removing columns depending on type
  const _columns = useMemo(() => {
    switch (type) {
      case 'v_stock':
      case 'z_stock':
        return columns.filter(el => el.id !== 'client_payment');
      default:
        return columns;
    }
  }, [type]);

  return (
    <Card>
      <CardHeader>
        <MonitoringListingTabPane type={type} setType={setType} />
      </CardHeader>
      <CardBody>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},

            {title: t(`monitoringListingPage.${title}`), path: '#'},
          ]}
        />
        <Table
          url="production-monitoring"
          name="production-monitoring"
          columns={_columns}
          otherParams={{
            type,
          }}
        />
      </CardBody>
    </Card>
  );
};

export default MonitoringListingView;
