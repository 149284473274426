import {StockProductsInPreparationStatusEnum} from 'features/stock/constants/stock-products-in-preparation-status-enum';
import {
  UpdateStockProductsInPreparationRequest,
  useUpdateStockProductsInPreparationMutation,
} from 'features/stock/store/endpoint/update-stock-products-in-preparation';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import {
  EnumMapping,
  useEnumAsOption,
} from 'features/studio/projects/utils/use-enum-as-option';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ValidationErrors} from 'services/api/types';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {uiActions} from 'store/ui/ui-slice';
import {ObjectId} from 'types/object-id';
import loadData from 'features/table/slice/actions/load-data';

import {Option} from 'types/option';

export const useEditStockInPreparationForm = (): EditStockInPreparationForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState<Params>({} as Params);

  const [updateStockProductsInPreparation, {isLoading: isSubmitting, error}] =
    useUpdateStockProductsInPreparationMutation();

  const serverErrors = parseValidationErrors(error);

  const shapePayload = (
    data: Params
  ): UpdateStockProductsInPreparationRequest => {
    return {
      id: data.id,
      tracking_code: data.tracking_code,
      status: data.status,
      comment: data.comment,
      transporter_id: data.transporter?.id as ObjectId,
    };
  };

  const handleSubmit = async (id: ObjectId) => {
    const result = await updateStockProductsInPreparation(
      shapePayload({...params, id})
    );

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      dispatch(
        loadData({
          name: 'stock-products-in-preparation',
          url: 'stock/products-in-preparation?filter[status]=WAITING_FOR_DELIVERY',
          page: 1,
        })
      );
      dispatch(uiActions.dismissModal());
    }
  };

  const handleChange: HandleChange = (key, val) => {
    setParams(prev => ({
      ...prev,
      [key]: val,
    }));
  };

  const options = useEnumAsOption(
    StockProductsInPreparationStatusEnum,
    'stockInPreparation.editModal'
  );

  return {
    params,
    options,
    isSubmitting,
    errorMessage: serverErrors.message,
    validationErrors: serverErrors.errors,
    setParams,
    handleChange,
    submit: handleSubmit,
  };
};

export type Params = StockProductInPreparation;

interface EditStockInPreparationForm {
  params: Params;
  isSubmitting: boolean;
  errorMessage?: string;
  validationErrors: ValidationErrors;
  setParams: SetParams;
  handleChange: HandleChange;
  submit: HandleSubmit;
  options: EnumMapping<typeof StockProductsInPreparationStatusEnum>[];
}

type SetParams = (args: Params) => void;

type HandleSubmit = (id: ObjectId) => void;

type HandleChange = (
  key: keyof StockProductInPreparation,
  val: Option | string
) => void;
