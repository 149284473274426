import {api} from 'services/api';
import {DirectoryFamily} from 'types/directory-family';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateDirectoryFamily = api.injectEndpoints({
  endpoints: builder => ({
    updateDirectoryFamily: builder.mutation<
      Response,
      UpdateDirectoryFamilyRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `directory-families/${params.id}`,
          body: {
            comment: params.comment,
            name: params.name,
            categories: params.categories?.map(x => x.id),
          },
        };
      },
    }),
  }),
});

export type UpdateDirectoryFamilyRequest = {
  name?: string;
  id: ObjectId;
  comment?: string;
  categories?: Option[];
};

type Response = ResponseData<DirectoryFamily>;

export const {useUpdateDirectoryFamilyMutation} = updateDirectoryFamily;
