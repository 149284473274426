import React from 'react';
import columns from './columns';
import FiltersSection from './filters-section';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Table from 'features/table';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

const StockProductsPage = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},

            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Table
          columns={columns}
          url="stock/products"
          name="stock-products"
          filtersComponent={FiltersSection}
        />
      </Container>
    </div>
  );
};

export default StockProductsPage;
