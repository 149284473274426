import {CalendarOrder} from 'features/stock/types/calendar-order';
import {CalendarSupplier} from 'features/stock/types/calendar-supplier';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

const getOrdersByRange = api.injectEndpoints({
  endpoints: builder => ({
    getOrdersByRange: builder.query<Response, OrdersByRangeRequest>({
      query: params => {
        const suppliersString = params.suppliers.map(x => x.id).join('|');
        return {
          method: 'POST',
          url: `calendar/orders`,
          body: {
            suppliers: suppliersString === '' ? undefined : suppliersString,
            from: params.from,
            to: params.to,
            keyword: params.keyword ? params.keyword : undefined,
          },
        };
      },
      //@ts-expect-error
      providesTags: result => {
        if (result) {
          return [
            ...result.data.map(({id}: any) => ({
              type: 'calendarOrder',
              id,
            })),
          ];
        } else {
          return [];
        }
      },
    }),
  }),
});

export type OrdersByRangeRequest = {
  from?: string;
  to?: string;
  suppliers: CalendarSupplier[];
  keyword?: string;
};

type Response = ResponseData<CalendarOrder[]>;

export const {useGetOrdersByRangeQuery} = getOrdersByRange;
export const selectOrder = getOrdersByRange.endpoints.getOrdersByRange.select;
