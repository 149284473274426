import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  quotation: () => __(translations.createQuotationPage.quotation, 'Quotations'),
  title: () => __(translations.createQuotationPage.title, 'Create quotation'),
  client: () => __(translations.createQuotationPage.client, 'Client'),
  addNewCompanyClient: () => __(translations.createQuotationPage.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.createQuotationPage.addNewPrivateClient, 'Add Private'),
  quotationType: () => __(translations.createQuotationPage.quotationType, 'Type'),
  quotationCategory: () => __(translations.createQuotationPage.quotationCategory, 'Category'),
  quotationOrigin: () => __(translations.createQuotationPage.quotationOrigin, 'Origin'),
  quotationLanguage: () => __(translations.createQuotationPage.quotationLanguage, 'Language'),
  quotationIssueDate: () => __(translations.createQuotationPage.quotationIssueDate, 'Quotation Issue Date'),
  quotationDeadline: () => __(translations.createQuotationPage.quotationDeadline, 'Quotation Deadline'),
  quotationProject: () => __(translations.createQuotationPage.quotationProject, 'Project'),
  categoryQuotationPrivateClient: () => __(translations.createQuotationPage.categoryQuotationPrivateClient, 'Private client'),
  categoryQuotationReseller: () => __(translations.createQuotationPage.categoryQuotationReseller, 'Reseller'),
  categoryQuotationDecoration: () => __(translations.createQuotationPage.categoryQuotationDecoration, 'Decoration'),
  categoryQuotationLuxuryBrand: () => __(translations.createQuotationPage.categoryQuotationLuxuryBrand, 'Luxury Brand'),
};
