import React, {useEffect, useState, useRef} from 'react';
import RightSidebar from '../CommonForBoth/RightSidebar';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';
import {useSelector, useDispatch} from 'react-redux';
import {layoutActions} from 'store/layout/layout-slice';
import {selectLayoutState} from 'store/layout/selectors';
import {AppTheme} from 'store/layout/types';

interface LayoutProps {
  children: any;
}

const Layout = (props: LayoutProps) => {
  const dispatch = useDispatch();

  const {isPreloader, layoutType, showRightSidebar} =
    useSelector(selectLayoutState);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const preloader: any = document.getElementById('preloader');

    if (isPreloader === true) {
      preloader.style.display = 'block';

      setTimeout(function () {
        preloader.style.display = 'none';
      }, 1000);
    } else {
      preloader.style.display = 'none';
    }
  }, [isPreloader]);

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const ref = useRef<any>();

  /**
   * Call dark/light mode
   *
   * @param value
   */
  const onChangeLayoutMode = (value: AppTheme) => {};

  return (
    <React.Fragment>
      <div className="pace pace-active" id="preloader" ref={ref}>
        <div
          className="pace-progress"
          data-progress-text="100%"
          data-progress="99"
          style={{transform: 'translate3d(100%, 0px, 0px)'}}
        >
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>

      <div id="layout-wrapper">
        <Header />
        <Navbar menuOpen={openMenu} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>

      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

export default Layout;
