import React, {Fragment} from 'react';
import LoadRunner from 'components/load-runner';

const ListSkeleton = () => {
  const fake = [1, 2, 3, 4, 5];

  const height = 20;
  const offset = 10;
  return (
    <LoadRunner width={'100%'} height={400} viewBox="0 0 100% 200">
      {fake.map(x => (
        <Fragment key={x}>
          <rect
            x="0%"
            y={offset * x + x * 5 + '%'}
            rx="10"
            ry="10"
            width="10%"
            height={height}
          />
          <rect
            x="15%"
            y={offset * x + x * 5 + '%'}
            rx="10"
            ry="10"
            width="25%"
            height={height}
          />

          <rect
            x="45%"
            y={offset * x + x * 5 + '%'}
            rx="10"
            ry="10"
            width="15%"
            height={height}
          />
          <rect
            x="65%"
            y={offset * x + x * 5 + '%'}
            rx="10"
            ry="10"
            width="8%"
            height={height}
          />
          <rect
            x="78%"
            y={offset * x + x * 5 + '%'}
            rx="10"
            ry="10"
            width="20%"
            height={height}
          />
        </Fragment>
      ))}
    </LoadRunner>
  );
};

export default ListSkeleton;
