import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Eye} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import Button from 'components/button';
import {StockProduct} from 'features/stock/types/stock-products';
import {SERIAL_NUMBER_STATUS} from 'types/product-variant';
import Can from 'features/hylian-shield/can';

interface Props {
  stockProduct: StockProduct;
}

const StockProductActions: FC<Props> = props => {
  const {stockProduct} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.UPDATE_STOCK_PRODUCT, {
        stockProduct: stockProduct,
        path: {
          url: 'stock/products',
          name: 'stock-products',
        },
      })
    );
  };
  const handleEntry = () => {
    dispatch(showModal(MODAL.UPDATE_STOCK_ENTRY, {stockProduct}));
  };
  const handleDetails = () => {
    dispatch(showModal(MODAL.DETAILS_STOCK_PRODUCT, {stockProduct}));
  };

  return (
    <div className="d-flex align-items-center text-nowrap h-100">
      <Can action="edit" subject="stock">
        {stockProduct.status === SERIAL_NUMBER_STATUS.OPENED ? (
          <Button tag="button" className="me-2" onClick={handleEntry}>
            <Edit size={14} />
            <span className="align-middle ms-3">{t(...messages.edit())}</span>
          </Button>
        ) : (
          <Button tag="button" className="me-2" onClick={handleEdit}>
            <Edit size={14} />
            <span className="align-middle ms-3 font-size-14 p-1">
              {t(...messages.enter())}
            </span>
          </Button>
        )}
      </Can>
      <Button onClick={handleDetails} color="important">
        <Eye size={14} />
      </Button>
    </div>
  );
};

export default StockProductActions;
