import React, {FC} from 'react';
import messages from '../messages';
import {StockOrderPaginationMeta} from './types';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import { useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import { Col, Row} from 'reactstrap';
import TagsItems from 'components/tags-card';
import Currency from 'components/currency/currency';
import NaturalNumber from 'components/natural-numbers/natural-numbers';

interface TagsProps {}

const TagsRow: FC<TagsProps> = props => {
  const {t} = useTranslation();

  const meta = useSelector(
    selectEntityMeta<StockOrderPaginationMeta>('stock-orders')
  );

  return (
    <Row className="align-items-center justify-content-between mb-3">
      <Col sm={3}>
        <TagsItems title={t(...messages.stockOrdertotalHt())}>
         
          <Currency value={meta?.stocks_total_ht} />
        </TagsItems>
      </Col>

      <Col sm={3}>
        <TagsItems title= {t(...messages.stockOrdertotalTtc())}>
           
       
          <Currency value={meta?.stocks_total_ttc} />
        </TagsItems>
      </Col>
      <Col sm={3}>
        <TagsItems title= {t(...messages.totalOrders())}>
        
           
          
          <NaturalNumber value={meta?.total_orders} />
        </TagsItems>
      </Col>
      <Col sm={3}>
        <TagsItems title= {t(...messages.stockOrderTotalCost())}>
        
           
          
          <Currency value={meta?.stocks_total_costs} />
        </TagsItems>
     </Col>
    </Row>
  );
};
interface TagItemsProps {
  tag: number;
}



export default TagsRow;
