import {api} from 'services/api';
import {DirectoryCategory} from 'types/directory-category';
import {ResponseData} from 'types/response-data';

const storeDirectoryCategory = api.injectEndpoints({
  endpoints: builder => ({
    storeDirectoryCategory: builder.mutation<
      Response,
      StoreDirectoryCategoryRequest
    >({
      query: params => {
        return {
          method: 'POST',
          url: `directory-categories`,
          body: {
            comment: params.comment,
            name: params.name,
          },
        };
      },
    }),
  }),
});

export type StoreDirectoryCategoryRequest = {
  name?: string;
  comment?: string;
};

type Response = ResponseData<DirectoryCategory>;

export const {useStoreDirectoryCategoryMutation} = storeDirectoryCategory;
