import {useMemo, useState} from 'react';
import {produce} from 'immer';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ObjectId} from 'types/object-id';
import {
  TransferOrderRequest,
  TransferResponse,
  useTransferOrderMutation,
} from '../../store/endpoints/transfer-order';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {selectOrder} from '../../store/endpoints/show-order';
import denormalizeObject from 'utils/denormalize-object';
import {RedirectTo} from '../../store/endpoints/transfer-order';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (onDismiss: () => void) => {
  const [transfer, {isLoading, error, data: response}] =
    useTransferOrderMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useSelector(selectActiveModalParams);

  const [params, setParams] = useState<Params>({});

  const [items, setItems] = useState<Record<ObjectId, boolean>>({});
  const handleSubmit = async () => {
    const result = await transfer({
      ...params,
      items,
      id,
    });

    if ('data' in result) {
      redirectByType(result?.data);
      onDismiss();
    }
  };

  const redirectByType = (res: TransferResponse) => {
    const {redirect_to} = res;

    const linkByDoctype = {
      Order: `dashboard/orders`,
      Invoice: `dashboard/invoices`,
    };

    const to = redirect_to[redirect_to.length - 1] as RedirectTo;
    window.open(
      `/app/${linkByDoctype[to.model_type]}/${to.id}/details`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const updateItem = (id: ObjectId) => {
    setItems(
      produce(items, draft => {
        draft[id] = !draft[id];
      })
    );
  };

  const order = useSelector(selectOrder({id}));

  const originalItems = order.data?.data.commercial_products_objectified;

  const itemsLength = useMemo(() => {
    const commercial_products = Object.keys(items ?? {})
      .filter(x => items[x])
      .map(id => ({id}));
    return commercial_products.length;
  }, [items]);

  const onToggleAll = () => {
    if (isAllSelected) {
      setItems({});
      return;
    }
    const itemsTmp = {} as Record<ObjectId, boolean>;
    denormalizeObject(originalItems).forEach(x => {
      if (!isTransferred(x.id)) {
        itemsTmp[x.id] = true;
      }
    });
    setItems({
      ...itemsTmp,
    });
  };

  const isAllSelected = useMemo(() => {
    const allItems = denormalizeObject(originalItems).filter(
      x => !x.is_transferred_to_invoice
    );

    return allItems.length === itemsLength;
  }, [itemsLength, originalItems]);

  const isTransferred = (id: ObjectId) => {
    if (!originalItems) {
      return true;
    }
    return !!originalItems[id].is_transferred_to_invoice;
  };
  return {
    setItems,
    itemsLength,

    setParams,
    onToggleAll,
    isTransferred,
    isAllSelected,
    submit: handleSubmit,
    updateItem,
    items,
    errorMessage: serverError.message,
    isLoading,
    params,
    validationErrors: serverError.errors,
  };
};

type Params = Partial<TransferOrderRequest>;
