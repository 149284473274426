import {Toast} from './types';
import uiSlice, {uiActions} from './ui-slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {resurrect} from 'features/modal-notch/reincarnation-angel/saga';
import {eatSoul} from 'features/modal-notch/soul-eater/saga';
import {toast as toaster} from 'react-toastify';
import {delay, put, takeLatest} from 'redux-saga/effects';

function* toast(action: PayloadAction<Toast>) {
  const {message, type} = action.payload;

  toaster[type](message, {position: toaster.POSITION.TOP_RIGHT});

  yield delay(1000);

  yield put(uiActions.clearToast());
}

export function* uiSaga() {
  yield takeLatest(uiSlice.actions.confirmDelete, eatSoul);
  yield takeLatest(uiSlice.actions.confirmRestore, resurrect);
  yield takeLatest(uiSlice.actions.toast, toast);
}
