import packageInfo from '../../package.json';
import composeRoute from 'utils/compose-route';

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_DEFAULT_THEME,
  REACT_APP_DEFAULT_LOCALE,
  REACT_APP_COPYRIGHT_ATTRIBUTION,
  REACT_APP_GOOGLE_WEB_CLIENT_ID,
  REACT_APP_WS_KEY,
  REACT_APP_WS_PORT,
  REACT_APP_WS_CLUSTER,
  REACT_APP_WS_SCHEME,
  REACT_APP_STORE_PREFIX,
  REACT_APP_WS_HOST,
  REACT_APP_NAME,
  REACT_APP_TILE_URL,
  REACT_APP_FACEBOOK_APP_ID,
} = process.env;

export const application = {
  name: REACT_APP_NAME,
  env: process.env.NODE_ENV,
  version: packageInfo.version,
  baseUrl: process.env.REACT_APP_BASE_URL,
  webSocketsAuthUrl: composeRoute('broadcasting/auth'),
  apiBaseUrl: String(REACT_APP_API_BASE_URL),
  storePrefix: REACT_APP_STORE_PREFIX,
  platform: 'web',
  defaultLocale: String(REACT_APP_DEFAULT_LOCALE),
  defaultTheme: String(REACT_APP_DEFAULT_THEME),
  copyRightAttribution: REACT_APP_COPYRIGHT_ATTRIBUTION,
  oauth: {
    google: {
      webClientId: String(REACT_APP_GOOGLE_WEB_CLIENT_ID),
      offlineAccess: true,
      /**
       * what API you want to access
       */
      scopes: ['email', 'profile'],
    },
    facebook: {
      appId: String(REACT_APP_FACEBOOK_APP_ID),
      scope: 'public_profile,email',
      version: '11.0',
    },
    apple: {
      /**
       *
       * in most cases use "appleAuth.Operation.LOGIN"
       */
      requestedOperation: undefined,
      requestedScopes: [
        // Scopes
        // appleAuth.Scope.EMAIL,
        // appleAuth.Scope.FULL_NAME,
      ],
    },
  },
  webSockets: {
    appKey: String(REACT_APP_WS_KEY),
    appCluster: String(REACT_APP_WS_CLUSTER),
    appHost: REACT_APP_WS_HOST,
    appPort: Number(REACT_APP_WS_PORT),
    appScheme: REACT_APP_WS_SCHEME,
  },
  maps: {
    /**
     * The map screen's default position
     * defaults to algeria's lat long
     */
    defaultLatitude: 36.7525,
    defaultLongitude: 3.04197,
    tileUrl: String(REACT_APP_TILE_URL),
    copyRightAttribution: REACT_APP_COPYRIGHT_ATTRIBUTION,
  },
};
