import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {useListSlice} from 'features/table/slice';
import {useToggleOverpaymentUsageMutation} from 'pages/accounting/store/endpoints/toggle-overpayment-usage';
import {useToggleOverpaymentArchiveMutation} from 'pages/accounting/store/endpoints/toggle-overpayment-archive';
import {STATUS} from 'constants/status';

export const useToggleOverpaymentsStatusForm = (onDismiss: () => void) => {
  const [toggleUsage, {isLoading: isTogglingUsage, error: usageError}] =
    useToggleOverpaymentUsageMutation();
  const [toggleArchive, {isLoading: isTogglingArchive, error: archiveError}] =
    useToggleOverpaymentArchiveMutation();

  const serverError = parseValidationErrors(usageError || archiveError);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, overpaymentId: id, currentStatus, newStatus} = modalParams;
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const query = newStatus === STATUS.ARCHIVED ? toggleArchive : toggleUsage;

    const result = await query({
      id,
    });

    if ('data' in result) {
      dispatch(toast('success', result.data?.message));

      const {actions} = useListSlice();
      dispatch(
        actions.loadData({
          name: 'overpayments',
          url: `overpayments?filter[status]=${currentStatus}`,
        })
      );

      onDismiss();
    }
  };

  return {
    message,
    isLoading: isTogglingUsage || isTogglingArchive,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
