import messages from './messages';
import {Option} from 'types/option';
import {__} from 'utils/messages';

export const DIRECTORY_TYPE = {
  COMPANY: 'COMPANY',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const DIRECTORY_TYPES = {
  [DIRECTORY_TYPE.COMPANY]: {
    id: DIRECTORY_TYPE.COMPANY,
    name: messages.company,
  },
  [DIRECTORY_TYPE.INDIVIDUAL]: {
    id: DIRECTORY_TYPE.INDIVIDUAL,
    name: messages.individual,
  },
};
