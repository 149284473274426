import React from 'react';
import {ColumnDef} from '@tanstack/table-core';
import EmptyCell from 'components/empty-cell';
import {Trans} from 'react-i18next';
import {StockInProduct} from 'features/stock/types/stock-in-product';
import StockInProductActions from './stock-in-products-actions';
import {Link} from 'react-router-dom';

const columns: ColumnDef<StockInProduct>[] = [
  {
    header: r => <Trans i18nKey="stockInProduct.logistic" />,
    enableSorting: true,
    accessorFn: row => <EmptyCell cell={row.stock_location.name} />,
    id: 'name',
    cell: r => r.getValue(),
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="stockInProduct.sku" />
      </span>
    ),
    accessorFn: r => <EmptyCell cell={r.product_variant?.sku} />,
    cell: r => r.getValue(),

    enableSorting: true,
    id: 'sku',
  },

  {
    header: () => (
      <span className="font-size-14">
        <Trans i18nKey="stockInProduct.product" />
      </span>
    ),
    accessorFn: row => (
      <Link
        to={`/dashboard/products/${row.product.id}/variants/${row.product_variant?.id}/general`}
        target="_blank"
      >
        <EmptyCell
          cell={<b>{row.product_variant.name?.toLocaleUpperCase()}</b>}
          fallback="-"
        />
      </Link>
    ),
    cell: r => r.getValue(),
    id: 'product',
    enableSorting: true,
    meta: {size: '16vw'},
  },

  {
    header: r => <Trans i18nKey="stockInProduct.quantity" />,
    enableSorting: true,
    id: 'products_count',
    accessorFn: row => (
      <EmptyCell cell={row.product_variant.serial_numbers_count} />
    ),
    cell: r => r.getValue(),
  },

  {
    header: r => <Trans i18nKey="stockInProduct.action" />,
    enableSorting: false,
    accessorFn: row => <StockInProductActions stockInProduct={row} />,
    id: 'actions',
    cell: r => r.getValue(),
  },
];

export default columns;
