import React, {FC, useContext, useEffect} from 'react';
import transferContext from './transfer-context';
import ExpandRow from 'features/table/compenents/expand-row';

interface Props {
  table: any;
}

export const TransferExpandAll: FC<Props> = props => {
  const {table} = props;
  const {initItems} = useContext(transferContext);

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, []);

  return (
    <ExpandRow
      onClick={table.getToggleAllRowsExpandedHandler()}
      isExpanded={table.getIsAllRowsExpanded()}
    />
  );
};
