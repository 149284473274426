import React, {FC, useMemo} from 'react';
import Currency from 'components/currency/currency';
import {IDeposit, IPhase} from 'types/project';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import {Col, Row} from 'reactstrap';
import TagsItem from 'components/tags-card';

export const GrandTotalRecap: FC<{phases: IPhase[]}> = ({phases}) => {
  const {t} = useTranslation();

  // conditions
  const isToInvoice: ConditionFun = deposit =>
    !deposit.completion_date && !deposit.paid_on;

  const isPending: ConditionFun = deposit =>
    !!deposit.completion_date && !deposit.paid_on;

  const isPaid: ConditionFun = deposit => !!deposit.paid_on;

  const getTotal = (condition: ConditionFun) => {
    const sum = (key: keyof Pick<IDeposit, 'honoraria' | 'fees'>) =>
      phases.map(item => {
        return item.deposits.reduce((acc, child) => {
          if (condition(child)) {
            return acc + Number(child[key]);
          }

          return acc;
        }, 0);
      });

    return _.sum(sum('honoraria')) + _.sum(sum('fees'));
  };

  const toInvoice = useMemo(() => getTotal(isToInvoice), [phases]);
  const pending = useMemo(() => getTotal(isPending), [phases]);
  const paid = useMemo(() => getTotal(isPaid), [phases]);

  return (
    <Row className="align-items-center justify-content-center mb-3">
      <Col sm={3}>
        <TagsItem title={t(...messages.grandTotal())}>
          <Currency value={toInvoice + paid + pending} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem title={t(...messages.totalPaid())}>
          <Currency value={paid} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem title={t(...messages.totalPending())}>
          <Currency value={pending} />
        </TagsItem>
      </Col>
      <Col sm={3}>
        <TagsItem title={t(...messages.totalToInvoice())}>
          <Currency value={toInvoice} />
        </TagsItem>
      </Col>
    </Row>
  );
};

type ConditionFun = (element: IDeposit) => boolean;
