import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.invoiceVtaAndTaxes.title, 'VAT & Taxes'),
  invoiceVtaNumber: () => __(translations.invoiceVtaAndTaxes.invoiceVtaNumber, 'VAT Number'),
  invoiceVtaMode: () => __(translations.invoiceVtaAndTaxes.invoiceVtaMode, 'VAT Mode'),
  invoiceVatIncluded: () => __(translations.invoiceVtaAndTaxes.invoiceVatIncluded, 'VAT exemption (force VAT)'),
  invoiceVatExcluded: () => __(translations.invoiceVtaAndTaxes.invoiceVatExcluded, 'Without VAT'),
  changesCommited: () => __(translations.invoiceVtaAndTaxes.changesCommited, 'Your changes has been saved successfully!'),
};
