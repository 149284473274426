/**
 * Item picker
 *
 */
import React from 'react';
import {FC, useMemo} from 'react';
import {Option} from './types';
import {isArray} from 'lodash';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {Label} from 'reactstrap';
import {selectAppTheme} from 'store/layout/selectors';
import {ObjectId} from 'types/object-id';
import useInputId from 'utils/use-input-id';

interface Props {
  errors?: string[] | string;
  isClearable?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  label?: string;
  onChange: (x: Option | ObjectId) => void;
  options: any[];
  value?: Option | ObjectId | null;
  valueOnly?: boolean;
  // All other props
  [x: string]: any;
}

const ItemPicker: FC<Props> = props => {
  const {
    options,
    id: inputId,
    value,
    label,
    onClick,
    errors,
    valueOnly,
    onChange,
    ...rest
  } = props;

  const getOptionValue = (s: Props['value']) => {
    if (props.getOptionValue) {
      return props.getOptionValue(s);
    }
    // @ts-ignore
    return s?.id;
  };

  const handleChange = (selected: Option) => {
    if (valueOnly) {
      onChange(getOptionValue(selected));
    } else {
      onChange(selected);
    }
  };

  const boundValue = useMemo(() => {
    if (isArray(value) && props.isMulti) {
      return value;
    }

    const id = typeof value !== 'object' ? value : getOptionValue(value);
    const optionsWithValue = [value, ...options];
    return optionsWithValue.find(x => getOptionValue(x) === id);
  }, [value, options]);

  const appTheme = useSelector(selectAppTheme);

  const selectThemeColors = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--bs-primary)', // for selected option bg-color
        neutral10: 'var(--bs-border-color)', // for tags border-color
        neutral20: 'var(--bs-border-color)', // for input border-color
        neutral0: appTheme === 'dark' ? 'var(--bs-input-bg)' : '#fff', // used
        neutral80: 'var(--bs-light-text)', // text color
      },
    };
  };

  const id = useInputId(inputId);

  return (
    <div onClick={onClick}>
      {label && (
        <Label className="mb-0" htmlFor={id}>
          {label}
        </Label>
      )}
      <Select
        theme={selectThemeColors}
        className="react-select"
        inputId={id}
        classNamePrefix="select"
        getOptionLabel={(v: Option) => v.name}
        getOptionValue={(v: Option) => v.id}
        styles={{
          menu: (provided: any) => ({...provided, zIndex: 2001}),
          control: (baseStyles: any) => ({
            ...baseStyles,
            borderColor: errors?.length ? 'red' : baseStyles.borderColor,
          }),
        }}
        options={options}
        onChange={handleChange}
        value={boundValue ?? null}
        {...rest}
      />
      {errors ? <div className="invalid-feedback d-block">{errors}</div> : null}
    </div>
  );
};

export default ItemPicker;
