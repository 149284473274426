import React, {FC, Fragment} from 'react';
import selectVat from '../variant/costs/slice/selectors/select-vat';
import messages from './messages';
import SliderVariant from './slider';
import formatters from 'locales/messages';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {Badge, CardTitle, Row} from 'reactstrap';
import {Card, CardBody, Col} from 'reactstrap';
import {selectVariant} from 'store/production/endpoints/show-variant';
import selectMainSituation from 'store/production/selectors/select-main-situation';
import {Product} from 'types/product';
import {ProductVariant} from 'types/product-variant';

interface Props {
  product: Product;
}

const InfoSection: FC<Props> = props => {
  const {product} = props;

  const {t} = useTranslation();

  const {id, variant: variantId} = useParams();

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data as ProductVariant;

  const ms = useSelector(selectMainSituation(variantId, id));

  const {vat} = useSelector(selectVat(id));

  const manifacturers = variant?.production_times;
  return (
    <Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-3">
            <h5 className="font-size-15"> {t(...messages.title())} </h5>
          </CardTitle>
          <div className="">
            {/* Row */}
            <Row>
              <Col md={5} className="fw-bold w-50">
                <b>{t(...messages.date())} </b>
              </Col>
              <Col className="text-end">
                {t(...formatters.intlDateTime(), {
                  val: new Date(variant?.creation_date ?? ''),
                  formatParams: {
                    val: {
                      year: 'numeric',
                      month: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      day: 'numeric',
                    },
                  },
                })}
              </Col>
            </Row>
            {/* Row */}
            <Row>
              <Col md={5} className="fw-bold">
                <b>{t(...messages.suppliers())} </b>
              </Col>
              <Col className="text-end">
                {manifacturers?.map(m => (
                  <div key={m.id}>
                    <Link
                      to={`/dashboard/directories/${m?.directory?.id}/general `}
                      target="_blank"
                    >
                      {m.directory?.display_name}
                    </Link>
                  </div>
                ))}
              </Col>
            </Row>

            {/* Row */}
            <Row>
              <Col md={5} className="fw-bold w-50">
                <b>{t(...messages.priceHt())}</b>
              </Col>
              <Col className="text-end">
                {t('numbers.currency', {
                  val: ms?.product_prices.PUBLIC_PRICE.price_ht ?? 0,
                })}
              </Col>
            </Row>

            {/* Row */}
            <Row>
              <Col md={5} className="fw-bold">
                <b>{t(...messages.vat())} </b>
              </Col>
              <Col className="text-end">{vat?.value}</Col>
            </Row>

            {/* Row */}
            <Row>
              <Col md={5} className="fw-bold w-50">
                <b>{t(...messages.priceTtc())}</b>
              </Col>
              <Col>
                <h4 className="mb-0 text-end">
                  <Badge
                    color="success"
                    className=" py-2 px-2 font-size-14"
                    size="md"
                  >
                    {t('numbers.currency', {
                      val: ms?.product_prices.PUBLIC_PRICE.price_ttc ?? 0,
                    })}
                  </Badge>
                </h4>
              </Col>
            </Row>
          </div>
          <hr />
          {/* Row */}
          <Row>
            <Col md={5} className="fw-bold w-50 ">
              <b>{t(...messages.stockAvilable())}</b>
            </Col>
            <Col className="text-end">{variant?.stock_available}</Col>
          </Row>{' '}
          {/* Row */}
          <Row>
            <Col md={5} className="fw-bold  ">
              <b>{t(...messages.stockTotal())} </b>
            </Col>
            <Col className="text-end">{variant?.stock_total}</Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle className="mb-3">
            <h5 className="font-size-15">{t(...messages.slider())}</h5>
          </CardTitle>

          <SliderVariant variant={variant} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default InfoSection;
