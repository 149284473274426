import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {IConclusion} from 'types/project';
import {ResponseData} from 'types/response-data';

const getProjectConclusion = api.injectEndpoints({
  endpoints: builder => ({
    getProjectConclusion: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `projects/${params.id}/conclusion`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<IConclusion>;

export const {useLazyGetProjectConclusionQuery} = getProjectConclusion;
