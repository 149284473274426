import {useState} from 'react';
import {produce} from 'immer';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ObjectId} from 'types/object-id';
import makeId from 'utils/make-id';
import {
  MovementInfo,
  StockMovementRequest,
  useStoreStockMovementMutation,
} from 'store/production/endpoints/store-stock-movement';
import {selectActiveModalParams} from 'store/ui/selectors';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useStockMove = () => {
  const [updateVariant, {isLoading, error, data: response}] =
    useStoreStockMovementMutation();

  const serverError = parseValidationErrors(error);

  const {variantId} = useSelector(selectActiveModalParams);

  const [params, setParams] = useState<Params>({});
  const [movementInfo, setMovementInfo] = useState<
    Record<ObjectId, MovementInfo>
  >({});
  const [deleteRecords, setdeleteRecords] = useState([] as ObjectId[]);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await updateVariant({
      ...params,
      variantId: variantId,
      movement_infos: movementInfo,
    });

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      dispatch(uiActions.dismissModal());
    }
  };

  const addMovementInfo = () => {
    const p = {
      id: makeId(),
      stock_location_start: undefined,
      stock_location_arrival: undefined,
      quantity: undefined,
      comment: undefined,
    };
    setMovementInfo(
      produce(movementInfo, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeMovementInfo = (id: ObjectId) => {
    const _movementInfo: Record<ObjectId, MovementInfo> = Object.assign(
      {},
      movementInfo
    );
    delete _movementInfo[id];
    setMovementInfo({
      ..._movementInfo,
    });
    setdeleteRecords([...deleteRecords, id]);
  };

  const updateMovementInfo = (
    p: MovementInfo,
    k: keyof MovementInfo,
    v: any
  ) => {
    setMovementInfo(
      produce(movementInfo, draft => {
        // @ts-ignore
        draft[p.id][k] = v;
      })
    );
  };
  /*   const initiatePackaging = (p: ProductDimension[]) => {
    let package_dimension = {} as Record<ObjectId, MovementInfo>;
    p.map((p: any) => {
      const d = produce(package_dimension, draft => {
        draft[p.id] = p;
      });
      package_dimension = {...package_dimension, ...d};
    });
    setMovementInfo  (package_dimension);
  }; */

  return {
    addMovementInfo,
    removeMovementInfo,
    setMovementInfo,
    setParams,
    submit: handleSubmit,
    updateMovementInfo,
    movementInfo,
    errorMessage: serverError.message,
    isLoading,
    params,
    statusText: response?.message,
    validationErrors: serverError.errors,
  };
};

type Params = StockMovementRequest;
