import {AuthState} from './types';

export const initialState: AuthState = {
  user: undefined,
  accessToken: undefined,
  fcmToken: undefined,
  resetPassword: {
    resetToken: undefined,
    passwordResetTokenRequested: false,
    passwordResetFinished: false,
    email: undefined,
  },
  google: {
    isGoogleAuthConfigured: false,
  },
  facebook: {
    isCancelled: false,
    grantedPermissions: [],
    declinedPermissions: [],
  },
  socialAuth: {
    isAlreadyRegistered: false,
    isAccountActive: true,
    isEmailHidden: false,
    existingUser: undefined,
    accessToken: undefined,
    id: undefined,
    provider_token: null,
    dob: undefined,
    first_name: '',
    last_name: '',
    email: '',
    gender: null,
    provider_name: undefined,
  },
};
