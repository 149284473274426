import {storeFamilyEndpoint} from '../endpoints/store-family';
import {ProductionState} from '../types';
import {PayloadAction} from '@reduxjs/toolkit';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';
import {WritableDraft} from 'types/writable-draft';

const matcher = storeFamilyEndpoint.matchFulfilled;

const reducer = (
  state: WritableDraft<ProductionState>,
  {payload}: PayloadAction<ResponseData<ProductFamily>>
) => {
  state.latestCreatedFamily = payload.data;
};

export const storeFamily = {
  matcher,
  reducer,
};
