import realDate from './real-date';
import {faker} from '@faker-js/faker';

export const useSeeder = () => {
  const length = () => faker.datatype.number({min: 10, max: 999});
  return {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    phone: () => faker.phone.number('0#########'),
    email: faker.internet.email(''),
    address: faker.address.streetAddress,
    zipCode: faker.address.zipCode('#####'),
    birthDate: realDate(faker.date.birthdate()),
    city: faker.address.city(),
    sentence: faker.lorem.sentence(),
    paragraph: faker.lorem.paragraph(2),
    fileName: faker.system.commonFileName,
    number: faker.datatype.number,
    productName: faker.commerce.productName(),
    companyName: faker.company.name(),
    dimensions: () =>
      `L${length()} x P${length()} ou P${length()} x H${length()} CM`,
    code: faker.random.alphaNumeric(),
  };
};
