import {selectDomain} from '.';
import {costCategories} from '../constants';
import {createSelector} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';
import {ProductCostItem} from 'types/product-cost-item';

export default createSelector([selectDomain], state => {
  const rest = costCategories.filter(x => x !== 'components');
  const allButComponents = rest.reduce((a, c) => {
    return {...a, ...state.fields[c]};
  }, {} as Record<ObjectId, ProductCostItem>);

  const specialValues = Object.values(state.fields.components);
  const allValues = Object.values(allButComponents);

  const eax = specialValues.reduce((a, c) => {
    const lineTotal = Number(c.cost_per_unit) * Number(c.quantity);
    return a + lineTotal;
  }, 0);

  const ebx = allValues.reduce((a, c) => {
    return a + Number(c.total_cost);
  }, 0);
  return ebx + eax;
});
