import React, {FC} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import {Col, Row, Spinner} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {useGetStockLocationsQuery} from 'features/production/store/endpoints/get-stock-locations';
import messages from './messages';
import LocationItem from './location-item/location-item';
interface Props {
  item?: CommercialProduct;
}

const StockAvailabilityTable: FC<Props> = props => {
  const {item} = props;

  const id = item?.variant?.id;

  const {data, isFetching} = useGetStockLocationsQuery(
    {id},
    {refetchOnMountOrArgChange: true}
  );

  const currentLocations = (data?.data ?? [])
    .filter(el => (el.quantity ?? 0) > 0)
    .sort((a, b) => a.name.localeCompare(b.name));

  const {t} = useTranslation();

  return (
    <div>
      <Row>
        <Col>
          <strong>{t(...messages.stockLocation())}</strong>
        </Col>
        <Col md={2}>
          <strong>{t(...messages.quantity())}</strong>
        </Col>

        <Col>
          <strong>{t(...messages.comments())}</strong>
        </Col>
        <Col md={1} />
      </Row>
      {currentLocations.map(l => (
        <LocationItem key={l.id} location={l} />
      ))}
      <div className="d-flex justify-content-center">
        {isFetching && <Spinner size="sm" />}
      </div>
      <hr />
    </div>
  );
};

export default StockAvailabilityTable;
