import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import TransferTable from '../transfer-table/transfer-table';
import messages from './messages';
import useTransferForm from './use-transfer-form';
import Button from 'components/button';

interface Props {
  onDismiss: () => void;
  id?: ObjectId;
  isTransferedToOrder: boolean;
  isTransferedToInvoice: boolean;
}

const TransferQuoteModal: FC<Props> = props => {
  const {onDismiss, id, isTransferedToOrder, isTransferedToInvoice} = props;

  const {t} = useTranslation();

  const {
    itemsLength,
    items,
    updateItem,
    errorMessage,
    isLoading,
    isAllSelected,
    toggleAll,
    submit,
  } = useTransferForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.transfer())}</span>
      </ModalHeader>
      <ModalBody className="">
        <div>{t(...messages.areYouSure())}</div>
        <div>
          <TransferTable
            onToggleAll={toggleAll}
            isAllSelected={isAllSelected}
            items={items}
            onUpdateItem={updateItem}
            id={id}
          />
        </div>
        <div className="text-danger">{t(...messages.cannotEditAnymore())}</div>
        {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={isLoading}
          color="important"
          disabled={itemsLength < 1 || isTransferedToOrder}
          onClick={() => submit('order_client')}
        >
          {t(...messages.toOrder())}
        </Button>
        <Button
          isLoading={isLoading}
          disabled={itemsLength < 1 || isTransferedToInvoice}
          color="important"
          onClick={() => submit('invoice')}
        >
          {t(...messages.toInvoice())}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default TransferQuoteModal;
