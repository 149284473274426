import {api} from 'services/api';
import {CompactDirectory} from 'types/compact-directory';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const dettachContact = api.injectEndpoints({
  endpoints: builder => ({
    dettachContact: builder.mutation<Response, DirectoryComponentsPriceRequest>(
      {
        query: params => ({
          method: 'POST',
          url: `/directories/${params.id}/dettach-contact`,
          body: {
            contact_id: params.contact?.id,
          },
        }),
        // @ts-expect-error
        invalidatesTags: result => {
          if (result) {
            return [{type: 'directories', id: result?.data.id}];
          }
          return [];
        },
      }
    ),
  }),
});

export type DirectoryComponentsPriceRequest = {
  id?: ObjectId;
  contact?: CompactDirectory;
};

type Response = ResponseData<Directory>;

export const {useDettachContactMutation} = dettachContact;
