import confirmRegistrationEndpoint from './confirm-registration';
import forgotPasswordEndpoint from './forgot-password';
import loginEndpoint from './login';
import refreshEndpoint from './refresh';
import registerEndpoint from './register';
import resetPasswordEndpoint from './reset-password';
import verifyIdentityEndpoint from './verify-identity';
import verifyPasswordResetTokenEndpoint from './verify-password-reset-token';
import {api} from 'services/api';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    confirmRegistration: confirmRegistrationEndpoint(builder),
    forgotPassword: forgotPasswordEndpoint(builder),
    login: loginEndpoint(builder),
    refresh: refreshEndpoint(builder),
    register: registerEndpoint(builder),
    resetPassword: resetPasswordEndpoint(builder),
    verifyIdentity: verifyIdentityEndpoint(builder),
    verifyPasswordResetToken: verifyPasswordResetTokenEndpoint(builder),
  }),
  overrideExisting: false,
});

export const {
  useConfirmRegistrationMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useRefreshQuery,
  useRegisterMutation,
  useResetPasswordMutation,
  useVerifyIdentityMutation,
  useVerifyPasswordResetTokenMutation,
} = authApi;
