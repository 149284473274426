import Stocklocation from 'features/stock/types/stock-location';
import {StockProduct} from 'features/stock/types/stock-products';
import {api} from 'services/api';
import {StockAttachment} from 'types/attachements';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateStockProduct = api.injectEndpoints({
  endpoints: builder => ({
    updateStockProduct: builder.mutation<Response, updateStockProductRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `stock/products/control-product`,
          body: {
            id: params.id,
            serial_number: params.serial_number,
            validated: params.validated === 'VALIDATED' ? true : false,
            stock_sub_location_id: params.stock_sub_location?.id,
            comment: params.comment,
            reason: params.invalid_reason?.value,
          },
        };
      },
    }),
  }),
});

export type updateStockProductRequest = {
  id: ObjectId;
  serial_number: string;
  stock_sub_location?: Option;
  comment?: string;
  invalid_reason?: Option;
  validated?: string;
};

type Response = ResponseData<StockProduct>;

export const {useUpdateStockProductMutation} = updateStockProduct;
