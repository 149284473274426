import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockMovementModal.title, 'Stock movement'),
  stockLocation: () => __(translations.stockMovementModal.stockLocation, 'Stock location'),
  from: () => __(translations.stockMovementModal.from, 'From'),
  to: () => __(translations.stockMovementModal.to, 'To'),
  quantity: () => __(translations.stockMovementModal.quantity, 'Quantity'),
  comments: () => __(translations.stockMovementModal.comments, 'Comments'),
  addMovement: () => __(translations.stockMovementModal.addMovement, 'Add Movement'),
  moveIn: () => __(translations.stockMovementModal.moveIn, 'In'),
  moveOut: () => __(translations.stockMovementModal.moveOut, 'Out'),
  clickToEdit: () => __(translations.stockMovementModal.clickToEdit, 'Click to edit'),
};
