import React, {FC, useState} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import showModal from 'store/ui/actions/show-modal';
import {ObjectId} from 'types/object-id';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Quotation} from 'types/quotation';
import {useUpdateStatusQuotationMutation} from 'features/commercial/quotations/store/endpoints/action-quotation-validate';
import toast from 'store/ui/actions/toast';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import Can from 'features/hylian-shield/can';
import {Subject} from 'features/hylian-shield/types';

interface Props {
  quotation: Quotation;
}

const QuotationActionTab: FC<Props> = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {quotation} = props;
  const [updateStatusQuotation, {isLoading, error}] =
    useUpdateStatusQuotationMutation();
  const serverError = parseValidationErrors(error);
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const {id} = useParams();

  const handleDuplicat = () => {
    dispatch(
      showModal(MODAL.QUOTATION_DUPLICAT_ACTIONS_MODAL, {
        id: id,
        message: t(...messages.duplicateAlertMessage()),
        onSuccess: (id: ObjectId) => {
          navigate(`/dashboard/quotations/${id}/details`);
        },
      })
    );
  };
  const handleArchive = () => {
    dispatch(
      showModal(MODAL.QUOTATION_ARCHIVE_ACTIONS_MODAL, {
        id: id,

        message: t(...messages.archiveAlertMessage()),
      })
    );
  };

  const handleSendEmail = () => {
    dispatch(
      showModal(MODAL.QUOTATION_SEND_EMAIL_ACTIONS_MODAL, {
        id: id,
      })
    );
  };

  const handleValidation = async () => {
    const result = await updateStatusQuotation({id: id});
    if ('error' in result) {
      dispatch(toast('error', serverError.message));
    } else if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const isTransferedToOrder = !!(
    quotation.related_documents?.filter(el => el.model_type === 'Order')
      .length ?? 0
  );
  const isTransferedToInvoice = !!(
    quotation.related_documents?.filter(el => el.model_type === 'Invoice')
      .length ?? 0
  );

  const isTransfered = isTransferedToOrder && isTransferedToInvoice;

  const handleTransfer = () => {
    dispatch(
      showModal(MODAL.TRANSFER_QUOTE, {
        id,
        isTransferedToOrder,
        isTransferedToInvoice,
      })
    );
  };

  return (
    <div>
      <AppBarActions>
        <div className="order-2 d-flex align-items-center ">
          <Button
            onClick={handleValidation}
            isLoading={isLoading}
            color="light mx-2"
          >
            {quotation.status === 'VALIDATED' ? (
              <>
                <Icon name="unlock" size={15} className="me-2" />
                {t('common.unlock')}
              </>
            ) : (
              <>
                <Icon name="lock" size={15} className="me-2" />
                {t('common.validate')}
              </>
            )}
          </Button>

          <Can
            subject={
              quotation.commercial_type?.code?.toLocaleLowerCase() as Subject
            }
            action="transfer"
          >
            <Button
              color="light"
              onClick={handleTransfer}
              disabled={isTransfered}
              className="me-2"
            >
              <Icon name="share" size={15} className="me-2" />
              {t(...messages.transfer())}
            </Button>
          </Can>

          <Can subject={['dcv', 'dcz']} action="export">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle className="btn-light me-2" caret>
                {t('commercialDocumentComponents.print')}
                <Icon name="chevron-down" className="ms-1" size={15} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem target={'_blank'} href={quotation?.pdf_links?.fr}>
                  {t('commercialDocumentComponents.printFrenchVersion')}
                </DropdownItem>
                <DropdownItem target={'_blank'} href={quotation?.pdf_links?.en}>
                  {t('commercialDocumentComponents.printEnglishVersion')}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Can>

          <Button
            color="light"
            className="text-nowrap "
            onClick={handleDuplicat}
          >
            <Icon name="copy" size={15} className="me-2" />
            {t('common.duplicat')}
          </Button>

          <Button color="light" onClick={handleSendEmail} className="ms-2">
            <Icon name="send" size={15} className="me-2" />
            {t(...messages.sendEmailQuotation())}
          </Button>

          <Button color="light" onClick={handleArchive} className="ms-2">
            <Icon name="folder" size={15} className="me-2" />
            {t(...messages.archiveQuotation())}
          </Button>
        </div>
      </AppBarActions>
    </div>
  );
};

export default QuotationActionTab;
