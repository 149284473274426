import React from 'react';
import EmptyCell from 'components/empty-cell';
import FormattedDate from 'components/formatted-date';
import {Trans} from 'react-i18next';
import {ColumnDef} from '@tanstack/react-table';
import {
  StockMovement,
  StockMovementCommercialDocument,
} from 'types/stock-movement';
import {Link} from 'react-router-dom';

const columns: ColumnDef<StockMovement>[] = [
  {
    header: r => <Trans i18nKey="stockMovementList.dateStockMovement" />,
    accessorFn: row => (
      <FormattedDate format="datetime" value={row.created_at} />
    ),
    cell: r => r.getValue(),
    id: 'created_at',
    enableSorting: false,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="stockMovementList.type" />,
    accessorFn: row => <EmptyCell cell={row?.type} />,
    cell: r => r.getValue(),
    id: 'type',
    enableSorting: false,
    meta: {costumClass: 'font-size-14'},
  },

  {
    header: r => <Trans i18nKey="stockMovementList.stockLocationStart" />,
    enableSorting: false,
    cell: r => r.getValue(),
    id: 'stock_location_start_name',
    accessorFn: row => <EmptyCell cell={row.stock_location_start_name} />,
    meta: {costumClass: 'font-size-14', size: '200px'},
  },
  {
    header: r => <Trans i18nKey="stockMovementList.stockLocationArrival" />,
    enableSorting: false,
    cell: r => r.getValue(),
    id: 'stock_location_arrival_name',
    accessorFn: row => <EmptyCell cell={row.stock_location_arrival_name} />,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="stockMovementList.quantity" />,
    enableSorting: false,
    cell: r => r.getValue(),
    id: 'quantity',
    accessorFn: row => <EmptyCell cell={row.quantity} />,
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="stockMovementList.comments" />,
    enableSorting: false,
    cell: r => r.getValue(),
    id: 'comment',
    accessorFn: row => (
      <div>
        {row.comment && <p>{row.comment}</p>}
        <Link target="_blank" to={generateLinks(row.commercial_document)}>
          {row.commercial_document?.ref}
        </Link>
      </div>
    ),
    meta: {costumClass: 'font-size-14'},
  },
  {
    header: r => <Trans i18nKey="stockMovementList.user" />,
    enableSorting: false,
    cell: r => r.getValue(),
    id: 'user',
    accessorFn: row => <EmptyCell cell={row.user?.profile?.full_name} />,
    meta: {costumClass: 'font-size-14'},
  },
];

export default columns;

const generateLinks = (
  commercial_document: StockMovementCommercialDocument
) => {
  switch (commercial_document?.type) {
    case 'order':
      return `/dashboard/orders/${commercial_document?.id}/details`;
    case 'invoice':
      return `/dashboard/invoices/${commercial_document?.id}/details`;
    default:
      return '/dashboard';
  }
};
