import Breadcrumb from 'components/Common/Breadcrumb';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {ButtonGroup, Card, CardBody, Col, Container, Row} from 'reactstrap';
import messages from './messages';
import {useLocation, useNavigate} from 'react-router';
import Button from 'components/button';
import MonitoringRoutes from '../monitoring-routes/monitoring-routes';
import monitoringRoute from '../../constants/monitoring-route';

const R = monitoringRoute;

const MonitoringMasterDetailRouter = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Row className="calendar-no-print">
          <React.Fragment>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div>
                      <ButtonGroup>
                        <Button
                          color="light"
                          outline
                          onClick={() => navigate(R.LISTING)}
                          active={location.pathname.includes(R.LISTING)}
                        >
                          {t(...messages.listing())}
                        </Button>
                        <Button
                          color="light"
                          outline
                          onClick={() => navigate(R.CALENDAR)}
                          active={location.pathname.includes(R.CALENDAR)}
                        >
                          {t(...messages.calendar())}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        </Row>
        <MonitoringRoutes />
      </Container>
    </div>
  );
};

export default MonitoringMasterDetailRouter;
