import {selectProject} from 'features/studio/projects/store/show-project';
import {
  StoreProjectCommissionsRequest,
  useStoreProjectCommissionsMutation,
} from 'features/studio/projects/store/store-project-commissions';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import toast from 'store/ui/actions/toast';
import {ObjectId} from 'types/object-id';
import {ICommission} from 'types/project';
import {
  HandleAddRow,
  HandleChangeRow,
  HandleRemoveRow,
  HandleSelectInvoice,
  HandleSubmit,
  SetParams,
} from './types';
import makeId from 'utils/make-id';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ValidationErrors} from 'services/api/types';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {Subject} from 'features/hylian-shield/types';

export const useCommissionsForm = (): ICommissionsForm => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [params, setParams] = useState<Params>({
    commissions: [],
    deleted_records: [],
    commissions_global_comment: '',
  });

  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const subject = useCurrentSubject(project?.project_type);

  useEffect(() => {
    if (!project) return;

    const commissions = project.commissions.map(el => {
      return {
        ...el,
        billing_date: el.invoice ? el.invoice.billing_date : '',
        payment_date: el.invoice
          ? el.invoice.payment_summary?.last_payment_date
          : '',
      };
    });

    setParams({commissions} as Params);
  }, []);

  const [storeCommissions, {isLoading: isSubmitting, error}] =
    useStoreProjectCommissionsMutation();

  const serverError = parseValidationErrors(error);

  const shapePayload = (data: Params): StoreProjectCommissionsRequest => {
    const {deleted_records, commissions_global_comment} = data;

    const commissions = data.commissions.map(el => {
      return {
        ...el,
        invoice_id: el.invoice ? el.invoice?.id : '',
      };
    });

    return {
      id,
      commissions,
      commissions_global_comment,
      deleted_records,
    } as StoreProjectCommissionsRequest;
  };

  const handleAddRow: HandleAddRow = () => {
    const newCommissions = [...params.commissions];

    newCommissions.push({
      id: makeId(),
      total_ht: 0,
      commission: '',
    } as ICommission);

    setParams({...params, commissions: newCommissions});
  };

  const handleChangeRow: HandleChangeRow = (index, key, value) => {
    const updatedCommission = [...params.commissions];

    updatedCommission[index] = {
      ...updatedCommission[index],
      [key]: value,
    };

    setParams({...params, commissions: updatedCommission});
  };

  const handleRemoveRow: HandleRemoveRow = index => {
    const updatedCommission = [...params.commissions];
    const deleted_records = [...(params.deleted_records ?? [])];

    if (project?.commissions.find(el => el.id === updatedCommission[index].id))
      deleted_records.push(updatedCommission[index].id);

    updatedCommission.splice(index, 1);

    setParams({
      commissions: updatedCommission,
      deleted_records,
    } as Params);
  };

  const handleSelectInvoice: HandleSelectInvoice = (invoice, index) => {
    const updatedCommission = [...params.commissions];

    updatedCommission[index] = {
      ...updatedCommission[index],
      total_ht: invoice?.marge_infos?.selling_price_ht ?? 0,
      billing_date: invoice?.billing_date ?? '',
      payment_date: invoice?.payment_summary?.last_payment_date ?? '',
      supplier: invoice?.client_name ?? '',
      products: invoice?.commercial_products
        ? invoice.commercial_products.map(el => el.custom_name).join(' | ')
        : '',
      invoice,
    };

    setParams({...params, commissions: updatedCommission});
  };

  const handleSubmit: HandleSubmit = async () => {
    const result = await storeCommissions(shapePayload(params));

    if ('data' in result) {
      const {commissions, commissions_global_comment} = result.data.data;

      // set missing datas
      const _commissions = commissions.map(el => {
        return {
          ...el,
          billing_date: el.invoice?.billing_date ?? '',
          payment_date: el.invoice?.payment_summary?.last_payment_date ?? '',
        };
      });

      setParams({
        commissions: _commissions,
        commissions_global_comment,
      } as Params);
      dispatch(toast('success', t('common.changesCommited')));
    }
  };

  const totalHT = useMemo(() => {
    return params.commissions.reduce(
      (acc, item) => acc + Number(item?.total_ht ?? 0),
      0
    );
  }, [params.commissions]);

  return {
    params,
    isSubmitting,
    totalHT,
    validationErrors: serverError.errors,
    subject,
    setParams,
    handleAddRow,
    handleChangeRow,
    handleRemoveRow,
    handleSelectInvoice,
    submit: handleSubmit,
  };
};

export default interface ICommissionsForm {
  params: Params;
  isSubmitting: boolean;
  totalHT: number;
  validationErrors: ValidationErrors;
  subject: Subject;
  setParams: SetParams;
  handleAddRow: HandleAddRow;
  handleChangeRow: HandleChangeRow;
  handleRemoveRow: HandleRemoveRow;
  handleSelectInvoice: HandleSelectInvoice;
  submit: HandleSubmit;
}

export type Params = {
  commissions: ICommission[];
  commissions_global_comment?: string;
  deleted_records: ObjectId[];
};
