import React from 'react';
import './loader.scss';
import logo from 'assets/images/india-logo.svg';
import {isMobile} from 'utils/is-mobile';

const LoadingPage = () => {
  return (
    <React.Fragment>
      <div className="bg-light-subtle vh-100 py-5">
        <div className=" h-100  w-100 text-center d-flex align-items-center justify-content-center">
          <img
            style={{
              position: 'relative',
              width: isMobile() ? '100px' : '185px',
              height: isMobile() ? '130px' : '215px',
              left: '-5px',
            }}
            src={logo}
            className="me-1 App-logo"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingPage;
