import React, {useMemo, useState} from 'react';
import SupplierItem from './supplier-item';
import {useGetSupplierGroupsQuery} from '../../store/endpoints/get-suppliers';
import {Alert, Collapse} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useSelector} from 'react-redux';
import selectSelectedSuppliersCount from '../../store/selectors/select-selected-suppliers-count';
import {actions} from '../../store';
import {useDispatch} from 'react-redux';
import {CalendarSupplier} from 'features/stock/types/calendar-supplier';
import {uniqBy} from 'lodash';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import messages from './messages';

const SuppliersList = () => {
  const {data, isFetching, error, refetch} = useGetSupplierGroupsQuery(
    undefined,
    {refetchOnMountOrArgChange: true}
  );

  const groups = data?.data ?? [];

  const [expandId, setExpandId] = useState(0);

  const {message} = parseValidationErrors(error);

  const countSelected = useSelector(selectSelectedSuppliersCount);

  const dispatch = useDispatch();

  const distinct = useMemo(() => {
    const suppliersWithDuplicates = groups.reduce((a, c) => {
      return [...a, ...c.suppliers];
    }, [] as CalendarSupplier[]);

    return uniqBy(suppliersWithDuplicates, 'id');
  }, [groups]);

  const isAllSelected = countSelected === distinct.length;

  const handleToggleAll = () => {
    if (countSelected === distinct.length) {
      dispatch(actions.unselectAll());
    } else {
      dispatch(actions.selectAllOf(distinct));
    }
  };

  const {t} = useTranslation();

  return (
    <div className="calendar-no-print">
      <div>{!!message && <Alert color="danger">{message}</Alert>}</div>
      <div>
        <div className="mb-2 d-flex align-items-center">
          <Button color="light" size="sm" onClick={handleToggleAll}>
            <Icon name={isAllSelected ? 'check-square' : 'square'} size={15} />
          </Button>
          <Button
            color="light"
            size="sm"
            onClick={refetch}
            className="ms-2"
            isLoading={isFetching}
          >
            {!isFetching && <Icon name="refresh-cw" size={15} />}
          </Button>
          <span className="flex-grow-1" />
          <span>
            {t(...messages.selected(), {
              items: countSelected,
              total: distinct.length,
            })}
          </span>
        </div>
      </div>
      <ListGroup className="list-group">
        {groups.map((group, index) => (
          <div key={group.id}>
            <div
              className="mt-2"
              role="button"
              onClick={() => {
                if (expandId === index) {
                  setExpandId(-1);
                } else {
                  setExpandId(index);
                }
              }}
            >
              <b>{`${group.name}`}</b>
              <sup>{` (${group.suppliers.length})`}</sup>
            </div>
            <Collapse
              isOpen={expandId === index}
              color="important"
              toggler={`id-toggler--${group.id}`}
            >
              {group.suppliers.map(supplier => (
                <SupplierItem key={supplier.id} supplier={supplier} />
              ))}
            </Collapse>
          </div>
        ))}
      </ListGroup>
    </div>
  );
};

export default SuppliersList;

const ListGroup = styled.div`
  padding-top: 16px;
`;
