import React from 'react';
import Icon from '@ailibs/feather-react-ts';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import messages from '../../messages';
import useExportsForm from './use-exports-form';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import {useParams} from 'react-router-dom';
import Can from 'features/hylian-shield/can';

const ExportsSection = () => {
  const {t} = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {handleExport, isLoading} = useExportsForm();

  const {id} = useParams();

  const project = useSelector(selectProject({id})).data?.data;

  const subject = useCurrentSubject(project?.project_type);

  return (
    <Dropdown
      disabled={isLoading}
      className="ms-2"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
    >
      <DropdownToggle color="light">
        {isLoading ? <Spinner size={'sm'} /> : t(...messages.exports())}
        <Icon name="chevron-down" size={15} className="ms-1" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled={isLoading}
          onClick={() => handleExport('contacts')}
        >
          {t(...messages.contacts())}
        </DropdownItem>
        <Can action="invoicing" subject={subject}>
          <DropdownItem
            disabled={isLoading}
            onClick={() => handleExport('phases')}
          >
            {t(...messages.phases())}
          </DropdownItem>
        </Can>
        <Can action="invoicing" subject={subject}>
          <DropdownItem
            disabled={isLoading}
            onClick={() => handleExport('mandays')}
          >
            {t(...messages.manDays())}
          </DropdownItem>
        </Can>
        <Can action="invoicing" subject={subject}>
          <DropdownItem
            disabled={isLoading}
            onClick={() => handleExport('commissions')}
          >
            {t(...messages.commissions())}
          </DropdownItem>
        </Can>
        <Can action="invoicing" subject={subject}>
          <DropdownItem
            disabled={isLoading}
            onClick={() => handleExport('sourcingfees')}
          >
            {t(...messages.sourcingFees())}
          </DropdownItem>
        </Can>
        <DropdownItem
          disabled={isLoading}
          onClick={() => handleExport('sells-ffe')}
        >
          {t(...messages.sellsFFE())}
        </DropdownItem>
        <DropdownItem
          disabled={isLoading}
          onClick={() => handleExport('sells-vintage')}
        >
          {t(...messages.vintage())}
        </DropdownItem>
        <Can action="invoicing" subject={subject}>
          <DropdownItem
            disabled={isLoading}
            onClick={() => handleExport('conclusion')}
          >
            {t(...messages.conclusion())}
          </DropdownItem>
        </Can>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportsSection;
