import React from 'react';
import EcotaxActions from './ecotax-actions';
import StatusBadge from 'components/status-badge';
import {EcoTax} from 'features/acounting/types/eco-tax';
import {Trans} from 'react-i18next';
import { ColumnDef } from '@tanstack/table-core';

const columns: ColumnDef<EcoTax>[] = [
  {
    header : r => <Trans i18nKey="ecoTaxPage.customsCode" />,
    accessorFn: row => row.customs_code,
    cell: r => r.getValue(),

    id: 'customs_code',
    enableSorting: true,
  },
  {
    header : r => <Trans i18nKey="ecoTaxPage.ecopartCode" />,
    accessorFn: row => row.ecopart_code,
    cell: r => r.getValue(),
    id: 'ecopart_code',
    enableSorting: true,
  },
  {
    header : r => <Trans i18nKey="ecoTaxPage.ecopartRate" />,
    accessorFn: row => row.ecopart_rate,
    id: 'ecopart_rate',
    cell: r => r.getValue(),
    enableSorting: true,
  },
  {
    header : r => <Trans i18nKey="ecoTaxPage.productCode" />,
    accessorFn: row => row.product_code,
    id: 'product_code',
    enableSorting: true,
    cell: r=>r.getValue(),
  },
  {
    header : r => <Trans i18nKey="ecoTaxPage.status" />,
    accessorFn: row => <StatusBadge status={row.status} />,
    id: 'status',
    enableSorting: true,
    cell: r => r.getValue(),
  },
  {
    header : r => <Trans i18nKey="productsPage.action" />,
    accessorFn: row => <EcotaxActions ecotaxData={row} />,
    id: 'action',
    enableSorting: false,
    cell: r => r.getValue(),
  },
];

export default columns;
