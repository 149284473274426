import React, {FC, Fragment, useMemo} from 'react';
import messages from './messages';
import makePricingModalColumns from './situation-columns';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Pricing} from 'types/pricing';
import Table from 'features/table';

interface Props {
  row?: Pricing;
  onDismiss: () => void;
}

const PricingPreviewModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {variant_id} = useSelector(selectActiveModalParams) as Pricing;

  const pricincolumns = useMemo(() => makePricingModalColumns(), []);
  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.title())}</span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Table
            columns={pricincolumns}
            url={`prices/${variant_id}/situations`}
            name="situations"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default PricingPreviewModal;
