import {useState, useEffect} from 'react';
import {useCurrentInvoice} from '../invoice-context/invoice-context';
import {
  UpdateInvoiceRequest,
  useUpdateInvoiceMutation,
} from 'features/commercial/invoices/store/endpoints/update-invoice';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useInvoiceGeneralInformationForm = () => {
  const [UpdateInvoice, {isLoading, error, isSuccess}] =
    useUpdateInvoiceMutation();
  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await UpdateInvoice(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };
  const [params, setParams] = useState<Params>({
    id: undefined,
    client: undefined,
    client_reference: undefined,
    document_category: undefined,
    commercial_type: undefined,
    project: undefined,
    origin: undefined,
    language: undefined,
    currency: undefined,
    document_type: 'DEFAULT',
    billing_date: undefined,
    due_date: undefined,
    delivery_date: undefined,
    is_after_sale_service: undefined,
    private_comment: undefined,
    // VAT and taxes
    vat_number: undefined,
    vat_mode: undefined,
    with_vat: false,
    is_detaxed: false,
    // transporter
    transporter: undefined,
    transporter_price: undefined,
  });

  const invoice = useCurrentInvoice();

  useEffect(() => {
    if (invoice) {
      setParams({
        id: invoice.id,
        client: invoice.directory,
        client_display_name: invoice.directory?.display_name,
        client_reference: invoice.ref_client,
        document_category: invoice.document_category,
        commercial_type: invoice.commercial_type,
        project: invoice.project,
        origin: invoice.origin,
        language: invoice.language,
        currency: invoice.currency,
        document_type: invoice.document_type,
        billing_date: invoice.billing_date,
        due_date: invoice.due_date,
        delivery_date: invoice.delivery_date,
        is_after_sale_service: invoice.is_after_sale_service,
        currency_conversion: invoice.currency_conversion,
        private_comment: invoice.private_comment,
        projectFeesType: invoice.project_fee_type,
        designation: invoice.designation,
        // VAT and taxes
        vat_number: invoice.vat_number,
        vat_mode: invoice.vat_mode,
        with_vat: invoice.tax_mode === 'NO-VAT',
        is_detaxed: invoice.tax_mode === 'DETAXED',
        // transporter

        transporter: invoice.transporter,
        transporter_price: invoice.transporter_price,
      });
    }
  }, [invoice]);
  const isSubTypeVisible = params.commercial_type?.code === 'H';

  const isCurrencyVisible =
    params.commercial_type?.code === 'H' ||
    params.commercial_type?.code === 'C';

  return {
    setParams,
    params,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
    isCurrencyVisible,
    isSubTypeVisible,
    submit: handleSubmit,
  };
};

type Params = UpdateInvoiceRequest;
