import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {ObjectId} from 'types/object-id';

export default createAction(
  actions.deleteOptionsById.type,
  function prepare(id: ObjectId) {
    return {
      payload: id,
    };
  }
);
