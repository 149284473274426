import {authApi} from '../endpoints';
import {AuthState, OAuthResult} from '../types';
import {AnyAction, PayloadAction} from '@reduxjs/toolkit';
import {AuthResponse} from 'services/api/response-types';
import {WritableDraft} from 'types/writable-draft';

const verifyIdentityMatcher = authApi.endpoints.verifyIdentity.matchFulfilled;

/**
 * Checks whether the user is already a member
 *
 */
const verifyIdentityReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<AuthResponse & OAuthResult> | AnyAction
) => {
  state.socialAuth.accessToken = payload.token;
  state.socialAuth.existingUser = payload.data;
  state.socialAuth.isAlreadyRegistered = payload.is_already_registered;
  state.socialAuth.isAccountActive = payload.account_is_active;
};

export const verifyIdentity = {
  matcher: verifyIdentityMatcher,
  reducer: verifyIdentityReducer,
};
