import React, {FC} from 'react';
import PermissionTab from '../permission';
import ProfileSection from '../profile-section';
import {Routes, Route, Navigate} from 'react-router';

interface Props {
  user: any;
}

const ShowUserRoutes: FC<Props> = props => {
  const {user} = props;
  return (
    <Routes>
      <Route path="/profile" element={<ProfileSection user={user} />} />
      <Route path="/permission" element={<PermissionTab user={user} />} />
      <Route path="/" element={<Navigate to="/profile" />} />
    </Routes>
  );
};

export default ShowUserRoutes;
