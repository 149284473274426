import {subMilliseconds} from 'date-fns';

/**
 * Converts a date object to iso string date but does not
 * add/subtract any hours/days or minutes
 *
 */
export default (dateLike: Date) => {
  if (!dateLike) {
    return undefined;
  }

  const timeZoneOffset = dateLike.getTimezoneOffset() * 60000;

  const realDateTime = subMilliseconds(dateLike, timeZoneOffset);

  const localIsoTime = new Date(realDateTime).toISOString();

  return localIsoTime;
};
