import React, {FC, Fragment} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Badge} from 'reactstrap';
import selectFiscalSettings from 'store/global/selectors/select-fiscal-settings';
import {Link} from 'react-router-dom';

interface Props {
  item: CommercialProduct;
}

const ItemMemos: FC<Props> = props => {
  const {item} = props;

  const fiscalSettings = useSelector(selectFiscalSettings);

  return (
    <Fragment>
      {/* eco tax memo */}
      {!!item.variant?.ecotax && (
        <p className="font-size-14 text-muted my-0 py-2 pb-0">
          {item?.variant?.ecotax?.name}{' '}
          <Badge className="py-1 px-2" color="info">
            <Trans
              i18nKey="numbers.currency"
              values={{val: item?.variant?.ecotax?.price}}
            />
          </Badge>
        </p>
      )}
      {/* vat memo */}
      {item.vat?.value === 0 && (
        <p className="font-size-14 text-muted my-0 pb-2">
          {fiscalSettings.invoice_vintage_phrase}
        </p>
      )}
      <code>
        {item?.has_label ? (
          <Link to={`${item?.pdf_links?.label ?? '#'}`} target="_blank">
            {item?.variant?.sku}
          </Link>
        ) : (
          item.variant?.sku
        )}
      </code>
    </Fragment>
  );
};

export default ItemMemos;
