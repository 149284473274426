import React, {FC} from 'react';
import {Button, CardHeader, CardTitle, UncontrolledTooltip} from 'reactstrap';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import IManDaysForm from '../use-man-days-form';
import {HelpCircle} from 'react-feather';
import Currency from 'components/currency/currency';
import Can from 'features/hylian-shield/can';

const HeaderSection: FC<Props> = ({
  handleAddManDay,
  project,
  totals,
  subject,
}) => {
  const {t} = useTranslation();

  const {
    artistic_director_mandays_rate,
    others_mandays_rate,
    artistic_director_mandays_cost,
    assistant_mandays_cost,
    project_manager_mandays_cost,
  } = project;

  const {consumed, remain} = totals;

  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <div>
          <CardTitle tag="h5" className="mb-4">
            {t(...messages.manDays())}
          </CardTitle>
          <Can action="edit" subject={subject}>
            <Button color="important" onClick={handleAddManDay}>
              {t(...messages.addManday())}
            </Button>
          </Can>
        </div>
        <div>
          <CardTitle tag="h5" className="mb-4">
            {t(...messages.manDaysIncludedInContract())}
          </CardTitle>
          <p>
            India: <strong>{artistic_director_mandays_rate ?? 0}</strong>
          </p>
          <p>
            {t(...messages.others())}:{' '}
            <strong>{others_mandays_rate ?? 0}</strong>
          </p>
        </div>
        <div>
          <CardTitle tag="h5" className="mb-4">
            {t(...messages.manDaysConsumed())}
          </CardTitle>
          <p>
            India: <strong>{consumed.artistic_director}</strong>
          </p>
          <p>
            {t(...messages.others())}: <strong>{consumed.others}</strong>
          </p>
        </div>
        <div>
          <CardTitle tag="h5" className="mb-4">
            {t(...messages.manDaysRemaining())}
          </CardTitle>
          <p>
            India:{' '}
            <strong
              className={remain.artistic_director <= 2 ? 'text-danger' : ''}
            >
              {remain.artistic_director}
            </strong>
          </p>
          <p>
            {t(...messages.others())}:{' '}
            <strong className={remain.others <= 2 ? 'text-danger' : ''}>
              {remain.others}
            </strong>
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div id="tooltip-scale">
          <span className="text-primary text-decoration-underline">
            {t(...messages.scale())}
          </span>
          <HelpCircle className="text-primary mb-2" size={10} />
        </div>
        <UncontrolledTooltip target="tooltip-scale" placement="top-start">
          <h6 className="text-white">{`${t(...messages.scale())} ${t(
            ...messages.manDays()
          )}`}</h6>
          <div className="text-start">
            <p>
              India : <Currency value={artistic_director_mandays_cost} />
            </p>
            <p>
              {`${t(...messages.projectManager())} : `}{' '}
              <Currency value={project_manager_mandays_cost} />
            </p>
            <p>
              {`${t(...messages.assistant())} : `}{' '}
              <Currency value={assistant_mandays_cost} />
            </p>
          </div>
        </UncontrolledTooltip>
      </div>
    </CardHeader>
  );
};

type Props = IManDaysForm;

export default HeaderSection;
