import {costCategories} from './constants';
import {CostCategoryFieldMap, CostsFormState} from './types';

export const makeEmptyFields = () => {
  return costCategories.reduce((a, c) => {
    a[c] = {} as CostCategoryFieldMap;
    return a;
  }, {} as CostsFormState['fields']);
};

// initial state
export const initialState: CostsFormState = {
  currentSituation: undefined,
  situationFields: {
    name: '',
    id: undefined,
  },
  lines: costCategories,
  fields: makeEmptyFields(),
  totalFields: {
    decorator_price_discount: 0,
    decorator_price: 0,
    public_price: 0,

    reseller_price_discount: 0,
    reseller_price: 0,
    deleted_records: {},
  },
  coefficients: {
    public: undefined,
    decorator: undefined,
    reseller: undefined,
  },
};
