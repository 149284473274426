import {api} from 'services/api';
import {Color} from 'types/colors';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const storeColor = api.injectEndpoints({
  endpoints: builder => ({
    storeColor: builder.mutation<Response, StoreColorRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `colors`,
          body: {
            name: {
              en: params.name.en,
              fr: params.name.fr,
            },
            code: params.code,
          },
        };
      },
    }),
  }),
});

export type StoreColorRequest = {
  id?: ObjectId;
  name: {en: string; fr: string};
  code: any;
};

type Response = ResponseData<Color>;

export const {useStoreColorMutation} = storeColor;
