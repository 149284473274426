import React, {FC, useContext} from 'react';
import EmptyLabel from '../../components/spacing/empty-label';
import removeItem from '../../store/actions/remove-item';
import updateItem from '../../store/actions/update-item';
import {CommercialProduct} from '../../types/commercial-product';
import invoiceContext from '../document-context/document-context';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Col, Row} from 'reactstrap';
import ItemRemovalButton from '../../components/item-removal-button/item-removal-button';

interface Props {
  item: CommercialProduct;
  index: number;
  dragHandleProps?: any;
}

const ItemSubtitle: FC<Props> = props => {
  const {item, dragHandleProps} = props;

  const line = item;

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(line, key, v));
  };

  const handleRemoveLine = () => {
    dispatch(removeItem(line.id));
  };

  const {validationErrors, is_locked} = useContext(invoiceContext);

  const {t} = useTranslation();

  return (
    <div>
      <Row className="pb-3">
        <Col md="auto">
          <Button
            color="link"
            size="sm"
            className="dragHandle p-0"
            disabled={is_locked}
            {...dragHandleProps}
          >
            <Icon name="menu" color="gray" size={20} />
          </Button>
        </Col>
        <Col>
          <TextInput
            label={t(...messages.subtitle())}
            disabled={is_locked}
            errors={validationErrors[`products.${line.order}.custom_name`]}
            value={line.custom_name}
            onChange={p => handleUpdate('custom_name', p)}
          />
        </Col>
        <Col md="auto" className="text-end align-items-center d-flex">
          <EmptyLabel />
          <ItemRemovalButton item={item} />
        </Col>
      </Row>
    </div>
  );
};

export default ItemSubtitle;
