import {updateCachedQuotationFromResource} from './show-quotation';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const editQuotation = api.injectEndpoints({
  endpoints: builder => ({
    editQuotation: builder.mutation<Response, EditQuotationRequest>({
      query: params => {
        const body: Partial<PartialEditQuotationTaxesRequest> = {};

        let taxMode: Quotation['tax_mode'] = 'NO-VAT-NO-DETAX';

        if (!params.with_vat && params.is_detaxed) {
          taxMode = 'DETAXED';
        }
        if (params.with_vat && !params.is_detaxed) {
          taxMode = 'NO-VAT';
        }

        if (taxMode === 'DETAXED' || taxMode === 'NO-VAT-NO-DETAX') {
          body.vat_number = params.vat_number;
          body.vat_mode = params.vat_mode?.value;
        }

        return {
          method: 'PUT',
          url: `quotations/${params.id}`,
          body: {
            directory_id: params.client?.id,
            commercial_type_id: params.commercial_type?.id,
            document_category_id: params.document_category?.id,
            origin_id: params.origin?.id,
            language: params.language?.value,
            due_date: params.deadline,
            created_at: params.created_at,
            ref_client: params?.client_reference,
            project_id: params?.project?.id,
            currency: params.currency?.value,
            production_declared_completion_date:
              params.production_declared_completion_date,
            public_comment: params?.public_comment,
            private_comment: params?.private_comment,
            deadline_announced_fr: params?.deadline_announced_fr,
            deadline_announced_en: params?.deadline_announced_en,
            production_requested_deposit: params?.production_requested_deposit,
            // Transporter
            transporter_id: params.transporter?.id,
            transporter_name: params.transporter?.display_name,
            transporter_price: params.transporter_price,

            // vat and taxes
            ...body,
            tax_mode: taxMode,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const q = (await queryFulfilled).data?.data;
          dispatch(updateCachedQuotationFromResource(q));
        } catch {
          // do nothing
        }
      },
    }),
  }),
});

export type EditQuotationRequest = {
  id?: ObjectId;
  client?: Option;
  document_category?: Option;
  client_display_name?: string;
  client_reference?: string;
  commercial_type?: Option;
  origin?: Option;
  language?: any;
  created_at?: string;
  deadline?: string;
  vat_number?: string;
  private_comment?: string;
  deadline_announced_fr?: string;
  production_declared_completion_date?: string;
  deadline_announced_en?: string;
  public_comment?: string;
  deadline_announced?: string;
  production_requested_deposit?: string;
  vat_mode?: Option;
  with_vat?: boolean;
  is_detaxed?: boolean;
  transporter?: Option;
  vatIncluded?: boolean;
  production_requested_deposited?: string;
  announced_deadline?: string;
  currency?: Option;
  project?: Option;
  transporter_price?: string | number;
};

type Response = ResponseData<Quotation>;

export type PartialEditQuotationTaxesRequest = {
  vat_number?: string;
  vat_mode?: ObjectId | undefined;
  with_vat?: boolean;
  is_detaxed?: boolean;
};

export const {useEditQuotationMutation} = editQuotation;
