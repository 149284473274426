import React from 'react';
import {AppRoute} from './types';
import UsersPage from 'pages/dashboard/users';
import CreateUser from 'pages/dashboard/users/create-user';
import EditUser from 'pages/dashboard/users/edit-user';
import ShowUserMasterDetail from 'pages/dashboard/users/show-user/master-details';

const userRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/users',
    component: <UsersPage />,
    subject: 'administration-user',
  },
  {
    path: '/dashboard/users/create',
    component: <CreateUser />,
    subject: 'administration-user',
  },
  {
    path: 'dashboard/users/:id/*',
    component: <ShowUserMasterDetail />,
    subject: 'administration-user',
  },
  {
    path: '/dashboard/users/:id/edit',
    component: <EditUser />,
    subject: 'administration-user',
  },
];

export default userRoutes;
