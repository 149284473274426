import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  catalog: () => __(translations.pricingPage.catalog, 'Catalog'),
  decoratorPricing: () => __(translations.pricingPage.decoratorPricing, 'Decorator'),
  discount: () => __(translations.pricingPage.discount, 'Discount'),
  editCosts: () => __(translations.pricingPage.editCosts, 'Edit costs'),
  label: () => __(translations.pricingPage.label, 'Label'),
  margin: () => __(translations.pricingPage.margin, 'Margin'),
  massActions: () => __(translations.pricingPage.massActions, 'Mass actions'),
  outCatalog: () => __(translations.pricingPage.outCatalog, 'Hide in catalog'),
  preview: () => __(translations.pricingPage.preview, 'Preview'),
  price: () => __(translations.pricingPage.price, 'Price'),
  priceInclTaxes: () => __(translations.pricingPage.priceInclTaxes, 'Price incl taxes'),
  profit: () => __(translations.pricingPage.profit, 'Profit'),
  publicPricing: () => __(translations.pricingPage.publicPricing, 'Public'),
  purchasePrice: () => __(translations.pricingPage.purchasePrice, 'Purchase Price'),
  putInCatalog: () => __(translations.pricingPage.putInCatalog, 'Show in catalog'),
  resellerPricing: () => __(translations.pricingPage.resellerPricing, 'Reseller'),
  skuCode: () => __(translations.pricingPage.skuCode, 'SKU'),
  catalogMapping: () => __(translations.pricingPage.catalogMapping, 'Catalog Mapping'),
  title: () => __(translations.pricingPage.title, 'Pricing'),
  turnover: () => __(translations.pricingPage.turnover, 'Turnover'),
  unitSales: () => __(translations.pricingPage.unitSales, 'Unit sales'),
  CostsByType: () => __(translations.pricingPage.CostsByType, 'Costs by type'),
  details: () => __(translations.pricingPage.details, 'Details'),
  dimensions: () => __(translations.pricingPage.dimensions, 'Dimensions'),
  edit: () => __(translations.pricingPage.edit, 'Edit'),
  exports: () => __(translations.pricingPage.exports, 'Exports'),
  families: () => __(translations.pricingPage.familie, 'Familie'),
  actionMultipleButton: () => __(translations.pricingPage.actionMultipleButton, 'Action multiple'),
  material: () => __(translations.pricingPage.material, 'Material'),
  pricing: () => __(translations.pricingPage.pricing, 'Edit pricing'),
  dateFrom: () => __(translations.pricingPage.dateFrom, 'From'),
  dateTo: () => __(translations.pricingPage.dateTo, 'To'),
  color: () => __(translations.pricingPage.color, 'Colors'),
  matter: () => __(translations.pricingPage.matter, 'Matters'),
  size: () => __(translations.pricingPage.size, 'Sizes'),
  supplier: () => __(translations.pricingPage.supplier, 'Suppliers'),
  inStock: () => __(translations.pricingPage.instock, 'Stock'),
  available: () => __(translations.pricingPage.available, 'Available'),
  notAvailable: () => __(translations.pricingPage.notAvailable, 'Not available'),
  inCatalog: () => __(translations.pricingPage.inCatalog, 'In catalog'),
  notInCatalog: () => __(translations.pricingPage.notInCatalog, 'Not in catalog'),
  category: () => __(translations.pricingPage.category, 'Category'),
  project: () => __(translations.pricingPage.project, 'Project'),
  furnitureCosts: () => __(translations.pricingPage.furnitureCosts, 'Furniture costs'),
  masterProduct: () => __(translations.pricingPage.masterProduct, 'Master product'),
  isoldCollection: () => __(translations.pricingPage.isoldCollection, 'include old collection products'),
  forSale: () => __(translations.pricingPage.forSale, 'For sale'),
  notForSale: () => __(translations.pricingPage.notForSale, 'Not for sale'),
  listOfCosts: () => __(translations.pricingPage.listOfCosts, 'List of costs'),
  productsList: () => __(translations.pricingPage.productsList, 'Products list'),
  products: () => __(translations.pricingPage.products, 'Main product'),
  clientCatalog: () => __(translations.pricingPage.clientCatalog, 'Client catalog '),
  resielerCatalog: () => __(translations.pricingPage.resellerCatalog, 'Reseller catalog '),
  clientResielerCatalog: () => __(translations.pricingPage.clientResellerCatalog, 'Client reseller catalog '),
  xls: () => __(translations.pricingPage.xls, 'XLS'),
  purchaseCost: () => __(translations.pricingPage.purchaseCost, 'Purchase Cost'),
  nomberofSellls: () => __(translations.pricingPage.nomberofSellls, 'Number of sells'),
  totalBenefit: () => __(translations.pricingPage.totalBenefit, 'Total benefit'),
  avrgProfit: () => __(translations.pricingPage.avrgProfit, 'Average profit '),
  totalTurnover: () => __(translations.pricingPage.totalTurnover, 'Total turnover'),
  fr: () => __(translations.pricingPage.fr, 'FR'),
  en: () => __(translations.pricingPage.en, 'EN'),
  patrimoine: () => __(translations.pricingPage.patrimoine, 'Patrimoine catalog'),
  imh: () => __(translations.pricingPage.imh, 'IMH catalog'),
  artistic: () => __(translations.pricingPage.artistic, 'Artistic catalog'),
  imhConsulting: () => __(translations.pricingPage.imhConsulting, 'IMH consulting catalog'),
  indiaPerso: () => __(translations.pricingPage.indiaPerso, 'India perso catalog'),
  localisation: () => __(translations.pricingPage.localisation, 'Catalog per localisation'),
  isBlocked: () => __(translations.pricingPage.isBlocked, 'include blocked products'),
};
