import {useEffect, useState} from 'react';
import {faker} from '@faker-js/faker';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectUser} from 'store/auth/selectors';
import {
  StoreDirectoryRequest,
  useStoreDirectoryMutation,
} from 'store/directory/endpoints/store-directory';
import {Option} from 'types/option';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [send, {isLoading, error}] = useStoreDirectoryMutation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const asc = useSelector(selectUser);
  const refUser = {id: asc?.id, name: asc?.profile?.full_name} as Option;

  const [params, setParams] = useState<Params>({
    first_name: __DEV__ ? fake.firstName : '',
    last_name: __DEV__ ? fake.lastName : '',
    phone: __DEV__ ? fake.phone() : '',
    categories: [],
    reference_contact: refUser,
    families: [],
    comment: __DEV__ ? fake.paragraph : '',
    email: __DEV__ ? fake.email : '',
    job: __DEV__ ? faker.name.jobTitle() : '',
    newsletters: undefined,
    directory_type: 'INDIVIDUAL', //important to hardcode here to not create two files that do the same
  });

  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();

  const name = queryParameters.get('newClient');
  const [first_name, last_name] = name ? name.split(' ') : ['', ''];

  useEffect(() => {
    if (last_name !== null && last_name !== undefined && last_name !== '') {
      setParams(prevParams => ({...prevParams, last_name}));
    }
  }, [last_name, setParams]);

  useEffect(() => {
    if (first_name !== null && first_name !== undefined && first_name !== '') {
      setParams(prevParams => ({...prevParams, first_name}));
    }
  }, [first_name, setParams]);

  const handleSubmit = async () => {
    const result = await send(params);

    if ('data' in result) {
      const contact = result?.data.data;
      navigate(`/dashboard/directories/${contact.id}/general`);
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = StoreDirectoryRequest;
