import React, {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {MODAL} from 'features/modal-notch/constants';
import selectSelectedResources from 'features/table/slice/selectors/select-selected-resources';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Badge, DropdownItem, DropdownMenu} from 'reactstrap';
import {DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import clearSelection from 'features/table/slice/actions/clear-selection';
import Can from 'features/hylian-shield/can';

interface Props {}

const BatchActions: FC<Props> = () => {
  const selectedVariants = useSelector(
    selectSelectedResources('product-variants-prices')
  );

  const hasSelectedItems = selectedVariants.length > 0;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleEditCosts = () => {
    dispatch(
      showModal(MODAL.UPDATE_COSTS_FORM, {
        resourceName: 'product-variants-prices',
        url: 'prices',
      })
    );
  };
  const handlePutInCatalog = () => {
    dispatch(
      showModal(MODAL.UPDATE_CATALOG_FORM, {
        resourceName: 'product-variants-prices',
        url: 'update-incatalog-multiple',
        path: 'prices',
      })
    );
  };
  const handlePutOutsideCatalog = () => {
    dispatch(
      showModal(MODAL.UPDATE_CATALOG_FORM, {
        resourceName: 'product-variants-prices',
        url: 'update-outcatalog-multiple',
        path: 'prices',
      })
    );
  };
  const handleClearSelection = () => {
    dispatch(clearSelection('product-variants-prices'));
  };

  return (
    <Can subject="product-prices" action="edit">
      <UncontrolledDropdown className="me-2 ">
        <DropdownToggle color="light" disabled={!hasSelectedItems}>
          {t(...messages.massActions())}
          {hasSelectedItems && (
            <Badge className="ms-1">{selectedVariants.length}</Badge>
          )}
          <Icon name="chevron-down" size={15} className="ms-1" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem disabled={false} onClick={handleEditCosts}>
            {t(...messages.editCosts())}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem disabled={false} onClick={handlePutInCatalog}>
            {t(...messages.putInCatalog())}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem disabled={false} onClick={handlePutOutsideCatalog}>
            {t(...messages.outCatalog())}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem disabled={false} onClick={handleClearSelection}>
            {t('productsPage.clearSelection')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Can>
  );
};

export default BatchActions;
