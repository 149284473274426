import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.actionTab.action, 'action'),
  title: () => __(translations.actionTab.title, 'Action of {{name}}'),
  merge: () => __(translations.actionTab.merge, 'Merge'),
  mergeText: () =>
    __(
      translations.actionTab.mergeText,
      "Merging will combine and consolidate information from two separate but similar contact entries in a device's address book, resulting in a single, updated and unified contact with all relevant details."
    ),
  delete: () => __(translations.actionTab.delete, 'delete'),
  deleteText: () => __(translations.actionTab.deleteText, 'Deleting a contact will remove it from the database. This action cannot be undone.'),
  deleteAlertMessage: () => __(translations.actionTab.deleteAlertMessage, 'Are you sure you want to delete this contact?'),
  archive: () => __(translations.actionTab.archive, 'archive'),
  restore: () => __(translations.actionTab.restore, 'restore'),
  archiveText: () => __(translations.actionTab.archiveText, 'Archiving a contact will remove it from the directory.'),
  archiveAlertMessage: () => __(translations.actionTab.archiveAlertMessage, 'Are you sure you want to archive this contact?'),

  attach: () => __(translations.actionTab.attach, 'Attach'),
  attachText: () => __(translations.actionTab.attachText, 'Attach a contact to a company'),
  dettach: () => __(translations.actionTab.dettach, 'Dettach'),
  dettachText: () => __(translations.actionTab.dettachText, 'Dettach a contact from a company'),
};
