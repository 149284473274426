import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.DetailsStockProductsModal.title, 'Details stock Product'),
  product: () => __(translations.DetailsStockProductsModal.product, 'Product'),
  sku: () => __(translations.DetailsStockProductsModal.sku, 'SKU'),
  serialNumber: () => __(translations.DetailsStockProductsModal.serialNumber, 'Serial number'),
  stockLocation: () => __(translations.DetailsStockProductsModal.stockLocation, 'Stock location'),
  sublocation: () => __(translations.DetailsStockProductsModal.sublocation, 'Sublocation'),
  option: () => __(translations.DetailsStockProductsModal.option, 'Option'),
  oreders: () => __(translations.DetailsStockProductsModal.oreders, 'Orders'),
  packageDimension: () => __(translations.DetailsStockProductsModal.packageDimension, 'Package dimension'),
  depth: () => __(translations.DetailsStockProductsModal.depth, 'Depth'),
  height: () => __(translations.DetailsStockProductsModal.height, 'Height'),
  name: () => __(translations.DetailsStockProductsModal.name, 'Name'),
  net_weight: () => __(translations.DetailsStockProductsModal.net_weight, 'Net weight'),
  volume: () => __(translations.DetailsStockProductsModal.volume, 'Volume'),
  weight: () => __(translations.DetailsStockProductsModal.weight, 'Weight'),
  width: () => __(translations.DetailsStockProductsModal.width, 'Width'),
};
