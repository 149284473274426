import {useContext, useEffect, useState} from 'react';
import {DIRECTORY_TYPE} from 'constants/directory-type';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import {
  UpdateDirectoryRequest,
  useUpdateDirectoryMutation,
} from 'store/directory/endpoints/update-directory';
import {useSeeder} from 'utils/use-seeder';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';

export default () => {
  const [send, {isLoading, error, isSuccess}] = useUpdateDirectoryMutation();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canEdit = checkAbility('edit', 'directory');

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const [params, setParams] = useState<Params>({
    id: '',
    first_name: '',
    last_name: '',
    newsletters: undefined,
    directory_categories: [],
    families: [],
    color_code: '',
    comment: '',
    email: '',
    job: '',
    directory_type: DIRECTORY_TYPE.INDIVIDUAL, //important to hardcode here to not create two files that do the same
  });

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await send(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;
  useEffect(() => {
    if (params.directory_categories.length === 0) {
      setParams({
        ...params,
        families: [],
      });
    }
  }, [params.directory_categories]);

  useEffect(() => {
    if (directory) {
      setParams({
        ...params,
        id: directory.id,
        color_code: directory.color_code,
        first_name: directory.first_name,
        last_name: directory.last_name,
        directory_categories: directory.directory_categories,
        families: directory.directory_families,
        comment: directory.comment,
        job: directory.job,
        reference_contact: directory.reference_contact,
        residency_country: directory.residency_country,
        gender: directory.gender,
        is_official_suppliers_small_items:
          directory.is_official_suppliers_small_items,
        is_official_studio_supplier: directory.is_official_studio_supplier,
        is_official_furniture_supplier:
          directory.is_official_furniture_supplier,
        requirement_rate: directory.requirement_rate,
        newsletters: directory.newsletters,
        website: directory.website,
      });
    }
  }, [directory]);

  return {
    setParams,
    params,
    isLoading,
    directory,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
    isSuccess,
    canEdit,
  };
};

type Params = UpdateDirectoryRequest;
