import React, {FC, Fragment} from 'react';
import AddressItem from './address-item';
import messages from './messages';
import Button from 'components/button';
import EmptyState from 'features/modal-notch/global-search/empty-state';
import useAfterLife from 'features/modal-notch/soul-eater/use-after-life';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {useGetDirectoryAddressesQuery} from 'store/directory/endpoints/get-directory-addresses';
import showAddressModal from 'store/ui/actions/show-address-modal';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Pricing} from 'types/pricing';
import denormalizeObject from 'utils/denormalize-object';
import selectAddresses from 'features/commercial/documents/store/selectors/select-addresses';
import LoadRunner from 'components/load-runner';

interface Props {
  row?: Pricing;
  onDismiss: () => void;
}

const AddressPickerModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {directory_id} = useSelector(selectActiveModalParams) as {
    directory_id: ObjectId;
  };

  const dispatch = useDispatch();

  const handleCreateNewAddress = () => {
    if (directory_id) {
      dispatch(showAddressModal(directory_id, true));
    }
  };

  const {data: response, isLoading} = useGetDirectoryAddressesQuery({
    directory_id,
  });
  const addresses = useSelector(selectAddresses);

  const {saints} = useAfterLife('address', denormalizeObject(response?.data));
  const directoryAddresses = saints?.filter(
    x => x.address_type.id !== addresses[0]?.address_type?.id
  );

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.title())}</span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <Row>
            {directoryAddresses.map(x => (
              <AddressItem key={x.id} address={x} />
            ))}
            {isLoading ? (
              <LoadRunner />
            ) : (
              <div>
                {directoryAddresses.length === 0 && <EmptyState />}
                <div className="text-center">
                  <Button
                    color="important"
                    className="px-4"
                    size="sm"
                    disabled={!directory_id}
                    onClick={handleCreateNewAddress}
                  >
                    {t(...messages.newAddress())}
                  </Button>
                </div>
              </div>
            )}
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default AddressPickerModal;
