import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const showProject = api.injectEndpoints({
  endpoints: builder => ({
    showProject: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `projects/${params.id}`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'projects', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Project>;

export const {useShowProjectQuery} = showProject;
export const selectProject = showProject.endpoints.showProject.select;
