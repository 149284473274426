import React, {FC, Fragment, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import useTransferOrderModal from './use-transfer-document-modal';
import Button from 'components/button';
import TransferTable from './transfer-table';
import TransferContext from './transfer-context';

interface Props {
  onDismiss: () => void;
}

const TransferToSupplierOrderModal: FC<Props> = props => {
  const {t} = useTranslation();

  const {
    errorMessage,
    updateItem,
    isLoading,
    isTransferred,
    validationErrors,
    items,
    submit,
    initItems,
    allItems,
    handleDismiss,
    canSubmit,
  } = useTransferOrderModal();

  const {id} = useSelector(selectActiveModalParams);

  return (
    <TransferContext.Provider
      value={{
        isTransferred,
        validationErrors,
        onUpdateItem: updateItem,
        items: items,
        initItems,
      }}
    >
      <Fragment>
        <ModalHeader className="bg-primary" toggle={handleDismiss}>
          <span className="text-light">
            <Trans i18nKey={t(...messages.title())} />
          </span>
        </ModalHeader>

        <ModalBody className="">
          <div>{t(...messages.areYouSure())}</div>
          <div>
            <TransferTable allItems={allItems} id={id} />
          </div>
          <div className="text-danger">{t('')}</div>
          {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button
            color="important"
            isLoading={isLoading}
            disabled={canSubmit}
            onClick={submit}
          >
            {t('common.saveChanges')}
          </Button>
          <Button color="light" onClick={handleDismiss}>
            {t('common.cancel')}
          </Button>
        </ModalFooter>
      </Fragment>
    </TransferContext.Provider>
  );
};

export default TransferToSupplierOrderModal;
