import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  contactList: () => __(translations.contatListTab.contactList, 'Contact list'),
  title: () => __(translations.contatListTab.title, 'Contact list of {{name}}'),
  contact: () => __(translations.contatListTab.contact, 'Contact'),
  comment: () => __(translations.contatListTab.comment, 'comment'),
  addContactList: () => __(translations.contatListTab.addContactList, 'Add Contact'),
  addNewContact: () => __(translations.contatListTab.addNewContact, 'Add new contact'),
  edit: () => __(translations.contatListTab.edit, 'Edit'),
  detach: () => __(translations.contatListTab.detach, 'Detach'),
  job: () => __(translations.contatListTab.job, 'Job: '),
  phone: () => __(translations.contatListTab.phone, 'Phone: '),
  email: () => __(translations.contatListTab.email, 'Email: '),
};
