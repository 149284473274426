import {ProductToAssemble} from 'features/stock/types/product-to-assamble';
import React from 'react';
import messages from './messages';
import {useTranslation} from 'react-i18next';
interface StockProductToAssembleTableProps {
  productToAssemble?: ProductToAssemble[];
}

export const StockProductToAssembleTable = (
  props: StockProductToAssembleTableProps
) => {
  const {productToAssemble} = props;
  const {t} = useTranslation();

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="text-center">{t(...messages.scan())}</th>
          <th className="text-center">{t(...messages.sku())}</th>
          <th className="text-center">{t(...messages.serialNumber())}</th>
          <th className="text-center">{t(...messages.name())}</th>
        </tr>
      </thead>
      <tbody>
        {productToAssemble?.map(p => {
          return (
            <tr key={`row--${p.variant_id}-${p.product_id}`}>
              <td>
                <input
                  type="checkbox"
                  id={`check--${p.variant_id}-${p.product_id}`}
                  className={'form-check-input cursor-pointer'}
                  checked={p.check}
                />
              </td>
              <td className="text-center">{p.sku}</td>
              <td className="text-center">{p.serial_number}</td>
              <td className="text-center">{p.name}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
