import React, {useEffect, useMemo, useRef, useState} from 'react';
import '@fullcalendar/react/dist/vdom';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar, {DatesSetArg, EventDropArg} from '@fullcalendar/react';
import {useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, Col, Row, Spinner} from 'reactstrap';
import SuppliersList from '../suppliers-list/suppliers-list';
import interactionPlugin, {EventResizeDoneArg} from '@fullcalendar/interaction';
import {useGetOrdersByRangeQuery} from '../../store/endpoints/get-orders-by-range';
import './monitoring-calendar-view.styles.scss';
import {useMoveOrderToMutation} from '../../store/endpoints/move-order-to';
import realDate from 'utils/real-date';
import {useAdjustOrderDelayMutation} from '../../store/endpoints/adjust-order-delay';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import CalendarEvent from '../calendar-event/calendar-event';
import {useSelector} from 'react-redux';
import selectSelectedSuppliers from '../../store/selectors/select-selected-suppliers';
import TextInput from 'components/text-input';
import CalendarControls from '../calendar-controls/calendar-controls';
import {useDebounce} from 'usehooks-ts';
import messages from './messages';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import clsx from 'clsx';

const MonitoringCalendarView = () => {
  const {t} = useTranslation();

  const [mov, {isLoading: isMoving}] = useMoveOrderToMutation();

  const dispatch = useDispatch();

  const onMove = async (record: any, to: Date, revert: () => void) => {
    const result = await mov({
      id: record?.publicId,
      to: realDate(to),
    });
    if ('data' in result) {
    } else {
      // @ts-expect-error
      dispatch(toast('error', result.error?.data?.message ?? ''));
      revert();
    }
  };

  const [keyword, setKeyword] = useState('');

  const onDrop = (dInfo: EventDropArg) => {
    const event = dInfo.event;
    const record = event?._def;
    const to = event?._instance?.range.start;

    if (!to || !record) {
      return;
    }
    onMove(record, to, dInfo.revert);
  };

  const [adjustDelay, {isLoading: isAdjusting}] = useAdjustOrderDelayMutation();

  const onResize = (dInfo: EventResizeDoneArg) => {
    const endsAt = dInfo.event.end;
    const event = dInfo.event;
    const record = event?._def;
    if (endsAt) {
      adjustDelay({
        id: record?.publicId,
        to: realDate(endsAt),
      });
    }
  };

  const [params, setParams] = useState<any>({
    from: '',
    to: '',
  });

  const suppliers = useSelector(selectSelectedSuppliers);

  const _keyword = useDebounce(keyword, 500);

  const {data, error, isFetching, refetch} = useGetOrdersByRangeQuery({
    from: params.from,
    to: params.to,
    suppliers,
    keyword: _keyword,
  });

  const {message: errorMessage} = parseValidationErrors(error);

  useEffect(() => {
    refetch();
  }, [params, suppliers, _keyword]);

  const orders = data?.data ?? [];

  const events = useMemo(() => {
    if (isFetching) {
      return [];
    }
    return orders.map(row => {
      return {
        title: row.document_ref,
        start: row.estimated_supplier_time,
        id: String(row.id),
        extendedProps: {row},
      };
    });
  }, [orders, isFetching]);

  const onDateChange = (req: DatesSetArg) => {
    setTimeout(() => {
      const from = realDate(req.start);
      const to = realDate(req.end);
      if (from !== params.from) {
        setParams({from, to});
      }
    }, 20);
  };

  const cRef = useRef<FullCalendar>();

  const calendarApi = cRef?.current?.getApi();

  return (
    <div>
      <Card>
        <CardBody>
          {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Row>
            <Col />
            <Col md="3">
              <div className="mb-3">
                <TextInput
                  onChange={k => setKeyword(k)}
                  value={keyword}
                  placeholder={t(...messages.searchPlaceHolder())}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <SuppliersList />
            </Col>
            <Col>
              <div className="calendar-no-print">
                <CalendarControls
                  calendarApi={calendarApi}
                  isLoading={isFetching}
                  from={params.from}
                  to={params.to}
                  onRefetch={() => {
                    refetch();
                  }}
                />
              </div>
              <div>
                <FullCalendar
                  // @ts-ignore
                  ref={cRef}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  editable
                  viewClassNames={clsx({'opacity-50': isFetching || isMoving})}
                  firstDay={1}
                  hiddenDays={[0, 6]}
                  droppable
                  allDayClassNames="calendar-days"
                  eventClassNames="text-white calendar-days"
                  dayCellClassNames="calendar-days"
                  slotMinWidth={10}
                  eventContent={e => (
                    <CalendarEvent
                      event={e.event}
                      row={e.event.extendedProps.row}
                    />
                  )}
                  eventDrop={onDrop}
                  eventResize={onResize}
                  navLinks={false}
                  headerToolbar={{
                    left: '',
                    center: '',
                    right: '',
                  }}
                  weekends={false}
                  events={events}
                  datesSet={onDateChange}
                />
                {isFetching || isMoving ? (
                  <Spinner
                    className="position-absolute top-50 start-50"
                    color="important"
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default MonitoringCalendarView;
