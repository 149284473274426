import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  date: () => __(translations.paymentItem.date, 'Date'),
  paymentType: () => __(translations.paymentItem.paymentType, 'Payment type'),
  paymentMethod: () => __(translations.paymentItem.paymentMethod, 'Payment method'),
  reference: () => __(translations.paymentItem.reference, 'Reference'),
  amount: () => __(translations.paymentItem.amount, 'Amount'),
  removePayment: () => __(translations.paymentItem.removePayment, 'Remove Payment'),
};
