import React from 'react';
import GetOptionLabel from './get-option-label';
import messages from './messages';
import NoOptionMessage from './no-option-message';
import {useSupplierOrdersForm} from './use-supplier-order-form';
import Icon from '@ailibs/feather-react-ts';
import Breadcrumb from 'components/Common/Breadcrumb';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import DateInput from 'components/date-input';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import {Alert, Card, CardBody, Col, Row} from 'reactstrap';
import {Directory} from 'types/directory';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

const CreateSupplierOrdersPage = () => {
  const {t} = useTranslation();
  const {params, validationErrors, isLoading, errorMessage, submit, setParams} =
    useSupplierOrdersForm();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {
              title: t(...messages.supplierOrders()),
              path: '/dashboard/supplier-orders',
            },
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <div>
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col md={12} className="my-2">
                  <AsyncItemPicker
                    endPoint="data/suppliers-type-company"
                    className="my-2"
                    getOptionLabel={(directory: Directory) => (
                      <GetOptionLabel directory={directory} />
                    )}
                    label={t(...messages.supplier())}
                    optionLabel=""
                    errors={validationErrors.supplier_id}
                    value={params.supplier}
                    onChange={supplier => setParams({...params, supplier})}
                    noOptionsMessage={(e: any) => (
                      <NoOptionMessage newClient={e.inputValue} />
                    )}
                  />
                </Col>
                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/commercial-types?supplier_order"
                    defaultValue
                    className="my-2"
                    label={t(...messages.supplierOrderType())}
                    optionLabel=""
                    errors={validationErrors.commercial_type_id}
                    value={params.type}
                    onChange={type => setParams({...params, type})}
                  />
                </Col>

                <Col md={12}>
                  <AsyncItemPicker
                    endPoint="data/languages"
                    defaultValue
                    className="my-2 text-capitalize"
                    label={t(...messages.supplierOrderLanguage())}
                    getOptionLabel={(l: any) => l.label}
                    errors={validationErrors.language}
                    value={params.language}
                    getOptionValue={(l: any) => l.label}
                    onChange={language => {
                      setParams({...params, language});
                    }}
                  />
                </Col>
                <Col md={12} className="my-2">
                  <DateInput
                    label={t(...messages.supplierOrderDate())}
                    value={params.order_date}
                    onChange={order_date => setParams({...params, order_date})}
                    errors={validationErrors.order_date}
                  />
                </Col>
                <Col md={12} className="mb-1">
                  <DateInput
                    label={t(...messages.supplierOrderDeadline())}
                    value={params.due_date}
                    onChange={due_date => setParams({...params, due_date})}
                    errors={validationErrors.due_date}
                  />
                </Col>
              </Row>

              <AppBarActions>
                <Button
                  isLoading={isLoading}
                  onClick={submit}
                  color="important"
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
              </AppBarActions>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default CreateSupplierOrdersPage;
