import React from 'react';
import clsx from 'clsx';
import {useLocation, useNavigate, useParams} from 'react-router';
import {TabContent, TabPane} from 'reactstrap';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import ProductAlertDetailsTable from 'features/stock/products-stock-grid/containers/product-alert-details-table/product-alert-details-table';
import NoProductSelected from 'features/stock/products-stock-grid/containers/no-product-selected/no-product-selected';
import {useTranslation} from 'react-i18next';
import collectionTab from './constants';

const EMPTY_TAB__ID = `tab--empty`;

const ProductAlerts = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const isActive = (id: ObjectId) => {
    return location.pathname === `/dashboard/products-stock/alerts/${id}`;
  };

  const {id} = useParams();

  return (
    <div className="pt-3">
      <div>
        <Nav tabs>
          {collectionTab.map(x => (
            <NavItem key={x.id}>
              <NavLink
                role={clsx({button: !isActive(x.id)})}
                onClick={() =>
                  navigate(`/dashboard/products-stock/alerts/${x.id}`)
                }
                active={isActive(x.id)}
              >
                {t(`productAlertDetailsTable.${x.name}`)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={id ? `tab--${id}` : EMPTY_TAB__ID}>
          <TabPane tabId={EMPTY_TAB__ID}>
            <NoProductSelected />
          </TabPane>
          <TabPane tabId={`tab--${id}`}>
            <ProductAlertDetailsTable id={id} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default ProductAlerts;
