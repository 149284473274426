import {createSelector} from 'reselect';
import {RootState} from 'store';
import {selectUser} from 'store/auth/selectors';
import {Notification} from 'types/notification';

export const selectDomain = (state: RootState) => state.account;

export const selectNotificationStatus = createSelector(
  [selectDomain, selectUser],
  (account, user) => {
    if (typeof account.notificationsEnabled === 'undefined') {
      return user?.notification_status;
    }
    /**
     * When the user toggles notifications on/off
     * the account slices becomes aware of the last mutation
     * but if not, we take the value stored in the server
     */
    return account.notificationsEnabled;
  }
);

export const selectUnreadNotificationsCount = createSelector(
  [selectUser, selectDomain],
  (user, accountState) => {
    const readCount = Object.values(accountState.readNotifications).length;
    const userNotifications = user?.unread_notifications_count ?? 0;
    const firebaseNotifications = accountState.unreadNotificationsCount;

    const unreadMinusRead =
      userNotifications + firebaseNotifications - readCount;

    return Math.max(unreadMinusRead, 0);
  }
);

export const selectIsNotificationUnread = (notification: Notification) =>
  createSelector([selectDomain], accountState => {
    if (typeof accountState.readNotifications[notification.id] === 'boolean') {
      return !accountState.readNotifications[notification.id];
    }
    return !notification?.read_at;
  });
