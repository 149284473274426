import React, {FC, Fragment, useContext} from 'react';
import CostsContext from '../costs-context';
import messages from '../messages';
import updateCost from '../slice/actions/update-cost';
import selectCostItems from '../slice/selectors/select-cost-items';
import {CostField} from '../slice/types';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Label, Row, Col} from 'reactstrap';
import {DirectoryProductVariant} from 'types/directory-product-variant';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import denormalizeObject from 'utils/denormalize-object';
import getDirectoryNameWithOverlap from 'utils/row-getters/get-directory-name-with-overlap';

interface Props {
  categoryId: 'components';
  index: number;
}

const Components: FC<Props> = props => {
  const {categoryId} = props;

  const {validationErrors} = useContext(CostsContext);

  const costs = useSelector(selectCostItems(categoryId));

  const vals = Object.values(costs);
  const dispatch = useDispatch();

  const handleUpdate = (id: ObjectId, field: CostField, value: string) => {
    dispatch(updateCost(id, categoryId, field, value));
  };

  const validate = (k: string, index: number) => {
    return validationErrors[`costs.${categoryId}.${index}.${k}`];
  };

  const {t} = useTranslation();
  return (
    <Fragment>
      <Row>
        <Col>
          {/* ROW MULTIPLIER */}
          <Label className="text-primary">
            <Trans i18nKey={`costsTab.${categoryId}`} />
          </Label>

          {vals.map((cost, i) => (
            <Row className="mb-3" key={cost.id}>
              <Col md={12}>
                <p>
                  <strong className="text-uppercase">
                    {cost.component?.name}
                  </strong>
                </p>
              </Col>
              <Col md="2">
                <AsyncItemPicker
                  endPoint="data/cost-types"
                  getOptionValue={(x: Option) => x.id}
                  getOptionLabel={(x: Option) => x.name}
                  label={t(...messages.type())}
                  isClearable={false}
                  disabled={categoryId === 'components'}
                  errors={validate('product_cost_type_id', i)}
                  value={cost.product_cost_type}
                  onChange={x => handleUpdate(cost.id, 'product_cost_type', x)}
                />
              </Col>
              <Col md="3">
                <ItemPicker
                  isClearable
                  getOptionLabel={(
                    directoryVariant: DirectoryProductVariant
                  ) => {
                    return getDirectoryNameWithOverlap(
                      directoryVariant?.directory ?? directoryVariant
                    );
                  }}
                  options={denormalizeObject(cost?.component?.suppliers)}
                  label={t(...messages.supplier())}
                  value={cost?.directory}
                  errors={validate('directory_id', i)}
                  onChange={(x: any) => {
                    handleUpdate(cost.id, 'directory', x?.directory);
                    handleUpdate(cost.id, 'cost_per_unit', x?.cost);
                  }}
                />
              </Col>
              <Col className="d-flex" md="2">
                <TextInput
                  label={t(...messages.costPerUnit())}
                  errors={validate('cost_per_unit', i)}
                  type="number"
                  disabled
                  value={cost.cost_per_unit}
                  onChange={x => handleUpdate(cost.id, 'cost_per_unit', x)}
                />
              </Col>
              <Col className="d-flex" md="2">
                <TextInput
                  containerStyle="w-100"
                  label={t(...messages.totalCost())}
                  type="number"
                  disabled
                  value={
                    Number(cost?.quantity ?? 0) * Number(cost.cost_per_unit)
                  }
                  onChange={x => {}}
                />
              </Col>
              {/* <Col /> */}
              <Col>
                <TextInput
                  label={t(...messages.comment())}
                  type="textarea"
                  rows={1}
                  value={cost.comment}
                  errors={validate('comment', i)}
                  onChange={x => handleUpdate(cost.id, 'comment', x)}
                />
              </Col>
              <Col className="" md={1}>
                <div>
                  <Label className="mb-0">&nbsp;</Label>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Components;
