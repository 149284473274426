import React from 'react';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Col, Row} from 'reactstrap';
import selectFiscalSettings from 'store/global/selectors/select-fiscal-settings';

const FiscalInfoSection = () => {
  const {t} = useTranslation();

  const fiscalInfo = useSelector(selectFiscalSettings);

  return (
    <div className="font-size-12">
      <br />
      <Row>
        <Col>{fiscalInfo.disclaimer_payment_method}</Col>
      </Row>
      <br />
      <Row>
        <Col> {fiscalInfo.company_name}</Col>
      </Row>
      <Row>
        <Col> {fiscalInfo.company_rib_address}</Col>
      </Row>
      <Row>
        <Col>
          {t(...messages.bicOrSwift())} {fiscalInfo.company_rib_bic}
        </Col>
      </Row>
      <Row>
        <Col>
          {t(...messages.iban())} {fiscalInfo.company_iban}
        </Col>
      </Row>
      <br />
      <Row>
        <Col>{fiscalInfo.disclaimer_recovery_costs}</Col>
      </Row>
      <Row>
        <Col>{fiscalInfo.disclaimer_interest_rates}</Col>
      </Row>
    </div>
  );
};

export default FiscalInfoSection;
