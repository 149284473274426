import {Action} from 'features/hylian-shield/types';

export const projectTabs: ProjectTabs[] = [
  {
    to: 'general',
    title: 'general',
    action: ['administration', 'show'],
  },
  {
    to: 'contacts',
    title: 'contacts',
    action: ['administration', 'show'],
  },
  {
    to: 'team',
    title: 'team',
    action: ['administration', 'show'],
  },
  {
    to: 'phases',
    title: 'phases',
    action: ['invoicing'],
  },
  {
    to: 'attachments',
    title: 'attachments',
    action: ['administration', 'show'],
  },
  {
    to: 'expenses',
    title: 'expenses',
    action: ['administration', 'show'],
  },
  {
    to: 'man-days',
    title: 'manDays',
    action: ['invoicing'],
  },
  {
    to: 'commissions',
    title: 'commissions',
    action: ['invoicing'],
  },
  {
    to: 'sourcing-fees',
    title: 'sourcingFees',
    action: ['invoicing'],
  },
  {
    to: 'sells-ffe',
    title: 'sellsFFE',
    action: ['administration', 'show'],
  },
  {
    to: 'vintage',
    title: 'vintage',
    action: ['administration', 'show'],
  },
  {
    to: 'conclusion',
    title: 'conclusion',
    action: ['invoicing'],
  },
];

type ProjectTabs = {
  to: string;
  title: string;
  action: Action | Action[];
};
