import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {application} from 'constants/application';
import {ApiEndpointBuilder} from 'services/api/types';
import {RootState} from 'store';

const verifyIdentityEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation({
    async queryFn(_: void, {getState}, extra, fetchWithBaseQuery) {
      const {fcmToken, socialAuth} = (getState() as RootState).auth;
      const result = await fetchWithBaseQuery({
        url: 'oauth/login',
        method: 'POST',
        body: {
          provider_token: socialAuth.provider_token,
          provider_name: socialAuth.provider_name,
          fcm_token: fcmToken,
          platform: application.platform,
        },
      });
      if (result.error) {
        return {error: result.error as FetchBaseQueryError};
      }
      return result;
    },
  });
};

export default verifyIdentityEndpoint;
