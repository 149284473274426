import {useLazyProjectCommissionsExportQuery} from 'features/studio/projects/store/exports/project-commissions-export';
import {useLazyProjectConclusionExportQuery} from 'features/studio/projects/store/exports/project-conclusion-export';
import {useLazyProjectMandaysExportQuery} from 'features/studio/projects/store/exports/project-mandays-export';
import {useLazyProjectPhasesExportQuery} from 'features/studio/projects/store/exports/project-phases-export';
import {useLazyProjectSellsFFEExportQuery} from 'features/studio/projects/store/exports/project-sells-ffe-export';
import {useLazyProjectSellsVintageExportQuery} from 'features/studio/projects/store/exports/project-sells-vintage-export';
import {useLazyProjectSourcingfeesExportQuery} from 'features/studio/projects/store/exports/project-sourcingfees-export';
import {selectProject} from 'features/studio/projects/store/show-project';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import toast from 'store/ui/actions/toast';

const useExportsForm = (): IExportsForm => {
  const {id} = useParams();

  const {data} = useSelector(selectProject({id}));

  const project = data?.data;

  const dispatch = useDispatch();

  const [exportCommissions, {isLoading: isExportingCommissions}] =
    useLazyProjectCommissionsExportQuery();
  const [exportConclusion, {isLoading: isExportingConclusion}] =
    useLazyProjectConclusionExportQuery();
  const [exportMandays, {isLoading: isExportingMandays}] =
    useLazyProjectMandaysExportQuery();
  const [exportPhases, {isLoading: isExportingPhases}] =
    useLazyProjectPhasesExportQuery();
  const [exportSellsFFE, {isLoading: isExportingSellsFFE}] =
    useLazyProjectSellsFFEExportQuery();
  const [exportSellsVintage, {isLoading: isExportingSellsVintage}] =
    useLazyProjectSellsVintageExportQuery();
  const [exportSourcingfees, {isLoading: isExportingSourcingfees}] =
    useLazyProjectSourcingfeesExportQuery();

  const handleExport = async (exports: AvailableExports) => {
    let result;

    switch (exports) {
      case 'commissions':
        result = await exportCommissions({id});
        break;
      case 'conclusion':
        result = await exportConclusion({id});
        break;
      case 'contacts':
        var anchor = document.createElement('a');
        anchor.href = project?.pdf_links_contact?.download_link ?? '';

        anchor.target = '_blank';
        anchor.click();
        break;
      case 'mandays':
        result = await exportMandays({id});
        break;
      case 'phases':
        result = await exportPhases({id});
        break;
      case 'sells-ffe':
        result = await exportSellsFFE({id});
        break;
      case 'sells-vintage':
        result = await exportSellsVintage({id});
        break;
      case 'sourcingfees':
        result = await exportSourcingfees({id});
        break;
      default:
        return;
    }

    if (exports !== 'contacts') {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const isLoading = useMemo(() => {
    return (
      isExportingCommissions ||
      isExportingConclusion ||
      isExportingMandays ||
      isExportingPhases ||
      isExportingSellsFFE ||
      isExportingSellsVintage ||
      isExportingSourcingfees
    );
  }, [
    isExportingCommissions,
    isExportingConclusion,
    isExportingMandays,
    isExportingPhases,
    isExportingSellsFFE,
    isExportingSellsVintage,
    isExportingSourcingfees,
  ]);

  return {
    isLoading,
    handleExport,
  };
};

interface IExportsForm {
  isLoading: boolean;
  handleExport: (arg: AvailableExports) => void;
}

type AvailableExports =
  | 'commissions'
  | 'conclusion'
  | 'contacts'
  | 'mandays'
  | 'phases'
  | 'sells-ffe'
  | 'sells-vintage'
  | 'sourcingfees';

export default useExportsForm;
