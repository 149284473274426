import React, {FC, Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import messages from './messages';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import useTransferOrderModal from './use-transfer-document-modal';
import Button from 'components/button';
import TransferTable from './transfer-table';

interface Props {
  onDismiss: () => void;
}

const TransferOrderModal: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    errorMessage,
    updateItem,
    isLoading,
    isTransferred,
    onToggleAll,
    isAllSelected,
    itemsLength,
    items,
    submit,
  } = useTransferOrderModal(onDismiss);

  const {id} = useSelector(selectActiveModalParams);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <div>{t(...messages.areYouSure())}</div>
        <div>
          <TransferTable
            isTransferred={isTransferred}
            onToggleAll={onToggleAll}
            isAllSelected={isAllSelected}
            items={items}
            onUpdateItem={updateItem}
            id={id}
          />
        </div>
        <div className="text-danger">{t('')}</div>
        {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button
          color="important"
          disabled={itemsLength === 0}
          isLoading={isLoading}
          onClick={submit}
        >
          {t('common.saveChanges')}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

export default TransferOrderModal;
