import React, {FC} from 'react';
import messages from '../../listing/list/messages';
import {useTranslation} from 'react-i18next';
import {Card} from 'reactstrap';

interface Props {}

const EmptyState: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className="pt-3 pb-5 h-100 w-100 text-center border-top ">
      {t(...messages.noRecords())}
    </div>
  );
};

export default EmptyState;
