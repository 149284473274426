import {useEffect} from 'react';
import {actions} from './slice';
import changeSituation from './slice/actions/change-situation';
import selectFields from './slice/selectors/select-fields';
import selectSituationFields from './slice/selectors/select-situation-fields';
import selectTotalFields from './slice/selectors/select-total-fields';
import selectVat from './slice/selectors/select-vat';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateCostsMutation} from 'store/production/endpoints/update-costs';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useCostsForm = () => {
  const [commit, {isLoading, error, isSuccess, reset}] =
    useUpdateCostsMutation();

  const serverError = parseValidationErrors(error);

  const {id, variant} = useParams();

  const costs = useSelector(selectFields);

  const totals = useSelector(selectTotalFields);

  const {vat} = useSelector(selectVat(id));

  const situation = useSelector(selectSituationFields);

  const handleSubmit = async () => {
    const result = await commit({
      costs,
      id,
      variant,
      totals,
      vat,
      situation,
    });
    if ('data' in result) {
      dispatch(changeSituation(result.data.data));
      dispatch(toast('success', 'common.changesCommited'));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, []);

  return {
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    submit: handleSubmit,
    validationErrors: serverError.errors,
    reset,
  };
};
