import React, {FC, Fragment, useRef, useState} from 'react';
import './dragndropstyle.scss';
import messages from './messages';
import {useUploadFile} from './use-upload-file';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import clsx from 'clsx';
import {truncate} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Badge, FormFeedback} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {TemporaryFile} from 'types/temporary-file';

type Option = {
  id?: ObjectId;
  name?: string;
};
interface Props {
  onChangeFile: (file?: any) => void;
  file?: TemporaryFile;
  errors?: string | string[];
  fileNameLength?: number;
}

const DragDropFile: FC<Props> = props => {
  const {onChangeFile, file, errors, fileNameLength = 20} = props;

  const [dragActive, setDragActive] = useState(false);

  const inputRef = useRef(null);

  const {submit: upload, errorMessage} = useUploadFile();

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      submit(e.dataTransfer.files[0]);
    }
  };

  const submit = async (f: File) => {
    const result = await upload({file: f});
    if (result?.data.data) {
      onChangeFile(result?.data.data);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      submit(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    if (inputRef.current) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const {t} = useTranslation();

  const validationErrors = errorMessage || errors;

  return (
    <Fragment>
      {!file ? (
        <div
          className={`form-file-upload ${errorMessage ? 'error-message' : ''}`}
          onDragEnter={handleDrag}
        >
          <input
            ref={inputRef}
            type="file"
            className="input-file-upload"
            onChange={handleChange}
          />

          <label
            htmlFor="input-file-upload"
            className={clsx({
              'label-file-upload upload-button text-secondary': true,
              'drag-active': dragActive,
              'bg-gray-light': !dragActive,
              'border-danger text-danger': validationErrors,
            })}
            onClick={onButtonClick}
          >
            {t(...messages.selectFile())}
          </label>

          {dragActive && (
            <div
              className="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </div>
      ) : (
        <div className="form-file-upload">
          <a href={file.url} target="_blank" rel="noreferrer">
            <Badge className="label-file-upload upload-button d-flex">
              <div className="d-flex align-items-center">
                <Icon name="file" className="me-1" size={15} />

                {truncate(file.name, {length: fileNameLength})}
              </div>
            </Badge>
          </a>
        </div>
      )}
      {/* Fake input field */}
      <input
        className={clsx({
          'form-control': true,
          'is-invalid': !!validationErrors,
        })}
        type="hidden"
      />
      <FormFeedback valid={false}>{validationErrors}</FormFeedback>
    </Fragment>
  );
};

export default DragDropFile;
