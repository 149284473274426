import React, {FC} from 'react';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import {Payment} from 'types/payment';
import {useDispatch} from 'react-redux';
import {useUpdateReconciledPaymentMutation} from 'pages/accounting/store/endpoints/update-reconciled-payment';
import Button from 'components/button';
import {useListSlice} from 'features/table/slice';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';

const PaymentsActions: FC<Props> = row => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const [submit, {isLoading, error}] = useUpdateReconciledPaymentMutation();

  const handleSubmit = async () => {
    const result = await submit({
      id: row.id,
    });

    if ('data' in result) {
      dispatch(toast('success', result.data?.data.message));

      const {actions} = useListSlice();
      dispatch(
        actions.loadData({
          url: 'payments',
          name: 'payments',
          page: 1,
        })
      );
    }
  };

  return (
    <div className="d-flex">
      <Can action="edit" subject="accounting">
        <Button
          color={row.reconciled_payment ? 'success' : 'light'}
          className="btn-md"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {row.reconciled_payment
            ? t(...messages.reconciledPayment())
            : t(...messages.reconcilingPayment())}
        </Button>
      </Can>
    </div>
  );
};

type Props = Payment;

export default PaymentsActions;
