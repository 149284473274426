import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderActionModal.title, 'Actions on order'),
  cancel: () => __(translations.orderActionModal.cancel, 'Cancel'),
  yesImSure: () => __(translations.orderActionModal.yesImSure, "Yes, I'm sure"),
  duplicateOrderTitle: () => __(translations.orderActionModal.duplicateOrderTitle, 'Duplicate'),
  orderDuplicateSuccess: () => __(translations.orderActionModal.orderDuplicateSuccess, 'Order duplicated successfully…'),
  duplicateAlertMessage: () => __(translations.orderActionModal.duplicateAlertMessage, ' Are you sure you want to duplicate this order?'),
  actions: () => __(translations.orderActionModal.actions, 'Actions on orders'),
  duplicateOrderDescription: () =>
    __(translations.orderActionModal.duplicateOrderDescription, 'This is useful if you want to create another order that has the same items as the current one, and make some minor changes to it '),
  duplicateOrder: () => __(translations.orderActionModal.duplicateOrder, 'Duplicate'),
  prepareOrder: () => __(translations.orderActionModal.prepareOrder, 'Prepare'),
  orderDirectory: () => __(translations.orderActionModal.orderDirectory, 'Client'),
  orderSupplier: () => __(translations.orderActionModal.orderSupplier, 'Supplier'),
  deleteOrderTitle: () => __(translations.orderActionModal.deleteOrderTitle, 'Delete order'),
  deleteOrderDescription: () => __(translations.orderActionModal.deleteOrderDescription, "Once you've deleted a order, there's no going back. This action is irreversible."),
  deleteOrder: () => __(translations.orderActionModal.deleteOrder, 'Delete'),
  deleteAlertMessage: () => __(translations.orderActionModal.deleteAlertMessage, 'are you sure you want to delete this order?'),
  orderEmailModalTitle: () => __(translations.orderActionModal.orderEmailModalTitle, 'Send order'),
  orderEmailSendSubject: () => __(translations.orderActionModal.orderEmailSendSubject, 'E-mail Subject'),
  orderEmailSubject: () =>
    __(
      translations.orderActionModal.orderEmailSubject,
      `Hello ...,

  Thank you for your visit.
  Please find enclosed your order.
  The delivery time is 12 to 14 weeks, on receipt of a 50% deposit.
  
  Please do not hesitate to contact me,
  
  Best regards,`
    ),
  orderEmailAdd: () => __(translations.orderActionModal.orderEmailAdd, 'Add'),
  orderEmailList: () => __(translations.orderActionModal.orderEmailList, 'Recipients'),
  orderEmail: () => __(translations.orderActionModal.orderEmail, 'E-mail'),
  sendEmailTitle: () => __(translations.orderActionModal.sendEmailTitle, 'Send via e-mail'),
  sendEmailDetails: () => __(translations.orderActionModal.sendEmailDetails, 'This will send the order as email'),
  sendEmailOrder: () => __(translations.orderActionModal.sendEmailOrder, 'Send'),
  emailOrderAttachment: () => __(translations.orderActionModal.emailOrderAttachment, 'Order'),
  emailPackingListAttachment: () => __(translations.orderActionModal.emailPackingListAttachment, 'Packing list'),
  emailOptionsSheetAttachment: () => __(translations.orderActionModal.emailOptionsSheetAttachment, 'Options Sheet'),
  emailStockSummaryAttachment: () => __(translations.orderActionModal.emailStockSummaryAttachment, 'Stock summary'),
  sendLabelOrderTitle: () => __(translations.orderActionModal.sendLabelOrderTitle, 'Send Labels'),
  sendLabelOrderDescription: () => __(translations.orderActionModal.sendLabelOrderDescription, 'This will send labels of the order as attachement'), //this traduction will be updated according to the exact discription of the action
  sendLabelOrder: () => __(translations.orderActionModal.sendLabelOrder, 'Send Labels'),

  sendLabelOrderSku: () => __(translations.orderActionModal.sendLabelOrderSku, 'Sku'),
  sendLabelOrderName: () => __(translations.orderActionModal.sendLabelOrderName, 'Name'),
  sendLabelOrderQuantity: () => __(translations.orderActionModal.sendLabelOrderQuantity, 'Quantity'),
  sendLabelOrderDeparture: () => __(translations.orderActionModal.sendLabelOrderDeparture, 'Departure'),
  sendLabelOrderArrival: () => __(translations.orderActionModal.sendLabelOrderArrival, 'Arrival'),
  sendLabelOrderSupplier: () => __(translations.orderActionModal.sendLabelOrderSupplier, 'Supplier'),
  sendLabelOrderEmail: () => __(translations.orderActionModal.sendLabelOrderEmail, 'Email'),
  sendLabelOrderGenerate: () => __(translations.orderActionModal.sendLabelOrderGenerate, 'Generate'),
  sendLabelOrderTransfer: () => __(translations.orderActionModal.sendLabelOrderTransfer, 'Transfer'),
  exportOrderFr: () => __(translations.orderSummarySection.exportOrderFr, 'Order ( FR )'),
  exportOrderEn: () => __(translations.orderSummarySection.exportOrderEn, 'Order ( EN )'),
  transfer: () => __(translations.orderActionModal.transfer, 'Transfer'),
  supplierOrder: () => __(translations.orderActionModal.supplierOrder, 'Supplier order'),
  invoice: () => __(translations.orderActionModal.invoice, 'Invoice'),
  exportPackingListFr: () => __(translations.orderSummarySection.exportPackingListFr, 'Packing List ( FR ) '),
  exportPackingListEn: () => __(translations.orderSummarySection.exportPackingListEn, 'Packing List ( EN ) '),
  orderArchiveMessage: () => __(translations.orderActionModal.documentArchiveMessage, 'Are you sure you want to archive this document?'),
};
