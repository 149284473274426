import {ApiEndpointBuilder} from 'services/api/types';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

const toggleNewsLetterEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<ResponseData<User>, boolean>({
    query: onOrOff => ({
      url: 'me/profile/newsletter',
      method: 'PUT',
      body: {
        status: onOrOff,
      },
    }),
  });
};

export default toggleNewsLetterEndpoint;
