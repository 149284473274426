import React from 'react';
import messages from './messages';
import EmptyStateIllustration from 'assets/images/empty-state.svg';
import {useTranslation} from 'react-i18next';

const EmptyState = () => {
  const {t} = useTranslation();
  return (
    <div className="p-2 d-flex flex-column justify-content-center align-items-center">
      {/* <img
        className="img-fluid mb-3 "
        height={300}
        width={300}
        src={EmptyStateIllustration}
      /> */}
      <div className="text-center text-muted">
        {t(...messages.noReccords())}
      </div>
    </div>
  );
};

export default EmptyState;
