import React, {FC} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import {CircularProgressbarStyles} from 'react-circular-progressbar/dist/types';
import 'react-circular-progressbar/dist/styles.css';

const success = `var(--bs-success)`;
const warning = `var(--bs-warning)`;

export const CircularProgress: FC<Props> = ({
  progression,
  strokeWidth = 12,
  styles,
}) => (
  <CircularProgressbar
    value={progression.value}
    text={progression?.text}
    strokeWidth={strokeWidth}
    styles={{
      text: {
        fontSize: '28px',
      },
      path: {
        stroke: progression.value < 99 ? warning : success,
      },
      ...styles,
    }}
  />
);

type Props = {
  progression: {
    value: number;
    text?: string;
  };
  strokeWidth?: number;
  styles?: CircularProgressbarStyles;
};
