import React, {Fragment, useContext, useEffect} from 'react';
import documentContext from '../document-context/document-context';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';
import {Subject} from 'features/hylian-shield/types';
import {useCurrentQuote} from 'pages/commercial/quotations/tabs/details/quotation-context';

const TableActions = () => {
  const {t} = useTranslation();

  const {errorMessage, submit, isLoading, is_locked, doc} =
    useContext(documentContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      dispatch(toast('error', errorMessage));
    }
  }, [errorMessage]);

  return (
    <Fragment>
      <AppBarActions>
        <Can
          subject={doc?.commercial_type?.code?.toLocaleLowerCase() as Subject}
          action="create-and-edit"
        >
          <div className="d-flex gap-2">
            <Button
              onClick={submit}
              disabled={is_locked}
              color="important"
              isLoading={isLoading}
            >
              <Icon name="check" size={15} className="me-1" />
              <Trans i18nKey="common.saveChanges" />
            </Button>
          </div>
        </Can>
      </AppBarActions>
    </Fragment>
  );
};

export default TableActions;
