import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const destroyDirectory = api.injectEndpoints({
  endpoints: builder => ({
    destroyDirectory: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'DELETE',
          url: `trashed/directories/${params.id}/force-delete`,
        };
      },
      // @ts-expect-error
      invalidatesTags: (_a, _b, arg) =>
        _a ? [{type: 'directories', id: arg.id}] : [],
    }),
  }),
});

type Request = {
  id?: ObjectId;
  url: string;
  action?: string;
};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useDestroyDirectoryMutation} = destroyDirectory;
