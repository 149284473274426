import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  admin: () => __(translations.roles.admin, 'Admin'),
  billingAddress: () => __(translations.addresses.billingAddress, 'Billing Address'),
  female: () => __(translations.genders.female, 'Female'),
  guest: () => __(translations.roles.guest, 'Guest'),
  male: () => __(translations.genders.male, 'Male'),
  shippingAddress: () => __(translations.addresses.shippingAddress, 'Shipping Address'),
  stockIn: () => __(translations.stock.stockIn, 'Stock in'),
  stockOut: () => __(translations.stock.stockOut, 'Stock out'),
  stockTransfer: () => __(translations.stock.stockTransfer, 'Stock transfer'),
  user: () => __(translations.roles.user, 'User'),

  individual: () => __(translations.directoryTypes.individual, 'Individual'),
  company: () => __(translations.directoryTypes.company, 'Company'),
};
