import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.addressPickerModal.title, 'Select an address'),
  newAddress: () => __(translations.addressPickerModal.newAddress, 'New address'),
  select: () => __(translations.addressPickerModal.select, 'Select…'),
  line1: () => __(translations.addressPickerModal.line1, 'Line 1'),
  line2: () => __(translations.addressPickerModal.line2, 'Line 2'),
  line3: () => __(translations.addressPickerModal.line3, 'Line 3'),
  vatMode: () => __(translations.addressPickerModal.vatMode, 'VAT Mode'),
};
