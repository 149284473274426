import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.directoryGeneralTab.title, 'General of {{name}}'),
  address: () => __(translations.directoryGeneralTab.address, 'Address'),
  category: () => __(translations.directoryGeneralTab.category, 'Category'),
  comment: () => __(translations.directoryGeneralTab.comment, 'Comment'),
  country: () => __(translations.directoryGeneralTab.country, 'Country'),
  companyList: () => __(translations.directoryGeneralTab.companyList, 'Company List'),
  description: () => __(translations.directoryGeneralTab.description, 'Description'),
  email: () => __(translations.directoryGeneralTab.email, 'Email'),
  exports: () => __(translations.directoryGeneralTab.exports, 'Exports'),
  family: () => __(translations.directoryGeneralTab.family, 'Family'),
  id: () => __(translations.directoryGeneralTab.id, 'ID'),
  newsletter: () => __(translations.directoryGeneralTab.newsletter, 'subscribed to newsletter'),
  name: () => __(translations.directoryGeneralTab.name, 'Name'),
  pageTitle: () => __(translations.directoryGeneralTab.pageTitle, 'Directory'),
  phone: () => __(translations.directoryGeneralTab.phone, 'Phone'),
  project: () => __(translations.directoryGeneralTab.project, 'Project'),
  referentContact: () => __(translations.directoryGeneralTab.referentContact, 'Referent contact'),
  requirementRate: () => __(translations.directoryGeneralTab.requirementRate, 'Requirement rate'),
  segment: () => __(translations.directoryGeneralTab.segment, 'Segment'),
  turnover: () => __(translations.directoryGeneralTab.turnover, 'Turnover'),
  type: () => __(translations.directoryGeneralTab.type, 'Type'),
  website: () => __(translations.directoryGeneralTab.website, 'Website'),
  official_studio_supplier: () => __(translations.directoryGeneralTab.officialSupplierStudio, 'Official supplier studio'),
  official_furniture_supplier: () => __(translations.directoryGeneralTab.officialSupplierFurniture, 'Official supplier furniture'),
  official_suppliers_small_items: () => __(translations.directoryGeneralTab.officialSupplierSmallItems, 'Official supplier small items'),
  gender: () => __(translations.directoryGeneralTab.gender, 'Gender'),
  first_name: () => __(translations.directoryGeneralTab.first_name, 'First Name'),
  last_name: () => __(translations.directoryGeneralTab.last_name, 'Last Name'),
  job: () => __(translations.directoryGeneralTab.job, 'Job'),
  company: () => __(translations.directoryGeneralTab.company, 'Company'),
  addCompany: () => __(translations.directoryGeneralTab.addCompany, 'Add Company'),
  color: () => __(translations.directoryGeneralTab.color, 'Color'),
};
