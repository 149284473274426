import {
  assembleProductRequest,
  useAssembleProductMutation,
} from 'features/stock/store/endpoint/assemble-product';
import {useSearchSerialNumberAssembleMutation} from 'features/stock/store/endpoint/post-serial-number-search-for-assemble';

import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResourceName} from 'types/resource-name';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useAssembleProductForm = () => {
  const [submit, {isLoading, error}] = useAssembleProductMutation();
  const [
    submitSerialNumber,
    {isLoading: isLoadingSerialNumber, error: errorSerialNumber},
  ] = useSearchSerialNumberAssembleMutation();

  const serverError = parseValidationErrors(error);
  const serverErrorSerialNumber = parseValidationErrors(errorSerialNumber);
  const modalParams = useSelector(selectActiveModalParams);
  const {path} = modalParams as {
    path: {name: ResourceName; url: string};
  };
  const [serialNumber, setSerialNumber] = useState<string>('');

  const [product, setProduct] = useState<commercialProductsStock>();
  const [params, setParams] = useState<Params>({
    codes: [],
    products: [],
    stock_location: undefined,
  });
  const handleSearchSerialNumber = async () => {
    const result = await submitSerialNumber({
      codes: [...params.codes, serialNumber],
    });
    if (serverErrorSerialNumber.errors) {
      setSerialNumber('');
    }

    if ('data' in result) {
      setProduct(result.data?.meta);
      setParams(s => ({
        ...s,
        codes: [...s.codes, serialNumber],
        products: result.data?.data,
      }));
    }
  };
  const handleSearchSerialNumberSearch = (code: string) => {
    setSerialNumber(code);
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(loadData({name: path.name, url: path.url, page: 1}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    validationErrorsSerialNumber: serverErrorSerialNumber.errors,
    ErrorMessageSerialNumber: serverErrorSerialNumber.message,
    isLoadingSerialNumber,
    handleSearchSerialNumberSearch,
    serialNumber,
    product,
    submitSerialNumber: handleSearchSerialNumber,
    submit: handleSubmit,
  };
};

type Params = assembleProductRequest;

type commercialProductsStock = {
  variant: ProductVariant;
  document_id: ObjectId;
  document_ref: String;
  document_type: string;
  should_show_join_action: boolean;
};
