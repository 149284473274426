import React, {FC} from 'react';
import {InvoiceCompact} from '../../types/invoice';
import messages from './messages';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import {Trans, useTranslation} from 'react-i18next';

interface PayCellProps {
  row: InvoiceCompact;
}

const PayCell: FC<PayCellProps> = props => {
  const {row} = props;
  const {t} = useTranslation();
  switch (row.document_type) {
    case 'DEFAULT':
      if (row.payment_is_completed) {
        return (
          <AsyncPreviewTooltip type="INVOICE" id={row.id} column="pay">
            {row.overpayment > 0 ? (
              <span className="w-100 text-danger  text-center fw-bold  ">
                {t(...messages.overpaid())} ({row.overpayment})
              </span>
            ) : (
              <span className="w-100 text-success text-center fw-bold ">
                {t(...messages.paid())}
              </span>
            )}
          </AsyncPreviewTooltip>
        );
      } else {
        return <Trans i18nKey="numbers.currency" values={{val: row.pay}} />;
      }

    case 'CREDIT':
      if (row.used) {
        return (
          <AsyncPreviewTooltip type="INVOICE" id={row.id} column="pay">
            <span className="w-100 text-success text-center fw-bold ">
              {' '}
              {t(...messages.used())}
            </span>
          </AsyncPreviewTooltip>
        );
      } else {
        return <Trans i18nKey="numbers.currency" values={{val: row.pay}} />;
      }
    default:
      return <Trans i18nKey="numbers.currency" values={{val: row.pay}} />;
  }
};

export default PayCell;
