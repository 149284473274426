import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  client: () => __(translations.invoiceSummarySection.client, 'Client'),
  contact: () => __(translations.invoiceSummarySection.contact, 'Contact'),
  date: () => __(translations.invoiceSummarySection.date, 'Date'),
  status: () => __(translations.invoiceSummarySection.status, 'Status'),

  totalPurchaseCostsExcl: () => __(translations.invoiceSummarySection.totalPurchaseCostsExcl, 'Total purchase costs excl'),
  totalSellingPriceExcl: () => __(translations.invoiceSummarySection.totalSellingPriceExcl, 'Total selling price excl'),
  marginExcl: () => __(translations.invoiceSummarySection.marginExcl, 'Margin excl'),
  projectName: () => __(translations.invoiceSummarySection.projectName, 'Project '),
  transporter: () => __(translations.invoiceSummarySection.transporter, 'Transporter '),
  transparterPrice: () => __(translations.invoiceSummarySection.transparterPrice, 'price '),
  clientFamily: () => __(translations.invoiceSummarySection.clientFamily, 'Family '),
  moreInformations: () => __(translations.invoiceSummarySection.moreInformations, 'More informations'),
};
