import React, {FC} from 'react';
import messages from './messages';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Badge, Card, CardBody} from 'reactstrap';
import dismissModalWithResult from 'store/ui/actions/dismiss-modal-with-result';
import {Address} from 'types/address';

interface Props {
  address: Address;
}
const AddressItem: FC<Props> = props => {
  const {address} = props;

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleSelectAddress = () => {
    dispatch(dismissModalWithResult(address));
  };

  return (
    <div className="col-sm-6">
      <Card>
        <CardBody>
          <h5 className="font-size-15 mb-1 ">{address.address_type.name}</h5>
          <p className="mb-0">
            <span className="text-muted">{t(...messages.line1())} </span>
            {address.first_line}
          </p>
          <p className="mb-0">
            <span className="text-muted">{t(...messages.line2())} </span>
            {address.second_line}
          </p>
          <p className="text-muted mb-1">
            <span className="text-muted">{t(...messages.line3())} </span>
            {address.third_line}
          </p>
          <p className="text-muted mb-1">
            [{address.display_name_parts.join(',')}]
          </p>

          <h5 className="mb-0 font-size-12">{t(...messages.vatMode())}</h5>
          <Badge className="ms-1">{address.vat_mode?.name}</Badge>
        </CardBody>
        <Button
          className="rounded-top-0 btn-light"
          size="sm"
          onClick={handleSelectAddress}
        >
          {t(...messages.select())}
        </Button>
      </Card>
    </div>
  );
};

export default AddressItem;
