import {StockProduct} from 'features/stock/types/stock-products';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

const searchSerialNumber = api.injectEndpoints({
  endpoints: builder => ({
    searchSerialNumber: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `stock/search-serial-number`,
        params: {
          serial_number: params.serial_number,
        },
      }),
    }),
  }),
});

type Request = {
  page?: number;
  serial_number: string;
};

type Response = ResponseData<StockProduct>;

export const {useLazySearchSerialNumberQuery} = searchSerialNumber;
