import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationActionModal.title, 'Actions on quotation'),
  cancel: () => __(translations.quotationActionModal.cancel, 'Cancel'),
  yesImSure: () => __(translations.quotationActionModal.yesImSure, "Yes, I'm sure"),
  duplicateQuotationTitle: () => __(translations.quotationActionModal.duplicateQuotationTitle, 'Duplicate'),
  quotationDirectory: () => __(translations.quotationActionModal.quotationDirectory, 'Client'),
  quotationEmailModalTitle: () => __(translations.quotationActionModal.quotationEmailModalTitle, 'Send quoatation'),
  quotationDuplicateSucees: () => __(translations.quotationActionModal.quotationDuplicateSucees, 'Quote duplicated successfully…'),
  quotationEmailSendSubject: () => __(translations.quotationActionModal.quotationEmailSendSubject, 'E-mail Subject'),
  quotationEmailSubject: () =>
    __(
      translations.quotationActionModal.quotationEmailSubject,
      `Hello ...,

  Thank you for your visit.
  Please find enclosed your quotation.
  The delivery time is 12 to 14 weeks, on receipt of a 50% deposit.
  
  Please do not hesitate to contact me,
  
  Best regards,`
    ),
  quotationEmailAdd: () => __(translations.quotationActionModal.quotationEmailAdd, 'Add'),
  quotationEmailList: () => __(translations.quotationActionModal.quotationEmailList, 'Recipients'),
  quotationEmail: () => __(translations.quotationActionModal.quotationEmail, 'E-mail'),
};
