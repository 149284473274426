import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  UpdateCommercialSettingsRequest,
  useUpdateCommercialSettingsMutation,
} from 'store/global/endpoints/update-commercial-settings';
import selectCommercialSettings from 'store/global/selectors/select-commercial-settings';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [submit, {isLoading, error, isSuccess}] =
    useUpdateCommercialSettingsMutation();

  const serverError = parseValidationErrors(error);

  const [params, setParams] = useState<Params>({});
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  const cSettings = useSelector(selectCommercialSettings);

  useEffect(() => {
    if (cSettings) {
      setParams({
        ...cSettings,
      });
    }
  }, [cSettings]);

  return {
    setParams,
    params,
    isSuccess,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = UpdateCommercialSettingsRequest;
