import React from 'react';
import MasterProdudctStats from 'features/production/containers/master-product-stats/master-produdct-stats';

const LinkedResources = () => {
  return (
    <div>
      <MasterProdudctStats />
    </div>
  );
};

export default LinkedResources;
