import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import {useDuplicatQuotation} from './use-action-quotation-duplicate';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const QuotationDuplicatActionConfirm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {submit, isLoading, message, params, setParams, validationErrors} =
    useDuplicatQuotation(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <AsyncItemPicker
          className="my-2"
          endPoint="data/directories"
          label={t(...messages.quotationDirectory())}
          getOptionLabel={(c: any) => c?.display_name}
          errors={validationErrors.directory}
          value={params.directory}
          onChange={directory => setParams({...params, directory})}
        />
        <p>{message} </p>
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.duplicateQuotationTitle())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default QuotationDuplicatActionConfirm;
