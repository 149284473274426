import {updateCachedDirectoryAddress} from './get-directory-addresses';
import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const UpdateDirectoryComplementaryInfoFrom = api.injectEndpoints({
  endpoints: builder => ({
    UpdateDirectoryComplementaryInfoFrom: builder.mutation<
      Response,
      UpdateDirectoryComplementaryInfoFromRequest
    >({
      query: params => ({
        method: 'POST',
        url: `directories/${params.id}/additional-data`,
        body: {
          legal_form_id: params.legal_form?.id,
          siret: params.siret,
          ape: params.ape,
          vat_number: params.vat_number,
          vat_type: params.vat_type?.value,
          iban: params.iban,
          bic_swift: params.bic_swift,
        },
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;
          dispatch(updateCachedDirectoryFromResource(directory));
        } catch {}
      },
    }),
  }),
});

export type UpdateDirectoryComplementaryInfoFromRequest = {
  id: ObjectId;
  siret?: string;
  legal_form?: Option;
  ape?: string;
  vat_number?: string;
  vat_type?: Option;
  iban?: string;
  bic_swift?: string;
};

type Response = ResponseData<Directory>;

export const {useUpdateDirectoryComplementaryInfoFromMutation} =
  UpdateDirectoryComplementaryInfoFrom;
