import {translations} from 'locales/translations';
import {__} from 'utils/messages';

/**
 * Common application messages
 *
 */
export default {
  /**
   * Date formats
   *
   */
  datesAgo: () => __(translations.dates.ago, '{{ date, ago }}'),
  datesShort: () => __(translations.dates.short, '{{ date, short }}'),
  datesLong: () => __(translations.dates.long, '{{ date, long }}'),
  datesRelative: () => __(translations.dates.relative, '{{ date, relative }}'),
  intlDateTime: () => __(translations.dates.dateTime, '{{val, datetime}}'),

  /**
   * Numbers
   *
   */
  currency: () => __(translations.numbers.currency, '{{val, currency(EUR)}}'),
  naturalNumber: () => __(translations.numbers.naturalNumber, '{{val, number(maximumFractionDigits: 0)}}'),
  percent: () => __(translations.numbers.percent, '{{val, number, percent}}'),
  edit: () => __(translations.common.edit, 'Edit'),

  add: () => __(translations.common.add, 'New'),
  cancel: () => __(translations.common.cancel, 'Cancel'),
  changesCommited: () => __(translations.common.changesCommited, 'Your changes has been saved successfully!'),
  dashboard: () => __(translations.common.dashboard, 'Dashboard'),
  export: () => __(translations.common.export, 'Export'),
  date: () => __(translations.common.date, 'Date'),
  close: () => __(translations.common.close, 'Close'),
  reload: () => __(translations.common.reload, 'Reload'),
  saveChanges: () => __(translations.common.saveChanges, 'Save changes'),
  doubleClickToRemove: () => __(translations.common.doubleClickToRemove, 'Double click to remove'),
  submit: () => __(translations.common.submit, 'Submit'),
  duplicat: () => __(translations.common.duplicat, 'Duplicat'),
  send: () => __(translations.common.send, 'Send'),
  validate: () => __(translations.common.validate, 'Validate'),
  name: () => __(translations.common.name, 'Name'),
  unlock: () => __(translations.common.unlock, 'Unlock'),
  newRecords: () => __(translations.common.newRecords, 'New Records'),
  changesDetected: () => __(translations.common.changesDetected, 'Changes detected'),
  noData: () => __(translations.common.noData, 'No Data'),
  preview: () => __(translations.common.preview, 'Preview'),
  print: () => __(translations.common.print, 'Print'),
  search: () => __(translations.common.search, 'Search...'),
  notAllowed: () => __(translations.common.notAllowed, 'This action is not allowed'),
};
