import React from 'react';
import columns from './columns';
import CustomerOrderControls from './customer-orders-controls/customer-orders-controls';
import messages from './messages';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import {CompactCustomerOrder} from 'features/commercial/orders/types/order';

const CustomerOrdersPage = () => {
  const {t} = useTranslation();
  const makeRowClassName = (row: CompactCustomerOrder) =>
    row.status === 'VALIDATED' ? 'bg-gray-light' : '';

  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>

      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.title()), path: '#'},
          ]}
        />
        <Table
          url="orders?type=CLIENT"
          otherParams={{
            delimiter: '|',
          }}
          hideSearch
          columns={columns}
          makeRowClassName={makeRowClassName}
          filtersComponent={CustomerOrderControls}
          name="customer-orders"
        />
      </Container>
    </div>
  );
};

export default CustomerOrdersPage;
