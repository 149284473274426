import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  approved: () => __(translations.statusBadge.approved, 'Approved'),
  available: () => __(translations.statusBadge.available, 'Available'),
  awaitingShipment: () => __(translations.statusBadge.awaitingShipment, 'Awaiting Shipment'),
  awaitingTransport: () => __(translations.statusBadge.awaitingTransport, 'Awaiting transport'),
  blocked: () => __(translations.statusBadge.blocked, 'Blocked'),
  canceled: () => __(translations.statusBadge.canceled, 'Canceled'),
  charged: () => __(translations.statusBadge.charged, 'Charged'),
  closed: () => __(translations.statusBadge.closed, 'Closed'),
  completed: () => __(translations.statusBadge.completed, 'Completed'),
  deducted: () => __(translations.statusBadge.deducted, 'Deducted'),
  defaulted: () => __(translations.statusBadge.defaulted, 'Defaulted'),
  partiallyInProduction: () => __(translations.statusBadge.partiallyInProduction, 'Partially in production'),
  deleted: () => __(translations.statusBadge.deleted, 'Deleted'),
  disabled: () => __(translations.statusBadge.disabled, 'Disabled'),
  enabled: () => __(translations.statusBadge.enabled, 'Enabled'),
  expired: () => __(translations.statusBadge.expired, 'Expired'),
  failed: () => __(translations.statusBadge.failed, 'Failed'),
  inProgress: () => __(translations.statusBadge.inProgress, 'In Progress'),
  inProduction: () => __(translations.statusBadge.inProduction, 'In production'),
  partiallyInStock: () => __(translations.statusBadge.partiallyInStock, 'Partially in stock'),
  inReview: () => __(translations.statusBadge.inReview, 'In Review'),
  validated: () => __(translations.statusBadge.validated, 'Validated'),
  invoiced: () => __(translations.statusBadge.invoiced, 'Invoiced'),
  ongoing: () => __(translations.statusBadge.ongoing, 'Ongoing'),
  opened: () => __(translations.statusBadge.opened, 'Opened'),
  partiallyDeducted: () => __(translations.statusBadge.partiallyDeducted, 'Partially Deducted'),
  partiallyDefaulted: () => __(translations.statusBadge.partiallyDefaulted, 'Partially Defaulted'),
  partiallyReceived: () => __(translations.statusBadge.partiallyReceived, 'Partially Received'),
  pending: () => __(translations.statusBadge.pending, 'Pending'),
  rejected: () => __(translations.statusBadge.rejected, 'Rejected'),
  used: () => __(translations.statusBadge.used, 'Used'),
  new: () => __(translations.statusBadge.new, 'New'),
  archived: () => __(translations.statusBadge.archived, 'Archived'),
  inStock: () => __(translations.statusBadge.inStock, 'In Stock'),
  finalized: () => __(translations.statusBadge.finalized, 'Finalized'),
  potential: () => __(translations.statusBadge.potential, 'Potential'),
  without_follow_up: () => __(translations.statusBadge.without_follow_up, 'Without follow up'),
  in_progress: () => __(translations.statusBadge.in_progress, 'In Progress'),
  refund: () => __(translations.statusBadge.refund, 'Refund'),
  refunded: () => __(translations.statusBadge.refunded, 'Refunded'),
  waiting_for_delivery: () => __(translations.statusBadge.waiting_for_delivery, 'Waiting for delivery'),
  delivered: () => __(translations.statusBadge.delivered, 'Delivered'),
};
