export default () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz';

  let rand = '';

  for (let i = 0; i < 16; i++) {
    const index = Math.floor(Math.random() * characters.length);
    rand += characters[index];
  }
  return rand;
};
