export const columnClassNames = [
  'text-center',
  'text-center',
  'text-center',
  'text-center stock-text--success',
  'text-center stock-text--success',
  'text-center stock-text--success',
  'text-center stock-text--success',
  'text-center',
  'text-center',
  'text-center',
  'text-center bg-light fw-bold',
  'text-center bg-light fw-bold',
  'text-center',
  'text-center stock-text--success',
  'text-center stock-text--success',
  'text-center stock-text--info',
  'text-center stock-text--success',
];
