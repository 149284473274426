import React from 'react';
import {FC} from 'react';
import messages from '../messages';
import {useProductForm} from './use-product-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Alert, Card, CardBody, Col, Label, Row} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {ProductFamily} from 'types/product-family';

const CreateProductForm: FC<{}> = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useProductForm();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };

  return (
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col md={8}>
            <TextInput
              label={t(...messages.name())}
              className="mb-1"
              data-testid="product-name-input"
              onKeyDown={handleKeyboardEventDown}
              value={params.name}
              errors={validationErrors.name}
              onChange={name => setParams({...params, name})}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={4}>
            <AsyncItemPicker
              endPoint="data/product-collections"
              className="mb-1"
              label={t(...messages.collection())}
              optionLabel=""
              errors={validationErrors.product_collection_id}
              value={params.collection}
              onChange={collection => setParams({...params, collection})}
            />
          </Col>
          <Col md={4}>
            <div className="d-flex flex-row align-items-center">
              <div className="flex-grow-1 me-3">
                <AsyncItemPicker
                  key={`products-families-${params.collection?.id}`}
                  endPoint="data/product-families"
                  className="mb-1"
                  otherParams={{
                    product_collections: params.collection?.id,
                  }}
                  disabled={!params.collection}
                  label={t(...messages.family())}
                  optionLabel=""
                  errors={validationErrors.product_family_id}
                  getOptionLabel={(x: ProductFamily) => (
                    <div>
                      <div>{x.name}</div>
                      <span className="text-muted">{x.inspiration}</span>
                    </div>
                  )}
                  isMulti
                  value={params.families}
                  onChange={families => setParams({...params, families})}
                />
              </div>
              <div className=" pt-3 ">
                <Button
                  className="w-100 "
                  color="light"
                  onClick={() => dispatch(showModal(MODAL.FAMILY_FORM))}
                >
                  <i className="bx bx-plus me-1" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={8}>
            <AsyncItemPicker
              endPoint="data/vats"
              className="mb-1"
              label={t(...messages.vat())}
              errors={validationErrors.vat_id}
              getOptionLabel={(v: any) => v.name}
              value={params.vat}
              onChange={vat => setParams({...params, vat})}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={8}>
            <TextInput
              rows={4}
              type="textarea"
              label={t(...messages.description())}
              value={params.description}
              errors={validationErrors.description}
              onChange={description => setParams({...params, description})}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={8}>
            <TextInput
              type="textarea"
              label={t(...messages.comment())}
              rows={4}
              value={params.comment}
              errors={validationErrors.comment}
              onChange={comment => setParams({...params, comment})}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="form-check my-3">
              <label className="form-check-label" htmlFor="form-check-left">
                {t(...messages.inCatalog())}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="form-check-left"
                checked={params.in_catalog}
                onChange={ev =>
                  setParams({...params, in_catalog: ev.target.checked})
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div className="my-3">
              {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <AppBarActions>
              <Button isLoading={isLoading} onClick={submit} color="important">
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export default CreateProductForm;
