import {layoutActions} from './layout-slice';
import {AppTheme, LayoutType, LeftSideBarTheme, LeftSideBarType} from './types';
import {PayloadAction} from '@reduxjs/toolkit';
import {SharedPrefs} from 'constants/shared-prefs';
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {saveString} from 'services/storage-service';

/**
 * Changes the body attribute
 *
 */
function changeBodyAttribute(attribute: any, value: any) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass: any, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({payload: layout}: PayloadAction<LayoutType>) {
  if (layout === 'horizontal') {
    yield put(layoutActions.changeTopbarTheme('light'));
    document.body.removeAttribute('data-sidebar');
    yield call(changeBodyAttribute, 'data-sidebar-size', 'lg');
    if (document.body.getAttribute('data-bs-theme') === 'dark') {
      yield put(layoutActions.changeTopbarTheme('dark'));
    }
  } else {
    yield put(layoutActions.changeTopbarTheme('light'));
    if (document.body.getAttribute('data-bs-theme') === 'dark') {
      yield put(layoutActions.changeTopbarTheme('dark'));
    }
  }
  yield call(changeBodyAttribute, 'data-layout', layout);
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({payload: width}: any) {
  yield call(changeBodyAttribute, 'data-layout-size', width);
}

/**
 * Changes the layout postion
 * @param {*} param0
 */
function* changeLayoutPosition({payload: theme}: any) {
  yield call(changeBodyAttribute, 'data-layout-scrollable', theme);
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme(a: PayloadAction<LeftSideBarTheme>) {
  yield call(changeBodyAttribute, 'data-sidebar', a.payload);
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({payload: theme}: any) {
  yield call(changeBodyAttribute, 'data-topbar', theme);
}

/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changelayoutMode({payload: layoutMode}: PayloadAction<AppTheme>) {
  saveString(SharedPrefs.APP_THEME, layoutMode);
  yield call(changeBodyAttribute, 'data-bs-theme', layoutMode);
  if (layoutMode === 'light') {
    yield put(layoutActions.changeTopbarTheme('light'));
  } else if (layoutMode === 'dark') {
    yield put(layoutActions.changeTopbarTheme('dark'));
  }
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType(action: PayloadAction<LeftSideBarType>) {
  switch (action.payload) {
    case 'md':
      yield call(manageBodyClass, 'sidebar-enable', 'remove');
      yield call(changeBodyAttribute, 'data-sidebar-size', 'md');
      break;
    case 'sm':
      yield call(manageBodyClass, 'sidebar-enable', 'remove');
      yield call(changeBodyAttribute, 'data-sidebar-size', 'sm');
      break;
    default:
      yield call(manageBodyClass, 'sidebar-enable', 'add');
      yield call(changeBodyAttribute, 'data-sidebar-size', 'lg');
      break;
  }
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  yield call(manageBodyClass, 'right-bar-enabled', 'add');
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(layoutActions.changeLayout.type, changeLayout);
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(layoutActions.changeLayoutWidth.type, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(
    layoutActions.changeSidebarTheme.type,
    changeLeftSidebarTheme
  );
}

export function* watchChangeLayoutPosition() {
  yield takeEvery(
    layoutActions.changeLayoutPosition.type,
    changeLayoutPosition
  );
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(layoutActions.changeSidebarType.type, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(layoutActions.changeTopbarTheme.type, changeTopbarTheme);
}

export function* watchChangelayoutMode() {
  yield takeEvery(layoutActions.changeLayoutTheme.type, changelayoutMode);
}

export function* watchShowRightSidebar() {
  yield takeEvery(layoutActions.showRightSidebar.type, showRightSidebar);
}

function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchShowRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchChangelayoutMode),
    fork(watchChangeLayoutPosition),
  ]);
}

export default LayoutSaga;
