/**
 * Combine all reducers in this file and export the combined reducers.
 */
import {productsStockGridSlice} from 'features/stock/products-stock-grid/store';
import accountSlice from './account/account-slice';
import authSlice from './auth/auth-slice';
import directorySlice from './directory/directory-slice';
import globalSlice from './global/global-slice';
import layoutSlice from './layout/layout-slice';
import productionSlice from './production/production-slice';
import uiSlice from './ui/ui-slice';
import userSlice from './users/user-slice';
import {Reducer, combineReducers} from '@reduxjs/toolkit';
import commercialDocumentSlice from 'features/commercial/documents/store/slice';
import {listingSlice} from 'features/listing/list/slice';
import {tableSlice} from 'features/table/slice';
import costsFormSlice from 'pages/dashboard/products/edit/variant/costs/slice';
import {api, blobApi} from 'services/api';
import {productionMonitoringSlice} from 'features/stock/production-monitoring/store';

/**
 * Merges the main reducer with injected reducers
 *
 */
export function createReducer(injectedReducers?: {[key: string]: Reducer}) {
  // Initially we don't have any injectedReducers
  return combineReducers({
    [api.reducerPath]: api.reducer,
    [blobApi.reducerPath]: blobApi.reducer,
    account: accountSlice.reducer,
    auth: authSlice.reducer,
    costs: costsFormSlice.reducer,
    directory: directorySlice.reducer,
    global: globalSlice.reducer,
    commercialDocument: commercialDocumentSlice.reducer,
    layout: layoutSlice.reducer,
    listing: listingSlice.reducer,
    table: tableSlice.reducer,
    productsStockGrid: productsStockGridSlice.reducer,
    productionMonitoring: productionMonitoringSlice.reducer,
    production: productionSlice.reducer,
    ui: uiSlice.reducer,
    users: userSlice.reducer,
    ...injectedReducers,
  });
}
