import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  general: () => __(translations.ordersConstants.general, 'General'),
  details: () => __(translations.ordersConstants.details, 'Details'),
  relatedResources: () => __(translations.ordersConstants.relatedResources, 'Related resources'),
  actions: () => __(translations.ordersConstants.actions, 'Actions'),
  logs: () => __(translations.ordersConstants.logs, 'Logs'),
};
