import React from 'react';
import messages from './messages';
import useMailingForm from './use-commercial-settings-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {CardTitle, Col, Row} from 'reactstrap';
import SectionSeparator from 'components/separator/section-separator';

const CommercialTab = () => {
  const {t} = useTranslation();

  const {
    isLoading,
    errorMessage,
    isSuccess,
    submit,
    validationErrors,
    params,
    setParams,
  } = useMailingForm();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="mb-0">
          <h5 className="font-size-15">{t(...messages.title())}</h5>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div>
          <div className="mt-2 pt-50">
            <SectionSeparator title={t(...messages.margeAlert())} />
            <Row className="mb-3">
              <h6>{t('pricingPage.publicPricing')}</h6>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMin())}
                  value={params.public_pricing_scale_min}
                  type="number"
                  onChange={min =>
                    setParams({
                      ...params,
                      public_pricing_scale_min: Number(min),
                    })
                  }
                  errors={validationErrors?.public_pricing_scale_min}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMax())}
                  value={params.public_pricing_scale_max}
                  type="number"
                  onChange={max =>
                    setParams({
                      ...params,
                      public_pricing_scale_max: Number(max),
                    })
                  }
                  errors={validationErrors?.public_pricing_scale_max}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <h6>{t('pricingPage.resellerPricing')}</h6>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMin())}
                  value={params.reseller_pricing_scale_min}
                  type="number"
                  onChange={min =>
                    setParams({
                      ...params,
                      reseller_pricing_scale_min: Number(min),
                    })
                  }
                  errors={validationErrors?.reseller_pricing_scale_min}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMax())}
                  value={params.reseller_pricing_scale_max}
                  type="number"
                  onChange={max =>
                    setParams({
                      ...params,
                      reseller_pricing_scale_max: Number(max),
                    })
                  }
                  errors={validationErrors?.reseller_pricing_scale_max}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <h6>{t('pricingPage.decoratorPricing')}</h6>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMin())}
                  value={params.decorator_pricing_scale_min}
                  type="number"
                  onChange={min =>
                    setParams({
                      ...params,
                      decorator_pricing_scale_min: Number(min),
                    })
                  }
                  errors={validationErrors?.decorator_pricing_scale_min}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.pricingScaleMax())}
                  value={params.decorator_pricing_scale_max}
                  type="number"
                  onChange={max =>
                    setParams({
                      ...params,
                      decorator_pricing_scale_max: Number(max),
                    })
                  }
                  errors={validationErrors?.decorator_pricing_scale_max}
                />
              </Col>
            </Row>
            <SectionSeparator title={t(...messages.vipCondition())} />

            <Row>
              <TextInput
                label={t(...messages.vipThreshold())}
                value={params.vip_threshold}
                onChange={vip_threshold =>
                  setParams({...params, vip_threshold})
                }
                errors={validationErrors?.vip_threshold}
              />
              <Col sm="6" className="mb-1"></Col>
            </Row>

            <Row>
              <Col className="mt-2" sm="12">
                <AppBarActions>
                  <Button
                    className="me-1"
                    onClick={submit}
                    isLoading={isLoading}
                    color="important"
                  >
                    <Icon name="check" className="me-2" size={15} />
                    <Trans i18nKey="common.saveChanges" />
                  </Button>
                </AppBarActions>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CommercialTab;
