import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  client: () => __(translations.quotationSummarySection.client, 'Client'),
  contact: () => __(translations.quotationSummarySection.contact, 'Contact'),
  date: () => __(translations.quotationSummarySection.date, 'Date'),
  status: () => __(translations.quotationSummarySection.status, 'Status'),
  exportPrintFormatEN: () => __(translations.quotationSummarySection.exportPrintFormatEN, 'Print format ( EN )'),
  exportEmailFormatFR: () => __(translations.quotationSummarySection.exportEmailFormatFR, 'Print format ( FR )'),
  totalPurchaseCostsExcl: () => __(translations.quotationSummarySection.totalPurchaseCostsExcl, 'Total purchase costs excl'),
  totalSellingPriceExcl: () => __(translations.quotationSummarySection.totalSellingPriceExcl, 'Total selling price excl'),
  marginExcl: () => __(translations.quotationSummarySection.marginExcl, 'Margin excl'),
  projectName: () => __(translations.quotationSummarySection.projectName, 'Project '),
  transporter: () => __(translations.quotationSummarySection.transporter, 'Transporter '),
  transparterPrice: () => __(translations.quotationSummarySection.transparterPrice, 'price '),
  clientFamily: () => __(translations.quotationSummarySection.clientFamily, 'Family '),
  moreInformations: () => __(translations.quotationSummarySection.moreInformations, 'More informations'),
};
