import {api} from 'services/api';
import {ListResponse} from 'types/list-response';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getProductLinkedResourcesStats = api.injectEndpoints({
  endpoints: builder => ({
    getProductLinkedResourcesStats: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `products/${params.id}/stats`,
      }),
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<
  {
    ca: number;
    selling_count: number;
    year: number;
  }[]
>;

export const {useGetProductLinkedResourcesStatsQuery} =
  getProductLinkedResourcesStats;
