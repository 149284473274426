import React, {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button, CardBody, Col, Row} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

const MaterialControls = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const addMaterial = () => {
    dispatch(
      showModal(MODAL.MATERIAL_MODAL, {
        store: true,
      })
    );
  };
  return (
    <Row>
      <Col>
        <CardBody>
          <Row className="mb-3">
            <AppBarActions>
              <Button
                className="btn btn-primary text-light"
                color="important"
                onClick={addMaterial}
              >
                <Icon name="plus" className="me-1" size={15} />
                {t(...messages.addMaterial())}
              </Button>
            </AppBarActions>
          </Row>
        </CardBody>
      </Col>
    </Row>
  );
};

export default MaterialControls;
