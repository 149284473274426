import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import queryParams from 'utils/query-params';

const getDirectoryExport = api.injectEndpoints({
  endpoints: builder => ({
    getDirectoryExport: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `export/directories`,
          params: queryParams(params, '|'),
        };
      },
    }),
  }),
});

type Request = {};

type Response = ResponseData<{message: string}>;

export const {useLazyGetDirectoryExportQuery} = getDirectoryExport;
