import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  contactList: () => __(translations.updateupdateContatListTab.contactList, 'Contact list'),
  title: () => __(translations.updateContatListTab.addContactList, 'Contact list of {{name}}'),
  contact: () => __(translations.updateContatListTab.contact, 'Contact'),
  comment: () => __(translations.updateContatListTab.comment, 'comment'),
  addContactList: () => __(translations.updateContatListTab.addContactList, 'Add Contact'),
  addNewContact: () => __(translations.updateContatListTab.addNewContact, 'Add new contact'),
  job: () => __(translations.updateContatListTab.job, 'Job'),
};
