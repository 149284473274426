export const variantTabs = [
  {
    to: 'general',
    title: 'general',
  },
  {
    to: 'stock',
    title: 'stock',
  },
  {
    to: 'components',
    title: 'components',
  },
  {
    to: 'translations',
    title: 'translations',
  },
  {
    to: 'costs',
    title: 'costs',
  },
  {
    to: 'manifacture-time',
    title: 'manifacturingTime',
  },
  {
    to: 'attachments',
    title: 'attachments',
  },
  {
    to: 'linked-resources',
    title: 'linkedResources',
  },

  {
    to: 'serial-number',
    title: 'serialNumber',
  },
];

export const productTabs = [
  {
    to: 'general',
    title: 'general',
  },
  {
    to: 'customization-options',
    title: 'customizationOptions',
  },
  {
    to: 'linked-resources',
    title: 'linkedResources',
  },
  {
    to: 'other',
    title: 'other',
  },
];
