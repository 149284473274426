import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import selectCommercialSettings from 'store/global/selectors/select-commercial-settings';

interface Props {
  value: string;
}
interface IndicatorProps {
  value: number;
  min?: number;
  max?: number;
}

const Indicator: FC<IndicatorProps> = props => {
  const {value, min, max} = props;

  if (min && value <= min) {
    return (
      <span className="text-danger">
        {value}
        <i className="mdi mdi-arrow-down-bold" />
      </span>
    );
  } else if (max && value >= max) {
    return (
      <span className="text-success">
        {value}
        <i className="mdi mdi-arrow-up-bold" />
      </span>
    );
  } else {
    return <span>{value}</span>;
  }
};

export const PublicPriceIndicator: FC<Props> = props => {
  const {value} = props;
  const number = Number(value);
  const commercial = useSelector(selectCommercialSettings);
  const {public_pricing_scale_max, public_pricing_scale_min} = commercial;

  return (
    <Indicator
      value={number}
      min={public_pricing_scale_min}
      max={public_pricing_scale_max}
    />
  );
};

export const ResellerPriceIndicator: FC<Props> = props => {
  const {value} = props;
  const number = Number(value);
  const commercial = useSelector(selectCommercialSettings);
  const {reseller_pricing_scale_max, reseller_pricing_scale_min} = commercial;

  return (
    <Indicator
      value={number}
      min={reseller_pricing_scale_min}
      max={reseller_pricing_scale_max}
    />
  );
};
export const DecoratorPriceIndicator: FC<Props> = props => {
  const {value} = props;
  const number = Number(value);
  const commercial = useSelector(selectCommercialSettings);
  const {decorator_pricing_scale_max, decorator_pricing_scale_min} = commercial;

  return (
    <Indicator
      value={number}
      min={decorator_pricing_scale_min}
      max={decorator_pricing_scale_max}
    />
  );
};
