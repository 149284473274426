import {Order} from '../../types/order';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const duplicatOrder = api.injectEndpoints({
  endpoints: builder => ({
    duplicatOrder: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `/orders/${params.id}/duplicate`,
          body: {
            directory_id: params.directory_id,
          },
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  directory_id?: ObjectId;
};

type Response = ResponseData<Order>;

export const {useDuplicatOrderMutation} = duplicatOrder;
