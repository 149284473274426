import {useState, useEffect} from 'react';
import {Invoice} from '../../../types/invoice';
import {useSendEmailInvoiceMutation} from 'features/commercial/invoices/store/endpoints/action-invoice-send-email';
import {useGetInvoiceDefaultMailContentQuery} from 'features/commercial/invoices/store/endpoints/get-invoice-default-mail-content';
import {selectInvoice} from 'features/commercial/invoices/store/endpoints/show-invoice';
import {produce} from 'immer';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ObjectId} from 'types/object-id';
import {Email} from 'types/phone';
import makeId from 'utils/make-id';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSendInvoiceAsEmail = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useSendEmailInvoiceMutation();

  const modalParams = useSelector(selectActiveModalParams);

  const {message, id} = modalParams;

  const {data} = useSelector(selectInvoice({id}));

  const invoice = data?.data as Invoice;

  const locale = invoice?.language;

  const response = useGetInvoiceDefaultMailContentQuery({
    local: locale?.value as string,
  });

  const [email, setEmail] = useState<EmailMap>({
    '1': {
      id: '1',
      email: invoice?.directory?.main_directory_email?.email,
    },
  });

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const updateEmail = (p: Email, k: keyof Email, v: any) => {
    setEmail(
      produce(email, draft => {
        // @ts-ignore this typescript bug
        draft[p.id][k] = v;
      })
    );
  };

  const removeEmail = (id: ObjectId) => {
    const _email: EmailMap = Object.assign({}, email);
    delete _email[id];
    setEmail({
      ..._email,
    });
  };

  const addEmail = () => {
    const p: Email = {
      id: makeId(),
      email: __DEV__ ? fake.email : '',
    };
    setEmail(
      produce(email, draft => {
        draft[p.id] = p;
      })
    );
  };

  const [params, setParams] = useState<Params>({
    body: undefined,
    emails: undefined,
    stock_summary: false,
    with_invoice: true,
    packing_list: false,
  });

  useEffect(() => {
    if (response.data?.data && response.data?.data.content) {
      setParams(prevParams => ({
        ...prevParams,
        body: response.data?.data.content,
      }));
    }
  }, [response.data?.data]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const emails = Object.values(email).map(emailObj => emailObj.email) as (
      | string
    )[];
    const result = await submit({
      id,
      body: params?.body,
      emails: emails,
      stock_summary: params.stock_summary,
      with_invoice: params.with_invoice,
      packing_list: params.packing_list,
    });
    if ('data' in result) {
      navigate(`/dashboard/invoices/${id}/actions`);
      dispatch(toast('success', result?.data?.message));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    updateEmail,
    email,
    removeEmail,
    addEmail,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = {
  body?: string;
  emails?: string[];
  stock_summary: boolean;
  with_invoice: boolean;
  packing_list: boolean;
};

export type EmailMap = Record<ObjectId, Email>;
