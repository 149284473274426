import React from 'react';
import messages from './messages';
import {Single, useComponentsForm} from './use-components-tab';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {MODAL} from 'features/modal-notch/constants';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Row, Col, Alert} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import getVariantName from 'utils/row-getters/get-variant-name';
import {CompactVariant} from 'types/compact-variant';

const PricingComponentsTab = () => {
  const {
    addComponent,
    components,
    errorMessage,
    isLoading,
    isSuccess,
    removeComponent,
    submit,
    directory,
    updateComponent,
    validationErrors,
    filterOption,
  } = useComponentsForm();

  const dispatch = useDispatch();
  const {t} = useTranslation();

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <SectionSeparator
            title={t(...messages.title(), {name: directory?.display_name})}
          />
        </Col>
        <Col md="auto" className="">
          {/* y */}
          <Button color="important" className="me-3" onClick={addComponent}>
            <Icon name="plus" className="me-1" size={18} />
            {t(...messages.addComponent())}
          </Button>

          <Button
            color="light"
            onClick={() => dispatch(showModal(MODAL.PRICING_ACTION, directory))}
          >
            {t(...messages.actionBtn())}
          </Button>
        </Col>
      </Row>

      {Object.values(components)
        .reverse()
        .map((p: Single, i) => (
          <Row className="mb-3" key={p.variant?.id || p.id}>
            <Col>
              <AsyncItemPicker
                label={t(...messages.label())}
                filterOption={filterOption}
                getOptionLabel={(xx: CompactVariant) => {
                  return getVariantName(xx);
                }}
                menuPlacement="top"
                endPoint="data/components-with-self-composed"
                errors={validationErrors[`variants.${i}.variant_id`]}
                value={p.variant}
                onChange={x => updateComponent(p, 'variant', x)}
              />
            </Col>

            <Col md={3}>
              <TextInput
                label={t(...messages.cost())}
                type="number"
                errors={validationErrors[`variants.${i}.cost`]}
                value={p.cost}
                onChange={x => updateComponent(p, 'cost', x)}
              />
            </Col>

            <Col className="align-items-end d-flex" md={1}>
              <Button
                color="link"
                className="text-danger"
                onClick={() => removeComponent(p.id, p.variant?.id)}
              >
                <Icon name="trash-2" size={18} />
              </Button>
            </Col>
          </Row>
        ))}

      <Row>
        <Col md={12} className=" mb-4">
          {!directory?.is_archived && (
            <AppBarActions>
              <Button isLoading={isLoading} color="important" onClick={submit}>
                <Icon name="check" size={15} className="me-1" />
                {t('common.saveChanges')}
              </Button>
            </AppBarActions>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PricingComponentsTab;
