import React from 'react';
import messages from './messages';
import {useProductActions} from './use-product-actions';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import Can from 'features/hylian-shield/can';

const ActionsTab = () => {
  const {t} = useTranslation();
  const {id, variant} = useParams();

  const {
    duplicate,
    handleDeleteMany,
    handleBlockMany,
    handleToOldCollectionMany,
    is_all_variant_blocked,
  } = useProductActions();
  return (
    <div className="order-1 d-flex">
      <NavLink
        to={`/dashboard/products/${id}/general`}
        className="btn btn-light mx-2"
      >
        <i className="me-1"></i>
        <span>{t('productsPage.masterProduct')}</span>
      </NavLink>
      <Can action="create" subject="products">
        <NavLink
          to={`/dashboard/products/${id}/add-variant`}
          className="btn btn-light"
        >
          <Icon name="plus" size={16} />

          <i className="me-1"></i>
          <span>{t('editProductPage.createVariant')}</span>
        </NavLink>
      </Can>
      <Button color="light" className="text-nowrap ms-2" onClick={duplicate}>
        <Icon name="copy" size={15} className="me-2" />
        {t('common.duplicat')}
      </Button>
      {/* <Button
        className="ms-2"
        color="light"
        onClick={handleToOldCollectionMany}
      >
        <Icon name="archive" size={15} className="me-2" />
        {t(...messages.toOldCollection())}
      </Button> */}

      <Button
        disabled={is_all_variant_blocked}
        className="ms-2"
        color="light"
        onClick={handleBlockMany}
      >
        <Icon name="archive" size={15} className="me-2" />
        {t(...messages.blocked())}
      </Button>
      <Can action="delete-and-merge" subject="products">
        <Button className="ms-2" color="danger" onClick={handleDeleteMany}>
          {t(...messages.deleteProduct())}
        </Button>
      </Can>
    </div>
  );
};

export default ActionsTab;
