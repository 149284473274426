import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  material: () => __(translations.materialsPage.material, 'Material'),
  title: () => __(translations.materialsPage.title, 'materials'),
  nameFrench: () => __(translations.materialsPage.nameFrench, 'Name in french'),
  nameEnglish: () => __(translations.materialsPage.nameEnglish, 'Name in english'),
  update: () => __(translations.materialsPage.update, 'Update'),
  code: () => __(translations.materialsPage.code, 'Code'),
  addMaterial: () => __(translations.materialsPage.addMaterial, 'Add material'),
  nameFr: () => __(translations.materialsPage.nameFr, 'Name in French'),
  nameEn: () => __(translations.materialsPage.nameEn, 'Name in English'),
  materialCode: () => __(translations.materialsPage.materialCode, 'material code'),
  yesImSure: () => __(translations.materialsPage.yesImSure, 'Yes im sure'),
  cancel: () => __(translations.materialsPage.cancel, 'Cancel'),
  materialCreatedSuccess: () => __(translations.materialsPage.materialCreatedSuccess, 'Material created successfully'),
  materialArchivedSuccess: () => __(translations.materialsPage.materialArchivedSuccess, 'Material deleted successfully'),
  materialUpdatedSuccess: () => __(translations.materialsPage.materialUpdatedSuccess, 'Material updated successfully'),
  deleteAlertMessage: () => __(translations.materialsPage.deleteAlertMessage, 'Are you sure you want to delete this material?'),
  actionOnMaterials: () => __(translations.materialsPage.actionOnMaterials, 'Action on materials'),
};
