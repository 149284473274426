import {ContactTypesEnum} from 'constants/contact';
import {TeamTypesEnum} from 'constants/team';
import {IRegroupedContact} from 'pages/dashboard/studio/edit/sections/contacts/use-contacts-form';
import {IRegroupedTeam} from 'pages/dashboard/studio/edit/sections/team/types';
import {IContact, IExpense, ITeam} from 'types/project';
import {ProjectAttachmentTypesEnum} from '../constants/project-attachments';
import {ProjectAttachment} from 'types/attachements';
import {IRegroupedAttachments} from 'pages/dashboard/studio/edit/sections/attachments/use-attachments-form';
import {ExpensesTypesEnum} from '../constants/project-expenses';
import {IRegroupedExpenses} from 'pages/dashboard/studio/edit/sections/expenses/use-expenses-form';

type Entity = 'contacts' | 'team' | 'attachments' | 'expenses';
type Data = IContact[] | ITeam[] | ProjectAttachment[] | IExpense[];

export const useGroupByType = (data: Data, type: Entity) => {
  let Enum: Record<string, any>;

  switch (type) {
    case 'contacts':
      Enum = ContactTypesEnum;
      break;
    case 'team':
      Enum = TeamTypesEnum;
      break;
    case 'attachments':
      Enum = ProjectAttachmentTypesEnum;
      break;
    case 'expenses':
      Enum = ExpensesTypesEnum;
      break;
    default:
      return;
  }

  return (Object.keys(Enum) as Array<keyof typeof Enum>).map(type => {
    return {
      type,
      data: data
        .map((element, identifier) => ({...element, identifier}))
        .filter(element => element.type === type),
    };
  }) as
    | IRegroupedTeam[]
    | IRegroupedContact[]
    | IRegroupedAttachments[]
    | IRegroupedExpenses[];
};
