import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {RedrectTo} from 'types/redrect-to';
import {ResponseData} from 'types/response-data';

const transferQuote = api.injectEndpoints({
  endpoints: builder => ({
    transferQuote: builder.mutation<TransferResponse, TransferQuoteRequest>({
      query: params => {
        const itemsObjectified =
          params.items ?? ({} as Record<ObjectId, boolean>);
        const commercial_products = Object.keys(params.items ?? {})
          .filter(x => itemsObjectified[x])
          .map(id => ({id}));

        return {
          method: 'POST',
          url: `/quotations/${params.id}/transfer`,
          body: {
            commercial_products,
            type_to_transfer: params.type_to_transfer,
          },
        };
      },
    }),
  }),
});

export type TransferQuoteRequest = {
  id?: ObjectId;
  items?: Record<ObjectId, boolean>;
  type_to_transfer: TransferType;
};

export type TransferType = 'order_client' | 'invoice';

export type TransferResponse = ResponseData<Quotation> & {
  redirect_to: {
    id: string;
    model_type: 'Order' | 'Invoice';
    model_ref: string;
  };
};

export const {useTransferQuoteMutation} = transferQuote;
