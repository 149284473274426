import selectPublicPrice from './select-public-price';
import selectPurchasePrice from './select-purchase-price';
import {createSelector} from '@reduxjs/toolkit';

export default createSelector(
  [selectPublicPrice, selectPurchasePrice],
  (publicPrice, purchasePrice) => {
    return publicPrice - purchasePrice;
  }
);
