import React from 'react';

import {AppLogo} from 'pages/welcome/components/app-logo';
import {Container} from 'reactstrap';
import appLogo from 'assets/images/logo.png';
import Button from 'components/button';

const fallbackRenderer = () => {
  const onClick = () => {
    history.back();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  return (
    <Container className="vh-100 d-flex justify-content-center align-items-center">
      <div className="d-flex gap-4">
        <AppLogo src={appLogo} style={{width: 100, height: 120}} />
        <div>
          <h3>Erreur</h3>
          <p>
            Une erreur inattendue est survenue. Veuillez contacter le support
            technique.
          </p>
          <Button onClick={onClick}>Revenir</Button>
        </div>
      </div>
    </Container>
  );
};

export default fallbackRenderer;
