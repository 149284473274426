import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.editOrderInfoModal.title, 'Edit order info'),

  supplierDelay: () => __(translations.editOrderInfoModal.supplierDelay, 'Supplier delay'),
  announcedDelay: () => __(translations.editOrderInfoModal.announcedDelay, 'Announced delay'),
  productionComments: () => __(translations.editOrderInfoModal.productionComments, 'Production comments'),
  status: () => __(translations.editOrderInfoModal.status, 'Status'),
  goToDocument: () => __(translations.editOrderInfoModal.goToDocument, 'Go to document'),
};
