import React, {FC, Fragment, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {actions} from '../../store';
import {useSelector} from 'react-redux';
import {ObjectId} from 'types/object-id';
import clsx from 'clsx';
import selectIsCollectionExpanded from '../../store/selectors/select-is-collection-expanded';
import ProductFamily from './product-family';
import {useGetFamiliesInStockQuery} from '../../store/endpoints/get-families-in-stock';
import {columnClassNames} from '../../constants/grid-classnames';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import {useParams} from 'react-router';
import Currency from 'components/currency/currency';

interface Props {
  row: {
    id: ObjectId;
    name: string;
    stock: number[];
  };
}
const ProductCollection: FC<Props> = props => {
  const {row} = props;
  const {type} = useParams();

  const dispatch = useDispatch();

  const isExpanded = useSelector(selectIsCollectionExpanded(row.id));

  const handleExpand = () => {
    dispatch(actions.expandCollection(row));
  };

  const {data, isFetching} = useGetFamiliesInStockQuery(
    {id: row.id, type: type || ''},
    {skip: !isExpanded}
  );

  const families = useMemo(() => {
    if (data) {
      return data.data;
    }
    return [];
  }, [data]);

  return (
    <Fragment>
      {/* parentRow */}
      <tr
        onClick={handleExpand}
        className={clsx({
          'highlighter-level--1': isExpanded,
          'cursor-state--idle': !isFetching,
          'cursor-state--loading': isFetching,
        })}
      >
        <td className="p-2">{`${row.name}`}</td>
        {row.stock.map((x, index) => {
          return (
            <td
              key={`product-collection-${x}--${index}`}
              className={clsx({'p-2': true, [columnClassNames[index]]: true})}
            >
              {index === 10 || index === 11 || type === 'quantity' ? (
                <NaturalNumber value={x} />
              ) : (
                <Currency value={x} />
              )}
            </td>
          );
        })}
      </tr>
      {/* children */}
      {!isFetching &&
        isExpanded &&
        families.map(family => (
          <ProductFamily collection={row} key={family.id} row={family} />
        ))}
    </Fragment>
  );
};

export default ProductCollection;
