import React, {FC, Fragment} from 'react';
import messages from './messages';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import {FilterMap} from 'features/listing/list/slice/types';
import {MODAL} from 'features/modal-notch/constants';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {CardBody, Row, Col, FormGroup} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
}

const FiltersSection: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters} = props;

  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(showModal(MODAL.DIRECTORY_FAMILY_FORM));
  };

  return (
    <Fragment>
      <CardBody className="py-0">
        <Row className="mt-1">
          <Col lg="3" md="3">
            <FormGroup>
              <AsyncItemPicker
                label={t(...messages.category())}
                endPoint="data/directory-categories"
                isClearable
                value={filters['filter[directory_category_id]']}
                onChange={o =>
                  onChangeFilters('filter[directory_category_id]', o)
                }
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3">
            <FormGroup></FormGroup>
          </Col>
          <Col lg="3" md="3">
            <FormGroup></FormGroup>
          </Col>
          <AppBarActions>
            <Button color="important" onClick={handleCreate}>
              <Plus size={15} className="me-1" />
              {t(...messages.addbtn())}
            </Button>
          </AppBarActions>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default FiltersSection;
