import {useState} from 'react';
import messages from '../messages';
import {
  UpdateColorRequest,
  useUpdateColorMutation,
} from '../store/endpoints/update-color';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {Color} from 'types/colors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useUpdateColorForm = (onDismiss: () => void, color?: Color) => {
  const [updateColor, {isLoading, error}] = useUpdateColorMutation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);
  const {actions} = useListSlice();
  const name = 'colors';
  const url = 'colors';
  const handleSubmit = async () => {
    const result = await updateColor(updateParams);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.colorUpdatedSuccess())));
      dispatch(actions.clearSelection(name));
      dispatch(actions.loadData({url, name}));
      onDismiss();
    }
  };

  const [updateParams, setUpdateParams] = useState<Params>({
    id: color?.id,
    name: {
      en: color?.translation?.en ?? '',
      fr: color?.translation?.fr ?? '',
    },
    code: color?.code,
  });

  return {
    updateParams,
    setUpdateParams,
    updateIsLoading: isLoading,
    updateValidationErrors: serverError.errors,
    updateErrorMessage: serverError.message,
    updateSubmit: handleSubmit,
  };
};
type Params = UpdateColorRequest;
