import {createAsyncThunk} from '@reduxjs/toolkit';
import appleSignInService from 'services/apple-signin';

/**
 * Apple login action
 *
 */
export const loginWithApple = createAsyncThunk(
  'oauth/loginWithApple',
  async () => {
    const result = await appleSignInService.signIn();
    return result;
  }
);
