import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import {StockValue} from 'features/stock/types/stock-value';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getProductInPreparationDetails = api.injectEndpoints({
  endpoints: builder => ({
    getProductInPreparationDetails: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `stock/products-in-preparation/${params.id}`,
      }),
      forceRefetch: () => true,
    }),
  }),
});

type Request = {
  id?: ObjectId;
};
type Response = ResponseData<StockProductInPreparation>;

export const {useGetProductInPreparationDetailsQuery} =
  getProductInPreparationDetails;
