/**
 * Save a string to the local storage
 *
 */
export const saveString = async (
  key: string,
  value: string
): Promise<boolean> => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Save a object to the local storage
 *
 * @param {string} key key
 * @param {object} value value
 * @returns {Promise<boolean>} whether or not the operation has been successful
 */
export const saveObject = async (key: string, value: any) => {
  try {
    const jsonValue = JSON.stringify(value);
    await localStorage.setItem(key, jsonValue);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Load a string from the local storage
 *
 */
export const loadString = async <T = string>(
  key: string
): Promise<T | null> => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      // value previously stored
      return value as T;
    }
    return null;
  } catch (e) {
    // error reading value
    return null;
  }
};

/**
 * Load a object from the local storage
 *
 * @param {string} key key
 * @returns {Promise<object|null>} the item loaded
 */
export const loadObject = async (key: string) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    return null;
  }
};

/**
 * Remove an item from the local storage
 *
 * @param {string} key key
 * @returns {Promise<boolean>} the item loaded
 */
export const removeItem = async (key: string) => {
  try {
    await localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
