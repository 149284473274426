import React from 'react';
import ContentLoader from 'react-content-loader';

const AvatarWithText = () => (
  <ContentLoader viewBox="0 0 100% 160" height={'100%'} width={'100%'}>
    <rect x="150" y="31" rx="4" ry="4" width="254" height="6" />
    <rect x="151" y="51" rx="3" ry="3" width="185" height="7" />
    <rect x="151" y="70" rx="3" ry="3" width="350" height="6" />

    <circle cx="70" cy="70" r="70" />
  </ContentLoader>
);

export default AvatarWithText;
