import React from 'react';
import {projectTabs} from '../constants';
import './project-tab-pane.styles.scss';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Nav, NavItem} from 'reactstrap';
import {useSelector} from 'react-redux';
import {selectProject} from 'features/studio/projects/store/show-project';
import Can from 'features/hylian-shield/can';
import {useCurrentSubject} from 'features/studio/projects/utils/use-current-subject';

const ProjectTabPane = () => {
  const {t} = useTranslation();

  const {id} = useParams();

  const project = useSelector(selectProject({id})).data?.data;

  const subject = useCurrentSubject(project?.project_type);

  const prefix = `/dashboard/projects/${id}`;

  return (
    <Nav className="nav-tabs-custom card-header-tabs border-top mt-4 tab-pane">
      {projectTabs.map(x => (
        <Can key={x.to} action={x.action} subject={subject}>
          <NavItem>
            <NavLink
              className={active =>
                clsx({active: active.isActive}, 'px-3 nav-link')
              }
              to={`${prefix}/${x.to}`}
            >
              {t(`editProjectPage.${x.title}`)}
            </NavLink>
          </NavItem>
        </Can>
      ))}
    </Nav>
  );
};

export default ProjectTabPane;
