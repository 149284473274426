import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  vintage: () => __(translations.editProjectVintageSection.vintage, 'Vintage'),
  invoiceNumber: () => __(translations.editProjectVintageSection.invoiceNumber, 'Invoice Number'),
  billingDate: () => __(translations.editProjectVintageSection.billingDate, 'Billing Date'),
  totalHT: () => __(translations.editProjectVintageSection.totalHT, 'Total HT'),
  globalMarginExcl: () => __(translations.editProjectVintageSection.globalMarginExcl, 'Global margin. Excl'),
  dueDate: () => __(translations.editProjectVintageSection.dueDate, 'Date de paiement'),
  products: () => __(translations.editProjectVintageSection.products, 'Product(s)'),
  quantity: () => __(translations.editProjectVintageSection.quantity, 'Quantity'),

  // inputs
  comment: () => __(translations.editProjectVintageSection.comment, 'Comment'),

  // status
  toInvoice: () => __(translations.editProjectVintageSection.toInvoice, 'To invoice'),
  pending: () => __(translations.editProjectVintageSection.pending, 'Pending'),
  paid: () => __(translations.editProjectVintageSection.paid, 'Paid'),

  // options
  achats: () => __(translations.editProjectVintageSection.achats, 'Achats'),
  patrimoine: () => __(translations.editProjectVintageSection.patrimoine, 'Patrimoine'),
};
