import React, {useContext} from 'react';
import messages from './messages';
import ToggleAttach from './toggle-attach';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {listingSlice} from 'features/listing/list/slice';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {Button} from 'reactstrap';
import {selectDirectory} from 'store/directory/endpoints/show-directory';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import {AbilityContext} from 'features/hylian-shield';
import {AbilityContextType} from 'features/hylian-shield/types';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';

const ActionsTab = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {checkAbility} = useContext(AbilityContext) as AbilityContextType;

  const canDelete = checkAbility('delete-and-merge', 'directory');

  const {id} = useParams();

  const {data} = useSelector(selectDirectory({id}));

  const directory = data?.data;
  const handleArchive = () => {
    dispatch(
      showModal(MODAL.ARCHIVE_DIRECTORY, {
        directory_id: id ?? '',

        message: t(...messages.archiveAlertMessage()),
      })
    );
  };

  const handleDelete = () => {
    if (!canDelete) return dispatch(toast('error', t('common.notAllowed')));
    dispatch(
      showModal(MODAL.DESTROY_DIRECTORY, {
        directory_id: id ?? '',
        onSuccess: () => {
          navigate(`/dashboard/directories`);
        },
        message: t(...messages.deleteAlertMessage()),
      })
    );
  };

  const handleRestore = () => {
    dispatch(
      showModal(MODAL.RESTORE_DIRECTORY, {
        directory_id: id ?? '',
      })
    );
  };
  if (directory?.is_archived) {
    return (
      <>
        <Can action="edit" subject="directory">
          <Button
            color="light"
            className="text-nowrap ms-2"
            onClick={handleRestore}
          >
            <Icon name="refresh-ccw" size={15} className="me-2" />
            {t(...messages.restore())}
          </Button>
        </Can>
        <Can action="delete-and-merge" subject="directory">
          <Button className="ms-2" color="danger" onClick={handleDelete}>
            <Icon name="trash" size={15} className="me-2" />
            {t(...messages.delete())}
          </Button>
        </Can>
      </>
    );
  }

  return (
    <div className="order-1">
      <Can action="edit" subject="directory">
        <Button
          color="light"
          className="text-nowrap ms-2"
          onClick={() => dispatch(showModal(MODAL.MERGE_DIRECTORY, directory))}
        >
          <Icon name="repeat" size={15} className="me-2" />
          {t(...messages.merge())}
        </Button>
      </Can>

      <Can action="edit" subject="directory">
        {directory?.directory_type.value === 'INDIVIDUAL' ? (
          <ToggleAttach directory={directory} />
        ) : null}
      </Can>
      <Can action="edit" subject="directory">
        <Button color="light" className="ms-2" onClick={handleArchive}>
          <Icon name="archive" size={15} className="me-2" />
          {t(...messages.archive())}
        </Button>
      </Can>
      <Can action="delete-and-merge" subject="directory">
        <Button
          className="ms-2"
          disabled={!canDelete}
          color="danger"
          onClick={handleDelete}
        >
          <Icon name="trash" size={15} className="me-2" />
          {t(...messages.delete())}
        </Button>
      </Can>
    </div>
  );
};

export default ActionsTab;
