import React, {useState} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import {CalendarApi} from '@fullcalendar/react';
import Button from 'components/button';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ButtonGroup} from 'reactstrap';
import messages from './messages';
import {now} from 'utils/now';
import isInRange from '../../utils/is-in-range';

interface Props {
  calendarApi?: CalendarApi;
  isLoading?: boolean;
  from: string;
  to: string;
  onRefetch?: () => void;
}

const CalendarControls: FC<Props> = props => {
  const {calendarApi, isLoading, from, to, onRefetch} = props;

  const {t} = useTranslation();

  const canGoToNow = isInRange(new Date(), from, to);

  const [view, setView] = useState('dayGridMonth');

  const changeView = (to: string) => {
    setView(to);
    calendarApi?.changeView(to);
  };

  return (
    <div className="d-flex calendar-no-print">
      {/* LEFT */}
      <div>
        <ButtonGroup>
          <Button
            size="sm"
            color="light"
            onClick={() => {
              calendarApi?.prev();
            }}
          >
            <Icon name="chevron-left" size={15} />
          </Button>
          <Button
            size="sm"
            color="light"
            onClick={() => {
              calendarApi?.next();
            }}
          >
            <Icon name="chevron-right" size={15} />
          </Button>

          <Button
            size="sm"
            disabled={canGoToNow}
            color="light"
            onClick={() => calendarApi?.gotoDate(now())}
          >
            {t(...messages.today())}
          </Button>
          <Button size="sm" color="light" onClick={() => window.print()}>
            {t('common.print')}
          </Button>
        </ButtonGroup>
      </div>
      {/* CENTER */}
      <div className="flex-grow-1 text-center">
        <h4 className="text-center mb-0">
          {calendarApi?.getCurrentData().viewTitle}
        </h4>
      </div>
      {/* RIGHT */}
      <div>
        <Button
          color="light"
          size="sm"
          onClick={onRefetch}
          disabled={isLoading}
        >
          {isLoading ? (
            <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
          ) : (
            <Icon name="refresh-cw" size={15} />
          )}
        </Button>
        <ButtonGroup className="ms-2">
          <Button
            size="sm"
            color="light"
            disabled={view === 'dayGridMonth'}
            onClick={() => changeView('dayGridMonth')}
          >
            {t(...messages.month())}
          </Button>
          <Button
            size="sm"
            color="light"
            disabled={view === 'dayGridWeek'}
            onClick={() => changeView('dayGridWeek')}
          >
            {t(...messages.week())}
          </Button>
          <Button
            size="sm"
            color="light"
            disabled={view === 'dayGridDay'}
            onClick={() => changeView('dayGridDay')}
          >
            {t(...messages.day())}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
export default CalendarControls;
