import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const pricingAction = api.injectEndpoints({
  endpoints: builder => ({
    pricingAction: builder.mutation<Response, Request>({
      query: params => {
        const variants =
          params.selectedVariants ?? ({} as Record<ObjectId, boolean>);
        return {
          method: 'POST',
          url: `directories/${params.id}/update-pricing`,
          body: {
            all_are_selected: params.all_are_selected,
            product_variants: Object.keys(variants).filter(id => variants[id]),
            increase_type: params.increase_type?.id,
            decrease: params.decrease,
            value: params.value,
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'directories', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  all_are_selected?: boolean;
  selectedVariants?: Record<ObjectId, boolean>;
  increase_type?: Option;
  decrease?: Boolean;
  value?: string;
};

type Response = ResponseData<Directory>;

export const {usePricingActionMutation} = pricingAction;
