import {initialState, makeEmptyFields} from './initial-state';
import {CostPayload, CostsFormState, SituationParamPayload} from './types';
import {FieldPayload, SubtotalPayload} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CostCategory} from 'types/cost-category';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {Situation} from 'types/situation';
import makeId from 'utils/make-id';
import normalizeArray from 'utils/normalize-array';

const costsFormSlice = createSlice({
  name: 'costs',
  initialState,
  reducers: {
    addCost(state, action: PayloadAction<CostCategory>) {
      const id = makeId();
      state.fields[action.payload][id] = {
        id,
        comment: '',
        cost_per_unit: 0,
        damped_on: '',
        total_cost: 0,
      };
    },
    removeCost(
      state,
      action: PayloadAction<{categoryId: CostCategory; id: ObjectId}>
    ) {
      const {id, categoryId} = action.payload;
      delete state.fields[categoryId][id];
      state.totalFields.deleted_records[id] =
        !state.totalFields.deleted_records[id];
    },
    updateCost(state, action: PayloadAction<CostPayload>) {
      const {id, categoryId, field, value} = action.payload;
      // @ts-expect-error
      state.fields[categoryId][id][field] = value;
    },
    updateField(state, action: PayloadAction<FieldPayload>) {
      const {key, value} = action.payload;
      state.totalFields[key] = value;
    },
    updateSubtotal(state, action: PayloadAction<SubtotalPayload>) {
      const {key, value} = action.payload;
      const publicPrice = state.totalFields.public_price;
      const discount = 100 - (100 * Number(value)) / publicPrice;

      state.totalFields[`${key}_price`] = value;
      state.totalFields[`${key}_price_discount`] = discount;
    },
    updateDiscount(state, action: PayloadAction<SubtotalPayload>) {
      const {key, value} = action.payload;
      const publicPrice = state.totalFields.public_price;
      const price = publicPrice - (publicPrice * Number(value)) / 100;

      state.totalFields[`${key}_price_discount`] = value;
      state.totalFields[`${key}_price`] = price;
    },
    updatePublicPrice(state, action: PayloadAction<SubtotalPayload>) {
      const value = action.payload;

      state.coefficients.public = null;
      state.totalFields.public_price = value;
      state.totalFields.decorator_price = value;
      state.totalFields.reseller_price = value;
    },
    situationChanged(state, action: PayloadAction<Situation>) {
      state.situationFields = action.payload;
      state.currentSituation = action.payload;
      state.fields = makeEmptyFields();
      const costs = action.payload?.product_costs ?? {};
      const costKeys = Object.keys(costs) as unknown as (keyof typeof costs)[];
      costKeys.forEach(c => (state.fields[c] = normalizeArray(costs[c])));

      const p = action.payload?.product_prices ?? {};
      state.totalFields.decorator_price_discount = p.DECORATOR_PRICE?.discount;
      state.totalFields.decorator_price = p.DECORATOR_PRICE?.price_ht;
      state.totalFields.public_price = p.PUBLIC_PRICE?.price_ht;
      state.totalFields.reseller_price_discount = p.RESELLER_PRICE?.discount;
      state.totalFields.reseller_price = p.RESELLER_PRICE?.price_ht;
    },
    updateSituation(state, action: PayloadAction<SituationParamPayload>) {
      const {key, value} = action.payload;
      if (!state.situationFields) {
        state.situationFields = {};
      }
      state.situationFields[key] = value;
    },

    updateComponents(state, action: PayloadAction<ProductVariant>) {
      state.situationFields = initialState.situationFields;
      state.totalFields = initialState.totalFields;
      state.currentSituation = initialState.currentSituation;
      const variant = action.payload;
      state.fields = makeEmptyFields();
      variant.components.forEach(variant => {
        const id = makeId();
        state.fields.components[id] = {
          id,
          comment: '',
          cost_per_unit: 0,
          damped_on: '',
          product_cost_type: variant.component.type,
          quantity: variant.component.quantity,
          total_cost: 0,
          component: variant,
        };
      });
    },
    updateCoefficient(state, action: PayloadAction<any>) {
      const {purchasePrice, value} = action.payload;
      state.coefficients.public = value;
      state.totalFields.public_price = (value * purchasePrice).toFixed(2);
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    __UNSAFE_hydrate(state, action: PayloadAction<CostsFormState>) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: b => {},
});

export const {actions, reducer} = costsFormSlice;

export default costsFormSlice;
