import React, {useCallback, useEffect, useState} from 'react';
import {useMemo, FC, Fragment} from 'react';
import './styles.scss';
import Icon from '@ailibs/feather-react-ts';
import classNames from 'classnames';
import {French} from 'flatpickr/dist/l10n/fr.js';
import Flatpickr from 'react-flatpickr';
import {useTranslation} from 'react-i18next';
import {FormFeedback, Label} from 'reactstrap';
import realDate from 'utils/real-date';
import useInputId from 'utils/use-input-id';

const DateRangeInput: FC<Props> = props => {
  const {
    value: propValue,
    id: inputId,
    label = '',
    errors = [],
    onChange,
  } = props;
  const {i18n} = useTranslation();
  const currentLocale = i18n.language;

  const selectedDates = useMemo(
    () =>
      propValue
        ? propValue.split(',').map(date => {
            if (date) {
              return new Date(date);
            }
          })
        : [],
    [propValue]
  );

  const errorMessage = useMemo(() => {
    return Array.isArray(errors) ? errors[0] : errors;
  }, [errors]);

  const handleChange = (dates: Date[]) => {
    const [from, to] = dates;
    if (to && from) {
      onChange([realDate(from), realDate(to)]?.join(','));
    } else if (!to && from) {
      onChange([realDate(from), realDate(from)]?.join(','));
    } else if (!from) {
      onChange('');
    }
  };
  const formatDateCallBack = useCallback(
    (date: Date, format: string, locale: {rangeSeparator: string}) => {
      if (
        selectedDates[0]?.toLocaleDateString() ===
        selectedDates[1]?.toLocaleDateString()
      ) {
        return selectedDates[0]?.toLocaleDateString();
      }
      return [
        selectedDates[0]?.toLocaleDateString(),
        selectedDates[1]?.toLocaleDateString(),
      ]?.join(locale.rangeSeparator);
    },
    [propValue, selectedDates]
  );

  const id = useInputId(inputId);
  const isValueSelected = useMemo(() => {
    return !!propValue;
  }, [propValue]);

  return (
    <Fragment>
      <Label htmlFor={id} className="m-0">
        {label}
      </Label>
      <div className="position-relative">
        <Icon
          name={isValueSelected ? 'x' : 'calendar'}
          size={18}
          className="position-absolute   end-0 me-2  h-100"
          onClick={() => {
            onChange('');
          }}
          color="gray"
        />

        <Flatpickr
          key={propValue}
          id={id}
          className={classNames('form-control', {
            'is-invalid': !!errorMessage,
          })}
          options={{
            mode: 'range',
            locale: currentLocale === 'fr' ? French : null,
            formatDate: formatDateCallBack,
          }}
          format="yyyy/MM/dd"
          value={selectedDates}
          onClose={handleChange}
        />
      </div>
      <FormFeedback>{errorMessage}</FormFeedback>
    </Fragment>
  );
};

interface Props {
  value?: string;
  label?: string;
  id?: string;
  errors?: string | string[];
  mode?: 'range' | 'single';
  onChange: (newDate?: string) => void;
}

export default DateRangeInput;
