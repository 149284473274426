import React from 'react';
import {FC, Fragment, useMemo} from 'react';
import {STATUES} from './constants';
import messages from './messages';
import {UserFiltersKeys} from './types';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import {FilterMap} from 'features/table/slice/types';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {CardBody, Col, FormGroup, Label, Row} from 'reactstrap';
import {Option} from 'types/option';

interface FilterProps {
  filters: FilterMap<UserFiltersKeys>;
  onChangeFilters: (key: UserFiltersKeys, value: any) => void;
}

const UserFilters: FC<FilterProps> = props => {
  const {t} = useTranslation();
  const statuses = useMemo(() => {
    return STATUES.map(x => {
      return {
        ...x,
        name: t(...x.name()),
      };
    });
  }, []);

  const {filters, onChangeFilters} = props;

  return (
    <Fragment>
      <CardBody className="py-0 mb-5">
        <Row className="mt-1">
          <Col className=" pt-4" lg="3" md="3">
            <FormGroup>
              <ItemPicker
                label={t(...messages.status())}
                options={statuses}
                value={filters['filter[status]'] as Option}
                isClearable
                onChange={o => onChangeFilters('filter[status]', o)}
              />
            </FormGroup>
          </Col>
          <Col className=" pt-4" lg="3" md="3">
            <FormGroup>
              <AsyncItemPicker
                endPoint="data/roles"
                className="mb-1"
                label={t(...messages.role())}
                value={filters['filter[role]'] as Option}
                onChange={o => onChangeFilters('filter[role]', o)}
              />
            </FormGroup>
          </Col>
          {/* <Col className=" pt-4" lg="3" md="3">
            <div
              className="form-check form-switch form-switch-md mt-4"
              dir="ltr"
            >
              <FormGroup className="d-flex align-items-end h-100">
                <input
                  type="checkbox"
                  className="form-check-input  mb-1"
                  id="trashed"
                  checked={!!filters['filter[trashed]']}
                  onChange={(o: any) =>
                    onChangeFilters('filter[trashed]', o.target.checked)
                  }
                />
                <Label className="form-check-label mx-2" htmlFor="trashed">
                  {t(...messages.archivedUser())}
                </Label>
              </FormGroup>
            </div>
          </Col> */}
          <AppBarActions>
            <Link className="btn btn-important" to="/dashboard/users/create">
              <Plus />
              {t(...messages.add())}
            </Link>
          </AppBarActions>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default UserFilters;
