import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationGeneralInformation.title, 'General information'),
  client: () => __(translations.quotationGeneralInformation.client, 'Client'),
  quotationIssueDate: () => __(translations.quotationGeneralInformation.quotationIssueDate, 'Quotation Issue Date'),
  quotationDeadline: () => __(translations.quotationGeneralInformation.quotationDeadline, 'Quotation Deadline'),
  addNewCompanyClient: () => __(translations.quotationGeneralInformation.addNewCompanyClient, 'Add Company'),
  addNewPrivateClient: () => __(translations.quotationGeneralInformation.addNewPrivateClient, 'Add Private'),
  quotationType: () => __(translations.quotationGeneralInformation.quotationType, 'Type'),
  quotationCategory: () => __(translations.quotationGeneralInformation.quotationCategory, 'Category'),
  quotationOrigin: () => __(translations.quotationGeneralInformation.quotationOrigin, 'Origin'),
  quotationLanguage: () => __(translations.quotationGeneralInformation.quotationLanguage, 'Language'),
  quotationCurrency: () => __(translations.quotationGeneralInformation.quotationCurrency, 'Currency'),
  clientReference: () => __(translations.quotationGeneralInformation.clientReference, 'Client Reference'),
  quotationProject: () => __(translations.quotationGeneralInformation.quotationProject, 'Project'),
  changesCommited: () => __(translations.quotationGeneralInformation.changesCommited, 'Your changes has been saved successfully!'),
  privateComment: () => __(translations.quotationGeneralInformation.privateComment, 'Private comment'),
  publicComment: () => __(translations.quotationGeneralInformation.publicComment, 'Public comment'),
};
