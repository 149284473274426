import React from 'react';
import Currency from 'components/currency/currency';
import EmptyCell from 'components/empty-cell';
import MultiLineRow from 'pages/commercial/pricing/pricing-grid/multi-line-row';
import {Trans} from 'react-i18next';
import {SituationLine} from 'types/situation-line';
import { ColumnDef } from '@tanstack/table-core';
import Percent from 'components/percent/percent';
import { DecoratorPriceIndicator, PublicPriceIndicator, ResellerPriceIndicator } from 'pages/commercial/pricing/pricing-grid/indicator';
import ProductCover from 'pages/dashboard/products/product-cover';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

const makePricingModalColumns: () => ColumnDef<SituationLine>[] = () => [
 


  {
    header: r => <Trans i18nKey="productsPage.name" />,
    accessorFn: r => <EmptyCell cell={r.situation_name} />,
    cell: r => r.getValue(),
      enableSorting: true,
    id: 'situation_name',
  },

  {
    header: r => <Trans i18nKey={'pricingPage.purchaseCost'} />,
    id: 'total_cost_ht',
    enableSorting: true,
    accessorFn: x =>
      <Currency value={x.total_cost_ht} /> ?? '-',
    cell: r => r.getValue(),
  },
  // Public pricing
  {
    header: r => (
      <span className="w-100 text-center">
        <Trans i18nKey={'pricingPage.publicPricing'} />
      </span>
    ),
    id: 'public_price',
    meta: {costumClass: 'w-100 text-center pe-4'},
    colSpan: 3,
    columns: [
      {
        id: 'public_price_ht',
        header: r => <Trans i18nKey={'pricingPage.price'} />,

        enableSorting: true,
        cell: r => r.getValue(),

        accessorFn: x => {
          return (
            <span className="w-100 text-center">
              <Currency value={x.public_price_ht} />
            </span>
          ) 
        },
      },
      {
        id: 'public_price_ttc',
        accessorFn: x => {
          return  (
            <span className="w-100 text-center">
              <Currency value={x.public_price_ttc} />
            </span>
          ) 
        },
        enableSorting: true,

        cell: r => r.getValue(),

        header: r => <Trans i18nKey={'pricingPage.priceInclTaxes'} />,
      },
      {
        id: 'public_benefit',
        enableSorting: true,
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        accessorFn: x =>
          <PublicPriceIndicator value={x.public_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },

  // Reseller pricing
  {
    header: r => (
      <span className="w-100 text-center">
        <Trans i18nKey={'pricingPage.resellerPricing'} />
      </span>
    ),
    id: 'reseller_price',
    colSpan: 3,
    meta: {costumClass: ' w-100 text-center'},

    columns: [
      {
        id: 'reseller_price_ht',
        enableSorting: true,
        header: r => <Trans i18nKey="pricingPage.price" />, //TRANS PRICE
        accessorFn: x =>
          (
            <span className="w-100 text-center">
              <Currency value={x.reseller_price_ht} />
            </span>
          
          ),
        cell: r => r.getValue(),
      },
      {
        id: 'reseller_discount',
        accessorFn: x =>
           (
            <span className="w-100 text-center">
              <Percent
                value={ x.reseller_discount}
              />
            </span>
          ),
        enableSorting: true,
        cell: r => r.getValue(),
        header: r => <Trans i18nKey={'pricingPage.discount'} />,
      },

      {
        id: 'reseller_benefit',
        enableSorting: true,
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        accessorFn: x =>
          <ResellerPriceIndicator value={x.reseller_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },
  // Decorator pricing
  {
    header: r => (
      <span className="w-100 text-center ">
        <Trans i18nKey={'pricingPage.decoratorPricing'} />
      </span>
    ),
    id: 'decorator_price',
    colSpan: 3,
    meta: {costumClass: ' w-100 text-center'},
    columns: [
      {
        header: r => <Trans i18nKey={'pricingPage.price'} />,
        id: 'decorator_price_ht',
        enableSorting: true,
        accessorFn: x => (
            <span className="w-100 text-center">
              <Currency value={x.decorator_price_ht} />
            </span>
          ) ,
        cell: r => r.getValue(),
      },
      {
        header: r => <Trans i18nKey={'pricingPage.discount'} />,
        id: 'decorator_discount',
        enableSorting: true,
        accessorFn: x => (
          <Percent
            value={ x.decorator_discount}
          />
        ),
        cell: r => r.getValue(),
      },
      {
        id: 'decorator_price_ttc',
        enableSorting: true,
        accessorFn: x =>
       (
            <span className="w-100 text-center">
              <Currency value={x.decorator_price_ttc} />
            </span>
          ) ,
        header: r => <Trans i18nKey={'pricingPage.priceInclTaxes'} />,
        cell: r => r.getValue(),
      },
      {
        header: r => <Trans i18nKey={'pricingPage.margin'} />,
        id: 'decorator_benefit',
        enableSorting: true,
        accessorFn: x =>
          <DecoratorPriceIndicator value={x.decorator_benefit} /> ?? '-',
        cell: r => r.getValue(),
      },
    ],
  },
  
];
export default makePricingModalColumns;
