import removeItem from './actions/remove-item';
import removeItemOption from './actions/remove-item-option';
import removePayment from './actions/remove-payment';
import invoicesSlice from './slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  DestroyResourceRequest,
  destroyResourceEndpoint,
} from 'features/listing/list/slice/destroy-resource';
import {put, takeLatest} from 'redux-saga/effects';
import {uiActions} from 'store/ui/ui-slice';
import {Address} from 'types/address';
import {FulfilledActionArg} from 'types/fulfilled-action';

/**
 * Upserts an existing address that has been returned as a result of
 * the dismissal of address modal
 *
 */
export function* touchAddress(action: PayloadAction<Address>) {
  const address = action.payload;
  yield put(invoicesSlice.actions.upsertAddress(address));
}

/**
 * Removes an item or item option according to type of the resource removed
 *
 */
function* removeItemOrOption(action: RemoveAction) {
  const {resourceId, name} = action.meta.arg.originalArgs;
  const itemTypes = ['order-product', 'invoice-product', 'quotation-product'];

  const optionTypes = [
    'order-product-option',
    'quotation-product-option',
    'invoice-product-option',
  ];

  if (itemTypes.includes(name)) {
    yield put(removeItem(resourceId));
  } else if (optionTypes.includes(name)) {
    yield put(removeItemOption(resourceId));
  } else if (name === 'payments') {
    yield put(removePayment(resourceId));
  }
}

/**
 * Root saga manages watcher lifecycle
 *
 */
export function* commercialDocumentSaga() {
  // Watches for list actions and calls loadData when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(uiActions.dismissModalWithResult, touchAddress);
  yield takeLatest(destroyResourceEndpoint.matchFulfilled, removeItemOrOption);
}

type RemoveAction = FulfilledActionArg<DestroyResourceRequest>;
