import {STOCK_MOVEMENT} from 'constants/stock-movements';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';
import {StockMovement} from 'types/stock-movement';
import {ValueOf} from 'types/value-of';

const storeStockMovement = api.injectEndpoints({
  endpoints: builder => ({
    storeStockMovement: builder.mutation<Response, StockMovementRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/${params.variantId}/stock-movements`,
          body: {
            type: params.type?.id,
            commercial_product_id: params.commercial_product_id,
            movement_infos: transMovementInfo(params.movement_infos ?? {}),
          },
        };
      },
      // @ts-ignore
      invalidatesTags: result => [
        {location: 1},
        {type: 'variants', id: result?.data.id},
      ],
    }),
  }),
});

export type StockMovementRequest = {
  id?: ObjectId;
  variantId?: ObjectId;
  type?: Option;
  commercial_product_id?: ObjectId;
  movement_infos?: Record<ObjectId, MovementInfo>;
};
export type MovementInfo = {
  id: ObjectId;
  stock_location_start?: Option;
  stock_location_arrival?: Option;

  stock_sub_location_start?: Option;
  stock_sub_location_arrival?: Option;
  quantity?: number;
  comment?: '';
};

type Response = ResponseData<ProductVariant>;
const transMovementInfo = (
  movement_info: Record<ObjectId, MovementInfo>
): any[] => {
  return Object.values(movement_info).map(x => {
    return {
      stock_location_start_id: x.stock_location_start?.id,
      stock_location_arrival_id: x.stock_location_arrival?.id,
      stock_sub_location_start_id: x.stock_sub_location_start?.id,
      stock_sub_location_arrival_id: x.stock_sub_location_arrival?.id,
      quantity: x.quantity,
      comment: x.comment,
    };
  });
};
export const {useStoreStockMovementMutation} = storeStockMovement;
