import {useState} from 'react';
import {HandleSubmit, Params, SetParams} from './types';
import {
  useStoreTransferStockLocationMutation,
  TransferStockLocationRequest,
} from 'features/production/store/endpoints/store-transfer-stock-location';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {ValidationErrors} from 'services/api/types';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';
import {MOVEMENT_TYPE} from './constant';

export const useTransferStockLocationForm = ({
  productVariantId,
  serialNumberId,
  stockLocationStart,
  onDismiss,
}: {
  productVariantId: ObjectId;
  serialNumberId: ObjectId;
  stockLocationStart: Option;
  onDismiss: () => void;
}): ITranferStockLocationForm => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const [params, setParams] = useState<Params>({
    stock_location_start_id: stockLocationStart.id,
    type: 'TRANSFERT',
  } as Params);

  const [storeTransferStockLocation, {error, isLoading: isSubmitting}] =
    useStoreTransferStockLocationMutation();

  const serverErrors = parseValidationErrors(error);

  const shapePayload = (): TransferStockLocationRequest => {
    return {
      ...params,
      productVariantId,
      serialNumberId,
    };
  };

  const handleSubmit: HandleSubmit = async () => {
    const result = await storeTransferStockLocation(shapePayload());

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      onDismiss();
    }
  };

  return {
    params,
    isSubmitting,
    validationErrors: serverErrors.errors,
    errorMessage: serverErrors.message,
    setParams,
    submit: handleSubmit,
  };
};

interface ITranferStockLocationForm {
  params: Params;
  isSubmitting: boolean;
  validationErrors?: ValidationErrors;
  errorMessage?: string;
  setParams: SetParams;
  submit: HandleSubmit;
}
