import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const massUpdateCatalog = api.injectEndpoints({
  endpoints: builder => ({
    massUpdateCatalog: builder.mutation<Response, MassUpdateCostsRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `product-variants/${params.url}`,
          body: {
            product_variants: params.ids,
          },
        };
      },
    }),
  }),
});

export type MassUpdateCostsRequest = {
  ids?: ObjectId[];

  url: string;
};

type Response = ResponseData<{message: string; affected_row: ObjectId[]}>;

export const {useMassUpdateCatalogMutation} = massUpdateCatalog;
