import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {Trans, useTranslation} from 'react-i18next';
import {Col, ModalBody, ModalHeader, Row} from 'reactstrap';
import {StockProduct} from 'features/stock/types/stock-products';
import {ProductDimension} from 'types/product-dimension';
import {Link} from 'react-router-dom';
import {generateLink} from 'features/commercial/documents/containers/option-related-documents-section/option-related-documents-section';

const DetailsStockProduct: FC<Props> = props => {
  const {onDismiss, stockProduct} = props;

  const {t} = useTranslation();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <LineItem
          label={t(messages.sku())}
          value={stockProduct.product_variant.sku}
        />
        <LineItem
          label={t(messages.product())}
          value={stockProduct.product_variant.name}
        />

        <LineItem
          label={t(messages.stockLocation())}
          value={stockProduct.stock_location_name}
        />
        <Row className="mb-2">
          <Col md={5} className="fw-bold w-20 font-size-14">
            <b>{t(messages.option())}</b>
          </Col>
          <ul className="w-50">
            <li>{stockProduct.product_variant.dimensions}</li>
            <li>{stockProduct.product_variant.finition}</li>
          </ul>
        </Row>

        <Row className="mb-2">
          <Col md={5} className="fw-bold w-20 font-size-14">
            <b>{t(messages.oreders())}</b>
          </Col>
          <ul className="w-50">
            <li>
              <Link
                to={`/dashboard/orders/${stockProduct.order.id}/details`}
                target="_blank"
              >
                {stockProduct.order.order_ref}
              </Link>
            </li>
            {stockProduct.order?.relatedDocuments?.map(document => {
              return (
                <li key={document.id}>
                  <Link to={generateLink(document)} target="_blank">
                    {document.model_ref}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Row>

        <h4 className="text-center ">{t(...messages.packageDimension())}</h4>
        <PackageDimensionTable
          packageDimensions={stockProduct.product_variant.package_dimensions}
        />
      </ModalBody>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
  stockProduct: StockProduct;
}

interface LineItemProps {
  label: string;
  value: string | number;
}

const LineItem: FC<LineItemProps> = props => {
  const {label, value} = props;

  return (
    <Row className="mb-2">
      <Col md={5} className="fw-bold w-20 font-size-14">
        <b>{label}</b>
      </Col>
      <Col>{value}</Col>
    </Row>
  );
};

export default DetailsStockProduct;
interface PackageDimensionProps {
  packageDimensions?: ProductDimension[];
}

const PackageDimensionTable = (props: PackageDimensionProps) => {
  const {packageDimensions} = props;
  const {t} = useTranslation();

  return (
    <table className="table">
      <thead>
        <tr>
          <th>{t(...messages.name())}</th>
          <th>{t(...messages.height())}</th>
          <th>{t(...messages.width())}</th>
          <th>{t(...messages.depth())}</th>
          <th>{t(...messages.weight())}</th>
          <th>{t(...messages.net_weight())}</th>
          <th>{t(...messages.volume())}</th>
        </tr>
      </thead>
      <tbody>
        {packageDimensions?.map(packageDimension => {
          return (
            <tr key={packageDimension.id}>
              <td>{packageDimension.name}</td>
              <td>{packageDimension.height}</td>
              <td>{packageDimension.width}</td>
              <td>{packageDimension.depth}</td>
              <td>{packageDimension.weight}</td>
              <td>{packageDimension.net_weight}</td>
              <td>{packageDimension.volume}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
