import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getProductStats = api.injectEndpoints({
  endpoints: builder => ({
    getProductStats: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `product-variants/${params.id}/stats`,
        };
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<
  {
    year: number;
    ca: number;
    selling_count: number;
  }[]
>;

export const {useGetProductStatsQuery} = getProductStats;
