import React, {FC} from 'react';
import Breadcrumb from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {Outlet, Route, Routes} from 'react-router';
import ProductsStockPage from '../products-stock/products-stock';
import ProductAlerts from '../product-alerts/product-alerts';
import ValueDisplayToggle from 'features/stock/products-stock-grid/components/value-display-toggle/value-display-toggle';

interface Props {}

const ProductsGridRouter: FC<Props> = props => {
  const {} = props;

  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t('productsStockPage.title')}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumb
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t('productsStockPage.title'), path: '#'},
          ]}
        />
        <div>
          <Row>
            <React.Fragment>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <ValueDisplayToggle />
                      <Routes>
                        <Route path="/:type" element={<ProductsStockPage />} />
                        <Route path="/alerts" element={<ProductAlerts />} />
                        <Route path="/alerts/:id" element={<ProductAlerts />} />
                      </Routes>
                      <Outlet />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </React.Fragment>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ProductsGridRouter;
