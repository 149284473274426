import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  add: () => __(translations.coordinatesTab.add, 'Add'),
  addEmail: () => __(translations.coordinatesTab.addEmail, 'Add Email'),
  addPhone: () => __(translations.coordinatesTab.addPhone, 'Add Number'),
  comment: () => __(translations.coordinatesTab.comment, 'Comment'),
  email: () => __(translations.coordinatesTab.email, 'Email'),
  emails: () => __(translations.coordinatesTab.emails, 'Email (s)'),
  main: () => __(translations.coordinatesTab.main, 'Main'),
  number: () => __(translations.coordinatesTab.number, 'Number'),
  phone: () => __(translations.coordinatesTab.phones, 'Phone (s)'),
  title: () => __(translations.coordinatesTab.title, 'Coordinate (s) list for {{name}}'),
  type: () => __(translations.coordinatesTab.type, 'Type'),
  code: () => __(translations.coordinatesTab.code, 'telephone code'),
};
