import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  directory: () => __(translations.settingsPage.directory, 'Directory'),
  general: () => __(translations.settingsPage.general, 'General'),
  fiscal: () => __(translations.settingsPage.fiscal, 'Fiscal'),
  commercial: () => __(translations.settingsPage.commercial, 'Commercial'),
  mailing: () => __(translations.settingsPage.mailing, 'Mailing'),
  title: () => __(translations.settingsPage.title, 'Settings'),
};
