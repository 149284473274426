import {actions} from '../slice';
import {createAction} from '@reduxjs/toolkit';
import {Payment} from 'types/payment';

export default createAction(actions.updatePayment.type, function prepare<
  T extends KeyType
>(item: Payment, key: T, value: Payment[T]) {
  return {
    payload: {
      item,
      key,
      value,
    },
  };
});

type KeyType = keyof Payment;

type ValueByKeyType<T> = {
  discount: number;
  notes: string;
};

type ValueByKeyTypeExtended<T> = ValueByKeyType<T> & {};
