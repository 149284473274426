import {useEffect, useState} from 'react';
import {produce} from 'immer';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectProduct} from 'store/production/endpoints/show-product';
import {useUpdateCustomizationMutation} from 'store/production/endpoints/update-customizations';
import {ObjectId} from 'types/object-id';
import {ProductCustomization} from 'types/product-customization';
import makeId from 'utils/make-id';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useCustomizationsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateCustomizationMutation();

  const serverError = parseValidationErrors(error);

  const {id} = useParams();

  const [customizations, setCustomizations] = useState<CustomizationsMap>({});

  const [deletedRecords, setdeletedRecords] = useState([] as ObjectId[]);

  const handleSubmit = () => {
    update({
      customizations: Object.values(customizations),
      deletedRecords,
      id,
    });
  };

  const addCustomization = (code: string) => {
    const p: ProductCustomization = {
      id: makeId(),
      is_variant: code === 'variant',
    };
    setCustomizations(
      produce(customizations, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeCustomization = (id?: ObjectId) => {
    if (!id) {
      return;
    }
    const _customizations: Record<ObjectId, ProductCustomization> =
      Object.assign({}, customizations);

    delete _customizations[id];
    setCustomizations({
      ..._customizations,
    });
    setdeletedRecords([...deletedRecords, id]);
  };

  const updateCustomization = (
    p: ProductCustomization,
    k: keyof ProductCustomization,
    v: any
  ) => {
    setCustomizations(
      produce(customizations, draft => {
        if (k === 'is_variant') {
          return;
        }
        draft[p.id][k] = v;
      })
    );
  };

  const {data} = useSelector(selectProduct({id}));

  const variant = data?.data;

  useEffect(() => {
    if (variant) {
      const tmp = variant.customization_options.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {} as CustomizationsMap);
      setCustomizations(tmp);
    }
  }, [variant]);

  return {
    addCustomization,
    removeCustomization,
    submit: handleSubmit,
    updateCustomization: updateCustomization,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    customizations,
    validationErrors: serverError.errors,
  };
};

type CustomizationsMap = Record<ObjectId, ProductCustomization>;
