import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const deleteOrder = api.injectEndpoints({
  endpoints: builder => ({
    deleteOrder: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'DELETE',
          url: `/orders/${params.id}/force-delete`,
        };
      },
    }),
  }),
});

type Request = {id: ObjectId};

type Response = ResponseData<{affected_row: ObjectId; message: string}>;

export const {useDeleteOrderMutation} = deleteOrder;
