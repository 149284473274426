import _ from 'lodash';
import {SerialNumber} from 'types/product-variant';

type SerialNumbersGroupedbyLocation = {
  location_name: string;
  quantity: number;
  serial_numbers: SerialNumber[];
};

/**
 * @summary group serial numbers by location name
 * @param serial_numbers
 * @returns
 */

export const groupBySerialNumbers = (
  serial_numbers: SerialNumber[]
): SerialNumbersGroupedbyLocation[] => {
  const groupedData = _.groupBy(serial_numbers, 'stock_sub_location.name');
  return _.map(groupedData, (group, key) => ({
    location_name: key === 'null' ? '' : key,
    quantity: group.length,
    serial_numbers: group,
  }));
};
