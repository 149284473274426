import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.orderPreparerModal.title, 'Preparer the order'),
  preparer: () => __(translations.orderPreparerModal.preparer, 'Preparer'),
  areYouSure: () => __(translations.orderPreparerModal.areYouSure, 'Are you sure?'),
  label: () => __(translations.orderPreparerModal.label, 'Label'),
  quantity: () => __(translations.orderPreparerModal.quantity, 'Quantity'),
  logistic: () => __(translations.orderPreparerModal.logistic, 'Logistic'),
  sku: () => __(translations.orderPreparerModal.sku, 'SKU'),

  emailSubject: () => __(translations.orderPreparerModal.emailSubject, 'Email subject'),
  prepareEmail: () => __(translations.orderPreparerModal.prepareEmail, 'E-mail'),
  prepareEmailAdd: () => __(translations.orderPreparerModal.prepareEmailAdd, 'Add'),

  emailHeader: () => __(translations.orderPreparerModal.emailHeader, 'Bonjour, \n Merci de prévoir la relâche des pièces suivantes :\n'),
  emailFooter: () => __(translations.orderPreparerModal.emailFooter, "\n\nMerci d'avance"),
};
