import React, {FC} from 'react';
import messages from '../messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import {FilterMap} from 'features/table/slice/types';
import {MODAL} from 'features/modal-notch/constants';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, CardBody, Col, Row} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import Can from 'features/hylian-shield/can';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
}

const EcoTaxControls: FC<FilterProps> = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Row>
      <Col>
        <CardBody>
          <Row className="mb-3">
            <Col md={8} />

            <AppBarActions>
              <Can action="edit" subject="accounting">
                <Button
                  color="important"
                  onClick={() => {
                    dispatch(
                      showModal(MODAL.ECOTAX_CREATE, {
                        onSuccess: () => {
                          navigate(`/dashboard/ecotaxes`);
                        },
                      })
                    );
                  }}
                >
                  <Icon name="plus" className="me-1" size={15} />
                  {t(...messages.addEcoTax())}
                </Button>
              </Can>
            </AppBarActions>
          </Row>
        </CardBody>
      </Col>
    </Row>
  );
};

export default EcoTaxControls;
