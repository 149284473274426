import {useMemo, useState} from 'react';

import { useLazyGetPatrimoineQuery } from 'features/commercial/quotations/store/endpoints/get-patrimoine';
import { LANGUAGE } from './constant';
import { useTranslation } from 'react-i18next';
import { Option } from 'types/option';
import { ObjectId } from 'types/object-id';
import { parseValidationErrors } from 'services/api/utils/parse-error';
import { useDispatch } from 'react-redux';
import loadData from 'features/table/slice/actions/load-data';
import { uiActions } from 'store/ui/ui-slice';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useExportPatrimoine = () => {
  const[submitCatalog, {isLoading,error}] =useLazyGetPatrimoineQuery()
  const serverError = parseValidationErrors(error);
  const {t} = useTranslation();

  const languages = useMemo(() => {
    return LANGUAGE.map(x => {
      return {
        ...x,
        name: t(...x.name()),
      };
    });
  }, []);


  const [params, setParams] = useState({
    locale: languages[0] as Option | ObjectId,
    'filter[localisations]': undefined,
    'filter[product_family_name]': '' as any, 
    per_localisation: 'true',
    
  });
  const dispatch = useDispatch();

  const handleSubmit = async() => {
    
    const result = await submitCatalog({...params,});
    if ('data' in result) {
      dispatch(loadData({name: 'product-variants-prices',url:'prices', page: 1 }));
      dispatch(toast('success', result.data?.message));


      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    errorMessage: serverError.message,
    params,
    languages,
    isLoading,
    submit: handleSubmit,
    validationErrors: serverError.errors,
  };
};
