import React from 'react';
import {FC} from 'react';
import messages from '../../messages';
import useCompanyForm from './use-company-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import PhoneInputText from 'components/phone-input';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {Alert, Card, CardBody, Col, Label, Row} from 'reactstrap';
import {Option} from 'types/option';

const CreateCompanyDirectoryForm: FC<{}> = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const {submit, params, isLoading, validationErrors, errorMessage, setParams} =
    useCompanyForm();

  const navigate = useNavigate();

  const handleKeyboardEventDown = (e: KeyboardEvent) => {
    if (e.code === 'Enter') {
      submit();
    }
  };
  const isSupplier =
    params.categories.findIndex((c: any) => c.type === 'SUPPLIER') !== -1;

  return (
    <Card>
      <CardBody>
        <Row className="mb-2">
          <Col md={5}>
            <TextInput
              label={t(...messages.name())}
              className="mb-1"
              value={params.company_name}
              errors={validationErrors.company_name}
              onChange={company_name => setParams({...params, company_name})}
            />
          </Col>
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/countries"
              label={t(...messages.country())}
              className="mb-1"
              value={params.residency_country}
              errors={validationErrors.residency_country}
              onChange={residency_country =>
                setParams({...params, residency_country})
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={2}>
            <AsyncItemPicker
              endPoint="data/phone-number-types"
              getOptionLabel={(x: Option) => x.label}
              getOptionValue={(x: Option) => x.value}
              label={t(...messages.type())}
              errors={validationErrors[`phone_number_type`]}
              onChange={phone_number_type =>
                setParams({...params, phone_number_type})
              }
              value={params.phone_number_type}
            />
          </Col>
          <Col md={3}>
            <PhoneInputText
              label={t(...messages.phone())}
              limitMaxLength={true}
              country="FR"
              enableSearch={true}
              international
              className="mb-1"
              value={params.phone}
              errors={validationErrors.phone}
              onChange={phone => setParams({...params, phone})}
            />
          </Col>

          <Col md={5}>
            <TextInput
              type="text"
              label={t(...messages.email())}
              rows={4}
              value={params.email}
              errors={validationErrors.email}
              onChange={email => setParams({...params, email})}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>
            <TextInput
              label={t(...messages.website())}
              className="mb-1"
              value={params.website}
              errors={validationErrors.website}
              onChange={website => setParams({...params, website})}
            />
          </Col>
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/newsletter-lists"
              className="mb-1"
              label={t(...messages.newsletter())}
              optionLabel=""
              errors={validationErrors.newsletters}
              value={params.newsletters}
              isMulti
              onChange={newsletters => setParams({...params, newsletters})}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/directory-categories"
              className="mb-1"
              label={t(...messages.category())}
              optionLabel=""
              errors={validationErrors.directory_categories}
              value={params.categories}
              isMulti
              onChange={categories => setParams({...params, categories})}
            />
          </Col>
          <Col md={5}>
            <div className="flex-grow-1">
              <AsyncItemPicker
                key={`directory-families-${params.categories
                  ?.map(c => c.id)
                  .join('|')}`}
                endPoint="data/directory-families"
                className="mb-1"
                otherParams={{
                  directory_categories: params.categories
                    ?.map(c => c.id)
                    .join('|'),
                }}
                disabled={params.categories?.length === 0}
                label={t(...messages.family())}
                optionLabel=""
                errors={validationErrors.directory_families}
                isMulti
                value={params.families}
                onChange={families => setParams({...params, families})}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/users"
              className="mb-1"
              label={t(...messages.referentContact())}
              errors={validationErrors.reference_contact_id}
              getOptionLabel={(v: any) => v.name}
              value={params.reference_contact}
              onChange={reference_contact =>
                setParams({...params, reference_contact})
              }
            />
          </Col>
          <Col md={5}>
            <AsyncItemPicker
              endPoint="data/requirement-rates"
              className="mb-1"
              label={t(...messages.requirementRate())}
              errors={validationErrors.requirement_rate}
              getOptionLabel={(v: any) => v.label}
              value={params.requirement_rate}
              onChange={requirement_rate =>
                setParams({...params, requirement_rate})
              }
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={10}>
            <TextInput
              type="textarea"
              label={t(...messages.comment())}
              rows={4}
              value={params.comment}
              errors={validationErrors.comment}
              onChange={comment => setParams({...params, comment})}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            {isSupplier && (
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_furniture_supplier: e.target.checked,
                      })
                    }
                    checked={params.is_official_furniture_supplier}
                    id="official-furniture-supplier"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-furniture-supplier"
                  >
                    {t(...messages.official_furniture_supplier())}
                  </label>
                </div>
                {/* 2 */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_studio_supplier: e.target.checked,
                      })
                    }
                    checked={params.is_official_studio_supplier}
                    id="official-studio-supplier"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-studio-supplier"
                  >
                    {t(...messages.official_studio_supplier())}
                  </label>
                </div>
                {/* 3 */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={e =>
                      setParams({
                        ...params,
                        is_official_suppliers_small_items: e.target.checked,
                      })
                    }
                    checked={params.is_official_suppliers_small_items}
                    id="official-suppliers-small-items"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="official-suppliers-small-items"
                  >
                    {t(...messages.official_suppliers_small_items())}
                  </label>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div className="my-3">
              {!!errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            </div>
          </Col>
        </Row>
        <AppBarActions>
          <Button isLoading={isLoading} onClick={submit} color="important">
            <Icon name="check" size={15} className="me-1" />
            {t('common.saveChanges')}
          </Button>
        </AppBarActions>
      </CardBody>
    </Card>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export default CreateCompanyDirectoryForm;
