import React, {FC} from 'react';
import messages from '../messages';
import CreateCompanyForm from './create-company-form';
import Breadcrumbs from 'components/Common/Breadcrumb';
import NotificationFilters from 'pages/dashboard/notifications/notification-filters';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container, List} from 'reactstrap';

interface Props {}

const CreateCompanyDirectoryPage: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {
              title: t('directoryPage.pageTitle'),
              path: '/dashboard/directories',
            },
            {title: t(...messages.addCompany()), path: '#'},
          ]}
        />
        <div>
          <CreateCompanyForm />
        </div>
      </Container>
    </div>
  );
};

export default CreateCompanyDirectoryPage;
