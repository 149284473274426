import React, {FC, useState} from 'react';
import Icon from '@ailibs/feather-react-ts';
import {useLazyGetPatrimoineQuery} from 'features/commercial/quotations/store/endpoints/get-patrimoine';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import toast from 'store/ui/actions/toast';
import messages from '../messages';
import {PatrimoineEXPORTMenu} from './constants';
import showModal from 'store/ui/actions/show-modal';
import {MODAL} from 'features/modal-notch/constants';

interface Props {
  item: {
    title: () => [string, ...any[]];
    subMenu: {
      title: () => [string, ...any[]];
      query: {
        locale: string;
        ' filter[product_family_name]'?: string;
      };
    }[];
  };
}

const ExportItems: FC<Props> = props => {
  const {item} = props;
  const [submitCatalog, {isLoading}] = useLazyGetPatrimoineQuery();
  const dispatch = useDispatch();

  const handleExport = async (filters: any) => {
    const result = await submitCatalog(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const {t} = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div style={{width: '100%', padding: '5px 0 0 5px'}}>
      {/* Action */}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        //@ts-ignore
        direction="right"
      >
        <DropdownToggle
          color="none"
          className="p-0 text-start"
          style={{width: '100%'}}
        >
          <div className="d-flex justify-content-between align-items-center w-100  px-1">
            <span>{t(...item.title())}</span>
            <Icon name="chevron-right" size={15} className="ms-1" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {item.subMenu.map((subItem, index) => {
            return (
              <DropdownItem
                key={index}
                onClick={() => {
                  handleExport({...subItem.query});
                }}
              >
                {t(...subItem.title())}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
        <DropdownItem divider />
      </Dropdown>
    </div>
  );
};

interface PatrimoineProps {}

const ExportPatrimoine: FC<PatrimoineProps> = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      //@ts-ignore
      direction="right"
    >
      <DropdownToggle
        color="none"
        className="p-0 text-start"
        style={{width: '100%'}}
      >
        <div className="d-flex justify-content-between align-items-center w-100  px-1">
          <span className="ms-2"> {t(...messages.patrimoine())}</span>
          <Icon name="chevron-right" size={15} className="ms-1" />
        </div>
      </DropdownToggle>
      <DropdownMenu style={{width: '240px'}}>
        {PatrimoineEXPORTMenu.map((item, index) => {
          return <ExportItems key={index} item={item} />;
        })}
        <DropdownItem
          className="ps-2"
          onClick={() => {
            dispatch(showModal(MODAL.EXPORT_PATRIMOINE));
          }}
        >
          {t(...messages.localisation())}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportPatrimoine;
