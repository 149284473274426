import {ProductToAssemble} from 'features/stock/types/product-to-assamble';
import {StockProduct} from 'features/stock/types/stock-products';
import StockSublocation from 'features/stock/types/stock-sub-location';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const assembleProduct = api.injectEndpoints({
  endpoints: builder => ({
    assembleProduct: builder.mutation<Response, assembleProductRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `stock/serial-number/join`,
          body: {
            codes: params.codes,
            stock_location_id: params.stock_location?.id,
          },
        };
      },
    }),
  }),
});

export type assembleProductRequest = {
  products: ProductToAssemble[];
  codes: string[];
  stock_location?: Option;
};

type Response = ResponseData<StockSublocation>;

export const {useAssembleProductMutation} = assembleProduct;
