import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  add: () => __(translations.directoryAttachments.add, 'New attachment'),
  date: () => __(translations.directoryAttachments.date, 'Date'),
  delete: () => __(translations.directoryAttachments.delete, 'Delete'),
  editImage: () => __(translations.directoryAttachments.editImage, 'Edit image'),
  file: () => __(translations.directoryAttachments.file, 'File'),
  fileName: () => __(translations.directoryAttachments.fileName, 'File name'),
  image: () => __(translations.directoryAttachments.image, 'Image'),
  inCatalog: () => __(translations.directoryAttachments.inCatalog, 'In catalog'),
  name: () => __(translations.directoryAttachments.name, 'Name'),
  show: () => __(translations.directoryAttachments.show, 'Show in browser'),
  title: () => __(translations.directoryAttachments.title, 'Directory Attachments of {{name}}'),
  type: () => __(translations.directoryAttachments.type, 'Type of file'),
  upload: () => __(translations.directoryAttachments.upload, 'Upload a file'),
};
