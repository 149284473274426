import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.stockMovementList.alertTitle, 'List of Stock Movement'),
  dateStockMovement: () => __(translations.stockMovementList.dateStockMovement, 'Date Stock Movement'),
  type: () => __(translations.stockMovementList.type, 'Type'),
  quantity: () => __(translations.stockMovementList.quantity, 'Quantity'),
  comments: () => __(translations.stockMovementList.comments, 'Comments'),
  stockLocationArrival: () => __(translations.stockMovementList.stockLocationArrival, 'Stock Location Arrival'),
  stockLocationStart: () => __(translations.stockMovementList.stockLocationStart, 'Stock Location Start'),
  user: () => __(translations.stockMovementList.user, 'User'),
};
