import {ApiEndpointBuilder} from 'services/api/types';
import {Notification} from 'types/notification';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const markAsReadEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<ResponseData<Notification>, Request>({
    query: request => ({
      method: 'PUT',
      url: `me/notifications/${request.id}`,
    }),
  });
};

type Request = {
  id: ObjectId;
};

export default markAsReadEndpoint;
