/*
 * List messages
 *
 * This contains all the text for the List component.
 */
import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  clearFilters: () => __(translations.listing.clearFilters, 'Clear Filters'),
  noRecords: () => __(translations.listing.noRecords, 'There are no records to display…'),
  perPage: () => __(translations.listing.perPage, 'Per page'),
  search: () => __(translations.listing.search, 'Search…'),
  show: () => __(translations.listing.show, 'Show…'),
  showingXOfY: () => __(translations.listing.showingXOfY, 'Showing {{x}} - {{y}} of {{z}}'),
  title: () => __(translations.listing.title, 'Search'),
};
