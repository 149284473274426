import React, {FC, Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {selectIsAuthenticated} from 'store/auth/selectors';

const GuestMiddleware: FC<any> = props => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const isGuest = !isAuthenticated;

  if (isGuest) {
    return <Fragment>{props.children}</Fragment>;
  }

  return <Navigate to={{pathname: '/'}} />;
};

export default GuestMiddleware;
