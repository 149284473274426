import React from 'react';
import {FC} from 'react';
import messages from './messages';
import {OverpaymentCompact} from 'features/acounting/types/overpayment';
import {MODAL} from 'features/modal-notch/constants';
import {Archive, FileText} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Button, UncontrolledTooltip} from 'reactstrap';
import showModal from 'store/ui/actions/show-modal';
import {STATUS} from 'constants/status';
import clsx from 'clsx';
import Can from 'features/hylian-shield/can';

interface Props {
  row: OverpaymentCompact;
}

const OverpaymentsActions: FC<Props> = ({row}) => {
  const dispatch = useDispatch();

  const {id, status} = row;

  const {t} = useTranslation();
  const updateOverpaymentStatus = (newStatus: string) => {
    dispatch(
      showModal(MODAL.TOGGLE_STATUS_OVERPAYEMENT, {
        overpaymentId: id,
        newStatus,
        currentStatus: status,
      })
    );
  };

  return (
    <div className="d-flex gap-1">
      <Can action="edit" subject="accounting">
        <Button
          className={clsx(
            {
              'd-none': status === STATUS.ARCHIVED,
              'btn-success': status === STATUS.AVAILABLE,
              'btn-info': status === STATUS.USED,
            },
            'btn-sm'
          )}
          onClick={() => updateOverpaymentStatus(STATUS.USED)}
          id={`tooltip-mark-as-used-${id}`}
        >
          <FileText size={16} />
        </Button>
        <UncontrolledTooltip
          placement="top"
          target={`tooltip-mark-as-used-${id}`}
        >
          {status === STATUS.AVAILABLE
            ? t(...messages.markAsUsed())
            : t(...messages.markAsAvailable())}
        </UncontrolledTooltip>

        <Button
          className={clsx(
            {
              'btn-danger':
                status === STATUS.AVAILABLE || status === STATUS.USED,
              'btn-default': status === STATUS.ARCHIVED,
            },
            'btn-sm'
          )}
          onClick={() => updateOverpaymentStatus(STATUS.ARCHIVED)}
          id={`tooltip-archive-${id}`}
        >
          <Archive size={16} />
        </Button>
        <UncontrolledTooltip placement="top" target={`tooltip-archive-${id}`}>
          {status === STATUS.ARCHIVED
            ? t(...messages.removeFromArchive())
            : t(...messages.archive())}
        </UncontrolledTooltip>
      </Can>
    </div>
  );
};

export default OverpaymentsActions;
