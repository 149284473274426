import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.familyPage.pageTitle, 'Families'),
  families: () => __(translations.familyPage.families, 'Families list'),
  addFamily: () => __(translations.familyPage.addFamily, 'Add Family'),
  colections: () => __(translations.familyPage.colections, 'Colections'),
  name: () => __(translations.familyPage.name, 'Name'),
  code: () => __(translations.familyPage.code, 'Code'),
  inspiration: () => __(translations.familyPage.inspiration, 'Inspiration'),
  createdAt: () => __(translations.familyPage.createdAt, 'Created At'),
  edit: () => __(translations.familyPage.edit, 'Edit'),
};
