import Stocklocation from 'features/stock/types/stock-location';
import {StockProduct} from 'features/stock/types/stock-products';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateStockEntry = api.injectEndpoints({
  endpoints: builder => ({
    updateStockEntry: builder.mutation<Response, updateStockEntryRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `stock/products/stock-entry`,
          body: {
            serial_number: params.serial_number,
          },
        };
      },
    }),
  }),
});

export type updateStockEntryRequest = {
  serial_number: string;
};

type Response = ResponseData<StockProduct>;

export const {useUpdateStockEntryMutation} = updateStockEntry;
