import {uiActions} from '../ui-slice';
import {MODAL} from 'features/modal-notch/constants';
import {Directory} from 'types/directory';

export default (value?: string) => {
  return uiActions.showModal({
    modalType: MODAL.GLOBAL_SEARCH,
    params: {
      value: value,
    },
  });
};
