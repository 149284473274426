import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useTransferProductForm} from './use-transfer-product-form';
import {Link} from 'react-router-dom';
import {generateLink} from 'features/commercial/documents/containers/related-documents-section/related-documents-section';

const TransferProductForm: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    isLoadingSerialNumber,
    validationErrorsSerialNumber,
    ErrorMessageSerialNumber,
    validationErrors,
    errorMessage,
    setParams,
    submitSerialNumber,
  } = useTransferProductForm();

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <div className="w-100">
              <TextInput
                onChange={serial_number =>
                  setParams({...params, serial_number})
                }
                value={params.serial_number}
                label={t(...messages.serialNumber())}
                errors={
                  validationErrors.serial_number ||
                  validationErrorsSerialNumber.serial_number
                }
              />
            </div>
            <Button
              className="align-self-end"
              isLoading={isLoadingSerialNumber}
              color="important"
              onClick={() =>
                params.serial_number
                  ? submitSerialNumber(params.serial_number)
                  : null
              }
            >
              {!isLoadingSerialNumber && (
                <i className="bx bx-search-alt align-middle"></i>
              )}
            </Button>
          </div>
          {params.stockProduct && (
            <>
              <div className="my-3 text-center">
                <h4>
                  <Link
                    to={`/dashboard/products/${params.stockProduct?.product_variant?.product_id}/variants/${params.stockProduct?.product_variant?.id}/general`}
                  >
                    {params.stockProduct?.product_variant?.name}
                  </Link>
                </h4>
                {params.stockProduct?.order?.relatedDocuments[0] && (
                  <Link
                    to={generateLink(
                      params.stockProduct?.order?.relatedDocuments[0]
                    )}
                  >
                    <p>
                      {
                        params.stockProduct?.order.relatedDocuments[0]
                          ?.model_ref
                      }
                    </p>
                  </Link>
                )}
              </div>
              <AsyncItemPicker
                endPoint="data/stock-sub-locations"
                onChange={stock_sub_location =>
                  setParams({...params, stock_sub_location})
                }
                value={params.stock_sub_location}
                label={t(messages.subLocation())}
                errors={validationErrors.stock_sub_location_id}
              />{' '}
            </>
          )}

          <div className="row my-2">
            <div className="col">
              <div>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </div>
              <div className="mt-3 d-grid"></div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} color="important" onClick={submit}>
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default TransferProductForm;
