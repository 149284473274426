import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  today: () => __(translations.monitoringCalendarView.today, 'Today'),
  print: () => __(translations.monitoringCalendarView.print, 'Print'),
  month: () => __(translations.monitoringCalendarView.month, 'Month'),
  week: () => __(translations.monitoringCalendarView.week, 'Week'),
  day: () => __(translations.monitoringCalendarView.day, 'Day'),
};
