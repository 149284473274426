import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalHeader} from 'reactstrap';
import EmptyState from 'features/table/compenents/empty-state';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {SerialNumber} from 'types/product-variant';
import {groupBySerialNumbers} from 'features/stock/utils/group-by-serial-numbers';
import {ObjectId} from 'types/object-id';
import {useGetProductInPreparationDetailsQuery} from 'features/stock/products-stock-grid/store/endpoints/get-product-in-preparation-details';
import ListSkeleton from 'features/table/compenents/list-skeleton';

const DetailsStockInPreparation: FC<Props> = props => {
  const {onDismiss, stockInPreparation} = props;

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <h4 className="text-center">{stockInPreparation?.client_name}</h4>
        <h5 className="text-center">
          <Link
            target="_blank"
            to={`/dashboard/orders/${stockInPreparation.order?.id}/details`}
          >
            {stockInPreparation.order?.document_ref}
          </Link>
        </h5>
        <StockProductTable id={stockInPreparation.id} />
      </ModalBody>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
  stockInPreparation: StockProductInPreparation;
}

export default DetailsStockInPreparation;

const StockProductTable = (props: {id: ObjectId}) => {
  const {id} = props;
  const {data, isLoading} = useGetProductInPreparationDetailsQuery(
    {id},
    {refetchOnMountOrArgChange: true}
  );

  const commercial_products = data?.data?.commercial_products;

  const {t} = useTranslation();
  if (isLoading) {
    return <ListSkeleton />;
  }

  return commercial_products?.length === 0 ? (
    <EmptyState />
  ) : (
    <table className="table">
      <thead>
        <tr>
          <th>{t(...messages.sku())}</th>
          <th className="text-center">{t(...messages.name())}</th>
          <th className="text-center">{t(...messages.quantity())}</th>
          <th className="text-center">{t(...messages.location())}</th>
        </tr>
      </thead>
      <tbody>
        {commercial_products?.map(product => {
          return (
            <tr key={product.id}>
              <td>{product.product_variant?.sku}</td>
              <td className="text-center">{product.product_variant?.name}</td>
              <td className="text-center">{product.quantity}</td>
              <td>
                {groupBySerialNumbers(product.serial_numbers).map((sn, x) => (
                  <div key={x}>
                    <span>{sn.location_name}</span>
                    <ul>
                      {sn.serial_numbers.map((child, y) => (
                        <li key={y}>{child.code}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
