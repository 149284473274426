import React, {FC, Fragment, ReactNode, useState} from 'react';
import messages from './messages';
import Currency from 'components/currency/currency';
import FormattedDate from 'components/formatted-date';
import StatusBadge from 'components/status-badge';
import {useTranslation} from 'react-i18next';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Alert, Col, Collapse, Row} from 'reactstrap';
import {Quotation} from 'types/quotation';
import NaturalNumber from 'components/natural-numbers/natural-numbers';
import Percent from 'components/percent/percent';
import {ArrowDown, ArrowUp} from 'react-feather';

interface Props {
  quotation: Quotation;
  onReload?: () => void;
}
interface ItemProps {
  label: string;
  value: string | number | undefined | ReactNode;
}
const SummaryItem: FC<ItemProps> = props => {
  const {label, value} = props;
  return (
    <p className="me-3 mb-1">
      <span className="fw-bold">{label}: </span>
      <span className="ms-1">{value ? value : '-'}</span>
    </p>
  );
};

const SummarySection: FC<Props> = props => {
  const {quotation, onReload, ...all} = props;

  const {t} = useTranslation();

  const [isCollapseOpen, setOpenCollapse] = useState(false);

  return (
    <div>
      <Fragment>
        <Row>
          <div className="col-sm order-2 order-sm-1">
            <div className="d-flex align-items-start mt-3">
              <div className="flex-grow-1">
                <div>
                  <h1
                    className="text-primary"
                    data-testid="quotation-reference"
                  >
                    {quotation.document_ref}
                  </h1>
                </div>
                <div
                  style={{width: '60%'}}
                  className="d-flex flex-wrap mt-2 mb-2"
                >
                  {quotation.project && (
                    <SummaryItem
                      label={t(...messages.projectName())}
                      value={quotation.project?.name}
                    />
                  )}

                  <SummaryItem
                    label={t('quotationProduction.quotationAnnouncedDeadline')}
                    value={
                      <FormattedDate
                        value={quotation.production_declared_completion_date}
                        format="date"
                      />
                    }
                  />
                </div>
                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 font-size-13">
                  <div>
                    <div className="d-flex flex-wrap gap-2">
                      <span className="fw-bold">{t(...messages.status())}</span>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex flex-wrap gap-2 font-size-16 mb-2">
                      <StatusBadge status={quotation.status} />
                    </div>
                  </div>
                </div>
                <div
                  style={{width: '60%'}}
                  className="d-flex flex-wrap mt-2 mb-2"
                >
                  <p className="me-3 mb-0">
                    <span className="fw-bold">{t(...messages.client())}</span>
                    <span className="ms-1">
                      <Link
                        to={`/dashboard/directories/${quotation.directory?.id}/general`}
                        target="_blank"
                      >
                        {quotation.directory?.display_name}
                      </Link>
                    </span>
                  </p>

                  <SummaryItem
                    label={t(...messages.contact())}
                    value={quotation.user?.name}
                  />
                  <SummaryItem
                    label={t(...messages.clientFamily())}
                    value={quotation.directory?.directory_families
                      ?.map(fam => fam.name)
                      ?.join(',')}
                  />
                </div>

                <Collapse isOpen={isCollapseOpen}>
                  <div style={{width: '60%'}} className="d-flex flex-wrap">
                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.totalPurchaseCostsExcl())} :
                      </span>
                      <span className="ms-1">
                        <Trans
                          i18nKey="numbers.currency"
                          values={{
                            val: quotation.marge_infos?.purchase_price_ht,
                          }}
                        />
                      </span>
                    </p>

                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.totalSellingPriceExcl())} :
                      </span>
                      <span className="ms-1">
                        <Trans
                          i18nKey="numbers.currency"
                          values={{
                            val: quotation.marge_infos?.selling_price_ht,
                          }}
                        />
                      </span>
                    </p>
                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.marginExcl())} :
                      </span>
                      <span className="ms-1 d-inline">
                        <Currency value={quotation.marge_infos?.marge_ht} />
                        {' | '}
                        <Percent value={quotation.marge_infos?.marge_percent} />
                        {' | '}
                        <NaturalNumber
                          value={quotation.marge_infos?.marge_coef}
                        ></NaturalNumber>
                      </span>
                    </p>
                  </div>
                  <div
                    style={{width: '60%'}}
                    className="d-flex flex-wrap mt-2 mb-2"
                  >
                    <SummaryItem
                      label={t(...messages.transporter())}
                      value={quotation.transporter?.display_name}
                    />
                    <p className="me-3 mb-1">
                      <span className="fw-bold">
                        {t(...messages.transparterPrice())} :
                      </span>
                      <span className="ms-1">
                        <Currency value={quotation.transporter_price} />
                      </span>
                    </p>
                  </div>
                </Collapse>
                <a
                  href="#"
                  onClick={() => setOpenCollapse(!isCollapseOpen)}
                  className="link-underline"
                  id="toggler"
                >
                  {t(...messages.moreInformations())}
                  {isCollapseOpen ? (
                    <ArrowUp size={14} />
                  ) : (
                    <ArrowDown size={14} />
                  )}
                </a>
              </div>
            </div>
          </div>
        </Row>

        <Row className="mt-3">
          <Col>
            {/* TODO: conditionally show this alert */}
            {false && (
              <Alert color="warning">
                <h4 className="alert-heading">Alert title</h4>
                <p className="mb-0">Something comes here</p>
              </Alert>
            )}
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

export default SummarySection;
