import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

export const getGeneralSettings = api.injectEndpoints({
  endpoints: builder => ({
    getGeneralSettings: builder.query<Response, void>({
      query: () => ({
        method: 'GET',
        url: `settings/general`,
      }),
    }),
  }),
});

type Response = ResponseData<Settings['general']>;

export const {useGetGeneralSettingsQuery} = getGeneralSettings;

export const generalSettingsEndpoint =
  getGeneralSettings.endpoints.getGeneralSettings;
