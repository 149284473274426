import React from 'react';
import {CreditUsage} from 'features/acounting/types/credit';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {UncontrolledTooltip} from 'reactstrap';

interface TooltipProps {
  cell: CreditUsage;
}

const TooltipCell = ({cell}: TooltipProps) => {
  const {t} = useTranslation();

  const link =
    cell.used_in_document_type === 'Invoice'
      ? `/dashboard/invoices/${cell.used_in_document_object.id}/details`
      : `/dashboard/orders/${cell.used_in_document_object.id}/details`;

  return (
    <div id={`tooltip-used-on-${cell.id}`} className=" py-1">
      <UncontrolledTooltip
        placement="top"
        target={`tooltip-used-on-${cell.id}`}
      >
        <span>
          <Trans i18nKey="numbers.currency" values={{val: cell.amount_used}} />{' '}
          {cell.payment && ` | ${t(`creditsPage.${cell.payment.type}`)}`}
        </span>
      </UncontrolledTooltip>
      <Link to={link} target="_blank">
        {cell.used_in_document_object.document_ref}
      </Link>
    </div>
  );
};

export default TooltipCell;
