import {UpdateProductRequest} from './update-product';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const actionProductDelete = api.injectEndpoints({
  endpoints: builder => ({
    actionProductDelete: builder.mutation<Response, Request>({
      query: params => {
        const variants =
          params.selectedVariants ?? ({} as Record<ObjectId, boolean>);
        return {
          method: 'DELETE',
          url: `products/${params.id}/force-delete-multiple`,
          body: {
            all_are_selected: params.all_are_selected,
            product_variants: Object.keys(variants).filter(id => variants[id]),
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: (_a, _b, arg) => {
        const variants =
          arg.selectedVariants ?? ({} as Record<ObjectId, boolean>);
        const product_variants_id = Object.keys(variants).filter(
          id => variants[id]
        );

        const invalidateVariantTags = product_variants_id.map(id => {
          return {type: 'variants', id};
        });
        return invalidateVariantTags;
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
  url: string;
  all_are_selected?: boolean;
  selectedVariants?: Record<ObjectId, boolean>;
};

type Response = ResponseData<ProductVariant>;

export const {useActionProductDeleteMutation} = actionProductDelete;
