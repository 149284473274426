import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  CardBody,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from 'reactstrap';
import messages from '../messages';
import {Trash2} from 'react-feather';
import {IContactForm} from '../use-contacts-form';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {Link} from 'react-router-dom';

const BodySection: FC<Props> = ({
  regroupedContacts,
  options,
  defaultOpenedAccordion,
  search,
  setSearch,
  handleRemoveInput,
  handleRowsInputsChange,
}) => {
  const {t} = useTranslation();

  return (
    <CardBody>
      <CardTitle>
        <h5>{t(...messages.contactsList())}</h5>
        <TextInput
          placeholder={t('common.search')}
          value={search}
          className="w-25"
          onChange={val => setSearch(val)}
        />
      </CardTitle>
      {defaultOpenedAccordion.length > 0 ? (
        <UncontrolledAccordion stayOpen defaultOpen={defaultOpenedAccordion}>
          {regroupedContacts.map((element, x) => (
            <AccordionItem key={x} color="danger">
              <AccordionHeader targetId={x.toString()}>
                <h5 color="light">
                  {t(
                    `editProjectContactsSection.${element.type.toLowerCase()}`
                  )}
                  <Badge
                    color={element.data.length > 0 ? 'success' : 'light'}
                    className="mx-2"
                  >
                    {element.data.length}
                  </Badge>
                </h5>
              </AccordionHeader>
              <AccordionBody accordionId={x.toString()}>
                {element.data.length > 0 ? (
                  <>
                    {element.data.map((child, y) => (
                      <Row style={{alignItems: 'center'}} key={y}>
                        <Col md={3} className="d-flex gap-2">
                          <div>
                            <Button
                              color="transparent"
                              className="border-0"
                              id={`tooltip-${y}`}
                              onDoubleClick={() =>
                                handleRemoveInput(child.identifier)
                              }
                            >
                              <Trash2 className="text-danger" size={20} />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltip-${y}`}
                            >
                              {t(...messages.doubleClickToRemove())}
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex align-items-center">
                            <Link
                              target="_BLANK"
                              to={`/dashboard/directories/${child.contact.id}/general`}
                              className="fw-bold"
                            >
                              {child.contact.display_name}
                            </Link>
                          </div>
                        </Col>

                        <Col md={3}>
                          <a
                            href={`mailto:${child.contact.main_directory_email?.email}`}
                          >
                            {child.contact.main_directory_email?.email}
                          </a>
                        </Col>
                        <Col md={2}>{child.contact.main_phone?.number}</Col>
                        <Col md={2}>
                          <div className="mt-4">
                            <ItemPicker
                              placeholder="Statut..."
                              value={child.status}
                              options={options.status}
                              onChange={status =>
                                handleRowsInputsChange(
                                  'status',
                                  status,
                                  child.identifier
                                )
                              }
                            />
                            {child.contact.directory_families.map((df, z) => (
                              <Badge key={z} color="secondary">
                                {df.name}
                              </Badge>
                            ))}
                          </div>
                        </Col>
                        <Col md={2}>
                          <TextInput
                            type="textarea"
                            data-testid="product-name-input"
                            rows={1}
                            placeholder="Commentaire"
                            value={child.comment}
                            onChange={comment =>
                              handleRowsInputsChange(
                                'comment',
                                comment,
                                child.identifier
                              )
                            }
                          />
                        </Col>

                        <hr
                          className="mt-2"
                          style={{
                            display:
                              y < element.data.length - 1 ? 'block' : 'none',
                          }}
                        ></hr>
                      </Row>
                    ))}
                  </>
                ) : (
                  <p style={{textAlign: 'center'}}>{t(...messages.noData())}</p>
                )}
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
      ) : (
        <div className="d-flex justify-content-center py-4">
          <p>{t('common.noData')}</p>
        </div>
      )}
    </CardBody>
  );
};

type Props = IContactForm;

export default BodySection;
