import {
  updateLocationCategoryRequest,
  useUpdateLocationCategoryMutation,
} from 'features/stock/store/endpoint/update-location-category';
import StockLocationCatergory from 'features/stock/types/stock-location-category';
import loadData from 'features/table/slice/actions/load-data';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useLocationCategoryForm = () => {
  const [submit, {isLoading, error}] = useUpdateLocationCategoryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);
  const stockLocationCategory =
    modalParams?.stockLocationCategory as StockLocationCatergory;

  const [params, setParams] = useState<Params>({
    id: stockLocationCategory?.id,
    name: stockLocationCategory?.name,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(loadData({name: 'stock-location-categories', page: 1}));
      dispatch(uiActions.dismissModalWithResult(result.data.data));
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = updateLocationCategoryRequest;
