import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {AuthResponse} from 'services/api/response-types';
import {ApiEndpointBuilder} from 'services/api/types';
import {saveAuthState} from 'services/auth-service';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';

const refreshEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<ResponseData<User>, void>({
    // @ts-expect-error
    async queryFn(_, api, extra, fetchWithBaseQuery) {
      const result = await fetchWithBaseQuery({
        url: 'me',
        method: 'GET',
      });
      if (result.error) {
        return {error: result.error as FetchBaseQueryError};
      }
      const response = result.data as AuthResponse;

      saveAuthState({
        user: response.data,
      });
      return {
        data: result.data,
      };
    },
  });
};

export default refreshEndpoint;
