import React from 'react';
import {FC} from 'react';
import messages from './messages';
import Can from 'features/hylian-shield/can';
import {tableSlice} from 'features/table/slice';
import {Edit, Eye, Trash2, RotateCw} from 'react-feather';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {DropdownMenu, DropdownItem, Button} from 'reactstrap';
import {UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import {uiActions} from 'store/ui/ui-slice';
import {User} from 'types/user';
import {NavLink} from 'react-router-dom';

interface Props {
  user: User;
}

const UserActions: FC<Props> = ({user}) => {
  const {action, is_deleted, role, deletable} = user || {};

  const navigate = useNavigate();

  /** Returns whether there are any actions available */
  const hasActions = ![].length; // Object.values(action).some(Boolean);

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const destroyUser = () => {
    dispatch(
      tableSlice.actions.destroyResource({
        resourceId: user.id,
        resourceName: 'users',
      })
    );
  };
  return (
    <div className="d-flex align-items-center h-100">
      <Button
        className="me-2"
        color="transparent"
        onClick={() => navigate(`/dashboard/users/${user.id}/profile`)}
      >
        <Eye size={20} />
      </Button>
      {role?.name !== 'ROOT' && (
        <Button
          className="me-2"
          onClick={() => navigate(`/dashboard/users/${user.id}/edit`)}
          color="transparent"
        >
          <Edit size={20} />
        </Button>
      )}

      {deletable && (
        <Button
          onClick={e =>
            dispatch(
              uiActions.confirmDelete({
                resourceId: user.id,
                name: 'users',
                onDestroy: destroyUser,
                message: t(t(...messages.dystoryMessage())),
              })
            )
          }
          color="transparent"
        >
          <Trash2 className="text-danger" size={20} />
        </Button>
      )}
    </div>
  );
};

export default UserActions;
