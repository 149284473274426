import hydrateAuthState from '../actions/hydrate-auth-state';
import {authApi} from '../endpoints';
import {AuthState} from '../types';
import {AnyAction, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {AuthResponse} from 'services/api/response-types';
import {WritableDraft} from 'types/writable-draft';

/**
 * Matches all auth endpoints that return both
 * a user object and an access token
 *
 */
const loginMatcher = isAnyOf(
  authApi.endpoints.login.matchFulfilled,
  authApi.endpoints.register.matchFulfilled,
  authApi.endpoints.confirmRegistration.matchFulfilled,
  hydrateAuthState.fulfilled
);

/**
 * Successful classic Login/Registration
 *
 */
const loginReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<AuthResponse> | AnyAction
) => {
  state.accessToken = payload.token;
  state.user = payload.data;
};

export const login = {
  matcher: loginMatcher,
  reducer: loginReducer,
};
