import {FilterMap} from '../types';
import {createAction} from '@reduxjs/toolkit';
import {ResourceName} from 'types/resource-name';

export default createAction(
  'table/hydrateFilters',
  function prepare(name: ResourceName, filters: FilterMap) {
    return {
      payload: {
        filters,
        name,
      },
    };
  }
);
