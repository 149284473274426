export const timeFormatters: Formatters = {
  datetime: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
  date: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  time: {
    hour: '2-digit',
    minute: '2-digit',
  },
  custom: {},
};

export type Formatters = Record<string, Intl.DateTimeFormatOptions>;
