import React from 'react';
import {AppRoute} from './types';
import StockLocalisationPage from 'pages/stock/localisation';
import StockLocationCategoriesPage from 'pages/stock/categories';
import StockSubLocationPage from 'pages/stock/sub-location';
import StockProductsPage from 'pages/stock/stock-products';
import ProductsGridRouter from 'pages/dashboard/stock/products-grid-router/products-grid-router';
import StockProductsDisputeSAVPage from 'pages/stock/stock-products-dispute-sav';
import StockInProductsPage from 'pages/stock/stock-in-products';
import StockProductsInPreparationPage from 'pages/stock/products-in-preparation';

const stockRoutes: Array<AppRoute> = [
  {
    path: '/dashboard/products-stock/*',
    component: <ProductsGridRouter />,
    subject: 'recap-stock',
  },
  {
    path: '/dashboard/stock-locations',
    component: <StockLocalisationPage />,
  },
  {
    path: '/dashboard/stock-location-categories',
    component: <StockLocationCategoriesPage />,
  },
  {
    path: '/dashboard/stock-sub-locations',
    component: <StockSubLocationPage />,
  },
  {
    path: '/dashboard/stock-products',
    component: <StockProductsPage />,
  },
  {
    path: '/dashboard/stock-products-dispute-sav',
    component: <StockProductsDisputeSAVPage />,
  },
  {
    path: '/dashboard/stock-products-in-stock',
    component: <StockInProductsPage />,
  },
  {
    path: '/dashboard/stock-products-in-preparation',
    component: <StockProductsInPreparationPage />,
  },
];

export default stockRoutes;
