import StockSublocation from 'features/stock/types/stock-sub-location';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const updateSubLocation = api.injectEndpoints({
  endpoints: builder => ({
    updateSubLocation: builder.mutation<Response, updateSubLocationRequest>({
      query: params => {
        return {
          method: 'PUT',
          url: `stock-sub-locations/${params.id}`,
          body: {
            name: params.name,
            stock_location_id: params.stock_location?.id,
          },
        };
      },
    }),
  }),
});

export type updateSubLocationRequest = {
  id: ObjectId;
  name: string;
  stock_location: Option;
};

type Response = ResponseData<StockSublocation>;

export const {useUpdateSubLocationMutation} = updateSubLocation;
