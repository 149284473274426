import React from 'react';
import messages from './messages';
import useGeneralSettingsForm from './use-general-settings-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {CardTitle, Col, Row} from 'reactstrap';

const GeneralTab = () => {
  const {t} = useTranslation();

  const {
    isLoading,
    errorMessage,
    isSuccess,
    submit,
    validationErrors,
    params,
    setParams,
  } = useGeneralSettingsForm();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="mb-0">
          <h5 className="font-size-15">{t(...messages.title())}</h5>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div>
          <div className="mt-2 pt-50">
            <Row>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.websiteName())}
                  value={params.site_name}
                  onChange={site_name => setParams({...params, site_name})}
                  errors={validationErrors?.site_name}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.email())}
                  value={params.company_email}
                  onChange={company_email =>
                    setParams({...params, company_email})
                  }
                  errors={validationErrors?.site_name}
                />
              </Col>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.companyAddressLine1())}
                  value={params.company_address_L1}
                  onChange={company_address_L1 =>
                    setParams({...params, company_address_L1})
                  }
                  errors={validationErrors?.company_address_L1}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.companyAddressLine2())}
                  value={params.company_address_L2}
                  onChange={company_address_L2 =>
                    setParams({...params, company_address_L2})
                  }
                  errors={validationErrors?.company_address_L2}
                />
              </Col>

              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.companyAddressZip())}
                  value={params.company_address_zip}
                  onChange={company_address_zip =>
                    setParams({...params, company_address_zip})
                  }
                  errors={validationErrors?.company_address_zip}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.companyAddressCity())}
                  value={params.company_address_city}
                  onChange={company_address_city =>
                    setParams({...params, company_address_city})
                  }
                  errors={validationErrors?.company_address_city}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.phone())}
                  value={params.company_phone}
                  onChange={company_phone =>
                    setParams({...params, company_phone})
                  }
                  errors={validationErrors?.company_phone}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.fax())}
                  value={params.company_fax}
                  onChange={company_fax => setParams({...params, company_fax})}
                  errors={validationErrors?.company_fax}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.websiteDomain())}
                  value={params.site_domain_name}
                  onChange={site_domain_name =>
                    setParams({...params, site_domain_name})
                  }
                  errors={validationErrors?.site_domain_name}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.websiteURL())}
                  value={params.site_url}
                  onChange={site_url => setParams({...params, site_url})}
                  errors={validationErrors?.site_url}
                />
              </Col>

              <Col className="mt-2" sm="12">
                <AppBarActions>
                  <Button
                    type="submit"
                    className="me-1"
                    onClick={submit}
                    isLoading={isLoading}
                    color="important"
                  >
                    <Icon name="check" className="me-2" size={15} />
                    {t('common.saveChanges')}
                  </Button>
                </AppBarActions>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default GeneralTab;
