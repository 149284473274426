import {StockValue} from 'features/stock/types/stock-value';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const getFamiliesInStock = api.injectEndpoints({
  endpoints: builder => ({
    getFamiliesInStock: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `stock/product-collections/${params.id}/families`,
        params: {
          type: params.type,
        },
      }),
      forceRefetch: () => true,
    }),
  }),
});

type Request = {
  id: ObjectId;
  type: string;
};
type Response = ResponseData<
  {
    id: ObjectId;
    name: string;
    stock: StockValue[];
  }[]
>;

export const {useGetFamiliesInStockQuery} = getFamiliesInStock;
