import React, {FC} from 'react';
import {Fragment} from 'react';
import {OPTIONS} from '../../../commercial/pricing/grid-controls/constants';
import messages from '../messages';
import Collections from './collections';
import Actions from './exports-section';
import MassActions from './mass-actions';
import {ProductsFilters} from './types';
import {useFilter} from './use-filter';
import AsyncItemPicker from 'components/async-item-picker';
import ItemPicker from 'components/item-picker';
import TagsItem from 'components/tags-card';
import {FilterMap} from 'features/listing/list/slice/types';
import {useTranslation} from 'react-i18next';
import {CardBody, Row, Col, Label, Input} from 'reactstrap';
import {FormGroup} from 'reactstrap';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<ProductsFilters>;
  onChangeFilters: (key: ProductsFilters, value: any) => void;
  onClearFilters?: () => void;
}

const ProductFilters: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  const {stockCounts, totals, isLoadingStockCounts, isLoadingTotals} =
    useFilter(filters);

  const collection = (filters['filter[product_collection_id]'] ??
    []) as ObjectId[];

  return (
    <Fragment>
      <CardBody className="mb-0 pb-0">
        <Row className="align-items-center justify-content-center mb-3">
          <Col sm={3}>
            <TagsItem
              isLoading={isLoadingStockCounts}
              title={t(...messages.total())}
            >
              {stockCounts?.stock_total ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem
              isLoading={isLoadingStockCounts}
              title={t(...messages.stockDispo())}
            >
              {stockCounts?.stock_available ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem
              isLoading={isLoadingTotals}
              title={t(...messages.productsTotal())}
            >
              {totals?.product_count ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem
              isLoading={isLoadingTotals}
              title={t(...messages.totalProduct())}
            >
              {totals?.variant_count ?? 0}
            </TagsItem>
          </Col>
        </Row>
        <SearchSection
          filters={filters}
          additionalActions={MassActions}
          onChangeFilters={onChangeFilters}
          onClearFilters={onClearFilters}
        >
          <Row className="mb-3">
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/products"
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.masterProduct())}
                  loadOnClick
                  value={filters['filter[product_id]']}
                  onChange={o => onChangeFilters('filter[product_id]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  key={`product-families-${collection}`}
                  endPoint="data/product-families"
                  otherParams={
                    collection.length > 0
                      ? {
                          product_collections: collection,
                        }
                      : undefined
                  }
                  className="mb-1"
                  label={t(...messages.families())}
                  value={filters['filter[product_family_id]']}
                  isMulti
                  loadOnClick
                  onChange={o =>
                    onChangeFilters('filter[product_family_id]', o)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/suppliers-type-company"
                  getOptionLabel={(v: any) => v.display_name}
                  className="mb-1"
                  loadOnClick
                  label={t(...messages.supplier())}
                  value={filters['filter[supplier_id]']}
                  onChange={o => onChangeFilters('filter[supplier_id]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <ItemPicker
                  label={t(...messages.inStock())}
                  options={OPTIONS}
                  // @ts-ignore
                  getOptionLabel={(o: Option) => t(...o.label())}
                  isClearable
                  value={filters['filter[in_stock]'] as Option}
                  onChange={o => onChangeFilters('filter[in_stock]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/colors"
                  label={t(...messages.color())}
                  loadOnClick
                  onChange={o => onChangeFilters('filter[color_id]', o)}
                  value={filters['filter[color_id]']}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/matters"
                  label={t(...messages.matter())}
                  loadOnClick
                  onChange={o => onChangeFilters('filter[matter_id]', o)}
                  value={filters['filter[matter_id]']}
                />
              </FormGroup>
            </Col>
            {/* <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/product-variants-categories"
                  defaultValue
                  getDefaultOptions={(meta: any) => meta?.default_index}
                  label={t(...messages.category())}
                  onChange={o =>
                    onChangeFilters('filter[product_variant_category_id]', o)
                  }
                  value={filters['filter[product_variant_category_id]']}
                />
              </FormGroup>
            </Col> */}
            <Col lg="3" md="3">
              <FormGroup>
                <AsyncItemPicker
                  endPoint="data/projects"
                  label={t(...messages.project())}
                  loadOnClick
                  onChange={o => onChangeFilters('filter[project]', o)}
                  value={filters['filter[project]']}
                />
              </FormGroup>
            </Col>
            {/* 
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_old_collection"
                    checked={!!filters['filter[is_old_collection]']}
                    onChange={(o: any) =>
                      onChangeFilters(
                        'filter[is_old_collection]',
                        o.target?.checked
                      )
                    }
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="is_old_collection"
                  >
                    {t(...messages.isoldCollection())}
                  </Label>
                </div>
              </FormGroup>
            </Col> */}
            <Col lg="3" md="3">
              <FormGroup className="d-flex align-items-end h-100">
                <div
                  className="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="is_blocked"
                    checked={!!filters['filter[is_blocked]']}
                    onChange={(o: any) =>
                      onChangeFilters('filter[is_blocked]', o.target?.checked)
                    }
                  />
                  <Label className="form-check-label" htmlFor="is_blocked">
                    {t(...messages.isBlocked())}
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </SearchSection>
        <Actions filters={filters} />
        <Collections filters={filters} onChangeFilters={onChangeFilters} />
      </CardBody>
    </Fragment>
  );
};

export default ProductFilters;
