import React, {FC} from 'react';
import messages from './messages';
import DateInput from 'components/date-input';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ValidationErrors} from 'services/api/types';
import {EditOrderRequest} from 'features/commercial/orders/store/endpoints/edit-order';
import styled from 'styled-components';

interface Props {
  validationErrors: ValidationErrors;
  onChangeParams: (p: any) => void;
  params: EditOrderRequest;
  is_locked?: boolean;
}

const OrderSupplier: FC<Props> = props => {
  const {validationErrors, params, onChangeParams, is_locked} = props;
  const {t} = useTranslation();
  /*   const {
    params,
    validationErrors,
    isLoading,
    errorMessage,
    submit,
    isSuccess,
    onChangeParams,
  } = useOrderSupplierForm(); */
  return (
    <div className="">
      <SectionSeparator title={t(...messages.title())} />
      <FakeSpace />

      <Row>
        <Col md={12} className="mb-2">
          <DateInput
            label={t(...messages.orderSupplierDeadline())}
            disabled={is_locked}
            value={params.estimated_supplier_time}
            onChange={estimated_supplier_time =>
              onChangeParams({...params, estimated_supplier_time})
            }
            errors={validationErrors.estimated_supplier_time}
          />
        </Col>
        <Col md={12} className="my-1 mb-2">
          <DateInput
            label={t(...messages.controlDate())}
            value={params.delivery_date}
            disabled={is_locked}
            onChange={delivery_date =>
              onChangeParams({...params, delivery_date})
            }
            errors={validationErrors.delivery_date}
          />
        </Col>
        <Col md={12} className="mb-2">
          <TextInput
            label={t(...messages.orderProductionComment())}
            disabled={is_locked}
            value={params.production_comments}
            errors={validationErrors.production_comments}
            onChange={production_comments => {
              onChangeParams({
                ...params,
                production_comments,
              });
            }}
          />
        </Col>
        <Col md={12} className="mb-2">
          <TextInput
            label={t(...messages.orderSupplierRef())}
            disabled={is_locked}
            value={params.supplier_reference}
            errors={validationErrors.supplier_reference}
            onChange={supplier_reference => {
              onChangeParams({
                ...params,
                supplier_reference,
              });
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrderSupplier;

const FakeSpace = styled.div`
  height: 11px;
`;
