import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const attachDirectory = api.injectEndpoints({
  endpoints: builder => ({
    attachDirectory: builder.mutation<Response, AttachDirectoryRequest>({
      query: params => ({
        method: 'POST',
        url: `/directories/${params.id}/attach-company`,
        body: {
          company_id: params.company?.id,
        },
      }),
      // @ts-expect-error
      invalidatesTags: (_a, _b, arg) =>
        _a ? [{type: 'directories', id: arg.id}] : [],
    }),
  }),
});

export type AttachDirectoryRequest = {
  id?: ObjectId;
  company?: Directory;
};

type Response = ResponseData<{message: string}>;

export const {useAttachDirectoryMutation} = attachDirectory;
