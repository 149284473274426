import React, {FC, ReactNode} from 'react';

interface EmptyCellProps {
  cell?: string | number | ReactNode;
  fallback?: string | ReactNode;
  className?: string;
}

const EmptyCell: FC<EmptyCellProps> = ({cell, className, fallback = ''}) => {
  return `${cell}`.trim() && cell ? (
    <span className={`w-100 py-2 ${className} `}>{cell}</span>
  ) : (
    <span className="w-100 text-center">{fallback}</span>
  );
};

export default EmptyCell;
