import {extraReducers} from './extra-reducers';
import {initialState} from './initial-state';
import {createSlice} from '@reduxjs/toolkit';

const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {},
  extraReducers,
});

export const productionActions = productionSlice.actions;

export default productionSlice;
