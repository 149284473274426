import React from 'react';
import QuotationDetailsTab from '../../tabs/details/quotation-details-tab';
import QuotationGeneralTab from '../../tabs/general/quotation-general-tab';
import QuotationLogsTab from '../../tabs/logs/quotation-logs-tab';
import QuotationRelatedPartsTab from '../../tabs/related-parts/quotation-related-parts-tab';
import {Route, Routes} from 'react-router';

const QuotationDetailsRouter = () => {
  return (
    <Routes>
      <Route path="/general" element={<QuotationGeneralTab />} />
      <Route path="/details" element={<QuotationDetailsTab />} />
      <Route path="/related-parts" element={<QuotationRelatedPartsTab />} />
      <Route path="/logs" element={<QuotationLogsTab />} />
      <Route path={`/`} element={<div>Nothing for now…</div>} />
    </Routes>
  );
};

export default QuotationDetailsRouter;
