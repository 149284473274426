import React, {useEffect, useState} from 'react';
import {FC, Fragment} from 'react';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useTranslation} from 'react-i18next';
import {
  Col,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import messages from './messages';
import TextInput from 'components/text-input';
import {
  StoreProjectManDaysConfigRequest,
  useStoreProjectManDaysConfigMutation,
} from '../../store/store-project-man-days-config';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {parseValidationErrors} from 'services/api/utils/parse-error';

const ConfigureManDaysModal: FC<Props> = props => {
  const {t} = useTranslation();

  const {onDismiss} = props;

  const dispatch = useDispatch();

  const {onUpdateProject, project} = useSelector(selectActiveModalParams);

  const [params, setParams] = useState<Params>(project);

  const [storeProjectManDaysConfig, {isLoading, error, data: response}] =
    useStoreProjectManDaysConfigMutation();

  const serverError = parseValidationErrors(error);

  const validationErrors = serverError.errors;

  const handleSubmit = async () => {
    const result = await storeProjectManDaysConfig(params);

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
      onUpdateProject(result.data.data);
      onDismiss();
    }
  };

  const handleChange = (key: keyof Params, value: number) => {
    setParams({
      ...params,
      [key]: value,
    } as Params);
  };

  useEffect(() => {
    if (error) dispatch(toast('error', serverError.message));
  }, [error]);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">{t(...messages.manDays())}</span>
      </ModalHeader>
      <ModalBody className="">
        <FormGroup>
          <Row className="mt-3">
            <h6>{`${t(...messages.artistic_director())} (India)`}</h6>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.dailyScale())}
                  className="mb-1"
                  type="number"
                  errors={validationErrors.artistic_director_mandays_cost}
                  value={params.artistic_director_mandays_cost}
                  onChange={val =>
                    handleChange('artistic_director_mandays_cost', Number(val))
                  }
                />
              </div>
            </Col>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.numberOfManDays())}
                  className="mb-1"
                  type="number"
                  errors={validationErrors.artistic_director_mandays_rate}
                  value={params.artistic_director_mandays_rate}
                  onChange={val =>
                    handleChange('artistic_director_mandays_rate', Number(val))
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <h6>{t(...messages.project_manager())}</h6>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.dailyScale())}
                  className="mb-1"
                  type="number"
                  errors={validationErrors.project_manager_mandays_cost}
                  value={params.project_manager_mandays_cost}
                  onChange={val =>
                    handleChange('project_manager_mandays_cost', Number(val))
                  }
                />
              </div>
            </Col>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.numberOfManDays())}
                  className="mb-1"
                  type="number"
                  errors={validationErrors.others_mandays_rate}
                  value={params.others_mandays_rate}
                  onChange={val =>
                    handleChange('others_mandays_rate', Number(val))
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <h6>{t(...messages.assistant())}</h6>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.dailyScale())}
                  className="mb-1"
                  type="number"
                  errors={validationErrors.assistant_mandays_cost}
                  value={params.assistant_mandays_cost}
                  onChange={val =>
                    handleChange('assistant_mandays_cost', Number(val))
                  }
                />
              </div>
            </Col>
            <Col md={6} lg={6} xs={12}>
              <div>
                <TextInput
                  label={t(...messages.numberOfManDays())}
                  type="number"
                  disabled
                  errors={validationErrors.others_mandays_rate}
                  value={params.others_mandays_rate}
                  onChange={() => {}}
                />
              </div>
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onDismiss}>
          {t('common.cancel')}
        </Button>
        <Button isLoading={isLoading} onClick={handleSubmit} color="important">
          <Icon name="check" size={15} className="me-1" />
          {t('common.saveChanges')}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

type Params = StoreProjectManDaysConfigRequest;

export default ConfigureManDaysModal;
