import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  comment: () => __(translations.createProductPage.comment, 'Comment'),
  create: () => __(translations.createProductPage.create, 'New product'),
  pageTitle: () => __(translations.createProductPage.pageTitle, 'New product'),
  productionTime: () => __(translations.createProductPage.productionTime, 'Production time'),
  products: () => __(translations.createProductPage.products, 'Products'),
  stockMax: () => __(translations.createProductPage.stockMax, 'Stock max'),
  stockMin: () => __(translations.createProductPage.stockMin, 'Stock min'),
  description: () => __(translations.createProductPage.description, 'Description'),
  saveAs: () => __(translations.createProductPage.saveAs, 'Save as'),
  collection: () => __(translations.createProductPage.collection, 'Collection'),
  name: () => __(translations.createProductPage.name, 'Name'),
  vat: () => __(translations.createProductPage.vat, 'V.A.T'),
  inCatalog: () => __(translations.createProductPage.inCatalog, 'in Catalog'),
  family: () => __(translations.createProductPage.family, 'Family'),
};
