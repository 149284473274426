import {useCallback, useEffect, useState} from 'react';
import loadData from '../slice/actions/load-data';
import {SortingState} from '@tanstack/react-table';
import {actions} from 'features/table/slice';
import {ListParams} from 'features/table/types';
import {useDispatch} from 'react-redux';
import {ObjectId} from 'types/object-id';
import {ResourceName} from 'types/resource-name';

const useSortColumns = (
  name: ResourceName,
  url: string,
  otherParams: ListParams | undefined
) => {
  const [sort, setSort] = useState<SortingState>([]);

  const dispatch = useDispatch();
  const onSort = (selectedColumn: ObjectId, sortDirection: 'desc' | 'asc') => {
    const [sort, direction] = [selectedColumn, sortDirection];
    if (selectedColumn) {
      dispatch(actions.changeSort({sort, direction, name}));
    } else {
      dispatch(actions.changeSort({name}));
    }
    dispatch(loadData({url, name, otherParams}));
  };

  useEffect(() => {
    const {id, desc} = sort[0] || {};
    onSort(id, desc ? 'desc' : 'asc');
  }, [sort]);

  return {
    setSort,
    sort,
  };
};

export default useSortColumns;
