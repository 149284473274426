import React, {FC, useState} from 'react';
import messages from '../messages';
import {DirectoryFilters} from './types';
import Icon from '@ailibs/feather-react-ts';
import {FilterMap} from 'features/listing/list/slice/types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {Dropdown, DropdownToggle} from 'reactstrap';
import {Spinner, DropdownMenu, DropdownItem} from 'reactstrap';
import {useLazyGetDirectoryExportQuery} from 'store/directory/endpoints/get-directory-export';
import toast from 'store/ui/actions/toast';
import Can from 'features/hylian-shield/can';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';

interface Props {
  filters: FilterMap<DirectoryFilters>;
}

const Actions: FC<Props> = props => {
  const {filters} = props;

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleSubmitCompanyDirectory = async (filters: any) => {
    const result = await submitDirectory(filters);
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [dropdownAdd, setDropdownAdd] = useState(false);

  const navigate = useNavigate();

  const filtersCompany = {...filters, 'filter[directory_type]': 'COMPANY'};

  const filtersContact = {...filters, 'filter[directory_type]': 'INDIVIDUAL'};

  const filtersAll = {...filters, 'filter[directory_type]': ''};

  const [submitDirectory, {isFetching: isLoadingDirectory}] =
    useLazyGetDirectoryExportQuery();

  return (
    <AppBarActions>
      <div className="justify-content-end d-flex">
        <Can action="create" subject="directory">
          <Dropdown
            isOpen={dropdownAdd}
            toggle={() => setDropdownAdd(!dropdownAdd)}
            direction="down"
          >
            <DropdownToggle color="important">
              <Icon name="plus" size={15} className="me-1" />
              {t(...messages.add())}
              <Icon name="chevron-down" className="ms-1" size={15} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigate('/dashboard/directories/companies/create');
                }}
              >
                {t(...messages.addCompany())}
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  navigate('/dashboard/directories/contacts/create')
                }
              >
                {t(...messages.addContact())}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Can>
        {/* Action */}
        <Can action="export" subject="directory">
          <Dropdown
            disabled={isLoadingDirectory}
            isOpen={dropdownOpen}
            className="ms-2"
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle color="light">
              {isLoadingDirectory ? (
                <Spinner size={'sm'} />
              ) : (
                t(...messages.exports())
              )}
              <Icon name="chevron-down" size={15} className="ms-1" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                disabled={isLoadingDirectory}
                onClick={() => handleSubmitCompanyDirectory(filtersContact)}
              >
                {t(...messages.exportContact())}
              </DropdownItem>
              <DropdownItem
                disabled={isLoadingDirectory}
                onClick={() => handleSubmitCompanyDirectory(filtersCompany)}
              >
                {t(...messages.exportCompany())}
              </DropdownItem>
              <DropdownItem
                disabled={isLoadingDirectory}
                onClick={() => handleSubmitCompanyDirectory(filtersAll)}
              >
                {t(...messages.exportAll())}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Can>
        <Can subject="directory" action="administration">
          <Link
            to="/dashboard/directories/categories"
            className="btn btn-light ms-2"
          >
            <Icon name="list" size={15} className="me-1" />
            {t(...messages.category())}
          </Link>
          <Link
            to="/dashboard/directories/families"
            className="btn btn-light ms-2"
          >
            <Icon name="list" size={15} className="me-1" />
            {t(...messages.family())}
          </Link>
        </Can>
      </div>
    </AppBarActions>
  );
};

export default Actions;
