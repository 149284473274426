import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductFamily} from 'types/product-family';
import {ResponseData} from 'types/response-data';

const updateProductFamily = api.injectEndpoints({
  endpoints: builder => ({
    updateProductFamily: builder.mutation<Response, UpdateProductFamilyRequest>(
      {
        query: params => ({
          method: 'PUT',
          url: `/product-families/${params.id}`,
          body: {
            name: params.name,
            code: params.code,
            inspiration: params.inspiration,
            product_collections: params.collections?.map(x => x.id),
          },
        }),
      }
    ),
  }),
});

export type UpdateProductFamilyRequest = {
  id?: ObjectId;
  name: string;
  code?: string;
  inspiration?: string;
  collections?: Option[];
};

type Response = ResponseData<ProductFamily>;

export const {useUpdateProductFamilyMutation} = updateProductFamily;
