import i18n from 'i18next';

const appService = {
  /**
   * Applies any necessary locale change adaptations
   *
   * Put here anything  related such as RTL tweaks
   * RTL adaptations...etc
   *
   */
  applyLocaleChangeAdaptations: async (locale: string) => {
    i18n.changeLanguage(locale);
  },
};

export default appService;
