import React from 'react';
import columns from './columns';
import ProjectFilters from './filters-section';
import messages from './messages';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Table from 'features/table';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import './index.scss';

const ProjectsPage = () => {
  const {t} = useTranslation();
  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},

            {title: t(...messages.pageTitle()), path: '#'},
          ]}
        />
        <Table
          columns={columns}
          url="projects"
          hideSearch
          name="projects"
          otherParams={{
            delimiter: '|',
          }}
          filtersComponent={ProjectFilters}
        ></Table>
      </Container>
    </div>
  );
};

export default ProjectsPage;
