import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ResponseData} from 'types/response-data';

const mergeDirectory = api.injectEndpoints({
  endpoints: builder => ({
    mergeDirectory: builder.mutation<Response, MergeDirectoryRequest>({
      query: params => ({
        method: 'POST',
        url: `/directories/${params.id}/merge`,
        body: {
          directory_id: params.directory?.id,
        },
      }),
      async onQueryStarted({}, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;
          await dispatch(updateCachedDirectoryFromResource(directory));
        } catch (e) {}
      },
    }),
  }),
});

export type MergeDirectoryRequest = {
  id?: ObjectId;
  directory?: Option;
};

type Response = ResponseData<Directory>;

export const {useMergeDirectoryMutation} = mergeDirectory;
