import React, {FC, useContext} from 'react';
import documentContext from '../../containers/document-context/document-context';
import removeItemOption from '../../store/actions/remove-item-option';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import {CommercialProduct} from '../../types/commercial-product';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {uiActions} from 'store/ui/ui-slice';
import {CommercialProductOption} from 'types/commercial-product-option';
import classNames from 'classnames';

interface Props {
  option: CommercialProductOption;
  item: CommercialProduct;
}

const OptionRemovalButton: FC<Props> = props => {
  const {option, item} = props;

  const {id: doc_id} = useParams();

  const dispatch = useDispatch();

  const isRemote = useSelector(selectIsRemoteObject(option.id));

  const {doc_type, is_locked} = useContext(documentContext);

  const handleRemoveOption = () => {
    if (!doc_type) {
      throw new Error(`Unknown document type`);
    }
    if (isRemote) {
      dispatch(
        uiActions.confirmDelete({
          name: `${doc_type}-product-option`,
          otherParams: {
            doc_id,
            item_id: item.id,
          },
          resourceId: option.id,
        })
      );
    } else {
      dispatch(removeItemOption(option.id));
    }
  };

  return (
    <Button
      onClick={handleRemoveOption}
      disabled={is_locked}
      className="px-0 pt-1"
      color="link"
      size="sm"
    >
      <Icon
        name="trash-2"
        className={classNames('', {
          'opacity-0': is_locked,
          'text-danger': true,
        })}
        size={20}
      />
    </Button>
  );
};

export default OptionRemovalButton;
