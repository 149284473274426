import React from 'react';
import messages from './messages';
import useFiscalSettingsForm from './use-fiscal-settings-form';
import Icon from '@ailibs/feather-react-ts';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, CardHeader} from 'reactstrap';
import {CardTitle, Col, Row} from 'reactstrap';

const FiscalInfoTab = () => {
  const {t} = useTranslation();

  const {
    isLoading,
    errorMessage,
    isSuccess,
    submit,
    validationErrors,
    params,
    setParams,
  } = useFiscalSettingsForm();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="mb-0">
          <h5 className="font-size-15">{t(...messages.title())}</h5>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div>
          <div className="mt-2 pt-50">
            {/* Row 1 */}
            <Row>
              <Col sm="4" className="mb-1">
                <TextInput
                  label={t(...messages.companyName())}
                  value={params.company_name}
                  onChange={company_name =>
                    setParams({...params, company_name})
                  }
                  errors={validationErrors?.companyName}
                />
              </Col>
              <Col sm="4" className="mb-1">
                <TextInput
                  label={t(...messages.legalForm())}
                  value={params.company_legal_form}
                  onChange={company_legal_form =>
                    setParams({...params, company_legal_form})
                  }
                  errors={validationErrors?.legal_form}
                />
              </Col>
              <Col sm="4" className="mb-1">
                <TextInput
                  label={t(...messages.capital())}
                  value={params.company_capital}
                  onChange={company_capital =>
                    setParams({...params, company_capital})
                  }
                  errors={validationErrors?.company_capital}
                />
              </Col>
            </Row>
            {/* Row 2 */}
            <Row>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.eoriNumber())}
                  value={params.company_eori_number}
                  onChange={company_eori_number =>
                    setParams({...params, company_eori_number})
                  }
                  errors={validationErrors?.company_eori_number}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.ape())}
                  value={params.company_ape}
                  onChange={company_ape => setParams({...params, company_ape})}
                  errors={validationErrors?.company_ape}
                />
              </Col>
            </Row>
            {/* Row 3 */}
            <Row>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.rcs())}
                  value={params.company_rcs}
                  onChange={company_rcs => setParams({...params, company_rcs})}
                  errors={validationErrors?.company_rcs}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.rexNumber())}
                  value={params.company_rex_number}
                  onChange={company_rex_number =>
                    setParams({...params, company_rex_number})
                  }
                  errors={validationErrors?.company_rex_number}
                />
              </Col>
            </Row>
            {/* Row 4 */}
            <Row>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.vatNumber())}
                  value={params.company_vat_number}
                  onChange={company_vat_number =>
                    setParams({...params, company_vat_number})
                  }
                  errors={validationErrors?.company_vat_number}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.ribOrBic())}
                  value={params.company_rib_bic}
                  onChange={company_rib_bic =>
                    setParams({...params, company_rib_bic})
                  }
                  errors={validationErrors?.company_rib_bic}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.ribAddress())}
                  value={params.company_rib_address}
                  onChange={company_rib_address =>
                    setParams({...params, company_rib_address})
                  }
                  errors={validationErrors?.company_rib_address}
                />
              </Col>
              <Col sm="6" className="mb-1">
                <TextInput
                  label={t(...messages.iban())}
                  value={params.company_iban}
                  onChange={company_iban =>
                    setParams({...params, company_iban})
                  }
                  errors={validationErrors?.company_iban}
                />
              </Col>
            </Row>
            {/* Row 4 */}
            <Row>
              <Col sm="12" className="mb-1">
                <TextInput
                  label={t(...messages.recoveryCostsDisclaimer())}
                  value={params.disclaimer_recovery_costs}
                  type="textarea"
                  onChange={recovery_costs_disclaimer =>
                    setParams({
                      ...params,
                      disclaimer_recovery_costs: recovery_costs_disclaimer,
                    })
                  }
                  errors={validationErrors?.disclaimer_recovery_costs}
                />
              </Col>
              <Col sm="12" className="mb-1">
                <TextInput
                  label={t(...messages.interestRatesDisclaimer())}
                  value={params.disclaimer_interest_rates}
                  type="textarea"
                  onChange={interest_rates_disclaimer =>
                    setParams({
                      ...params,
                      disclaimer_interest_rates: interest_rates_disclaimer,
                    })
                  }
                  errors={validationErrors?.disclaimer_interest_rates}
                />
              </Col>
              <Col sm="12" className="mb-1">
                <TextInput
                  label={t(...messages.paymentMethodDisclaimer())}
                  value={params.disclaimer_payment_method}
                  type="textarea"
                  onChange={payment_method_disclaimer =>
                    setParams({
                      ...params,
                      disclaimer_payment_method: payment_method_disclaimer,
                    })
                  }
                  errors={validationErrors?.disclaimer_payment_method}
                />
              </Col>
              <Col sm="12" className="mb-1">
                <TextInput
                  label={t(...messages.vintageVatDisclaimer())}
                  value={params.invoice_vintage_phrase}
                  type="textarea"
                  onChange={invoice_vintage_phrase =>
                    setParams({
                      ...params,
                      invoice_vintage_phrase,
                    })
                  }
                  errors={validationErrors?.invoice_vintage_phrase}
                />
              </Col>
            </Row>

            {/* Validation and submit */}
            <Row>
              <Col className="mt-2" sm="12">
                <AppBarActions>
                  <Button
                    className="me-1"
                    onClick={submit}
                    isLoading={isLoading}
                    color="important"
                  >
                    <Icon name="check" className="me-2" size={15} />
                    <Trans i18nKey="common.saveChanges" />
                  </Button>
                </AppBarActions>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default FiscalInfoTab;
