import {ContactTypesEnum} from 'constants/contact';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {IContact, Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectContacts = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectContacts: builder.mutation<
      Response,
      StoreProjectContactsRequest
    >({
      query: (body: StoreProjectContactsRequest) => {
        return {
          method: 'POST',
          url: `projects/${body.id}/contacts`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

interface IContactRequest {
  directory_id: ObjectId;
  comment: string | undefined;
  type: ContactTypesEnum;
}

export type StoreProjectContactsRequest = {
  id: string | undefined;
  contacts: IContactRequest[];
  deleted_records?: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectContactsMutation} = storeProjectContacts;
