import React from 'react';
import columns from './columns';
import messages from './messages';
import StockOrderControls from './stock-orders-controls/stock-orders-controls';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';
import Table from 'features/table';
import {CompactStockOrder} from 'features/commercial/orders/types/order';

const StockOrderPage = () => {
  const {t} = useTranslation();
  const makeRowClassName = (row: CompactStockOrder) =>
    row.status === 'VALIDATED' ? 'bg-gray-light' : '';
  return (
    <div className="page-content pb-0">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {title: t(...messages.orders()), path: '#'},
          ]}
        />
        <Table
          columns={columns}
          url="orders?type=STOCK"
          makeRowClassName={makeRowClassName}
          hideSearch
          name="stock-orders"
          filtersComponent={StockOrderControls}
        />
      </Container>
    </div>
  );
};

export default StockOrderPage;
