import React, {FC, Fragment, useEffect} from 'react';
import messages from '../messages';
import LabelRow from './label-row';
import './order-send-label-modal.styles.scss';
import {useSendLabelOrder} from './use-action-order-send-label';
import Button from 'components/button';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalFooter, ModalHeader, Alert} from 'reactstrap';
import OtherEmailAddresses from './other-email-addresses';
import makeId from 'utils/make-id';
import {useSelector} from 'react-redux';
import {selectActiveModalParams} from 'store/ui/selectors';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';

const OrderSendLabelAction: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    labels,
    validationErrors,
    setParams,
    params,
    syncLabelSupplierEmail,
    updateLabel,
    errorMessage,
    emails,
    onChangeEmails,
  } = useSendLabelOrder(onDismiss);

  const {id} = useSelector(selectActiveModalParams);
  const {data} = useSelector(selectOrder({id}));
  const o = data?.data;

  const email = o?.supplier_email;

  useEffect(() => {
    if (email) {
      onChangeEmails({...emails, [makeId()]: email});
    }
  }, [email]);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>
      <ModalBody>
        <LabelRow
          syncLabelSupplierEmail={syncLabelSupplierEmail}
          updateLabel={updateLabel}
          labels={labels}
          validationErrors={validationErrors}
        />
        <TextInput
          rows={12}
          type="textarea"
          value={params.body}
          label={t(...messages.orderEmailSendSubject())}
          onChange={body => {
            setParams({...params, body});
          }}
        />
        <OtherEmailAddresses
          emails={emails}
          onChangeEmails={onChangeEmails}
          validationErrors={validationErrors}
        />
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};
interface Props {
  message?: string;
  onDismiss: () => void;
  submit: () => void;
  isLoading?: boolean;
}

export default OrderSendLabelAction;
