import {Order} from 'features/commercial/orders/types/order';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const adjustOrderDelay = api.injectEndpoints({
  endpoints: builder => ({
    adjustOrderDelay: builder.mutation<Response, ResizeOrderRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `orders/${params.id}/adjust-delay`,
          body: {
            to: params.to,
          },
        };
      },
    }),
  }),
});

export type ResizeOrderRequest = {
  id?: ObjectId;
  to?: string;
};

type Response = ResponseData<Order>;

export const {useAdjustOrderDelayMutation} = adjustOrderDelay;
