import React, {FC} from 'react';
import messages from './messages';
import ProjectDetailsSection from './project-details-section';
import Breadcrumbs from 'components/Common/Breadcrumb';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {Container} from 'reactstrap';

interface Props {}

const EditProjectPage: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className="page-content">
      <Helmet>
        <title> {t(...messages.pageTitle())}</title>
      </Helmet>
      <Container fluid>
        <Breadcrumbs
          showHeading={false}
          breadcrumbItems={[
            {title: t('common.dashboard'), path: '/dashboard'},
            {
              title: t(...messages.projectTitle()),
              path: '/dashboard/projects',
            },
            {title: t(...messages.pageTitle()), path: '#'},
          ]}
        />
        <ProjectDetailsSection />
      </Container>
    </div>
  );
};

export default EditProjectPage;
