import {useEffect, useState} from 'react';
import {nanoid} from '@reduxjs/toolkit';
import {produce} from 'immer';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectVariant} from 'store/production/endpoints/show-variant';
import {useUpdateVariantComponentsMutation} from 'store/production/endpoints/update-components';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductVariant} from 'types/product-variant';
import makeId from 'utils/make-id';
import normalizeArray from 'utils/normalize-array';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';
import {useTranslation} from 'react-i18next';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useComponentsForm = () => {
  const [update, {isLoading, error, isSuccess}] =
    useUpdateVariantComponentsMutation();

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const {id, variant: variantId} = useParams();

  const [components, setComponents] = useState<ComponentMap>({});

  const [deleteRecords, setDeleteRecords] = useState<ObjectId[]>([]);

  const handleSubmit = () => {
    const result = update({
      components: Object.values(components),
      deleteRecords,
      id,
      variant: variantId,
    });

    if ('data' in result) {
      dispatch(toast('success', t('common.changesCommited')));
    } else {
      dispatch(toast('error', serverError.message));
    }
  };

  const addComponent = () => {
    const p: Single = {
      id: makeId(),
      quantity: 1,
    };
    setComponents(
      produce(components, draft => {
        draft[p.id] = p;
      })
    );
  };

  const removeComponent = (id?: ObjectId) => {
    if (!id) {
      return;
    }
    const _components: ComponentMap = Object.assign({}, components);
    delete _components[id];
    setComponents({
      ..._components,
    });
    setDeleteRecords([...deleteRecords, id]);
  };

  const updateComponent = (p: Single, k: keyof Single, v: any) => {
    setComponents(
      produce(components, draft => {
        draft[p.id][k] = v;
      })
    );
  };

  const {data} = useSelector(selectVariant({id: variantId, productId: id}));

  const variant = data?.data;

  useEffect(() => {
    if (variant) {
      const tmp = variant.components.map(x => ({
        id: x.id,
        quantity: x.component.quantity,
        name: x.name,
        variant: x,
      }));
      setComponents(normalizeArray(tmp));
    }
  }, [variant]);

  const filterOption = (option: Option<ProductVariant>, inputValue: string) => {
    const isSelected = Object.values(components).some(
      x => x?.variant?.id === option.value
    );
    return !isSelected;
  };

  return {
    addComponent,
    filterOption,
    removeComponent,
    submit: handleSubmit,
    updateComponent,
    components,
    errorMessage: serverError.message,
    isLoading,
    isSuccess,
    validationErrors: serverError.errors,
  };
};

type Single = {
  id: ObjectId;
  variant?: ProductVariant;
  quantity?: number;
  type?: Option;
};
type ComponentMap = Record<ObjectId, Single>;
