import {api} from 'services/api';
import {Model} from 'types/model';
import {ResponseData} from 'types/response-data';

const getAsyncData = api.injectEndpoints({
  endpoints: builder => ({
    getAsyncData: builder.query<Response, Request>({
      query: params => ({
        method: params.method ?? 'GET',
        url: params.url,
      }),
      forceRefetch() {
        return true;
      },
    }),
  }),
});

type Request = {
  url: string;
  method?: 'GET' | 'POST';
};

type Response = ResponseData<Model[]>;

export const {useGetAsyncDataQuery, useLazyGetAsyncDataQuery} = getAsyncData;

export const getAsyncDataEndpoint = getAsyncData.endpoints.getAsyncData;
