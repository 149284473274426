import selectAllLineTotals from './select-all-line-totals';
import selectAllVats from './select-all-vats';
import {createSelector} from '@reduxjs/toolkit';

/**
 * Initially, all grand totals are set to 0
 *
 */
const initialState = {
  subtotalExclTax: 0,
  totalExclTax: 0,
  vats: 0,
  totalInclTax: 0,
  totalAfterGlobalDiscount: 0,
  ecoTax: 0,
};

export default createSelector(
  [selectAllLineTotals, selectAllVats],
  (lineTotals, vats) => {
    const t = lineTotals.reduce(
      (a, c) => {
        a.subtotalExclTax += c.priceExclTaxes;
        a.totalInclTax += c.priceIncTaxes;
        a.totalExclTax += c.priceIncTaxes - c.taxes;
        a.totalAfterGlobalDiscount += c.totalAfterGlobalDiscount;
        a.ecoTax += c.ecoTax;
        return a;
      },
      {...initialState}
    );

    return {
      subtotalExclTax: t.subtotalExclTax,
      totalExclTax: t.totalExclTax,
      vats,
      totalInclTax: t.totalInclTax,
      subTotalAfterGlobalDiscount: t.totalAfterGlobalDiscount,
      ecoTax: t.ecoTax,
    };
  }
);
