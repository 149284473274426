import React, {FC} from 'react';
import {
  layoutTypes,
  layoutTheme,
  layoutWidthTypes,
} from '../../constants/layout';
import LanguageDropdown from './TopbarDropdown/LanguageDropdown';
import messages from './messages';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import 'simplebar/src/simplebar.css';
import {layoutActions} from 'store/layout/layout-slice';
import {selectLayoutWidth} from 'store/layout/selectors';
import {selectLayoutState} from 'store/layout/selectors';
import {AppTheme} from 'store/layout/types';

interface Props {}
const __DEV__ = process.env.NODE_ENV === 'development';

const RightSidebar: FC<Props> = () => {
  const dispatch = useDispatch();

  const {
    layoutType,
    layoutMode,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
  } = useSelector(selectLayoutState);

  const layoutWidth = useSelector(selectLayoutWidth);

  const onChangeMode = (value: AppTheme) => {
    dispatch(layoutActions.changeLayoutTheme(value));
  };

  const {t} = useTranslation();

  return (
    <React.Fragment>
      <div data-simplebar className="h-100">
        <div className="p-4">
          <div className="d-flex flex-column justify-content-between align-items-start center ">
            <h6>{t(...messages.language())}</h6>
            <LanguageDropdown />
          </div>
          {/*    <Row>
             <Col>
              <h6 className="mt-4 mb-3">{t(...messages.layoutWidth())}</h6>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioFluid"
                  name="radioWidth"
                  value={layoutWidthTypes.FLUID}
                  className="form-check-input"
                  checked={layoutWidth === layoutWidthTypes.FLUID}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(layoutActions.changeLayoutWidth('fluid'));
                    }
                  }}
                />
                <label htmlFor="radioFluid" className="form-check-label">
                  
                  {t(...messages.fluid())}
                </label>
              </div>
              {'   '}
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioBoxed"
                  name="radioWidth"
                  value={layoutWidthTypes.BOXED}
                  className="form-check-input"
                  checked={layoutWidth === layoutWidthTypes.BOXED}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(layoutActions.changeLayoutWidth('boxed'));
                    }
                  }}
                />
                <label htmlFor="radioBoxed" className="form-check-label">
                  {t(...messages.boxed())}
                </label>
              </div>
            </Col> 
             <Col>
              <h6 className="mt-4 mb-3 pt-2"> {t(...messages.layoutMode())}</h6>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value={layoutTheme.LIGHTMODE}
                  className="form-check-input"
                  checked={layoutMode === layoutTheme.LIGHTMODE}
                  onChange={e => {
                    onChangeMode('light');
                    if (layoutType !== layoutTypes.HORIZONTAL)
                      dispatch(layoutActions.changeSidebarTheme('light'));
                  }}
                />
                <label className="form-check-label" htmlFor="radioThemeLight">
                  {t(...messages.light())}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value={layoutTheme.DARKMODE}
                  className="form-check-input"
                  checked={layoutMode === layoutTheme.DARKMODE}
                  onChange={e => {
                    onChangeMode('dark');
                    if (layoutType !== layoutTypes.HORIZONTAL)
                      dispatch(layoutActions.changeSidebarTheme('dark'));
                  }}
                />
                <label className="form-check-label" htmlFor="radioThemeDark">
                  {t(...messages.dark())}
                </label>
              </div>
            </Col> 
          </Row> */}
          {__DEV__ && (
            <Col>
              <h6 className="mt-4 mb-3 pt-2"> {t(...messages.layoutMode())}</h6>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioThemeLight"
                  name="radioTheme"
                  value={layoutTheme.LIGHTMODE}
                  className="form-check-input"
                  checked={layoutMode === layoutTheme.LIGHTMODE}
                  onChange={e => {
                    onChangeMode('light');
                    if (layoutType !== layoutTypes.HORIZONTAL)
                      dispatch(layoutActions.changeSidebarTheme('light'));
                  }}
                />
                <label className="form-check-label" htmlFor="radioThemeLight">
                  {t(...messages.light())}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="radioThemeDark"
                  name="radioTheme"
                  value={layoutTheme.DARKMODE}
                  className="form-check-input"
                  checked={layoutMode === layoutTheme.DARKMODE}
                  onChange={e => {
                    onChangeMode('dark');
                    if (layoutType !== layoutTypes.HORIZONTAL)
                      dispatch(layoutActions.changeSidebarTheme('dark'));
                  }}
                />
                <label className="form-check-label" htmlFor="radioThemeDark">
                  {t(...messages.dark())}
                </label>
              </div>
            </Col>
          )}
          <Row>
            <Col>
              <h6 className="mt-4 mb-3">{t(...messages.layoutPosition())}</h6>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="layout-position-fixed"
                  name="layout-position"
                  value={0}
                  className="form-check-input"
                  checked={!layoutPosition}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(layoutActions.changeLayoutPosition(false));
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="layout-position-fixed"
                >
                  {t(...messages.fixed())}
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="layout-position-scrollable"
                  name="layout-position"
                  value={1}
                  className="form-check-input"
                  checked={layoutPosition}
                  onChange={e => {
                    if (e.target.checked) {
                      dispatch(layoutActions.changeLayoutPosition(true));
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="layout-position-scrollable"
                >
                  {t(...messages.scrollable())}
                </label>
              </div>
            </Col>
            {/* Top bar color is removed because it creates more problems than it solves */}
          </Row>
          {layoutType === 'vertical' ? (
            <Row>
              <Col>
                <h6 className="mt-4 mb-3 sidebar-setting">
                  {t(...messages.sidebarSize())}
                </h6>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-default"
                    value="lg"
                    checked={leftSideBarType === 'lg'}
                    onChange={e => {
                      if (e.target.checked) {
                        dispatch(layoutActions.changeSidebarType('lg'));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-default"
                  >
                    {t(...messages.default())}
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-compact"
                    value="md"
                    checked={leftSideBarType === 'md'}
                    onChange={e => {
                      if (e.target.checked) {
                        dispatch(layoutActions.changeSidebarType('md'));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-compact"
                  >
                    {t(...messages.compact())}
                  </label>
                </div>
                <div className="form-check sidebar-setting">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="sidebar-size"
                    id="sidebar-size-small"
                    value="sm"
                    checked={leftSideBarType === 'sm'}
                    onChange={e => {
                      if (e.target.checked) {
                        dispatch(layoutActions.changeSidebarType('sm'));
                      }
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="sidebar-size-small"
                  >
                    {t(...messages.small())}
                  </label>
                </div>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightSidebar;
