import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.supplierOrderPage.title, 'Supplier Orders'),
  addSupplierOrder: () => __(translations.supplierOrderPage.addSupplierOrder, 'Add supplier Order'),
  countermark: () => __(translations.supplierOrderPage.countermark, 'Countermark'),
  supplier: () => __(translations.supplierOrderPage.supplier, 'Supplier'),
  customerCodeStatus: () => __(translations.supplierOrderPage.customerCodeStatus, 'Customer Code Status'),
  estimatedDelivery: () => __(translations.supplierOrderPage.estimatedDelivery, 'Estimated Delivery'),
  controlDate: () => __(translations.supplierOrderPage.controlDate, 'Control Date'),
  number: () => __(translations.supplierOrderPage.number, 'Number'),
  date: () => __(translations.supplierOrderPage.date, 'Date'),
  contact: () => __(translations.supplierOrderPage.contact, 'Contact'),
  totalHt: () => __(translations.supplierOrderPage.totalHt, 'Total excl taxes'),
  status: () => __(translations.supplierOrderPage.status, 'Status'),
  nullOrderTooltip: () => __(translations.supplierOrderPage.nullOrderTooltip, 'No records were found'),
  totalSupplierOrders: () => __(translations.supplierOrderPage.totalSupplierOrders, 'Total  supplier orders'),
  totalPendingSupplierOrders: () => __(translations.supplierOrderPage.totalPendingSupplierOrders, 'Total pending supplier orders'),
  totalValidSupplierOrders: () => __(translations.supplierOrderPage.totalValidSupplierOrders, 'Total valid supplier orders'),
  totalExpiredSupplierOrders: () => __(translations.supplierOrderPage.totalExpiredSupplierOrders, 'Total expired supplier orders'),
  type: () => __(translations.supplierOrderPage.type, 'Type'),
  year: () => __(translations.supplierOrderPage.year, 'Year'),
  month: () => __(translations.supplierOrderPage.month, 'Month'),
  supplierOrderTotalHt: () => __(translations.supplierOrderPage.supplierOrderTotalHt, 'Total orders  HT'),
  supplierOrderTotalTtc: () => __(translations.supplierOrderPage.supplierOrderTotalTtc, 'Total orders TTC'),
  estimatedDate: () => __(translations.supplierOrderPage.estimatedDate, 'Estimated Date'),
};
