import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  pageTitle: () => __(translations.productPage.pageTitle, 'Mater products'),
  masterProducts: () => __(translations.productPage.masterProducts, 'Master products'),
  name: () => __(translations.productPage.name, 'Name'),
  collectionName: () => __(translations.productPage.collectionName, 'Colection'),
  familyName: () => __(translations.productPage.familyName, 'Family'),
  variantCount: () => __(translations.productPage.variantCount, 'Variant count'),
  ecotaxProductCode: () => __(translations.productPage.ecotaxProductCode, 'Ecotax code'),
  actions: () => __(translations.productPage.actions, 'Actions'),
  alertsArchiveManyVariants: () => __(translations.actionProductPage.alertsArchiveManyVariants, 'selecting all variants will archive the product'),
};
