import {api} from 'services/api';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

const updateMailingSettings = api.injectEndpoints({
  endpoints: builder => ({
    updateMailingSettings: builder.mutation<
      Response,
      UpdateMailingSettingsRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `settings/mailing`,
          body: {
            ...params,
          },
        };
      },
    }),
  }),
});

export type UpdateMailingSettingsRequest = Settings['mailing'];

type Response = ResponseData<Settings['mailing']>;

export const {useUpdateMailingSettingsMutation} = updateMailingSettings;
