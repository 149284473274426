import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Product} from 'types/product';
import {ResponseData} from 'types/response-data';

const showProduct = api.injectEndpoints({
  endpoints: builder => ({
    showProduct: builder.query<Response, Request>({
      query: params => ({
        method: 'GET',
        url: `products/${params.id}`,
      }),
      // @ts-expect-error
      providesTags: result => {
        if (result) {
          return [{type: 'product', id: result.data.id}];
        }
        return [];
      },
    }),
  }),
});

type Request = {
  id?: ObjectId;
};

type Response = ResponseData<Product>;

export const {useShowProductQuery} = showProduct;
export const selectProduct = showProduct.endpoints.showProduct.select;
