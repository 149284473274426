import {changeAppLocale} from './change-app-locale';
import {getAllSettingsEndpoint} from './endpoints/get-all-settings';
import {getFiscalSettingsEndpoint} from './endpoints/get-fiscal-settings';
import {generalSettingsEndpoint} from './endpoints/get-general-settings';
import {updateFiscalSettingsEndpoint} from './endpoints/update-fiscal-settings';
import {updateGeneralSettingsEndpoint} from './endpoints/update-general-settings';
import {initialState, WSClientStatus} from './initial-state';
import initializeApplication from './initialize-application';
import {createSlice, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import loadLayoutPreferences from 'store/layout/actions/load-layout-preferences';
import {ResponseData} from 'types/response-data';
import {Settings} from 'types/settings';

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    wsStatusChanged(state, action: PayloadAction<WSClientStatus>) {
      state.wsClient.status = action.payload;
    },
  },
  extraReducers: build => {
    build.addCase(initializeApplication.fulfilled, (state, action) => {
      state.isBusy = false;
      state.locale = action.payload.appLocale;
    });

    build.addCase(loadLayoutPreferences.fulfilled, (state, action) => {
      state.perPage = action.payload.perPage;
    });

    build.addCase(changeAppLocale.fulfilled, (state, action) => {
      state.locale = action.payload;
      state.isBusy = false;
    });

    build.addCase(changeAppLocale.pending, state => {
      state.isBusy = true;
    });

    build.addMatcher(
      isAnyOf(
        generalSettingsEndpoint.matchFulfilled,
        updateGeneralSettingsEndpoint.matchFulfilled
      ),
      (state, action: PayloadAction<ResponseData<Settings['general']>>) => {
        state.settings.general = action.payload.data;
      }
    );

    build.addMatcher(
      isAnyOf(
        getFiscalSettingsEndpoint.matchFulfilled,
        updateFiscalSettingsEndpoint.matchFulfilled
      ),
      (state, action: PayloadAction<ResponseData<Settings['fiscal']>>) => {
        state.settings.fiscal = action.payload.data;
      }
    );

    build.addMatcher(getAllSettingsEndpoint.matchFulfilled, (state, action) => {
      state.settings = action.payload.data;
    });
  },
});

export default globalSlice;

export const globalActions = globalSlice.actions;
