import React, {FC} from 'react';
import {timeFormatters} from './constants';
import formatters from 'locales/messages';
import {useTranslation} from 'react-i18next';

interface Props {
  value?: string;
  format: 'date' | 'time' | 'datetime' | 'custom';
  customFormat?: Intl.DateTimeFormatOptions;
}

const FormattedDate: FC<Props> = props => {
  const {value, format, customFormat = {}} = props;

  const usedFormat = timeFormatters[format];

  const {t} = useTranslation();

  return value
    ? t(...formatters.intlDateTime(), {
        val: new Date(value),
        formatParams: {
          val: {
            ...usedFormat,
            ...customFormat,
          },
        },
      })
    : null;
};

export default FormattedDate;
