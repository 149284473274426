import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  customerOrders: () => __(translations.createCustomerOrderPage.customerOrders, 'Customer Orders'),
  title: () => __(translations.createCustomerOrderPage.title, 'Create customer orders'),
  client: () => __(translations.createCustomerOrderPage.client, 'Client'),
  customerOrderType: () => __(translations.createCustomerOrderPage.customerOrderType, 'Order type'),
  customerOrderCategory: () => __(translations.createCustomerOrderPage.customerOrderCategory, 'Order category'),
  customerOrderProject: () => __(translations.createCustomerOrderPage.customerOrderProject, 'Project'),
  customerOrderOrigin: () => __(translations.createCustomerOrderPage.customerOrderOrigin, 'Origin'),
  customerOrderLanguage: () => __(translations.createCustomerOrderPage.customerOrderLanguage, 'Language '),
  customerOrderBillingDate: () => __(translations.createCustomerOrderPage.customerOrderBillingDate, 'Billing date '),
  customerOrderDeadline: () => __(translations.createCustomerOrderPage.customerOrderDeadline, 'Deadline date '),
  addNewCompanyClient: () => __(translations.createCustomerOrderPage.addNewCompanyClient, 'Add Company '),
  addNewPrivateClient: () => __(translations.createCustomerOrderPage.addNewPrivateClient, 'Add Private '),
};
