import {Subject} from 'features/hylian-shield/types';

export const menuItems: MenuItems[] = [
  {
    to: '/dashboard/invoices',
    name: 'invoices',
    id: 'invoices',
    subject: ['h', 'r', 'c', 'z', 'v'],
  },
  {
    to: '/dashboard/monitoring/listing',
    name: 'monitoring',
    id: 'monitoring',
    subject: 'production-monitoring',
  },
  {
    to: '/dashboard/projects',
    name: 'studio',
    id: 'studio',
    subject: 'studio-projects',
  },
  /*   {
    to: '/dashboard/statistics',
    name: 'statistics',
    id: 'statistics',
  }, */
  {
    to: '/dashboard/directories',
    name: 'directory',
    id: 'directory',
    subject: 'directory',
  },
  {
    to: '/dashboard/products',
    name: 'products',
    id: 'products',
    subject: 'products',
  },
  {
    to: '/dashboard/pricing',
    name: 'pricing',
    id: 'pricing',
    subject: 'product-prices',
  },
  {
    to: '/dashboard/products-stock/quantity',
    name: 'stock',
    id: 'stock',
    subject: 'recap-stock',
  },
  {
    to: '/dashboard/quotations',
    name: 'quotations',
    id: 'quotations',
    subject: ['dcv', 'dcz'],
  },
  {
    to: '/dashboard/orders',
    name: 'orders',
    id: 'orders',
    subject: ['ccc', 'cch', 'ccr', 'ccs', 'ccv', 'ccz', 'cfv', 'cfz'],
  },
];

type MenuItems = {
  to: string;
  name: string;
  id: string;
  subject: Subject | Subject[];
};
