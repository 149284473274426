import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  manifacturingTime: () => __(translations.manifacturingTime.manifacturingTime, 'Manifacturing time '),
  title: () => __(translations.manifacturingTime.title, 'Manifacturing time of {{name}}'),
  Manifacturer: () => __(translations.manifacturingTime.Manifacturer, 'Manifacturer'),
  time: () => __(translations.manifacturingTime.time, 'time'),
  addTime: () => __(translations.manifacturingTime.addTime, 'Add time'),
};
