import React, {FC} from 'react';
import Actions from './actions';
import Filters from './filters';
import TagsRow from './tags-row';
import {FilterMap} from 'features/table/slice/types';
import {useTranslation} from 'react-i18next';
import {Button, CardBody, Col, Row} from 'reactstrap';
import {useLocalStorage} from 'usehooks-ts';

interface FilterProps {
  filters: FilterMap;
  onChangeFilters: (key: string, value: any) => void;
  onClearFilters?: () => void;
}

const InvoicesControls: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  return (
    <Row>
      <Col>
        <CardBody>
          <TagsRow />

          <Row className="mb-3">
            <Col className="justify-content-end d-flex" md={6}>
              <Actions filters={filters}></Actions>
            </Col>
          </Row>
          <Filters
            filters={filters}
            onClearFilters={onClearFilters}
            onChangeFilters={onChangeFilters}
          />
        </CardBody>
      </Col>
    </Row>
  );
};

export default InvoicesControls;
