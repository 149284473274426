import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedOrderFromResource} from './show-order';
import {Order} from '../../types/order';

const transferOrder = api.injectEndpoints({
  endpoints: builder => ({
    transferOrder: builder.mutation<TransferResponse, TransferOrderRequest>({
      query: params => {
        const itemsObjectified =
          params.items ?? ({} as Record<ObjectId, boolean>);
        const products = Object.keys(params.items ?? {})
          .filter(x => itemsObjectified[x])
          .map(id => ({
            commercial_product_id: id,
          }));

        return {
          method: 'POST',
          url: `/orders/${params.id}/transfer-to-invoice`,
          body: {
            products,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

export type TransferOrderRequest = {
  id?: ObjectId;
  items?: Record<ObjectId, boolean>;
};

export type TransferType = 'order_client' | 'invoice';

export type TransferResponse = ResponseData<Order> & {
  redirect_to: RedirectTo[];
};

export type RedirectTo = {
  id: string;
  model_type: 'Order' | 'Invoice';
  model_ref: string;
};

export const {useTransferOrderMutation} = transferOrder;
