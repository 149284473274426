import {authApi} from '../endpoints';
import {AuthState} from '../types';
import {AnyAction, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {ResponseData} from 'types/response-data';
import {User} from 'types/user';
import {WritableDraft} from 'types/writable-draft';

const refreshMatcher = isAnyOf(authApi.endpoints.refresh.matchFulfilled);

/**
 * Refreshes the user object
 *
 */
const refreshReducer = (
  state: WritableDraft<AuthState>,
  {payload}: PayloadAction<ResponseData<User>> | AnyAction
) => {
  state.user = payload.data;
};

export const refresh = {
  matcher: refreshMatcher,
  reducer: refreshReducer,
};
