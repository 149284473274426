import React from 'react';
import ContentLoader from 'react-content-loader';

const ProfilWithText = () => (
  <ContentLoader viewBox="0 0 100% 100%" height={'100%'} width={'100%'}>
    <rect x="20" y="31" rx="4" ry="4" width="440" height="15" />
    <rect x="500" y="31" rx="3" ry="3" width="440" height="15" />
    <rect x="20" y="80" rx="4" ry="4" width="440" height="15" />
    <rect x="500" y="80" rx="3" ry="3" width="440" height="15" />
    <rect x="20" y="130" rx="4" ry="4" width="440" height="15" />
    <rect x="500" y="130" rx="3" ry="3" width="440" height="15" />
  </ContentLoader>
);

export default ProfilWithText;
