import messages from './messages';
import {Directory} from 'types/directory';

export const directoryTabs = [
  {
    to: 'general',
    title: messages.general,
  },
  {
    to: 'coordinates',
    title: messages.coordinates,
  },
  {
    to: 'addresses',
    title: messages.addresses,
  },
  {
    to: 'complementary-info',
    title: messages.additionalInfo,
    hidden: (r?: Directory) => r?.directory_type.value === 'COMPANY',
  },
  {
    to: 'contact-list',
    title: messages.contacts,
    hidden: (r?: Directory) => r?.directory_type.value === 'COMPANY',
  },
  {
    to: 'pricing',
    hidden: (r?: Directory) =>
      r?.is_supplier && r?.directory_type.value === 'COMPANY',
    title: messages.pricing,
  },
  {
    to: 'attachments',
    title: messages.attachments,
  },
  {
    to: 'linked-resources',
    title: messages.stat,
  },
  {
    to: 'interactions',
    title: messages.interactions,
  },
];
