import {useState, useEffect} from 'react';
import messages from '../messages';
import {useDuplicatOrderMutation} from 'features/commercial/orders/store/endpoints/action-duplicat-order';
import {selectOrder} from 'features/commercial/orders/store/endpoints/show-order';
import {Order} from 'features/commercial/orders/types/order';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {CommercialDocument} from 'features/commercial/documents/types/commercial-document';
import {Option} from 'types/option';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useDuplicatOrder = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useDuplicatOrderMutation();
  const {t} = useTranslation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {onSuccess, message, id} = modalParams;

  const [params, setParams] = useState<Params>({
    id: undefined,
    directory: undefined,
  });

  const {data} = useSelector(selectOrder({id}));

  const order = data?.data as any;

  useEffect(() => {
    if (order.document_type === 'SUPPLIER') {
      setParams({
        id: order?.id,
        directory: {
          id: order?.supplier_id,
          display_name: order?.supplier_name,
        },
        document_type: order?.document_type as string,
      });
    } else {
      setParams({
        id: order?.id,
        directory: order.directory,
        document_type: order?.document_type as string,
      });
    }
  }, [order]);

  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const directory_id = params.directory?.id;
    const result = await submit({
      id,
      directory_id,
    });
    if ('data' in result) {
      onSuccess(result?.data?.data.id);
      dispatch(toast('success', t(...messages.orderDuplicateSuccess())));
      onDismiss();
    }
  };

  return {
    params,
    setParams,
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = {
  id?: ObjectId;
  directory?: Option;
  document_type?: string;
};
