import React, {FC} from 'react';
import messages from './messages';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import SectionSeparator from 'components/separator/section-separator';
import {selectQuotation} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {useTranslation, Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Table} from 'reactstrap';
import {QuotationRelatedPart} from 'types/quotation-related-part';
import AsyncPreviewTooltip from 'components/async-preview-tooltip';
import {generateLink} from 'features/commercial/documents/containers/related-documents-section/related-documents-section';
import {TYPE} from 'components/async-preview-tooltip/constant';
import StatusBadge from 'components/status-badge';
import FormattedDate from 'components/formatted-date';
import Currency from 'components/currency/currency';

const QuotationRelatedPartsTab = () => {
  const {id} = useParams();

  const {data} = useSelector(selectQuotation({id}));

  const res = data?.data?.related_documents as QuotationRelatedPart[];

  const {t} = useTranslation();

  return (
    <div>
      <SectionSeparator title={t(...messages.title())} />

      <Table>
        <thead>
          <tr>
            <th>{t(...messages.code())}</th>
            <th>{t(...messages.client())}</th>

            <th>{t(...messages.date())}</th>
            <th>{t(...messages.totalExcl())}</th>
            <th>{t(...messages.status())}</th>
          </tr>
        </thead>
        <tbody>
          {res?.map(item => (
            <tr key={item.model_id}>
              <td>
                <AsyncPreviewTooltip
                  id={item.model_id}
                  type={item.model_type.toLocaleUpperCase() as TYPE}
                  column="ref"
                >
                  <Link to={generateLink(item)} target="_blank">
                    {item.model_ref}
                  </Link>
                </AsyncPreviewTooltip>
              </td>
              <td>
                {item.document_type === 'SUPPLIER' ? (
                  <Link
                    to={`/dashboard/directories/${item.supplier?.id}/general`}
                    target="_blank"
                  >
                    {item.supplier?.display_name}
                    <Icon className="ms-1" name="external-link" size={14} />
                  </Link>
                ) : (
                  <Link
                    to={`/dashboard/directories/${item.client?.id}/general`}
                    target="_blank"
                  >
                    {item.client?.display_name}
                    <Icon className="ms-1" name="external-link" size={14} />
                  </Link>
                )}
              </td>
              <td>
                <FormattedDate value={item.created_at} format="date" />
              </td>
              <td>
                <Currency value={item.total_ht} />
              </td>
              <td>
                <StatusBadge status={item.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default QuotationRelatedPartsTab;
