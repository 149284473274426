import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  stockAndQuantities: () => __(translations.productsStockGrid.stockAndQuantities, 'Stock - Quantities'),
  stockAndValues: () => __(translations.productsStockGrid.stockAndValues, 'Stock - Values'),
  total: () => __(translations.productsStockGrid.total, 'Total'),
  export: () => __(translations.productsStockGrid.export, 'Export'),
  exportStockByProducts: () => __(translations.productsStockGrid.exportStockByProducts, 'Export stock by products'),
  exportBySupplierOrder: () => __(translations.productsStockGrid.exportBySupplierOrder, 'Export by supplier order'),
};
