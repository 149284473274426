import {TeamTypesEnum} from 'constants/team';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectTeam = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectTeam: builder.mutation<Response, StoreProjectTeamRequest>({
      query: (body: StoreProjectTeamRequest) => {
        return {
          method: 'POST',
          url: `projects/${body.id}/assistants`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

interface ITeamRequest {
  user_id: ObjectId;
  type: TeamTypesEnum;
}

export type StoreProjectTeamRequest = {
  id: string | undefined;
  team: ITeamRequest[];
  deleted_records?: ObjectId[];
};

type Response = ResponseData<Project>;

export const {useStoreProjectTeamMutation} = storeProjectTeam;
