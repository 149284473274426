import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationProduction.title, 'Production'),
  quotationDepositRequested: () => __(translations.quotationProduction.quotationDepositRequested, 'Deposit requested'),
  quotationAnnouncedDeadline: () => __(translations.quotationProduction.quotationAnnouncedDeadline, 'Announced deadline'),
  deadlineAnnouncedFr: () => __(translations.quotationProduction.deadlineAnnouncedFr, 'Deadline announced to customer (FR)'),
  deadlineAnnouncedEn: () => __(translations.quotationProduction.deadlineAnnouncedEn, 'Deadline announced to customer (EN)'),
  changesCommited: () => __(translations.quotationProduction.changesCommited, 'Your changes has been saved successfully!'),
};
