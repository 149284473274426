import styled from 'styled-components';

export const AppLogo = styled.img`
  position: relative;
  width: 160px;
  height: 190px;
  left: -5px;
`;

export const MobileLogo = styled.img`
  position: relative;
  width: 100px;
  height: 130px;
  left: -5px;
`;
