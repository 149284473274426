import {CommercialProduct} from '../types/commercial-product';
import {CommercialProductType} from 'types/commercial-product-type';
import makeId from 'utils/make-id';

export default ({at, type}: Payload): CommercialProduct => {
  const productTypes = [
    'PRODUCT',
    'EXTERNAL_PRODUCT',
  ] as CommercialProductType[];

  const isProduct = productTypes.includes(type);

  return {
    id: makeId(),
    discount: '0',
    public_comment: '',
    custom_name: '',
    order: at,
    commercial_product_type: type,
    quantity: isProduct ? '1' : undefined,
    unit_price_ht: isProduct ? '0' : undefined,
    is_custom_made: false,
    handel_stock: type === 'PRODUCT',
  };
};

type Payload = {
  at: number;
  type: CommercialProductType;
};
