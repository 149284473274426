import {ProductToAssemble} from 'features/stock/types/product-to-assamble';
import {StockProduct} from 'features/stock/types/stock-products';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

const searchSerialNumberAssemble = api.injectEndpoints({
  endpoints: builder => ({
    searchSerialNumberAssemble: builder.mutation<Response, Request>({
      query: params => ({
        method: 'POST',
        url: `stock/serial-number/search`,
        body: {
          codes: params.codes,
        },
      }),
    }),
  }),
});

type Request = {
  codes?: string[];
};

type Response = ResponseData<ProductToAssemble[]>;

export const {useSearchSerialNumberAssembleMutation} =
  searchSerialNumberAssemble;
