import {updateCachedQuotationFromResource} from './show-quotation';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Quotation} from 'types/quotation';
import {ResponseData} from 'types/response-data';

const updateQuotationDetails = api.injectEndpoints({
  endpoints: builder => ({
    updateQuotationDetails: builder.mutation<Response, UpdateQuotationRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `quotations/${params.id}/commercial-products`,
          body: {
            id: params.id,
            ...params,
          },
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const q = (await queryFulfilled).data.data;
          dispatch(updateCachedQuotationFromResource(q));
        } catch {}
      },
    }),
  }),
});

export type UpdateQuotationRequest = {
  id?: ObjectId;
};

type Response = ResponseData<Quotation>;

export const {useUpdateQuotationDetailsMutation} = updateQuotationDetails;
