import _, {Dictionary} from 'lodash';
import {Option} from 'types/option';

/**
 * This is a helper function that takes VAT values that are grouped by value
 * and unwraps them to an array of key value pairs
 *
 */
export default (input: Input): VatTotal[] => {
  const vatTotals: Record<string, VatTotal> = {};

  for (const items of Object.values(input)) {
    for (const item of items) {
      const vatName = item.vat.name as string;
      const taxes = item.taxes;

      if (!vatTotals[vatName]) {
        vatTotals[vatName] = {
          name: vatName,
          value: 0,
        };
      }

      vatTotals[vatName].value += taxes;
    }
  }

  return Object.values(vatTotals);
};

interface Item {
  taxes: number;
  vat: Option;
}

interface Input extends Dictionary<Item[]> {}

interface VatTotal {
  name: string;
  value: number;
}
