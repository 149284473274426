import React, {Fragment, useContext} from 'react';
import CostsContext from './costs-context';
import messages from './messages';
import updateDiscount from './slice/actions/update-discount';
import updateSubtotal from './slice/actions/update-subtotal';
import selectDecoratorCoeMargin from './slice/selectors/select-decorator-coe-margin';
import selectDecoratorPriceIncludingTax from './slice/selectors/select-decorator-price-including-tax';
import selectPurchasePrice from './slice/selectors/select-purchase-price';
import selectTotalFields from './slice/selectors/select-total-fields';
import selectVat from './slice/selectors/select-vat';
import clsx from 'clsx';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Col, Row} from 'reactstrap';

const DecoratorPrice = () => {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const totalFields = useSelector(selectTotalFields);

  const purchasePrice = useSelector(selectPurchasePrice);

  const decoratorCoeMarg = useSelector(selectDecoratorCoeMargin);

  const {id} = useParams();

  const {coe} = useSelector(selectVat(id));

  const decoratorTotal = useSelector(selectDecoratorPriceIncludingTax(coe));

  const {validationErrors} = useContext(CostsContext);

  return (
    <Fragment>
      <Col md={3}>
        <TextInput
          label={t(...messages.decoratorSubtotal())}
          errors={validationErrors['prices.decorator_price.price_ht']}
          type="number"
          className={clsx({
            'border-warning': totalFields.decorator_price < purchasePrice,
          })}
          value={totalFields.decorator_price}
          onChange={v => dispatch(updateSubtotal('decorator', v))}
        />
        <Row>
          <Col md={6}>
            <TextInput
              label={t(...messages.coeMargin())}
              disabled
              readOnly
              type="text"
              value={decoratorCoeMarg}
              onChange={() => {}}
            />
          </Col>
          <Col md={6}>
            <TextInput
              label={t(...messages.discount())}
              type="number"
              max="100"
              value={totalFields.decorator_price_discount}
              errors={validationErrors['prices.decorator_price.discount']}
              onChange={v => dispatch(updateDiscount('decorator', v))}
            />
          </Col>
        </Row>
        <TextInput
          label={t(...messages.decoratorTotal())}
          disabled
          readOnly
          type="number"
          value={decoratorTotal.toFixed(2)}
          onChange={() => {}}
        />
      </Col>
    </Fragment>
  );
};

export default DecoratorPrice;
