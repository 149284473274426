import React, {FC, useContext} from 'react';
import documentContext from '../../containers/document-context/document-context';
import removeItem from '../../store/actions/remove-item';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import {CommercialProduct} from '../../types/commercial-product';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {uiActions} from 'store/ui/ui-slice';
import classNames from 'classnames';

interface Props {
  item: CommercialProduct;
}

const ItemRemovalButton: FC<Props> = props => {
  const {item} = props;

  const {id: doc_id} = useParams();

  const dispatch = useDispatch();

  const isRemote = useSelector(selectIsRemoteObject(item.id));

  const {doc_type, is_locked} = useContext(documentContext);

  const handleRemoveItem = () => {
    if (!doc_type) {
      throw new Error(`Unknown document type`);
    }
    if (isRemote) {
      dispatch(
        uiActions.confirmDelete({
          name: `${doc_type}-product`,
          otherParams: {doc_id},
          resourceId: item.id,
        })
      );
    } else {
      dispatch(removeItem(item.id));
    }
  };

  return (
    <Button
      disabled={is_locked}
      color="link"
      size="sm"
      onClick={handleRemoveItem}
    >
      <Icon
        name="trash-2"
        className={classNames('', {
          'opacity-0': is_locked,
          'text-danger': true,
        })}
        size={20}
      />
    </Button>
  );
};

export default ItemRemovalButton;
