import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateProductFamilyMutation} from 'store/production/endpoints/update-family';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ProductFamily} from 'types/product-family';

export const useFamilyForm = () => {
  const [submitFamily, {isLoading, error}] = useUpdateProductFamilyMutation();

  const serverError = parseValidationErrors(error);
  const modalParams = useSelector(selectActiveModalParams);
  const family = modalParams?.family as ProductFamily;

  const [params, setParams] = useState({
    id: family?.id ?? 0,
    name: family?.name ?? '',
    code: family?.code ?? '',
    inspiration: family?.inspiration ?? '',
    collections: family?.product_collections ?? [],
  });
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submitFamily(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(loadData({name: 'families', page: 1, url: 'product-families'}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
