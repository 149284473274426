import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  value?: string | number;
}

const NaturalNumber: FC<Props> = props => {
  const {value} = props;

  const {t} = useTranslation();
  return (
    <Fragment>
      {t('numbers.naturalNumber', {
        val: value ?? 0,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      })}
    </Fragment>
  );
};

export default NaturalNumber;
