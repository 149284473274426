import React from 'react';
import {FC, Fragment} from 'react';
import {toastConfig} from './constants';
import messages from './messages';
import Button from 'components/button';
import {actions} from 'features/listing/list/slice'; //this code will be removed after the next release
import {useDestroyResourceMutation} from 'features/listing/list/slice/destroy-resource';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {ResourceName} from 'types/resource-name';

const SoulEater: FC<Props> = props => {
  const {message, onDismiss} = props;

  const {t} = useTranslation();

  const [execute, {isLoading}] = useDestroyResourceMutation();

  const dispatch = useDispatch();

  const handleRemoveResource = async (acc: boolean) => {
    if (!acc) {
      onDismiss();
      return;
    }
    const result = await execute({
      name: props.name,
      resourceId: props.resourceId,
      otherParams: props.otherParams,
    });
    if ('data' in result) {
      dispatch(
        actions.destroyResource({
          resourceId: props.resourceId,
          resourceName: props.name,
        })
      );
      toast.success(result.data?.data?.message, toastConfig);
    }

    onDismiss();
  };

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.alert()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <p>{message ?? <Trans i18nKey={messages.deleteConfirmation()} />}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="important"
          onClick={() => handleRemoveResource(true)}
          isLoading={isLoading}
        >
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={() => handleRemoveResource(false)}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  name: ResourceName;
  onDismiss: () => void;
  otherParams?: any;
  resourceId: string | number;
}

export default SoulEater;
