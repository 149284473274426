import React from 'react';
import AsyncItemPicker from 'components/async-item-picker';
import {Directory} from 'types/directory';
import GetOptionLabel from './get-option-label';
import NoOptionMessage from './no-option-message';

const DirectoryPicker = ({
  label,
  errors,
  value,
  otherParams,
  onChange,
  className,
}: DirectoryPickerType) => (
  <AsyncItemPicker
    endPoint="data/directories"
    otherParams={otherParams}
    errors={errors}
    getOptionLabel={(directory: Directory) => (
      <GetOptionLabel directory={directory} />
    )}
    label={label}
    className={className}
    optionLabel=""
    value={value}
    onChange={onChange}
    noOptionsMessage={(e: any) => <NoOptionMessage newClient={e.inputValue} />}
  />
);

type DirectoryPickerType = {
  label: string;
  value: any;
  errors?: string | string[] | undefined;
  otherParams?: {
    excluded_families: string;
    excluded_categories: string;
  };
  onChange: {
    (value: Directory): void;
  };
  className?: string;
};

export default DirectoryPicker;
