import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedOrderFromResource} from './show-order';
import {Order} from '../../types/order';
import {Option} from 'types/option';

const preparerOrder = api.injectEndpoints({
  endpoints: builder => ({
    preparerOrder: builder.mutation<PreparerResponse, PreparerOrderRequest>({
      query: params => {
        const itemsObjectified =
          params.items ?? ({} as Record<ObjectId, boolean>);
        const commercial_products = Object.keys(params.items ?? {})
          .filter(x => itemsObjectified[x])
          .map(id => ({
            commercial_product_id: id,
          }));

        const stock_location_id = params.stock_location?.id;

        delete params.items;
        delete params.stock_location;
        return {
          method: 'POST',
          url: `/orders/${params.id}/prepare`,
          body: {
            ...params,
            stock_location_id,
            commercial_products,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

export type PreparerOrderRequest = {
  id?: ObjectId;
  stock_location?: Option;
  items?: Record<ObjectId, boolean>;
  body?: string;
  emails: Email[] | string[];
};

export type Email = {
  id: ObjectId;
  value: string;
};

export type PreparerResponse = ResponseData<Order> & {
  redirect_to: RedirectTo[];
};

export type RedirectTo = {
  id: string;
  model_type: 'Order' | 'Invoice';
  model_ref: string;
};

export const {usePreparerOrderMutation} = preparerOrder;
