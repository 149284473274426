import {selectOrder} from '../../store/endpoints/show-order';
import {Order} from '../../types/order';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

export const useCurrentOrder = () => {
  const {id} = useParams();

  const {data} = useSelector(selectOrder({id}));

  return data?.data as Order;
};
