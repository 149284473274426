import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.quotationVtaAndTaxes.title, 'VAT & Taxes'),
  quotationVtaNumber: () => __(translations.quotationVtaAndTaxes.quotationVtaNumber, 'VAT Number'),
  quotationVtaMode: () => __(translations.quotationVtaAndTaxes.quotationVtaMode, 'VAT Mode'),
  quotationVatIncluded: () => __(translations.quotationVtaAndTaxes.quotationVatIncluded, 'VAT exemption (force VAT)'),
  quotationVatExcluded: () => __(translations.quotationVtaAndTaxes.quotationVatExcluded, 'Without VAT'),
  changesCommited: () => __(translations.quotationVtaAndTaxes.changesCommited, 'Your changes has been saved successfully!'),
};
