import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import useGenerateCredit from './use-action-generate-credit';
import clsx from 'clsx';
import Button from 'components/button';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Card, CardBody, FormGroup, Input, Label, Row} from 'reactstrap';
import {ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

const InvoiceGenerateCreditActionConfirm: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    errorMessage,
    toggleCommercialProduct,
    toggleSelectAll,
    selectAll,
    selectedCommercialProducts,
    commercialProducts,
  } = useGenerateCredit(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.title())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <p>{t(...messages.invoiceCreditMessage())} </p>
        <div className="mb-3">
          <FormGroup check>
            <Input
              type="checkbox"
              id={`clone-commercial-product-all`}
              checked={selectAll}
              className={clsx({
                'border-black border-opacity-50': !selectAll,
              })}
              onChange={toggleSelectAll}
            />
            <Label check for={`clone-commercial-product-all`}>
              {t(...messages.selectAll())}
            </Label>
          </FormGroup>
        </div>
        <div>
          {commercialProducts.map(v => (
            <Card
              key={v.id}
              color={clsx({primary: selectedCommercialProducts[v.id]})}
              outline
            >
              <CardBody className="p-2">
                <FormGroup className="" check>
                  <Input
                    type="checkbox"
                    className={clsx({
                      'border-black border-opacity-50':
                        !selectedCommercialProducts[v.id],
                    })}
                    id={`clone-commercial-product-${v.id}`}
                    checked={selectedCommercialProducts[v.id]}
                    onChange={() => toggleCommercialProduct(v)}
                  />
                  <Label
                    check
                    for={`clone-commercial-product-${v.id}`}
                    className="text-uppercase"
                  >
                    {v.custom_name}
                  </Label>
                </FormGroup>
              </CardBody>
            </Card>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Alert color="danger" isOpen={!!errorMessage}>
            {errorMessage}
          </Alert>
        </Row>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.invoiceCreditTitle())}
        </Button>
        <Button color="light" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default InvoiceGenerateCreditActionConfirm;
