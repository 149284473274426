import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {IPhase, Project} from 'types/project';
import {ResponseData} from 'types/response-data';

const storeProjectPhases = api.injectEndpoints({
  endpoints: builder => ({
    storeProjectPhases: builder.mutation<Response, StoreProjectPhasesRequest>({
      query: (body: StoreProjectPhasesRequest) => {
        return {
          method: 'POST',
          url: `projects/${body.id}/phases`,
          body,
        };
      },
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'projects', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type StoreProjectPhasesRequest = {
  id: string | undefined;
  phases: IPhase[];
  phase_global_comment?: string;
  deleted_records: {
    phases?: ObjectId[];
    deposits?: ObjectId[];
  };
};

type Response = ResponseData<Project>;

export const {useStoreProjectPhasesMutation} = storeProjectPhases;
