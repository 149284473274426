import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {
  MergeDirectoryRequest,
  useMergeDirectoryMutation,
} from 'store/directory/endpoints/merge-directory';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';
import {ProductFamily} from 'types/product-family';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useMergeDirectoryForm = () => {
  const [submit, {isLoading, error}] = useMergeDirectoryMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const family = modalParams?.family as ProductFamily;

  const fake = useSeeder();

  const [params, setParams] = useState<Params>({
    id: modalParams.id,
    directory: undefined,
  });
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(toast('success', result.data.message));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type Params = MergeDirectoryRequest;
