import React from 'react';
import {FC} from 'react';
import messages from '../messages';
import useCompanyForm from './use-company-form';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import AsyncItemPicker from 'components/async-item-picker';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Label, Row} from 'reactstrap';
import {Option} from 'types/option';
import {TwitterPicker} from 'react-color';
import './styles.scss';
import styled from 'styled-components';
import Can from 'features/hylian-shield/can';

const GeneralCompanyDirectoryForm: FC<{}> = () => {
  const {t} = useTranslation();

  const {
    submit,
    params,
    isLoading,
    validationErrors,
    setParams,
    directory,
    canEdit,
  } = useCompanyForm();

  const isSupplier =
    params.directory_categories.findIndex((c: any) => c.type === 'SUPPLIER') !==
    -1;

  return (
    <>
      <SectionSeparator
        title={t(...messages.title(), {name: directory?.display_name})}
      />
      <Row className="mb-2">
        <Col md={5}>
          <TextInput
            label={t(...messages.name())}
            className="mb-1"
            value={params.company_name}
            errors={validationErrors.company_name}
            onChange={company_name => setParams({...params, company_name})}
          />
        </Col>
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/countries"
            className="mb-1"
            label={t(...messages.country())}
            errors={validationErrors.residency_country}
            value={params.residency_country}
            onChange={residency_country =>
              setParams({...params, residency_country})
            }
          />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/directory-categories"
            className="mb-1"
            label={t(...messages.category())}
            optionLabel=""
            errors={validationErrors.directory_categories}
            value={params.directory_categories}
            isMulti
            onChange={directory_categories =>
              setParams({...params, directory_categories})
            }
          />
        </Col>
        <Col md={5}>
          <AsyncItemPicker
            key={`directory-families-${params.directory_categories
              ?.map(c => c.id)
              .join('|')}`}
            endPoint="data/directory-families"
            className="mb-1"
            otherParams={{
              directory_categories: params.directory_categories
                ?.map(c => c.id)
                .join('|'),
            }}
            disabled={params.directory_categories?.length === 0}
            label={t(...messages.family())}
            optionLabel=""
            errors={validationErrors.directory_families}
            isMulti
            value={params.families}
            onChange={families => setParams({...params, families})}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/users"
            className="mb-1"
            label={t(...messages.referentContact())}
            errors={validationErrors.reference_contact_id}
            getOptionLabel={(v: any) => v.name}
            value={params.reference_contact}
            onChange={reference_contact =>
              setParams({...params, reference_contact})
            }
          />
        </Col>
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/requirement-rates"
            className="mb-1"
            label={t(...messages.requirementRate())}
            errors={validationErrors.requirement_rate}
            getOptionLabel={(v: Option) => v.label}
            getOptionValue={(v: Option) => v.value}
            value={params.requirement_rate}
            onChange={requirement_rate =>
              setParams({...params, requirement_rate})
            }
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <AsyncItemPicker
            endPoint="data/newsletter-lists"
            className="mb-1"
            label={t(...messages.newsletter())}
            optionLabel=""
            errors={validationErrors.newsletters}
            value={params.newsletters}
            isMulti
            onChange={newsletters => setParams({...params, newsletters})}
          />
        </Col>

        <Col md={5}>
          <TextInput
            label={t(...messages.website())}
            className="mb-1"
            value={params.website}
            errors={validationErrors.website}
            onChange={website => setParams({...params, website})}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <TextInput
            type="textarea"
            label={t(...messages.comment())}
            rows={4}
            value={params.comment}
            errors={validationErrors.comment}
            onChange={comment => setParams({...params, comment})}
          />
        </Col>
        <Col md={5}>
          <Label>{t(...messages.color())}</Label>
          <div className="d-flex">
            <TwitterPicker
              className="shadow-none rounded"
              color={params.color_code}
              onChange={(c: any) => setParams({...params, color_code: c.hex})}
            />
            <div className="py-3 ">
              <Boxx
                style={{backgroundColor: params.color_code}}
                className="flex-grow-1 rounded rounded-2"
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          {isSupplier && (
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={e =>
                    setParams({
                      ...params,
                      is_official_furniture_supplier: e.target.checked,
                    })
                  }
                  checked={params.is_official_furniture_supplier}
                  id="official-furniture-supplier"
                />
                <label
                  className="form-check-label"
                  htmlFor="official-furniture-supplier"
                >
                  {t(...messages.official_furniture_supplier())}
                </label>
              </div>
              {/* 2 */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={e =>
                    setParams({
                      ...params,
                      is_official_studio_supplier: e.target.checked,
                    })
                  }
                  checked={params.is_official_studio_supplier}
                  id="official-studio-supplier"
                />
                <label
                  className="form-check-label"
                  htmlFor="official-studio-supplier"
                >
                  {t(...messages.official_studio_supplier())}
                </label>
              </div>
              {/* 3 */}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={e =>
                    setParams({
                      ...params,
                      is_official_suppliers_small_items: e.target.checked,
                    })
                  }
                  checked={params.is_official_suppliers_small_items}
                  id="official-suppliers-small-items"
                />
                <label
                  className="form-check-label"
                  htmlFor="official-suppliers-small-items"
                >
                  {t(...messages.official_suppliers_small_items())}
                </label>
              </div>
            </div>
          )}
        </Col>
      </Row>
      {!directory?.is_archived && (
        <AppBarActions>
          <Can action="edit" subject="directory">
            <Button isLoading={isLoading} onClick={submit} color="important">
              <Icon name="check" size={15} className="me-1" />
              {t('common.saveChanges')}
            </Button>
          </Can>
        </AppBarActions>
      )}
    </>
  );
};

const Spacer = () => <Label style={{marginTop: '2.75rem'}}></Label>;

export const Boxx = styled.div`
  aspect-ratio: 1 / 1;
  height: 100%;
`;

export default GeneralCompanyDirectoryForm;
