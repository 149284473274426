import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  title: () => __(translations.commercialSettingsTab.title, 'Commercial'),
  changesCommited: () => __(translations.commercialSettingsTab.changesCommited, 'All changes were saved!'),
  pricingScaleMin: () => __(translations.commercialSettingsTab.pricingScaleMin, 'Pricing Scale min'),
  pricingScaleMax: () => __(translations.commercialSettingsTab.pricingScaleMax, 'Pricing Scale max'),
  vipThreshold: () => __(translations.commercialSettingsTab.vipThreshold, 'VIP Threshold'),
  margeAlert : () => __(translations.commercialSettingsTab.margeAlert, 'Marge Alert'),
  vipCondition: () => __(translations.commercialSettingsTab.vipCondition, 'VIP Condition'),
};
