import React, {FC} from 'react';
import {Col, Label, Row} from 'reactstrap';

interface Props {
  title?: string;
}

const SectionSeparator: FC<Props> = props => {
  const {title} = props;

  return (
    <div>
      <Row>
        <Col md={12}>
          <div>
            <Label>
              <strong>{title}</strong>
            </Label>
          </div>
        </Col>
      </Row>
      <hr className="mt-1" />
    </div>
  );
};

export default SectionSeparator;
