import {useState} from 'react';
import messages from '../messages';
import {
  ArchiveColorRequest,
  useArchiveColorMutation,
} from '../store/endpoints/archive-color';
import {useListSlice} from 'features/table/slice';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import toast from 'store/ui/actions/toast';
import {ObjectId} from 'types/object-id';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useColorDeleteForm = (
  onDismiss: () => void,
  colorId: ObjectId
) => {
  const [archiveColor, {isLoading, error}] = useArchiveColorMutation();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const serverError = parseValidationErrors(error);
  const {actions} = useListSlice();
  const name = 'colors';
  const url = 'colors';
  const handleSubmit = async () => {
    const result = await archiveColor(params);
    if ('data' in result) {
      dispatch(toast('success', t(...messages.colorArchivedSuccess())));
      dispatch(actions.clearSelection(name));
      dispatch(actions.loadData({url, name}));
      onDismiss();
    }
  };

  const [params, setParams] = useState<Params>({
    id: colorId,
  });

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
type Params = ArchiveColorRequest;
