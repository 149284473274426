import React from 'react';
import {Fragment, useState} from 'react';
import './styles.scss';
import ImageCropPicker from 'components/image-crop-picker';
import {Upload} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Row} from 'reactstrap';
import {useUpdateAvatarMutation} from 'store/account/endpoints/update-avatar';
import {selectProfile, selectUser} from 'store/auth/selectors';
import {useUploadTemporaryFileMutation} from 'store/global/endpoints/upload-temporary-file';

const AvatarSection = () => {
  const [showCropper, setShowCropper] = useState(false);

  const [updateAvatar, {isLoading}] = useUpdateAvatarMutation();

  const [sendTempFile, {isLoading: isUploadingTemp}] =
    useUploadTemporaryFileMutation({});

  const handleUpdateAvatar = async (file: File) => {
    setShowCropper(false);

    const tempFileResult = await sendTempFile({data: {file}});
    if ('data' in tempFileResult) {
      const avatarResult = await updateAvatar({
        image: tempFileResult.data.data?.id,
      });
    }
  };

  const profile = useSelector(selectProfile);

  const {t} = useTranslation();

  const user = useSelector(selectUser);

  const [avatar, fallbackAvatar] = [
    profile?.avatar?.square,
    'https://placehold.co/400x400',
  ];

  return (
    <Fragment>
      <Row>
        <div className="col-sm order-2 order-sm-1">
          <div className="d-flex align-items-start mt-3 mt-sm-0">
            <div className="flex-shrink-0">
              {/* CONTENT */}
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <div className="btt"></div>
                  <label
                    htmlFor="imageUpload"
                    onClick={() => setShowCropper(true)}
                  >
                    <Upload size={15} className="mt-2 text-primary" />
                  </label>
                </div>
                <div className="avatar-preview">
                  <img
                    className="profile-user-img img-fluid rounded-circle d-block"
                    id="imagePreview"
                    src={avatar ?? fallbackAvatar}
                    alt="Profile picture"
                  />
                </div>
              </div>
            </div>
            <div className="flex-grow-1">
              <div>
                <h5 className="font-size-16 mb-1">
                  {`${profile?.first_name} ${profile?.last_name}`}
                </h5>
                <p className="text-muted font-size-13">{user?.role?.label}</p>

                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                  <div>
                    <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                    {user?.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <ImageCropPicker
        onCropFinished={handleUpdateAvatar}
        onClose={() => setShowCropper(false)}
        isVisible={showCropper}
        aspect={1 / 1}
      />
    </Fragment>
  );
};

export default AvatarSection;
