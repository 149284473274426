import React, {FC} from 'react';
import messages from './messages';
import {MODAL} from 'features/modal-notch/constants';
import {Edit, Trash2} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {uiActions} from 'store/ui/ui-slice';
import Button from 'components/button';
import StocklocationCatergory from 'features/stock/types/stock-location-category';
import Can from 'features/hylian-shield/can';

interface Props {
  stockLocationCategory: StocklocationCatergory;
}

const StockLocationCategoryActions: FC<Props> = props => {
  const {stockLocationCategory} = props;

  const dispatch = useDispatch();

  const {t} = useTranslation();
  const handleDestroy = () => {
    dispatch(
      uiActions.confirmDelete({
        resourceId: stockLocationCategory.id,
        name: 'stock-location-categories',
      })
    );
  };

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.UPDATE_STOCK_LOCATION_CATEGORY, {stockLocationCategory})
    );
  };

  const hasActions = true;
  return (
    <div className="d-flex align-items-center h-100">
      <Can action="edit" subject="stock">
        <Button
          tag="button"
          color="transparent"
          className="me-2"
          onClick={handleEdit}
        >
          <Edit size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default StockLocationCategoryActions;
