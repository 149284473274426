import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductCollection} from 'types/product-collection';
import {ResponseData} from 'types/response-data';
import queryParams from 'utils/query-params';

const getProductStockCounts = api.injectEndpoints({
  endpoints: builder => ({
    getProductStockCounts: builder.query<Response, Request>({
      query: params => {
        return {
          method: 'GET',
          url: `product-variants/stock-counts`,
          params: queryParams(params, '|'),
        };
      },
    }),
  }),
});

type Request = {};

type Response = ResponseData<{
  stock_total: number;
  stock_available: number;
}>;

export const {useGetProductStockCountsQuery} = getProductStockCounts;
