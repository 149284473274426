import {EcoTax} from 'features/acounting/types/eco-tax';
import {api} from 'services/api';
import {ResponseData} from 'types/response-data';

const storeEcotax = api.injectEndpoints({
  endpoints: builder => ({
    storeEcotax: builder.mutation<Response, StoreEcotaxRequest>({
      query: params => {
        return {
          method: 'POST',
          url: `ecotaxes`,
          body: {
            product_code: params.product_code,
            customs_code: params.customs_code,
            ecopart_code: params.ecopart_code,
            ecopart_rate: params.ecopart_rate,
          },
        };
      },
    }),
  }),
});

export type StoreEcotaxRequest = {
  product_code?: string;
  customs_code?: string;
  ecopart_code?: string;
  ecopart_rate?: string;
};

type Response = ResponseData<EcoTax>;

export const {useStoreEcotaxMutation} = storeEcotax;
