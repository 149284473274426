import React, {FC, useState} from 'react';
import {Fragment} from 'react';
import messages from './messages';
import AsyncItemPicker from 'components/async-item-picker';
import {useTranslation} from 'react-i18next';
import {CardBody, Row, Col, FormGroup} from 'reactstrap';

interface FilterProps {
  filters: any;
  onChangeFilters: (key: string, value: any) => void;
}

const ExportsFilters: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters} = props;

  const handleChangeFilters = (key: string, value: any) => {
    onChangeFilters(key, value);
  };

  const [search_Menu, setsearch_Menu] = useState(false);

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  return (
    <Fragment>
      <CardBody className="py-0">
        {/* First row */}
        <Row className="align-items-center">
          <Col lg="3" md="3">
            <FormGroup>
              <AsyncItemPicker
                endPoint="data/export-types"
                className="mb-1"
                getOptionLabel={(o: any) => o.label}
                label={t(...messages.type())}
                value={filters ? filters['filter[type]'] : null}
                onChange={o => handleChangeFilters('filter[type]', o)}
              />
            </FormGroup>
          </Col>

          <Col md={3}></Col>
        </Row>
        {/* <!-- end row --> */}
        {/* First row */}
      </CardBody>
    </Fragment>
  );
};

export default ExportsFilters;
