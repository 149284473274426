import {updateCachedVariantDetailsFromResource} from './show-variant';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductComponent} from 'types/product-component';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateComponents = api.injectEndpoints({
  endpoints: builder => ({
    updateVariantComponents: builder.mutation<
      Response,
      VariantComponentsRequest
    >({
      query: params => ({
        method: 'POST',
        url: `/products/${params.id}/variants/${params.variant}/components`,
        body: {
          delete_records: params.deleteRecords,
          components: params.components.map(x => ({
            id: x.id,
            variant_id: x.variant?.id,
            quantity: x.quantity,
          })),
          product_id: params.id,
          variant: params.variant,
        },
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const result = (await queryFulfilled).data.data;

          dispatch(updateCachedVariantDetailsFromResource(result));
        } catch {}
      },
    }),
  }),
});

export type VariantComponentsRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  deleteRecords: ObjectId[];
  components: ProductComponent[];
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateVariantComponentsMutation} = updateComponents;
