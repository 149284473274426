import React, {FC} from 'react';
import {CommercialProduct} from '../../types/commercial-product';
import ItemPicker from 'components/item-picker';
import TextInput from 'components/text-input';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {CommercialProductOption} from 'types/commercial-product-option';
import {Option} from 'types/option';
import {useSelector} from 'react-redux';
import selectIsRemoteObject from '../../store/selectors/select-is-remote-object';
import OptionRelatedDocumentsSection from '../option-related-documents-section/option-related-documents-section';
import messages from './messages';

interface Props {
  option: CommercialProductOption;
  item: CommercialProduct;
  index: number;
}

const ComponentItem: FC<Props> = props => {
  const {option, item, index} = props;

  const {t} = useTranslation();

  const isRemoteObject = useSelector(selectIsRemoteObject(item.id));

  return (
    <div>
      <Row>
        <Col md={4}>
          <ItemPicker
            // this is intentionally set to do nothing
            onChange={() => {}}
            isDisabled
            getOptionLabel={(v: Option) => v?.name}
            getOptionValue={(v: Option) => v?.id}
            value={option.option_value}
            label={t(...messages.component())}
            options={item.variant?.components ?? []}
          />
        </Col>
        <Col md={4}>
          <TextInput
            // this is intentionally set to do nothing
            onChange={() => {}}
            disabled
            readOnly
            label={t(...messages.quantity())}
            value={option.quantity}
          />
        </Col>
        <Col md={4}>
          {isRemoteObject && (
            <OptionRelatedDocumentsSection
              item={item}
              index={index}
              option={option}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ComponentItem;
