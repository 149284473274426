import React, {FC, Fragment, useEffect, useState} from 'react';
import {ReactElement, createContext, useContext} from 'react';
import {createPortal} from 'react-dom';
import {now} from 'utils/now';

interface Props {
  children: ReactElement | string | number;
}

const AppBarActions: FC<Props> = props => {
  const {children} = props;

  const {container: island} = useContext(AppActionBarContext);

  // this fixes some issue
  const [_, setFirst] = useState<string>();
  useEffect(() => setFirst(now()), []);

  return (
    <Fragment>
      {island?.current ? createPortal(children, island.current) : null}
    </Fragment>
  );
};

export default AppBarActions;

export const AppActionBarContext = createContext<{
  container: React.RefObject<ActionBarElement> | null;
  updateContainer: (a?: ActionBarElement) => void;
}>({
  container: null,
  updateContainer: () => {},
});

export type ActionBarElement = HTMLDivElement | null | undefined;
