import React, {FC, useContext} from 'react';
import documentContext from '../../containers/document-context/document-context';
import removeAddress from '../../store/actions/remove-address';
import {MODAL} from 'features/modal-notch/constants';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import {Address} from 'types/address';
import {
  Badge,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface Props {
  address: Address;
  index: number;
}

const AddressItem: FC<Props> = props => {
  const {address, index} = props;
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.UPDATE_ADDRESS_FORM, {address, hideDefault: true})
    );
  };

  const handleDelete = () => {
    dispatch(removeAddress(address.id));
  };

  const {validationErrors, doc, is_locked} = useContext(documentContext);

  return (
    <Col className="col-sm-6 col-xl-6">
      <Card>
        <CardBody>
          <h5 className="font-size-15 mb-1 d-flex justify-content-between align-items-center mb-2  ">
            <span>{address.address_type.name}</span>
            <UncontrolledDropdown direction="start" className="text-end">
              <DropdownToggle disabled={is_locked} className="" tag="i">
                <i className="bx bx-dots-horizontal-rounded" role="button" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag={Link} className="w-100" onClick={handleEdit}>
                  <span className="align-middle ms-2">{t('common.edit')}</span>
                </DropdownItem>

                <DropdownItem
                  tag="button"
                  className="text-danger"
                  onClick={handleDelete}
                >
                  <span className="align-middle ms-2">
                    {t('common.delete')}{' '}
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </h5>
          <p className="font-size-15 mb-1 ">{address.label}</p>
          <p className="mb-1">
            {address.first_line}
            {address.second_line?.length > 0 &&
              ', ' + address.second_line + ', '}
            {address.third_line}
          </p>
          <p className="text-muted mb-1">
            {address.display_name_parts.join(',')}
          </p>
          <p className="mb-2">{address.country?.name}</p>

          <h5 className="mb-2 font-size-14">
            {t('updateAddressModal.vatMode')}
            <Badge className="ms-1 font-size-14">
              {address.vat_mode?.label}
            </Badge>
          </h5>

          <p>{address.comment}</p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AddressItem;
