import {updateCachedDirectoryFromResource} from './show-directory';
import {api} from 'services/api';
import {Directory} from 'types/directory';
import {ObjectId} from 'types/object-id';
import {ProductComponent} from 'types/product-component';
import {ResponseData} from 'types/response-data';

const updateComponentsPrice = api.injectEndpoints({
  endpoints: builder => ({
    updateComponentsPrice: builder.mutation<
      Response,
      DirectoryComponentsPriceRequest
    >({
      query: params => ({
        method: 'POST',
        url: `/directories/${params.id}/pricing`,
        body: {
          delete_records: params.delete_records,
          variants: params.variants.map(x => ({
            id: x.id,
            variant_id: x.variant?.id,
            cost: x.cost,
          })),
          id: params.id,
        },
      }),

      async onQueryStarted(i, {dispatch, queryFulfilled}) {
        try {
          const directory = (await queryFulfilled).data.data;

          dispatch(updateCachedDirectoryFromResource(directory));
        } catch (e) {}
      },
    }),
  }),
});

export type DirectoryComponentsPriceRequest = {
  id?: ObjectId;
  delete_records: ObjectId[];
  variants: ProductComponent[];
};

type Response = ResponseData<Directory>;

export const {useUpdateComponentsPriceMutation} = updateComponentsPrice;
