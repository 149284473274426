import React from 'react';
import {FC, Fragment} from 'react';
import messages from './messages';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, ModalHeader} from 'reactstrap';
import {StockInProduct} from 'features/stock/types/stock-in-product';
import EmptyState from 'features/table/compenents/empty-state';
import {SerialNumber} from 'types/product-variant';
import _ from 'lodash';
import {groupBySerialNumbers} from 'features/stock/utils/group-by-serial-numbers';
import {Link} from 'react-router-dom';

const DetailsStockInProduct: FC<Props> = props => {
  const {onDismiss, stockInProduct} = props;

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={messages.title()} />
        </span>
      </ModalHeader>
      <ModalBody className="">
        <h4 className="text-center ">{stockInProduct.product_variant.name}</h4>

        <StockProductTable
          serial_numbers={stockInProduct.product_variant.serial_numbers}
        />
      </ModalBody>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
  stockInProduct: StockInProduct;
}

export default DetailsStockInProduct;
interface StockInProductProps {
  serial_numbers?: SerialNumber[];
}

const StockProductTable = (props: StockInProductProps) => {
  const {serial_numbers} = props;
  const {t} = useTranslation();

  return serial_numbers?.length === 0 ? (
    <EmptyState />
  ) : (
    <table className="table">
      <thead>
        <tr>
          <th>{t(...messages.subLocalisation())}</th>
          <th className="text-center">{t(...messages.quantity())}</th>
          <th className="text-center">{t(...messages.serialNumber())}</th>
          <th className="text-center">{t(...messages.client())}</th>
        </tr>
      </thead>
      <tbody>
        {serial_numbers &&
          groupBySerialNumbers(serial_numbers)?.map((serial_number, index) => {
            return (
              <tr key={index}>
                <td>{serial_number?.location_name ?? ''}</td>
                <td className="text-center">{serial_number?.quantity}</td>
                <td className="text-center">
                  {serial_number?.serial_numbers.map(sn => (
                    <div key={sn.id}>
                      <Link to={sn.pdf_links.label} target="_blank">
                        {sn.code}
                      </Link>{' '}
                      <br />
                    </div>
                  ))}
                </td>
                <td className="text-center">
                  {serial_number?.serial_numbers.map(sn => (
                    <div key={sn.id}>
                      {sn.order?.client_name} <br />
                    </div>
                  ))}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
