import {extraReducers} from './extra-reducerr';
import initialState from './initial-state';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Notification} from 'types/notification';

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    notificationReceived(state, action: PayloadAction<Notification>) {
      state.unreadNotificationsCount += 1;
    },
    clearNotifications(state) {
      state.readNotifications = {};
    },
  },
  extraReducers,
});

export const accountActions = accountSlice.actions;

export default accountSlice;
