import React, {FC, useCallback, useContext, useEffect} from 'react';
import documentContext from '../../containers/document-context/document-context';
import updateItem from '../../store/actions/update-item';
import selectRelatableItems from '../../store/selectors/select-relatable-items';
import {CommercialProduct} from '../../types/commercial-product';
import messages from './messages';
import ItemPickerMultiple from 'components/item-picker-multiple/item-picker-multiple';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

interface Props {
  item: CommercialProduct;
  index: number;
}

const LinkedItemsPicker: FC<Props> = props => {
  const {item, index} = props;
  const relatableProducts = useSelector(selectRelatableItems(item));
  const linkedProducts = item?.linked_products;

  const {validationErrors, is_locked} = useContext(documentContext);

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleUpdate = (key: keyof CommercialProduct, v: any) => {
    dispatch(updateItem(item, key, v));
  };
  return (
    <div>
      <ItemPickerMultiple
        options={relatableProducts}
        label={t(...messages.chooseProductToLink())}
        errors={validationErrors[`products.${index}.linked_products`]}
        isDisabled={is_locked}
        isMulti
        value={item.linked_products}
        getOptionLabel={(x: any) => x.name}
        getOptionValue={(x: any) => x?.id}
        onChange={(p: any) => handleUpdate('linked_products', p)}
      />
      <div className="invalid-feedback d-block">
        {item?.linked_products?.map((x, i) => (
          <div key={x.id}>
            {validationErrors[`products.${index}.child_ids.${i}`]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkedItemsPicker;
