import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.usersPage.action, 'Action'),
  add: () => __(translations.usersPage.add, 'Add'),
  admin: () => __(translations.roles.admin, 'Admin'),
  archive: () => __(translations.usersPage.archive, 'Archive'),
  avatar: () => __(translations.usersPage.avatar, 'Avatar'),
  dashboard: () => __(translations.usersPage.dashboard, 'Dashboard'),
  archivedUser: () => __(translations.usersPage.archivedUser, 'Archived users'),
  all: () => __(translations.usersPage.all, 'All Users'),
  delete: () => __(translations.usersPage.delete, 'Delete'),
  details: () => __(translations.usersPage.details, 'Details'),
  disabled: () => __(translations.usersPage.disabled, 'Disabled'),
  edit: () => __(translations.usersPage.edit, 'Edit'),
  email: () => __(translations.usersPage.email, 'E-mail'),
  enabled: () => __(translations.usersPage.enabled, 'Enabled'),
  fullName: () => __(translations.usersPage.fullName, 'Full Name'),
  guest: () => __(translations.roles.guest, 'Guest'),
  id: () => __(translations.usersPage.id, 'ID'),
  pageTitle: () => __(translations.usersPage.pageTitle, 'User'),
  role: () => __(translations.usersPage.role, 'Role'),
  status: () => __(translations.usersPage.status, 'Status'),
  user: () => __(translations.stock.user, 'User'),
  users: () => __(translations.usersPage.users, 'Users'),
  show: () => __(translations.usersPage.show, 'Show'),
  restore: () => __(translations.usersPage.restore, 'Restore'),
  archiveMessage: () => __(translations.usersPage.archiveMessage, 'Do you really want to Archive this resource?'),
  dystoryMessage: () => __(translations.soulEater.deleteConfirmation, 'Do you really want to delete this resource?'),
};
