import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  backToLogin: () => __(translations.resetPasswordPage.backToLogin, 'Back to login'),
  pageTitle: () => __(translations.resetPasswordPage.pageTitle, 'Reset password'),
  password: () => __(translations.resetPasswordPage.password, 'New Password'),
  passwordConfirmation: () => __(translations.resetPasswordPage.passwordConfirmation, 'Password confirmation'),
  passwordResetSuccess: () => __(translations.resetPasswordPage.passwordResetSuccess, 'Your password has been reset. Please, sign in with your new password.'),
  resetCodeInstructions: () => __(translations.resetPasswordPage.resetCodeInstructions, "An email with a verification code was just sent to '{{email}}'"),
  resetPasswordHint: () => __(translations.resetPasswordPage.resetPasswordHint, 'Your new password must be different from previously used passwords'),
  sendAgain: () => __(translations.resetPasswordPage.sendAgain, 'Request new code'),
  submit: () => __(translations.resetPasswordPage.submit, 'Reset password'),
  token: () => __(translations.resetPasswordPage.token, 'Reset code'),
};
