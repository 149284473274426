import React, {FC} from 'react';
import {Card, Spinner} from 'reactstrap';

interface TagsItemProps {
  title: string;
  children: React.ReactNode;
  color?: string; // bs color
  isLoading?: boolean;
}

const TagsItems: FC<TagsItemProps> = props => {
  const {title, children, color = 'black', isLoading} = props;
  return (
    <Card className="px-3 py-3 mb-0 text-center">
      <h5 className={`text-${color} fs-6`}>{title}</h5>
      {isLoading ? (
        <div className="w-100 h-100 p-2 ">
          <Spinner size="sm" className="" />
        </div>
      ) : (
        <p className="text-muted m-0 fs-4">{children}</p>
      )}
    </Card>
  );
};

export default TagsItems;
