import {selectDomain} from '.';
import {createSelector} from '@reduxjs/toolkit';
import denormalizeObject from 'utils/denormalize-object';

export default createSelector([selectDomain], domain => {
  const items = domain.items;

  const asArray = denormalizeObject(items);

  return asArray.sort((a, b) => Number(a.order) - Number(b.order));
});
