import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateStockProductsInPreparation = api.injectEndpoints({
  endpoints: builder => ({
    updateStockProductsInPreparation: builder.mutation<
      Response,
      UpdateStockProductsInPreparationRequest
    >({
      query: params => {
        return {
          method: 'PUT',
          url: `prepare-orders/${params.id}/deliver`,
          body: params,
        };
      },
    }),
  }),
});

export type UpdateStockProductsInPreparationRequest = {
  id: ObjectId;
  status: string;
  tracking_code?: string;
  comment?: string;
  transporter_id?: ObjectId;
};

type Response = ResponseData<UpdateStockProductsInPreparationRequest>;

export const {useUpdateStockProductsInPreparationMutation} =
  updateStockProductsInPreparation;
