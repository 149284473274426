import React from 'react';
import QuotationGeneralInformation from './quotation-general-informations';
import QuotationTransporter from './quotation-transporter';
import QuotationVtaAndTaxes from './quotation-vta-and-taxes';
import {Col, Row} from 'reactstrap';

const QuotationGeneralTab = () => {
  return (
    <div>
      <Col>
        <QuotationGeneralInformation />
      </Col>
    </div>
  );
};

export default QuotationGeneralTab;
