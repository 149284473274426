import {updateCachedVariantFromResource} from './get-product-variants';
import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const duplicatVariant = api.injectEndpoints({
  endpoints: builder => ({
    duplicatVariant: builder.mutation<Response, Request>({
      query: params => {
        return {
          method: 'POST',
          url: `/product-variants/${params.id}/duplicate`,
        };
      },
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
          const result = (await queryFulfilled).data.data;
          dispatch(updateCachedVariantFromResource(result));
        } catch {}
      },
    }),
  }),
});

type Request = {
  id: ObjectId;
};

type Response = ResponseData<ProductVariant>;

export const {useDuplicatVariantMutation} = duplicatVariant;
