import React, {FC} from 'react';
import {MODAL} from 'features/modal-notch/constants';
import {Edit} from 'react-feather';
import {useDispatch} from 'react-redux';
import showModal from 'store/ui/actions/show-modal';
import Button from 'components/button';
import StockProductInPreparation from 'features/stock/types/stock-products-in-preparation';
import Can from 'features/hylian-shield/can';

interface Props {
  stockInPreparation: StockProductInPreparation;
}

const EditAction: FC<Props> = props => {
  const {stockInPreparation} = props;

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(
      showModal(MODAL.EDIT_STOCK_IN_PREPARATION_MODAL, {
        stockInPreparation,
      })
    );
  };

  return (
    <div className="d-flex align-items-center h-100">
      <Can action="edit" subject="stock">
        <Button
          tag="button"
          color="transparent"
          className="me-2 btn-light"
          onClick={handleEdit}
        >
          <Edit size={20} />
        </Button>
      </Can>
    </div>
  );
};

export default EditAction;
