import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  contacts: () => __(translations.editProjectContactsSection.contacts, 'Contacts'),
  contact: () => __(translations.editProjectContactsSection.contact, 'Contact'),
  contactsList: () => __(translations.editProjectContactsSection.contactsList, 'Contacts List'),
  addContact: () => __(translations.editProjectContactsSection.addContact, 'Add a new contact'),
  category: () => __(translations.editProjectPage.category, 'Category'),

  noData: () => __(translations.editProjectContactsSection.noData, 'No Data'),
  consulted: () => __(translations.editProjectContactsSection.consulted, 'Consulted'),
  call_for_tender: () => __(translations.editProjectContactsSection.call_for_tender, 'Call for tender'),
  doubleClickToRemove: () => __(translations.editProjectContactsSection.doubleClickToRemove, 'Double click to remove'),
  addToList: () => __(translations.editProjectContactsSection.addToList, 'Add to list'),

  technicalSheetClient: () => __(translations.editProjectContactsSection.technicalSheetClient, 'technical sheet client'),
  technicalSheetInterne: () => __(translations.editProjectContactsSection.technicalSheetInterne, 'technical sheet interne'),

  retained: () => __(translations.editProjectContactsSection.retained, 'Retained'),
  delegated_owner: () => __(translations.editProjectContactsSection.delegated_owner, 'Project Owner'),
  delegated_project_manager: () => __(translations.editProjectContactsSection.delegated_project_manager, 'Delegated project owner'),
  operator: () => __(translations.editProjectContactsSection.operator, 'Operator'),
  supplier: () => __(translations.editProjectContactsSection.supplier, 'Supplier'),
  business: () => __(translations.editProjectContactsSection.business, 'Business'),
  client_referent: () => __(translations.editProjectContactsSection.client_referent, 'Customer contact'),
  others: () => __(translations.editProjectContactsSection.others, 'Others'),
};
