import React, {FC, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocalStorage} from 'usehooks-ts';

import {CardBody, Row, Col} from 'reactstrap';
import {FormGroup, Collapse} from 'reactstrap';

import messages from '../messages';
import Actions from './app-bar';

import Button from 'components/button';
import ItemPicker from 'components/item-picker';
import TagsItem from 'components/tags-card';
import DateRangeInput from 'components/date-range-input';

import {FilterMap} from 'features/listing/list/slice/types';

import {Option} from 'types/option';
import {useEnumAsOption} from 'features/studio/projects/utils/use-enum-as-option';
import {ProjectStatusEnum} from 'features/studio/projects/constants/project-status';
import {ProjectTypeEnum} from 'features/studio/projects/constants/project-type';
import {ProjectPaginationMeta, ProjectsFilters} from './types';
import {useSelector} from 'react-redux';
import selectEntityMeta from 'features/table/slice/selectors/select-entity-meta';
import SearchSection from 'features/table/compenents/search-section';

interface FilterProps {
  filters: FilterMap<ProjectsFilters>;
  onChangeFilters: (key: ProjectsFilters, value: any) => void;
  onClearFilters?: () => void;
}

const ProjectFilters: FC<FilterProps> = props => {
  const {t} = useTranslation();

  const {filters, onChangeFilters, onClearFilters} = props;

  const meta = useSelector(selectEntityMeta<ProjectPaginationMeta>('projects'));

  const options = {
    types: useEnumAsOption(ProjectTypeEnum, 'projectsPage'),
    status: useEnumAsOption(ProjectStatusEnum, 'statusBadge'),
    is_online: [
      {
        id: true,
        name: t(...messages.online()),
      },
      {
        id: false,
        name: t(...messages.offline()),
      },
    ],
  };

  return (
    <Fragment>
      <CardBody>
        <Row className="mb-4">
          <Col md={6}>
            <Actions filters={filters} />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-3">
          <Col sm={3}>
            <TagsItem title={t(...messages.projectsTotal())}>
              {meta?.total_projects ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem title={t(...messages.scenography())}>
              {meta?.total_scenography ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem title={t(...messages.design_collaborations())}>
              {meta?.total_design_collaborations ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem title={t(...messages.petits_projets())}>
              {meta?.total_petits_projets ?? 0}
            </TagsItem>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-3">
          <Col sm={3}>
            <TagsItem title={t(...messages.archi_residence())}>
              {meta?.total_archi_residence ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem title={t(...messages.archi_hotel_restaurant())}>
              {meta?.total_archi_hotel_restaurant ?? 0}
            </TagsItem>
          </Col>
          <Col sm={3}>
            <TagsItem title={t(...messages.archi_retail())}>
              {meta?.total_archi_retail ?? 0}
            </TagsItem>
          </Col>
        </Row>

        {/* First row */}
        <SearchSection
          filters={filters}
          onChangeFilters={onChangeFilters}
          onClearFilters={onClearFilters}
        >
          <Row className="mb-3">
            <Col lg="3" md="3">
              <FormGroup>
                <DateRangeInput
                  label={t(...messages.filterBetweenDates())}
                  value={filters['filter[between_date]'] as string}
                  onChange={o => onChangeFilters('filter[between_date]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <ItemPicker
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.type())}
                  isClearable
                  value={filters['filter[project_type]'] as Option}
                  options={options.types}
                  onChange={o => onChangeFilters('filter[project_type]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <ItemPicker
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  label={t(...messages.status())}
                  isClearable
                  value={filters['filter[status]'] as Option}
                  options={options.status}
                  onChange={o => onChangeFilters('filter[status]', o)}
                />
              </FormGroup>
            </Col>
            <Col lg="3" md="3">
              <FormGroup>
                <ItemPicker
                  getOptionLabel={(v: any) => v.name}
                  className="mb-1"
                  isClearable
                  label={t(...messages.online())}
                  value={filters['filter[is_online]'] as Option}
                  options={options.is_online}
                  onChange={o => onChangeFilters('filter[is_online]', o)}
                />
              </FormGroup>
            </Col>
          </Row>
        </SearchSection>
      </CardBody>
    </Fragment>
  );
};

export default ProjectFilters;
