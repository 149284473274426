import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';
import {updateCachedOrderFromResource} from './show-order';
import {Order} from '../../types/order';
import {Option} from 'types/option';
import {CommercialProduct} from 'features/commercial/documents/types/commercial-product';
import {CommercialProductOption} from 'types/commercial-product-option';

const transferOrderToSupplierOrder = api.injectEndpoints({
  endpoints: builder => ({
    transferOrderToSupplierOrder: builder.mutation<
      TransferToSupplierOrderResponse,
      TransferToSupplierOrderRequest
    >({
      query: params => {
        const itemsObjectified =
          params.items ?? ({} as Record<ObjectId, TransferSupplierOrderRow>);
        const products = Object.keys(params.items ?? {})
          // filter out unselected items

          .filter(uuid => params.selectedIds?.includes(uuid))
          .map(uuid => {
            const item = itemsObjectified[uuid];

            const is_component = item?.commercial_product?.type === 'COMPONENT';
            return {
              commercial_product_id: is_component
                ? undefined
                : item.commercial_product?.id,

              commercial_product_option_id: is_component
                ? item.commercial_product?.id
                : undefined,

              is_component,
              sku: item.commercial_product?.sku,
              supplier_id: item.supplier?.id,
              should_transfer: true,
              stock_location_id: item.stock_location_id,
            };
          });

        return {
          method: 'POST',
          url: `/orders/${params.id}/transfer-to-supplier-order`,
          body: {
            products,
          },
        };
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

export type TransferToSupplierOrderRequest = {
  id?: ObjectId;
  items?: Record<ObjectId, TransferSupplierOrderRow>;
  selectedIds?: ObjectId[];
};

export type TransferToSupplierOrderType = 'order_client' | 'invoice';

export type TransferToSupplierOrderResponse = ResponseData<Order> & {
  redirect_to: RedirectTo[];
};

export type RedirectTo = {
  id: string;
  model_type: 'Order' | 'Invoice';
  model_ref: string;
};

export const {useTransferOrderToSupplierOrderMutation} =
  transferOrderToSupplierOrder;

export type TransferSupplierOrderRow = {
  is_component?: boolean;
  supplier?: Option;
  commercial_product?: Partial<CommercialProduct & CommercialProductOption>;
  commercial_product_id?: ObjectId;
  is_compenent: boolean;
  is_selected: boolean;
  location_id?: ObjectId;
  stock_location_id?: ObjectId;
};
