import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch, useSelector} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useUpdateDirectoryFamilyMutation} from 'store/directory/endpoints/update-directory-family';
import {selectActiveModalParams} from 'store/ui/selectors';
import {uiActions} from 'store/ui/ui-slice';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const [submit, {isLoading, error}] = useUpdateDirectoryFamilyMutation();

  const serverError = parseValidationErrors(error);

  const directoryFamily = useSelector(selectActiveModalParams);

  const [params, setParams] = useState({
    id: directoryFamily?.id,
    name: directoryFamily?.name,
    categories: directoryFamily?.categories,
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit(params);
    if ('data' in result) {
      dispatch(loadData({name: 'directory-families', page: 1}));
      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
