import React, {FC} from 'react';
import ItemProduct from '../../containers/item-product/item-product';
import ItemSubtitle from '../../containers/item-subtitle/item-subtitle';
import ItemTitle from '../../containers/item-title/item-title';
import {CommercialProduct} from '../../types/commercial-product';
import clsx from 'clsx';
import {useSelector} from 'react-redux';
import {selectAppTheme} from 'store/layout/selectors';
import ItemExternalProduct from '../../containers/item-external-product/item-external-product';

interface Props {
  item: CommercialProduct;
  index: number;
  dragHandleProps?: any;
  isDragging?: boolean;
}

const ItemMapper: FC<Props> = props => {
  const {item, index, ...rest} = props;

  const C = componentMap[item.commercial_product_type];

  const theme = useSelector(selectAppTheme);

  return (
    <div
      className={clsx({
        'bg-white': theme === 'light',
        'bg-dark': theme === 'dark',
      })}
    >
      <C item={item} index={index} {...rest} />
    </div>
  );
};

const componentMap = {
  TITLE: ItemTitle,
  EXTERNAL_PRODUCT: ItemExternalProduct,
  PRODUCT: ItemProduct,
  SUBTITLE: ItemSubtitle,
};

export default ItemMapper;
