import React from 'react';
import {FC, Fragment} from 'react';
import messages from '../messages';
import EmailRow from './email-row';
import {useSendQuotationAsEmail} from './use-action-quotation-send-email';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Button from 'components/button';
import SectionSeparator from 'components/separator/section-separator';
import TextInput from 'components/text-input';
import {Trans, useTranslation} from 'react-i18next';
import {ModalBody, Row, Col, ModalFooter, ModalHeader} from 'reactstrap';

const QuotationSendEmailAction: FC<Props> = props => {
  const {onDismiss} = props;

  const {t} = useTranslation();

  const {
    submit,
    isLoading,
    addEmail,
    message,
    updateEmail,
    email,
    removeEmail,
    validationErrors,
    params,
    setParams,
  } = useSendQuotationAsEmail(onDismiss);

  return (
    <Fragment>
      <ModalHeader className="bg-primary" toggle={onDismiss}>
        <span className="text-light">
          <Trans i18nKey={t(...messages.quotationEmailModalTitle())} />
        </span>
      </ModalHeader>

      <ModalBody className="">
        <TextInput
          rows={12}
          type="textarea"
          label={t(...messages.quotationEmailSendSubject())}
          value={params.body}
          errors={validationErrors.body}
          onChange={body => setParams({...params, body})}
        />
        <Row className="my-3">
          <Col>
            <SectionSeparator title={t(...messages.quotationEmailList())} />
          </Col>
          <Col md="auto">
            <Button color="light" onClick={addEmail}>
              <Icon name="plus" size={15} className="mb-1" />
              {t(...messages.quotationEmailAdd())}
            </Button>
          </Col>
        </Row>
        <EmailRow
          updateEmail={updateEmail}
          email={email}
          removeEmail={removeEmail}
          validationErrors={validationErrors}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="important" onClick={submit} isLoading={isLoading}>
          {t(...messages.yesImSure())}
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          {t(...messages.cancel())}
        </Button>
      </ModalFooter>
    </Fragment>
  );
};

interface Props {
  message?: string;
  onDismiss: () => void;
}

export default QuotationSendEmailAction;
