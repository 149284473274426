import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useArchiveVariantMutation} from 'store/production/endpoints/action-variant-archive';
import {selectActiveModalParams} from 'store/ui/selectors';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useSaveProductAsForm = (onDismiss: () => void) => {
  const [submit, {isLoading, error}] = useArchiveVariantMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {message, variant} = modalParams;

  const navigate = useNavigate();
  const handleSubmit = async () => {
    const result = await submit(variant);
    if ('data' in result) {
      navigate(`/dashboard/products/${variant?.product_id}/general`);
      toast.success(result?.data?.data.message);
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
