import messages from './messages';
import {__} from 'utils/messages';

export const ADDRESS_TYPE = {
  BILLING: 'FACTURATION',
  SHIPPING: 'LIVRAISON',
};

export const ADDRESS_TYPES = {
  [ADDRESS_TYPE.BILLING]: {
    id: ADDRESS_TYPE.BILLING,
    name: 'addresses.billingAddress',
  },
  [ADDRESS_TYPE.SHIPPING]: {
    id: ADDRESS_TYPE.SHIPPING,
    name: 'addresses.shippingAddress',
  },
};
