import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  tarif: () => __(translations.priceComponentsTab.pricing, 'Pricing'),
  title: () => __(translations.priceComponentsTab.title, 'Components of {{name}}'),
  label: () => __(translations.priceComponentsTab.label, 'Label'),
  price: () => __(translations.priceComponentsTab.price, 'Price'),
  addComponent: () => __(translations.priceComponentsTab.addComponent, 'Add component'),
  actionBtn: () => __(translations.priceComponentsTab.actionBtn, 'Action multiple'),
  cost: () => __(translations.priceComponentsTab.cost, 'Cost'),
};
