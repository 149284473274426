import React, {useEffect, useMemo} from 'react';
import QuotationActionTab from '../tabs/actions/quotation-actions-tab';
import QuotationDetailsRouter from './quotation-details-router/quotation-details-router';
import QuotationSummarySection from './summary-section/quotation-summary-section';
import QuotationsTabPane from './tab-pane/quotations-tab-pane';
import Icon from '@ailibs/feather-react-ts/dist/Icon';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Button from 'components/button';
import LoadRunner from 'components/load-runner';
import {useShowQuotationQuery} from 'features/commercial/quotations/store/endpoints/show-quotation';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {Quotation} from 'types/quotation';
import messages from './messages';
import useAbility from 'features/hylian-shield/use-ability';
import {Subject} from 'features/hylian-shield/types';
import {useDispatch} from 'react-redux';
import toast from 'store/ui/actions/toast';

const QuotationMasterDetails = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {id} = useParams();

  const navigate = useNavigate();

  const {checkAbility} = useAbility();

  const {data, isFetching, error, isError, isSuccess, refetch} =
    useShowQuotationQuery({id});

  const quotation = data?.data as Quotation;

  const canShow = useMemo(
    () => checkAbility('show', quotation?.commercial_type?.code as Subject),
    [quotation]
  );

  useEffect(() => {
    if (!canShow && !!quotation) {
      dispatch(toast('error', t('common.notAllowed')));
      navigate('/dashboard/quotations');
    }
  }, [canShow]);

  const serverError = parseValidationErrors(error);

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>
            {' '}
            {t(...messages.helmetTitle(), {ref: quotation?.document_ref})}{' '}
          </title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs
            breadcrumbItems={[
              {
                title: t('common.dashboard'),
                path: '/dashboard',
              },
              {
                title: t('quotationsPage.title'),
                path: '/dashboard/quotations',
              },
              {
                title: t('quotationDetailsPage.pageTitle'),
                path: '#',
              },
            ]}
          />
          {/* Content */}

          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  {isSuccess && (
                    <QuotationSummarySection
                      quotation={quotation}
                      onReload={refetch}
                    />
                  )}
                  <QuotationsTabPane />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {isFetching && (
                    <div className="d-flex justify-content-center">
                      <LoadRunner
                        width={'100%'}
                        height={80}
                        viewBox="0 0 100% 60"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="20"
                        />
                        <rect
                          x="0"
                          y="40"
                          rx="5"
                          ry="5"
                          width="85%"
                          height="20"
                        />
                      </LoadRunner>
                    </div>
                  )}

                  {isError && (
                    <div>
                      <Alert color="danger">{serverError.message}</Alert>
                      <div className="d-flex justify-content-center py-3">
                        <Button onClick={refetch}>
                          <Icon name="refresh-cw" size={15} className="me-2" />
                          Reload
                        </Button>
                      </div>
                    </div>
                  )}

                  {isSuccess && <QuotationDetailsRouter />}
                  {isSuccess && <QuotationActionTab quotation={quotation} />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QuotationMasterDetails;
