import React, {FC} from 'react';
import ProfilWithText from '../profile-section/skeleton';
import messages from './messages';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
  Table,
} from 'reactstrap';
import {PermissionData} from 'types/permission-data';
import {User} from 'types/user';

interface Props {
  user?: User;
}

const PermissionTab: FC<Props> = props => {
  const {t} = useTranslation();

  const {user} = props;

  const permissionData = user?.permissionData || [];

  return (
    <div className="">
      <Helmet>
        <title> {t(...messages.title())}</title>
      </Helmet>
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <h5 className="font-size-16">{t(...messages.title())}</h5>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {user ? (
            <Table>
              <thead>
                <tr>
                  <th>{t(...messages.module())}</th>
                  <th>{t(...messages.permission())}</th>
                </tr>
              </thead>
              <tbody>
                {permissionData?.map((item: PermissionData) => (
                  <tr key={item.id}>
                    <td>{item?.label}</td>
                    <td>
                      <div className="d-flex  flex-wrap">
                        {item?.contents.map(content => (
                          <Badge
                            key={content}
                            className="px-2 me-2 mb-2 py-1"
                            color="secondary"
                          >
                            {content}
                          </Badge>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ProfilWithText></ProfilWithText>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

interface Props {}

export default PermissionTab;
