import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {ProductComponent} from 'types/product-component';
import {ProductManifactureTime} from 'types/product-manifacture-time';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const updateManifactureTime = api.injectEndpoints({
  endpoints: builder => ({
    updateManifactureTime: builder.mutation<
      Response,
      VariantManifactureTimeRequest
    >({
      query: params => ({
        method: 'POST',
        url: `product-variants/${params.variant}/production-times`,
        body: {
          deleted_records: params.deleteRecords,
          production_times: params.manifactureTime.map(x => ({
            id: x.id.toString(),
            directory_id: x.directory?.id.toString(),
            days: x.days,
          })),
          product_id: params.id,
          variant: params.variant,
        },
      }),
      // @ts-expect-error
      invalidatesTags: result => {
        if (result) {
          return [{type: 'variants', id: result?.data.id}];
        }
        return [];
      },
    }),
  }),
});

export type VariantManifactureTimeRequest = {
  id?: ObjectId;
  variant?: ObjectId;
  deleteRecords: ObjectId[];
  manifactureTime: ProductManifactureTime[];
};

type Response = ResponseData<ProductVariant>;

export const {useUpdateManifactureTimeMutation} = updateManifactureTime;
