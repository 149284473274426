import React, {FC} from 'react';
import {CardBody, Col, Row} from 'reactstrap';
import TagsItem from 'components/tags-card';
import Currency from 'components/currency/currency';
import messages from '../messages';
import {useTranslation} from 'react-i18next';
import TextInput from 'components/text-input';
import IConclusionForm from '../use-conclusion-form';

const BodySection: FC<Props> = ({params, isLoading, setParams, round}) => {
  const {t} = useTranslation();

  const {total_CA, duration, total_CA_monthly} = params.conclusion;

  return (
    <CardBody>
      <Row>
        <Col />
        <Col>
          <TagsItem title={t(...messages.totalCAProject())} color="info">
            <Currency value={round(total_CA)} />
          </TagsItem>
        </Col>
        <Col>
          <TagsItem title={t(...messages.projectDurationToDate())} color="info">
            {`${isLoading ? 0 : duration} ${t(...messages.months())}`}
          </TagsItem>
        </Col>
        <Col>
          <TagsItem title={t(...messages.totalCAMonthlyPaid())} color="info">
            <Currency value={round(total_CA_monthly)} />
          </TagsItem>
        </Col>
      </Row>
      <hr />
      <div className="mt-2">
        <TextInput
          type="textarea"
          value={params.conclusion_global_comment}
          label={t(...messages.comment())}
          onChange={conclusion_global_comment =>
            setParams({...params, conclusion_global_comment})
          }
        />
      </div>
    </CardBody>
  );
};

type Props = IConclusionForm;

export default BodySection;
