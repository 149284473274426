import {createReducer} from './reducers';
import rootSaga from './sagas';
import {uiActions} from './ui/ui-slice';
import {configureStore, StoreEnhancer} from '@reduxjs/toolkit';
import type {PreloadedState} from '@reduxjs/toolkit';
import {createInjectorsEnhancer} from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {api, blobApi} from 'services/api';

export default function configureAppStore(
  preloadedState?: PreloadedState<RootState>
) {
  const reduxSagaMonitorOptions = {};

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const {run: runSaga} = sagaMiddleware;

  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [uiActions.confirmDelete.type],
        },
      }).concat([api.middleware, blobApi.middleware, ...middlewares]);
    },
    preloadedState,
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
    enhancers,
  });

  return {
    ...store,
    runSaga: () => sagaMiddleware.run(rootSaga),
  };
}

export type RootState = ReturnType<ReturnType<typeof createReducer>>;
export type AppStore = ReturnType<typeof configureAppStore>;

export type AppDispatch = AppStore['dispatch'];
