import React, {FC} from 'react';
import DragHandleSpacer from '../../components/spacing/drag-handle-spacer';
import {CommercialProduct} from '../../types/commercial-product';
import {useDispatch} from 'react-redux';
import {Row, Col} from 'reactstrap';
import LinkedItemsPicker from '../../components/linked-items-picker/linked-items-picker';

interface Props {
  item: CommercialProduct;
  index: number;
}

const ItemLinkingSection: FC<Props> = props => {
  const {item, index} = props;

  const dispatch = useDispatch();

  return (
    <Row className="pb-3">
      <Col md="auto">
        <DragHandleSpacer />
      </Col>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <LinkedItemsPicker index={index} item={item} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ItemLinkingSection;
