import {Order} from '../../types/order';
import {updateCachedOrderFromResource} from './show-order';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import selectDocumentFormBody from 'features/commercial/documents/store/selectors/select-document-form-body';
import {api} from 'services/api';
import {RootState} from 'store';
import {ObjectId} from 'types/object-id';
import {ResponseData} from 'types/response-data';

const updateOrderDetails = api.injectEndpoints({
  endpoints: builder => ({
    updateOrderDetails: builder.mutation({
      async queryFn(arg, {getState}, extraOptions, baseQuery) {
        const body = selectDocumentFormBody(getState() as RootState);
        const result = await baseQuery({
          url: `orders/${arg.id}/commercial-products`,
          method: 'POST',
          body: {
            id: arg.id,
            ...body,
          },
        });
        if (result.error) {
          return {error: result.error as FetchBaseQueryError};
        }

        return result as {data: ResponseData<Order>};
      },
      async onQueryStarted(_, {dispatch, queryFulfilled}) {
        try {
          const o = (await queryFulfilled).data?.data;
          dispatch(updateCachedOrderFromResource(o));
        } catch {}
      },
    }),
  }),
});

export type UpdateOrderRequest = {
  id?: ObjectId;
};

type Response = ResponseData<Order>;

export const {useUpdateOrderDetailsMutation} = updateOrderDetails;
