import {FC, createContext, useEffect} from 'react';
import React from 'react';
import {AbilityContextType, Action, Subject} from './types';
import upgradeShield from './upgrade-shield';
import {Ability} from '@casl/ability';
import {useSelector} from 'react-redux';
import {selectUser} from 'store/auth/selectors';

const ability = new Ability();

export const AbilityContext = createContext<AbilityContextType>({
  checkAbility: () => false,
});

const HylianShield: FC<Props> = props => {
  const {children} = props;

  const user = useSelector(selectUser);

  const checkAbility = (
    action: Action | Action[],
    subject: Subject | Subject[]
  ): boolean => {
    // if no specific permission requested, return true
    if (subject === 'any') return true;

    if (typeof action === 'string' && typeof subject === 'string')
      return ability.can(action, subject);

    if (Array.isArray(action) && typeof subject === 'string')
      return action.some(act => ability.can(act, subject));

    if (Array.isArray(subject) && typeof action === 'string')
      return subject.some(sbj => ability.can(action, sbj));

    if (Array.isArray(subject) && Array.isArray(action))
      return subject.some(sbj => action.some(act => ability.can(act, sbj)));

    return false;
  };

  useEffect(() => {
    ability.update(upgradeShield(user));
  }, [user]);
  return (
    <AbilityContext.Provider value={{checkAbility}}>
      {children}
    </AbilityContext.Provider>
  );
};

interface Props {
  children: React.ReactNode;
}

export default HylianShield;
