import React, {FC} from 'react';
import InvoiceTable from './invoice-stat/invoice-table';
import OrderTable from './order-stat/order-table';
import QuoteTable from './quote-stat/quote-table';
import SupplierOrderTable from './supplier-order-stat/supplier-order-table';
import VariantCharts from './variant-charts';
import {useParams} from 'react-router';
import {Row} from 'reactstrap';

interface Props {}

const LinkedResourcesTab: FC<Props> = props => {
  const {id, variant} = useParams();

  return (
    <>
      <Row className="align-items-center justify-content-center mb-3">
        <VariantCharts id={variant} />
      </Row>

      <OrderTable id={variant} />
      <InvoiceTable id={variant} />
      <QuoteTable id={variant} />

      <SupplierOrderTable id={variant} />
    </>
  );
};

export default LinkedResourcesTab;
