import {fcmTokenRetrieved} from './reducers/fcm-token-retrieved';
import {forgotPassword} from './reducers/forgot-password';
import {login} from './reducers/login';
import {loginWithApple} from './reducers/login-with-apple';
import {loginWithFacebook} from './reducers/login-with-facebook';
import {loginWithGoogle} from './reducers/login-with-google';
import {mergeUser} from './reducers/merge-user';
import {refresh} from './reducers/refresh';
import {resetPassword} from './reducers/reset-password';
import {setupGoogleAuth} from './reducers/setup-google-auth';
import {updateUser} from './reducers/update-user';
import {verifyIdentity} from './reducers/verify-identity';
import {verifyPasswordResetToken as verifyResetToken} from './reducers/verify-password-reset-token';
import {AuthState} from './types';
import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

export const extraReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(fcmTokenRetrieved.matcher, fcmTokenRetrieved.reducer);

  builder.addCase(mergeUser.matcher, mergeUser.reducer);

  builder.addCase(setupGoogleAuth.matcher, setupGoogleAuth.reducer);

  builder.addMatcher(forgotPassword.matcher, forgotPassword.reducer);

  builder.addMatcher(login.matcher, login.reducer);

  builder.addMatcher(loginWithApple.matcher, loginWithApple.reducer);

  builder.addMatcher(loginWithFacebook.matcher, loginWithFacebook.reducer);

  builder.addMatcher(loginWithGoogle.matcher, loginWithGoogle.reducer);

  builder.addMatcher(refresh.matcher, refresh.reducer);

  builder.addMatcher(resetPassword.matcher, resetPassword.reducer);

  builder.addMatcher(updateUser.matcher, updateUser.reducer);

  builder.addMatcher(verifyIdentity.matcher, verifyIdentity.reducer);

  builder.addMatcher(verifyResetToken.matcher, verifyResetToken.reducer);
};
