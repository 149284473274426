import {useState} from 'react';
import loadData from 'features/table/slice/actions/load-data';
import {useDispatch} from 'react-redux';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useStoreFamilyMutation} from 'store/production/endpoints/store-family';
import toast from 'store/ui/actions/toast';
import {uiActions} from 'store/ui/ui-slice';
import {useSeeder} from 'utils/use-seeder';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useFamilyForm = () => {
  const [storeFamily, {isLoading, error}] = useStoreFamilyMutation();

  const serverError = parseValidationErrors(error);

  const fake = useSeeder();

  const [params, setParams] = useState({
    name: __DEV__ ? fake.productName : '',
    code: __DEV__ ? fake.code : '',
    inspiration: __DEV__ ? fake.paragraph : '',
    collections: [],
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await storeFamily(params);
    if ('data' in result) {
      dispatch(loadData({name: 'families', page: 1, url: 'product-families'}));

      dispatch(uiActions.dismissModal());
    }
  };

  return {
    setParams,
    params,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};
