import React, {FC} from 'react';

interface Props {
  children: React.ReactNode;
}

const EmptyLayout: FC<Props> = ({children}) => {
  return <div>{children}</div>;
};

export default EmptyLayout;
