import React from 'react';
import {Card, Col, Row} from 'reactstrap';
import HeaderSection from './header-section';
import BodySection from './body-section';
import {useConclusionForm} from './use-conclusion-form';
import AppBarActions from 'components/VerticalLayout/app-bar-actions';
import Icon from '@ailibs/feather-react-ts';
import {useTranslation} from 'react-i18next';
import ExportsSection from '../../shared/exports-section';
import Button from 'components/button';

const Conclusion = () => {
  const {t} = useTranslation();

  const form = useConclusionForm();

  return (
    <Card>
      <HeaderSection {...form} />
      <BodySection {...form} />
      <Row>
        <Col md={8}>
          <div className="justify-content-end d-flex">
            <AppBarActions>
              <>
                <Button
                  isLoading={form.isSubmitting}
                  onClick={form.submit}
                  color="important"
                >
                  <Icon name="check" size={15} className="me-1" />
                  {t('common.saveChanges')}
                </Button>
                <ExportsSection />
              </>
            </AppBarActions>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Conclusion;
